import * as React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const classes = new Array();
  const attr = {};

  if (props.size) {
    classes.push(`mi-${props.size}${parseInt(props.size) === props.size ? 'x' : ''}`);
  }

  if (props.rounded) {
    classes.push('mi-rounded');
  }

  if (props.color) {
    classes.push(props.color);
  }

  if (props.background) {
    classes.push(`bg-${props.background}`);
  }

  if (props.className) {
    classes.push(props.className);
  }

  if (props.style) {
    attr.style = props.style;
  }

  if (props.onClick) {
    attr.onClick = props.onClick;
  }

  return (
    <i className={`mi mi-${props.glyph} ${classes.join(' ')}`} {...attr} />
  );
};

Icon.propTypes = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.any,
  color: PropTypes.string,
  background: PropTypes.string,
  style: PropTypes.object,
  rounded: PropTypes.any,
  className: PropTypes.string,
};

export default Icon;
