import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { Form, Input } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';
import { Icon } from '../../utils';
import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';

import * as applicationsActions from '../../actions/applicationsActions';

export class ApplicationBarApplicant extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      editable: {
        email: false,
        homePhone: false,
        mobile: false,
      },
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.document.getElementById('app').addEventListener('touchstart', this.handleClick);
    window.document.getElementById('app').addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.document.getElementById('app').removeEventListener('touchstart', this.handleClick);
    window.document.getElementById('app').removeEventListener('click', this.handleClick);
  }

  handleScroll() {
    if (this.refs.applicationBarTrigger) {
      this.refs.applicationBarTrigger.hide();
      this.reset();
    }
  }

  handleClick(event) {
    if (this.isElementWithinApplicationBar(event.target)) {
      return false;
    }

    if (this.refs.applicationBarTrigger) {
      this.refs.applicationBarTrigger.hide();
      this.reset();
    }
  }

  isElementWithinApplicationBar(element) {
    while (element) {
      if (element.id == 'applicationBarApplicant' || element.id == 'applicationBarLink') {
        return true;
      }
      element = element.parentNode;
    }

    return false;
  }

  reset() {
    this.setState({
      editable: {
        email: false,
        homePhone: false,
        mobile: false,
      },
    });
  }

  edit(fieldName) {
    const newState = { ...this.state };
    newState.editable[fieldName] = true;
    this.setState(newState);
  }

  save(field, values) {
    const newState = { ...this.state };
    newState.editable[field] = false;
    this.setState(newState);

    let type;
    switch (field) {
      case 'homePhone': type = 1; values[field] = values[field].toString().replace(/[ ()-]/g, ''); break;
      case 'mobile': type = 5; values[field] = values[field].toString().replace(/[ ()-]/g, ''); break;
      case 'email': type = 6; break;
    }

    const data = {
      applicationNumber: this.props.applicant.applicationNumber,
      applicantId: this.props.applicant.applicantId,
      contactTypeId: type,
      value: values[field],
      field,
    };

    this.props.actions.setApplicantProperty(data)
      .then(() => {
        Notify.show(messages.MESS_SUCCESS, 'success');
      })
      .catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error');
      });
  }

  render() {
    const popover = (
      <Popover id="applicationBarApplicant" ref="ApplicationBarApplicant" style={{ minWidth: '240px' }}>
        <dl>
          <dt>
            {'Phone '}
            <a data-test="edit-phone" href="javascript:void(0)" onClick={this.edit.bind(this, 'homePhone')} className={this.state.editable.homePhone ? 'hidden' : ''}><Icon glyph="edit" /></a>
          </dt>
          {
              (() => {
                if (this.state.editable.homePhone) {
                  return (
                    <dd>
                      <Form
                        onValidSubmit={this.save.bind(this, 'homePhone')}
                        layout="vertical"
                        className="r form form-instant"
                        disabled={false}
                        elementWrapperClassName=""
                        labelClassName=""
                        rowClassName=""
                        validateBeforeSubmit
                        validatePristine>
                        <Input
                          name="homePhone"
                          autoFocus
                          className="form-control form-control-sm"
                          validations={{
                            matchRegexp: /^[\d ()-]*$/,
                            isRequired: true,
                          }}
                          validationError="Phone number is required and must be valid"
                          value={this.props.applicant.contact.homePhone && this.props.applicant.contact.homePhone[0] || ''}
                        />

                        <button
                          type="submit"
                          data-test="save-phone"
                          className="btn btn-sm btn-circle btn-success a"
                          style={{
                            right: 0,
                            top: '2px',
                          }}
                        >
                          <Icon glyph="check" />
                        </button>
                      </Form>
                    </dd>
                  );
                }
                return (
                    <dd className="pt-xs" data-test="phone">
                      {
                          this.props.applicant.contact.homePhone && this.props.applicant.contact.homePhone.length
                            ? <a className="primary" href={`tel:${this.props.applicant.contact.homePhone[0]}`}>{this.props.applicant.contact.homePhone[0]}</a>
                            : <span className="mute-lg">N/A</span>
                      }
                    </dd>
                );
              })()
            }

          <dt>
Mobile
<a data-test="edit-mobile" href="javascript:void(0)" onClick={this.edit.bind(this, 'mobile')} className={this.state.editable.mobile ? 'hidden' : ''}><Icon glyph="edit" /></a>
          </dt>
          {
              (() => {
                if (this.state.editable.mobile) {
                  return (
                    <dd>
                      <Form onValidSubmit={this.save.bind(this, 'mobile')} layout="vertical" className="r form form-instant">
                        <Input
                          name="mobile"
                          autoFocus
                          className="form-control form-control-sm"
                          validations={{
                            matchRegexp: /^[\d ()-]*$/,
                            isRequired: true,
                          }}
                          validationError="Mobile is required and must be valid"
                          value={this.props.applicant.contact.mobile && this.props.applicant.contact.mobile[0] || ''}
                        />

                        <button
                          type="submit"
                          data-test="save-mobile"
                          className="btn btn-sm btn-circle btn-success a"
                          style={{
                            right: 0,
                            top: '2px',
                          }}
                        >
                          <Icon glyph="check" />
                        </button>
                      </Form>
                    </dd>
                  );
                }
                return (
                    <dd className="pt-xs" data-test="mobile">
                      {
                          this.props.applicant.contact.mobile && this.props.applicant.contact.mobile.length
                            ? <a className="primary" href={`tel:${this.props.applicant.contact.mobile[0]}`}>{this.props.applicant.contact.mobile[0]}</a>
                            : <span className="mute-lg">N/A</span>
                      }
                    </dd>
                );
              })()
            }

          <dt>
Email
<a data-test="edit-email" href="javascript:void(0)" onClick={this.edit.bind(this, 'email')} className={this.state.editable.email ? 'hidden' : ''}><Icon glyph="edit" /></a>
          </dt>
          {
              (() => {
                if (this.state.editable.email) {
                  return (
                    <dd>
                      <Form onValidSubmit={this.save.bind(this, 'email')} layout="vertical" className="r form form-instant">
                        <Input
                          name="email"
                          autoFocus
                          className="form-control form-control-sm"
                          validations={{
                            isEmail: true,
                            isRequired: true,
                          }}
                          validationError="Email address is required and must be valid"
                          value={this.props.applicant.contact.email && this.props.applicant.contact.email[0] || ''}
                        />

                        <button
                          type="submit"
                          data-test="save-email"
                          className="btn btn-sm btn-circle btn-success a"
                          style={{
                            right: 0,
                            top: '2px',
                          }}
                        >
                          <Icon glyph="check" />
                        </button>
                      </Form>
                    </dd>
                  );
                }
                return (
                    <dd className="pt-xs" data-test="email">
                      {
                          this.props.applicant.contact.email && this.props.applicant.contact.email.length
                            ? <a className="primary" href={`mailto:${this.props.applicant.contact.email[0]}`}>{this.props.applicant.contact.email[0]}</a>
                            : <span className="mute-lg">N/A</span>
                      }
                    </dd>
                );
              })()
            }
        </dl>
      </Popover>
    );

    switch (this.props.applicant.type) {
      case 'Person':
        return (
          <OverlayTrigger ref="applicationBarTrigger" trigger={['click']} tabIndex="1" rootClose placement="bottom" overlay={popover}>
            <span className="bar-applicant">
              <a href="javascript:void(0)" id="applicationBarLink">
                {this.props.applicant.name}
              </a>
            </span>
          </OverlayTrigger>
        );

      case undefined: {
        return (
          <span className="mr-md">{this.props.applicant.name}</span>
        );
      }

      default:
        return null;
    }
  }
}

// ApplicationBarApplicant.propTypes = {
//   applicant: PropTypes.object,
//   actions: PropTypes.any
// };

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applicationsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBarApplicant);
