import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Modal} from 'react-bootstrap';
import {Link} from 'react-router';
import {Form, Checkbox} from 'formsy-react-components';
import Loadr from '../../../utils/loadr';
import {IFormData} from '../interfaces'
import {Icon} from '../../../utils';
import {ISubmitApplication} from '../reducer';

import Confirm from './confirm';
import Loading from './loading';
import Success from './success';
import Validation from './validation';
import Error from './error';
import { ISession } from '../../../interfaces';

interface ISubmitProps {
  debug: boolean;
  visible: boolean;
  loading: boolean | string;
  data: any;
  submit: ISubmitApplication;
  session: ISession;
  notes: string;
  hideModal: () => void;
  saveStepData: (data: IFormData, label: string) => void;
  retrySubmit: () => void;
  resetSubmit: () => void;
  changeNote: (note: string) => void;
}

interface ISubmitState {
}

export class Submit extends React.Component<ISubmitProps, ISubmitState> {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.resetModal = this.resetModal.bind(this);
  }

  onValidSubmit(data: IFormData) {
    this.props.saveStepData(data, 'submit');
  }

  resetModal() {
    this.props.resetSubmit();
    this.props.hideModal();
  }

  hideModal() {
    if (this.canHideModal()) {
      this.props.hideModal();
    }
  }

  shouldShowConfirm(): boolean {
    return this.props.submit.status == 'initial';
  }
  shouldShowSuccess(): boolean {
    return this.props.submit.status == 'submitted';
  }
  shouldShowValidationErrors(): boolean {
    return this.props.submit.status == 'validationError';
  }
  shouldShowApiError(): boolean {
    return this.props.submit.status == 'apiError';
  }
  shouldShowLoading(): boolean {
    return this.props.submit.status == 'submitting';
  }
  canHideModal(): boolean {
    return this.shouldShowConfirm() || this.shouldShowValidationErrors() || this.shouldShowApiError();
  }

  render(): JSX.Element {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.hideModal}
        className="feature">

        <Modal.Header>
          <br />
          { this.canHideModal() && <button type="button" className="close" aria-label="Close" onClick={this.hideModal} /> }
        </Modal.Header>

        <Modal.Body>
          {
            (() => {
              switch (this.props.submit.status) {
                case "initial": {
                  return <Confirm data={this.props.data} onValidSubmit={this.onValidSubmit} hideModal={this.hideModal} notes={this.props.notes} changeNote={this.props.changeNote} />;
                }

                case "submitting": {
                  return <Loading message={this.props.loading} />;
                }

                case "submitted": {
                  return <Success submission={this.props.submit} token={this.props.session.token} />;
                }

                case "validationError": {
                  return <Validation submission={this.props.submit} resetModal={this.resetModal} />;
                }

                case "explosion":
                case "apiError": {
                  return <Error submission={this.props.submit} retrySubmit={this.props.retrySubmit} resetModal={this.resetModal} />;
                }

                default: {
                  const exhaustiveCheck: never = this.props.submit;
                }
              }
            })()
          }
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    submit: state.submission.submit,
    session: state.session
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
