import * as React from 'react';
import { Link } from 'react-router';
import {Icon} from '../../../utils';
import {ISubmittedSuccessful} from '../reducer';
import { IJWT } from '../../../interfaces';
import { InfoAlert } from '../../../utils/alert';
import { API } from '../../../api';

interface ISuccessProps {
    submission: ISubmittedSuccessful;
    token: IJWT;
}

export const Success = (props: ISuccessProps) => {
  return (
    <div className="text-center mt-lg mb-sm-n">
        <Icon glyph="check-all" className="text-success" size={4} />

        <h4 className="pt-lg mt-lg">Application has been submitted</h4>
        <p className="text-lg mt-sm mb-lg mute">What's next? Upload the signed and dated Application Summary.</p>

        <a className="btn btn-success btn-lg mt-lg mb-lg" href={`${API.base}documents/pdf/${props.submission.applicationNumber}/2?auth_token=${props.token}`}>
            Download Application Summary
        </a>

        <br />

        <Link className="btn btn-default mb-lg" to={`applications/${props.submission.applicationNumber}`}>
          <span className="secondary">Take me to the application</span>
        </Link>

        <br />
        <br />

        <InfoAlert title="Did you know?">
          You can find the Application Summary and other application documents in the downloads section of the application.
        </InfoAlert>

    </div>
  );
}

export default Success;
