import * as React from 'react';
import PropTypes from 'prop-types';
import * as ReactTooltip from 'react-tooltip';

const MobileDetect = require('mobile-detect');

export class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      id: null,
      style: {},
      event: '',
    };

    this.event = '';
  }


  UNSAFE_componentWillMount() {
    const UA = new MobileDetect(window.navigator && window.navigator.userAgent);

    if (UA.mobile() || UA.tablet()) {
      this.event = 'click';
    }

    setTimeout(ReactTooltip.rebuild, 100);
  }

  getBackground() {
    if (!this.props.local) {
      return `url(data:image/png;base64,${this.props.contact.lFStaffPhoto})`;
    }
    return `url(/${window.iqUIVersion}/assets/avatars/${this.props.contact.lFStaffID}.jpg)`;
  }

  getClassName() {
    return `${this.props.className || ''} ${this.props.size ? `avatar-${this.props.size}` : ''}`;
  }

  getInitials() {
    return this.props.contact ? (this.props.contact.lFStaffFirstName || '').toString()[0] + (this.props.contact.lFStaffSurname || '').toString()[0] : '';
  }

  getToolTip() {
    return this.props.contact ? `${this.props.contact.lFStaffFirstName} ${this.props.contact.lFStaffSurname}` : '';
  }

  render() {
    if (this.props.contact) {
      return (
        <div className={`avatar ${this.getClassName()}`} data-tip={this.getToolTip()} data-event={this.event} data-eventoff={this.event}>
          <div className="avatar-image" style={{ backgroundImage: this.getBackground(this.props.contact.lFStaffId) }} />
          <span className="avatar-initials">{this.getInitials()}</span>
        </div>
      );
    }
    return null;
  }
}

Avatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
  size: PropTypes.any,
  contact: PropTypes.object,
  local: PropTypes.bool,
};

export default Avatar;
