import * as React from 'react';
import PropTypes from 'prop-types';
import MaskedInput, {IMaskedInputBaseProps} from './maskedInput';

type IPhoneType = "landLine" | "mobile";
interface IPhoneInputProps extends IMaskedInputBaseProps {
  type: IPhoneType;
}

const getPlaceholder = (type: IPhoneType): string => {
  return type == "landLine" ? "xx xxxx xxxx" : "xxxx xxx xxx";
}

export const PhoneInput = (props: IPhoneInputProps): JSX.Element => {
  return (
    <MaskedInput
      name={props.name}
      label={props.label}
      placeholder={getPlaceholder(props.type)}
      maxLength={12}
      value=""
      onKeyUp={props.onKeyUp}
      validations={{
        ...(props.validations || {}),
        isPhone: true,
        isUnlikelyPhone: true
      }}
      validationErrors={{
        ...(props.validationErrors || {}),
        isPhone: "Must be a valid phone number",
        isUnlikelyPhone: "Must be a valid phone number"
      }}
    />
  );
}

export default PhoneInput;
