import { PRODUCTGROUPS } from '../constants/types';
import Api from '.';
import { v4 as uuidv4 } from 'uuid';
import * as queryString from "query-string";

interface GlassGuideSearchRequest {
  requestId?: string;
  count: boolean;
  skip: number;
  top: number;
  searchMode: string;
  queryType: string;
  filter: string;
  search: string;
  orderby?: string;
}

interface GlassGuideVehicle {
  NVIC: string;
  YEAR: string;
  MAKE: string;
  FAMILY: string;
  VARIANT: string;
  SERIES: string;
  STYLE: string;
  DRIVE: string;
  ENGINE: string;
  VALVE: string;
  NO_OF_VALVES: string;
  CC: string;
  SIZE: string;
  TRANSMISSION: string;
  CYL: string;
  WIDTH: string;
  NEW_PR: string;
  ABOVE_AVERAGE_PR: string;
  AVERAGE_PR: string;
  BELOW_AVERAGE_PRICE: string;
  FUEL_TYPE: string;
}

interface GlassGuideSearchResponse {
  '@data.context': string;
  '@data.count': number;
  value: GlassGuideVehicle[] & {
    '@search.score': number;
  };
}

const createGlassGuideRequest = (query, productGroup): GlassGuideSearchRequest => {
  let filter = "TYPE eq 'MOTOR'";
  // TODO: update this if condition and VehicleType enum after Motorbike MVP
  if (productGroup === PRODUCTGROUPS.MOTOR_CONSUMER) {
    filter = "TYPE eq 'MOTOR' or (TYPE eq 'MOTORBIKE' and MAKE ne 'HARLEY-DAVIDSON')";   
  }
  if (productGroup === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) {
    return {
      count: true,
      skip: 0,
      top: 20,
      searchMode: 'any',
      queryType: 'simple',
      filter: "TYPE eq 'CARAVAN'",
      search: query,
    };
  }

  return {
    count: true,
    skip: 0,
    top: 20,
    searchMode: 'all',
    queryType: 'full',
    filter: filter,
    search: query
      .trim()
      .split(' ')
      .filter((x) => x)
      .map((x) => `${x.replace(/[\:\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')}*`)
      .join(' AND '),
    orderby: 'YEAR desc',
  };
};

interface IVinSearchRequest {
  requestId: string;
  vin: string;
}
const createVinSearchRequest = (query: string): IVinSearchRequest =>{
  return {
    requestId: uuidv4(),
    vin: query,     
  };
}
interface IRegoPlateSearchRequest {
  requestId: string;
  plate: string;
  state: string;
}
const createRegoPlateSearchRequest = (plate: string, state: string): IRegoPlateSearchRequest =>{
  return {
    requestId: uuidv4(),
    plate: plate,
    state: state,
  };
}

export enum VehicleSearchStatus {
  ResultNotFound = "ResultNotFound",
  DataError = "DataError",
  ResultFound = "ResultFound",
}

const isLocalDev = process.env.NODE_ENV === "development";
const useLocalVehicleSearchApi = isLocalDev && !!process.env.IQWEB_VEHICLE_SEARCH_API_URL;

class GlassGuideApi {
  static apiBaseUrl = useLocalVehicleSearchApi
    ? '/localdev-api-proxy/vehicle-search/'
    : Api.glassGuideBase
  
  static lookupNvics(query, productGroup): Promise<GlassGuideSearchResponse> {
    const url = `${this.apiBaseUrl}searchbyyearmakemodel`;
    let request = createGlassGuideRequest(query, productGroup);
    request.requestId = uuidv4();     

    return Api.post(url, request)
      .then((response) => {
        response.data.value = this.toUpperCaseProps(response.data.value);       
        return response.data;    
      });
  }
  
  static toUpperCaseProps(objects: any[]): any[] {
    const array: any[] = [];
    for (const obj of objects) {
      for (const key of Object.keys(obj)) {
        Object.assign(obj, { [key.toUpperCase()]: obj[key] });
        delete obj[key];
      }
      array.push(obj);
    }
    return array;
  }

  static searchByVin(query: any): Promise<any> {    
    return Api.post(`${this.apiBaseUrl}searchbyvin`, createVinSearchRequest(query));
  }
  
  static searchByRegoPlate(plate: string, state: string): Promise<any> {
    return Api.post(`${this.apiBaseUrl}searchbyregoplate`, createRegoPlateSearchRequest(plate, state));
  }
}

export default GlassGuideApi;
