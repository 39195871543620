/* eslint-disable no-shadow */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, hashHistory } from 'react-router';
import { Actions, Location } from 'history';
import { RouteHook } from 'react-router/lib/Router';
import MotorSubmissionWebComponent from './web-component';
import { ISession } from '../../interfaces';
import { MotorDeclarationsState } from '../../reducers/motorDeclarationsReducer';
import { resetMotorDeclarations } from '../../actions/motorDeclarationsActions';

import { bindActionCreators } from 'redux';
import * as calculatorsActions from '../../actions/calculatorsActions';
import { Overlay } from '../../utils';
import rateQuotePage from '../rateQuotePage';

interface MotorSubmissionPageState {
  isStable: boolean;
  pendingLocation?: Location;
}

interface MotorSubmissionPageProps {
  rateQuote: any;
  adjustmentProfileID: number | null;
  productType: string;
  isResidencyAndAgeConfirmed: boolean;
  resetMotorDeclarations: () => any;
}

const initialState: MotorSubmissionPageState = {
  isStable: true,
};

const routeMapping = {
  ConsumerMotor: '/motor',
  ConsumerCaravan: '/caravan',
  CommercialMotor: '/commercial',
  NovatedLease: '/novated',
};

const getRoute = (productType) => routeMapping[productType];

const onCancel = (router) => router.push('/applications');
const onComplete = (router, id: string) => router.push(`/applications/${id}`);
const onIdChanged = (newId: string, productType: string) => hashHistory.push(`/applications/submit${getRoute(productType)}/${newId}`);
const onCancelNavigation = (setState) => setState((state) => ({ ...state, pendingLocation: undefined }));
const onStableChange = (setState, router, isStable: boolean, pendingLocation, resetMotorDeclarations: () => any) => {
  setState((state) => ({
    ...state,
    isStable,
  }));

  if (isStable && pendingLocation !== undefined) {
    resetMotorDeclarations();

    // if stable and there is a pending location, then go to that location
    router.replace(pendingLocation);
  }
};
// eslint-disable-next-line no-shadow
const routerWillLeave = (state: MotorSubmissionPageState, setState, resetMotorDeclarations: () => any): RouteHook => (nextLocation?: Location) => {
  if (nextLocation && !state.isStable) {
    // don't allow navigation yet, wait until component is in a stable state
    // eslint-disable-next-line no-shadow
    setState((state: MotorSubmissionPageState) => ({
      ...state,
      pendingLocation: nextLocation,
    }));
    return false;
  } if (!nextLocation && !state.isStable) {
    // trying to close/navigate away from the site
    // have to return a message (cannot perform async action)
    return 'You have unsaved changes, are you sure you want to exit?';
  }

  resetMotorDeclarations();
  return null; // don't do anything, allow navigation
};

export const MotorSubmissionPage = (props: RouteComponentProps<{ id?: string; }, {}> & MotorSubmissionPageProps & Location) => {
  const [state, setState] = React.useState(initialState);

  if (!props.params.id && !props.isResidencyAndAgeConfirmed && !props.location.query.rateQuoteId) {
    props.router.push('/applications');
  }

  if (props.location.query.rateQuoteId && !props.rateQuote) {
    props.router.push(`/calculators/motor-prequalification/${props.location.query.rateQuoteId}`);
  }

  const isResidencyAndAgeConfirmed = props.params.id ? true : props.isResidencyAndAgeConfirmed;

  // register leave hook
  React.useEffect(() => props.router.setRouteLeaveHook(props.route, routerWillLeave(state, setState, props.resetMotorDeclarations)), [props.router, state]);

  return (
    <div className="no-blur" style={{ marginTop: '-8px' }}>
      <MotorSubmissionWebComponent
        rateQuote={!!props.rateQuote ? JSON.stringify(props.rateQuote) : ''}
        applicationId={props.params.id}
        isLeaving={!!state.pendingLocation}
        adjustmentProfileID={props.adjustmentProfileID}
        productType={props.productType}
        isResidencyAndAgeConfirmed={isResidencyAndAgeConfirmed}
        onCancel={() => onCancel(props.router)}
        onComplete={(id) => onComplete(props.router, id)}
        onStableChange={(s) => onStableChange(setState, props.router, s, state.pendingLocation, props.resetMotorDeclarations)}
        onIdChanged={(id) => onIdChanged(id, props.productType)}
        onCancelNavigation={() => onCancelNavigation(setState)}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  rateQuote: state.calculators.prequal,
  adjustmentProfileID: state.session.details.adjustmentProfileID,
  isResidencyAndAgeConfirmed: state.motorDeclarations.ageConfirmed && state.motorDeclarations.residencyStatusConfirmed,
});

const mapDispatchToProps = (dispatch) => ({
  resetMotorDeclarations: () => dispatch(resetMotorDeclarations()),
  actions: bindActionCreators({
    ...calculatorsActions
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MotorSubmissionPage));
