import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { hashHistory, withRouter } from 'react-router';
import { Form } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';
import * as Guid from 'guid';

import Event3rdParty from '../../helpers/event3rdParty';
import { Overlay } from '../../utils';

import Mask from '../../helpers/mask';

import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';
import * as functions from '../../constants/functions';

import * as referenceActions from '../../actions/referenceActions';
import * as calculatorsActions from '../../actions/calculatorsActions';
import * as storageActions from '../../actions/storageActions';

import ComServiceAbilityHeader from './comServiceAbilityHeader';
import ComServiceAbilityApplicants from './comServiceAbilityApplicants';
import ComServiceAbilityCompanies from './comServiceAbilityCompanies';
import ComServiceAbilityExpenses from './comServiceAbilityExpenses';
import ComServiceAbilityProducts from './comServiceAbilityProducts';
import ComServiceAbilityResult from './comServiceAbilityResult';
import ComServiceAbilityPrint from './comServiceAbilityPrint';

import CalculatorPane from '../../components/calculatorPane';
import CalculatorModal from '../../components/calculatorModal';
import CalculatorPrompt from '../../components/calculatorPrompt';
import { ProfileType } from '../../actions/prequalActions';

const Moment = require('moment');

const investmentPurpose = 1;
export const surplusOptionsResidential = [0, 100, 200];

export class ComServiceAbilityPage extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      init: false,
      result: undefined,
      data: {
        applicants: [],
      },
      modal: false,
      prompt: false,
      profileName: null,
      profileId: Guid.create().value,
      sessionId: Guid.create().value,
      lastUpdated: null,
      hasChanged: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.setProcessing = this.setProcessing.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setPrompt = this.setPrompt.bind(this);
    this.setPrequal = this.setPrequal.bind(this);
    this.savePrequal = this.savePrequal.bind(this);
    this.dismissPrequal = this.dismissPrequal.bind(this);
    this.setChange = this.setChange.bind(this);
    this.prepareComLoanDetails = this.prepareComLoanDetails.bind(this);
    this.handleApplicantsChange = this.handleApplicantsChange.bind(this);
    this.handleWorkshopWithBdm = this.handleWorkshopWithBdm.bind(this);
  }

  handleApplicantsChange() {
    if (this.form && this.form.formsyForm.current) {
      let data = this.form.formsyForm.current.getModel();
      data = this.prepareSubmit(data);
      this.setState({ data });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.id) {
      this.props.actions.getPrequal(this.props.params.id)
        .then((result) => {
          if (result.data) {
            let data = JSON.parse(result.data.value);
            data = typeof data == 'string' ? JSON.parse(data) : data;
            
            // Map the previous saved service prequalifications to the new structure
            if (data.multiLoanDetails) {
              data = functions.mapMultiLoanDetails(data);
            }

            // Map the previous saved applicants to the new structure
            if (data.applicants && data.applicantRelation) {
              data = functions.mapApplicants(data);
            }

            // Map the previous self employed gross income to current financial year income
            if (data.applicants && data.applicants.some((applicant) => applicant.incomes && applicant.incomes.some((income) => income.type == 'selfEmployed' && income.amount))) {
              data = functions.mapSelfEmployed(data);
            }

            // Map the previous company gross income to current financial year income
            if (data.applicants && data.applicants.some((applicant) => applicant.incomes && applicant.incomes.some((income) => income.type == 'company' && income.amount))) {
              data = functions.mapCompanyIncome(data);
            }

            // Map the previous saved companies to the new structur
            if (data.companies) {
              data = functions.mapBusiness(data);
            }

            this.setState({
              init: true,
              profileName: result.data.profileName,
              profileId: result.data.profileId,
              lastUpdated: result.data.lastUpdated,
              data,
            }, this.setPrequal);
          } else {
            this.setState({
              init: true,
            }, () => this.handleApplicantsChange());
          }
        });
    } else {
      this.setState({
        init: true,
      }, () => this.handleApplicantsChange());
    }
  }

  componentDidMount() {
    this.props.actions.getCommOtherIncomeTypes();

    this.props.router.setRouteLeaveHook(this.props.route, (route) => {
      if (this.state.hasChanged) {
        this.setPrompt(route);
        return false;
      }
      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname) {
      this.setState({
        result: undefined,
        data: undefined,
        profileName: null,
        profileId: Guid.create().value,
        sessionId: Guid.create().value,
        lastUpdated: null,
        hasChanged: false,
      });

      this.form.formsyForm.current.reset();
    }
  }

  getServiceabilityPageType() {
    return 'commercial-serviceability';
  }

  setChange(hasChanged) {
    this.setState({
      hasChanged,
    });
  }

  setProfile(data) {
    this.setState({
      profileName: data.name,
    }, this.savePrequal);
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  setModal(modal) {
    this.setState({
      modal,
    });
  }

  setPrompt(prompt) {
    this.setState({
      prompt,
    });
  }

  setPrequal() {
    if (this.state.data && (this.state.data.applicants || this.state.data.companies)) {
      const data = Mask.flatten(this.state.data);

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          let newKey = key.toString();
          newKey = newKey.replace(/\.(\d+)\./g, '[$1]');
          newKey = newKey.replace('incomes', '[incomes]');
          newKey = newKey.replace('splitLoanDetails', '[splitLoanDetails]');
          newKey = newKey.replace(/](?!.*?])/, '].');

          if (key != newKey) {
            data[newKey] = data[key];
            delete data[key];
          }
        }
      }

      this.form.formsyForm.current.reset(data);
      setTimeout(() => {
        if (!(this.form.formsyForm.current.inputs.filter((input) => !input.isValid()).length)) {
          this.handleSubmit(this.form.formsyForm.current.getModel(), true);
        }
      });
    }
  }

  savePrequal(route) {
    if (!this.state.profileName) {
      this.setModal(true);
    } else {
      this.setProcessing('Saving prequalification...');

      const profileId = this.state.profileId || Guid.create().value;
      const profileData = { ...(this.state.data || {}), ...this.form.formsyForm.current.getModel() };

      // 3rd party
      Event3rdParty.send('save-serviceability');

      this.props.actions.savePrequal(this.state.profileName, JSON.stringify(profileData), profileId, ProfileType.CommercialServiceability)
        .then(() => {
          Notify.show(messages.MESS_SUCCESS, 'success');
          this.setState({
            profileId,
            hasChanged: false,
            lastUpdated: Moment().format(),
          }, () => {
            if (route) {
              hashHistory.push(route);
            }
          });
        })
        .catch()
        .then(() => {
          this.setProcessing(false);
        });
    }
  }

  dismissPrequal() {
    this.setChange(false);
  }

  prepareComLoanDetails(data) {
    data.assetClassType = 'Commercial';
    data.maxBorrowingCapacitySurplus = surplusOptionsResidential;

    if (data.multiLoanDetails) {
      data.multiLoanDetails.forEach((loan, loanIndex) => {
        data.multiLoanDetails[loanIndex].purchaseAmount = parseInt(loan.purchaseAmount.toString().replace(/[^0-9.]/g, ''));
        data.multiLoanDetails[loanIndex].riskGrade = 'AAA';
        data.multiLoanDetails[loanIndex].term = parseInt(loan.term);
        data.multiLoanDetails[loanIndex].lvr = parseFloat(loan.lvr);
        data.multiLoanDetails[loanIndex].residualAmount = 0;

        if (loan.splitLoanDetails) {
          loan.splitLoanDetails.forEach((split, splitIndex) => {
            data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].loanAmount = parseInt(split.loanAmount.toString().replace(/[^0-9.]/g, ''));
            data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].repayment = parseInt(split.repayment);
            data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].purpose = parseInt(split.purpose);
            data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].structure = parseInt(split.structure);
            data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].interestRate = parseFloat((split.interestRate / 100).toFixed(4));

            if (split.purpose === investmentPurpose && split.ownerships) {
              split.ownerships.forEach((ownership, ownershipsIndex) => {
                data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].ownerships[ownershipsIndex].owns = parseFloat((ownership.owns / 100).toFixed(4));
              });
            }
          });
        }
      });
    }

    return data;
  }

  prepareSubmit(data) {
    if (!data.expenses) {
      data.expenses = {};
    }

    data.sessionId = this.state.sessionId;
    data.profileId = this.state.profileId;
    data.submissionId = Guid.create().value;

    data.expenses.otherInvestmentLoanAmount = 0;
    data.expenses.postSettlementFinancialCommitments = 0;
    data.expenses.totalCreditCardLimit = 0;

    data = this.prepareComLoanDetails(data);
    if (data.liabilities) {
      data.liabilities.forEach((liability, index) => {
        switch (liability.type) {
          case 'credit': {
            data.liabilities[index].amount = parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            data.expenses.totalCreditCardLimit += parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            break;
          }
          case 'homeloan':
          case 'investment': {
            data.liabilities[index].amount = parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            data.liabilities[index].balance = parseInt(liability.balance.toString().replace(/[^0-9.]/g, ''));

            data.expenses.postSettlementFinancialCommitments += parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            data.expenses.otherInvestmentLoanAmount += parseInt(liability.balance.toString().replace(/[^0-9.]/g, ''));

            if (liability.ownerships) {
              liability.ownerships.forEach((ownership, ownershipIndex) => {
                data.liabilities[index].ownerships[ownershipIndex].owns = parseFloat((ownership.owns / 100).toFixed(4));
              });
            }
            break;
          }
          case 'loan':
          case 'rent':
          case 'other': {
            data.liabilities[index].amount = parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            data.expenses.postSettlementFinancialCommitments += parseInt(liability.amount.toString().replace(/[^0-9.]/g, ''));
            break;
          }
        }
      });
    }

    if (data.applicants) {
      data.applicants.forEach((applicant, index) => {
        if (applicant.inRelationshipWithId == applicant.id) {
          data.applicants[index].inRelationshipWithId = '';
        }

        data.applicants[index].expenses = parseInt(data.applicants[index].expenses.toString().replace(/[^0-9.]/g, ''));
        data.applicants[index].dependants = parseInt(data.applicants[index].dependants);

        data.applicants[index].payg = [];
        data.applicants[index].otherIncome = [];
        data.applicants[index].selfEmployed = [];
        data.applicants[index].companyIncome = [];

        if (applicant.incomes) {
          applicant.incomes.forEach((income) => {
            const info = {};

            switch (income.type) {
              case 'payg': {
                info.frequency = parseInt(income.frequency);
                info.amount = parseFloat(income.amount.toString().replace(/[^0-9.]/g, ''));
                info.other = parseFloat((income.other || 0).toString().replace(/[^0-9.]/g, ''));
                data.applicants[index].payg.push(info);
                break;
              }
              case 'other': {
                info.frequency = parseInt(income.frequency);
                info.amount = parseFloat(income.amount.toString().replace(/[^0-9.]/g, ''));
                info.incomeOtherTypeId = parseInt(income.incomeOtherTypeId);
                info.incomeOtherGroupId = parseInt(income.incomeOtherGroupId);
                data.applicants[index].otherIncome.push(info);
                break;
              }
              case 'selfEmployed': {
                info.currentAmount = parseFloat(income.currentAmount.toString().replace(/[^-0-9.]/g, ''));
                info.previousAmount = 0;
                info.currentDepreciation = parseFloat(income.currentDepreciation.toString().replace(/[^0-9.]/g, ''));
                info.previousDepreciation = 0;
                info.currentInterestPaid = parseFloat(income.currentInterestPaid.toString().replace(/[^0-9.]/g, ''));
                info.previousInterestPaid = 0;
                data.applicants[index].selfEmployed.push(info);
                break;
              }
            }
          });

          delete applicant.incomes;
        }
      });
    } else {
      data.applicants = [];
    }

    if (data.companies) {
      data.companies.forEach((company, index) => {
        data.companies[index].profit = parseFloat(company.profit.toString().replace(/[^-0-9.]/g, ''));
        data.companies[index].amortisation = parseFloat(company.amortisation.toString().replace(/[^0-9.]/g, ''));
        data.companies[index].interest = parseFloat(company.interest.toString().replace(/[^0-9.]/g, ''));
        data.companies[index].depreciation = parseFloat(company.depreciation.toString().replace(/[^0-9.]/g, ''));
        data.companies[index].otheraddback = parseFloat(company.otheraddback.toString().replace(/[^0-9.]/g, ''));
        data.companies[index].otherdeduction = parseFloat(company.otherdeduction.toString().replace(/[^0-9.]/g, ''));
      });
    } else {
      data.companies = [];
    }

    return data;
  }

  handleSubmit(data, silent) {
    data = this.prepareSubmit(data);

    if (!silent) {
      this.setProcessing('Calculating serviceability...');
    }

    // 3rd party
    Event3rdParty.send(this.getServiceabilityPageType());

    this.props.actions.calculateServiceability(data)
      .then((result) => {
        if (data.multiLoanDetails) {
          data.multiLoanDetails.forEach((loan, loanIndex) => {
            if (loan.splitLoanDetails) {
              loan.splitLoanDetails.forEach((split, splitIndex) => {
                data.multiLoanDetails[loanIndex].splitLoanDetails[splitIndex].interestRate = parseFloat((split.interestRate * 100).toFixed(4));
              });
            }
          });
        }

        this.setState({
          result: result.data,
          data,
        });
      })
      .catch(() => {})
      .then(() => {
        this.setProcessing(false);
        if (silent) {
          this.setChange(false);
        }
      });
  }

  handleInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  handleChange() {
    this.setChange(true);
  }

  handleWorkshopWithBdm(requestData, resultData) {
    var workshopWithBdmData = { 
      serviceabilityRequest: requestData,
      serviceabilityResult: resultData,
      originatorId: this.props.session.details.introducerId,
    };

    this.props.actions.workshopWithBdm(workshopWithBdmData)
      .then(() => {
        this.setState({
          result: workshopWithBdmData.serviceabilityResult,
          data: workshopWithBdmData.serviceabilityRequest,
        });
        Notify.show(messages.MESS_SUCCESS, 'success');
      })
      .catch(() => {});
  }

  /**
   * Render
   */
  render() {
    if (!this.state.init) {
      return null;
    }

    return (
      <div>
        <CalculatorModal visible={this.state.modal} setModal={this.setModal} setProfile={this.setProfile} />

        <CalculatorPrompt visible={this.state.prompt} setPrompt={this.setPrompt} savePrequal={this.savePrequal} dismissPrequal={this.dismissPrequal} />

        <Row>
          <Col sm={12} className="main-panel serviceability-print">
            {
              (() => {
                if (this.state.processing) {
                  return (
                    <Overlay>{this.state.processing}</Overlay>
                  );
                }
              })()
            }

            <Form
              onValidSubmit={(data) => this.handleSubmit(data, false)}
              onInvalidSubmit={this.handleInvalidSubmit}
              onChange={this.handleChange}
              layout="vertical"
              ref={(form) => { { this.form = form; } }}
            >

              <div className="panel panel-default">
                <div className="panel-body p-h">
                  <ComServiceAbilityHeader />

                  <ComServiceAbilityCompanies
                    data={this.state.data}
                    OnCompaniesChanged={this.handleApplicantsChange}
                  />

                  <ComServiceAbilityApplicants
                    data={this.state.data}
                    OnApplicantsChanged={this.handleApplicantsChange}
                  />

                  <ComServiceAbilityExpenses
                    data={this.state.data}
                  />

                  <ComServiceAbilityProducts
                    data={this.state.data}
                    id={this.state.profileId}
                  />

                  <ComServiceAbilityPrint
                    data={this.state.data}
                    result={this.state.result}
                  />
                  
                  <ComServiceAbilityResult
                    result={this.state.result}
                    data={this.state.data}
                    session={this.props.session}
                    savePrequal={this.savePrequal}
                    workshopWithBdm={this.handleWorkshopWithBdm}
                    router={this.props.router}
                  />


                </div>
              </div>

            </Form>

          </Col>
        </Row>
      </div>
    );
  }
}

ComServiceAbilityPage.propTypes = {
  actions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
    calculators: state.calculators,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions, ...calculatorsActions, ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ComServiceAbilityPage));
