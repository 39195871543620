import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';
import ApplicationSecurityResiValuation from './applicationSecurityResiValuation';

export class ApplicationSecurityResi extends React.Component {
  constructor(props) {
    super(props, {});
    this.state = {
    };
  }

  render() {
    return (
          <div className="card card-border">
            <Icon className="card-icon" glyph="home" />
            {
                this.props.application.resiSecurity.map((security) => (
                    <div key={security.addressID} className={`security security-${this.props.application.resiSecurity.length == 1 ? 'single' : 'multiple'}`}>
                      <h4>
                      {security.unitNbr}
                      {' '}
                      {security.streetNbr}
                      {' '}
                      {security.streetName}
                      {' '}
                      {security.streetType}
                      </h4>
                      <p className="mute">
                        {security.postcode}
                        {', '}
                        {security.suburb}
                        <br />
                        {security.state}
                        {', '}
                        {security.country}
                      </p>

                      <ApplicationSecurityResiValuation valuationStatus={this.props.application.valuationStatus} security={security} />
                    </div>
                ))
            }
          </div>
    );
  }
}

ApplicationSecurityResi.propTypes = {
  application: PropTypes.any,
};

export default ApplicationSecurityResi;
