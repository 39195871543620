import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { notify as Notify } from 'react-notify-toast';


import { hashHistory, RouteComponentProps } from 'react-router';
import { Form, Input } from 'formsy-react-components';
import * as errors from '../../constants/errors';
import Event3rdParty from '../../helpers/event3rdParty';
import { Overlay } from '../../utils';

import * as applicationsActions from '../../actions/applicationsActions';

import ApplicationInfo from '../applicationPage/applicationInfo';
import ApplicationUpdateButton from './applicationUpdateButton';
import ApplicationUpdateProduct from './applicationUpdateProduct';
import ApplicationUpdateVehicle from './applicationUpdateVehicle';
import ApplicationUpdateInsurance from './applicationUpdateInsurance';

import LoadingPane from '../../components/loadingPane';

import Mask from '../../helpers/mask';
import { ISession } from '../../interfaces';
import { PRODUCTGROUPS } from '../../constants/types';
import { ApplicationUpdateApplicants } from './applicationUpdateAplicants';
import { ApplicationUpdateDetails } from './applicationUpdateDetails';

/**
 * New stuff
 */
const Moment = require('moment');

type ApplicationUpdatePageProps = {
  actions: any,
  application: any,
  session: ISession,
} & RouteComponentProps<{ applicationID: number }, {}>

type ApplicationUpdatePageState = {
  application: any,
  processing: boolean,
}

export class ApplicationUpdatePage extends React.Component<ApplicationUpdatePageProps, ApplicationUpdatePageState> {
  form: Form | null;

  constructor(props) {
    super(props);
    
    this.state = {
      application: null,
      processing: false,
    };

    this.getApplication = this.getApplication.bind(this);
    this.setApplication = this.setApplication.bind(this);
    this.pollApplication = this.pollApplication.bind(this);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  componentDidMount() {
    this.getApplication(this.props.params.applicationID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.application.active != prevProps.application.active) {
      setTimeout(this.setApplication);
    }
  }

  onValidSubmit(data) {
    this.setProcessing(true);

    // removing insurance in total resulting in server error - hence adding emtpty object
    data.insuranceSummary = {};
    
    if (!data.motorInsuranceInterest) data.motorInsuranceInterest = {
      lpiInterests: [],
      veiInterest: null
    };

    if (data.manualEdits && data.manualEdits.selectedEditOption) {
      delete data.manualEdits.selectedEditOption;
    }

    data.motorPurpose.purchasePrice = parseFloat(Mask.clean(data.motorPurpose.purchasePrice));
    data.motorPurpose.deposit = parseFloat(Mask.clean(data.motorPurpose.deposit));
    data.motorPurpose.netTradeIn = parseFloat(Mask.clean(data.motorPurpose.netTradeIn));
    data.motorPurpose.balloonResidual = parseFloat(Mask.clean(data.motorPurpose.balloonResidual));
    data.motorPurpose.applicationFee = parseFloat(Mask.clean(data.motorPurpose.applicationFee));
    data.motorPurpose.comprehensiveInsuranceAmount = parseFloat(Mask.clean(data.motorPurpose.comprehensiveInsuranceAmount));
    data.motorPurpose.extendedWarranty = parseFloat(Mask.clean(data.motorPurpose.extendedWarranty));

    data.motorPurchaseVehicle.vehicleRegistrationExpiry = data.motorPurchaseVehicle.vehicleRegistrationExpiry ? Moment(data.motorPurchaseVehicle.vehicleRegistrationExpiry, 'MM/YYYY').format() : null;
    data.motorPurchaseVehicle.registrationNumber = data.motorPurchaseVehicle.vehicleRegistrationNumber;
    data.motorPurchaseVehicle.registrationState = data.motorPurchaseVehicle.vehicleRegistrationState;
    // MOTOR_NOVATED_LEASE
    if (data.motorProduct.productGroupId === PRODUCTGROUPS.MOTOR_NOVATED_LEASE){
      data.motorPurpose.accessories = parseFloat(Mask.clean(data.motorPurpose.accessories));
      data.motorPurpose.vehiclePrice = parseFloat(Mask.clean(data.motorPurpose.vehiclePrice));
      data.motorPurpose.onRoadCost = parseFloat(Mask.clean(data.motorPurpose.onRoadCost));
      data.motorPurpose.luxuryCarTax = parseFloat(Mask.clean(data.motorPurpose.luxuryCarTax));
      data.motorPurpose.claimableGST = parseFloat(Mask.clean(data.motorPurpose.claimableGST));
      data.motorPurpose.nonClaimableGST = parseFloat(Mask.clean(data.motorPurpose.nonClaimableGST));
      data.motorPurpose.residualValue = parseFloat(Mask.clean(data.motorPurpose.residualValue));
      data.motorPurpose.services = parseFloat(Mask.clean(data.motorPurpose.services));
      data.motorPurpose.annualKilometres= parseFloat(Mask.clean(data.motorPurpose.annualKilometres));
      data.motorPurpose.purchasePrice = this.calculatePurchasePrice(data);
    }

    if (data.motorPurchaseVehicle && !!data.motorPurchaseVehicle.nvic) {
      if (data.motorProduct.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) {
        data.motorPurchaseVehicle.nvic = `CV-${data.motorPurchaseVehicle.nvic}`;
      }
      if (data.motorPurchaseVehicle.isMotorbike) {
        data.motorPurchaseVehicle.nvic = `MB-${data.motorPurchaseVehicle.nvic}`;
      }
      data.motorPurchaseVehicle.preApproval = false;
    }

    if (data.motorProduct.productGroupId !== PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) {
      data.motorPurchaseVehicle.odometer = parseInt(Mask.clean(data.motorPurchaseVehicle.odometer));
    }
    
    this.props.actions.saveApplication(data)
      .then(() => {
        this.pollApplication(1);
      })
      .catch((result) => {
        setTimeout(() => {
          this.setProcessing(false);

          if (result.response.status == 409) {
            return Notify.show('Another assessment is already in progress. Please try again...', 'error', 3000);
          }

          return Notify.show(result.response.data.message || errors.ERR_DEFAULT, 'error', 3000);
        }, 1000);
      });
  }

  calculatePurchasePrice(data){
    return parseFloat(Mask.clean(data.motorPurpose.vehiclePrice)) + 
      parseFloat(Mask.clean(data.motorPurpose.accessories)) + 
      parseFloat(Mask.clean(data.motorPurpose.onRoadCost)) +
      parseFloat(Mask.clean(data.motorPurpose.luxuryCarTax));
  }

  onInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  getApplication(applicationID) {

    this.props.actions.getApplications()
      .then(() => {
        this.props.actions.getApplication(applicationID);

        if (this.props.application.active) {
          this.setApplication();

          if (!this.props.application.active.applicationState) {
            hashHistory.push(`/applications/${this.props.application.active.applicationNumber}`);
          }
        }
      });
  }

  setApplication() {
    const application = JSON.parse(JSON.stringify(this.props.application.active));

    if (!application.personApplicants) {
      application.personApplicants = [];

      (application.applicants || [])
      .filter(applicant => applicant.type == "Person")
      .forEach(applicant => {
        const email = applicant.contact.email != null && applicant.contact.email.length > 0 ? applicant.contact.email[0] : "";
        const mobileNumber = applicant.contact.mobile != null && applicant.contact.mobile.length > 0 ? applicant.contact.mobile[0] : "";

        application.personApplicants.push({
          applicantId: applicant.applicantId,
          mobileNumber: mobileNumber,
          email: email,
          name: applicant.name
        });
      });
    }

    if (!application.manualEdits) {
      application.manualEdits = {};
    }

    if (!application.motorPurchaseVehicle) {
      application.motorPurchaseVehicle = {
        preApproval: true,
        isUsed: false,
        year: Moment().format('YYYY'),
        makeId: 0,
        make: '',
        modelId: 0,
        model: '',
        bodyTypeId: 0,
        transmissionTypeId: 0,
        odometer: '0',
        vehicleRegistrationNumber: '',
        vehicleRegistrationExpiry: '',
        vehicleColour: '',
        vin: '',
        engineNumber: '',
        nvic: '',
        vendorName: '',
      };
    } else {
      if (application.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) {
        application.motorPurchaseVehicle.nvic = application.motorPurchaseVehicle.nvic ? application.motorPurchaseVehicle.nvic.replace('CV-', '') : '';
      } 
      // if NVIC starts with 'MB-' then it is motorbike. Remove the prefeix to search for vehicle as glass guide do not have data with 'MB-' prefix
      if (application.motorPurchaseVehicle.nvic?.startsWith('MB-')) {  
        application.motorPurchaseVehicle.nvic = application.motorPurchaseVehicle.nvic.replace('MB-', '');
      }
      application.motorPurchaseVehicle.vehicleRegistrationExpiry = application.motorPurchaseVehicle.vehicleRegistrationExpiry ? Moment(application.motorPurchaseVehicle.vehicleRegistrationExpiry).format('MM/YYYY') : '';
      application.motorPurchaseVehicle.preApproval = false;
    }

    application.motorPurchaseVehicle.vendorType = application.motorPurchaseVehicle.vendorTypeID || 1;

    if (!application.motorInsuranceInterest) {
      application.motorInsuranceInterest = {
        lpiInterests: [],
        veiInterest: null,
        interestedInLPI: false,
        interestedInVEI: false,
      };
    }

    if (!application.motorInsuranceInterest.lpiInterests.length && application.applicants?.length > 0) {
      for (let index = 0; index < application.applicants.length; index++) {
        const applicant = application.applicants[index];

        if (applicant.type.toLowerCase() === 'person' && ((applicant.status !== undefined && applicant.status.toLowerCase() === 'borrower') || applicant.status === undefined)) {
          application.motorInsuranceInterest.lpiInterests.push({
            applicantId: applicant.applicantId,
            productNumber: null,
            paymentMethodId: 3, // Direct Debit
          });
        }
      }
      application.motorInsuranceInterest.interestedInLPI = false;
    } else if (application.applicants?.length > 0) {
      for (let index = 0; index < application.applicants.length; index++) {
        const applicant = application.applicants[index];

        if (!application.motorInsuranceInterest.lpiInterests.map(x => x.applicantId).includes(applicant.applicantId)) {
          if (applicant.type.toLowerCase() === 'person' && ((applicant.status !== undefined && applicant.status.toLowerCase() === 'borrower') || applicant.status === undefined)) {
            application.motorInsuranceInterest.lpiInterests.push({
              applicantId: applicant.applicantId,
              productNumber: 0,
              paymentMethodId: 3, // Direct Debit
            });
          }
        }
        application.motorInsuranceInterest.interestedInLPI = true;
      }
    }

    if (!application.motorInsuranceInterest.veiInterest) {
      application.motorInsuranceInterest.veiInterest = {
        productNumber: null,
        paymentMethodId: 3, // Direct Debit
      };
      application.motorInsuranceInterest.interestedInVEI = false;
    } else if (application.motorInsuranceInterest.veiInterest?.productNumber != null) {
      application.motorInsuranceInterest.interestedInVEI = true;
    }

    this.setState({
      application,
    });


    if (this.form) {
      const opts = { decimal: 2 };
      application.motorPurpose.purchasePrice = Mask.number(application.motorPurpose.purchasePrice, opts);
      application.motorPurpose.deposit = Mask.number(application.motorPurpose.deposit, opts);
      application.motorPurpose.netTradeIn = Mask.number(application.motorPurpose.netTradeIn, { decimal: 2, negative: true });
      application.motorPurpose.balloonResidual = Mask.number(application.motorPurpose.balloonResidual || 0, opts);
      application.motorPurpose.applicationFee = Mask.number(application.motorPurpose.applicationFee, opts);
      application.motorPurpose.comprehensiveInsuranceAmount = Mask.number(application.motorPurpose.comprehensiveInsuranceAmount, opts);
      application.motorPurpose.extendedWarranty = Mask.number(application.motorPurpose.extendedWarranty, opts);

      application.motorPurchaseVehicle.odometer = Mask.number(application.motorPurchaseVehicle.odometer);

      if (this.form.formsyForm.current !== null) {
        this.form.formsyForm.current.reset(Mask.flatten(application));
      }
      if (application.motorProduct.productGroupId === PRODUCTGROUPS.MOTOR_NOVATED_LEASE) {
        
        application.motorPurpose.accessories = Mask.number(application.motorPurpose.accessories, opts);
        application.motorPurpose.vehiclePrice = Mask.number(application.motorPurpose.vehiclePrice, opts);
        application.motorPurpose.onRoadCost = Mask.number(application.motorPurpose.onRoadCost, opts);
        application.motorPurpose.luxuryCarTax = Mask.number(application.motorPurpose.luxuryCarTax, opts);
        application.motorPurpose.claimableGST = Mask.number(application.motorPurpose.claimableGST);
        application.motorPurpose.nonClaimableGST = Mask.number(application.motorPurpose.nonClaimableGST);
        application.motorPurpose.residualValue = Mask.number(application.motorPurpose.residualValue, opts);
        application.motorPurpose.services = Mask.number(application.motorPurpose.services, opts);
        application.motorPurpose.annualKilometres= Mask.number(application.motorPurpose.annualKilometres, opts);
      }
    }
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  pollApplication(attempt) {
    this.props.actions.pollApplication(this.state.application.applicationNumber, attempt)
      .then((result) => {
        if (result.response.status == 204) {
          return setTimeout(() => {
            this.pollApplication(attempt + 1);
          }, 1000);
        }

        // 3rd party
        Event3rdParty.send('update-indicative');

        this.setProcessing(false);
        this.getApplication(this.props.params.applicationID);
        this.props.actions.updateApplication(result.response.data);

        const hasVeiDeclinedResult = result.response.data.insuranceAssessments.filter((r) => r.status == 'Decline' && r.productGroupId == 17200).length > 0;
        if (hasVeiDeclinedResult) {
          Notify.show('VEI Declined - Customer ineligible for selected cover, please choose another cover option', 'error', 7000);
        } else {
          Notify.show('Your changes have been saved!', 'success', 3000);
        }
      })
      .catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error', 3000);
        this.setProcessing(false);
      });
  }

  render() {
    if (!this.state.application || !this.props.session.details) {
      return (
        <LoadingPane label="application" />
      );
    }

    return (
      <div>
        <ApplicationInfo application={this.state.application} update={false} processing={this.state.processing} />

        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Saving changes...</Overlay>
              );
            }
          })()
        }


        <Row>
          <Col sm={12} className="p-h">
            <Form
              layout="vertical"
              ref={(form) => this.form = form}
              onValidSubmit={this.onValidSubmit}
              onInvalidSubmit={this.onInvalidSubmit}
            >
              <Input
                name="applicationNumber"
                type="hidden"
              />

              <div className="panel panel-default">
                <ApplicationUpdateApplicants params={this.props.params} application={this.state.application} formsy={this.form ? this.form.formsyForm.current : null} />
                <ApplicationUpdateProduct adjustmentProfileID={this.props.session?.details?.adjustmentProfileID} params={this.props.params} application={this.state.application} formsy={this.form ? this.form.formsyForm.current : null} />
                <ApplicationUpdateVehicle params={this.props.params} application={this.state.application} formsy={this.form ? this.form.formsyForm.current : null} />
                <ApplicationUpdateInsurance params={this.props.params} application={this.state.application} formsy={this.form ? this.form.formsyForm.current : null} />
                <ApplicationUpdateDetails params={this.props.params} application={this.state.application} formsy={this.form ? this.form.formsyForm.current : null} />
              </div>

              <ApplicationUpdateButton applicationID={this.props.params.applicationID} />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    application: state.application,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applicationsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdatePage);
