import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../utils';

const PaneDetails = (props) => (
    <div className="pane-details pull-right" onClick={props.toggleOptions}>
      <div className="pane-avatar mr-md">

        <div className="avatar avatar-sm hidden-xs hidden-sm">
          <div className="avatar-image" style={props.styles} />
          <span className="avatar-initials">{props.initials}</span>
        </div>

        <div className="avatar avatar-xs visible-xs visible-sm mt-xs">
          <div className="avatar-image" style={props.styles} />
          <span className="avatar-initials">{props.initials}</span>
        </div>
      </div>

      <h3 className="pane-title">{props.name}</h3>
      <Icon glyph="chevron-down" className="pull-right animate" />
    </div>
);

PaneDetails.propTypes = {
  toggleOptions: PropTypes.func,
  styles: PropTypes.object,
  initials: PropTypes.string,
  name: PropTypes.string,
};

export default PaneDetails;
