import * as React from 'react';
import {Loadr} from '../../../utils';

import {IApplicants, IFormData} from '../interfaces';

interface ILoadingProps {
    message: boolean | string;
}

export const Loading = (props: ILoadingProps) => {
    return (
        <div className="text-center mt-lg mb-lg">
            <Loadr inline md />

            <h4 className="pt-lg mt-lg">Submitting application</h4>
            <p className="text-lg mt-sm mb-lg mute">{props.message}...</p>
        </div>
    );
}

export default Loading;
