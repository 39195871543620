import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import ReactTooltip from 'react-tooltip';
import Guid from 'guid';

import Mask from '../../helpers/mask';

import * as referenceActions from '../../actions/referenceActions';
import * as functions from '../../constants/functions';

import Icon from '../../utils/icon';

export class ComServiceAbilityCompany extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      incomes: [],
    };

    this.changeIncomeType = this.changeIncomeType.bind(this);
    this.changeOtherIncomeType = this.changeOtherIncomeType.bind(this);
    this.addIncome = this.addIncome.bind(this);
    this.removeIncome = this.removeIncome.bind(this);
    this.removeCompany = this.removeCompany.bind(this);
    this.getCompanyName = this.getCompanyName.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
  }

  inputRefs = {};

  setRef = (id) => (ref) => {
    if (ref) this.inputRefs[id] = ref;
    else delete this.inputRefs[id];
  };

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.incomes) {
      this.props.data.incomes.map((income) => {
        this.addIncome(false, income);
      });
    } else if (!this.props.index) {
      this.addIncome(false);
    }

    ReactTooltip.rebuild();
  }

  getIncomeTypes() {
    return [{
      value: 'payg',
      label: 'PAYG',
    },
    {
      value: 'other',
      label: 'Other income',
    },
    {
      value: 'selfEmployed',
      label: 'Self employed',
    },
    {
      value: 'company',
      label: 'Company',
    }];
  }

  getRelationships() {
    return [{
      value: 1,
      label: 'Single',
    },
    {
      value: 2,
      label: 'Married/De-facto',
    }];
  }

  getDependants() {
    return functions.getDependants();
  }

  getCompanies() {
    const companies = [{
      value: '-1',
      disabled: true,
      label: 'Please select',
    },
    {
      value: '',
      label: 'Non-company',
    }];

    this.props.companies.map((company, index) => {
      if (company.companyId !== this.props.company.companyId) {
        companies.push({
          value: company.companyId,
          label: company.name || `Company ${index + 1}`,
        });
      }
    });

    return companies;
  }

  getOtherGroups() {
    return this.props.otherIncomeGroups;
  }

  getOtherTypes() {
    return this.props.otherIncomeTypes;
  }

  getFrequencies(index) {
    const frequencies = [];

    const incomeType = this.state.incomes[index].type;
    if (incomeType != 'selfEmployed' && incomeType != 'company') {
      frequencies.push({
        value: 4,
        label: 'Weekly',
      });
      frequencies.push({
        value: 3,
        label: 'Fortnightly',
      });
      frequencies.push({
        value: 2,
        label: 'Monthly',
      });
    }

    frequencies.push({
      value: 1,
      label: 'Annually',
    });

    return frequencies;
  }

  changeIncomeType(value, idx) {
    const newState = this.state;
    newState.incomes[idx].type = value;
    this.setState(newState);
  }

  changeOtherIncomeType(value, idx) {
    const newState = this.state;
    newState.incomes[idx].incomeOtherGroupId = this.getOtherTypes().filter((type) => type.typeId == value)[0].groupId;
    this.setState(newState);
  }

  addIncome(focus, income) {
    if (!income) {
      income = {
        incomeId: Guid.create().value,
        type: 'payg',
        incomeOtherGroupId: 1,
      };
    }

    if (!income.incomeId) {
      income.incomeId = Guid.create().value;
    }

    const newState = this.state;
    newState.incomes.push(income);

    this.setState(newState, () => {
      if (focus !== false) {
        document.getElementById(`companies[${this.props.index}][incomes][${newState.incomes.length - 1}].type`).focus();
      }
    });
  }

  removeIncome(index) {
    const newState = this.state;
    newState.incomes.splice(index, 1);
    this.setState(newState);
  }

  removeCompany() {
    this.props.removeCompany(this.props.index);
  }

  handleName(value) {
    this.props.handleName(value, this.props.index);
  }

  handlePartner(value) {
    this.props.handlePartner(value, this.props.index);
  }

  handleRelationship(value) {
    this.props.handleRelationship(value, this.props.index);
  }

  getCompanyName() {
    const name = this.props.company.name || `Business ${this.props.index + 1}`;
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="card card-shallow card-stack incomes">

        <div className="panel-row">
          <h3 className="mt-sm ib">{this.getCompanyName()}</h3>
          <button
            id={`removeCompany${this.props.index}`}
            type="button"
            className="btn btn-default btn-danger pull-right btn-circle"
            style={{ marginTop: '-4px', marginRight: '-8px' }}
            onClick={this.removeCompany}
          >
            <Icon glyph="delete" />
          </button>
        </div>

        <Row className="panel-row">
          <Input
            id={`companies[${this.props.index}].id`}
            name={`companies[${this.props.index}].id`}
            ref={this.setRef(`companies[${this.props.index}].id`)}
            value={this.props.company.companyId}
            type="hidden"
          />

          <Col sm={6}>
            <Input
              id={`companies[${this.props.index}].name`}
              name={`companies[${this.props.index}].name`}
              ref={this.setRef(`companies[${this.props.index}].name`)}
              value={this.props.company.name}
              label="Name"
              data-hj-masked
              blurCallback={(_, value) => {
                this.handleName(value);
              }}
            />
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].profit`}
                  name={`companies[${this.props.index}].profit`}
                  ref={this.setRef(`companies[${this.props.index}].profit`)}
                  value={this.props.company.profit || '0'}
                  label={(
                    <span>
                      {'Profit '}
                      <small className="mute">(current year) (NPBT)</small>
                    </span>
                  )}
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs, { negative: true })}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].amortisation`}
                  name={`companies[${this.props.index}].amortisation`}
                  ref={this.setRef(`companies[${this.props.index}].amortisation`)}
                  value={this.props.company.amortisation || '0'}
                  label="Amortisation"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].interest`}
                  name={`companies[${this.props.index}].interest`}
                  ref={this.setRef(`companies[${this.props.index}].interest`)}
                  value={this.props.company.interest || '0'}
                  label="Interest Addback"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].depreciation`}
                  name={`companies[${this.props.index}].depreciation`}
                  ref={this.setRef(`companies[${this.props.index}].depreciation`)}
                  value={this.props.company.depreciation || '0'}
                  label="Depreciation Addback"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].otheraddback`}
                  name={`companies[${this.props.index}].otheraddback`}
                  ref={this.setRef(`companies[${this.props.index}].otheraddback`)}
                  value={this.props.company.otheraddback || '0'}
                  label="Other Addback"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <Col sm={12}>
                <div className="form-prefix">$</div>
                <Input
                  id={`companies[${this.props.index}].otherdeduction`}
                  name={`companies[${this.props.index}].otherdeduction`}
                  ref={this.setRef(`companies[${this.props.index}].otherdeduction`)}
                  value={this.props.company.otherdeduction || '0'}
                  label="Other Deduction"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                  validations={{
                    isFormattedNumber: true,
                    isRequired: false,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

ComServiceAbilityCompany.propTypes = {
  index: PropTypes.number.isRequired,
  company: PropTypes.object.isRequired,
  data: PropTypes.object,
  companies: PropTypes.array.isRequired,
  otherIncomeGroups: PropTypes.array.isRequired,
  otherIncomeTypes: PropTypes.array.isRequired,
  removeCompany: PropTypes.func.isRequired,
  handleName: PropTypes.func.isRequired,
  handlePartner: PropTypes.func.isRequired,
  handleRelationship: PropTypes.func.isRequired,
};

ComServiceAbilityCompany.defaultProps = {
  data: {},
};

function mapStateToProps(state) {
  return {
    otherIncomeGroups: state.reference.otherIncomeGroups,
    otherIncomeTypes: state.reference.otherIncomeTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComServiceAbilityCompany);
