import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select, Checkbox} from 'formsy-react-components';

import Mask from '../../helpers/mask';
import {toOption, toRequired, selectText} from './functions';
import Title from './title';

import {IOption} from '../../interfaces';
import {IApplicants} from './interfaces';

interface IOwnershipProps {
  applicants: IApplicants;
  name: string;
}

interface IOwnershipState {
  remainder: number
}

export class Ownership extends React.Component<IOwnershipProps, IOwnershipState> {
  UNSAFE_componentWillMount() {
    this.setState({
      remainder: 100
    });

    this.updateRemainder = this.updateRemainder.bind(this);
  }

  updateRemainder() {
    let remainder = this.props.applicants.individuals.reduce((remainder, applicant) => {
      return remainder - Number((this.refs[`${this.props.name}.share.${applicant.id}`] as any).getValue())
    }, 100);

    this.setState({
      remainder
    })
  }

  get remainder(): number {
    return this.state.remainder;
  }

  render(): JSX.Element {
    return (
      <div>
        <Title>Ownership</Title>
        <Row>
          <Col sm={3} smOffset={9}>
            <label>{toRequired("Share")}</label>
          </Col>
        </Row>

        {
          this.props.applicants.individuals.map(applicant => {
            return (
              <Row key={`applicant_${applicant.id}_ownership`}>
                <Col sm={9} className="pt-md mute">
                  {applicant.firstName} {applicant.lastName}
                </Col>
                <Col sm={3}>
                  <div className="form-suffix" style={{marginTop: '2px'}}>%</div>
                  <Input
                    name={`${this.props.name}.share.${applicant.id}`}
                    ref={`${this.props.name}.share.${applicant.id}`}
                    label=""
                    value="0"
                    maxLength={3}
                    blurCallback={this.updateRemainder.bind(this)}
                    onFocus={event => selectText(this.refs, event)}
                    validations={{
                      isRequired: true,
                      isFormattedNumber: true,
                      isTotalHundredPercent: (values, value) => {
                        let total = 0;
                        this.props.applicants.individuals.forEach(applicant => {
                          total += Number(values[`${this.props.name}.share.${applicant.id}`]);
                        });
                        return total > 0 && total <= 100;
                      }
                    }}
                    className="form-control text-right"
                    validationError="Share is required" />
                </Col>
              </Row>
            );
          })
        }

        <Row>
          <Col sm={9} className="pt-md mute">
            Others (non-applicants)
          </Col>
          <Col sm={3}>
            <div className="form-suffix" style={{marginTop: '2px'}}>%</div>
            <Input
              name={`${this.props.name}.share.other`}
              label=""
              className="form-control text-right"
              value={String(this.remainder)}
              disabled
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Ownership;
