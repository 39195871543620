import * as queryString from 'query-string';
import * as cookie from 'js-cookie';

type LibCampaign = ValidLibCampaign | UndefinedLibCampaign;
type ValidLibCampaign = string;
type UndefinedLibCampaign = undefined;

class MarketingCampaign {  
  initialise(location: Location): void {
    let libCampaign = this._extract(location);

    if (typeof libCampaign !== 'undefined') {
      this._set(libCampaign);
    }
  }

  get value(): LibCampaign {
    return cookie.get('libcampaign');
  }
  
  _set(libCampaign: ValidLibCampaign): void {
    cookie.set('libcampaign', libCampaign, {
      domain: ".liberty.com.au",
      expires: 1825 //5 years as per MCO standard
    });
  }

  _extract(location: Location): LibCampaign {
    const {lib_campaign} = queryString.parse(location.search);
    return lib_campaign;
  }
}

export default new MarketingCampaign();


