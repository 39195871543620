import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';
import Mask from '../../../helpers/mask';
import DateInput from '../dateInput';
import CurrencyInput from '../currencyInput';
import * as Moment from 'moment';
import Title from '../title';

import {toOption, firstOption, toRequired} from '../functions';

import {IOption} from '../../../interfaces';

interface ISummaryProps {
  loanSplits: any[],
  lvr: string,
  loanAmount: number,
  securityAmount: number
}
interface ISummaryState {
}

export class Summary extends React.Component<ISummaryProps, ISummaryState> {
  get loanTermOptions(): IOption[] {
    let options: IOption[] = [];
    for (let i = 30; i >= 10; i--) {
      options.push({
        label: `${i} years`,
        value: i
      });
    }
    return options;
  }

  get productOptions(): IOption[] {
    return ["Star"].map(o => toOption(o, false))
  }

  render() {
    return (
      <div className="panel-section">
        <Title>Loan</Title>
        <div className="panel-section-body panel-section-body-simple">

        <Row>

          <Col sm={2}>
            <div className="form-suffix">%</div>
            <Input
              name="lvr"
              label="LVR"
              value={this.props.lvr}
              disabled />
          </Col>

          <Col sm={3}>
            <Select
              name="loanProduct"
              label="Product"
              options={this.productOptions}
              value={this.productOptions[0].value}
              className="form-control"/>
          </Col>

          <Col sm={2}>
            <Select
              name="loanTerm"
              label="Loan term"
              options={this.loanTermOptions}
              value={this.loanTermOptions[0].value}
              className="form-control"/>
          </Col>

          <Col sm={2}>
            <DateInput
              name={`settlementDate`}
              label={toRequired("Settlement date")}
              precision="day"
              validations={{
                isTwoWeeksAway: (_, value: string) => {
                  return Moment().diff(Moment(value, "DD/MM/YYYY"), "days") <= 14;
                }
              }}
              validationErrors={{
                isTwoWeeksAway: "Must be at least 2 weeks away",
              }}
            />
          </Col>

          </Row>

          <hr />

          <Row />
        <Row>
          <Col sm={9}>
            <span className="mute pt-sm ib">Value of securities</span>
          </Col>
          <Col sm={3}>
            <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
            <Input
              name="securityAmount"
              value={Mask.number(this.props.securityAmount)}
              className="form-control text-right"
              disabled />
          </Col>
        </Row>

        <Row>
          <Col sm={9}>
            <span className="mute pt-sm ib">Value of loans</span>
          </Col>
          <Col sm={3}>
            <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
            <Input
              name="loanAmount"
              value={Mask.number(this.props.loanAmount)}
              className="form-control text-right"
              disabled />
          </Col>
        </Row>
        </div>
      </div>
    );
  }
}

export default Summary;
