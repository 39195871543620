import { MotorDeclarationsAction, MotorDeclarationsActionType } from '../actions/motorDeclarationsActions';
import Storage from '../helpers/storage';
import initialState from './initialState';

export interface MotorDeclarationsState {
  residencyStatusConfirmed: boolean;
  ageConfirmed: boolean;
}

const intialState: MotorDeclarationsState = Object.entries(initialState.motorDeclarations).length === 0 ? {
  residencyStatusConfirmed: false,
  ageConfirmed: false,
} : initialState.motorDeclarations;

export const motorDeclarationsReducer = (state: MotorDeclarationsState = intialState, action: MotorDeclarationsAction): MotorDeclarationsState => {
  switch (action.type) {
    case MotorDeclarationsActionType.ConfirmedMotorDeclarations: {
      const motorDeclarations = {
        residencyStatusConfirmed: true,
        ageConfirmed: true,
      };

      Storage.setItem('motorDeclarations', JSON.stringify(motorDeclarations));
      return {
        ...state,
        ...motorDeclarations,
      };
    }

    case MotorDeclarationsActionType.ResetMotorDeclarations: {
      const motorDeclarations = {
        residencyStatusConfirmed: false,
        ageConfirmed: false,
      };

      Storage.setItem('motorDeclarations', JSON.stringify(motorDeclarations));

      return {
        ...state,
        ...motorDeclarations,
      };
    }

    default:
      return state;
  }
};
