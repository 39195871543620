import { Dispatch } from "redux";

export type ComServiceAbilitySuggestionAction =
  SetPreferredSurplus;

export enum ComServiceAbilitySuggestionActionType {
  SetPreferredSurplus = "SetPreferredSurplus"
}

interface SetPreferredSurplus {
  value: string;
  type: ComServiceAbilitySuggestionActionType.SetPreferredSurplus;
}

export const setPreferredSurplus = (preferredSurplus: string) => (dispatch: Dispatch<SetPreferredSurplus>) => {
  dispatch({
    value: preferredSurplus,
    type: ComServiceAbilitySuggestionActionType.SetPreferredSurplus
  });
}
