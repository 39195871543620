import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';

import {Loadr} from '../../utils';

interface INotesProps {
  text: string,
  visible: boolean,
  updateNotes: (text: string) => void,
  toggleModal: () => void
}

interface INotesState {
  pending: any
}

export class Notes extends React.Component<INotesProps, INotesState> {
  timeout: any = false;

  constructor(props) {
    super(props);

    this.state = {
      pending: false
    };

    this.setNote = this.setNote.bind(this);
    this.saveNote = this.saveNote.bind(this);
  }

  setNote() {
    (this.refs.notes as any).value = this.props.text;
  }

  saveNote(event: any) {
    if (!this.timeout) {
      this.setState({pending: true})
      this.delayNote(event.target.value);
    } else {
      clearTimeout(this.timeout);
      this.delayNote(event.target.value);
    }
  }

  delayNote(note: string) {
    this.timeout = setTimeout(() => {
      this.props.updateNotes(note);
      this.timeout = false;
      this.setState({pending: false})
    }, 200);
  }

  render(): JSX.Element {
    return (
      <div className="ib a" style={{left: 0, bottom: 0, zIndex: 400}}>
        <button className="btn btn-simple btn-lg btn-default" type="button" onClick={this.props.toggleModal}>Application notes</button>

        <Modal
          show={this.props.visible}
          onHide={this.props.toggleModal}
          onEntered={this.setNote}
        >

          <Modal.Body>
            <button type="button" className="close" aria-label="Close" onClick={this.props.toggleModal}>
              <span aria-hidden="true">×</span>
            </button>

            <h4 className="mb-lg mute">Application notes</h4>
            <p className="mute">Leave any notes you think might help us assessing this application. The notes will save automatically.</p>
          </Modal.Body>

          <div className="form-group mb-h">
            <textarea
              name="notes"
              ref="notes"
              autoFocus
              className="form-control form-control-notes"
              placeholder="Type your notes here..."
              onChange={this.saveNote} />
          </div>

          { this.state.pending && <Loadr sm className="m-lg" style={{top: 'auto', right: 0, bottom: 0, left: 'auto'}} /> }
        </Modal>

      </div>
    );
  }
}

export default Notes;
