import * as React from 'react';
import { Icon } from '../../utils';

export enum AssetClassesFilter {
  Residential = "mortgage",
  Motor = "motor",
  Commercial = "commercial",
  Insurance = "insurance",
  Business = "business"
}

type DocumentTabProps = {
  filter: AssetClassesFilter,
  query: string,
  title: string,
  filterFunction: (query: string) => void,
  visible: any,
  icon: string
}

const DocumentTab = (props: DocumentTabProps) => {
  if (!props.visible(props.query)) {
    return null;
  }

  return (
    <a className={`animate-fast tab ${props.title.toLowerCase()} ${props.filter == props.query ? 'active' : ''}`} onClick={() => { props.filterFunction(props.query); }}>
      <Icon glyph={props.icon} size={2} className="mute-lg" />
      <p className="hidden-xs">{props.title}</p>
    </a>
  );
};

export default DocumentTab;
