import * as React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'formsy-react-components';
import Mask from '../../helpers/mask';
import {selectText} from '../../constants/functions';

export interface IMaskedInputBaseProps {
  placeholder?: string;
  name: string;
  direction?: "ltr" | "rtl";
  label?: string | JSX.Element;
  className?: string;
  pattern?: string;
  prefix?: string;
  suffix?: string;
  maxLength?: number;
  value?: string | number;
  disabled?: boolean;
  hidePlaceholder?: boolean;
  validations?: any;
  validationError?: string;
  validationErrors?: any;
  updateOnChange?:boolean;
  updateOnBlur?:boolean;
  onKeyUp?: (event: any) => void;
  blurCallback?: (field: string, value: string) => void;
  changeCallback?: (field: string, value: string) => void;

  "data-test-name"?: any;
}

interface IMaskedInputProps extends IMaskedInputBaseProps {
  placeholder: string;
}

export class Masked extends React.Component<IMaskedInputProps, any> {
  constructor(props) {
    super(props);

    this.state = {
        value: '',
        chars: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.maskCurrentValue = this.maskCurrentValue.bind(this);
    this.getValueOfMask = this.getValueOfMask.bind(this);
  }

  componentWillMount() {
    this.setState({
      value: this.props.value || ''
    });
  }

  handleChange(event) {
    let value = this.maskCurrentValue(event.target.value);
    this.setState({value});

    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
    }
  }

  handleBlur(_, value) {
    let maskedValue = this.maskCurrentValue(value);
    this.setState({value: maskedValue});
  }

  maskCurrentValue(value = "") {
    var charset = this.props.pattern || false;
    let maskedNumber = 'XDMYxdmy';
    var maskedLetter = '_';
    var placeholder = this.props.placeholder;
    var direction = this.props.direction || "ltr";
    var l = placeholder.length
    var newValue = '';
    var i, j, isInt, isLetter, strippedValue, matchesNumber, matchesLetter;

    // strip special characters
    strippedValue = value.replace(/\D/g, "");

    if (direction == "rtl") {
      strippedValue = strippedValue.split("").reverse().join("");
    }

    for (i = 0, j = 0; i < l; i++) {
        isInt = !isNaN(parseInt(strippedValue[j]));
        isLetter = strippedValue[j] ? strippedValue[j].match(/[A-Z]/i) : false;
        matchesNumber = (maskedNumber.indexOf(placeholder[i]) >= 0);
        matchesLetter = (maskedLetter.indexOf(placeholder[i]) >= 0);
        if ((matchesNumber && isInt) || (charset && matchesLetter && isLetter)) {
          newValue += strippedValue[j++];
        } else if ((!charset && !isInt && matchesNumber) || (charset && ((matchesLetter && !isLetter) || (matchesNumber && !isInt)))) {
          // this.options.onError( e );
          // write your own error handling function
          return newValue;
        } else {
            newValue += placeholder[i];
        }

        // break if no characters left and the pattern is non-special character
        if (strippedValue[j] == undefined) {
          break;
        }
    }

    if (direction == "rtl") {
      newValue = newValue.split("").reverse().join("");
    }

    return newValue;
  }

  getValueOfMask() {
    return <span>{this.state.value}{this.props.placeholder.substr(this.state.value.length)}</span>
  }

  getField(field) {
    return `${this.props.name}.${field}`;
  }

  shouldShowPlaceholder(): boolean {
    return !this.props.hidePlaceholder;
  }

  shouldShowPrefix(): boolean {
    return !!this.props.prefix;
  }

  shouldShowSuffix(): boolean {
    return !!this.props.suffix;
  }

  render(): JSX.Element {
    let attr: any = {
      name: this.props.name,
      id: this.props.name,
      ref: this.props.name,
      value: this.state.value,
      maxLength: this.props.maxLength,
      autoComplete: "no",
      blurCallback: this.handleBlur,
      onKeyUp: this.handleChange,
      updateOnChange: this.props.updateOnChange || false,
      updateOnBlur: this.props.updateOnBlur || false,
    };

    let classNames = ["form-group", "form-group-masked"];

    if (this.props.label) attr.label = this.props.label;
    if (!this.props.label) classNames.push("has-no-label")

    if (this.props.disabled) attr.disabled = "disabled";

    if (this.props.className) attr.className = this.props.className;
    if (this.props.changeCallback) attr.changeCallback = this.props.changeCallback;
    if (this.props.blurCallback) attr.blurCallback = this.props.blurCallback;
    if (this.props.validations) attr.validations = this.props.validations;
    if (this.props.validationError) attr.validationError = this.props.validationError;
    if (this.props.validationErrors) attr.validationErrors = this.props.validationErrors;

    return (
      <div className={classNames.join(" ")}>
        { this.shouldShowPlaceholder.call(this) && <span aria-hidden="true"className="form-mask">{ this.getValueOfMask() }</span> }
        { this.shouldShowPrefix.call(this) && <div className="form-prefix">{this.props.prefix}</div> }
        { this.shouldShowSuffix.call(this) && <div className="form-suffix">{this.props.suffix}</div> }
        <Input {...attr} />
      </div>
    );
  }
}

export default Masked;
