import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Avatar } from '../../utils';
import * as sessionActions from '../../actions/sessionActions';

export class ProfileName extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {};
  }

  getFullName(details) {
    if (details && details.contact) {
      return details.contact;
    }
    return null;
  }

  render() {
    return (
      <div className="panel-part">
        <h5 className="panel-bar">
          Profile
        </h5>

        <Avatar contact={{ lFStaffID: this.props.details.introducerId, lFStaffFirstName: this.props.details.firstName, lFStaffSurname: this.props.details.lastName }} local className="mr-md" />

        <h4 className="ib" id="profileName">{this.getFullName(this.props.details)}</h4>
      </div>
    );
  }
}

ProfileName.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  details: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileName);
