import * as React from 'react';
import PropTypes from 'prop-types';

const AccreditationComplete = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div className="text-center mt-lg">
      <h2 className="mb-lg mt-lg">All set</h2>
      <h5 className="mute">One of our specialists will verify your accreditation shortly.</h5>
      <h5 className="mute">When this is complete you will receive your username via email and password via SMS.</h5>
      <h5 className="mt-lg">Looking forward to working with you!</h5>

      {/* LinkedIn tracking pixel */}
      <img height="1" width="1" alt="" src="https://dc.ads.linkedin.com/collect/?pid=150012&conversionId=198156&fmt=gif" />
    </div>
  );
};

AccreditationComplete.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default AccreditationComplete;
