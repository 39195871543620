import React from 'react';
import { withMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { hashHistory, Link } from 'react-router';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';
import Storage from '../../helpers/storage';

import * as sessionActions from '../../actions/sessionActions';

import InfoPane from '../../components/infoPane';
import TermsModal from '../../components/termsModal';
import SparkModal from '../../components/sparkModal';
import { requestScopes } from '../../helpers/authConfig';
import { Overlay } from '../../utils';

const loginUsername = React.createRef();
const loginPassword = React.createRef();
let msalInProgress = false; // Msal can't determine properly when it's doing something else. We need to manage it.

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      hasSetUsername: false,
      showTermsModal: false,
      showSparkModal: false,
      processing: false,
      creds: {
        loginUsername: this.props.params.username || '',
        loginPassword: '',
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.startProcessing = this.startProcessing.bind(this);
    this.stopProcessing = this.stopProcessing.bind(this);

    this.showTerms = this.showTerms.bind(this);
    this.hideTerms = this.hideTerms.bind(this);
    this.showSpark = this.showSpark.bind(this);
    this.hideSpark = this.hideSpark.bind(this);
  }

  componentDidMount() {
    this.pageLoad();
  }

  componentDidUpdate() {
    this.pageLoad();
  }

  handleChange() {
    const creds = {
      loginUsername: loginUsername.current.state.value,
      loginPassword: '',
    };

    if (loginPassword?.current?.state?.value !== undefined) creds.loginPassword = loginPassword.current.state.value;

    // If the user has removed their user name, take them back to the start.
    if (this.state.creds.loginUsername !== undefined && creds.loginUsername !== this.state.creds.loginUsername) {
      this.state.hasSetUsername = false;
    }

    this.setState({
      creds,
    });
  }

  handleSubmit(data) {
    if (!this.state.hasSetUsername) {
      this.props.actions.getMigrated(this.state.creds.loginUsername)
        .then((response) => {
          if (response === undefined) return;

          this.state.hasSetUsername = true;
          if (response.data) {
            // if (API response == B2C)
            this.setUseB2C(true);
            this.callB2CLogin();
          } else {
            // if (API response == loannet)
            this.setUseB2C(false);
            this.forceUpdate();
          }
        });

      return;
    }

    const loginPayload = {
      username: data.loginUsername,
      password: data.loginPassword,
    };

    if (data.loginUsername.toUpperCase().indexOf('MPER') == 0 || data.loginUsername.toLowerCase().indexOf('@libertynetwork.com.au') > -1 || data.loginUsername.toLowerCase().indexOf('@mikepero.co.nz') > -1) {
      loginPassword.current.setValue('');
      this.setState({
        showSparkModal: true,
        creds: {
          loginUsername: data.loginUsername,
          loginPassword: '',
        },
      });

      return;
    }

    this.startProcessing();
    this.props.actions.doLogin(loginPayload)
      .then(() => {
        this.redirectTo('applications');
      })
      .catch(() => {
        loginPassword.current.setValue('');
        this.setState({
          creds: {
            loginUsername: this.state.creds.loginUsername,
            loginPassword: '',
          },
        });
        this.stopProcessing();
      });
  }

  redirectTo(nextLocation) {
    let location = `/${nextLocation}`;
    if (Storage.getItem('redirect')) {
      location = Storage.getItem('redirect');
      Storage.removeItem('redirect');
    }
    hashHistory.push(location);
  }

  startProcessing() {
    this.setState({
      processing: true,
    });
  }

  stopProcessing() {
    this.setState({
      processing: false,
    });
  }

  showTerms() {
    this.setState({
      showTermsModal: true,
    });
  }

  hideTerms() {
    this.setState({
      showTermsModal: false,
    });
  }

  showSpark() {
    this.setState({
      showSparkModal: true,
    });
  }

  hideSpark() {
    this.setState({
      showSparkModal: false,
    });
  }

  setUseB2C(useB2C) {
    // TEMP_B2C - This is a temporary setting until we migrate everyone across to B2C
    Storage.setItem('isUseB2C', useB2C);
    return useB2C;
  }

  isUseB2C() {
    // TEMP_B2C - This is a temporary setting until we migrate everyone across to B2C
    const isUseB2C = Storage.getItem('isUseB2C');
    return (isUseB2C === null || isUseB2C === undefined)
      ? false
      : isUseB2C;
  }

  getAccount() {
    const allAccounts = this.props.msalContext.accounts;
    const accounts = !allAccounts ? [] : allAccounts;
    return accounts.length > 0
      ? accounts[0]
      : null;
  }

  loginRedirect() {
    const request = { loginHint: this.state.creds.loginUsername };
    return this.props.msalContext.instance.loginRedirect(request);
  }

  getTokenOrLogin(autoRedirect) {
    if (this.props.msalContext.inProgress === InteractionStatus.None && msalInProgress === false) {
      const msalInstance = this.props.msalContext.instance;
      const account = this.getAccount();
      if (!account) {
        if (autoRedirect) {
          Storage.setItem('LoginRedirectedB2C', true);
          this.loginRedirect();
          return;
        }
        return;
      }

      msalInProgress = true;
      const request = { scopes: requestScopes.scopes, account };
      return msalInstance.acquireTokenSilent(request)
        .then((accessTokenResponse) => {
          Storage.setItem('token', accessTokenResponse.accessToken);
          msalInProgress = false;
          this.callB2CLogin(accessTokenResponse.accessToken);
        })
        .catch((error) => {
          msalInProgress = false;
          if (autoRedirect) {
            msalInstance.acquireTokenRedirect(request);
          }
          // throw (error);
        });
    }
  }

  isAuthenticated(token = undefined) {
    if (token === undefined || token === null) token = Storage.getItem('token');

    return this.isUseB2C()
      ? this.getAccount() !== null && (token !== undefined && token !== '' && token !== null)
      : !!JSON.parse(token);
  }

  callB2CLogin(token = undefined) {
    this.setUseB2C(true); // TEMP_B2C - Set the session token to say we're using B2C, this is a temporary setting until we get everyone across to B2C

    // If a user is not logged in and authentication is not already in progress, invoke login
    if (!this.isAuthenticated(token) && this.props.msalContext.inProgress === InteractionStatus.None && (this.state.hasSetUsername === true || Storage.getItem('LoginRedirectedB2C') === 'true')) {
      Storage.removeItem('LoginRedirectedB2C'); // We only remove the 'isRedirected' tag after we know that it's able to get the token correctly (takes some time for MsalContext to catch up)
      this.getTokenOrLogin(true);
      return; // We bail out here so we have time to authenticate the token. If we're successful, then the getTokenOrLogin will automatically re-call this function once it's authenticated.
    }

    this.tryLoadIntroducersPage();
  }

  valueNotEmpty(value) {
    return value !== null && value !== '' && value !== undefined;
  }

  tryLoadIntroducersPage() {
    if (this.isAuthenticated() && (Storage.getItem('isUseB2C') === 'true')) {
      this.redirectTo('introducers');
    }
  }

  pageLoad() {
    // If this is the first time the page has loaded, but MSAL is currently logged in (and we're not in the middle of a process),
    // then log out MSAL because it's a dead session and we've just come from the log-out process
    const loggedInAcccount = this.getAccount();
    const msalInstance = this.props.msalContext.instance;
    if (loggedInAcccount !== null && Storage.getItem('LoginRedirectedB2C') !== 'true' && Storage.getItem('isUseB2C') !== 'true') {
      Storage.removeItem('isUseB2C');
      msalInstance.logoutRedirect({ account: loggedInAcccount });
    }

    const token = Storage.getItem('token');
    try {
      // try to parse the token to JSON (only loanNet tokens will parse successfully). If it fails, then it'll fall back to attempt B2C token
      const t = JSON.parse(token);
      if (this.valueNotEmpty(t)) {
        this.setUseB2C(false);
        this.redirectTo('applications');
        // If we return here, we don't hit the 'finally'
        return;
      }
    } catch (e) {
      // Empty, don't throw an exception here because we're trying to determine if we're using B2C or not and don't know for sure until later.
    } finally {
      this.tryLoadIntroducersPage();
    }

    // We have to capture when the page loads because the Msal login-redirect does not have a return event we can capture.
    // So once we know we have a successful login we can attempt to get the access token from Msal again.
    if (Storage.getItem('LoginRedirectedB2C') === 'true' && (Storage.getItem('isUseB2C') === 'true')) {
      this.callB2CLogin();
    }
  }

  render() {
    if (Storage.getItem('LoginRedirectedB2C') === 'true' && (Storage.getItem('isUseB2C') === 'true')) {
      return <Overlay>Signing in...</Overlay>;
    }

    return (
      <div>
        <div className="text-center mt-lg mb-lg">
          <Link to="/login">
            <img alt="" src={`/${window.iqUIVersion}/assets/img/logo-liberty-financial.png`} className="mt-lg ml-md-n" width="180" />
          </Link>
        </div>

        <div className="panel panel-fixed panel-default mb-lg">
          <div className="panel-body p-lg">
            <h2 className="mb-md mt-sm text-center">Sign in</h2>

            <Form
              id="loginForm"
              layout="vertical"
              onValidSubmit={this.handleSubmit}
              onChange={this.handleChange}
            >
              <Col sm={12}>
                <Input
                  id="loginUsername"
                  name="loginUsername"
                  ref={loginUsername}
                  type="text"
                  label="Email Address"
                  placeholder="Email address"
                  validationError="Email address is required"
                  autoFocus={!this.props.params.username}
                  tabIndex="1"
                  validations={{
                    isRequired: true,
                    maxLength: 255,
                  }}
                />

                {this.state.hasSetUsername && (Storage.getItem('isUseB2C') !== 'true')
                  ? (
                    <Input
                      id="loginPassword"
                      name="loginPassword"
                      ref={loginPassword}
                      type="password"
                      label={(
                        <div>
                          {'Password '}
                          <Link tabIndex="4" to={this.props.params.username ? `reset/${this.props.params.username}` : 'reset'} className="mr-lg" style={{ position: 'absolute', right: 0 }}>Reset password</Link>
                        </div>
                )}
                      placeholder="LoanNET password"
                      validationError="Password is required"
                      autoFocus={!!this.props.params.username}
                      tabIndex="2"
                      validations={{
                        isRequired: true,
                        maxLength: 255,
                      }}
                    />
                  ) : null}
              </Col>


              <button
                className="btn btn-primary btn-lg btn-block btn-center loginButton"
                disabled={!(this.state.creds.loginUsername)}
                tabIndex="3"
                id="loginButton"
              >
                {this.state.hasSetUsername ? 'Sign In' : 'Continue'}
              </button>

            </Form>


          </div>

          <div className="panel-footer pt-lg pb-lg pl-h pr-h">
            <InfoPane action="signing in" />
          </div>

          {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Signing in...</Overlay>
              );
            }
          })()
        }
        </div>

        <div className="text-center mt-lg mb-lg">
          <Link to="get-accredited" className="p-sm">Get accredited with Liberty</Link>

          <small className="b mt-lg">
            <span className="mute">Access to and use of Liberty IQ is subject to our </span>
            <a href="javascript:void(0)" onClick={this.showTerms}>Terms of Use</a>
            <span className="mute"> and </span>
            <a href="https://www.liberty.com.au/disclosures/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
.
          </small>
        </div>

        <TermsModal visible={this.state.showTermsModal} hideModal={this.hideTerms} />
        <SparkModal visible={this.state.showSparkModal} hideModal={this.hideSpark} />

      </div>
    );
  }
}


LoginPage.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  params: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(LoginPage));
