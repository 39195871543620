import { AxiosResponse } from 'axios';
import Api from '.';

export interface Lookup<TKey, TValue> {
  key: TKey
  value: TValue
}

export interface BidMetrics<T> {
  turnaroundTime: T
  nps: T
  creditAssessorsScore: T
  trustScore: T
  trustpilotReviews: T
  productRating: T
  productReviews: T
  dcaMembership: T
  rap: T
  fiapMembership: T
  bCorpScore: T
}

export interface BidMetricData {
  value: ScoreRange | boolean | number | null
  date: string | null
  denominator: number | null
  unitType: UnitType
  cadence: Cadence
}

export type Cadence = "Daily" | "Weekly" | "Monthly" | "Quarterly"

export type UnitType = "Days" | "Stars" | "Points" | "Reviews" | "Boolean"

export interface ScoreRange {
  min: number
  max: number
}

export type BidMetric =
  | "TurnaroundTime"
  | "NPS"
  | "CreditAssessorsScore"
  | "TrustScore"
  | "TrustpilotReviews"
  | "ProductRating"
  | "ProductReviews"
  | "DcaMembership"
  | "Rap"
  | "FiapMembership"
  | "BCorpScore"

export function bidMetricsToArray<A>(data: BidMetrics<A>) : [BidMetric, A][] {
  return [
    ["TurnaroundTime", data.turnaroundTime],
    ["NPS", data.nps],
    ["CreditAssessorsScore", data.creditAssessorsScore],
    ["TrustScore", data.trustScore],
    ["TrustpilotReviews", data.trustpilotReviews],
    ["ProductRating", data.productRating],
    ["ProductReviews", data.productReviews],
    ["DcaMembership", data.dcaMembership],
    ["Rap", data.rap],
    ["FiapMembership", data.fiapMembership],
    ["BCorpScore", data.bCorpScore],
  ]
}

export function zipBidMetrics<A, B>(dataA: BidMetrics<A>, dataB: BidMetrics<B>) : BidMetrics<[A, B]> {
  return {
    turnaroundTime: [dataA.turnaroundTime, dataB.turnaroundTime],
    nps: [dataA.nps, dataB.nps],
    creditAssessorsScore: [dataA.creditAssessorsScore, dataB.creditAssessorsScore],
    trustScore: [dataA.trustScore, dataB.trustScore],
    trustpilotReviews: [dataA.trustpilotReviews, dataB.trustpilotReviews],
    productRating: [dataA.productRating, dataB.productRating],
    productReviews: [dataA.productReviews, dataB.productReviews],
    dcaMembership: [dataA.dcaMembership, dataB.dcaMembership],
    rap: [dataA.rap, dataB.rap],
    fiapMembership: [dataA.fiapMembership, dataB.fiapMembership],
    bCorpScore: [dataA.bCorpScore, dataB.bCorpScore],
  };
}

class ReferenceApi {
  static lookupUrl() {
    return `${Api.base}lookup/`;
  }

  static getLoanPurposes() : Promise<AxiosResponse<Lookup<string, string>[]>> {
    return Api.get(`${this.lookupUrl()}LoanPurposes`);
  }

  static getNvic(nvic) {
    return Api.get(`${this.lookupUrl()}VehicleDetailByCriteria?NVIC=${nvic}`);
  }

  static getYears() {
    return Api.get(`${this.lookupUrl()}VehicleYearOfManufacture`);
  }

  static getMakes(year) : Promise<AxiosResponse<Lookup<number, string>[]>> {
    return Api.get(`${this.lookupUrl()}VehicleMakeByCriteria?Year=${year}&IsNew=1`);
  }

  static getModels(year, make) : Promise<AxiosResponse<Lookup<number, string>[]>> {
    return Api.get(`${this.lookupUrl()}VehicleModelByCriteria?Year=${year}&IsNew=1&Make=${make}`);
  }

  static getBodyTypes(year, make, model) : Promise<AxiosResponse<Lookup<number, string>[]>> {
    return Api.get(`${this.lookupUrl()}VehicleBodyByCriteria?Year=${year}&IsNew=1&Make=${make}&Model=${model}`);
  }

  static getTransmissions(year, make, model, bodyType) : Promise<AxiosResponse<Lookup<number, string>[]>> {
    return Api.get(`${this.lookupUrl()}VehicleTransmissionByCriteria?Year=${year}&IsNew=1&Make=${make}&Model=${model}&Body=${bodyType}`);
  }

  static getCategories(year, make, model, bodyType, transmission) : Promise<AxiosResponse<Lookup<string, string>[]>> {
    return Api.get(`${this.lookupUrl()}VehicleCategoryByCriteria?Year=${year}&IsNew=1&Make=${make}&Model=${model}&Body=${bodyType}&Transmission=${transmission}`);
  }

  static getLpiTypes() : Promise<AxiosResponse<Lookup<number, string>[]>> {
    return Api.get(`${this.lookupUrl()}LpiCoverOptions`);
  }

  static getAppraisal(nvic, odometer, isNew) {
    return Api.get(`${this.lookupUrl()}VehicleAppraisalByCriteria?NVIC=${nvic}&IsNew=${isNew}&Odometer=${odometer}`);
  }

  static getVeiTypes() {
    return Api.get(`${this.lookupUrl()}veicoveroptionswithbenefitAmount`);
  }

  static getVehicleYearOnYearDepreciation() {
    return Api.get(`${this.lookupUrl()}vehicleyearonyeardepreciation`);
  }

  static getVendorTypes() : Promise<AxiosResponse<Lookup<string, string>[]>> {
    return Api.get(`${this.lookupUrl()}VendorTypes`);
  }

  static getInsurancePaymentMethods() : Promise<AxiosResponse<Lookup<string, string>[]>> {
    return Api.get(`${this.lookupUrl()}insurancepaymentmethods`);
  }

  static getVerificationLevels(productGroupId) {
    return Api.get(`${this.lookupUrl()}VerificationLevelByCriteria?ProductGroupId=${productGroupId}`);
  }

  static getInsurers() {
    return Api.get(`${this.lookupUrl()}insurersinaustralia`);
  }

  static getOtherIncomeTypes() {
    return Api.get(`${this.lookupUrl()}other-income-types`);
  }

  static getCommOtherIncomeTypes() {
    return Api.get(`${this.lookupUrl()}comm-other-income-types`);
  }

  static getBestInterestsDutyMetrics() : Promise<BidMetrics<BidMetricData>> {
    const config = Api.config();
    config.cache = {
      expires: 1 // Cache the metrics for 1 hour
    };
    return Api.get(`${this.lookupUrl()}best-interests-duty-metrics`, config).then(response => response.data)
  }

  static getMaxClaimableGstAmount(productGroupId) {
    return Api.get(`${this.lookupUrl()}limits/max-claimable-gst?ProductGroupId=${productGroupId}`);
  }

  static getMaxRateDiscount(productGroupId) {     
    return Api.get(`${this.lookupUrl()}limits/max-rate-discount?ProductGroupId=${productGroupId}`);
  }

  static getLPIPremium(data) {
    return Api.post(`${this.lookupUrl()}lpipremiumdetails`, data);
  }

}

export default ReferenceApi;
