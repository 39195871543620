import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import * as referenceActions from '../../actions/referenceActions';
import { VeiCalculatorHeader } from './veiCalculatorHeader';
import VeiCalculatorDetails from './veiCalculatorDetails';
import { VeiCalculatorResult } from './veiCalculatorResult';
import { VeiOption } from '../applicationUpdatePage/applicationUpdateVei';

export interface VeiCalculatorProps {
  veiOptions: VeiOption[],
  isValid: boolean,
  actions: {
    getVeiTypes: Function,
    getVehicleYearOnYearDepreciation: Function
    calculateLoanInfo: Function,
  }
}

interface VeiCalculatorState {
  showResults: boolean
}

export class VeiCalculatorPage extends React.Component<VeiCalculatorProps, VeiCalculatorState> {
  constructor(props: VeiCalculatorProps) {
    super(props, {})
  }
  
  UNSAFE_componentWillMount() {
    this.props.actions.getVeiTypes();
    this.props.actions.getVehicleYearOnYearDepreciation();
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={12} className="main-panel">
            <div className='panel panel-default'>
              <div className='panel-body p-h'>
                <VeiCalculatorHeader />

                <VeiCalculatorDetails />

                <VeiCalculatorResult veiOptions={this.props.veiOptions} isValid={this.props.isValid} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ reference, veiCalculator }): { veiOptions: VeiOption[], isValid: boolean } {
  return {
    veiOptions: veiCalculator.validVeiOptions || reference.veiTypes,
    isValid: veiCalculator.isValid
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VeiCalculatorPage);
