import * as React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import { Checkbox } from 'formsy-react-components';

import { Icon } from '../../utils';

const AccreditationProduct = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div>
      <div className="panel-bar ml-lg-n mr-lg-n pl-lg">
        <h5 className="panel-title">
          Accreditation products&nbsp;
          <small className="mute">(select at least one)</small>
        </h5>
      </div>
     
      <Row float="center">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="col-20 pl-sm pr-sm">
            <Checkbox
              name="productResi"
              id="productResi"
              rowClassName="form-group-toggle fill"
              changeCallback={props.handleChange}
              validations="isSelected"
              value={false}
              tabIndex="1"
              valueLabel={(
                <div className="toggle r">
                  <Icon size={2} glyph="home" className="mute-md" />
                  <p>Residential</p>
                </div>
              )}
            />
          </div>        

          <div className="col-20  pl-sm pr-sm">
            <Checkbox
              name="productCommercial"
              id="productCommercial"
              rowClassName="form-group-toggle fill"
              validations="isSelected"
              value={false}
              tabIndex="3"
              valueLabel={(
                <div className="toggle r">
                  <Icon size={2} glyph="city-alt" className="mute-md" />
                  <p>Commercial</p>
                </div>
              )}
            />
          </div>

          <div className="col-20  pl-sm pr-sm">
            <Checkbox
              name="productSMSF"
              id="productSMSF"
              rowClassName="form-group-toggle fill"
              changeCallback={props.handleChange}
              value={false}
              validations="isSelected"
              tabIndex="4"
              valueLabel={(
                <div className="toggle r">
                  <div className="r mute-md">
                    <Icon size={2} glyph="balance-wallet" />
                    <Icon
                      glyph="money-box"
                      className="mute-md a"
                      style={{
                        fontSize: '18px', marginLeft: '-8px', top: '-8px', left: '50%',
                      }} />
                  </div>
                  <p>SMSF</p>
                </div>
              )}
            />
          </div>
        </div>        
      </Row>

      <Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="col-20  pl-sm pr-sm">
            <Checkbox
              name="productMotor"
              id="productMotor"
              rowClassName="form-group-toggle fill"
              validations="isSelected"
              value={false}
              tabIndex="2"
              valueLabel={(
                <div className="toggle r">
                  <Icon size={2} glyph="directions-car" className="mute-md" />
                  <p>Motor</p>
                </div>
              )}
            />
          </div>

          <div className="col-20  pl-sm pr-sm">
            <Checkbox
              name="productInsurance"
              id="productInsurance"
              rowClassName="form-group-toggle fill"
              validations="isSelected"
              value={false}
              tabIndex="5"
              valueLabel={(
                <div className="toggle r">
                  <Icon size={2} glyph="shield-security" className="mute-md" />
                  <p>
                    {'Insurance '}
                    <small className="mute">by</small>
                    {' LFI'}
                  </p>
                </div>
              )}
            />
          </div>

          <div className="col-20  pl-sm pr-sm">
            <Checkbox
              name="productBusinessCare"
              id="productBusinessCare"
              rowClassName="form-group-toggle fill"
              validations="isSelected"
              value={false}
              tabIndex="6"
              valueLabel={(
                <div className="toggle r">
                  <Icon size={2} glyph="card-travel" className="mute-md" />
                  <p>Business</p>
                </div>
              )}
            />
          </div>
        </div>
      </Row>
    </div>
  );
};

AccreditationProduct.propTypes = {
  handleChange: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AccreditationProduct;
