import * as Formsy from 'formsy-react';
import { get_States } from '../constants/functions';
import { MOTOR_VIN_MIN_LENGTH, MOTOR_PLATE_MIN_LENGTH, MOTOR_PLATE_MAX_LENGTH } from '../constants/types';
import Mask from './mask';
const Moment = require('moment');


Formsy.addValidationRule('isRequired', (_, value) => !!(value && value.toString().trim().length > 0));
Formsy.addValidationRule('isNotZero', (_, value) => Number(value) !== 0);
Formsy.addValidationRule('isSelected', (_, value) => !!(value != '-1' && value.toString().trim().length > 0));
Formsy.addValidationRule('isFormattedNumber', (values, value) => !!(value !== undefined && value.toString().length && value.toString().replace(/[^0-9.]/g, '')));
Formsy.addValidationRule('isPositive', (values, value) => value >= 0);
Formsy.addValidationRule('isPhone', (values, value) => {
  const regular = /^0[1-8]{1}\d{8}$/g;
  const special = /^1[389]{1}\d{4,10}$/g;

  const trimmed = String(value).toString().replace(/[ ()-]/g, '');
  return !value || regular.test(trimmed) || special.test(trimmed);
});
Formsy.addValidationRule('isMobile', (values, value) => {
  const regular = /^04\d{8}$/g;
  const special = /^1[389]{1}\d{4,10}$/g;

  const trimmed = String(value).toString().replace(/[ ()-]/g, '');
  return !value || regular.test(trimmed) || special.test(trimmed);
});
Formsy.addValidationRule('isUnlikelyPhone', (_, value = '') => value.indexOf('00000000') == -1);
Formsy.addValidationRule('isFullName', (values, value) => {
  const results = value.toString().trim().match(/ /g);
  return (results != null) ? results.length >= 1 : false;
});
Formsy.addValidationRule('isAddress', (values, value) => {
  const results = value.toString().trim().match(/ /g);
  return (results != null) ? results.length >= 2 && value.toString().search(/\d/) >= 0 : false;
});
Formsy.addValidationRule('isPostcode', (values, value) => {
  const postcode = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/;
  return postcode.test(value);
});
Formsy.addValidationRule('isPostcodeOptional', (values, value) => {
  if (value) {
    const postcode = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/;
    return postcode.test(value);
  }
  return true;
});
Formsy.addValidationRule('isFormattedNumberOptional', (values, value) => {
  if (value) {
    return !!(value !== undefined && value.toString().length && value.toString().replace(/[^0-9.]/g, ''));
  }
  return true;
});
Formsy.addValidationRule('isSelected', (values) => {
  let selections = 0;
  Object.keys(values).map((key) => {
    if (values[key]) {
      selections++;
    }
  });

  return !!selections;
});
Formsy.addValidationRule('isValidRegistration', (values, value) => {
  /**
   * No need for new vehicles
   */
  if (!values.vehicleIsUsed && !value) {
    return true;
  }

  /**
   * Check valid length
   */
  if (value && value.length >= MOTOR_PLATE_MIN_LENGTH && value.length <= MOTOR_PLATE_MAX_LENGTH) {
    return true;
  }

  return false;
});
Formsy.addValidationRule('isValidState', (values, value) => {
  const STATE_LIST = get_States();
  /**
   * No need for new vehicles
   */
  if (!values.vehicleIsUsed && !value) {
    return true;
  }

  /**
   * Check valid length
   */
  if (value && value.length > 0 && STATE_LIST.indexOf(value) >= 0) {
    return true;
  }

  return false;
});
Formsy.addValidationRule('isValidVin', (values, value) => {
  /**
   * No need for new vehicles
   */
  if (!values.vehicleIsUsed && !value) {
    return true;
  }

  /**
   * Check valid length
   */
  if (value && value.length == MOTOR_VIN_MIN_LENGTH) {
    return true;
  }

  return false;
});
Formsy.addValidationRule('isValidEngine', (values, value) => {
  /**
   * No need for new vehicles
   */
  if (!values.vehicleIsUsed && !value) {
    return true;
  }

  /**
   * Check valid length
   */
  if (value && value.length >= 4) {
    return true;
  }

  return false;
});
Formsy.addValidationRule('isValidRegistrationExpiry', (values, value) => {
  const unformattedDate = Mask.clean(value);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Offset the months by 1 (0-11 to 1-12)

  /**
   * The following is a easter egg for testing
   * so that it always returns true for our test under
   * certain conditions
   */
  if (values.vehicleVinNo == '1ABCD23DEFG456789' && values.vehicleEngineNo == 'A12BC34567' && values.vehicleRegistrationExpiry == '00/0000') {
    return true;
  }

  /**
   * No need for new vehicles
   */
  if (!values.vehicleIsUsed && !value) {
    return true;
  }

  /**
   * Check the current length is 6
   */
  if (unformattedDate.length !== 6) {
    return false;
  }

  /**
   * Check that the first 2 characters are 12 or less
   */
  if (parseInt(unformattedDate.substr(0, 2)) > 12) {
    return false;
  }

  /**
   * Check that the last 4 characters are at least this year
   * and less then 10 years in the future
   */
  if (parseInt(unformattedDate.substr(2, 4)) < currentYear
    || parseInt(unformattedDate.substr(2, 4)) > currentYear + 10) {
    return false;
  }

  /**
   * Check that the first 2 characters are equal to or greater
   * then the current month if the last 4 characters are
   * equal to the current year
   */
  if (parseInt(unformattedDate.substr(2, 4)) === currentYear) {
    if (parseInt(unformattedDate.substr(0, 2)) < currentMonth) {
      return false;
    }
  }

  /**
   * Still no errors, all good
   */
  return true;
});

// Date field optional, between 1 and 31, padding 0 optional.
// Month field between 1 and 12, padding 0 optional.
// Year field 4 digits starting with 19 or 20.
const regexDate = /^((0?[1-9]|[12][0-9]|3[01])\/)?(0?[1-9]|1[0-2])\/(19|20)\d{2}$/;

Formsy.addValidationRule('isValidDate', (_, value = '') => {
  if (value == '') return true;

  const validDateFormat = regexDate.test(value);
  if (!validDateFormat) return false;

  const paddedDate = value.split('/').map((x) => (x.length == 1 ? `0${x}` : x)).join('/');
  let unformattedDate = Mask.clean(paddedDate);

  // add date if only month and year is passed 
  if (unformattedDate.length == 6) {
    unformattedDate = `01${unformattedDate}`;
  }

  return Moment(unformattedDate, 'DDMMYYYY').isValid() && Moment(unformattedDate, 'DDMMYYYY').year() > 1900;
});

Formsy.addValidationRule('isValidPastDate', (values, value = '') => {
  if (!value) return true;

  const validDateFormat = regexDate.test(value);
  if (!validDateFormat) return false;

  const paddedDate = value.split('/').map((x) => (x.length == 1 ? `0${x}` : x)).join('/');
  let unformattedDate = Mask.clean(paddedDate);

  // add date if only month and year is passed 
  if (unformattedDate.length == 6) {
    unformattedDate = `01${unformattedDate}`;
  }

  if (Moment(unformattedDate, 'DDMMYYYY').diff(Moment(), 'days') < 0) {
    return true;
  }
  
  return false;
});
Formsy.addValidationRule('isValidFutureDate', (values, value = '') => {
  if (value == '') return true;

  const validDateFormat = regexDate.test(value);
  if (!validDateFormat) return false;

  const paddedDate = value.split('/').map((x) => (x.length == 1 ? `0${x}` : x)).join('/');
  let unformattedDate = Mask.clean(paddedDate);

  // add date if only month and year is passed 
  if (unformattedDate.length == 6) {
    unformattedDate = `01${unformattedDate}`;
  }

  if (Moment(unformattedDate, 'DDMMYYYY').diff(Moment(), 'days') > 0) {
    return true;
  }
  
  return false;
});
Formsy.addValidationRule('ageMinValidator', (values, value = '') => {
  if (!value) return true;

  const paddedDate = value.split('/').map((x) => (x.length == 1 ? `0${x}` : x)).join('/');
  let unformattedDate = Mask.clean(paddedDate);

  // add date if only month and year is passed 
  if (unformattedDate.length == 6) {
    unformattedDate = `01${unformattedDate}`;
  }

  return Moment().diff(Moment(value, 'DDMMYYYY'), 'years') >= 18;
});
Formsy.addValidationRule('ageMaxValidator', (values, value = '') => {
  if (!value) return true;

  const paddedDate = value.split('/').map((x) => (x.length == 1 ? `0${x}` : x)).join('/');
  let unformattedDate = Mask.clean(paddedDate);

  // add date if only month and year is passed 
  if (unformattedDate.length == 6) {
    unformattedDate = `01${unformattedDate}`;
  }

  return Moment().diff(Moment(value, 'DDMMYYYY'), 'years') <= 150;
});
Formsy.addValidationRule('isValidNumber', (values, value) => !!(value.toString().length && value.toString().replace(/[^0-9.]/g, '')));
Formsy.addValidationRule('validateNumber', (values, value) => (value.toString().match(/[^0-9.]/g) == null));
Formsy.addValidationRule('isValidMonth', (values, value) => value >= 0 && value <= 12);
Formsy.addValidationRule('isValidYear', (values, value) => value >= 0 && value <= 100);
Formsy.addValidationRule('vedaScoreValidation', (values, value) => {
  if (!value || value == '') {
    return true;
  }

  const val = value.toString().replace(/[^0-9.]/g, '');
  if (val == '') {
    return false;
  }

  const numVal = Number(val);
  return numVal >= -1000 && numVal <= 1000;
});
Formsy.addValidationRule('numberOfEnquiriesValidation', (values, value) => {
  if (!value || value == '') {
    return true;
  }

  const val = value.toString().replace(/[^0-9.]/g, '');
  if (val == '') {
    return false;
  }

  const numVal = Number(val);
  return numVal >= 0 && numVal <= 1000;
});
Formsy.addValidationRule('validComprehensiveInsurerCommissionValue', (values, value) => {
  if (values.comprehensiveInsurerCommissionType == '$') {
    if (value == '' || parseFloat(value) <= 0) { return false; }
  } else if (values.comprehensiveInsurerCommissionType == '%') {
    if (value == '' || parseFloat(value) <= 0 || parseFloat(value) > 100) { return false; }
  }

  return true;
});
Formsy.addValidationRule('validExtWarrantyInsurerCommissionValue', (values, value) => {
  if (values.extWarrantyInsurerCommissionType == '$') {
    if (value == '' || parseFloat(value) <= 0) { return false; }
  } else if (values.extWarrantyInsurerCommissionType == '%') {
    if (value == '' || parseFloat(value) <= 0 || parseFloat(value) > 100) { return false; }
  }

  return true;
});

export default Formsy;
