import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingStepTrack extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="onboarding-step text-center track">
        <h2 className="onboarding-title">Track and progress your applications</h2>
        <h4 className="onboarding-subtitle mute">Stay up to date on the status of your current application. Receive updates when your application progresses or when we need you to upload outstanding documents via IQ. </h4>
      </div>
    );
  }
}
