import { Dispatch } from "redux";

export type MotorPrequalAction = GetPrequalResultAction | SetPrequalResultAction | ChangeDiscountRateAction | ChangeHasVeiAction | ChangeHasLpiAction

interface Commission {
  minimum: number
  maximum: number
}

type PrequalResult = {
  riskGrade: string
  interestRate: number
  repayment: number
  assessmentResult: string
  loanTermInMonths: number
  loanAmount: number
  commission: Commission
  referMessage: string
  equalisationFee: number
}

export enum MotorPrequalActionTypes {
  GetPrequalResultAction = "GetPrequalResult",
  SetPrequalResultAction = "SetPrequalResult",
  ChangeDiscountRateAction = "ChangeDiscountRate",
  ChangeHasVeiAction = "ChangeHasVeiAction",
  ChangeHasLpiAction = "ChangeHasLpiAction"
}

interface GetPrequalResultAction { type: MotorPrequalActionTypes.GetPrequalResultAction }

interface SetPrequalResultAction { type: MotorPrequalActionTypes.SetPrequalResultAction, prequalResult: PrequalResult }

interface ChangeDiscountRateAction { type: MotorPrequalActionTypes.ChangeDiscountRateAction, rateDiscount: number }

interface ChangeHasVeiAction { type: MotorPrequalActionTypes.ChangeHasVeiAction, hasVei: Boolean }

interface ChangeHasLpiAction { type: MotorPrequalActionTypes.ChangeHasLpiAction, hasLpi: Boolean }

export const getPrequalResult = () => (dispatch: Dispatch<GetPrequalResultAction>) => {
  dispatch({
    type: MotorPrequalActionTypes.GetPrequalResultAction,
  });
}

export const setPrequalResult = (prequalResult: PrequalResult) => (dispatch: Dispatch<SetPrequalResultAction>) => {
  dispatch({
    type: MotorPrequalActionTypes.SetPrequalResultAction,
    prequalResult
  });
}

export const changeDiscountRate = (rateDiscount: number) => (dispatch: Dispatch<ChangeDiscountRateAction>) => {
  dispatch({
    type: MotorPrequalActionTypes.ChangeDiscountRateAction,
    rateDiscount
  })
}

export const changeHasVei = (hasVei: Boolean) => (dispatch: Dispatch<ChangeHasVeiAction>) => {
  dispatch({
    type: MotorPrequalActionTypes.ChangeHasVeiAction,
    hasVei
  })
}

export const changeHasLpi = (hasLpi: Boolean) => (dispatch: Dispatch<ChangeHasLpiAction>) => {
  dispatch({
    type: MotorPrequalActionTypes.ChangeHasLpiAction,
    hasLpi: hasLpi
  })
}