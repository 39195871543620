import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import MotorPrequalProperty from './motorPrequalProperty';

export default class MotorPrequalToolAssets extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }


  /**
     * Render
     */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />
          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Assets (please tick the box if it applies) </h3>
          </div>
        </div>
        <div className="panel-section-body">
          <div className="panel-section-container">
            <MotorPrequalProperty namePrefix="loanDetails.asset" />
          </div>
        </div>
      </div>
    );
  }
}
