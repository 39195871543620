import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notify as Notify } from 'react-notify-toast';
import Axios from 'axios';

import * as documentsActions from '../../../actions/documentsActions';
import * as applicationsActions from '../../../actions/applicationsActions';

import { Overlay, Icon } from '../../../utils';

import API from '../../../api';

import ApplicationAgreement from '../applicationAgreement';
import ApplicationEsigDocument from './applicationEsigDocument';
import { PRODUCTGROUPS, CAPABILITIES } from '../../../constants/types';


/** Status 240 is Aproved */
const canDownloadMotorLoanContract = (statusCode, applicationType, printAfterApproval) => applicationType === 2 && ((statusCode >= 240 && statusCode < 320) || (printAfterApproval && statusCode <= 240));


const isMotorConsumer = (productGroupNumber) => productGroupNumber == PRODUCTGROUPS.MOTOR_CONSUMER || productGroupNumber == PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN;

const isMotorCommercial = (productGroupNumber) => productGroupNumber == PRODUCTGROUPS.MOTOR_COMMERCIAL;

const isNovatedLease = (productGroupNumber) => productGroupNumber == PRODUCTGROUPS.MOTOR_NOVATED_LEASE;

const isGuarantorDocPack = (downloadableDocumentId) => downloadableDocumentId === 4 || downloadableDocumentId === 5;

const isCommercialOrConsumerWithoutGuarantor = (application) => {
  const isCommercial = application.productGroupId === PRODUCTGROUPS.MOTOR_COMMERCIAL;
  const isConsumerWithoutGuarantor = isMotorConsumer(application.productGroupId) && application.applicants.filter((a) => a.status === 'Guarantor').length === 0;

  return isCommercial || isConsumerWithoutGuarantor;
};

export class ApplicationDownloadDocuments extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.props = props;
    this.state = {
      downloadableDocuments: [
        { id: 1, type: 2, name: 'Application Document Pack' }, // >= 230
        { id: 2, type: 2, name: 'Motor Loan Documents' }, // >= 240 || printAfterApproval
        { id: 3, type: 2, name: 'Indicative Approval' }, // >= 230
        { id: 4, type: 2, name: 'Guarantor Application Pack' }, // >= 230
        { id: 5, type: 2, name: 'Guarantor Settlement Pack' }, // > = 230

        { id: 7, type: 2, name: 'VEI Application Form' }, // >= 230

        { id: 2, type: 1, name: 'Full Application Summary' }, // >= 240
        { id: 8, type: 1, name: 'MPI Application Form' }, // >= 230

        { id: 13, type: 2, name: 'Novation Agreement' },
      ],
      availableDocuments: [],
      processing: false,
      expanded: false,
      agreement: false,
      documentId: undefined,
    };

    this.getDocument = this.getDocument.bind(this);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.showAgreement = this.showAgreement.bind(this);
    this.hideAgreement = this.hideAgreement.bind(this);
  }

  componentDidMount() {
    this.setDocuments();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setDocuments();
  }


  setDocuments() {
    const availableDocuments = this.filterAvailableDocuments(this.state.downloadableDocuments, this.props.application);
    this.setState({
      availableDocuments,
    });
  }

  /**
   * Filter available documents from downloadable documents
   *
   * @param  Array  downloadableDocuments
   * @param  Number  applicationType
   * @param  Number  applicationStatusCode:number
   *
   * @return Array
   */
  // eslint-disable-next-line react/sort-comp
  filterAvailableDocuments(downloadableDocuments, application) {
    const availableDocuments = downloadableDocuments.filter((downloadableDocument) => {
      if (downloadableDocument.type === -1 || downloadableDocument.type === application.applicationType) {
        if (downloadableDocument.id === 1) {
          return true;
        }

        if (downloadableDocument.id === 13 && downloadableDocument.type === 2) {
          return isNovatedLease(application.productGroupId);
        }

        /**
         * Filter out
         * */
        if (downloadableDocument.id === 2 && !canDownloadMotorLoanContract(application.applicationStatus.code, application.applicationType, this.props.session.capabilities.indexOf(CAPABILITIES.PRINT_AFTER_APPROVAL) !== -1)) {
          return false;
        }

        /**
           * Statuscode 230 is pre-approved
           */
        if (downloadableDocument.id !== 2 && application.applicationStatus.code < 230) {
          return false;
        }


        if (downloadableDocument.id >= 3 && downloadableDocument.id <= 5) {
          if (application.applicationStatus.code < 240 || application.applicationStatus.code > 320) {
            return false;
          }

          if (!application.applicationState) {
            return false;
          }
        }

        if (isGuarantorDocPack(downloadableDocument.id)
            && (isCommercialOrConsumerWithoutGuarantor(application) || isNovatedLease(application.productGroupId))) {
          return false;
        }

        if (downloadableDocument.id === 2 && isNovatedLease(application.productGroupId)) {
          return false;
        }

        /**
         * Insurance documents
         */
        if (downloadableDocument.id === 8) {
          if (!application.applicationState) {
            return false;
          }

          return false;
        }

        if (downloadableDocument.id === 7) {
          if (!application.applicationState) {
            return false;
          }

          if (this.props.application.insuranceSummary) {
            const Vei = this.props.application.insuranceSummary.vehicleEquityInsurance;
            if (Vei && Vei.applicationNumber > 0) {
              return true;
            }
          }

          return false;
        }

        return true;
      }
    });

    return availableDocuments;
  }

  toggleDropdown() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  startProcessing() {
    this.setState({
      processing: true,
    });
  }

  stopProcessing() {
    this.setState({
      processing: false,
    });
  }

  showAgreement(documentId) {
    this.setState({
      agreement: true,
      documentId,
    });
  }

  hideAgreement() {
    this.setState({
      agreement: false,
      documentId: undefined,
    });
  }

  handleClick(type, document) {
    this.toggleDropdown();
    type = type || 'regular';

    if (document.id === 2 && this.props.application.applicationType === 2) {
      /**
         * Open modal
         */
      this.showAgreement(document.id);
    } else if (document.id === 7 || document.id === 8) {
      let insuranceId = 0;

      if (document.id === 7) {
        insuranceId = this.props.application.insuranceSummary.vehicleEquityInsurance.applicationNumber;
      }

      if (document.id === 8) {
        insuranceId = this.props.application.insuranceSummary.loanProtectionInsurances[0].applicationNumber;
      }

      /**
             * Get the document using the insurance number
             *
             * TODO: Need to figure out where to get this number from
             */
      this.getDocument(insuranceId, document.id, type);
    } else {
      /**
             * Get the document using the application number
             */
      this.getDocument(this.props.application.applicationNumber, document.id, type);
    }
  }

  getDocument(applicationNumber, documentId, type) {
    Notify.show('Your download will start shortly...', 'success', 10000);
    const self = this;
    let url;
    switch (type) {
      case 'publish':
        url = `${API.base}documents/publish-docs/${documentId}`;
        break;
      case 'regular':
      default:
        url = `${API.base}documents/pdf/${applicationNumber}/${documentId}`;
        break;
    }

    Axios.get(url, { responseType: 'arraybuffer', timeout: 120 * 1000 })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = pdfBlobUrl;
        downloadLink.download = this.state.downloadableDocuments.filter((doc) => doc.id === documentId)[0].name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(pdfBlobUrl);
      }).finally(() => {
        Notify.hide();
      });

    if (documentId === 2) {
      self.hideAgreement();
    }
  }

  render() {
    if (!this.props.application) {
      return null;
    }

    let disabled = false;

    /**
     * Check if application is not inactive
     */
    if (this.props.application.applicationType != 2 && this.props.application.applicationState == 0) {
      disabled = true;
    }


    /**
     * Check if there are documents to download, if not, we need to abort
     */
    if (!this.state.availableDocuments.length) {
      disabled = true;
    }

    /**
     * We can enable the button if there are published docs
     */
    if (this.props.application.publishDocuments && this.props.application.publishDocuments.length) {
      disabled = false;
    }

    return (
      <div className={`widget dropdown mt-h ${this.state.expanded ? 'open' : ''}`}>
        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Preparing document...</Overlay>
              );
            }
          })()
        }
        {
          canDownloadMotorLoanContract(this.props.application.applicationStatus.code, this.props.application.applicationType)
          && (isMotorConsumer(this.props.application.productGroupId) || isMotorCommercial(this.props.application.productGroupId) || isNovatedLease(this.props.application.productGroupId))
            ? (
              <ApplicationEsigDocument
                showAgreement={false}
                application={this.props.application}
                getApplication={this.props.getApplication}
              />
            )
            : ''
        }

        <button className={`btn btn-left btn-lg btn-block btn-success mt-sm ${(this.state.processing || disabled) ? 'btn-disabled' : ''}`} disabled={(this.state.processing || disabled)} onClick={this.toggleDropdown}>
          <Icon glyph="download" className="btn-icon" />
          {
                  (() => {
                    const count = this.props.application.publishDocuments ? this.props.application.publishDocuments.filter((doc) => doc.isNew).length : 0;

                    if (count) {
                      return (
                        <span className="badge badge-danger badge-round pull-right">{count}</span>
                      );
                    }
                  })()
              }
              Download
          {' '}
          <span className="hidden-sm">Documents</span>
        </button>

        <ul className="dropdown-menu dropdown-menu-block">
          {
                  (() => {
                    if (this.state.availableDocuments.length) {
                      return (
                        <li className="dropdown-header">{this.props.application.applicationType == 1 ? 'Submission Documents' : 'Application Documents'}</li>
                      );
                    }
                  })()
              }
          {
                  this.state.availableDocuments.map((downloadableDocument) => (
                    <li key={`downloadableDocument${downloadableDocument.id}`}><a onClick={() => { this.handleClick('regular', downloadableDocument); }}>{downloadableDocument.name}</a></li>
                  ))
              }

          {
                  (() => {
                    if (this.props.application.publishDocuments && this.props.application.publishDocuments.filter((doc) => doc.type.search('Approval') != -1).length) {
                      return (
                        <li className="dropdown-header">Assessment Documents</li>
                      );
                    }
                  })()
              }
          {
                (() => {
                  if (this.props.application.publishDocuments && this.props.application.publishDocuments.length) {
                    return (
                      this.props.application.publishDocuments.filter((doc) => doc.type.search('Approval') != -1).map((publishDocument) => (
                        <li key={`downloadableDocument${publishDocument.id}`}><a className={publishDocument.isNew ? 'new' : ''} onClick={() => { this.handleClick('publish', publishDocument); }}>{publishDocument.type}</a></li>
                      ))
                    );
                  }
                })()
              }

          {
                  (() => {
                    if (this.props.application.publishDocuments && this.props.application.publishDocuments.filter((doc) => doc.type.search('Approval') == -1).length) {
                      return (
                        <li className="dropdown-header">Settlement Documents</li>
                      );
                    }
                  })()
              }
          {
                (() => {
                  if (this.props.application.publishDocuments && this.props.application.publishDocuments.length) {
                    return (
                      this.props.application.publishDocuments.filter((doc) => doc.type.search('Approval') == -1).map((publishDocument) => (
                        <li key={`downloadableDocument${publishDocument.id}`}><a className={publishDocument.isNew ? 'new' : ''} onClick={() => { this.handleClick('publish', publishDocument); }}>{publishDocument.type}</a></li>
                      ))
                    );
                  }
                })()
              }

        </ul>

        {(() => {
          if (this.props.application && this.props.application.applicationType === 2 && this.props.application.motorPurchaseVehicle) {
            return (
              <ApplicationAgreement
                      getApplication={this.props.getApplication}
                      getDocument={this.getDocument}
                      application={this.props.application}
                      visible={this.state.agreement}
                      hideAgreement={this.hideAgreement}
                      documentId={this.state.documentId}
                      isDocumentDownloadPending={false}
                      submitLabel="Confirm &amp; Download"
                      modalTitle="Confirm Final Details"
                      showContactDetails={false}
                    />
            );
          }
        })()}

      </div>
    );
  }
}

ApplicationDownloadDocuments.propTypes = {
  application: PropTypes.object.isRequired,
  getApplication: PropTypes.func,
  session: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...documentsActions, ...applicationsActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDownloadDocuments);
