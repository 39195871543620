import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps, hashHistory } from 'react-router';
import { Path } from 'history';
import { bindActionCreators} from 'redux';
import { Row, Col} from 'react-bootstrap';
import { Form } from 'formsy-react-components';

import { notify } from 'react-notify-toast';

import * as messages from '../../constants/messages';

import { getLvrDataByPostcode, setLvrFilterValue, LvrData, LvrFilterField } from './actions';
import { getPrequal, PrequalUrlParams, ProfileType, savePrequal } from '../../actions/prequalActions';

import event3rdParty from '../../helpers/event3rdParty';

import { Overlay } from '../../utils';

import LvrHeader from './lvrHeader';
import LvrOptions from './lvrOptions';
import LvrResult from './lvrResult';

import CalculatorPane from '../../components/calculatorPane';
import CalculatorModal from '../../components/calculatorModal';
import Mask from '../../helpers/mask';

import { Guid } from 'guid-typescript';
import { AxiosResponse } from 'axios';

type Prequal = NewPrequal | ExistingPrequal;
interface NewPrequal {
  loading: boolean;
}
interface ExistingPrequal extends NewPrequal {
  profileName: string;
  profileId: Guid;
  lastUpdated: string
  data: any;
}

interface LvrState {
  processing: boolean | string;
  modal: boolean;
  prequal: Prequal;
}

interface LvrProps {
  lvr: LvrData[];
  params:any;
  filter:any;
  actions: {
    getPrequal: any;
    getLvrDataByPostcode: typeof getLvrDataByPostcode;
    setLvrFilterValue: typeof setLvrFilterValue;
    savePrequal: any;
    getLvrByPostcode: any;
  };
}

const isValidPostcode = (postcode: string) => postcode.length === 4;
const isPostcodeField = (field: string) => field === "postcode";

const hasPrequalId = (params: PrequalUrlParams) => !!params.id;
const hasPrequalName = (prequal: any) => !!prequal.profileName;
const hasPrequalData = (response: any) => !!response.data;

const shouldRedirect = (redirectRoute?: string) => !!redirectRoute;
const shouldCreatePrequal = (prequal: any) => !hasPrequalName(prequal);

const mergePrequalData = (profileData, formData, filter) => ({
  ...profileData,
  filter: {
    ...formData,
    ...filter
  }});

export class LvrPage extends React.Component<LvrProps & RouteProps, LvrState> {
  constructor(props) {
    super(props, {});
    this.state = {
      prequal: {
        loading: true
      }, // TODO move to it's own world
      processing: false, // TODO to reducer
      modal: false // TODO to reducer
    };
  }

  UNSAFE_componentWillMount() {
    hasPrequalId(this.props.params)
      ? this.getPrequal(this.props.params)
      : this.initPrequal();
  }

  private initPrequal = () => this.setState({ prequal: { loading: false } });

  private getPrequal = (params: PrequalUrlParams) => {
    this.props.actions.getPrequal(params.id)
      .then((response: AxiosResponse) => {
        if (hasPrequalData(response)) {
          const { profileName, profileId, lastUpdated, value } = response.data;
          const prequal = {
            loading: false,
            profileName,
            profileId,
            lastUpdated,
            data: JSON.parse(value)
          };

          this.setPrequal(prequal);
          this.setState({prequal});
        }
      });
  }

  onFilterChange = (field, value) => {
    this.props.actions.setLvrFilterValue(field, value);

    if (isPostcodeField(field) && isValidPostcode(value)) {
      this.props.actions.getLvrByPostcode(value);
    }
  }

  handlePostcodeChange = (field, value) => {
    this.props.actions.setLvrFilterValue(field, value);
    this.props.actions.getLvrByPostcode(value);
  }

  setProcessing = (processing: boolean | string) => {
    this.setState({
      processing
    });
  }

  setPrequal = (prequal: ExistingPrequal) => {
    (this.refs.form as any).formsyForm.current.reset(Mask.flatten({
      ...prequal.data,
      postcode: prequal.data.filter.postcode
    }));

    if (prequal.data.filter) {
      Object.keys(prequal.data.filter)
        .forEach(key => {
          const value = prequal.data.filter[key];
          key === "postcode"
            ? this.props.actions.getLvrDataByPostcode(value)
            : this.props.actions.setLvrFilterValue(key as LvrFilterField, value);
        });
    }
  }

  setProfile = ({name}) => {
    this.setState({
      prequal: {
        profileName: name,
        profileId: Guid.create(),
        lastUpdated: "",
        data: {},
        loading: false
      }
    }, () => this.savePrequal());

  }

  savePrequal = (redirectRoute?: string) => {
    if (shouldCreatePrequal(this.state.prequal)) {
      this.setModal(true);
    } else {
      this.setProcessing('Saving prequalification...');

      const { profileName, data, profileId } = this.state.prequal as ExistingPrequal;
      const newProfileData = mergePrequalData(data, (this.refs.form as any).formsyForm.current.getModel(), this.props.filter)

      this.props.actions.savePrequal(profileName, newProfileData, profileId, ProfileType.ResidentialLvr)
        .then(() => notify.show(messages.MESS_SUCCESS, 'success'))
        .then(() => this.setProcessing(false))
        .then(() => event3rdParty.send('save-lvr'))
        .then(() => shouldRedirect(redirectRoute) && hashHistory.push(redirectRoute as Path));
    }
  }

  setModal = (modal) => {
    this.setState({
      modal
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div>
        <CalculatorModal visible={this.state.modal} setModal={this.setModal} setProfile={this.setProfile} />
        <CalculatorPane prequal={this.state.prequal} setModal={this.setModal} active="lvr" />

        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>{this.state.processing}</Overlay>
              );
            }
          })()
        }

        <Row>
          <Col sm={12} className="main-panel">
            <Form
              layout="vertical"
              ref="form"
              disabled={false}
              className=""
              elementWrapperClassName=""
              labelClassName=""
              rowClassName=""
              validateBeforeSubmit
              validatePristine>

              <div className="panel panel-default">
                <div className="panel-body p-h">
                  <LvrHeader />

                  <div className="panel-section">
                    <div className='panel-section-header panel-header-fixed mb-md'>
                      <i className='mi mi-map mi-3x ib mr-md text-center' style={{width: '44px'}} />

                      <div className='ib va-tt mt-sm-n'>
                        <h3 className='mb-h mt-h'>Details</h3>
                      </div>
                    </div>

                    <div className='panel-section-body'>
                      <div className="panel-section-container">
                        <LvrOptions />
                      </div>
                    </div>
                  </div>

                  <LvrResult
                    savePrequal={this.savePrequal} />
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
    calculators: state.calculators,
    data: state.lvr.data,
    filter: state.lvr.filter,
    prequal: state.prequal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getPrequal, savePrequal, getLvrDataByPostcode, setLvrFilterValue }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LvrPage);

