import * as React from 'react';
import PropTypes from 'prop-types';

export default class ProfileCompany extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  render() {
    return (
      <div className="panel-part">
        <h5 className="panel-bar">Company</h5>

        <dl className="dl-horizontal">
          <dt>Company</dt>
          <dd data-hj-masked>{this.props.getValue(this.props.details.companyName)}</dd>
        </dl>
      </div>
    );
  }
}

ProfileCompany.propTypes = {
  details: PropTypes.any,
  getValue: PropTypes.func,
};
