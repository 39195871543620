import * as React from 'react';
import PropTypes from 'prop-types';

import {
  DropdownButton, MenuItem, Row, Col,
} from 'react-bootstrap';

import { Icon } from '../../utils';

import ApplicationsListItem from './applicationsListItem';
import ApplicationsPartialListItem from './applicationsPartialListItem';
import ApplicationsListEmpty from './applicationsListEmpty';
import ApplicationSubmitPane from './applicationSubmitPane';
import ApplicationPartialMotorListItem from './applicationPartialMotorListItem';

import SearchPane from '../../components/searchPane';


const ApplicationsList = (props) => (
  <div>
    <Row className="mt-sm mb-md">
      <Col sm={6} xs={8}>
        <SearchPane query={props.applications.query} placeholder="Search applications" onSearch={props.searchApplications} />
      </Col>

      <Col sm={4} xs={4}>
        <div className={`panel-filter ib animate ${props.applications.query ? 'transparent' : ''}`}>
          <DropdownButton
            id="filterApplications"
            className="btn-link btn-primary mt-sm p-h"
            pullRight
            title={(
              <span>
                <Icon glyph="filter-list" size="lg" className="mr-sm mute-lg" />
                <span className="hidden-xs">Filter</span>
              </span>
            )}
          >
            <MenuItem className={props.applications.filter == 2 ? 'active' : ''} id="filterApplicationsAll" onSelect={(() => { props.filterApplications(2); })}>All</MenuItem>
            <MenuItem className={props.applications.filter == 1 ? 'active' : ''} id="filterApplicationsActive" onSelect={(() => { props.filterApplications(1); })}>Current Applications</MenuItem>
            <MenuItem className={props.applications.filter == 0 ? 'active' : ''} id="filterApplicationsInactive" onSelect={(() => { props.filterApplications(0); })}>Past Applications</MenuItem>
          </DropdownButton>
        </div>

        <div className={`panel-sort ib animate ml-md ${props.applications.query ? 'transparent' : ''}`}>
          <DropdownButton
            id="sortApplications"
            className="btn-link btn-primary mt-sm p-h"
            pullRight
            title={(
              <span>
                <Icon glyph="sort-amount-asc" size="lg" className="mr-sm mute-lg" />
                <span className="hidden-xs">Sort</span>
              </span>
            )}
          >
            <MenuItem className={props.applications.order == 'submitted_desc' ? 'active' : ''} id="sortApplicationsSubmitted" onSelect={(() => { props.sortApplications('submitted_desc'); })}>Submitted Date</MenuItem>
            <MenuItem className={props.applications.order == 'status_desc' ? 'active' : ''} id="sortApplicationsProgress" onSelect={(() => { props.sortApplications('status_desc'); })}>Application progress</MenuItem>
            <MenuItem className={props.applications.order == 'actions_desc' ? 'active' : ''} id="sortApplicationsActions" onSelect={(() => { props.sortApplications('actions_desc'); })}>Actions required</MenuItem>
            <MenuItem className={props.applications.order == 'modified_desc' ? 'active' : ''} id="sortApplicationsModified" onSelect={(() => { props.sortApplications('modified_desc'); })}>Last modified Date</MenuItem>
            <MenuItem className={props.applications.order == 'name_desc' ? 'active' : ''} id="sortApplicationsName" onSelect={(() => { props.sortApplications('name_desc'); })}>Primary applicant</MenuItem>
          </DropdownButton>
        </div>
      </Col>

    </Row>

    <div className="panel panel-default">
      <div className="panel-body p-h loan-list">
        <ApplicationSubmitPane
          visible={props.hasSubmit()}
          showMotorApplicationButton={props.isMotor}
          showResApplicationButton={!!props.isResSubmission && !!props.isUAFUser}
          onSubmitMotorClick={props.onSubmitMotorClick}
        />
        {
          props.applications.partialMotor
          && props.applications.partialMotor.results
          && props.isMotor
          && props.applications.partialMotor.results
            .map((application) => <ApplicationPartialMotorListItem key={application.applicationId} application={application} />)
        }

        {
          (() => {
            if (props.applications.list.length > 0) {
              props.applications.partial
              && props.applications.partial
                .filter((application) => !application.submitted)
                .map((application) => <ApplicationsPartialListItem key={application.applicationId} application={application} />);
            }
          })
        }

        {
          (() => {
            if (props.applications.list.length) {
              return props.applications.list.map((application, idx) => (
                <ApplicationsListItem application={application} key={`application-${idx}`} />
              ));
            }
            if ((!props.applications.partialMotor || !props.applications.partialMotor.results || props.applications.partialMotor.results.length == 0)
              && (!props.applications.partial || props.applications.partial.length == 0)) {
              return (
                <ApplicationsListEmpty filterApplications={props.filterApplications} applications={props.applications} />
              );
            }
          })()
        }
      </div>

    </div>

    {
      (() => {
        if (props.applications.other && props.applications.filter !== 2) {
          return (
            <div className="text-center">
              <Icon glyph="filter-list" size="lg" className="mute-lg mr-xs" />
              {' '}
              <a href="javascript:void(0)" onClick={() => { props.filterApplications(2); }}>
                {'Show '}
                {props.applications.other}
                {' application'}
                {props.applications.other !== 1 ? 's' : ''}
              </a>
              {' hidden by filters'}
            </div>
          );
        }
      })()
    }
  </div>
);

ApplicationsList.propTypes = {
  applications: PropTypes.object.isRequired,
  filterApplications: PropTypes.func.isRequired,
  sortApplications: PropTypes.func.isRequired,
  searchApplications: PropTypes.func.isRequired,
  hasSubmit: PropTypes.func.isRequired,
  isMotor: PropTypes.bool.isRequired,
  isResSubmission: PropTypes.bool.isRequired,
  onSubmitMotorClick: PropTypes.func.isRequired,
  isUAFUser: PropTypes.bool.isRequired,
};

export default ApplicationsList;
