import Formsy from 'formsy-react';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ApplicationUpdateHeader from './applicationUpdateHeader';

import * as referenceActions from '../../actions/referenceActions';
import { Col, Row } from 'react-bootstrap';
import { Select, Textarea } from 'formsy-react-components';

type ApplicationUpdateDetailsProps = {
  application: any,
  params: any,
  formsy: Formsy | null,
}

type ApplicationUpdateDetailsState = {
  visible: boolean,
  editOptions: ApplicationEditOption[]
}

type ApplicationEditOption = {
  name: string,
  label: string,
  visible: boolean
}

export class ApplicationUpdateDetails extends React.Component<ApplicationUpdateDetailsProps, ApplicationUpdateDetailsState> {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.params.tab == 'details',
      editOptions: [
        {
          name: "",
          label: "",
          visible: false
        },
        ...
        [
          "Applicant Name",
          "Applicant Date of Birth",
          "Residential Address",
          "Driver License No",
          "Income Details",
          "Asset Details",
          "Liability Details",
          "Other"
        ].map(optionName => ({
          name: `manualEdits.${optionName}`,
          label: optionName,
          visible: false
        }))
      ]
    };

    this.toggleSection = this.toggleSection.bind(this);
    this.showEditOption = this.showEditOption.bind(this);
    this.removeEditOption = this.removeEditOption.bind(this);
  }

  toggleSection() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  showEditOption() {
    const model = this.props.formsy?.getModel();
    const selectedOption = this.state.editOptions.filter(o => o.name == model.manualEdits.selectedEditOption);
    selectedOption[0].visible = true;
    this.setState({ editOptions: this.state.editOptions });
  }

  removeEditOption(evt) {
    evt.preventDefault();
    evt.persist();

    const option = this.state.editOptions.filter(o => o.name == evt.target.getAttribute('data-value'));
    option[0].visible = false;
    this.setState({ editOptions: this.state.editOptions });

    return false;
  }

  render() {
    const visibleOptions = this.state.editOptions.filter(o => o.visible);
    const dropdownOptions = this.state.editOptions.filter(o => !o.visible).map(o => ({ label: o.label, value: o.name }));

    return (
      <div className={`panel-section ${this.state.visible ? '' : 'collapsed'}`}>
        <ApplicationUpdateHeader
          title="Application Edits"
          description="Update loan application details for further assessment"
          glyph="edit"
          section="details"
          toggleSection={this.toggleSection}
        />

        <div className="panel-section-body">
          <div className="panel-section-container mt-sm">

            {visibleOptions.map((opt) => (
              <Row key={opt.name}>
                <Col sm={12}>
                  <Textarea
                    name={opt.name}
                    id={opt.name}
                    placeholder="Type your edits here"
                    label={opt.label}
                  />

                  <a
                    onClick={this.removeEditOption}
                    className="btn btn-link"
                    data-value={opt.name}
                    style={{ position: 'absolute', right: 0, top: -4 }}> Remove </a>
                </Col>
              </Row>
            ))}

            {visibleOptions.length > 0 && <hr />}

            <Row>
              <Col sm={7}>
                <Select
                  name="manualEdits.selectedEditOption"
                  id="manualEdits.selectedEditOption"
                  label="Select an item to add changes"
                  className="form-control"
                  value=""
                  options={dropdownOptions}
                />
              </Col>
              <Col sm={2} style={{ marginTop: 30 }}>
                <a id="addOptionButton" onClick={this.showEditOption} className="btn btn-success btn-sm"> Add </a>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateDetails);