import * as React from 'react';
import PropTypes from 'prop-types';
import {IOption} from '../../interfaces';
import {IFormData, IDataLabel} from './interfaces';
import Mask from '../../helpers/mask';

function strip(string: string): string {
    return string
        .replace(/[&,\/]/g, ' ')
        .replace('  ', ' ')
        .toLowerCase();
}

function camelCase(string: string): string {
    return string
        .split(' ')
        .map((word, index) => index ? word.charAt(0).toUpperCase() + word.slice(1) : word)
        .join('')
}

function convert(string: string): string {
    string = strip(string);
    string = camelCase(string);

    return string;
}

export function getCurrentFormData(form): any {
    return form.formsyForm.current.getModel();
}

export function hasFormData(data, label: IDataLabel): boolean {
    return !!data[label];
}

export function setFormData(data: IFormData, label: IDataLabel, form) {
    setTimeout(() => {
        let flatData = Mask.flatten(data[label]);
        for (let key in flatData) {
            let newKey = String(key);

            if (label === "incomes") {
                newKey = "incomes." + newKey;
            }

            newKey = newKey.replace(/\.(\d+)\./g, '[$1].');
            newKey = newKey.replace(/\.\./g, '.');

            if (key != newKey) {
                flatData[newKey] = flatData[key]
                delete flatData[key];
            }
        }

        let formsy = form.formsyForm.current;
        formsy.inputs.forEach(input => {
            input.setValue(flatData[input.props.name])

            let event = {
              target: input["element"] || { value: input.getValue(), name: input.props.name }
            };

            typeof input.props.blurCallback == "function" && input.props.blurCallback(event.target.name, event.target.value);
            typeof input.props.onKeyUp == "function" && input.props.onKeyUp(event);
            typeof input.props.changeCallback == "function" && input.props.changeCallback(event.target.name, event.target.value);

            let field = document.getElementById(`${input.props.name}`);
            field && field.dispatchEvent(new Event("onInject"));
        });
    });
}

export const shouldFormBeVisible = (currentStep: number, formStep: number): string => {
    //return "form form-submit";
    return currentStep != formStep ? 'hidden' : 'form form-submit';
}

export const firstOption = (options: IOption[] = []): string | number | undefined => {
    if (options && options.length) {
        return options[0].value;
    }

    return undefined;
}

export const toOption = (option: string, shouldConvert: boolean = true): IOption => ({
    label: option,
    value: !shouldConvert ? option : convert(option)
});

export const toOptionGroup = (option: string, group: string): IOption => ({...toOption(option), group});

export const toRequired = (label: string | JSX.Element): JSX.Element => (<span><span className="danger">●</span> {label}</span>);

export const selectText = (refs: any, event: FocusEvent) => {
    let target = event.target as HTMLInputElement;
    if (target.value == "0") {
        refs[target.name].element.select();
    }
}
