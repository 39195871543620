import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

export const ComServiceAbilitySubmitNow = (props) => {
  if (!props.shouldShowSubmitNow) {
    return null;
  }

  return (
    <div className="loan-submit ml-md-n mr-md-n mb-lg b" style={{ marginTop: '-12px', width: 'auto' }}>
      <h4>Whoohoo</h4>

      <p className="text-lg white mute mb-lg">
        We&apos;re making submitting applications to us as fast and fuss-free as possible and you&apos;ve got early access! There are still some conditions, but by the looks of it this one fits the bill!
      </p>
      <Link to="/applications/submit/residential" className="btn btn-lg btn-primary">Take me there</Link>
    </div>
  );
};

ComServiceAbilitySubmitNow.propTypes = {
  shouldShowSubmitNow: PropTypes.bool,
};

ComServiceAbilitySubmitNow.defaultProps = {
  shouldShowSubmitNow: true,
};

export default ComServiceAbilitySubmitNow;
