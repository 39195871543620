import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../utils';
import {ISubmittedFailedApi, IExplosion} from '../reducer';

interface IErrorProps {
    submission: ISubmittedFailedApi | IExplosion;
    resetModal: () => void;
    retrySubmit: () => void;
}

export const Error = (props: IErrorProps) => {
    return (
        <div className="text-center mt-lg mb-lg">
            <Icon glyph="alert-circle-o" className="text-danger" size={4} />

            <h4 className="pt-lg mt-lg">Something went wrong</h4>
            <p className="text-lg mt-sm mb-lg mute">You could try again or call 13 11 33...</p>

            <button className="btn btn-simple btn-default mt-lg" onClick={props.retrySubmit}>
                <span className="secondary">Try again</span>
            </button>

            <button className="btn btn-simple btn-default mt-lg" onClick={props.resetModal}>
                Close
            </button>
        </div>
    );
}

export default Error;
