import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingStepConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="onboarding-step text-center">
        <h2 className="onboarding-title">Confirm your details</h2>
        <h4 className="onboarding-subtitle mute">
        We’ve imported your profile from LoanNet.
        <br className="hidden-xs" />
        Please take a minute to confirm all your details are up to date.
        </h4>
      </div>
    );
  }
}
