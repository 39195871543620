import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Icon } from '../../utils';

export class LoannetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className="mt-lg mb-md text-center">

        <Row>
          <Col sm={10} smOffset={1}>
            <div className="panel panel-default">
              <div className="panel-body pt-lg pb-lg">

                <Row>
                  <Col sm={8} smOffset={2}>

                    <Icon glyph="desktop-windows" size={4} className="mute-lg mt-lg" />

                    <h2>
                    &nbsp;LoanNET
                    <sup>&reg;</sup>
                    </h2>
                    <h4 className="mute">Liberty&#39;s patented online and offline application and approval system. </h4>

                    <p className="mt-md">With a conditional approval within seconds you can enjoy the best range of specialty lending products in the industry from the people who invented them.</p>

                    <p>
                      <a href="https://bsp.liberty.com.au/Downloads/LoanNET2007.msi" className="btn btn-lg btn-secondary mt-lg ">
                      Download LoanNET
                      <sup>&reg;</sup>
                      </a>
                      <br />
                      <small className="mute ib mt-sm">(Windows only)</small>
                    </p>


                  </Col>
                </Row>
              </div>
            </div>


          </Col>
        </Row>


      </div>
    );
  }
}

LoannetPage.propTypes = {
};

export default LoannetPage;
