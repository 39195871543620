import { MotorPrequalAction, MotorPrequalActionTypes } from './actions'
import PMT from '../../helpers/pmt';

export enum AssessmentResult {
  Accepted = "Success",
  Declined = "Failure",
  None = "None"
}

interface MotorPrequalState {    
    hasVei:Boolean
    hasLpi:Boolean
    riskGrade: string
    interestRate: number
    calculatedInterestRate : number
    repayment: number
    loanTermInMonths: number
    loanAmount: number
    minCommission: number
    maxCommission: number
    commission: number
    rateDiscount: number
    referMessage: string
    equalisationFee: number
    assessmentResult: AssessmentResult
}

export const initialState: MotorPrequalState = {
    hasVei:false,
    hasLpi:false,
    riskGrade: "string",
    interestRate: 0,
    calculatedInterestRate: 0,
    repayment: 0,
    loanTermInMonths: 0,
    loanAmount: 0,
    minCommission: 0,
    maxCommission: 0,
    commission: 0,
    rateDiscount: 0,
    equalisationFee: 0,
    referMessage: "",
    assessmentResult: AssessmentResult.None
}

const getMMP = (interestRate: number, loanTermInMonths: number, loanAmount: number) =>
 PMT(interestRate / 1200, loanTermInMonths, loanAmount);

const changeDiscountRate = (state: MotorPrequalState, rateDiscount: number) => {
    const newInterestRate = state.interestRate - rateDiscount / 100;
    const pmt = getMMP(newInterestRate, state.loanTermInMonths, state.loanAmount);
    const comm = state.minCommission + ((state.maxCommission - state.minCommission) * (200 - rateDiscount) / 200);

    return {
        ...state,
        calculatedInterestRate: newInterestRate,
        repayment: pmt,
        commission: comm,
        rateDiscount: rateDiscount
    }
}

export const motorPrequalReducer = (state: MotorPrequalState = initialState, action: MotorPrequalAction): MotorPrequalState => {
    switch (action.type) {
        case MotorPrequalActionTypes.GetPrequalResultAction:
            return {
              ...state              
            }
        case MotorPrequalActionTypes.SetPrequalResultAction:
            const result = action.prequalResult;
            const assessmentResult = result.riskGrade === "Rejected"
              ? AssessmentResult.Declined
              : AssessmentResult.Accepted

            return {
                ...state,
                loanTermInMonths: result.loanTermInMonths,
                loanAmount: result.loanAmount,
                riskGrade: result.riskGrade,
                interestRate: result.interestRate,
                calculatedInterestRate: result.interestRate,
                repayment: getMMP(result.interestRate, result.loanTermInMonths, result.loanAmount),
                minCommission: result.commission.minimum,
                maxCommission: result.commission.maximum,
                commission: result.commission.maximum,
                equalisationFee: result.equalisationFee,
                referMessage: result.referMessage,
                assessmentResult: assessmentResult,
                rateDiscount:0
            };

        case MotorPrequalActionTypes.ChangeDiscountRateAction:
            return changeDiscountRate(state, action.rateDiscount);
        
        case MotorPrequalActionTypes.ChangeHasVeiAction:
            return {
                ...state,
                hasVei: action.hasVei
            };

        case MotorPrequalActionTypes.ChangeHasLpiAction:
            return {
                ...state,
                hasLpi: action.hasLpi
            };

        default:
            return state
    }
}

