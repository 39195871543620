import * as React from 'react';
import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import Mask from '../../../helpers/mask';

import * as Guid from 'guid';

import {toOption, hasFormData, setFormData} from '../functions';

import Navigation from '../navigation';
import Add from '../add';
import Header from '../header';
import Title from '../title';

import {debug} from '../debug';

import {shouldFormBeVisible} from '../functions';

import Purchase from './purchase';
import Personal from './personal';
import Refinance from './refinance';

import {IBaseProps, IGuid, ILiability, ILiabilityItem, ILoanLiability, IFormData} from '../interfaces';
import {IOption} from '../../../interfaces';
import AddButton from '../addButton';

interface IPurposeItem {
  id: string,
  type: string,
  amount: number
}

interface IPurposeProps extends IBaseProps {}

interface IPurposeState {
  purposes: IPurposeItem[],
  totalPersonalAmount: number,
  totalPurchaseAmount: number
}
const form: React.RefObject<Form> = React.createRef();

export class Purpose extends React.Component<IPurposeProps, IPurposeState> {
  constructor(props) {
    super(props)

    this.state = {
      purposes: [],
      totalPersonalAmount: 0,
      totalPurchaseAmount: 0
    };

    this.addPurpose = this.addPurpose.bind(this);
    this.removePurpose = this.removePurpose.bind(this);

    this.updateTotalPersonalAmount = this.updateTotalPersonalAmount.bind(this);
    this.updatePurchaseAmount = this.updatePurchaseAmount.bind(this);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (hasFormData(this.props.data, 'purpose')) {
      (this.props.data.purpose.purchases || []).forEach(purchase => {
        this.addPurpose(toOption("Purchase property"))
      });
    }

  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'purpose')) {
      setFormData(this.props.data, 'purpose', form.current);
    }
  }

  addPurpose(option : IOption) {
    const purposes = this.state.purposes;
    purposes.push({
      id: Guid.create().value,
      type: String(option.value),
      amount: 0
    });

    this.setState({purposes});
  }

  removePurpose(id: IGuid) {
    const purposes = this.state.purposes.filter(purpose => purpose.id != id);
    this.setState({purposes});
  }

  updateTotalPersonalAmount(totalPersonalAmount: number) {
    this.setState({totalPersonalAmount});
  }

  updatePurchaseAmount(id: IGuid, amount: number) {
    let purposes = this.state.purposes;
    purposes.map(purpose => {
      if (purpose.id == id) {
        purpose.amount = amount;
      }

      return purpose;
    });

    this.setState({purposes});
  }

  hasPurpose(purposes: IPurposeItem[]): boolean {
    return !!purposes.length;
  }

  hasRefinance(liabilities: ILoanLiability[]): boolean {
    return liabilities.some(liability => !!liability.refinance);
  }

  hasEnteredPurposeOrRefinance(): boolean {
    return this.hasPurpose(this.state.purposes) || this.hasRefinance(this.props.data.liabilities.liabilities);
  }

  hasSecurities(data): boolean {
    let hasAssetSecurities = this.props.data.assets.specificAssets && this.props.data.assets.specificAssets.some(asset => asset.security);
    let hasPurchaseSecurities = (data.purchases || []).some(purchase => purchase.security);

    return hasAssetSecurities || hasPurchaseSecurities;
  }

  onValidSubmit(data: IFormData) {
    // Can't move on if there are 0 purpose and 0 refinance...
    if (!this.hasEnteredPurposeOrRefinance.call(this)) {
      return this.props.notifyStep("No purpose entered...");
    }

    // Can't move on if there are no securities...
    if (!this.hasSecurities.call(this, data)) {
      return this.props.notifyStep("No securities entered...");
    }

    this.props.saveStepData(data, 'purpose');
  }

  onInvalidSubmit() {
    this.props.notifyStep();
  }

  isRefinanced(liability) {
    return liability.refinance;
  }

  hasRefinances(liabilities) {
    return liabilities && liabilities.some(liability => this.isRefinanced(liability))
  }

  get options(): IOption[] {
    return [
      "Purchase property",
    ].map(o => toOption(o));
  }

  get totalPurchaseAmount(): number {
    return this.state.purposes.reduce((value, purpose) => value + purpose.amount, 0);
  }

  get totalPersonalAmount(): number {
    return this.state.totalPersonalAmount;
  }

  get totalRefinanceAmount(): number {
    return this.props.data.liabilities.liabilities
      ? this.props.data.liabilities.liabilities
          .filter(liability => this.isRefinanced(liability))
          .reduce((sum, liability) => (sum += Number(Mask.clean(liability.balance))), 0)
      : 0;
  }

  get totalLoanAmount(): number {
    return this.totalPurchaseAmount + this.totalPersonalAmount + this.totalRefinanceAmount;
  }

  mapIcon(option: IOption): string {
    switch (option.value) {
      case 'purchaseProperty':
      default: return 'home';
    }
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        className={shouldFormBeVisible(this.props.currentStep, 4)}
        ref={form}
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">
            <Header title="Purpose" required />

            <div className="panel-section">
              <Title noborder>Purpose</Title>
              <div className="panel-section-body panel-section-body-simple">
                <div className="panel-section-container panel-section-container-well">
                  {
                    this.state.purposes.map((purpose, index) => {
                      switch (purpose.type) {
                        case 'purchaseProperty': {
                          return <Purchase form={form} key={purpose.id} index={index} id={purpose.id} data={this.props.data} removePurpose={this.removePurpose} updatePurchaseAmount={this.updatePurchaseAmount} />
                        }
                      }
                    })
                  }

                  <div className="panel-section-btns">
                    {
                      this.options.map(option => {
                        return (
                          <AddButton
                            glyph={this.mapIcon(option)}
                            label={option.label}
                            onClick={this.addPurpose.bind(this, option)} />
                        );
                      })
                    }
                  </div>
                </div>
              </div>

              {
                (() => {
                  if (this.hasRefinances(this.props.data.liabilities.liabilities)) {
                    return (
                      <div>
                        <Title noborder>Refinance</Title>
                        <div className="panel-section-body panel-section-body-simple">
                          <div className="panel-section-container panel-section-container-well">
                            {
                              this.props.data.liabilities.liabilities
                                .filter(liability => this.isRefinanced(liability))
                                .map((liability, index) => {
                                return (
                                  <Refinance key={'refinance' + liability.id} liability={liability} index={index} />
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    );
                  }
                })()
              }

              <Personal form={form} updateTotalPersonalAmount={this.updateTotalPersonalAmount} />

              <Title>Total</Title>
              <div className="panel-section-body panel-section-body-simple">
                <Row>
                  <Col sm={9} className="text-right mute" style={{paddingTop: '10px'}}>
                    Total personal use
                  </Col>
                  <Col sm={3}>
                    <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
                    <Input
                      name={`totals.totalPersonalAmount`}
                      className="form-control text-right"
                      value={Mask.number(this.totalPersonalAmount)}
                      disabled />
                  </Col>
                </Row>

                {
                  (() => {
                    if (this.hasRefinances(this.props.data.liabilities.liabilities)) {
                      return (
                        <Row>
                          <Col sm={9} className="text-right mute" style={{paddingTop: '10px'}}>
                            Total refinance use
                          </Col>
                          <Col sm={3}>
                            <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
                            <Input
                              name={`totals.totalRefinanceAmount`}
                              className="form-control text-right"
                              value={Mask.number(this.totalRefinanceAmount)}
                              disabled />
                          </Col>
                        </Row>
                      );
                    }
                  })()
                }

                <Row>
                  <Col sm={9} className="text-right mute" style={{paddingTop: '10px'}}>
                    Total purpose use
                  </Col>
                  <Col sm={3}>
                    <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
                    <Input
                      name={`totals.totalPurchaseAmount`}
                      className="form-control text-right"
                      value={Mask.number(this.totalPurchaseAmount)}
                      disabled />
                  </Col>
                </Row>

                <hr />

                <Row>
                  <Col sm={9} className="text-right mute" style={{paddingTop: '10px'}}>
                    Total loan amount
                  </Col>
                  <Col sm={3}>
                    <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
                    <Input
                      name={`totals.totalLoanAmount`}
                      className="form-control text-right"
                      value={Mask.number(this.totalLoanAmount)}
                      disabled />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <Navigation previousStep={this.props.previousStep} />
      </Form>
    );
  }
}

export default Purpose;
