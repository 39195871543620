import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Icon } from '../../utils';
import { Vehicle } from './actions';
import Mask from '../../helpers/mask';

interface Application {
    motorPurchaseVehicle: MotorPurchaseVehicle;
    applicationState: number;
    applicationNumber: number;
    applicationType: number;
}

interface MotorPurchaseVehicle {
    vehicleRegistrationNumber: string,
    nvic: string;
    make: string;
    model: string;
    year: string;
}

type ApplicationSecurityMotorVehicleProps = {
  vehicle: Vehicle,
  application: Application,
}

export const ApplicationSecurityMotorVehicle = ({ vehicle, application }: ApplicationSecurityMotorVehicleProps) => (
  <div className="security security-single">
    {
      !!application.motorPurchaseVehicle.nvic
        ? (
          <>
            <h4>
              {Mask.ucfirst(vehicle.make)}
              {' '}
              {Mask.ucfirst(vehicle.model)}
            </h4>
            <p className="mute ellipsis">
              {vehicle.year}
              {' '}
              {vehicle.category}
            </p>
          </>
        )
        : (
          <>
            <h4>
              {`${Mask.ucfirst(application.motorPurchaseVehicle.make)} ${Mask.ucfirst(application.motorPurchaseVehicle.model)}`}
            </h4>
            <p className="mute ellipsis">
              {application.motorPurchaseVehicle.year}
            </p>
          </>
        )
    }

    <div className="security-details">
      {
          !!application.motorPurchaseVehicle.vehicleRegistrationNumber
            ? (
              <p>
                <span>
                  <small className="mute">
Registration
                    <br />
                  </small>
                  <span>{application.motorPurchaseVehicle.vehicleRegistrationNumber}</span>
                </span>
              </p>
            )
            : ''
        }
      {
          application.applicationState
            ? (
              <Link className="btn btn-secondary btn-circle card-btn" to={`/applications/${application.applicationNumber}/update/vehicle`}>
                <Icon glyph="edit" />
              </Link>
            )
            : ''
        }
    </div>
  </div>
);

function mapStateToProps({ motorApplication }) {
  return {
    vehicle: motorApplication.vehicle,
  };
}

export default connect(mapStateToProps, {})(ApplicationSecurityMotorVehicle);
