import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeDiscountRate } from './actions'

interface SliderProps {
    rateDiscount: number;
    actions: {
        changeDiscountRate: typeof changeDiscountRate
    }
}

const maxRateDiscountAllowed: number = 2;

export const MotorPrequalSlider = (props: SliderProps) => (
    <Row>
        <Col sm={2} className="hidden-xs pr-h mute text-right">
            <h5 style={{ paddingTop: '35px' }}>0%</h5>
        </Col>
        <Col sm={8}>
            <h5 className='value pt-h text-center mb-sm'>{(props.rateDiscount / 100).toFixed(2)}% <span className="mute f-sm">Rate Discount</span></h5>
            <Slider
                min={0}
                max={maxRateDiscountAllowed * 100}
                step={5}
                value={props.rateDiscount}
                orientation='horizontal'
                onChange={(value) => props.actions.changeDiscountRate(value)}
            />
        </Col>
        <Col sm={2} className="hidden-xs pl-h mute">
            <h5 style={{ paddingTop: '35px' }}>{maxRateDiscountAllowed}%</h5>
        </Col>
    </Row>)

function mapStateToProps({ motorPrequal }) {
    return {
        rateDiscount: motorPrequal.rateDiscount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ changeDiscountRate }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MotorPrequalSlider);
