import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Input } from 'formsy-react-components';

import { setLvrFilterValue, LvrFilter, getLvrDataByPostcode } from './actions';

import LvrToggle from './lvrToggle';

export interface LvrOptionsProps {
  filter: LvrFilter;
  actions: {
    setLvrFilterValue: typeof setLvrFilterValue;
    getLvrDataByPostcode: typeof getLvrDataByPostcode;
  };
}

export class LvrOptions extends React.Component<LvrOptionsProps, {}> {
  constructor(props: LvrOptionsProps) {
    super(props);
  }

  private handlePostcodeChange = (_, postcode: string, e) => {
    this.props.actions.getLvrDataByPostcode(postcode);
    this.props.actions.setLvrFilterValue('postcode', postcode);
  }

  public render() {
    const lvrToggleProps = {
      filter: this.props.filter,
      changeCallback: this.props.actions.setLvrFilterValue
    }

    return (
      <div>
        <Row>
          <Col sm={12}>
            <Input
              autoFocus
              id="postcode"
              name="postcode"
              type="tel"
              maxLength="4"
              placeholder="Type a postcode"
              className="form-control form-control-hg mb-lg"
              label="Postcode"
              value=""
              changeDebounceInterval={0}
              changeCallback={this.handlePostcodeChange}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <LvrToggle
              label="Purpose"
              name="purpose"
              options={
                [{
                  label: "Owner",
                  value: "Owner Occupied",
                }, {
                  label: "Investor",
                  value: "Investor"
                }]
              }
              {...lvrToggleProps}
            />

            <div className="hidden-xs ib mr-md" />

            <LvrToggle
              label="Verification level"
              name="verificationLevel"
              options={
                [{
                  label: "Full doc",
                  value: "Star",
                }, {
                  label: "Low doc",
                  value: "Nova",
                }, {
                  label: "Private",
                  value: "Private"
                }]
              }
              {...lvrToggleProps}
            />

            <div className="hidden-xs ib mr-md" />

            <LvrToggle
              label="Property type"
              name="propertyType"
              tooltip="House includes townhouses. <br />Unit includes apartments and flats."
              options={
                [{
                  label: "House",
                  value: "House"
                }, {
                  label: "Unit",
                  value: "Unit"
                }]
              }
              {...lvrToggleProps}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.lvr.filter
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setLvrFilterValue, getLvrDataByPostcode }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LvrOptions);

