import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon, Money, DateTime } from '../../utils';

export class ApplicationSecurityResiValuation extends React.Component {
  constructor(props) {
    super(props, {});
    this.state = {
    };
  }

  render() {
    return (
          <p className="mt-md mb-h">
            <Icon glyph="assignment" className={this.props.valuationStatus.code == '520' ? 'success' : 'mute-md'} />
&nbsp;
{
                    (() => {
                      if (this.props.security.valuationValueAmt > 1) {
                        return (
                          <span>
                          Valuation completed at
                          <br />
                          <strong><Money round={0}>{this.props.security.valuationValueAmt}</Money></strong>
                          </span>
                        );
                      } if (this.props.security.inspectionDT) {
                        return (
                        <span>
                        Valuation booked for
                        <br />
                        <strong><DateTime>{this.props.security.inspectionDT}</DateTime></strong>
                        </span>
                        );
                      }

                      return this.props.valuationStatus.description;
                    })()
                }
          </p>
    );
  }
}

ApplicationSecurityResiValuation.propTypes = {
  valuationStatus: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

export default ApplicationSecurityResiValuation;
