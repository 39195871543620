import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Select, Checkbox } from 'formsy-react-components';

import { submitLfiProtectLead } from './actions';
import { VeiOption } from "../applicationUpdatePage/applicationUpdateVei";
import { convertToLfiVeiOptions } from '../applicationUpdatePage/helper'

export interface LfiProtectPrequalDetailsProps {
  veiOptions: VeiOption[],
  lpiOptions: VeiOption[],
  submitLfiProtectLead: Function
}

interface ILfiProtectInfo {	
  veiProductNumber: number
  lpiProductNumber: number
}

export class LfiProtectPrequalDetails extends React.Component<LfiProtectPrequalDetailsProps, ILfiProtectInfo> {
  constructor(props: LfiProtectPrequalDetailsProps) {
      super(props, {});	

      this.state = {
        veiProductNumber: -1,
        lpiProductNumber: -1
      }
      
      this.handleSubmit = this.handleSubmit.bind(this);
      this.updateVeiProductOption =  this.updateVeiProductOption.bind(this);
      this.updateLpiProductOption = this.updateLpiProductOption.bind(this);
      this.validateProductOption =  this.validateProductOption.bind(this);
  }	

  getLoanTypes() {
    return [
      {
        value: '',
        label: 'Please select'
      },
      {
        value: 'Unsecured Personal Loan',
        label: 'Unsecured Personal Loan'
      },
      {
        value: 'Secured Personal Loan',
        label: 'Secured Personal Loan'
      },
      {
        value: 'Motor Loan',
        label: 'Motor Loan'
      },
      {
        value: 'Other Loan',
        label: 'Other Loan'
      }
    ]
  }

  updateVeiProductOption(_, veiProductNumber: string){
    this.setState({veiProductNumber: Number(veiProductNumber)})
  }

  updateLpiProductOption(_, lpiProductNumber: string){
    this.setState({lpiProductNumber: Number(lpiProductNumber)})
  }	

  validateProductOption(){
    return this.state.veiProductNumber > 0 || this.state.lpiProductNumber > 0;
  }

  handleSubmit(data){
    this.props.submitLfiProtectLead(data)
  }

  handleClear(){
    window.location.reload(true);
  }

  render() {
    return <Form
      onValidSubmit={this.handleSubmit}
      layout="vertical"
      ref="form"
      disabled={false}
      className=""
      elementWrapperClassName=""
      labelClassName=""
      rowClassName=""
      validateBeforeSubmit>
        
      <div className="panel-section">	
        <div className="panel-section-header panel-header-fixed mb-md">
        </div>
        <div className="panel-section-body">
          <div className="panel-section-container">
            <Row>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Input
                  id="firstName"
                  name="firstName"
                  ref="firstName"
                  type="text"
                  label="First name"                  
                  className="form-control form-control-lg"
                  validationError="First name is required"
                  validations={{
                    isRequired: true
                  }}
                />
              </Col>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Input
                  id="lastName"
                  name="lastName"
                  ref="lastName"
                  type="text"
                  label="Last name"                  
                  className="form-control form-control-lg"
                  validationError="Last name is required"
                  validations={{
                    isRequired: true
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Input
                  id="emailAddress"
                  name="emailAddress"
                  ref="emailAddress"
                  type="text"
                  label="Email address"                  
                  className="form-control form-control-lg"
                  validationError="Email address is required"
                  validations={{
                    isEmail: true,
                    isRequired: true
                  }}
                />
              </Col>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Input
                  id="mobileNumber"
                  name="mobileNumber"
                  ref="mobileNumber"
                  type="text"
                  label="Mobile number"                  
                  className="form-control form-control-lg"
                  validationError="Mobile number is required"
                  validations={{
                    matchRegexp: /^[\d ()-]*$/,
                    isRequired: true
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Select
                  id="loanType"
                  name="loanType"
                  label="Loan Type"
                  value=""
                  options={this.getLoanTypes()}
                  validationError="Please select loan type"
                  validations={{
                    isRequired: true,
                  }}
                  className="form-control"                />
              </Col>							
            </Row>
            <Row>
              <Col style={{ marginLeft: '12px' }}>
                  <h6><strong>LFI Product Options</strong></h6>
              </Col>
            </Row>
            <Row>							
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Select
                  id="veiProductNumber"
                  name="veiProductNumber"
                  label="VEI Cover Option"
                  value={this.state.veiProductNumber}
                  options={convertToLfiVeiOptions(this.props.veiOptions)}
                  className="form-control" 
                  changeCallback={this.updateVeiProductOption}
                  validations={{									
                    isLfiProductSelected: this.validateProductOption
                  }}
                  validationErrors={{
                    isLfiProductSelected: "Must select at least one LFI product - VEI or LPI"
                  }}
                />
              </Col>
              <Col sm={5} style={{ marginTop: '4px' }}>
                <Select
                  id="lpiProductNumber"
                  name="lpiProductNumber"
                  label="LPI Cover Option"
                  value={this.state.lpiProductNumber}
                  options={this.props.lpiOptions}
                  className="form-control"   
                  changeCallback={this.updateLpiProductOption}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <div style={{ marginTop: '12px' }}>
                  <Checkbox
                  id="isFsgProvided"
                  name="isFsgProvided"
                  ref="isFsgProvided"
                  valueLabel={<span>Financial Services (FSG) provided to the customer</span>}
                  value={false}							
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div style={{ marginTop: '12px' }}>
                  <Checkbox
                  id="isPdsProvided"
                  name="isPdsProvided"
                  ref="isPdsProvided"
                  valueLabel={<span>Product Disclosure Statement (PDS) provided to the customer</span>}
                  value={false}										
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div style={{ marginTop: '12px' }}>
                  <Checkbox
                  id="isCustomerInformedProductInfo"
                  name="isCustomerInformedProductInfo"
                  ref="isCustomerInformedProductInfo"
                  valueLabel={<span>Confirm you have advised the customer LFI will be sending product information to them</span>}
                  value={false}										
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>	
                <div className="text-right" style={{ margin: '25px 0' }}>							
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </Col>
              <Col sm={6}>	
                <div className="text-left" style={{ margin: '25px 0' }}>							
                  <button onClick={this.handleClear} className="btn btn-secondary">Clear</button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Form>
  }
}

function mapStateToProps(state) {
  return {	  
    reference: state.reference,
    submitStatus: state.lfiProtectPrequal.submitStatus,
    submitErrorMessage: state.lfiProtectPrequal.submitErrorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ submitLfiProtectLead }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LfiProtectPrequalDetails);
