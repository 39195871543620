function introducerHasSubmission(session) {
  return session.details && session.details.isSubmission;
}

function isOneApplicant(applicants) {
  return applicants.length == 1;
}

function isNotSelfEmployedIncome(applicants) {
  return applicants.every((applicant) => applicant.selfEmployed.length == 0);
}

function isNotCompanyIncome(applicants) {
  return applicants.every((applicant) => applicant.companyIncome.length == 0);
}

function isPrime(multiLoanDetails) {
  return multiLoanDetails[0].riskGrade == 'AAA';
}

function serviceAbilityFitsSubmission(doesService, data) {
  return doesService
    && isOneApplicant(data.applicants)
    && isNotSelfEmployedIncome(data.applicants)
    && isNotCompanyIncome(data.applicants)
    && isPrime(data.multiLoanDetails);
}

export function shouldShowSubmitNow(session, doesService, data) {
  return introducerHasSubmission(session) && serviceAbilityFitsSubmission(doesService, data);
}
