import * as React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone-component';
import { notify as Notify } from 'react-notify-toast';

import API from '../../api';

import { Icon, Avatar, DateTime } from '../../utils';

import * as errors from '../../constants/errors';
import Event3rdParty from '../../helpers/event3rdParty';
import Storage from '../../helpers/storage';

export default class ApplicationAction extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      history: false,
    };
    this.toggleHistory = this.toggleHistory.bind(this);
  }

  toggleHistory() {
    this.setState({
      history: !this.state.history,
    });
  }

  renderDescription(action) {
    if (action.name) {
      return (
        <div>
          {this.renderBadge(action)}
          <strong className="ib">{action.name}</strong>
          {' '}
          <br />
          <p className="mute">{action.description}</p>
        </div>
      );
    }

    return (
      <strong className="ib">
        {this.renderBadge(action)}
        {action.description}
      </strong>
    );
  }

  renderLabel(action) {
    return (
      <div className="action-label">
        {this.renderBadge(action)}
        {action.name || action.description}
      </div>
    );
  }

  renderBadge(action) {
    return action.status == 2 && <span className="badge badge-outline badge-sm badge-secondary mr-sm">Awaiting verification</span>;
  }

  /**
   * Render
   * @return ReactComponent
   */
  render() {
    const { action } = this.props;
    switch (this.props.getActionType(action)) {
      case 'post': {
        return (
          <div className={`action ${this.props.state.complete == action.id ? 'out' : ''} ${this.props.state.active == action.id ? 'active' : 'active'}`} key={`outstanding-${action.id}`} tabIndex={this.props.index} onClick={() => { this.props.setActive(action.id); }}>
            <Icon glyph={this.props.getActionIcon(action)} className={`action-icon action-${this.props.getActionType(action)}`} />

            {this.renderLabel(action)}

            <div className="action-details">

              {this.renderDescription(action)}

              <p className="mute">Post to The Settlements Team, Level 16 / 535 Bourke Street, Melbourne VIC 3000</p>

              {
                  (() => {
                    if (action.status == 1) {
                      if (this.props.getActionNote(action)) {
                        return (
                          <div className="action-note">
                            <Icon glyph="alert-circle" className="action-alert" />

                            <Avatar className="avatar-xxs mr-sm" contact={this.props.state.contacts[this.props.getActionNote(action).createdById]} />
                            <DateTime className="action-note-date">{this.props.getActionNote(action).timestamp}</DateTime>
                            <span className="action-note-text">{this.props.getActionNote(action).text}</span>
                          </div>
                        );
                      }
                    }

                    if (action.status == 2) {
                      return (
                        <div className="action-completed">
                        {this.props.getActionType(action) == 'file' ? 'Uploaded' : 'Completed'}
                        {' on '}
                        <DateTime>{action.history[action.history.length - 1].timestamp}</DateTime>
                        </div>
                      );
                    }
                  })()
                }

              {
                (() => {
                  if (action.status == 1) {
                    return (
                      <div className="action-cta">
                        <span className="btn btn-secondary btn-default action-incomplete" onClick={() => { this.props.actionComplete(action); }}>Complete</span>
                      </div>
                    );
                  }
                })()
              }


            </div>
          </div>
        );
      }
      case 'action': 
      case 'emailOrPhone': {
        return (
          <div className={`action ${this.props.state.complete == action.id ? 'out' : ''} ${this.props.state.active == action.id ? 'active' : 'active'}`} key={`outstanding-${action.id}`} tabIndex={this.props.index} onClick={() => { this.props.setActive(action.id); }}>
            <Icon glyph={this.props.getActionIcon(action)} className={`action-icon action-${this.props.getActionType(action)}`} />

            {this.renderLabel(action)}

            <div className="action-details">

              {this.renderDescription(action)}

              {
                  (() => {
                    if (action.status == 1) {
                      if (this.props.getActionNote(action)) {
                        return (
                          <div className="action-note">
                            <Icon glyph="alert-circle" className="action-alert" />
                            <Avatar className="avatar-xxs mr-sm" contact={this.props.state.contacts[this.props.getActionNote(action).createdById]} />
                            <DateTime className="action-note-date">{this.props.getActionNote(action).timestamp}</DateTime>
                            <span className="action-note-text">{this.props.getActionNote(action).text}</span>
                          </div>
                        );
                      }
                    }

                    if (action.status == 2) {
                      return (
                        <div className="action-completed">
                        {this.props.getActionType(action) == 'file' ? 'Uploaded' : 'Completed'}
                        {' on '}
                        <DateTime>{action.history[action.history.length - 1].timestamp}</DateTime>
                        </div>
                      );
                    }
                  })()
                }

              {
                  (() => {
                    if (action.status == 1) {
                      return (
                        <div className="action-cta">
                          <span className="btn btn-secondary btn-default" onClick={() => { this.props.actionComplete(action); }}>
                             {this.props.getActionType(action) == 'emailOrPhone' ? 'Complete (by email or phone call)' : 'Complete'}
                          </span>
                        </div>
                      );
                    }
                  })()
                }

            </div>
          </div>
        );
      }
      case 'file': {
        const self = this;

        // Because we don't know what type our token is going to be,
        // try to parse it as the original JWT token, otherwise take it as is
        // because it's probably a B2C token instead (or an invalid token which should fail out)
        // B2C - Remove once migration is complete
        let token;
        try {
          token = JSON.parse(Storage.getItem('token'));
        } catch (e) {
          token = Storage.getItem('token');
        }

        return (
          <div className={`action ${this.props.state.complete == action.id ? 'out' : ''} ${action.status == 1 ? 'outstanding' : ''} ${this.props.state.active == action.id ? 'active' : 'active'}`} key={`outstanding-${action.id}`} tabIndex={this.props.index} onClick={() => { this.props.setActive(action.id); }}>
            <Dropzone
              id={`dropzone${action.id}`}
              ref={`dropzone${action.id}`}
              className="dz"
              djsConfig={{
                init() {
                  self[`dropzone${action.id}`] = this;

                  this.on('addedfile', (file) => {
                    if (!file.size) {
                      this.removeFile(file);

                      Notify.show(errors.ERR_DEFAULT, 'error', 3000);
                    }

                    setTimeout(() => {
                      Event3rdParty.send('upload-file', {
                        accepted: file.accepted,
                        type: file.type,
                        size: file.size,
                        status: file.status,
                      });
                    }, 1000);

                    self.props.setActive(action.id);
                  });

                  this.on('error', (progress, response) => {
                    let message = '';

                    if (response.errors && response.errors.length) {
                      message = response.errors[0].message;
                    }
                    if (response.message) {
                      message = response.message;
                    }

                    this.defaultOptions.error(progress, message);
                    Notify.show(response && typeof response === 'string' ? response : message || errors.ERR_DEFAULT, 'error', 3000);
                  });

                  this.on('success', (file) => {
                    self.props.fileComplete(action, file);
                  });
                  this.on('queuecomplete', (file) => { self.props.dropzoneComplete(this, file); });
                },
                url: `${API.base}documents/upload/${this.props.application.applicationNumber}/${action.id}`,
                renameFilename(file) {
                  const ext = file.substr(file.length - (file.length - file.lastIndexOf('.')));
                  file = file.replace(ext, '');
                  file = file.toLowerCase().replace(/[^\w]/gi, '') + ext;
                  return file;
                },
                addRemoveLinks: true,
                dictDefaultMessage: '',
                dictRemoveFile: '',
                dictCancelUpload: 'Cancel',
                dictInvalidFileType: 'You can\'t upload documents that are not .jpg, .jpeg, .png, .tiff or .pdf',
                dictFileTooBig: `You can't upload documents larger than ${10} MB`,
                dictMaxFilesExceeded: `You cannot upload more than ${10} document`,
                dictResponseError: errors.ERR_DEFAULT,
                previewsContainer: `.dz-previews-${action.id}`,
                headers: { Authorization: `Bearer ${token}`, IsB2C: `${Storage.getItem('isUseB2C') || ''}`, OriginatorId: `${Storage.getItem('session') || ''}` },
                acceptedFiles: '.png, .jpg, .jpeg, .tiff, .pdf',
                maxFilesize: 10,
                maxFiles: 10,
                clickable: `.dz-click-${action.id}`,
              }}
            >


              <Icon glyph={this.props.getActionIcon(action)} className={`action-icon action-${this.props.getActionType(action)}`} />

              {this.renderLabel(action)}

              <div className="action-details">

                {this.renderDescription(action)}


                {
                  (() => {
                    if (action.status == 1) {
                      if (this.props.getActionNote(action)) {
                        return (
                          <div className="action-note">
                            <Icon glyph="alert-circle" className="action-alert" />
                            <Avatar className="avatar-xxs mr-sm" contact={this.props.state.contacts[this.props.getActionNote(action).createdById]} />
                            <DateTime className="action-note-date">{this.props.getActionNote(action).timestamp}</DateTime>
                            <span className="action-note-text">{this.props.getActionNote(action).text}</span>
                          </div>
                        );
                      }
                    }

                    if (action.status == 2) {
                      return (
                    <div className="action-completed">
                      {this.props.getActionType(action) == 'file' ? 'Last uploaded' : 'Completed'}
                      {' on '}
                      <DateTime>{action.history[action.history.length - 1].timestamp}</DateTime>
                      <button className="btn btn-sm btn-link p-h ml-sm" type="button" onClick={this.toggleHistory}>
                      (
                      {this.state.history ? 'hide' : 'show'}
                      {' upload history'}
                      )
                      </button>
                    </div>
                      );
                    }
                  })()
            }
                <div className={`action-history mt-sm ${this.state.history ? '' : 'hidden'}`}>
                  {
                    action.history.slice(0).reverse().map((file) => (
                    <div className="action-history-item">
                    <Icon glyph="file" className="mute-hg mr-sm" />
                    {' '}
                    <small>
                    <DateTime>{file.timestamp}</DateTime>
                    {' '}
                    <strong>{file.fileName}</strong>
                    </small>
                    </div>
                    ))
                  }
                </div>

                <div className={`action-preview dz-previews dz-previews-${action.id}`} id={`dropZonePreview${action.id}`} ref={`dropZonePreview${action.id}`} />

                <div className="action-cta">
                  <div className="dz-message va-m" id={`dropZoneMessage${action.id}`} ref={`dropZoneMessage${action.id}`} />

                  <div className={`dz-click-${action.id} ib`}>
                    <span className="btn btn-default btn-secondary">Upload</span>
                  </div>
                </div>
              </div>

            </Dropzone>
          </div>
        );
      }
    }
  }
}

ApplicationAction.propTypes = {
  action: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  getActionType: PropTypes.func.isRequired,
  getActionNote: PropTypes.func.isRequired,
  getActionIcon: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
  actionComplete: PropTypes.func.isRequired,
  fileComplete: PropTypes.func.isRequired,
  dropzoneComplete: PropTypes.func.isRequired,
};
