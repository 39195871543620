
import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input } from 'formsy-react-components';
import {Applicant, Broker} from './applicationDocuments/applicationEsigDocument'

export interface ApplicationAgreementContactProps {
    applicants: Applicant[],
    broker: Broker
}

export const ApplicationAgreementContactDetails = (props: ApplicationAgreementContactProps) =>
    <div>
        <Row>
            <Col sm={4}>
                <Input
                    name="brokerEmail"
                    label="Your Email"
                    value={props.broker.email}
                    disabled={true}
                />
            </Col>
        </Row>
        {
            props.applicants.map((a: Applicant, i) => {
                return <Row key={`applicant[${i}]`}>
                    <Col sm={4}>
                        <Input
                            name={`applicant[${i}].Email`}
                            label={`Applicant ${i+1} Email`}
                            value={a.contact.email[0]}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={4}>
                        <Input
                            name={`applicant[${i}].Mobile`}
                            label={`Applicant ${i+1} Mobile`}
                            value={a.contact.mobile[0]}
                            disabled={true}
                        />
                    </Col>
                </Row>
            })
        }
        <div className="split" />
    </div>
