import * as React from 'react';

import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import AddressInput from '../addressInput';
import DateInput from '../dateInput';
import Title from '../title';

import {toOption, toRequired, firstOption} from '../functions';

import {IOption} from '../../../interfaces';

interface ILivingState {
}

interface ILivingProps {
  index: number;
  askPreviousAddress: boolean;
  setAskPreviousAddress: (value: boolean) => void;
}

const dateIsComplete = (date: string) => date.length == "mm/yyyy".length
const shouldAskPreviousAddress = (date: string): boolean => {
  if (dateIsComplete(date)) {
    const atAddressMonths = Math.ceil(Moment().diff(Moment(`01/${date}`, 'DD/MM/YYYY'), 'months'));
    return atAddressMonths <= 24;
  }

  return false;
}

export class Individual extends React.Component<ILivingProps, ILivingState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  changeAddress(date: string): void {
    this.props.setAskPreviousAddress(
      shouldAskPreviousAddress(date)
    );
  }

  get occupancies(): IOption[] {
    return ["Owning", "Renting", "Boarding", "Free accomodation", "With parents", "Other"].map(o => toOption(o));
  }

  render() {
    return (
      <div className="panel-section">
        <Title>Address</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row className="panel-row">
            <Col sm={6}>
              <AddressInput
                name={`individuals[${this.props.index}].currentAddress`}
                label={toRequired("Current address")}
                validations={{
                  isRequired: true
                }}
              />
            </Col>

            <Col sm={2}>
              <Select
                name={`individuals[${this.props.index}].currentAddress.occupancy`}
                id={`individuals[${this.props.index}].currentAddress.occupancy`}
                label={toRequired("Occupancy")}
                options={this.occupancies}
                value={firstOption(this.occupancies)}
                className="form-control"/>
            </Col>

            <Col sm={2}>
            <DateInput
                data-test-name="from"
                name={`individuals[${this.props.index}].currentAddress.from`}
                label={toRequired("Move in date")}
                precision="month"
                validations={{
                  isRequired: true,
                  isValidPastDate: true,
                }}
                validationErrors={{
                  isRequired: "Move in date is required",
                  isValidPastDate: "Date needs to be in the past",
                }}
                changeCallback={(field, value) => this.changeAddress(value)} />
              </Col>

          </Row>

          {
            (() => {
              if (this.props.askPreviousAddress) {
                return (
                  <Row>
                    <Col sm={6}>
                      <AddressInput
                        data-test-name="previous"
                        name={`individuals[${this.props.index}].previousAddress`}
                        label={toRequired("Previous address")}
                        validations={{
                          isRequired: true
                        }}
                      />
                    </Col>

                    <Col sm={2}>
                      <Select
                        name={`individuals[${this.props.index}].previousAddress.occupancy`}
                        id={`individuals[${this.props.index}].previousAddress.occupancy`}
                        label={toRequired("Occupancy")}
                        options={this.occupancies}
                        value={firstOption(this.occupancies)}
                        className="form-control" />
                    </Col>


                    <Col sm={2}>
                      <DateInput
                        name={`individuals[${this.props.index}].previousAddress.from`}
                        label={toRequired("Move in date")}
                        precision="month"
                        validations={{
                          isRequired: true,
                          isValidPastDate: true
                        }}
                        validationErrors={{
                          isRequired: "Move in date is required",
                          isValidPastDate: "Date needs to be in the past"
                        }} />
                    </Col>

                    <Col sm={2}>
                      <DateInput
                        name={`individuals[${this.props.index}].previousAddress.to`}
                        label={toRequired("Move out date")}
                        precision="month"
                        validations={{
                          isRequired: true,
                          isValidPastDate: true
                        }}
                        validationErrors={{
                          isRequired: "Move out date is required",
                          isValidPastDate: "Date needs to be in the past"
                        }} />
                    </Col>

                  </Row>
                );
              }
            })()
          }

          <Row>
            <Col sm={6}>
              <AddressInput
                name={`individuals[${this.props.index}].postAddress`}
                label={<span>Post address <small className="mute">(leave blank if same as current address)</small></span>}
              />
            </Col>
          </Row>

        </div>

      </div>
    );
  }
}

export default Individual;
