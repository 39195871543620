import { Dispatch } from "redux";

export type ServiceAbilitySuggestionAction =
  SetPreferredSurplus;

export enum ServiceAbilitySuggestionActionType {
  SetPreferredSurplus = "SetPreferredSurplus"
}

interface SetPreferredSurplus {
  value: string;
  type: ServiceAbilitySuggestionActionType.SetPreferredSurplus;
}

export const setPreferredSurplus = (preferredSurplus: string) => (dispatch: Dispatch<SetPreferredSurplus>) => {
  dispatch({
    value: preferredSurplus,
    type: ServiceAbilitySuggestionActionType.SetPreferredSurplus
  });
}
