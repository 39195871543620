import * as React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';
import Axios from 'axios';

import Mask from '../../../helpers/mask';
import {toOption, toOptionGroup, toRequired, selectText, firstOption} from '../functions';
import {roleOptions} from '../options';

import AddressInput from '../addressInput';
import Remove from '../remove';
import Title from '../title';
import SelectableInput from '../selectableInput';
import MaskedInput from '../maskedInput';
import CurrencyInput from '../currencyInput';
import PhoneInput from '../phoneInput';
import DateInput from '../dateInput';

interface IPaygState {
  selectedEmployment: string
}

export class Payg extends React.Component<any, IPaygState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployment: 'permanentFullTime'
    };

    this.changeEmployment = this.changeEmployment.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      selectedEmployment: this.props.income.status
    })
  }

  changeEmployment(_, selectedEmployment = 'permanentFullTime') {
    this.setState({
      selectedEmployment
    });
  }

  get status(): any[] {
    return ["Permanent full time", "Temporary full time", "Permanent part time", "Temporary part time", "Casual"].map(o => toOption(o));
  }

  get breakdown(): any[] {
    return ["Income", "Allowance", "Bonus", "Overtime", "Parental leave", "Commission"].map(o => toOption(o));
  }

  render(): JSX.Element {
    return (
      <div className={`card p-lg mt-lg`}>
        <Input
          name={`incomes[${this.props.index}].type`}
          type="hidden"
          value="payg"
        />

        <Input
          name={`incomes[${this.props.index}].belongsTo`}
          type="hidden"
          value={this.props.income.belongsTo}
        />

        <Title top>
          Employer
          <Remove onClick={this.props.removeIncome.bind(this, this.props.income.id)} />
        </Title>

        <Row>
          <Col sm={3}>
            <Input
              name={`incomes[${this.props.index}].company`}
              id={`incomes[${this.props.index}].company`}
              label={toRequired("Company")}
              validations={{
                isRequired: true
              }}
              value=""
              validationError="Company is required" />
          </Col>

          <Col sm={6}>
            <AddressInput
              name={`incomes[${this.props.index}].address`}
              label={toRequired("Address")}
              validations={{
                isRequired: true
              }} />
          </Col>

          <Col sm={3}>
            <PhoneInput
              name={`incomes[${this.props.index}].referencePhone`}
              label={toRequired(<span>Phone <small className="mute">(reference)</small></span>)}
              type="landLine" />
          </Col>
        </Row>

        <Title>Position</Title>

        <Row>
          <Col sm={4}>
            <SelectableInput
              name={`incomes[${this.props.index}].role`}
              label={toRequired("Role")}
              validations={{
                isStrict: true
              }}
              validationError="Role is required"
              options={roleOptions} />

          </Col>

          <Col sm={2}>
            <Select
              name={`incomes[${this.props.index}].status`}
              id={`incomes[${this.props.index}].status`}
              className="form-control"
              label={toRequired("Type")}
              changeCallback={this.changeEmployment}
              options={this.status}
              value={firstOption(this.status)} />
          </Col>

          {
            (() => {
              if (this.state.selectedEmployment != 'permanentFullTime' && this.state.selectedEmployment != 'temporaryFullTime') {
                return (
                  <Col sm={2}>
                    <Input
                      name={`incomes[${this.props.index}].hours`}
                      ref={`incomes[${this.props.index}].hours`}
                      id={`incomes[${this.props.index}].hours`}
                      label={toRequired(<span>Hours <small className="mute">(weekly)</small></span>)}
                      validations={{
                        isRequired: true,
                        isNotZero: true,
                        isValidHours: (_, value) => Number(value) <= 60
                      }}
                      validationError="Hours is required"
                      value="0" />
                  </Col>
                )
              }
            })()
          }

          <Col sm={2}>
            <DateInput
              name={`incomes[${this.props.index}].startDate`}
              label={toRequired("Start date")}
              precision="month"
              validations={{
                isRequired: true,
                isValidDate: true,
                isValidPastDate: true
              }}
              validationErrors={{
                isRequired: "Start date is required",
                isValidDate: "Must be a valid date",
                isValidPastDate: "Start date must be in the past"
              }}
            />
          </Col>

          <Col sm={2}>
            <DateInput
              name={`incomes[${this.props.index}].endDate`}
              label="End date"
              precision="month"
              validations={{
                isValidDate: true
              }}
              validationErrors={{
                isValidDate: "Must be a valid date",
              }}
            />
          </Col>
        </Row>

        <Title sub="Gross yearly">Income</Title>

        <Row>
          <Col sm={3} smOffset={9}>
              <label>{toRequired("Amount")}</label>
          </Col>
        </Row>

        {
          this.breakdown.map(field => {
            let validations: any = {
              isRequired: true
            };

            let validationErrors: any = {
              isRequired: `${field.label} amount is required`
            };

            if (field.value == "income") {
              validations.isNotZero = true;
              validationErrors.isNotZero = `${field.label} amount can not be 0`
            }

            return (
              <Row key={`income_${this.props.index}_${field.label}`}>
                <Col sm={9}>
                  <div className="mute pt-sm">{field.label}</div>
                </Col>

                <Col sm={3}>
                  <CurrencyInput
                    name={`incomes[${this.props.index}].${field.value}Amount`}
                    value="0"
                    align="right"
                    validations={validations}
                    validationErrors={validationErrors} />
                </Col>
              </Row>
            );
          })
        }
      </div>
    );
  }
}

export default Payg;
