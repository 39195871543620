import * as React from 'react';
import PropTypes from 'prop-types';
import * as Guid from 'guid';

import ComServiceAbilityApplicant from './comServiceAbilityApplicant';
import { Icon } from '../../utils';

export default class ComServiceAbilityApplicants extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      applicants: [],
    };

    this.addApplicant = this.addApplicant.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.applicants) {
      const applicants = [];
      this.props.data.applicants.forEach((applicant) => {
        applicant.applicantId = applicant.id || Guid.create().value;
        applicants.push(applicant);
      });

      this.setState({
        applicants,
      });
    }
  }

  addApplicant() {
    const { applicants } = this.state;
    applicants.push({
      applicantId: Guid.create().value,
      name: '',
      relationship: 1,
      partner: '',
    });
    this.setState({
      applicants,
    }, () => this.props.OnApplicantsChanged());
  }

  removeApplicant(index) {
    const applicants = Object.assign([], this.state.applicants);

    if (applicants[index].partner) {
      applicants.forEach((applicant, instance) => {
        if (applicant.applicantId == applicants[index].partner) {
          applicants[instance].partner = '';
        }
      });
    }

    applicants.splice(index, 1);
    this.setState({
      applicants,
    }, () => this.props.OnApplicantsChanged());
  }

  handleName(name, index) {
    const { applicants } = this.state;
    applicants[index].name = name;
    this.setState({
      applicants,
    }, () => this.props.OnApplicantsChanged());
  }

  handleRelationship(relationship, index) {
    const { applicants } = this.state;

    if (relationship == 1) {
      if (applicants[index].inRelationshipWithId) {
        applicants.forEach((applicant, instance) => {
          if (applicant.applicantId == applicants[index].inRelationshipWithId) {
            applicants[instance].inRelationshipWithId = '';
          }
        });
      }
    }

    applicants[index].relationship = relationship;
    applicants[index].inRelationshipWithId = '';

    this.setState({
      applicants,
    });
  }

  handlePartner(inRelationshipWithId, index) {
    const { applicants } = this.state;

    applicants.forEach((applicant, instance) => {
      if (inRelationshipWithId == '') {
        if (applicant.applicantId == applicants[index].inRelationshipWithId) {
          applicants[instance].inRelationshipWithId = '';
        }
      }

      if (applicant.applicantId == inRelationshipWithId) {
        applicants[instance].relationship = 2;
        applicants[instance].inRelationshipWithId = applicants[index].applicantId;
      }
    });

    applicants[index].inRelationshipWithId = inRelationshipWithId;

    this.setState({
      applicants,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section np">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-accounts mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">
            Individual Applicants
            {' '}
            <small className="mute">(if applicable)</small>
            </h3>
          </div>
        </div>
        <div className="panel-section-body applicants">
          <div className="panel-section-container m-h p-md bg-primary-50">
            {
              this.state.applicants.map((applicant, index) => (
                  <ComServiceAbilityApplicant
                    key={`applicant-${applicant.applicantId}`}
                    index={index}
                    data={this.props.data && this.props.data.applicants ? this.props.data.applicants[index] : null}
                    applicant={{ ...applicant }}
                    applicants={this.state.applicants}
                    handleName={this.handleName}
                    handleRelationship={this.handleRelationship}
                    handlePartner={this.handlePartner}
                    removeApplicant={this.removeApplicant}
                  />
              ))
            }

            <button style={{ width: '100%', fontSize: '15px' }} type="button" className="card card-shallow card-mute card-hover mt-md" id="addApplicant" onClick={this.addApplicant}>
              <strong className="secondary">
              <Icon glyph="plus" className="primary mute-lg mr-sm" />
              {' '}
              Add individual applicant
              </strong>
            </button>

          </div>
        </div>
      </div>
    );
  }
}

ComServiceAbilityApplicants.propTypes = {
  data: PropTypes.object.isRequired,
  OnApplicantsChanged: PropTypes.func.isRequired,
};

ComServiceAbilityApplicants.defaultProps = {
};
