import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ApplicationActionIcon from './applicationActionIcon';
import ApplicationTypeIcon from './applicationTypeIcon';
import ApplicationApplicants from './applicationApplicants';

const mergeFirstLastName = (individual) => ({
  ...individual,
  name: `${individual.firstName} ${individual.lastName}`,
});

const ApplicationPartialListItem = (props) => (
  <Link className="loan-item" to={`/applications/submit/residential/${props.application.applicationId}`}>
    <div>
      <ApplicationTypeIcon type={1} />

      <ApplicationApplicants applicants={props.application.applicants.individuals.map(mergeFirstLastName)} />

      <p className="loan-status">Not Submitted</p>
    </div>

    <ApplicationActionIcon status={100} actions={[]} />
  </Link>
);

ApplicationPartialListItem.propTypes = {
  application: PropTypes.object.isRequired,
};

export default ApplicationPartialListItem;
