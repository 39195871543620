/* eslint-disable import/default */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, useRouterHistory } from 'react-router';
import { createHashHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import { MsalProvider } from '@azure/msal-react';
import routes from './routes';
import configureStore from './store/configureStore';
import Interceptor from './helpers/interceptors';
import MarketingCampaign from './helpers/marketingCampaign';

import 'react-rangeslider/lib/index.css';
import { msalInstance } from './helpers/authConfig';

require('./helpers/rules');

require('./assets/styles/bootstrap.less');
require('./assets/styles/iq.less');
require('./assets/styles/nprogress.less');

const store = configureStore();
const history = syncHistoryWithStore(useRouterHistory(createHashHistory)(), store);

Interceptor.register(store);
MarketingCampaign.initialise(location);
typeof window.Raven !== 'undefined' && window.Raven.config('https://94b68028da224113b4d1e797acc2e184@sentry.io/100370').install();

let prevRoute = null;
function onUpdate() {
  const routeLen = this?.state?.routes?.length;
  const route = routeLen ? this.state.routes[routeLen - 1]?.path : null;
  if (prevRoute !== route) {
    // scroll only if the route changed
    window.scrollTo(0, 0);
  }
  prevRoute = route;
}

render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <Router history={history} routes={routes} onUpdate={onUpdate} />
    </MsalProvider>
  </Provider>,
  document.getElementById('app'),
);
