import * as React from 'react';
import PropTypes from 'prop-types';

const Loadr = (props) => {
  const classes = new Array();

  if (props.lg) {
    classes.push('loadr-lg');
  }

  if (props.sm) {
    classes.push('loadr-sm');
  }

  if (props.md) {
    classes.push('loadr-md');
  }

  if (props.left) {
    classes.push('loadr-left');
  }

  if (props.right) {
    classes.push('loadr-right');
  }

  if (props.inline) {
    classes.push('loadr-inline');
  }

  if (props.className) {
    classes.push(props.className);
  }

  return (
    <div className={`loadr ${classes.join(' ')}`} style={props.style || {}} />
  );
};

Loadr.propTypes = {
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
  inline: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.any,
};

export default Loadr;
