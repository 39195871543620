import * as React from 'react';
import PropTypes from 'prop-types';
import Validation from 'react-validation';

import objectAssign from 'object-assign';

const Input = (props) => {
  const opts = objectAssign({}, props);

  /**
     * Apply default attributes
     */
  const defaults = {
    className: 'form-control',
    errorClassName: 'has-error',
    errorContainerClassName: 'has-error',
    id: props.id || props.name,
  };

  /**
     * Apply label
     */
  let label;
  if (opts.label) {
    label = opts.label;
    delete opts.label;
  }
  let optional;
  if (opts.optional) {
    optional = opts.optional;
    delete opts.optional;
  }

  /**
     * Apply help text
     */
  let help;
  if (opts.help) {
    help = opts.help;
    delete opts.help;
  }

  /**
     * Combine all attributes
     * @type {[type]}
     */
  const attr = objectAssign({}, { ...opts, ...defaults });

  /**
     * Return the element
     */
  return (
      <div className="form-group form-group-reverse">
        <Validation.components.Input
          {...attr}
        />

        {
          (() => {
            if (label !== undefined) {
              return (
                <label htmlFor={attr.id} className="control-label b">
                  {label}

                  {
                    (() => {
                      if (optional !== undefined) {
                        return (
                          <small className="pull-right mute" style={{ paddingTop: '1px' }}>
                                (optional)
                          </small>
                        );
                      }
                    })()
                  }
                </label>
              );
            }
          })()
        }

        {
          (() => {
            if (help !== undefined) {
              return (
                <small className="help-block">
                  {help}
                </small>
              );
            }
          })()
        }
      </div>
  );
};

Input.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
};

export default Input;
