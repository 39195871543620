import * as React from 'react';
import PropTypes from 'prop-types';

export default class PrequalHeader extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {};
  }

  doSomething() {}

  /**
   * Render
   */
  render() {
    return (
      <div className="mt-lg pt-sm mb-lg text-center">
        <h1 className="mt-lg">Calculators</h1>
        <h4 className="mute">Easily prequalify your customer, it all starts here</h4>
      </div>
    );
  }
}

PrequalHeader.propTypes = {
};
