import * as React from 'react';

interface ITitleProps {
  children: any;
  sub?: string | JSX.Element;
  top?: boolean;
  noborder?: boolean;
}

const Title = (props: ITitleProps): JSX.Element =>  {
  return (
    <div className={`panel-row pl-lg ${!props.noborder ? 'panel-row-line ' : ''} ${!props.top ? 'pt-md mt-md' : 'mt-md'}`}>
      <h4 className="mt-sm mb-md ib" style={{width: '100%', height: '20px'}}>{props.children}</h4>
      {props.sub && <p className="mute mt-sm-n mb-h pb-md">{props.sub}</p>}
    </div>
  );
}

export default Title;
