import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Applicant, IDVerificationStatus, ApplicationIDVerificationProps, ApplicationIDVerification, } from './applicationIDVerification';
import { sendApplicantIDVerificationLink, getApplicantIDVerificationStatus } from './actions';
import { Icon, Widget, Loadr } from '../../../utils';
import { IDVerificationState } from './reducer';

interface IState {
  expanded: boolean
}

const hasIDVerificationNotInitiatedForAnyApplicant = (applicants: Applicant[]): Boolean => applicants.every((a) => a.idVerificationStatus === IDVerificationStatus.NotStarted && a.canSend === true);

/** After approval and untill the settlement */
const canDoIDVerification = (statusCode) => statusCode >= 230 && statusCode < 330;

export class ApplicationIDVerificationToggle extends React.Component<ApplicationIDVerificationProps, IState> {
  constructor(props: ApplicationIDVerificationProps) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleWidget = this.toggleWidget.bind(this);
  }

  componentWillMount() {
    this.props.applicants.forEach(a => this.props.actions.getApplicantIDVerificationStatus(a.applicantId))
  }

  toggleWidget() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    if (canDoIDVerification(this.props.applicationStatusCode) && this.props.isIDVerification) {
      return (
        <Widget titleStyleClass="voi-widget-title" title="ID Verification">
              <ApplicationIDVerification {...this.props} />
        </Widget>
      );
    }
    return null;
  }
}

function mapStateToProps({ application, idVerification, session }) {
  return {
    applicationNumber: (application.active != null && application.active != undefined) ? application.active.applicationNumber : null,
    broker: {
      name: session.details.contact,
      email: session.details.email,
      smsBrandName : (application.active != null && application.active != undefined && session.details.products.indexOf(p => p.productGroupId === application.active.productGroupId) !== -1)
        ? session.details.products.find(p => p.productGroupId === application.active.productGroupId).smsBrandName : session.details.smsBrandName,
      mobileNumber: session.details.mobile,
      sendSMS: session.details.iqSms,
    },
    applicants: (application.active != null || application.active != undefined) ? application.active.applicants.map(a => { return mapApplicantIdVerificationStatus(a, idVerification); }) : [],
    applicationType: (application.active != null && application.active != undefined) ? application.active.applicationType : null,
    applicationStatusCode: (application.active != null && application.active != undefined) ? application.active.applicationStatus.code : -1,
    isIDVerification: session.details.isIDVerification && (session.details.isMotor || session.details.isResidential)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sendApplicantIDVerificationLink, getApplicantIDVerificationStatus }, dispatch),
  };
}

const mapApplicantIdVerificationStatus = (applicant, idVerificationState: IDVerificationState) => {
  const status = idVerificationState.applicants.find(a => a.applicantId === applicant.applicantId);
  if (status !== undefined)
    return { applicantId: applicant.applicantId, name: applicant.name, mobileNumber: applicant.contact.mobile[0], idVerificationStatus: status.idVerificationStatus, canSend: status.canSend }

  return { applicantId: applicant.applicantId, name: applicant.name, mobileNumber: applicant.contact.mobile[0], idVerificationStatus: IDVerificationStatus.NotStarted, canSend: true }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationIDVerificationToggle);
