import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col} from 'react-bootstrap';
import * as ReactTooltip from 'react-tooltip';

import { Input, Select, Checkbox } from 'formsy-react-components';

import { RouteComponentProps } from 'react-router';
import Formsy from 'formsy-react';
import * as referenceActions from '../../actions/referenceActions';

import ApplicationUpdateHeader from './applicationUpdateHeader';
import ApplicationUpdateVehiclePicker from './applicationUpdateVehiclePicker';

import Mask from '../../helpers/mask';
import { PRODUCTGROUPS, MOTOR_PLATE_MAX_LENGTH, MOTOR_PLATE_MIN_LENGTH, MOTOR_VIN_MIN_LENGTH } from '../../constants/types';
import VinSearch from './applicationUpdateVehicleVinSearch';
import RegoPlateSearch from './applicationUpdateVehicleRegoPlateSearch';
import { get_States } from '../../constants/functions';

const Moment = require('moment');
const isConsumerCaravan = (productGroupId: number) => productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN;
export type ApplicationUpdateVehicleProps = {
  actions: any,
  reference: any,
  session: any,
  formsy: Formsy | null,
  application: any,
} & RouteComponentProps<{ tab: string }, {}>

type ApplicationUpdateVehicleState = {
  visible: boolean,
  description: string | null,
  manual: boolean,
  message: string | null,
}

export class ApplicationUpdateVehicle extends React.Component<ApplicationUpdateVehicleProps, ApplicationUpdateVehicleState> {
  STATE_LIST = get_States();
  constructor(props) {
    super(props);
    
    this.state = {
      visible: false,
      message: null,
      manual: !this.props.application?.motorPurchaseVehicle?.nvic && isConsumerCaravan(this.props.application?.productGroupId || 0), 
      description: this.props.application?.motorPurchaseVehicle?.nvic? 
        `${this.props.application?.motorPurchaseVehicle.year} ${this.props.application?.motorPurchaseVehicle.make} ${this.props.application?.motorPurchaseVehicle.model}`:null,
    };
    
    this.toggleSection = this.toggleSection.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getVendorTypes = this.getVendorTypes.bind(this);
    this.setAppraisal = this.setAppraisal.bind(this);
    this.handleIsUsedChange = this.handleIsUsedChange.bind(this);
    this.handleOdometerChange = this.handleOdometerChange.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  componentDidMount() {
    this.getVendorTypes();

    if (this.props.params.tab == 'vehicle') {
      this.setState({
        visible: true,
      });
    }
    ReactTooltip.rebuild();
  }
  
  getVendorTypes() {
    this.props.actions.getVendorTypes();
  }

  getValue(field) {
    const matches = this.props.formsy && this.props.formsy.inputs.filter((input) => input.props.id == field);

    if (matches && matches.length) {
      return matches[0].getValue();
    }

    return undefined;
  }

  setAppraisal(odometer, isNew) {
    const nvic = this.getValue('motorPurchaseVehicle.nvic');
    if (nvic === undefined) { return; }

    this.props.actions.getAppraisal(nvic, odometer, isNew, this.props.application.productGroupId);
  }

  handleOdometerChange(field, odometer) {
    const isNew = !this.getValue('motorPurchaseVehicle.isUsed');
    this.setAppraisal(odometer, isNew);
  }

  handleIsUsedChange(field, isUsed) {
    const odometer = this.getValue('motorPurchaseVehicle.odometer');
    this.setAppraisal(odometer, !isUsed);
  }

  toggleSection() {
    this.setState({
      visible: !this.state.visible,
    });    
  }
 
  setMessage(message: string) {
    this.setState({message});
  }
  
  render() {
    return (
      <div className={`panel-section ${this.state.visible ? '' : 'collapsed'}`}>
        <ApplicationUpdateHeader
          title="Vehicle &amp; Vendor"
          description="Select or change the vehicle"
          glyph="directions-car"
          section="vehicle"
          toggleSection={this.toggleSection}
        />

        <div className="panel-section-body">                       
          <div className="panel-section-container">
            <h5>
              Vehicle Details
            </h5>
            <Input
              name="motorPurchaseVehicle.preApproval"
              type="hidden"
            />
            <div className='p-sm bg-primary-50'>         
              <h5>Search by <span className="text-danger"><strong><small>{this.state.message}</small></strong></span>       </h5>
              
              <ApplicationUpdateVehiclePicker setMessage={this.setMessage} formsy={this.props.formsy} application={this.props.application} />    
              <Row>
                <RegoPlateSearch setMessage={this.setMessage} getValue={this.getValue} formsy={this.props.formsy} application={this.props.application} ></RegoPlateSearch>
              </Row>
              <Row>
                <VinSearch setMessage={this.setMessage} getValue={this.getValue} formsy={this.props.formsy} application={this.props.application} ></VinSearch>
              </Row>
            </div>
            <hr></hr>     
             
            <Row>
              <Col sm={3} md={3}>
                <Input
                  name="motorPurchaseVehicle.vehicleColour"
                  id="motorPurchaseVehicle.vehicleColour"
                  label="Colour"
                  validations={{
                    maxLength: 20,
                  }}
                  validationError="Colour must be less than 20 characters"
                  value=""
                />
              </Col>
              <Col sm={3} md={3}>
                <Input
                  name="motorPurchaseVehicle.engineNumber"
                  id="motorPurchaseVehicle.engineNumber"
                  placeholder="eg: A12BC34567"
                  label="Engine No."
                  value=""
                  validationError="Engine no. must be at least 4 characters"
                  validations={{
                    isValidEngine: true,
                  }}
                />
              </Col>
              { this.props.application.productGroupId !== PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN
              && (
              <Col sm={3} md={3}>
                <Input
                  type="text"
                  name="motorPurchaseVehicle.odometer"
                  id="motorPurchaseVehicle.odometer"
                  changeDebounceInterval={0}
                  updateOnBlur={false}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value)}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value)}
                  changeCallback={this.handleOdometerChange}
                  label="Odometer"
                  value=""
                  validationError="Odometer must be positive"
                  validations={{
                    isFormattedNumber: true,
                    isRequired: true,
                  }}
                />
              </Col>
              )}               
            </Row>
            <Row>
              <Col sm={3} md={3}>
                <Input
                  name="motorPurchaseVehicle.vehicleRegistrationExpiry"
                  id="motorPurchaseVehicle.vehicleRegistrationExpiry"
                  ref="motorPurchaseVehicle.vehicleRegistrationExpiry"
                  placeholder={`eg: ${Moment().add(6, 'M').format('MM/YYYY')}`}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => Mask.dateFormsy(name, value, this.refs)}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label="Registration Expiry"
                  value=""
                  validationError="Registration expiry must be valid"
                  validations={{
                    isValidRegistrationExpiry: true,
                  }}
                />
              </Col>
              <Col sm={3}>
                <Input
                  name="motorPurchaseVehicle.vehicleRegistrationNumber"
                  id="motorPurchaseVehicle.vehicleRegistrationNumber"
                  ref="motorPurchaseVehicle.vehicleRegistrationNumber"
                  type="text"
                  label="Registration No."
                  placeholder="eg: ABC123."            
                  autoComplete="off"
                  validationError="Registration no. must be valid"
                  validations={{
                    isValidRegistration: true,
                  }}      
                  data-tip
                  data-for="plate_tooltip"
                />
                <ReactTooltip id="plate_tooltip" efect="solid" place="bottom">
                Letters and numbers only. Must be between {MOTOR_PLATE_MIN_LENGTH} and {MOTOR_PLATE_MAX_LENGTH} characters in length
                </ReactTooltip>
              </Col>
              <Col sm={3}>
                <Select
                    name="motorPurchaseVehicle.vehicleRegistrationState"
                    id="motorPurchaseVehicle.vehicleRegistrationState"
                    ref="motorPurchaseVehicle.vehicleRegistrationState"
                    label="State"
                    placeholder="Please select"
                    options={this.STATE_LIST.map(x =>({
                        "value" : x,
                        "label" : x
                    }))}              
                    validations={{
                      isValidState: true,
                    }}
                    className="form-control"                                      
                    data-tip
                    data-for="state_tooltip"
                />
                <ReactTooltip id="state_tooltip" efect="solid" place="bottom">
                State is required
                </ReactTooltip>
              </Col>
              <Col sm={3}>
                <Input
                  name="motorPurchaseVehicle.vin"
                  id="motorPurchaseVehicle.vin"
                  type="text"
                  label="Vehicle Identification Number (VIN)"
                  placeholder="eg: 1ABCD23DEFG456789."
                  autoComplete="off"
                  validationError="Must be 17 characters in length."
                  validations={{                 
                    isValidVin: true
                  }}
                  data-tip
                  data-for="vin_tooltip"
                />
                <ReactTooltip id="vin_tooltip" efect="solid" place="bottom">
                Must be {MOTOR_VIN_MIN_LENGTH} characters in length. Cannot include I, O or Q. Must contain at least 1 alpha and 1 numeric character
                </ReactTooltip>
              </Col>
            </Row>
                     
            <Row>
              <Col sm={3} md={3}>
                <Select
                  name="motorPurchaseVehicle.vendorType"
                  id="motorPurchaseVehicle.vendorType"
                  label="Vendor Type"
                  placeholder="Please select"
                  options={this.props.reference.vendorTypes}
                  className="form-control"
                />
              </Col>

              <Col sm={7} md={3}>
                <Input
                  name="motorPurchaseVehicle.vendorName"
                  id="motorPurchaseVehicle.vendorName"
                  label="Vendor Name"
                  value=""
                />
              </Col>
              <Col sm={3}>
                <div className="form-group form-group-offset">
                  <div className="checkbox inline">
                    <label className="control-label">Condition</label>
                    <div>
                      <Checkbox
                        name="motorPurchaseVehicle.isUsed"
                        id="motorPurchaseVehicle.isUsed"
                        valueLabel={<span>Pre-Owned vehicle</span>}
                        value={false}
                        changeCallback={this.handleIsUsedChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>                        
            </Row> 
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    reference: state.reference,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export const ApplicationUpdateVehicleConnectedwithoutActions = connect(mapStateToProps, {})(ApplicationUpdateVehicle);

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateVehicle);
