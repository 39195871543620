import { Dispatch } from "redux"
import moment = require("moment");
import { VeiLoanInformation } from "./reducer";
import { VeiOption } from "../applicationUpdatePage/applicationUpdateVei";
import { VehicleYearOnYearDepreciation } from "../applicationUpdatePage/veiTypes";
import Mask from "../../helpers/mask";

export type VeiCalculatorAction = ChangeLoanInfoAction;


export enum VeiCalculatorActionTypes {
    ChangeLoanInfoAction = "ChangeLoanInfoAction",
}
export interface ChangeLoanInfoAction {
    type: VeiCalculatorActionTypes.ChangeLoanInfoAction,

    value: string,
    isFormValid: boolean,
    change: (l: VeiLoanInformation,v: any) => VeiLoanInformation,

    veiOptions: VeiOption[],
    vehicleYearOnYearDepreciation: VehicleYearOnYearDepreciation[]
}

export const changeLoanInfo = (change: (l: VeiLoanInformation, v: any) => VeiLoanInformation, name: any, value: any, isFormValid: boolean) => (dispatch: Dispatch<ChangeLoanInfoAction>, getState:Function) => {
    dispatch({
        type:  VeiCalculatorActionTypes.ChangeLoanInfoAction,
        value: value,
        isFormValid: isFormValid,
        change: change,
        veiOptions:getState().reference.veiTypes,
        vehicleYearOnYearDepreciation:getState().reference.vehicleYearOnYearDepreciation
    });
}

export const changeLoanAmount = changeLoanInfo.bind(null, (l:VeiLoanInformation, v) : VeiLoanInformation => { console.log(JSON.stringify(this)); return {...l, loanAmount: Mask.clean(v)}});
export const changeLoanInterestRate = changeLoanInfo.bind(null, (l:VeiLoanInformation, v) : VeiLoanInformation=> { return {...l, interestRate: v}});
export const changeLoanTerm = changeLoanInfo.bind(null, (l:VeiLoanInformation, v): VeiLoanInformation => { return {...l, loanTermYears: v}});
export const changeVehicleValuationAmount = changeLoanInfo.bind(null, (l:VeiLoanInformation, v) : VeiLoanInformation=> { return {...l, vehicleValuationAmount: Mask.clean(v)}});
export const changeVehicleAge = changeLoanInfo.bind(null, (l:VeiLoanInformation, v) : VeiLoanInformation => { return {...l, vehicleYear: v }});
export const changeBalloonAmount = changeLoanInfo.bind(null, (l:VeiLoanInformation, v) : VeiLoanInformation => { return {...l, balloonAmount: Mask.clean(v)}});

export const VeiCalculatorActions = {
    changeLoanAmount,
    changeLoanInterestRate,
    changeLoanTerm,
    changeVehicleValuationAmount,
    changeVehicleAge,
    changeBalloonAmount
}
