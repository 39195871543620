import * as React from 'react';
import { AxiosError } from 'axios';
import ReferenceApi, { BidMetricData, BidMetrics, zipBidMetrics, bidMetricsToArray, BidMetric } from '../../api/referenceApi';
import { Loadr, Widget } from '../../utils';
import * as Moment from 'moment';
import * as ReactTooltip from 'react-tooltip';

interface BidUiData {
  name: string
  iconFontAwesomeClasses: string
  tooltip: React.ReactElement
}

const bidUiData : BidMetrics<BidUiData> = {
  turnaroundTime: {
    name: "Residential Turnaround Time",
    iconFontAwesomeClasses: "far fa-clock",
    tooltip: <span>Average number of days for application to be assessed for conditional approval</span>,
  },
  nps: {
    name: "Net Promoter Score",
    iconFontAwesomeClasses: "far fa-ruler-triangle",
    tooltip: <span>Momentum Intelligence net promoter score methodology as a loyalty score from -100 to +100</span>,
  },
  creditAssessorsScore: {
    name: "Credit Assessors Score",
    iconFontAwesomeClasses: "far fa-ruler-triangle",
    tooltip: <span>Momentum Intelligence broker ratings on access, ease, consistency of credit provider decision (score from -100 to +100)</span>,
  },
  trustScore: {
    name: "Trust Score",
    iconFontAwesomeClasses: "far fa-star",
    tooltip: <span><a href="https://au.trustpilot.com/review/www.liberty.com.au" target="_blank" rel="noreferrer">Trustpilot</a> customer reviews, based on average star rating out of 5</span>,
  },
  trustpilotReviews: {
    name: "Trustpilot Reviews",
    iconFontAwesomeClasses: "",
    tooltip: <span></span>,
  },
  productRating: {
    name: "Product Rating",
    iconFontAwesomeClasses: "far fa-star",
    tooltip: <span><a href="https://www.productreview.com.au/listings/liberty-financial" target="_blank" rel="noreferrer">Product Review</a> customer opinion website, where products are rated as a score out of 5</span>,
  },
  productReviews: {
    name: "Product Reviews",
    iconFontAwesomeClasses: "",
    tooltip: <span></span>,
  },
  dcaMembership: {
    name: "DCA Membership",
    iconFontAwesomeClasses: "far fa-check",
    tooltip: <span><a href="https://www.dca.org.au/" target="_blank" rel="noreferrer">Diversity Council of Australia</a> membership</span>,
  },
  rap: {
    name: "RAP",
    iconFontAwesomeClasses: "far fa-check",
    tooltip: <span><a href="https://www.reconciliation.org.au/" target="_blank" rel="noreferrer">Reconciliation Australia</a>: Reconciliation Action Plan</span>,
  },
  fiapMembership: {
    name: "FIAP Membership",
    iconFontAwesomeClasses: "far fa-check",
    tooltip: <span><a href="https://goodshepherdmicrofinance.org.au/services/financial-inclusion-action-plans-fiap/" target="_blank" rel="noreferrer">Financial Inclusion Action Plan Program</a> membership</span>,
  },
  bCorpScore: {
    name: "B Corp Impact Score",
    iconFontAwesomeClasses: "far fa-ruler-triangle",
    tooltip: <span><a href="https://www.bcorporation.com.au/directory/Australia/Victoria/Liberty-Financial" target="_blank" rel="noreferrer">Certified B Corporation</a></span>,
  },
};

function filterMetrics([metric, [data, uiData]] : [BidMetric, [BidMetricData, BidUiData]]) {
  if (metric === "ProductReviews" || metric === "TrustpilotReviews")
    return false; // Inlined into Product Rating and Trust Score
  if (data.unitType === "Boolean" && data.value === false || data.value === null)
    return false; // Don't display Boolean metrics we don't have, we don't want to show that we don't have something

  return true;
}

function getQuarter(date: Moment.Moment) : [year: number, quarter: number] {
  const currentQuarter = Math.ceil((date.month() + 1) / 3)
  if (currentQuarter === 1)
    return [date.year() - 1, 4]
  return [date.year(), currentQuarter - 1]
}

function formatQuarter(date: Moment.Moment) : string {
  const [year, quarter] = getQuarter(date);
  return `Q${quarter} ${year}`;
}

function BidLastUpdated(props: { metric: BidMetric, data: BidMetricData }) {
  const date = props.data.date !== null ? Moment(props.data.date, "YYYY-MM-DD") : null;
  const dateString =
    date !== null && props.data.cadence === "Quarterly" && (props.metric === "NPS" || props.metric === "CreditAssessorsScore")
      ? formatQuarter(date)
      : date?.format("D MMM [']YY");

  return (
    <p className="bid-last-updated mute mb-sm">
      Updated: {dateString}
    </p>
  )
}

function formatMetricValue(metric: BidMetricData) : string | null {
  if (metric.value === null)
    return null;
  else if (typeof metric.value === "number")
    return metric.value.toString();
  else if (typeof metric.value === "boolean")
    return "Yes";
  else if (metric.value.min === metric.value.max)
    return metric.value.max.toString();
  else
    return `${metric.value.min}-${metric.value.max}`;
}

function formatDenominator(metric: BidMetricData) : string {
  return metric.denominator !== null
    ? `/${metric.denominator}`
    : "";
}

function getMaxNumericValue(metric: BidMetricData) : number | null {
  if (metric.value === null)
    return null;
  else if (typeof metric.value === "number")
    return metric.value;
  else if (typeof metric.value === "boolean")
    return null;
  else
    return metric.value.max;
}

function formatUnitType(metric: BidMetricData) {
  switch (metric.unitType) {
    case "Days":
      const maxValue = getMaxNumericValue(metric);
      if (maxValue === null)
        return null;
      else if (maxValue > 1)
        return "days";
      else
        return "day";
    default:
      return null;
  }
}

function getReviewsCount(metric: BidMetric, allBidData: BidMetrics<[BidMetricData, BidUiData]>): number | null {
  switch (metric) {
    case "ProductRating":
      return getMaxNumericValue(allBidData.productReviews[0]);
    case "TrustScore":
      return getMaxNumericValue(allBidData.trustpilotReviews[0]);
    default:
      return null;
  }
}

function BidMetricValue(props: { metric: BidMetric, data: BidMetricData, uiData: BidUiData, allBidData: BidMetrics<[BidMetricData, BidUiData]> }) {
  const reviewsCount = getReviewsCount(props.metric, props.allBidData);
  const reviewsTextOrNothing = reviewsCount !== null ? `(${reviewsCount} reviews)` : null;
  return (
    <div>
      <span className="secondary">
        <i className={props.uiData.iconFontAwesomeClasses}></i>
      </span>
      <span className="bid-metric-value">
        { formatMetricValue(props.data) + formatDenominator(props.data) } { formatUnitType(props.data) }
      </span>
      <span className="bid-metric-value-aux mute">
        { reviewsTextOrNothing }
      </span>
    </div>
  )
}

function BidMetrics(props: { metrics: BidMetrics<BidMetricData> }) {
  const zippedBidData = React.useMemo(() => zipBidMetrics(props.metrics, bidUiData), [props.metrics])
  const bidData = React.useMemo(() => bidMetricsToArray(zippedBidData).filter(filterMetrics), [zippedBidData]);

  return (
    <dl className="bid">
      { bidData.map(([metric, [data, uiData]], index) =>
        <div className="bid-metric" key={index}>
          <dt>
            <h4 className="mb-h mt-xs">{uiData.name}</h4>
            <i className="mi mi-help-outline mute pointer bid-help-icon"
               data-tip
               data-for={`${metric}-tooltip`}
            />
            <ReactTooltip id={`${metric}-tooltip`} clickable={true} delayHide={1000} effect="solid">
              <div className="bid-help-tooltip">{uiData.tooltip}</div>
            </ReactTooltip>
          </dt>
          <dd>
            <BidLastUpdated metric={metric} data={data} />
            <BidMetricValue metric={metric} data={data} uiData={uiData} allBidData={zippedBidData} />
          </dd>
        </div>
      )}
    </dl>
  )
}

export interface BestInterestsDutyWidgetProps {
  io?: {
    getBestInterestsDutyMetrics: typeof ReferenceApi.getBestInterestsDutyMetrics
  }
}

export default function BestInterestsDutyWidget(props: BestInterestsDutyWidgetProps) {
  const [ bidMetricsDataResponse, setBidMetricsDataResponse ] = React.useState<BidMetrics<BidMetricData> | AxiosError | null>(null);
  const io = React.useMemo(() => props.io ?? { getBestInterestsDutyMetrics: ReferenceApi.getBestInterestsDutyMetrics.bind(ReferenceApi) }, [ props.io ])

  React.useEffect(() => {
    io.getBestInterestsDutyMetrics()
      .then(
        setBidMetricsDataResponse,
        (error: AxiosError) => setBidMetricsDataResponse(error)
      );
  }, [setBidMetricsDataResponse, io])

  return (
    <Widget title="Our Best Interests Duty Metrics">
      { bidMetricsDataResponse === null
        ? <Loadr />
        : "isAxiosError" in bidMetricsDataResponse
          ? <div className="bid-load-error">
              <i className="far fa-exclamation-triangle"></i>
              Failed to load (status code: { bidMetricsDataResponse.response?.status }).
            </div>
          : <BidMetrics metrics={bidMetricsDataResponse} />
      }
    </Widget>
  )
}
