import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';

import CurrencyInput from '../currencyInput';

import Mask from '../../../helpers/mask';
import {toOption, firstOption} from '../functions';

import Remove from '../remove';
import Title from '../title';

import {IOption} from '../../../interfaces';
import {ILoanSplit} from '../interfaces';

import {frequencyOptions, purposeOptions, structureOptions, repaymentOptions} from '../options';

interface ISplitProps {
  index: number,
  loanSplit: ILoanSplit,
  loanSplits: ILoanSplit[],
  loanAmount: number,
  removeLoanSplit: (id: string) => void
}
interface ISplitState {
  repayments: "principalAndInterest" | "interestOnly";
  structure: "variable" | "fixed";
}

export class Split extends React.Component<ISplitProps, ISplitState> {
  UNSAFE_componentWillMount() {
    this.state = {
      repayments: "principalAndInterest",
      structure: "variable",
    }

    this.changeSplit = this.changeSplit.bind(this);
  }

  shouldShowTerms(): boolean {
    return this.state.repayments == "interestOnly" || this.state.structure == "fixed";
  }

  changeSplit(field: string, value: string) {
    if (field) {
      let key = field.split(".")[1];

      this.setState(newState => {
        newState[key] = value;
        return newState;
      });
    }
  }

  get termOptions(): IOption[] {
    let options = "123";

    if (this.state.structure == "variable") {
      options += "45";
    }

    return options.split("").map(o => ({
      value: o,
      label: `${o} year${Number(o) > 1 ? 's' : ''}`
    }));
  }

  render() {
    return (
      <div className={`card card-shallow mt-lg p-lg`}>

        <Title top>
          Split {this.props.index + 1}
          {this.props.loanSplits.length > 1 && <Remove onClick={this.props.removeLoanSplit.bind(this, this.props.loanSplit.id)} />}
        </Title>

        <Row>
          <Col sm={3} className="pr-lg">
            <Select
              name={`loanSplits[${this.props.index}].repayments`}
              label="Repayments"
              options={repaymentOptions}
              value={firstOption(repaymentOptions)}
              changeCallback={this.changeSplit}
              className="form-control" />
          </Col>

          <Col sm={3} className="pl-lg pr-lg">
            <span className="mute a" style={{top: '41px', left: '-12px'}}>and</span>
            <Select
              name={`loanSplits[${this.props.index}].structure`}
              label="Structure"
              options={structureOptions}
              value={firstOption(structureOptions)}
              changeCallback={this.changeSplit}
              className="form-control"/>
          </Col>

          <Col sm={2} className={`pl-lg ${this.shouldShowTerms() ? '' : 'hidden'}`}>
            <span className="mute a" style={{top: '41px', left: '-10px'}}>for</span>

            <Select
              name={`loanSplits[${this.props.index}].term`}
              id={`loanSplits[${this.props.index}].term`}
              label={<span>&nbsp;</span>}
              options={this.termOptions}
              value={firstOption(this.termOptions)}
              className="form-control"/>
          </Col>

          <Col sm={3} smOffset={this.shouldShowTerms() ? 1 : 3}>
            <CurrencyInput
              name={`loanSplits[${this.props.index}].value`}
              label="Loan amount"
              align="right"
              validations={{
                isRequired: true,
                isNotZero: true,
                isSplitAmountValid: (values, value) => {
                  let totalAmount = 0;

                  for (let i = 0; i < this.props.loanSplits.length; i++) {
                    totalAmount += +Mask.clean(values[`loanSplits[${i}].value`])
                  }

                  return totalAmount == +this.props.loanAmount;
                }
              }}
              validationErrors={{
                isRequired: "Split amount is required",
                isNotZero: "Split amount can not be 0",
                isSplitAmountValid: "Split amounts must add up to loan amount"
              }}
              value={this.props.index == 0 ? Mask.number(this.props.loanAmount) : '0'}
            />
          </Col>

        </Row>

      </div>
    );

  }

}

export default Split;
