import * as React from 'react';
import PropTypes from 'prop-types';

const ApplicationApplicants = (props) => (
    <ul className="loan-client" data-hj-masked="true">
      {
        props.applicants.map((applicant, idx) => {
          /* eslint-disable no-fallthrough */
          switch (applicant.type) {
            case 'Company':
              if (applicant.status != 'Borrower') { return null; }
            case undefined:
            case 'Person':
              return (
                <li className="loan-client-name" key={`applicant-${idx}`}>{applicant.name}</li>
              );
            default:
              return null;
          }
        })
      }
    </ul>
);

ApplicationApplicants.propTypes = {
  applicants: PropTypes.array.isRequired,
};

export default ApplicationApplicants;
