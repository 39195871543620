import * as React from 'react';
import PropTypes from 'prop-types';
import MaskedInput, {IMaskedInputBaseProps} from './maskedInput';

interface IDateInputProps extends IMaskedInputBaseProps {
  precision: "day" | "month";
  placeholder?: any;
}

export class DateInput extends React.Component<IDateInputProps, any> {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getPlaceholder(precision) {
    return precision == "day" ? 'dd/mm/yyyy' : 'mm/yyyy';
  }

  getMaxLength(precision) {
    return this.getPlaceholder(precision).length
  }

  render(): JSX.Element {
    return (
      <MaskedInput
        name={this.props.name}
        label={this.props.label}
        placeholder={this.getPlaceholder(this.props.precision)}
        maxLength={this.getMaxLength(this.props.precision)}
        value=""
        changeCallback={this.props.changeCallback}
        blurCallback={this.props.blurCallback}
        validations={{
          isValidDate: true,
          ...this.props.validations
        }}
        validationErrors={{
          isValidDate: "Must be a valid date",
          ...this.props.validationErrors
        }} />
    );
  }
}

export default DateInput;
