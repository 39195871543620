
import initialState from './initialState';
import * as types from '../constants/types';
const objectAssign = require('object-assign');

export default function calculatorsReducer(state = initialState.calculators, action) {
  switch (action.type) {
    case types.SEARCH_PREQUALS: {
      const prequals = action.data || [];
      return objectAssign({}, state, { prequals });
    }

    case types.GET_PREQUAL: {
      const prequal = action.data;
      return objectAssign({}, state, { prequal });
    }

    case types.ADD_PREQUAL: {
      const prequal = action.data;
      return objectAssign({}, state, { prequal });
    }

    case types.CALCULATE_SERVICEABILITY:
      return state;

    case types.CALCULATE_LVR:
      return state;

    default:
      return state;
  }
}
