import * as React from 'react';
import {Form} from 'formsy-react-components';

import * as Guid from 'guid';

import Mask from '../../../helpers/mask';

import Navigation from '../navigation';
import Header from '../header';
import Title from '../title';
import AddButton from '../addButton';

import Expenses from './expenses';
import Loan from './loan';
import Other from './other';

import {liabilityOptions, expenseOptions} from '../options'

import {IOption} from '../../../interfaces';
import {IGuid, IAssets, IApplicants, IBaseProps, ILiabilityItem, IFormData} from '../interfaces';

import {shouldFormBeVisible, toOption, hasFormData, setFormData, getCurrentFormData} from '../functions';

interface LiabilitiesData {
  expense: {
    [name: string]: {
      description: string;
      amount: string;
    }
  };
  liabilities?: ILiabilityItem[];
}

interface ILiabilitiesState {
  liabilities: ILiabilityItem[];
  initiated: IInitiated;
}
interface ILiabilityProps extends IBaseProps {}
interface IInitiated {
  rent: boolean;
  homeLoan: boolean;
}

export const onValidSubmit = (data: IFormData, applicants: IApplicants, liabilities: ILiabilityItem[], notifyStep: (message: string) => void, saveStepData: (data: IFormData, key: string) => void) => {
  // if renting, should have rent liability, duh
  if (isApplicantRenting(applicants) && !hasRentLiability(liabilities)) {
    return notifyStep("No rent entered...");
  }

  if (!hasExpensesEntered(data)) {
    return notifyStep("No living expenses entered...");
  }

  saveStepData(data, 'liabilities');
}

const onInvalidSubmit = (notifyStep) => notifyStep();

const mapIcon = (option: IOption) => {
  switch (option.value) {
    case 'homeLoan': return 'home';
    case 'vehicleLoan': return 'car';
    case 'securedLoan': return 'balance';
    case 'personalLoan': return 'account';
    case 'creditCard': return 'card';
    case 'rent': return 'balance-wallet';
    default: return 'receipt';
  }
}

const isApplicantRenting = (applicants: IApplicants) => {
  return applicants.individuals.some(individual => ["renting", "boarding"].indexOf(individual.currentAddress.occupancy) > -1);
}

const hasHomeLoanLiability = (liabilities: ILiabilityItem[]) => {
  return hasLiability(liabilities, 'homeLoan');
}

const hasLiability = (liabilities: ILiabilityItem[], type: string) => {
  return liabilities.some(liability => liability.type == type);
}

const shouldAddRentLiability = (applicants: IApplicants, state: ILiabilitiesState) => {
  return isApplicantRenting(applicants) && !hasRentLiability(state.liabilities);
}

const shouldAddHomeLoanLiability = (assets: IAssets, state: ILiabilitiesState) => {
  return hasPropertyAsset(assets) && !hasHomeLoanLiability(state.liabilities)
}

const hasExpensesEntered = (data: LiabilitiesData) => {
  let totalExpenses = expenseOptions
    .map(option => option.value)
    .reduce((total: number, field) => {
      let amount = Number(data.expense[field] && data.expense[field].amount);
      return (isNaN(amount) ? 0 : amount) + total;
    }, 0);

  return totalExpenses > 0;
}

const hasRentLiability = (liabilities: ILiabilityItem[]) => {
  return hasLiability(liabilities, 'rent');
}

const hasPropertyAsset = (assets: IAssets) => {
  return assets.specificAssets && assets.specificAssets.some(asset => asset.type == "property");
}

export class Liabilities extends React.Component<ILiabilityProps, ILiabilitiesState> {
  constructor(props) {
    super(props);

    this.state = {
      liabilities: [],
      initiated: {
        rent: false,
        homeLoan: false
      }
    };

    this.addLiability = this.addLiability.bind(this);
    this.removeLiability = this.removeLiability.bind(this);
    this.changeLiabilityRepayment = this.changeLiabilityRepayment.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (hasFormData(this.props.data, 'liabilities')) {
      this.props.data.liabilities.liabilities &&
      this.props.data.liabilities.liabilities.forEach(liability => {
        this.addLiability(
          liabilityOptions.filter(liabilityOption => liabilityOption.value == liability.type)[0]
        );
      });
    } else {
      if (shouldAddRentLiability(this.props.data.applicants, this.state)) {
        this.addInitialLiability("Rent");
      }

      if (shouldAddHomeLoanLiability(this.props.data.assets, this.state)) {
        this.addInitialLiability("Home loan");
      }
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'liabilities')) {
      setFormData(this.props.data, 'liabilities', this.refs.form);
      setTimeout(() => this.forceUpdate()); // manually trigger re-render because Formsy doesn't
    }
  }

  addInitialLiability(type: string) {
    this.addLiability(
      toOption(type)
    );

    let newState = this.state;
    newState.initiated[toOption(type).value] = true;
    this.setState(newState);
  }

  addLiability(option: IOption) {
    const liabilities = this.state.liabilities;
    liabilities.push({
      id: Guid.create().value,
      type: option.value as string,
      name: option.label,
      repayments: 0,
      limit: 0
    });
    this.setState({liabilities});
  }

  removeLiability(id: IGuid) {
    const liabilities = this.state.liabilities.filter(asset => asset.id != id);
    this.setState({liabilities});
  }

  changeLiabilityRepayment(id: IGuid, repayments: number | string) {
    const liabilities = this.state.liabilities.map(liability => {
      if (liability.id == id) {
        liability.repayments = Mask.clean(repayments);
      }

      return liability;
    });
    this.setState({liabilities});
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={(data: IFormData) => onValidSubmit(data, this.props.data.applicants, this.state.liabilities, this.props.notifyStep, this.props.saveStepData)}
        onInvalidSubmit={() => onInvalidSubmit(this.props.notifyStep)}
        className={shouldFormBeVisible(this.props.currentStep, 3)}
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">
            <Header title="Liabilities" required />

            <div className="panel-section">
              <Title noborder>Liabilities</Title>
              <div className="panel-section-body panel-section-body-simple">
                <div className="panel-section-container panel-section-container-well">

                {
                  this.state.liabilities.map((liability, index) => {
                    switch (liability.type) {
                      case 'rent':
                      case 'other': {
                        return <Other key={liability.id} index={index} liability={liability} changeLiabilityRepayment={this.changeLiabilityRepayment} removeLiability={this.removeLiability} />
                      }
                      default: {
                        return <Loan key={liability.id} index={index} liability={liability} changeLiabilityRepayment={this.changeLiabilityRepayment} removeLiability={this.removeLiability} data={this.props.data} />
                      }
                    }
                  })
                }

                <div className="panel-section-btns">
                  {
                    liabilityOptions.map(option => {
                      return (
                        <AddButton
                          glyph={mapIcon(option)}
                          label={option.label}
                          onClick={this.addLiability.bind(this, option)} />
                      );
                    })
                  }
                </div>

                </div>
              </div>
            </div>

            <Expenses form={this.refs.form} liabilities={this.state.liabilities} />
          </div>
        </div>

        <Navigation saveSubmission={() => this.props.saveSubmission(getCurrentFormData(this.refs.form), "liabilities")} previousStep={this.props.previousStep} />
      </Form>
    );
  }
}

export default Liabilities;
