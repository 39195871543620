import * as React from 'react';
import PropTypes from 'prop-types';

export default class ProfileBank extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  render() {
    const details = JSON.parse(JSON.stringify(this.props.details));
    if (!details.bankAccount) {
      details.bankAccount = {
        bank: '',
        bsb: '',
        accountNumber: '',
      };
    }

    return (
      <div className="panel-part">
        <h5 className="panel-bar">Bank details</h5>

        <dl className="dl-horizontal">
          <dt>Bank name</dt>
          <dd data-hj-masked>{this.props.getValue(details.bankAccount.bank)}</dd>

          <dt>BSB</dt>
          <dd data-hj-masked>{this.props.getValue(details.bankAccount.bsb)}</dd>

          <dt>Account</dt>
          <dd data-hj-masked>{this.props.getValue(details.bankAccount.accountNumber.toString().replace(/x/g, '•'))}</dd>
        </dl>
      </div>
    );
  }
}

ProfileBank.propTypes = {
  details: PropTypes.any,
  getValue: PropTypes.func,
};
