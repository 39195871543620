import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';

import { Icon } from '../../utils';

import * as calculatorsActions from '../../actions/calculatorsActions';

class CalculatorModal extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      valid: true,
    };

    this.hideModal = this.hideModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateName = this.validateName.bind(this);
  }

  hideModal() {
    this.props.setModal(false);
  }

  handleSubmit(data) {
    this.props.setProfile(data);
    this.hideModal();
  }

  validateName(event) {
    const query = event.target.value;

    this.props.actions.searchPrequals(query)
      .then((response) => {
        const valid = !response.data.some((match) => match.profileName.trim().toUpperCase() == query.trim().toUpperCase());
        this.setState({
          valid,
        }, () => this.form.formsyForm.current.validate(this.name));
      });
  }

  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.hideModal}
      >

        <Form
          onValidSubmit={this.handleSubmit}
          layout="vertical"
          ref={(form) => { this.form = form; }}
        >

          <Modal.Body>
            <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
              <span aria-hidden="true">×</span>
            </button>

            <div className="text-center">
              <Icon glyph="account-add" className="mt-lg mb-lg mute-lg" size={6} />
            </div>

            <Input
              id="name"
              name="name"
              ref={(name) => { this.name = name; }}
              type="text"
              label="Name"
              data-hj-masked
              autoFocus
              autoComplete="off"
              value={this.props.suggestedName}
              className="form-control form-control-lg"
              validationError="Name is required and must be unique"
              validations={{
                isRequired: true,
                customValidator: () => this.state.valid,
              }}
              changeDebounceInterval={0}
              onKeyUp={this.validateName}
            />

          </Modal.Body>

          <Modal.Footer>
            <button type="button" onClick={this.hideModal} className="btn btn-default">Cancel</button>
            <button type="submit" className="btn btn-success">Save</button>
          </Modal.Footer>

        </Form>

      </Modal>
    );
  }
}

CalculatorModal.propTypes = {
  suggestedName: PropTypes.string,
  setModal: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

CalculatorModal.defaultProps = {
  suggestedName: '',
};

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...calculatorsActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorModal);
