import API from '../api';
import * as types from '../constants/types';

export const calculateServiceability = (data) => (dispatch) => API.calculateServiceability(data)
  .then((response) => dispatch({
    type: types.CALCULATE_SERVICEABILITY,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const workshopWithBdm = (data) => (dispatch) => API.workshopWithBdm(data)
  .then((response) => dispatch({
    type: types.WORKSHOP_WITH_BDM,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const calculateRate = (data) => (dispatch, getState) => API.calculateRate(data)
  .then((response) => dispatch({
    type: types.CALCULATE_RATE,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const calculateLvr = (postcode) => (dispatch) => API.calculateLvr(postcode)
  .then((response) => dispatch({
    type: types.CALCULATE_LVR,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const calculateMotorRepayments = (data) => (dispatch) => API.calculateMotorRepayments(data)
  .then((response) => dispatch({
    type: types.CALCULATE_MOTOR_REPAYMENTS,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const submitRateQuote = (data) => (dispatch) => API.submitRateQuote(data)
  .then((response) => dispatch({
    type: types.SUBMIT_RATE_QUOTE,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const trackRateQuote = (data) => (dispatch) => API.trackRateQuote(data)
  .then((response) => dispatch({
    type: types.TRACK_RATE_QUOTE,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });
  
export const callMeBack = (data) => (dispatch) => API.callMeBack(data)
  .then((response) => dispatch({
    type: types.CALL_ME_BACK,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const searchPrequals = (query) => (dispatch, getState) => API.searchPrequals(query, getState().session.details.isDev)
  .then((response) => dispatch({
    type: types.SEARCH_PREQUALS,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });

export const getPrequal = (id) => (dispatch, getState) => API.getPrequal(id, getState().session.details.isDev)
  .then((response) => dispatch({
    type: types.GET_PREQUAL,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });
  
export const savePrequal = (name, data, id, profileType) => (dispatch, getState) => API.savePrequal(name, data, id, profileType, getState().session.details.isDev)
  .then((response) => dispatch({
    type: types.SAVE_PREQUAL,
    data: response.data,
  }))
  .catch((error) => {
    throw (error);
  });
  
export const addPrequal = (data) => (dispatch) => dispatch({
  type: types.ADD_PREQUAL,
  data,
});
