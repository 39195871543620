import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';

import Guid from 'guid';
import Mask from '../../helpers/mask';

export default class ServiceAbilityProductMotor extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      lvr: 0,
      splits: [{
        splitId: Guid.create().value,
      }],
    };
  }

inputRefs = {};

setRef = (id) => (ref) => {
  if (ref) this.inputRefs[id] = ref;
  else delete this.inputRefs[id];
};

getRiskGrades = () => [{
  value: 'AAA',
  label: 'Owner/Buyer',
}, {
  value: 'Other',
  label: 'Other',
}]

getLoanTerms() {
  const terms = [];
  for (let value = 3; value <= 7; value++) {
    terms.push({
      value,
      label: `${value} years`,
    });
  }

  return terms;
}

/**
   * Render
   */
render() {
  return (
      <div className="card card-shallow card-stack expenses">
        <Row className={this.state.splits.length > 1 ? 'r panel-row' : 'r mb-md-n'}>

          <Input
            id={`multiLoanDetails[${this.props.index}].id`}
            name={`multiLoanDetails[${this.props.index}].id`}
            value={this.props.loans[this.props.index].loanId}
            type="hidden"
          />

          <Col sm={3}>
            <div className="form-prefix">$</div>
            <Input
              id={`multiLoanDetails[${this.props.index}].purchaseAmount`}
              name={`multiLoanDetails[${this.props.index}].purchaseAmount`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].purchaseAmount`)}
              type="text"
              label={(
                <span>
                  {'Loan Amount '}
                  <small className={`mute ${this.state.splits.length == 1 ? 'hidden' : ''}`}>(total)</small>
                </span>
              )}
              value={this.props.data && this.props.data.multiLoanDetails ? Mask.number(this.props.data.multiLoanDetails[this.props.index].purchaseAmount) : 0}
              changeDebounceInterval={0}
              updateOnBlur={false}
              blurCallback={(name, value) => {
                Mask.numberFormsy(name, value, this.inputRefs);
              }}
              validationError="Loan Amount is required and must be between $5,000 and $100,000"
              validations={{
                customValidator: (values, value) => {
                  const loanAmount = parseInt(value.toString().replace(/[^0-9.]/g, ''));

                  if (!loanAmount || loanAmount < 5000 || loanAmount > 100000) {
                    return false;
                  }

                  return true;
                },
              }}
            />
          </Col>

          <Col sm={2}>
            <Select
              id={`multiLoanDetails[${this.props.index}].term`}
              name={`multiLoanDetails[${this.props.index}].term`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].term`)}
              label="Loan Term"
              value={this.getLoanTerms()[2].value}
              options={this.getLoanTerms()}
              className="form-control"
            />
          </Col>

          <Col sm={2}>
            <div className="form-prefix">%</div>
            <Input
              id={`multiLoanDetails[${this.props.index}].interestRate`}
              name={`multiLoanDetails[${this.props.index}].interestRate`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].interestRate`)}
              type="text"
              label={<span>Rate</span>}
              value={this.props.data && this.props.data.multiLoanDetails && this.props.data.multiLoanDetails[this.props.index] ? (this.props.data.multiLoanDetails[this.props.index].interestRate) : 0}
              validationError="Interest rate is required"
              validations={{
                isFloat: true,
                isExisty: true,
                isRequired: true,
                customValidator: (values, value) => value <= 30,
              }}
            />
          </Col>

          <Col sm={3}>
            <div className="form-prefix">$</div>
            <Input
              id={`multiLoanDetails[${this.props.index}].residualAmount`}
              name={`multiLoanDetails[${this.props.index}].residualAmount`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].residualAmount`)}
              type="text"
              label={(
                <span>
                  Residual/Balloon Amount
                  {' '}
                  <small className={`mute ${this.state.splits.length == 1 ? 'hidden' : ''}`}>(total)</small>
                </span>
              )}
              value={this.props.data && this.props.data.multiLoanDetails ? Mask.number(this.props.data.multiLoanDetails[this.props.index].residualAmount) : 0}
              changeDebounceInterval={0}
              updateOnBlur={false}
              blurCallback={(name, value) => {
                Mask.numberFormsy(name, value, this.inputRefs);
              }}
              validationError="Loan Amount must be greater than Residual/Balloon Amount"
              validations={{
                customValidator: (values, value) => {
                  let loanAmount = 0;
                  this.state.splits.map((split, index) => {
                    loanAmount += parseInt(Mask.clean(values[`multiLoanDetails[${index}].purchaseAmount`]) || 0);
                  });
                  const residualAmount = parseInt(value.toString().replace(/[^0-9.]/g, ''));
                  return loanAmount > residualAmount;
                },
              }}
            />
          </Col>

        </Row>

        {
          this.state.splits.map((split, index) => (
              <Row key={`split-${split.splitId}`} className="r panel-row mt-lg">
                <Input
                  id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].id`}
                  name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].id`}
                  value={split.splitId}
                  type="hidden"
                />
                {this.state.splits.length > 1 && (
                  <h3 className="panel-row-title">
                    Split
                    {' '}
                    {index + 1}
                  </h3>
                )}
              </Row>
          ))
        }
      </div>
  );
}
}

ServiceAbilityProductMotor.propTypes = {
  index: PropTypes.number.isRequired,
  loans: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};
