import * as React from 'react';
import PropTypes from 'prop-types';

import ComServiceAbilityModal from './comServiceAbilityModal';

export default class ComServiceAbilityHeader extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      fineprint: false,
    };

    this.toggleFineprint = this.toggleFineprint.bind(this);
  }

  toggleFineprint() {
    this.setState({
      fineprint: !this.state.fineprint,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="mt-lg pt-sm mb-lg text-center np">
        <h1 className="mt-lg" id="comServiceabilityHeader">
        Commercial Mortgage Serviceability Calculator
        </h1>
        <h4 className="mute">Estimate your customer&apos;s servicing capacity</h4>
        <button type="button" id="showServiceabilityModal" className="btn btn-link" onClick={this.toggleFineprint}>
        {'Things you should know '}
        <span className="hidden-xs">about this calculator</span>
        </button>

        <ComServiceAbilityModal hideModal={this.toggleFineprint} visible={this.state.fineprint} />
      </div>
    );
  }
}

ComServiceAbilityHeader.propTypes = {
};

ComServiceAbilityHeader.defaultProps = {
};
