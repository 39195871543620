import {toOption, toOptionGroup} from './functions';
import {IOption} from '../../interfaces';

export const liabilityOptions: IOption[] = ["Home loan", "Vehicle loan", "Personal loan", "Secured loan", "Credit card", "Rent", "Other"].map(o => toOption(o));
export const valuationOptions: IOption[] = ["Purchase price", "Valuation", "E-valuer", "Rateable value", "Project cost", "Market value", "Owner estimate"].map(o => toOption(o));
export const propertyTypeOptions: IOption[] = ["House", "Unit (under 40m2)", "Unit (over 40m2)", "Apartment (serviced)", "Multi unit", "Office", "Land", "Shop", "Factory", "Warehouse"].map(o => toOption(o));
export const propertyTitleOptions: IOption[] = ["Torrens", "Stratum", "Time share", "Old law", "Crown lease", "Company share", "Community title", "Building unit titles"].map(o => toOption(o));
export const propertyZoneOptions: IOption[] = ["Residential", "Rural", "Business", "Commercial", "Industrial"].map(o => toOption(o));
export const propertyUseOptions: IOption[] = ["Residence", "Investment"].map(o => toOption(o));
export const currentUseOptions: IOption[] = [...propertyUseOptions, toOption("To be sold")];
export const landSizeOptions: IOption[] = [{label: "Under 20.000 m2", value: "under2ha"}, {label: "20.000 m2 or over", value: "over2ha"}];

export const vehicleMakeOptions: IOption[] = [ "Abarth", "Alfa Romeo", "Aston Martin", "Audi", "Bentley", "Bolwell", "BMW", "Bufori", "Bugatti", "Caterham", "Chery", "Chevrolet", "Chrysler", "Citroen", "Dodge", "Ferrari", "Fiat", "Ford", "Great Wall", "Haval", "Holden", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "Kia", "Lamborghini", "Land Rover", "Lexus", "Lotus", "Maserati", "Mazda", "Mini", "Mitsubishi", "Nissan", "Opel", "Peugeot", "Porsche", "Proton", "Renault", "Skoda", "SsangYong", "Subaru", "Suzuki", "Tata", "Toyota", "Volkswagen"].map(o => toOption(o));

export const frequencyOptions: IOption[] = ["Monthly", "Fortnightly", "Weekly"].map(o => toOption(o));
export const purposeOptions: IOption[] = ["Owner occupied", "Investment"].map(o => toOption(o, false));
export const structureOptions: IOption[] = ["Variable", "Fixed"].map(o => toOption(o));
export const repaymentOptions: IOption[] = ["Principal and interest", "Interest only"].map(o => toOption(o));

export const titleOptions: IOption[] =  ["Mr.", "Mrs.", "Miss", "Dr.", "Ms."].map(o => toOption(o));
export const dependantOptions: IOption[] =  "1 2 3 4 5 6".split(" ").map(o => toOption(o));
export const relationshipOptions: IOption[] =  ["Single", "Married", "De-facto", "Separated", "Divorced", "Widowed"].map(o => toOption(o));
export const genderOptions: IOption[] =  ["Male", "Female"].map(o => toOption(o));

export const assetOptions: IOption[] = ["Bank account", "Household items", "Shares", "Superannuation", "Life insurance", "Business value", "Plant and equipment", "SMSF assets", "Other"].map(o => toOption(o));
export const lenderOptions: IOption[] = [
    "Adelaide Bank",
    "AFG Home Loans",
    "AMO",
    "AMP Bank",
    "ANZ",
    "Arab bank Australia",
    "Aussie",
    "Australian Military Bank",
    "Australian Unity",
    "Auswide Bank",
    "Bank Australia",
    "Bank of Melbourne",
    "Bank of Sydney",
    "BankSA",
    "BankVic",
    "Bankwest",
    "BCU",
    "Bendigo Bank",
    "Beyond Bank",
    "BOQ",
    "Carloans.com.au",
    "Catalyst Money",
    "Citi",
    "ClickLoans",
    "Commonwealth Bank",
    "Community First Credit Union",
    "CUA",
    "Delphi Bank",
    "Easy Street Financial Services",
    "ECU",
    "Endeavour Mutual Bank",
    "FCCS Credit Union",
    "First Option Credit Union",
    "Firstmac",
    "Freedom Lend",
    "G&C Mutual Bank",
    "Gateway Credit Union",
    "Greater Bank",
    "Heritage Bank",
    "Holiday Coast Credit Union",
    "Homeloans.com.au",
    "Homestar Finance",
    "Horizon Credit Union",
    "HSBC Home Loans",
    "Hume Bank",
    "Hunter United",
    "Illawarra Credit Union",
    "IMB Bank",
    "ING Direct",
    "Keystart",
    "Liberty",
    "Loans.com.au",
    "Macquarie Bank",
    "Macquarie Credit Union",
    "ME Bank",
    "Mortgage House",
    "MOVE",
    "My Credit Union",
    "MyState",
    "NAB",
    "Newcastle Permanent",
    "Northern Inland Credit Union",
    "P&N Bank",
    "Pacific Mortgage Group",
    "People’s Choice Credit Union",
    "Pepper",
    "Police Bank",
    "QBANK",
    "Qudos Bank",
    "Queenslanders Credit Union",
    "RAMS",
    "Reduce",
    "Regional Australia Bank",
    "Resi",
    "SCU",
    "SERVICE ONE Alliance Bank",
    "St.George Bank",
    "State Custodians",
    "Summerland Credit Union",
    "Suncorp Bank",
    "Teachers Mutual Bank",
    "The Capricornian",
    "The Mac",
    "The Rock",
    "Transport Mutual Credit Union",
    "UBank",
    "Unibank",
    "Virgin Money",
    "Westpac",
    "Woolworths Employees’ Credit Union",
    "Yellow Brick Road"
  ].map(lender => toOption(lender, false));

  export const otherLiabilityOptions: IOption[] = ["Body Corporate fees", "Council rates", "Insurance", "Other", "Unpaid tax", "Water rates", "Debts/foreclosure"].map(o => toOption(o));
  export const expenseOptions: IOption[] = ["Child care", "Education", "Entertainment", "Food", "Insurance", "Maintenance", "Transport", "Utilities/rates", "Other"].map(o => toOption(o));
  export const roleOptions: IOption[] = [
    {value: "1111-11", label: "Parliamentarian or Councillor"}, 
    {value: "1111-13", label: "Judge"}, 
    {value: "1111-15", label: "Magistrate"}, 
    {value: "1111-17", label: "Tribunal Member"}, 
    {value: "1111-79", label: "Legislators and Government Appointed Officials nec"}, 
    {value: "1112-11", label: "General Manager"}, 
    {value: "1191-11", label: "Construction Project Manager"}, 
    {value: "1191-13", label: "Project Builder"}, 
    {value: "1192-11", label: "Importer or Exporter"}, 
    {value: "1192-13", label: "Wholesaler"}, 
    {value: "1193-11", label: "Manufacturer"}, 
    {value: "1211-11", label: "Finance Manager"}, 
    {value: "1212-11", label: "Company Secretary"}, 
    {value: "1213-11", label: "Human Resource Manager"}, 
    {value: "1221-11", label: "Engineering Manager"}, 
    {value: "1222-11", label: "Production Manager (Manufacturing)"}, 
    {value: "1222-13", label: "Production Manager (Mining)"}, 
    {value: "1223-11", label: "Supply and Distribution Manager"}, 
    {value: "1224-11", label: "Information Technology Manager"}, 
    {value: "1231-11", label: "Sales and Marketing Manager"}, 
    {value: "1291-11", label: "Policy and Planning Manager"}, 
    {value: "1292-11", label: "Director of Nursing"}, 
    {value: "1292-13", label: "Medical Administrator"}, 
    {value: "1293-11", label: "School Principal"}, 
    {value: "1293-13", label: "Faculty Head"}, 
    {value: "1293-15", label: "Regional Education Manager"}, 
    {value: "1293-79", label: "Education Managers nec"}, 
    {value: "1294-11", label: "Commissioned Defence Force Officer"}, 
    {value: "1294-13", label: "Commissioned Fire Officer"}, 
    {value: "1294-15", label: "Commissioned Police Officer"}, 
    {value: "1294-81", label: "Trainee Commissioned Defence Force Officer"}, 
    {value: "1295-11", label: "Child Care Co-ordinator"}, 
    {value: "1296-11", label: "Media Producer"}, 
    {value: "1296-13", label: "Artistic Director"}, 
    {value: "1299-11", label: "Research and Development Manager"}, 
    {value: "1299-13", label: "Laboratory Manager"}, 
    {value: "1299-15", label: "Welfare Centre Manager"}, 
    {value: "1299-17", label: "Environment, Parks and Land Care Manager"}, 
    {value: "1299-19", label: "Sports Administrator"}, 
    {value: "1299-79", label: "Specialist Managers nec"}, 
    {value: "1311-11", label: "Mixed Crop and Livestock Farmer"}, 
    {value: "1312-11", label: "Mixed Livestock Farmer"}, 
    {value: "1312-13", label: "Beef Cattle Farmer"}, 
    {value: "1312-15", label: "Dairy Farmer"}, 
    {value: "1312-17", label: "Sheep Farmer"}, 
    {value: "1312-19", label: "Pig Farmer"}, 
    {value: "1312-21", label: "Poultry Farmer"}, 
    {value: "1312-23", label: "Horse Breeder"}, 
    {value: "1312-25", label: "Apiarist"}, 
    {value: "1312-79", label: "Livestock Farmers nec"}, 
    {value: "1313-11", label: "Grain, Oilseed and Pasture Grower"}, 
    {value: "1313-13", label: "Sugar Cane Grower"}, 
    {value: "1313-15", label: "Tobacco Grower"}, 
    {value: "1313-17", label: "Fruit and Nut Grower"}, 
    {value: "1313-19", label: "Vegetable Grower"}, 
    {value: "1313-21", label: "Flower Grower"}, 
    {value: "1313-79", label: "Crop Farmers nec"}, 
    {value: "1314-11", label: "Aquaculture Farmer"}, 
    {value: "2111-11", label: "Chemist"}, 
    {value: "2112-11", label: "Geologist"}, 
    {value: "2112-13", label: "Geophysicist"}, 
    {value: "2113-11", label: "Anatomist or Physiologist"}, 
    {value: "2113-13", label: "Botanist"}, 
    {value: "2113-15", label: "Zoologist"}, 
    {value: "2113-17", label: "Biochemist"}, 
    {value: "2113-19", label: "Marine Biologist"}, 
    {value: "2113-79", label: "Life Scientists nec"}, 
    {value: "2114-11", label: "Environmental Research Scientist"}, 
    {value: "2114-13", label: "Forester"}, 
    {value: "2114-15", label: "Park Ranger"}, 
    {value: "2114-17", label: "Soil Scientist"}, 
    {value: "2114-19", label: "Agricultural Scientist"}, 
    {value: "2114-21", label: "Agricultural Adviser"}, 
    {value: "2114-79", label: "Environmental and Agricultural Science Professionals nec"}, 
    {value: "2115-11", label: "Medical Scientist"}, 
    {value: "2119-11", label: "Physicist"}, 
    {value: "2119-13", label: "Meteorologist"}, 
    {value: "2119-15", label: "Extractive Metallurgist"}, 
    {value: "2119-17", label: "Physical Metallurgist"}, 
    {value: "2119-19", label: "Materials Scientist"}, 
    {value: "2119-79", label: "Natural and Physical Science Professionals nec"}, 
    {value: "2121-11", label: "Architect"}, 
    {value: "2121-13", label: "Landscape Architect"}, 
    {value: "2122-11", label: "Quantity Surveyor"}, 
    {value: "2123-11", label: "Cartographer"}, 
    {value: "2123-13", label: "Surveyor"}, 
    {value: "2124-11", label: "Civil Engineer"}, 
    {value: "2125-11", label: "Electrical Engineer"}, 
    {value: "2125-13", label: "Electronics Engineer"}, 
    {value: "2126-11", label: "Mechanical Engineer"}, 
    {value: "2126-13", label: "Production or Plant Engineer"}, 
    {value: "2127-11", label: "Mining Engineer (excluding Petroleum)"}, 
    {value: "2127-13", label: "Petroleum Engineer"}, 
    {value: "2127-15", label: "Materials Engineer"}, 
    {value: "2128-11", label: "Civil Engineering Technologist"}, 
    {value: "2128-13", label: "Mechanical Engineering Technologist"}, 
    {value: "2128-15", label: "Electrical or Electronics Engineering Technologist"}, 
    {value: "2128-79", label: "Engineering Technologists nec"}, 
    {value: "2129-11", label: "Aeronautical Engineer"}, 
    {value: "2129-13", label: "Agricultural Engineer"}, 
    {value: "2129-15", label: "Biomedical Engineer"}, 
    {value: "2129-17", label: "Chemical Engineer"}, 
    {value: "2129-19", label: "Industrial Engineer"}, 
    {value: "2129-21", label: "Naval Architect"}, 
    {value: "2129-79", label: "Building and Engineering Professionals nec"}, 
    {value: "2211-11", label: "Accountant"}, 
    {value: "2212-11", label: "External Auditor"}, 
    {value: "2212-13", label: "Internal Auditor"}, 
    {value: "2213-11", label: "Corporate Treasurer"}, 
    {value: "2221-11", label: "Public Relations Officer"}, 
    {value: "2221-13", label: "Marketing Specialist"}, 
    {value: "2221-15", label: "Market Research Analyst"}, 
    {value: "2221-17", label: "Advertising Specialist"}, 
    {value: "2222-11", label: "Sales Representative (Industrial Products)"}, 
    {value: "2222-13", label: "Sales Representative (Information and Communication Products)"}, 
    {value: "2222-15", label: "Sales Representative (Medical and Pharmaceutical Products)"}, 
    {value: "2222-79", label: "Technical Sales Representatives nec"}, 
    {value: "2231-11", label: "Systems Manager"}, 
    {value: "2231-13", label: "Systems Designer"}, 
    {value: "2231-15", label: "Software Designer"}, 
    {value: "2231-17", label: "Applications and Analyst Programmer"}, 
    {value: "2231-19", label: "Systems Programmer"}, 
    {value: "2231-21", label: "Computer Systems Auditor"}, 
    {value: "2231-79", label: "Computing Professionals nec"}, 
    {value: "2291-11", label: "Personnel Officer"}, 
    {value: "2291-13", label: "Personnel Consultant"}, 
    {value: "2291-15", label: "Industrial Relations Officer"}, 
    {value: "2291-17", label: "Training Officer"}, 
    {value: "2292-11", label: "Librarian"}, 
    {value: "2293-11", label: "Mathematician"}, 
    {value: "2293-13", label: "Statistician"}, 
    {value: "2293-15", label: "Actuary"}, 
    {value: "2294-11", label: "Management Consultant"}, 
    {value: "2294-13", label: "Organisation and Methods Analyst"}, 
    {value: "2294-15", label: "Quality Assurance Manager"}, 
    {value: "2295-13", label: "Land Economist"}, 
    {value: "2295-11", label: "Valuer"}, 
    {value: "2299-11", label: "Health Information Manager"}, 
    {value: "2299-13", label: "Records Manager"}, 
    {value: "2299-15", label: "Archivist"}, 
    {value: "2299-17", label: "Policy Analyst"}, 
    {value: "2299-19", label: "Intelligence Officer"}, 
    {value: "2299-79", label: "Business and Information Professionals nec"}, 
    {value: "2311-11", label: "General Medical Practitioner"}, 
    {value: "2311-81", label: "Medical Practitioner in Training"}, 
    {value: "2312-11", label: "Anaesthetist"}, 
    {value: "2312-13", label: "Dermatologist"}, 
    {value: "2312-15", label: "Emergency Medicine Specialist"}, 
    {value: "2312-17", label: "Obstetrician and Gynaecologist"}, 
    {value: "2312-19", label: "Ophthalmologist"}, 
    {value: "2312-21", label: "Paediatrician"}, 
    {value: "2312-23", label: "Pathologist"}, 
    {value: "2312-25", label: "Specialist Physician"}, 
    {value: "2312-27", label: "Psychiatrist"}, 
    {value: "2312-29", label: "Radiologist"}, 
    {value: "2312-31", label: "Surgeon"}, 
    {value: "2312-79", label: "Specialist Medical Practitioners nec"}, 
    {value: "2321-11", label: "Nurse Manager"}, 
    {value: "2322-11", label: "Nurse Educator"}, 
    {value: "2322-13", label: "Nurse Researcher"}, 
    {value: "2323-11", label: "Registered Nurse"}, 
    {value: "2324-11", label: "Registered Midwife"}, 
    {value: "2325-11", label: "Registered Mental Health Nurse"}, 
    {value: "2326-11", label: "Registered Developmental Disability Nurse"}, 
    {value: "2381-11", label: "Dentist"}, 
    {value: "2381-13", label: "Dental Specialist"}, 
    {value: "2382-11", label: "Hospital Pharmacist"}, 
    {value: "2382-13", label: "Industrial Pharmacist"}, 
    {value: "2382-15", label: "Retail Pharmacist"}, 
    {value: "2383-11", label: "Occupational Therapist"}, 
    {value: "2384-11", label: "Optometrist"}, 
    {value: "2385-11", label: "Physiotherapist"}, 
    {value: "2386-11", label: "Speech Pathologist"}, 
    {value: "2387-11", label: "Chiropractor"}, 
    {value: "2387-13", label: "Osteopath"}, 
    {value: "2388-11", label: "Podiatrist"}, 
    {value: "2391-11", label: "Medical Diagnostic Radiographer"}, 
    {value: "2391-13", label: "Radiation Therapist"}, 
    {value: "2391-15", label: "Nuclear Medicine Technologist"}, 
    {value: "2391-17", label: "Sonographer"}, 
    {value: "2392-11", label: "Veterinarian"}, 
    {value: "2393-11", label: "Dietitian"}, 
    {value: "2394-11", label: "Naturopath"}, 
    {value: "2394-13", label: "Acupuncturist"}, 
    {value: "2394-79", label: "Natural Therapy Professionals nec"}, 
    {value: "2399-11", label: "Audiologist"}, 
    {value: "2399-13", label: "Orthoptist"}, 
    {value: "2399-15", label: "Orthotist"}, 
    {value: "2399-79", label: "Health Professionals nec"}, 
    {value: "2411-11", label: "Pre-Primary School Teacher"}, 
    {value: "2412-11", label: "Primary School Teacher"}, 
    {value: "2413-11", label: "Secondary School Teacher"}, 
    {value: "2414-11", label: "Special Needs Teacher"}, 
    {value: "2414-13", label: "Teacher of the Hearing Impaired"}, 
    {value: "2414-15", label: "Teacher of the Sight Impaired"}, 
    {value: "2414-79", label: "Special Education Teachers nec"}, 
    {value: "2421-11", label: "University Lecturer"}, 
    {value: "2421-13", label: "University Tutor"}, 
    {value: "2422-11", label: "Vocational Education Teacher"}, 
    {value: "2491-11", label: "Art Teacher (Private)"}, 
    {value: "2491-13", label: "Music Teacher (Private)"}, 
    {value: "2491-15", label: "Dance Teacher (Private)"}, 
    {value: "2491-17", label: "Drama Teacher (Private)"}, 
    {value: "2491-79", label: "Extra-Systemic Teachers nec"}, 
    {value: "2492-11", label: "English as a Second Language Teacher"}, 
    {value: "2493-11", label: "Education Officer"}, 
    {value: "2511-11", label: "Social Worker"}, 
    {value: "2512-11", label: "Welfare Worker"}, 
    {value: "2512-13", label: "Community Worker"}, 
    {value: "2513-11", label: "Rehabilitation Counsellor"}, 
    {value: "2513-13", label: "Drug and Alcohol Counsellor"}, 
    {value: "2513-15", label: "Family Counsellor"}, 
    {value: "2513-17", label: "Careers Counsellor"}, 
    {value: "2513-19", label: "Student Counsellor"}, 
    {value: "2513-79", label: "Counsellors nec"}, 
    {value: "2514-11", label: "Clinical Psychologist"}, 
    {value: "2514-13", label: "Educational Psychologist"}, 
    {value: "2514-15", label: "Organisational Psychologist"}, 
    {value: "2514-79", label: "Psychologists nec"}, 
    {value: "2515-11", label: "Minister of Religion"}, 
    {value: "2521-11", label: "Barrister"}, 
    {value: "2521-13", label: "Solicitor"}, 
    {value: "2521-79", label: "Legal Professionals nec"}, 
    {value: "2522-11", label: "Economist"}, 
    {value: "2523-11", label: "Urban and Regional Planner"}, 
    {value: "2529-11", label: "Historian"}, 
    {value: "2529-13", label: "Interpreter"}, 
    {value: "2529-15", label: "Translator"}, 
    {value: "2529-79", label: "Social Professionals nec"}, 
    {value: "2531-11", label: "Painter (Visual Arts)"}, 
    {value: "2531-13", label: "Sculptor"}, 
    {value: "2531-15", label: "Potter or Ceramic Artist"}, 
    {value: "2531-79", label: "Visual Arts and Crafts Professionals nec"}, 
    {value: "2532-11", label: "Photographer"}, 
    {value: "2533-11", label: "Fashion Designer"}, 
    {value: "2533-13", label: "Graphic Designer"}, 
    {value: "2533-15", label: "Industrial Designer"}, 
    {value: "2533-17", label: "Interior Designer"}, 
    {value: "2533-19", label: "Illustrator"}, 
    {value: "2534-11", label: "Editor"}, 
    {value: "2534-13", label: "Print Journalist"}, 
    {value: "2534-15", label: "Television Journalist"}, 
    {value: "2534-17", label: "Radio Journalist"}, 
    {value: "2534-19", label: "Copywriter"}, 
    {value: "2534-21", label: "Technical Writer"}, 
    {value: "2534-79", label: "Journalists and Related Professionals nec"}, 
    {value: "2535-11", label: "Author"}, 
    {value: "2535-13", label: "Book Editor"}, 
    {value: "2535-15", label: "Script Editor"}, 
    {value: "2536-11", label: "Art Director (Film, Television or Stage)"}, 
    {value: "2536-13", label: "Director (Film, Television, Radio or Stage)"}, 
    {value: "2536-15", label: "Director of Photography"}, 
    {value: "2536-17", label: "Film and Video Editor"}, 
    {value: "2536-19", label: "Stage Manager"}, 
    {value: "2536-21", label: "Program Director (Radio or Television)"}, 
    {value: "2536-23", label: "Technical Director"}, 
    {value: "2536-79", label: "Film, Television, Radio and Stage Directors nec"}, 
    {value: "2537-11", label: "Music Director"}, 
    {value: "2537-13", label: "Singer"}, 
    {value: "2537-15", label: "Instrumental Musician"}, 
    {value: "2537-17", label: "Composer"}, 
    {value: "2537-79", label: "Musicians and Related Professionals nec"}, 
    {value: "2538-11", label: "Actor"}, 
    {value: "2538-13", label: "Dancer or Choreographer"}, 
    {value: "2538-79", label: "Actors, Dancers and Related Professionals nec"}, 
    {value: "2539-11", label: "Radio Presenter"}, 
    {value: "2539-13", label: "Television Presenter"}, 
    {value: "2541-11", label: "Aircraft Pilot"}, 
    {value: "2541-13", label: "Air Traffic Controller"}, 
    {value: "2541-15", label: "Flight Service Officer"}, 
    {value: "2541-17", label: "Flight Engineer"}, 
    {value: "2541-19", label: "Flying Instructor"}, 
    {value: "2541-79", label: "Air Transport Professionals nec"}, 
    {value: "2542-17", label: "Ships Surveyor"}, 
    {value: "2542-11", label: "Ships Master"}, 
    {value: "2542-13", label: "Master Fisher"}, 
    {value: "2542-15", label: "Ships Engineer"}, 
    {value: "2542-19", label: "Ships Officer"}, 
    {value: "2542-79", label: "Sea Transport Professionals nec"}, 
    {value: "2543-11", label: "Occupational Health and Safety Officer"}, 
    {value: "2543-13", label: "Environmental Health Officer"}, 
    {value: "2549-11", label: "Conservator"}, 
    {value: "2549-13", label: "Electorate Officer"}, 
    {value: "2549-15", label: "Patents Examiner"}, 
    {value: "2549-17", label: "Oenologist"}, 
    {value: "2549-19", label: "Recreation Officer"}, 
    {value: "2549-21", label: "Museum or Gallery Curator"}, 
    {value: "2549-79", label: "Professionals nec"}, 
    {value: "3111-11", label: "Medical Laboratory Technical Officer"}, 
    {value: "3111-79", label: "Medical Technical Officers nec"}, 
    {value: "3112-11", label: "Chemistry Technical Officer"}, 
    {value: "3112-13", label: "Earth Science Technical Officer"}, 
    {value: "3112-15", label: "Life Science Technical Officer"}, 
    {value: "3112-17", label: "Agricultural Technical Officer"}, 
    {value: "3112-79", label: "Science Technical Officers nec"}, 
    {value: "3121-11", label: "Building Associate"}, 
    {value: "3121-13", label: "Architectural Associate"}, 
    {value: "3121-15", label: "Surveying and Cartographic Associate"}, 
    {value: "3121-17", label: "Building Inspector"}, 
    {value: "3121-19", label: "Plumbing Inspector"}, 
    {value: "3121-21", label: "Plumbing Engineering Associate"}, 
    {value: "3122-11", label: "Civil Engineering Associate"}, 
    {value: "3122-13", label: "Civil Engineering Technician"}, 
    {value: "3123-11", label: "Electrical Engineering Associate"}, 
    {value: "3123-13", label: "Electrical Engineering Technician"}, 
    {value: "3124-11", label: "Electronic Engineering Associate"}, 
    {value: "3124-13", label: "Electronic Engineering Technician"}, 
    {value: "3125-11", label: "Mechanical Engineering Associate"}, 
    {value: "3125-13", label: "Mechanical Engineering Technician"}, 
    {value: "3129-11", label: "Biomedical Engineering Associate"}, 
    {value: "3129-13", label: "Metallurgical and Materials Technician"}, 
    {value: "3129-15", label: "Mine Deputy"}, 
    {value: "3129-79", label: "Building and Engineering Associate Professionals nec"}, 
    {value: "3211-11", label: "Branch Accountant (Financial Institution)"}, 
    {value: "3211-13", label: "Financial Institution Branch Manager"}, 
    {value: "3212-11", label: "Stockbroking Dealer"}, 
    {value: "3212-13", label: "Futures Trader"}, 
    {value: "3212-15", label: "Financial Market Dealer"}, 
    {value: "3212-17", label: "Commodities Trader"}, 
    {value: "3212-19", label: "Insurance Broker"}, 
    {value: "3212-21", label: "Bookmaker"}, 
    {value: "3212-79", label: "Financial Dealers and Brokers nec"}, 
    {value: "3213-11", label: "Financial Investment Adviser"}, 
    {value: "3291-11", label: "Office Manager"}, 
    {value: "3292-11", label: "Project or Program Administrator"}, 
    {value: "3293-11", label: "Real Estate Agency Manager"}, 
    {value: "3293-13", label: "Property Manager"}, 
    {value: "3293-15", label: "Real Estate Salesperson"}, 
    {value: "3294-11", label: "Computing Support Technician"}, 
    {value: "3311-11", label: "Shop Manager"}, 
    {value: "3321-11", label: "Restaurant and Catering Manager"}, 
    {value: "3322-01", label: "Head Chef"}, 
    {value: "3322-11", label: "Chef"}, 
    {value: "3323-11", label: "Hotel or Motel Manager"}, 
    {value: "3324-11", label: "Club Manager (Licensed Premises)"}, 
    {value: "3325-11", label: "Caravan Park and Camping Ground Manager"}, 
    {value: "3329-11", label: "Other Hospitality and Accommodation Managers"}, 
    {value: "3391-11", label: "Fitness Centre Manager"}, 
    {value: "3391-13", label: "Other Sports Centre Manager"}, 
    {value: "3391-15", label: "Amusement Centre Manager"}, 
    {value: "3391-79", label: "Sport and Recreation Managers nec"}, 
    {value: "3392-11", label: "Customer Service Manager"}, 
    {value: "3393-11", label: "Transport Company Manager"}, 
    {value: "3399-11", label: "Post Office Manager"}, 
    {value: "3399-13", label: "Railway Station Manager"}, 
    {value: "3399-15", label: "Betting Agency Branch Manager"}, 
    {value: "3399-17", label: "Hair and Beauty Salon Manager"}, 
    {value: "3399-19", label: "Car Rental Agency Manager"}, 
    {value: "3399-21", label: "Fleet Manager"}, 
    {value: "3399-23", label: "Stock and Station Agent"}, 
    {value: "3399-25", label: "Travel Agency Manager"}, 
    {value: "3399-27", label: "Theatre or Cinema Manager"}, 
    {value: "3399-29", label: "Funeral Director"}, 
    {value: "3399-79", label: "Managing Supervisors (Sales and Service) nec"}, 
    {value: "3411-11", label: "Enrolled Nurse"}, 
    {value: "3421-11", label: "Parole or Probation Officer"}, 
    {value: "3421-13", label: "Youth Worker"}, 
    {value: "3421-15", label: "Residential Care Officer"}, 
    {value: "3421-17", label: "Disabilities Services Officer"}, 
    {value: "3421-19", label: "Family Support Worker"}, 
    {value: "3491-11", label: "Ambulance Officer"}, 
    {value: "3491-13", label: "Intensive Care Ambulance Paramedic"}, 
    {value: "3492-11", label: "Dental Therapist"}, 
    {value: "3492-13", label: "Dental Hygienist"}, 
    {value: "3492-15", label: "Dental Technician"}, 
    {value: "3493-11", label: "Aboriginal and Torres Strait Islander Health Worker"}, 
    {value: "3494-11", label: "Massage Therapist"}, 
    {value: "3911-01", label: "Supervisor, Police Officers"}, 
    {value: "3911-11", label: "Police Officer"}, 
    {value: "3991-11", label: "Primary Products Inspector"}, 
    {value: "3992-11", label: "Safety Inspector"}, 
    {value: "3993-11", label: "Jockey"}, 
    {value: "3993-13", label: "Golfer"}, 
    {value: "3993-15", label: "Footballer"}, 
    {value: "3993-17", label: "Other Sportsperson"}, 
    {value: "3993-19", label: "Gymnastics Coach"}, 
    {value: "3993-21", label: "Tennis Coach"}, 
    {value: "3993-23", label: "Swimming Coach"}, 
    {value: "3993-25", label: "Horseriding Coach"}, 
    {value: "3993-27", label: "Other Sports Coach"}, 
    {value: "3993-29", label: "Sports Development Officer"}, 
    {value: "3993-31", label: "Sports Umpire"}, 
    {value: "3993-33", label: "Horse or Dog Racing Official"}, 
    {value: "3993-35", label: "Other Sports Official"}, 
    {value: "3994-11", label: "Senior Non-Commissioned Defence Force Officer"}, 
    {value: "3995-11", label: "Senior Fire Fighter"}, 
    {value: "3996-11", label: "Retail Buyer"}, 
    {value: "3997-11", label: "Library Technician"}, 
    {value: "3999-11", label: "Interior Decorator"}, 
    {value: "3999-13", label: "Museum or Art Gallery Technician"}, 
    {value: "3999-15", label: "Radio Operator"}, 
    {value: "3999-17", label: "Private Investigator"}, 
    {value: "3999-19", label: "Security Adviser"}, 
    {value: "3999-79", label: "Associate Professionals nec"}, 
    {value: "4111-01", label: "Supervisor, General Mechanical Engineering Tradespersons"}, 
    {value: "4111-11", label: "General Mechanical Engineering Tradesperson"}, 
    {value: "4111-81", label: "Apprentice General Mechanical Engineering Tradesperson"}, 
    {value: "4112-01", label: "Supervisor, Metal Fitters and Machinists"}, 
    {value: "4112-11", label: "Fitter"}, 
    {value: "4112-13", label: "Metal Machinist (First Class)"}, 
    {value: "4112-15", label: "Textile, Clothing or Footwear Mechanic"}, 
    {value: "4112-81", label: "Apprentice Fitter"}, 
    {value: "4112-83", label: "Apprentice Metal Machinist"}, 
    {value: "4112-85", label: "Apprentice Textile, Clothing or Footwear Mechanic"}, 
    {value: "4113-11", label: "Toolmaker"}, 
    {value: "4113-01", label: "Supervisor, Toolmakers"}, 
    {value: "4113-81", label: "Apprentice Toolmaker"}, 
    {value: "4114-01", label: "Supervisor, Aircraft Maintenance Engineers"}, 
    {value: "4114-11", label: "Aircraft Maintenance Engineer (Mechanical)"}, 
    {value: "4114-13", label: "Aircraft Maintenance Engineer (Structures)"}, 
    {value: "4114-15", label: "Aircraft Maintenance Engineer (Avionics)"}, 
    {value: "4114-81", label: "Apprentice Aircraft Maintenance Engineer (Mechanical)"}, 
    {value: "4114-83", label: "Apprentice Aircraft Maintenance Engineer (Structures)"}, 
    {value: "4114-85", label: "Apprentice Aircraft Maintenance Engineer (Avionics)"}, 
    {value: "4115-01", label: "Supervisor, Precision Metal Tradespersons"}, 
    {value: "4115-11", label: "Precision Instrument Maker and Repairer"}, 
    {value: "4115-13", label: "Watch and Clock Maker and Repairer"}, 
    {value: "4115-15", label: "Locksmith"}, 
    {value: "4115-17", label: "Saw Maker and Repairer"}, 
    {value: "4115-19", label: "Gunsmith"}, 
    {value: "4115-21", label: "Engraver"}, 
    {value: "4115-81", label: "Apprentice Precision Metal Tradesperson"}, 
    {value: "4121-01", label: "Supervisor, General Fabrication Engineering Tradespersons"}, 
    {value: "4121-11", label: "General Fabrication Engineering Tradesperson"}, 
    {value: "4121-81", label: "Apprentice General Fabrication Engineering Tradesperson"}, 
    {value: "4122-01", label: "Supervisor, Structural Steel and Welding Tradespersons"}, 
    {value: "4122-11", label: "Metal Fabricator"}, 
    {value: "4122-13", label: "Pressure Welder"}, 
    {value: "4122-15", label: "Welder (First Class)"}, 
    {value: "4122-81", label: "Apprentice Metal Fabricator"}, 
    {value: "4122-83", label: "Apprentice Welder"}, 
    {value: "4123-01", label: "Supervisor, Forging Tradespersons"}, 
    {value: "4123-11", label: "Blacksmith"}, 
    {value: "4123-13", label: "Farrier"}, 
    {value: "4123-81", label: "Apprentice Blacksmith"}, 
    {value: "4123-83", label: "Apprentice Farrier"}, 
    {value: "4124-01", label: "Supervisor, Sheetmetal Tradespersons"}, 
    {value: "4124-11", label: "Sheetmetal Worker (First Class)"}, 
    {value: "4124-81", label: "Apprentice Sheetmetal Worker"}, 
    {value: "4125-01", label: "Supervisor, Metal Casting Tradespersons"}, 
    {value: "4125-11", label: "Metal Casting Tradesperson"}, 
    {value: "4125-81", label: "Apprentice Metal Casting Tradesperson"}, 
    {value: "4126-01", label: "Supervisor, Metal Finishing Tradespersons"}, 
    {value: "4126-11", label: "Metal Polisher"}, 
    {value: "4126-13", label: "Electroplater (First Class)"}, 
    {value: "4126-81", label: "Apprentice Metal Polisher"}, 
    {value: "4126-83", label: "Apprentice Electroplater"}, 
    {value: "4211-01", label: "Supervisor, Motor Mechanics"}, 
    {value: "4211-11", label: "Motor Mechanic"}, 
    {value: "4211-81", label: "Apprentice Motor Mechanic"}, 
    {value: "4212-01", label: "Supervisor, Automotive Electricians"}, 
    {value: "4212-11", label: "Automotive Electrician"}, 
    {value: "4212-81", label: "Apprentice Automotive Electrician"}, 
    {value: "4213-01", label: "Supervisor, Panel Beaters"}, 
    {value: "4213-11", label: "Panel Beater"}, 
    {value: "4213-81", label: "Apprentice Panel Beater"}, 
    {value: "4214-01", label: "Supervisor, Vehicle Painters"}, 
    {value: "4214-11", label: "Vehicle Painter"}, 
    {value: "4214-81", label: "Apprentice Vehicle Painter"}, 
    {value: "4215-01", label: "Supervisor, Vehicle Body Makers"}, 
    {value: "4215-11", label: "Vehicle Body Maker"}, 
    {value: "4215-81", label: "Apprentice Vehicle Body Maker"}, 
    {value: "4216-01", label: "Supervisor, Vehicle Trimmers"}, 
    {value: "4216-11", label: "Vehicle Trimmer"}, 
    {value: "4216-81", label: "Apprentice Vehicle Trimmer"}, 
    {value: "4311-01", label: "Supervisor, Electricians"}, 
    {value: "4311-11", label: "General Electrician"}, 
    {value: "4311-13", label: "Electrician (Special Class)"}, 
    {value: "4311-15", label: "Lift Mechanic"}, 
    {value: "4311-81", label: "Apprentice Electrician"}, 
    {value: "4311-83", label: "Apprentice Lift Mechanic"}, 
    {value: "4312-01", label: "Supervisor, Refrigeration and Airconditioning Mechanics"}, 
    {value: "4312-11", label: "Refrigeration and Airconditioning Mechanic"}, 
    {value: "4312-81", label: "Apprentice Refrigeration and Airconditioning Mechanic"}, 
    {value: "4313-01", label: "Supervisor, Electrical Distribution Tradespersons"}, 
    {value: "4313-11", label: "Electrical Powerline Tradesperson"}, 
    {value: "4313-13", label: "Cable Jointer"}, 
    {value: "4313-81", label: "Apprentice Electrical Powerline Tradesperson"}, 
    {value: "4313-83", label: "Apprentice Cable Jointer"}, 
    {value: "4314-01", label: "Supervisor, Electronic Instrument Tradespersons"}, 
    {value: "4314-11", label: "General Electronic Instrument Tradesperson"}, 
    {value: "4314-13", label: "Electronic Instrument Tradesperson (Special Class)"}, 
    {value: "4314-81", label: "Apprentice Electronic Instrument Tradesperson"}, 
    {value: "4315-01", label: "Supervisor, Electronic and Office Equipment Tradespersons"}, 
    {value: "4315-11", label: "Electronic Equipment Tradesperson"}, 
    {value: "4315-13", label: "Business Machine Mechanic"}, 
    {value: "4315-81", label: "Apprentice Electronic Equipment Tradesperson"}, 
    {value: "4315-83", label: "Apprentice Business Machine Mechanic"}, 
    {value: "4316-01", label: "Supervisor, Communications Tradespersons"}, 
    {value: "4316-11", label: "General Communications Tradesperson"}, 
    {value: "4316-13", label: "Communications Linesperson"}, 
    {value: "4316-81", label: "Apprentice General Communications Tradesperson"}, 
    {value: "4316-83", label: "Apprentice Communications Linesperson"}, 
    {value: "4411-01", label: "Supervisor, Carpentry and Joinery Tradespersons"}, 
    {value: "4411-11", label: "Carpenter and Joiner"}, 
    {value: "4411-13", label: "Carpenter"}, 
    {value: "4411-15", label: "Joiner"}, 
    {value: "4411-81", label: "Apprentice Carpenter and Joiner"}, 
    {value: "4411-83", label: "Apprentice Carpenter"}, 
    {value: "4411-85", label: "Apprentice Joiner"}, 
    {value: "4412-01", label: "Supervisor, Fibrous Plasterers"}, 
    {value: "4412-11", label: "Fibrous Plasterer"}, 
    {value: "4412-81", label: "Apprentice Fibrous Plasterer"}, 
    {value: "4413-01", label: "Supervisor, Roof Slaters and Tilers"}, 
    {value: "4413-11", label: "Roof Slater and Tiler"}, 
    {value: "4413-81", label: "Apprentice Roof Slater and Tiler"}, 
    {value: "4414-01", label: "Supervisor, Bricklayers"}, 
    {value: "4414-11", label: "Bricklayer"}, 
    {value: "4414-81", label: "Apprentice Bricklayer"}, 
    {value: "4415-01", label: "Supervisor, Solid Plasterers"}, 
    {value: "4415-11", label: "Solid Plasterer"}, 
    {value: "4415-81", label: "Apprentice Solid Plasterer"}, 
    {value: "4416-01", label: "Supervisor, Wall and Floor Tilers and Stonemasons"}, 
    {value: "4416-11", label: "Wall and Floor Tiler"}, 
    {value: "4416-13", label: "Stonemason"}, 
    {value: "4416-81", label: "Apprentice Wall and Floor Tiler"}, 
    {value: "4416-83", label: "Apprentice Stonemason"}, 
    {value: "4421-01", label: "Supervisor, Painters and Decorators"}, 
    {value: "4421-11", label: "Painter and Decorator"}, 
    {value: "4421-81", label: "Apprentice Painter and Decorator"}, 
    {value: "4422-01", label: "Supervisor, Signwriters"}, 
    {value: "4422-11", label: "Signwriter"}, 
    {value: "4422-81", label: "Apprentice Signwriter"}, 
    {value: "4423-01", label: "Supervisor, Floor Finishers"}, 
    {value: "4423-11", label: "Floor Finisher"}, 
    {value: "4423-81", label: "Apprentice Floor Finisher"}, 
    {value: "4431-01", label: "Supervisor, Plumbers"}, 
    {value: "4431-11", label: "General Plumber"}, 
    {value: "4431-13", label: "Gasfitter"}, 
    {value: "4431-15", label: "Drainer"}, 
    {value: "4431-17", label: "Roof Plumber"}, 
    {value: "4431-19", label: "Mechanical Services and Airconditioning Plumber"}, 
    {value: "4431-81", label: "Apprentice General Plumber"}, 
    {value: "4431-83", label: "Apprentice Gasfitter"}, 
    {value: "4431-85", label: "Apprentice Drainer"}, 
    {value: "4431-87", label: "Apprentice Roof Plumber"}, 
    {value: "4431-89", label: "Apprentice Mechanical Services and Airconditioning Plumber"}, 
    {value: "4511-01", label: "Supervisor, Meat Tradespersons"}, 
    {value: "4511-11", label: "Butcher"}, 
    {value: "4511-13", label: "Smallgoods Maker"}, 
    {value: "4511-15", label: "Slaughterperson"}, 
    {value: "4511-81", label: "Apprentice Butcher"}, 
    {value: "4511-83", label: "Apprentice Smallgoods Maker"}, 
    {value: "4511-85", label: "Apprentice Slaughterperson"}, 
    {value: "4512-01", label: "Supervisor, Bakers and Pastrycooks"}, 
    {value: "4512-11", label: "Baker"}, 
    {value: "4512-13", label: "Pastrycook"}, 
    {value: "4512-81", label: "Apprentice Baker"}, 
    {value: "4512-83", label: "Apprentice Pastrycook"}, 
    {value: "4513-11", label: "Cook"}, 
    {value: "4513-81", label: "Apprentice Cook or Chef"}, 
    {value: "4519-11", label: "Miller"}, 
    {value: "4519-13", label: "Buttermaker or Cheesemaker"}, 
    {value: "4519-15", label: "Confectioner"}, 
    {value: "4519-81", label: "Apprentice Miller"}, 
    {value: "4519-83", label: "Apprentice Buttermaker or Cheesemaker"}, 
    {value: "4519-85", label: "Apprentice Confectioner"}, 
    {value: "4611-11", label: "Farm Overseer"}, 
    {value: "4612-11", label: "Shearer"}, 
    {value: "4613-11", label: "Wool Classer"}, 
    {value: "4613-13", label: "Hide and Skin Classer"}, 
    {value: "4614-11", label: "Horse Trainer"}, 
    {value: "4614-79", label: "Animal Trainers nec"}, 
    {value: "4621-11", label: "Nurseryperson"}, 
    {value: "4621-81", label: "Apprentice Nurseryperson"}, 
    {value: "4622-11", label: "Greenkeeper"}, 
    {value: "4622-81", label: "Apprentice Greenkeeper"}, 
    {value: "4623-01", label: "Head Gardener"}, 
    {value: "4623-11", label: "General Gardener"}, 
    {value: "4623-13", label: "Landscape Gardener"}, 
    {value: "4623-15", label: "Tree Surgeon"}, 
    {value: "4623-81", label: "Apprentice General Gardener"}, 
    {value: "4623-83", label: "Apprentice Landscape Gardener"}, 
    {value: "4623-85", label: "Apprentice Tree Surgeon"}, 
    {value: "4911-11", label: "Graphic Pre-Press Tradesperson"}, 
    {value: "4911-81", label: "Apprentice Graphic Pre-Press Tradesperson"}, 
    {value: "4912-11", label: "Printing Machinist"}, 
    {value: "4912-13", label: "Small Offset Printer"}, 
    {value: "4912-81", label: "Apprentice Printing Machinist"}, 
    {value: "4912-83", label: "Apprentice Small Offset Printer"}, 
    {value: "4913-11", label: "Binder and Finisher"}, 
    {value: "4913-81", label: "Apprentice Binder and Finisher"}, 
    {value: "4914-11", label: "Screen Printer"}, 
    {value: "4914-81", label: "Apprentice Screen Printer"}, 
    {value: "4921-11", label: "Wood Machinist (A-Grade)"}, 
    {value: "4921-13", label: "Wood Turner"}, 
    {value: "4921-81", label: "Apprentice Wood Machinist"}, 
    {value: "4921-83", label: "Apprentice Wood Turner"}, 
    {value: "4922-01", label: "Supervisor, Cabinetmakers"}, 
    {value: "4922-11", label: "Cabinetmaker"}, 
    {value: "4922-81", label: "Apprentice Cabinetmaker"}, 
    {value: "4929-11", label: "Picture Framer"}, 
    {value: "4929-13", label: "Furniture Finisher"}, 
    {value: "4929-79", label: "Wood Tradespersons nec"}, 
    {value: "4929-81", label: "Apprentice Picture Framer"}, 
    {value: "4929-83", label: "Apprentice Furniture Finisher"}, 
    {value: "4929-99", label: "Apprentice Wood Tradespersons nec"}, 
    {value: "4931-01", label: "Supervisor, Hairdressers"}, 
    {value: "4931-11", label: "Hairdresser"}, 
    {value: "4931-81", label: "Apprentice Hairdresser"}, 
    {value: "4941-11", label: "General Clothing Tradesperson"}, 
    {value: "4941-13", label: "Tailor"}, 
    {value: "4941-15", label: "Dressmaker"}, 
    {value: "4941-17", label: "Apparel Cutter"}, 
    {value: "4941-19", label: "Patternmaker-Grader (Clothing)"}, 
    {value: "4941-79", label: "Clothing Tradespersons nec"}, 
    {value: "4941-81", label: "Apprentice Clothing Tradesperson"}, 
    {value: "4942-11", label: "Furniture Upholsterer"}, 
    {value: "4942-79", label: "Upholsterers and Bedding Tradespersons nec"}, 
    {value: "4942-81", label: "Apprentice Upholsterer or Bedding Tradespersons"}, 
    {value: "4943-11", label: "Shoemaker"}, 
    {value: "4943-13", label: "Medical Grade Shoemaker"}, 
    {value: "4943-81", label: "Apprentice Shoemaker"}, 
    {value: "4944-11", label: "Leather Goods Maker"}, 
    {value: "4944-13", label: "Canvas Goods Maker"}, 
    {value: "4944-15", label: "Sail Maker"}, 
    {value: "4944-81", label: "Apprentice Leather Goods, Canvas Goods or Sail Maker"}, 
    {value: "4981-11", label: "Shipwright"}, 
    {value: "4981-13", label: "Boat Builder and Repairer"}, 
    {value: "4981-81", label: "Apprentice Shipwright"}, 
    {value: "4981-83", label: "Apprentice Boat Builder and Repairer"}, 
    {value: "4982-11", label: "Flat Glass Tradesperson"}, 
    {value: "4982-13", label: "Glass Blower"}, 
    {value: "4982-81", label: "Apprentice Flat Glass Tradesperson"}, 
    {value: "4982-83", label: "Apprentice Glass Blower"}, 
    {value: "4983-11", label: "Jeweller"}, 
    {value: "4983-13", label: "Gem Cutter and Polisher"}, 
    {value: "4983-81", label: "Apprentice Jeweller"}, 
    {value: "4983-83", label: "Apprentice Gem Cutter and Polisher"}, 
    {value: "4984-11", label: "Florist"}, 
    {value: "4985-11", label: "Fire Fighter"}, 
    {value: "4986-01", label: "Supervisor, Drillers"}, 
    {value: "4986-11", label: "Driller"}, 
    {value: "4987-01", label: "Supervisor, Chemical, Petroleum and Gas Plant Operators"}, 
    {value: "4987-11", label: "Chemical Plant Operator"}, 
    {value: "4987-13", label: "Petroleum and Gas Plant Operator"}, 
    {value: "4988-01", label: "Supervisor, Power Generation Plant Operators"}, 
    {value: "4988-11", label: "Power Generation Plant Operator"}, 
    {value: "4991-11", label: "Defence Force Member Not Elsewhere Included"}, 
    {value: "4991-81", label: "Trainee Defence Force Member Not Elsewhere Included"}, 
    {value: "4992-11", label: "Sound Technician"}, 
    {value: "4992-13", label: "Camera Operator (Film, Television or Video)"}, 
    {value: "4992-15", label: "Television Equipment Operator"}, 
    {value: "4992-17", label: "Broadcast Transmitter Operator"}, 
    {value: "4992-19", label: "Motion Picture Projectionist"}, 
    {value: "4992-21", label: "Light Technician"}, 
    {value: "4992-23", label: "Production Assistant (Film, Television or Radio)"}, 
    {value: "4992-25", label: "Production Assistant (Theatre)"}, 
    {value: "4992-27", label: "Make Up Artist"}, 
    {value: "4992-79", label: "Performing Arts Support Workers nec"}, 
    {value: "4999-11", label: "Optical Mechanic"}, 
    {value: "4999-13", label: "Diver"}, 
    {value: "4999-15", label: "Aircraft Safety Equipment Worker"}, 
    {value: "4999-17", label: "Piano Tuner"}, 
    {value: "4999-79", label: "Tradespersons and Related Workers nec"}, 
    {value: "5111-11", label: "Secretary"}, 
    {value: "5111-13", label: "Personal Assistant"}, 
    {value: "5911-11", label: "Bookkeeper"}, 
    {value: "5912-11", label: "Credit and Loans Officer"}, 
    {value: "5991-11", label: "Clerk of Court"}, 
    {value: "5991-13", label: "Law Clerk"}, 
    {value: "5991-15", label: "Trust Officer"}, 
    {value: "5992-11", label: "Court or Hansard Reporter"}, 
    {value: "5993-11", label: "Insurance Agent"}, 
    {value: "5994-11", label: "Insurance Risk Surveyor"}, 
    {value: "5994-13", label: "Insurance Investigator"}, 
    {value: "5994-15", label: "Insurance Loss Adjuster"}, 
    {value: "5995-11", label: "Desktop Publishing Operator"}, 
    {value: "5996-11", label: "Flight Service Director"}, 
    {value: "5996-13", label: "Flight Attendant"}, 
    {value: "5996-79", label: "Travel Attendants nec"}, 
    {value: "5999-11", label: "Radio Despatcher"}, 
    {value: "5999-13", label: "Auctioneer"}, 
    {value: "5999-15", label: "Visual Merchandiser"}, 
    {value: "5999-17", label: "Photographers Assistant"}, 
    {value: "6111-11", label: "General Clerk"}, 
    {value: "6121-11", label: "Typist and Word Processing Operator"}, 
    {value: "6121-13", label: "Data Entry Operator"}, 
    {value: "6131-11", label: "Receptionist"}, 
    {value: "6141-01", label: "Supervisor, Accounting Clerks"}, 
    {value: "6141-11", label: "Accounts Clerk"}, 
    {value: "6141-13", label: "Credit Clerk"}, 
    {value: "6141-15", label: "Cost Clerk"}, 
    {value: "6142-11", label: "Payroll Clerk"}, 
    {value: "6143-01", label: "Supervisor, Bank Workers"}, 
    {value: "6143-11", label: "Bank Worker"}, 
    {value: "6144-01", label: "Supervisor, Insurance Clerks"}, 
    {value: "6144-11", label: "Insurance Clerk"}, 
    {value: "6145-01", label: "Supervisor, Money Market and Statistical Clerks"}, 
    {value: "6145-11", label: "Money Market Clerk"}, 
    {value: "6145-13", label: "Statistical Clerk"}, 
    {value: "6151-11", label: "Production Recording Clerk"}, 
    {value: "6152-01", label: "Supervisor, Transport and Despatching Clerks"}, 
    {value: "6152-11", label: "Receiving and Despatching Clerk"}, 
    {value: "6152-13", label: "Import-Export Clerk"}, 
    {value: "6153-01", label: "Supervisor, Stock and Purchasing Clerks"}, 
    {value: "6153-11", label: "Stock Clerk"}, 
    {value: "6153-13", label: "Purchasing Officer"}, 
    {value: "6153-15", label: "Order Clerk"}, 
    {value: "6153-17", label: "Sales Clerk"}, 
    {value: "6191-11", label: "Inquiry Clerk"}, 
    {value: "6191-13", label: "Admissions Clerk"}, 
    {value: "6192-11", label: "Library Assistant"}, 
    {value: "6193-11", label: "Personnel Records Clerk"}, 
    {value: "6193-13", label: "Employment Office Clerk"}, 
    {value: "6194-11", label: "Customs Inspector"}, 
    {value: "6194-13", label: "Taxation Inspector"}, 
    {value: "6194-15", label: "Social Security Assessor"}, 
    {value: "6194-17", label: "Motor Vehicle Licence Examiner"}, 
    {value: "6194-19", label: "Transport Operations Inspector"}, 
    {value: "6194-21", label: "Train Examiner"}, 
    {value: "6194-23", label: "Water Inspector"}, 
    {value: "6194-79", label: "Intermediate Inspectors and Examiners nec"}, 
    {value: "6199-11", label: "Proof Reader"}, 
    {value: "6199-13", label: "Debt Collector"}, 
    {value: "6199-15", label: "Mail Supervisor"}, 
    {value: "6199-17", label: "Court Orderly"}, 
    {value: "6199-19", label: "Bailiff or Sheriff"}, 
    {value: "6199-21", label: "Coding Clerk"}, 
    {value: "6199-79", label: "Intermediate Clerical Workers nec"}, 
    {value: "6211-11", label: "Sales Representative (Personal and Household Goods)"}, 
    {value: "6211-13", label: "Sales Representative (Business Services)"}, 
    {value: "6211-15", label: "Sales Representative (Builders and Plumbers Supplies)"}, 
    {value: "6211-17", label: "Sales Representative (Motor Vehicle Parts and Accessories)"}, 
    {value: "6211-79", label: "Sales Representatives nec"}, 
    {value: "6212-11", label: "Motor Vehicle and Caravan Salesperson"}, 
    {value: "6212-13", label: "Motor Vehicle Parts Interpreter"}, 
    {value: "6213-11", label: "Retail Supervisor"}, 
    {value: "6213-13", label: "Checkout Supervisor"}, 
    {value: "6311-11", label: "Pre-School Aide"}, 
    {value: "6311-13", label: "Integration Aide"}, 
    {value: "6311-15", label: "Teachers Aide"}, 
    {value: "6311-17", label: "Aboriginal and Torres Strait Islander Education Worker"}, 
    {value: "6312-11", label: "Child Care Worker"}, 
    {value: "6312-13", label: "Family Day Care Worker"}, 
    {value: "6312-15", label: "Nanny"}, 
    {value: "6313-11", label: "Hostel Parent"}, 
    {value: "6313-13", label: "Child or Youth Residential Care Assistant"}, 
    {value: "6313-15", label: "Refuge Worker"}, 
    {value: "6313-17", label: "Aged or Disabled Person Carer"}, 
    {value: "6313-19", label: "Therapy Aide"}, 
    {value: "6314-11", label: "Personal Care Assistant"}, 
    {value: "6314-13", label: "Nursing Assistant"}, 
    {value: "6321-11", label: "Hotel Service Supervisor"}, 
    {value: "6322-01", label: "Supervisor, Bar Attendants"}, 
    {value: "6322-11", label: "Bar Attendant"}, 
    {value: "6323-01", label: "Supervisor, Waiters"}, 
    {value: "6323-11", label: "General Waiter"}, 
    {value: "6323-13", label: "Drink Waiter"}, 
    {value: "6324-11", label: "Hospitality Trainee"}, 
    {value: "6391-11", label: "Dental Assistant"}, 
    {value: "6392-11", label: "Veterinary Nurse"}, 
    {value: "6393-11", label: "Prison Officer"}, 
    {value: "6394-11", label: "Gaming Pit Boss"}, 
    {value: "6394-13", label: "Gaming Table Supervisor"}, 
    {value: "6394-15", label: "Gaming Dealer"}, 
    {value: "6395-11", label: "Beauty Therapist"}, 
    {value: "6395-13", label: "Natural Remedy Consultant"}, 
    {value: "6395-15", label: "Weight Loss Consultant"}, 
    {value: "6396-11", label: "Fitness Instructor"}, 
    {value: "6396-13", label: "Outdoor Adventure Leader"}, 
    {value: "6397-11", label: "Travel Agent"}, 
    {value: "6397-13", label: "Tourist Information Officer"}, 
    {value: "6397-15", label: "Tour Guide"}, 
    {value: "6399-11", label: "Museum or Gallery Attendant"}, 
    {value: "6399-13", label: "Driving Instructor"}, 
    {value: "6399-15", label: "Animal Attendant"}, 
    {value: "6399-17", label: "Pest and Weed Controller"}, 
    {value: "6399-79", label: "Intermediate Service Workers nec"}, 
    {value: "7111-11", label: "General Construction Plant Operator"}, 
    {value: "7111-13", label: "Bulldozer Operator"}, 
    {value: "7111-15", label: "Backhoe Operator"}, 
    {value: "7111-17", label: "Loader Operator"}, 
    {value: "7111-19", label: "Grader Operator"}, 
    {value: "7111-21", label: "Excavator Operator"}, 
    {value: "7111-23", label: "Paving Plant Operator"}, 
    {value: "7111-25", label: "Road Roller Operator"}, 
    {value: "7112-11", label: "Forklift Driver"}, 
    {value: "7119-11", label: "Agricultural and Horticultural Mobile Plant Operator"}, 
    {value: "7119-13", label: "Railway Track Repair Mobile Plant Operator"}, 
    {value: "7119-15", label: "Streetsweeper Operator"}, 
    {value: "7119-17", label: "Logging Plant Operator"}, 
    {value: "7119-79", label: "Mobile Plant Operators nec"}, 
    {value: "7121-11", label: "Engine or Boiler Operator"}, 
    {value: "7122-11", label: "Crane, Hoist or Lift Operator"}, 
    {value: "7123-01", label: "Supervisor, Engineering Production Systems Workers"}, 
    {value: "7123-11", label: "Engineering Production Systems Worker"}, 
    {value: "7124-11", label: "Pulp Mill Operator"}, 
    {value: "7124-13", label: "Paper Mill Operator"}, 
    {value: "7129-01", label: "Supervisor, Other Intermediate Stationary Plant Operators"}, 
    {value: "7129-11", label: "Railway Signal Operator"}, 
    {value: "7129-13", label: "Train Controller"}, 
    {value: "7129-15", label: "Concrete Pump Operator"}, 
    {value: "7129-17", label: "Cement Production Plant Operator"}, 
    {value: "7129-19", label: "Concrete Batching Plant Operator"}, 
    {value: "7129-21", label: "Water and Waste Water Plant Operator"}, 
    {value: "7129-23", label: "Bulk Materials Handling Plant Operator"}, 
    {value: "7129-79", label: "Intermediate Stationary Plant Operators nec"}, 
    {value: "7211-01", label: "Supervisor, Sewing Machinists"}, 
    {value: "7211-11", label: "Sewing Machinist"}, 
    {value: "7212-01", label: "Supervisor, Textile and Footwear Production Machine Operators"}, 
    {value: "7212-11", label: "Yarn Carding and Spinning Machine Operator"}, 
    {value: "7212-13", label: "Weaving Machine Operator"}, 
    {value: "7212-15", label: "Knitting Machine Operator"}, 
    {value: "7212-17", label: "Textile Dyeing and Finishing Machine Operator"}, 
    {value: "7212-19", label: "Hide and Skin Processing Machine Operator"}, 
    {value: "7212-21", label: "Footwear Production Machine Operator"}, 
    {value: "7212-79", label: "Textile and Footwear Production Machine Operators nec"}, 
    {value: "7291-01", label: "Supervisor, Plastics Production Machine Operators"}, 
    {value: "7291-11", label: "General Plastics Production Machine Operator"}, 
    {value: "7291-13", label: "Plastics Compounding and Reclamation Machine Operator"}, 
    {value: "7291-15", label: "Reinforced Plastic and Composite Production Worker"}, 
    {value: "7291-17", label: "Plastic Cablemaking Machine Operator"}, 
    {value: "7291-79", label: "Plastics Production Machine Operators nec"}, 
    {value: "7292-01", label: "Supervisor, Rubber Production Machine Operators"}, 
    {value: "7292-11", label: "Tyre Production Machine Operator"}, 
    {value: "7292-79", label: "Rubber Production Machine Operators nec"}, 
    {value: "7293-01", label: "Supervisor, Chemical Production Machine Operators"}, 
    {value: "7293-11", label: "Chemical Production Machine Operator"}, 
    {value: "7294-01", label: "Supervisor, Wood Processing Machine Operators"}, 
    {value: "7294-11", label: "Wood Processing Machine Operator"}, 
    {value: "7295-11", label: "Paper Products Machine Operator"}, 
    {value: "7296-01", label: "Supervisor, Glass Production Machine Operators"}, 
    {value: "7296-11", label: "Glass Production Machine Operator"}, 
    {value: "7297-01", label: "Supervisor, Clay, Stone and Concrete Processing Machine Operators"}, 
    {value: "7297-11", label: "Clay Products Machine Operator"}, 
    {value: "7297-13", label: "Stone Processing Machine Operator"}, 
    {value: "7297-15", label: "Concrete Products Machine Operator"}, 
    {value: "7297-79", label: "Clay, Stone and Concrete Processing Machine Operators nec"}, 
    {value: "7298-11", label: "Photographic Developer and Printer"}, 
    {value: "7299-11", label: "Industrial Spray Painter"}, 
    {value: "7299-79", label: "Intermediate Machine Operators nec"}, 
    {value: "7311-11", label: "Heavy Truck Driver"}, 
    {value: "7311-13", label: "Furniture Removalist"}, 
    {value: "7312-11", label: "Bus Driver"}, 
    {value: "7312-13", label: "Tram Driver"}, 
    {value: "7313-11", label: "Automobile Driver"}, 
    {value: "7314-11", label: "Delivery Driver"}, 
    {value: "7315-11", label: "Train Driver"}, 
    {value: "7315-13", label: "Train Drivers Assistant"}, 
    {value: "7911-11", label: "Miner"}, 
    {value: "7912-11", label: "Blasting Worker"}, 
    {value: "7913-11", label: "Scaffolder"}, 
    {value: "7913-13", label: "Steel Fixer"}, 
    {value: "7913-15", label: "Structural Steel Erector"}, 
    {value: "7913-17", label: "Construction Rigger"}, 
    {value: "7914-11", label: "Building Insulation Installer"}, 
    {value: "7914-13", label: "Home Improvements Installer"}, 
    {value: "7991-11", label: "Motor Vehicle Parts and Accessories Fitter"}, 
    {value: "7992-11", label: "Product Examiner"}, 
    {value: "7992-13", label: "Product Grader"}, 
    {value: "7992-15", label: "Product Tester"}, 
    {value: "7993-01", label: "Supervisor, Storepersons"}, 
    {value: "7993-11", label: "Storeperson"}, 
    {value: "7994-11", label: "Seafarer"}, 
    {value: "7994-13", label: "Fishing Hand"}, 
    {value: "7995-01", label: "Supervisor, Forestry and Logging Workers"}, 
    {value: "7995-11", label: "Tree Faller"}, 
    {value: "7995-13", label: "Forestry Worker"}, 
    {value: "7995-15", label: "Logging Assistant"}, 
    {value: "7996-11", label: "Printing Table Hand"}, 
    {value: "7996-13", label: "Printers Assistant"}, 
    {value: "8111-11", label: "Registry or Filing Clerk"}, 
    {value: "8112-11", label: "Mail Clerk"}, 
    {value: "8112-13", label: "Postal Sorting Officer"}, 
    {value: "8113-11", label: "Switchboard Operator"}, 
    {value: "8114-11", label: "Courier"}, 
    {value: "8114-13", label: "Postal Delivery Officer"}, 
    {value: "8115-11", label: "Betting Agency Counter Clerk"}, 
    {value: "8115-13", label: "Telephone Betting Clerk"}, 
    {value: "8115-15", label: "Bookmakers Clerk"}, 
    {value: "8115-79", label: "Betting Clerks nec"}, 
    {value: "8116-11", label: "Office Trainee"}, 
    {value: "8119-11", label: "Meter Reader"}, 
    {value: "8119-13", label: "Classified Advertising Clerk"}, 
    {value: "8119-15", label: "Interviewer"}, 
    {value: "8119-17", label: "Parking Inspector"}, 
    {value: "8119-79", label: "Elementary Clerks nec"}, 
    {value: "8211-11", label: "Sales Assistant (Food and Drink Products)"}, 
    {value: "8211-13", label: "Sales Assistant (Fabric, Clothing and Footwear)"}, 
    {value: "8211-15", label: "Sales Assistant (Other Personal and Household Goods)"}, 
    {value: "8211-17", label: "Sales Assistant (Postal Services)"}, 
    {value: "8211-79", label: "Sales Assistants nec"}, 
    {value: "8291-11", label: "Checkout Operator"}, 
    {value: "8291-13", label: "Office Cashier"}, 
    {value: "8292-11", label: "Ticket Seller"}, 
    {value: "8292-13", label: "Transport Conductor"}, 
    {value: "8293-11", label: "Street Vendor"}, 
    {value: "8293-13", label: "Cash Van Salesperson"}, 
    {value: "8293-15", label: "Door-to-Door Salesperson"}, 
    {value: "8294-11", label: "Telemarketer"}, 
    {value: "8295-11", label: "Sales Demonstrator"}, 
    {value: "8295-13", label: "Model"}, 
    {value: "8296-11", label: "Service Station Attendant"}, 
    {value: "8297-11", label: "Sales and Service Trainee"}, 
    {value: "8299-11", label: "Rental Salesperson"}, 
    {value: "8299-13", label: "Materials Recycler"}, 
    {value: "8299-79", label: "Elementary Sales Workers nec"}, 
    {value: "8311-11", label: "Security Officer"}, 
    {value: "8311-13", label: "Armoured Car Escort"}, 
    {value: "8311-79", label: "Guards and Security Officers nec"}, 
    {value: "8312-11", label: "Ticket Collector or Usher"}, 
    {value: "8312-13", label: "Luggage Porter or Doorperson"}, 
    {value: "8313-11", label: "Domestic Housekeeper"}, 
    {value: "8314-11", label: "Caretaker"}, 
    {value: "8315-11", label: "Laundry Worker"}, 
    {value: "8319-11", label: "Railways Assistant"}, 
    {value: "8319-13", label: "Hair and Beauty Salon Assistant"}, 
    {value: "8319-15", label: "Sterilisation Technology Worker"}, 
    {value: "8319-17", label: "Car Park Attendant"}, 
    {value: "8319-19", label: "Crossing Supervisor"}, 
    {value: "8319-21", label: "Trolley Collector"}, 
    {value: "8319-23", label: "Leaflet and Newspaper Deliverer"}, 
    {value: "8319-25", label: "Examination Supervisor"}, 
    {value: "8319-27", label: "Prostitute or Escort"}, 
    {value: "8319-79", label: "Elementary Service Workers nec"}, 
    {value: "9111-11", label: "Commercial Cleaner"}, 
    {value: "9111-15", label: "Carpet Cleaner"}, 
    {value: "9111-17", label: "Vehicle Cleaner"}, 
    {value: "9111-19", label: "Window Cleaner"}, 
    {value: "9111-79", label: "Cleaners nec"}, 
    {value: "9111-13", label: "Domestic Cleaner"}, 
    {value: "9211-11", label: "Engineering Production Process Worker"}, 
    {value: "9212-11", label: "Product Assembler"}, 
    {value: "9213-11", label: "Meatworks Labourer"}, 
    {value: "9213-13", label: "Meat Boner and Slicer"}, 
    {value: "9213-15", label: "Poultry Process Worker"}, 
    {value: "9213-17", label: "Fish Process Worker"}, 
    {value: "9214-11", label: "Food and Drink Processing Machine Attendant"}, 
    {value: "9214-13", label: "Baking Factory Hand"}, 
    {value: "9214-15", label: "Dairy Factory Hand"}, 
    {value: "9214-17", label: "Fruit and Vegetable Factory Hand"}, 
    {value: "9214-19", label: "Food Products Millhand"}, 
    {value: "9214-79", label: "Food Factory Hands nec"}, 
    {value: "9215-11", label: "Sawmill Labourer"}, 
    {value: "9215-13", label: "Timberyard Labourer"}, 
    {value: "9215-15", label: "Wood and Wood Products Factory Hand"}, 
    {value: "9215-17", label: "Pulp and Paper Mill General Hand"}, 
    {value: "9219-11", label: "Cement and Concrete Plant Labourer"}, 
    {value: "9219-13", label: "Clay Processing Factory Hand"}, 
    {value: "9219-15", label: "Chemical Plant Labourer"}, 
    {value: "9219-17", label: "Rubber and Plastics Factory Hand"}, 
    {value: "9219-19", label: "Hide and Skin Processing Labourer"}, 
    {value: "9219-21", label: "Glass Processing Labourer"}, 
    {value: "9219-23", label: "Fabric and Textile Factory Hand"}, 
    {value: "9219-25", label: "Footwear Factory Hand"}, 
    {value: "9219-79", label: "Process Workers nec"}, 
    {value: "9221-11", label: "Hand Packer"}, 
    {value: "9222-11", label: "Packager and Container Filler"}, 
    {value: "9911-11", label: "Mining Support Worker"}, 
    {value: "9911-13", label: "Drillers Assistant"}, 
    {value: "9912-11", label: "Earthmoving Labourer"}, 
    {value: "9913-11", label: "Paving and Surfacing Labourer"}, 
    {value: "9914-11", label: "Survey Hand"}, 
    {value: "9915-11", label: "Railway Labourer"}, 
    {value: "9916-11", label: "Construction Assistant"}, 
    {value: "9916-13", label: "Plumbers Assistant"}, 
    {value: "9917-11", label: "Concreter"}, 
    {value: "9918-11", label: "Electrical or Telecommunications Trades Assistant"}, 
    {value: "9919-11", label: "Lagger"}, 
    {value: "9919-13", label: "Crane Chaser"}, 
    {value: "9919-15", label: "Fence Erector"}, 
    {value: "9921-11", label: "General Farm Hand"}, 
    {value: "9921-13", label: "Fruit, Vegetable or Nut Farm Hand"}, 
    {value: "9921-15", label: "Stud Hand or Stable Hand"}, 
    {value: "9921-17", label: "Shearing Shed Hand"}, 
    {value: "9921-79", label: "Farm Hands nec"}, 
    {value: "9922-11", label: "Horticultural Nursery Assistant"}, 
    {value: "9922-13", label: "Garden Labourer"}, 
    {value: "9929-11", label: "Shooter-Trapper"}, 
    {value: "9929-13", label: "Rural Trainee"}, 
    {value: "9929-79", label: "Agricultural and Horticultural Labourers nec"}, 
    {value: "9931-11", label: "Kitchenhand"}, 
    {value: "9932-11", label: "Fast Food Cook"}, 
    {value: "9933-11", label: "Pastrycooks Assistant"}, 
    {value: "9933-79", label: "Food Trades Assistants nec"}, 
    {value: "9991-11", label: "Garbage Collector"}, 
    {value: "9992-11", label: "Freight Handler (Road and Rail)"}, 
    {value: "9992-13", label: "Waterside Worker"}, 
    {value: "9992-15", label: "Truck Drivers Offsider"}, 
    {value: "9993-11", label: "Handyperson"}, 
    {value: "9999-11", label: "Vending Machine Attendant"}, 
    {value: "9999-13", label: "Sign Erector"}, 
    {value: "9999-15", label: "Mechanics Assistant"}, 
    {value: "9999-17", label: "Stormwater, Drainage and Sewerage System Labourer"}, 
    {value: "9999-79", label: "Labourers and Related Workers nec"}
]