
import {VeiOption} from './applicationUpdateVei'

export type SelectOption = {
    label:string,
    value:string,
    disabled : Boolean
}

export const convertToSelectOptions = <T>(list : T[] | null, defaultValue:string, getName:Function, getValue:Function, defaultDisabled:boolean=true):SelectOption[] => {
    const defaultOption ={
        label: 'Please select',
        value: defaultValue,
        disabled: defaultDisabled
    };

    if(!list)
        return [defaultOption];

    var selectOptions = list.map(l => {
        return {
            label: getName(l),
            value: getValue(l),
            disabled: false
        }});

    return [defaultOption, ...selectOptions];
}

export const convertToVeiSelectOptions = (veioptions : VeiOption[], defaultValue:string, getName:Function, getValue:Function, defaultDisabled:boolean=true):SelectOption[] =>
    convertToSelectOptions(sortVeiOptionsByBenefitAmountDesc(veioptions), defaultValue, getName, getValue, defaultDisabled);

export const filterEligibleVeiOptions = (veiTypes: VeiOption[], maxGap: Number) => {
    const shouldReturnVeiOptions = maxGap > 0;
    if (!shouldReturnVeiOptions)
        return [];

    var lowerEligibleOption = veiTypes
    .filter(v => v.shortfallBenefitAmount <= maxGap)

    var higherOptionsAsc = veiTypes
        .filter(v => v.shortfallBenefitAmount > maxGap)
        .sort(sortByBenefitAmountAsc);

    if (higherOptionsAsc.length > 0) {
        return [higherOptionsAsc[0], ...lowerEligibleOption];
    }

    return lowerEligibleOption;
}

export const sortVeiOptionsByBenefitAmountDesc = (veioptions:VeiOption[]) => veioptions.sort(sortByBenefitAmountDesc);

export const convertToApplicationUpdateVeiOptions = (veiTypes:VeiOption[]) => {
    var defaultValue = "";
    const optionLabel = (option:VeiOption) => option.productName;
    const optionValue = (option:VeiOption) => option.productNumber;

   return convertToVeiSelectOptions(veiTypes, defaultValue, optionLabel, optionValue)
}

export const convertToMotorPrequalVeiOptions = (veiTypes:VeiOption[]) => {
    var defaultValue = "0";
    const optionLabel = (option:VeiOption) => option.productName;
    const optionValue = (option:VeiOption) => option.premiumAmount;

    var sortedVeioptions = sortVeiOptionsByBenefitAmountDesc(veiTypes);
   return convertToSelectOptions(sortedVeioptions, defaultValue, optionLabel, optionValue)
}

export const convertToLfiVeiOptions = (veiTypes:VeiOption[]) => {
    var defaultValue = "-1";
    const optionLabel = (option:VeiOption) => option.productName;
    const optionValue = (option:VeiOption) => option.premiumAmount;

    var sortedVeioptions = sortVeiOptionsByBenefitAmountDesc(veiTypes);
    return convertToSelectOptions(sortedVeioptions, defaultValue, optionLabel, optionValue, false)
}

const sortByBenefitAmountAsc = (a:VeiOption, b:VeiOption) => a.shortfallBenefitAmount < b.shortfallBenefitAmount ? -1 : 1;
const sortByBenefitAmountDesc = (a:VeiOption, b:VeiOption) => a.shortfallBenefitAmount > b.shortfallBenefitAmount ? -1 : 1;
