import { EmploymentHistoryActionTypes, EmploymentHistoryAction, EmploymentType } from './actions'
import { Guid } from 'guid-typescript';
import * as Moment from 'moment';

export interface EmploymentRowState {
  id: Guid,
  showEndDate: boolean,
  endDateCanBeInFuture: boolean,
  isCurrent: boolean,
  isContract: boolean,
  type: string,
  endDate: string
  years?: number,
  months?: number,
}

interface EmploymentHistoryState {
  employmentRows: EmploymentRowState[],
  showRemoveButton: boolean,
}

export const initialState: EmploymentHistoryState = {
  employmentRows: [{
    id: Guid.create(),
    showEndDate: false,
    endDateCanBeInFuture: false,
    isCurrent: true,
    isContract: false,
    endDate: '',
    type: ''
  }],
  showRemoveButton: false
}

const shouldShowRemoveButton = (employments: EmploymentRowState[]) => employments.length > 1;
const isEmploymentContractType = (employmentType?: EmploymentType) => employmentType && employmentType == "Contract Full Time" || employmentType == "Contract Part Time";
const shouldShowEndDate = (isCurrent: boolean, isContract: boolean) => isContract || !isCurrent;
const endDateCanBeInFuture = (isCurrent: boolean, isContract: boolean) => isCurrent && isContract;

const createEmploymentRow = (employment?: any) => 
  {
    let isCurrent = employment?.isCurrent ?? true;
    let isContract = isEmploymentContractType(employment?.type);
    let showEndDate = shouldShowEndDate(isCurrent, isContract);
    let endDateInFuture = endDateCanBeInFuture(isCurrent, isContract);

    return ({
        id: Guid.create(),
        showEndDate: showEndDate, 
        endDateCanBeInFuture: endDateInFuture,
        isContract: isContract,
        isCurrent: isCurrent,
        years: employment?.years,
        months: employment?.months,
        type: employment?.type || '',
        endDate: employment?.endDate ? Moment(employment.endDate).format('MM/YYYY') : ''
      });
  }

export const employmentHistoryReducer = (state: EmploymentHistoryState = initialState, action: EmploymentHistoryAction): EmploymentHistoryState => {
  switch (action.type) {
    case EmploymentHistoryActionTypes.LoadEmployment:
      const employmentRows = action.employment?.map(e => createEmploymentRow(e)) || [createEmploymentRow()]
      return { ...state, employmentRows, showRemoveButton: employmentRows.length > 1  }

    case EmploymentHistoryActionTypes.AddEmployment:
      const employmentsWithAddedEmployment = [
        ...state.employmentRows,
        createEmploymentRow()
      ];
      return {
        ...state,
        employmentRows: employmentsWithAddedEmployment,
        showRemoveButton: shouldShowRemoveButton(employmentsWithAddedEmployment)
      }
    case EmploymentHistoryActionTypes.RemoveEmployment:
      const filteredEmployments = state.employmentRows.filter(employment => employment.id != action.id)
      return {
        ...state,
        employmentRows: filteredEmployments,
        showRemoveButton: shouldShowRemoveButton(filteredEmployments)
      }
    case EmploymentHistoryActionTypes.ChangeEmploymentType:
      const isContract = isEmploymentContractType(action.employmentType);
      return {
        ...state,
        employmentRows: state.employmentRows.map(e =>
          e.id == action.id
            ? { ...e, isContract: isContract, showEndDate: shouldShowEndDate(e.isCurrent, isContract), endDateCanBeInFuture: endDateCanBeInFuture(e.isCurrent, isContract) }
            : e
        )
      }
    case EmploymentHistoryActionTypes.SetEmploymentToCurrent:
      return {
        ...state,
        employmentRows: state.employmentRows.map(e =>
          e.id == action.id
            ? { ...e, isCurrent: true, showEndDate: shouldShowEndDate(true, e.isContract), endDateCanBeInFuture: endDateCanBeInFuture(true, e.isContract) }
            : e
        )
      }
    case EmploymentHistoryActionTypes.SetEmploymentToNotCurrent:
      return {
        ...state,
        employmentRows: state.employmentRows.map(e =>
          e.id == action.id
            ? { ...e, isCurrent: false, showEndDate: shouldShowEndDate(false, e.isContract), endDateCanBeInFuture: endDateCanBeInFuture(false, e.isContract) }
            : e
        )
      }
    default:
      return state
  }
}

