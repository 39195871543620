import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { initialiseForm } from './actions';
import { SubmissionStatus } from './reducer';

interface MessageProps {
    status: SubmissionStatus,    
    initialiseForm: Function
  }

export class LfiProtectPrequalMessage extends React.Component<MessageProps> {
  constructor(props: MessageProps) {
      super(props, {});	

      this.state = {
        status: props.status
      }

      this.handleAdd = this.handleAdd.bind(this);			
  }	

  handleAdd(){
    this.props.initialiseForm()
  }

  render() {
    return (
      <div id="submit-message" className="panel-section">
        <div className="panel-section-body panel-section-body-tint">
          <div className="panel-section-container text-center">
            <div className="text-center" style={{ margin: '25px 0' }}>
              <h4>LFI Protect deferral sale lead has been successfully submitted.</h4>
              <h4>Please check your email for further details.</h4>
            </div>
            <div className="text-center" style={{ margin: '35px 0' }}>							
              <button onClick={this.handleAdd} className="btn btn-secondary">Add Another One</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ lfiProtectPrequal }) {
    return {
      status: lfiProtectPrequal.status,
      errorMessage: lfiProtectPrequal.errorMessage
    };
  }

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ initialiseForm }, dispatch)
    };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(LfiProtectPrequalMessage);

  
