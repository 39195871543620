import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router'

import {Row, Col} from 'react-bootstrap';

import * as Moment from 'moment';

import {notify as Notify} from 'react-notify-toast';

import * as Guid from 'guid';

import * as errors from '../../constants/errors';

import Progress from './progress';
import Applicants from './applicants';
import Incomes from './incomes';
import Assets from './assets';
import Liabilities from './liabilities';
import Purpose from './purpose';
import Structure from './structure';
import Declaration from './declaration';
import Intro from './intro';
import Submit from './submit';
import Notes from './notes';

import {Overlay} from '../../utils';

import {IDataLabel, IApplication, IPartialApplication, IFormData, IGuid} from './interfaces';
import * as actions from './actions';

import {ISubmissionState, ILoadApplication, ISaveApplication} from './reducer';
import Event3rdParty from '../../helpers/event3rdParty';

type IDebug = "debug";

interface IApplicantSubmitPageActions {
  loadApplication: (id: IGuid) => Promise<any>;
  initApplication: () => void;
  resetApplication: () => void;
  saveApplication: (data: IPartialApplication, callback) => Promise<any>;
  submitApplication: (data: IApplication) => Promise<any>;
  resetSubmit: () => void;
  updateApplicationNotes: (text: string) => void;
  toggleApplicationNotes: () => void;
}

interface IApplicationSubmitPageProps {
  actions: IApplicantSubmitPageActions;
  submission: ISubmissionState;
  router: any;
  params: {
    id: IGuid | IDebug;
  };
}

interface IApplicationSubmitPageState {
  currentStep: number;
  renderStep: number;
  loading: string | boolean;
  showIntro: boolean;
  submit: boolean;
}

function shouldShowLoading(load: ILoadApplication): boolean {
  return load.status === "loading";
}
function shouldShowSaving(save: ISaveApplication): boolean {
  return save.status === "saving";
}
function shouldShowSubmission(submission: ISubmissionState): boolean {
  return submission.load.status === "loaded";
}
function shouldShowApplicants(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && renderStep >= 0;
}
function shouldShowIncomes(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.applicants && renderStep >= 1;
}
function shouldShowAssets(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.incomes && renderStep >= 2;
}
function shouldShowLiabilities(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.assets && renderStep >= 3;
}
function shouldShowPurpose(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.liabilities && renderStep >= 4;
}
function shouldShowStructure(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.purpose && renderStep >= 5;
}
function shouldShowDeclaration(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowSubmission(submission) && submission.data.structure && renderStep >= 6;
}
function shouldShowSubmit(submission: ISubmissionState, renderStep: number): boolean {
  return shouldShowDeclaration(submission, renderStep);
}

function shouldLoadApplication(params): boolean {
  return params.id && params.id !== "debug";
}

function shouldShowIntro(state: IApplicationSubmitPageState) {
  return state.showIntro;
}

function mergePartialApplication(application: IPartialApplication, data: IPartialApplication, label: IDataLabel, notes: string) {
  return {
    ...application,
    notes,
    [label]: data
  }
}

export class ApplicationSubmitPage extends React.Component<IApplicationSubmitPageProps, IApplicationSubmitPageState> {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      renderStep: 1,
      loading: false,
      showIntro: true,
      submit: false
    };

    this.changeStep = this.changeStep.bind(this);
    this.toggleSubmit = this.toggleSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.retrySubmit = this.retrySubmit.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (shouldLoadApplication(this.props.params)) {
      this.props.actions.loadApplication(this.props.params.id);
      this.setState({
        showIntro: false
      })
      Event3rdParty.send(`loaded-application`);
    }
    else {
      this.props.actions.initApplication();
      Event3rdParty.send(`started-new-application`);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetApplication();
  }

  savePartialApplication = (data: IFormData, label: IDataLabel) => {
    this.props.actions.saveApplication(
      mergePartialApplication(this.props.submission.data, data, label, this.props.submission.notes.text),
      this.processStep.bind(this, label)
    );

    Event3rdParty.send(`saved-application`);
  }

  processStep(label: IDataLabel) {
    if (label == 'declaration') {
      this.toggleSubmit();
    } else if (label == 'submit') {
      this.handleSubmit();
    } else {
      this.nextStep();
    }
  }

  handleSubmit() {
    let {data, notes} = this.props.submission;

    let application = {
      ...data,
      submissionId: Guid.create().value,
      notes: notes.text
    }

    let interval = setInterval(() => {
      this.setLoading(this.randomLoadingMessage())
    }, 2000);

    this.props.actions.submitApplication(application)
      .then(() => {
        clearInterval(interval);
        this.setLoading(false);

        if (this.props.submission.submit.status === "submitted") {
          Event3rdParty.send(`submitted-application`);

          this.props.actions.saveApplication(
            {
              ...this.props.submission.data,
              submitted: Moment().format()
            },
            this.redirectApplication(this.props.submission.submit.applicationNumber)
          );
        }
      });
  }

  resetSubmit() {
    this.props.actions.resetSubmit();
  }

  retrySubmit() {
    this.handleSubmit();
  }

  redirectApplication(applicationNumber: string) {
    setTimeout(() => {
      this.props.router.push(`/applications/${applicationNumber}`)
    }, 10 * 1000);
  }

  setLoading(message: string | boolean) {
    this.setState({
      loading: message
    })
  }

  nextStep() {
    this.changeStep(this.state.currentStep + 1, true);
  }

  previousStep() {
    this.changeStep(this.state.currentStep - 1, true);
  }

  changeStep(newStep: number, forceStep: boolean = false) {
    if (newStep < this.state.currentStep || forceStep) {
      this.setState({
        currentStep: newStep,
        renderStep: Math.max(this.state.renderStep, newStep)
      });

      window.scrollTo(0, 0);
    }
  }

  toggleSubmit() {
    this.setState({submit: !this.state.submit});
  }

  notifyStep(message = errors.ERR_VALIDATION) {
    Notify.show(message, 'error', 3000);
  }

  randomLoadingMessage(): string {
    let messages = [
      "Loading",
      "Processing",
      "Submitting",
      "Crunching data",
      "Working away",
      "00101110 00101110 00101110",
      "It not you, it's me",
      "Twiddling thumbs",
      "Dividing by zero, yikes",
      "It must be almost done",
      "At least you're not on hold",
      "It's worth the wait",
      "Mining some coins"
    ];

    return messages[Math.floor(Math.random() * messages.length)];
  }

  get steps(): any[] {
    return [{
      id: 0,
      name: 'Applicants',
      visible: true
    }, {
      id: 1,
      name: 'Incomes',
      visible: true
    }, {
      id: 2,
      name: 'Assets',
      visible: true
    }, {
      id: 3,
      name: 'Liabilities',
      visible: true
    }, {
      id: 4,
      name: 'Purpose',
      visible: true
    }, {
      id: 5,
      name: 'Structure',
      visible: true
    }, {
      id: 6,
      name: 'Declaration',
      visible: true
    }];
  }

  get attributes(): any {
    return {
      saveStepData: this.savePartialApplication,
      notifyStep: this.notifyStep,
      nextStep: this.nextStep,
      previousStep: this.previousStep,
      data: this.props.submission.data,
      currentStep: this.state.currentStep
    }
  }

  render() {
    return (
      <div>
        <Progress steps={this.steps} currentStep={this.state.currentStep} changeStep={this.changeStep} />
        <Intro shouldShow={shouldShowIntro(this.state)} />

        <Row className="r">
          <Col className="main-panel" sm={12}>
            {shouldShowApplicants(this.props.submission, this.state.renderStep) && <Applicants {...this.attributes} />}
            {shouldShowIncomes(this.props.submission, this.state.renderStep) && <Incomes {...this.attributes} />}
            {shouldShowAssets(this.props.submission, this.state.renderStep) && <Assets {...this.attributes} />}
            {shouldShowLiabilities(this.props.submission, this.state.renderStep) && <Liabilities {...this.attributes} />}
            {shouldShowPurpose(this.props.submission, this.state.renderStep) && <Purpose {...this.attributes} />}
            {shouldShowStructure(this.props.submission, this.state.renderStep) && <Structure {...this.attributes} />}
            {shouldShowDeclaration(this.props.submission, this.state.renderStep) && <Declaration {...this.attributes} />}
            {shouldShowSubmit(this.props.submission, this.state.renderStep) && <Submit {...this.attributes} notes={this.props.submission.notes.text} changeNote={this.props.actions.updateApplicationNotes} visible={this.state.submit} loading={this.state.loading} resetSubmit={this.resetSubmit} retrySubmit={this.retrySubmit} hideModal={this.toggleSubmit} />}
          </Col>

          <Notes
            text={this.props.submission.notes.text}
            visible={this.props.submission.notes.visible}
            updateNotes={this.props.actions.updateApplicationNotes}
            toggleModal={this.props.actions.toggleApplicationNotes} />

          {shouldShowLoading(this.props.submission.load) && <Overlay>Loading application...</Overlay>}
          {shouldShowSaving(this.props.submission.save) && <Overlay>Saving application...</Overlay>}
        </Row>
      </div>
    );
  }
}

function mapStateToProps({submission}) {
  return {
    submission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...actions}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationSubmitPage));
