import * as React from 'react';
import {Form} from 'formsy-react-components';
import * as Moment from 'moment';

import * as Guid from 'guid';

import Header from '../header';
import Navigation from '../navigation';
import Add from '../add';

import Summary from './summary';
import Split from './split';
import Residency from './residency';

import {getProperties, calculateLoanAmount, calculateSecurityAmount, calculateLvrPercentage, getResidenceOptions} from './controller';

import {ILoanSplit, IBaseProps, IGuid} from '../interfaces';
import {shouldFormBeVisible, hasFormData, setFormData} from '../functions';
import {debug} from '../debug';
import AddButton from '../addButton';


interface IStructureState {
  loanSplits: ILoanSplit[]
}

interface IStructureProps extends IBaseProps {}

export class Structure extends React.Component<IStructureProps, IStructureState> {
  constructor(props) {
    super(props);

    this.state = {
      loanSplits: []
    };
  }

  UNSAFE_componentWillMount(){
    if (hasFormData(this.props.data, 'structure')) {
      this.props.data.structure.loanSplits.forEach(this.addLoanSplit.bind(this));
    } else {
      this.addLoanSplit();
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'structure')) {
      setFormData(this.props.data, 'structure', this.refs.form);
    }
  }

  addLoanSplit(): void {
    let loanSplits = this.state.loanSplits;

    loanSplits.push({
      id: Guid.create().value
    });

    this.setState({
      loanSplits
    });
  }

  removeLoanSplit(id: IGuid): void {
    const loanSplits = this.state.loanSplits.filter(loanSplit => loanSplit.id != id);
    this.setState({loanSplits});
  }

  render(): JSX.Element {
    const properties = getProperties(this.props.data.assets.specificAssets, this.props.data.purpose.purchases);

    const securityAmount = calculateSecurityAmount(properties);
    const loanAmount = calculateLoanAmount(this.props.data.purpose.purchases, this.props.data.purpose.uses, this.props.data.liabilities.liabilities);
    const lvrPercentage = calculateLvrPercentage(loanAmount, securityAmount);

    const residenceOptions = getResidenceOptions(properties, this.props.data.applicants.individuals);

    return (
      <Form
        onValidSubmit={data => this.props.saveStepData(data, 'structure')}
        onInvalidSubmit={errors => this.props.notifyStep()}
        className={shouldFormBeVisible(this.props.currentStep, 5)}
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">

            <Header title="Structure" required />

            <Summary
              loanSplits={this.state.loanSplits}
              lvr={lvrPercentage}
              loanAmount={loanAmount}
              securityAmount={securityAmount} />

            <div className="panel-section">
              <div className="panel-section-body panel-section-body-simple">
                <div className="panel-section-container panel-section-container-well">
                  {
                    this.state.loanSplits.map((loanSplit, index) => {
                      return (
                        <Split
                          key={loanSplit.id}
                          index={index}
                          loanSplit={loanSplit}
                          loanSplits={this.state.loanSplits}
                          loanAmount={loanAmount}
                          removeLoanSplit={this.removeLoanSplit.bind(this)} />
                      );
                    })
                  }

                  <div className="panel-section-btns">
                    <AddButton
                      glyph="storage"
                      label="Loan split"
                      onClick={this.addLoanSplit.bind(this)} />
                  </div>

                </div>
              </div>
            </div>

            <Residency applicants={this.props.data.applicants} properties={residenceOptions}  />
          </div>
        </div>

        <Navigation previousStep={this.props.previousStep} />
      </Form>
    );
  }
}

export default Structure;
