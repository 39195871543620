import initialState from './initialState';
import * as types from '../constants/types';

const objectAssign = require('object-assign');

export default function referenceReducer(state = initialState.reference, action) {
  switch (action.type) {
    case types.GET_LOAN_PURPOSES: {
      const loanPurposes = action.data.map(mapSelect);
      return objectAssign({}, state, { loanPurposes });
    }

    case types.GET_VERIFICATION_LEVELS: {
      const verificationLevels = action.data.map(mapSelect);
      return objectAssign({}, state, { verificationLevels });
    }

    case types.GET_LPI_TYPES: {
      const lpiTypes = addSelect(action.data.map(mapSelect));
      return objectAssign({}, state, { lpiTypes });
    }

    case types.GET_VEI_TYPES: {
      const veiTypes = action.data;
      return objectAssign({}, state, { veiTypes });
    }

    case types.GET_VEHICLE_YOY_DEPRECIATION: {
      const vehicleYearOnYearDepreciation = action.data;
      return objectAssign({}, state, { vehicleYearOnYearDepreciation });
    }

    case types.GET_VENDOR_TYPES: {
      const vendorTypes = action.data.map(mapSelect);
      return objectAssign({}, state, { vendorTypes });
    }

    case types.GET_NVIC: {      
      const nvics = [];
      
      nvics.push({
        groupHeader: `${action.data.result.yearOfManufacture} ${action.data.result.make} ${action.data.result.model}`,
        items: [{
          nvic: action.data.result.nvic,
          model: action.data.result.model,
          make: action.data.result.make,
          year: action.data.result.yearOfManufacture,
          body: action.data.result.bodyType ?? '',
          category: '',
          transmission: '',
          variant: '',        
          groupBy: `${action.data.result.yearOfManufacture} ${action.data.result.make} ${action.data.result.model}`,   
          registrationPlate: action.data.result.registrationPlate,
          registrationState: action.data.result.registrationState,
          registrationExpiryDate: action.data.result.registrationExpiryDate,
          colour: action.data.result.colour,
          engineNumber: action.data.result.engineNumber,
          vin: action.data.result.vin,
        }],
      });
      
      return objectAssign({}, state, { nvics, nvic: nvics[0].items[0] });
    }

    case types.GET_NVICS: {
      const nvics = [];
      let vehicles = [];

      action.data.value.map((glassGuideVehicle) => {
        vehicles.push({
          nvic: glassGuideVehicle.NVIC,
          model: glassGuideVehicle.FAMILY,
          make: glassGuideVehicle.MAKE,
          year: +glassGuideVehicle.YEAR,
          body: glassGuideVehicle.STYLE ?? '',
          category: mapGlassGuideCategory(glassGuideVehicle),
          transmission: glassGuideVehicle.TRANSMISSION,
          variant: glassGuideVehicle.VARIANT ?? '',
          groupBy: `${glassGuideVehicle.YEAR} ${glassGuideVehicle.MAKE} ${glassGuideVehicle.FAMILY}`,
          fuelType: glassGuideVehicle.FUEL_TYPE,
          isMotorbike: glassGuideVehicle.METADATA_STORAGE_NAME.includes('motorbike'), 
        });
      });
      vehicles.sort((a, b) => {
        if (a.year > b.year) return -1;
        if (a.year < b.year) return 1;
        if (a.variant > b.variant) return 1;
        if (a.variant < b.variant) return -1;
        return a.category > b.category ? 1 : -1;
      });
      vehicles = vehicles.reduce((acc, curr) => {
        if (!acc[curr.groupBy]) acc[curr.groupBy] = [];
        acc[curr.groupBy].push(curr);
        return acc;
      }, {});
      Object.entries(vehicles).map((groupedItem) => {
        nvics.push({
          groupHeader: groupedItem[0],
          items: groupedItem[1],
        });
      });
      return objectAssign({}, state, { nvics });
    }

    case types.SET_NVIC: {
      const nvic = action.data;
      return objectAssign({}, state, { nvic });
    }

    case types.CLEAR_NVIC: {
      return objectAssign({}, state, { nvic: undefined });
    }

    case types.CLEAR_APPRAISAL: {
      return objectAssign({}, state, { appraisal: undefined });
    }

    case types.GET_APPRAISAL: {
      const appraisal = action.data[0];
      return objectAssign({}, state, { appraisal });
    }

    case types.GET_YEARS: {
      const years = action.data.map(mapSelect);
      return objectAssign({}, state, { years });
    }

    case types.GET_MAKES: {
      const makes = addSelect(action.data.map(mapSelect));
      return objectAssign({}, state, { makes });
    }

    case types.GET_MODELS: {
      const models = addSelect(action.data.map(mapSelect));
      return objectAssign({}, state, { models });
    }

    case types.GET_BODY_TYPES: {
      const bodyTypes = addSelect(action.data.map(mapSelect));
      return objectAssign({}, state, { bodyTypes });
    }

    case types.GET_TRANSMISSIONS: {
      const transmissions = addSelect(action.data.map(mapSelect));
      return objectAssign({}, state, { transmissions });
    }

    case types.GET_CATEGORIES: {
      const categories = action.data.map(mapSelect);
      return objectAssign({}, state, { categories });
    }

    case types.GET_INSURERS: {
      return objectAssign({}, state, { insurers: action.data });
    }

    case types.GET_OTHER_INCOME_TYPES: 
    case types.GET_COMM_OTHER_INCOME_TYPES: {
      const otherIncomeGroups = [];
      const otherIncomeTypes = [];

      action.data.map((otherIncomeType) => {
        if (!otherIncomeGroups.filter((group) => group.name == otherIncomeType.incomeOtherGroupId).length) {
          otherIncomeTypes.push({
            value: otherIncomeType.incomeOtherTypeId,
            label: otherIncomeType.incomeOtherType,

            type: otherIncomeType.incomeOtherType,
            typeId: otherIncomeType.incomeOtherTypeId,
            group: otherIncomeType.incomeOtherGroup,
            groupId: otherIncomeType.incomeOtherGroupId,
          });
        }
      });

      return objectAssign({}, state, { otherIncomeTypes, otherIncomeGroups });
    }

    case types.GET_INSURANCE_PAYMENT_METHODS: {
      const insurancePaymentMethods = action.data.map(mapSelect);
      return objectAssign({}, state, { insurancePaymentMethods });
    }

    default: {
      return state;
    }
  }
}

const mapSelect = (x) => ({
  label: x.value,
  value: x.key,
});

const addSelect = (x) => {
  x.unshift({
    label: 'Please select',
    value: -1,
  });
  return x;
};

const mapGlassGuideCategory = (v) => {
  let vehicleCategory = '';

  vehicleCategory += !v.SERIES ? '' : `${v.SERIES} • `;
  vehicleCategory += !v.STYLE ? '' : `${v.STYLE} • `;
  vehicleCategory += !v.DRIVE ? '' : `${v.DRIVE} • `;
  vehicleCategory += !v.TRANSMISSION ? '' : v.TRANSMISSION;

  return vehicleCategory.trim();
};
