import * as React from 'react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';

import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';

import RemoveEmploymentButton from './removeEmploymentButton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeEmployment, changeEmploymentType, setEmploymentToNotCurrent, setEmploymentToCurrent } from './actions';

import Mask from '../../../helpers/mask';
import { Guid } from 'guid-typescript';

interface EmploymentRowProps {
  applicantIndex: number;
  index: number;
  id: Guid;
  showEndDate: boolean;
  endDateCanBeInFuture: boolean;
  showRemoveButton: boolean;
  isCurrent: boolean;
  years?: number;
  months?: number;
  type: string;
  endDate: string;
  actions: {
    removeEmployment: typeof removeEmployment,
    changeEmploymentType: typeof changeEmploymentType,
    setEmploymentToNotCurrent: typeof setEmploymentToNotCurrent,
    setEmploymentToCurrent: typeof setEmploymentToCurrent
  }
}

export class EmploymentRow extends React.Component<EmploymentRowProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { applicantIndex, index, id, showEndDate, endDateCanBeInFuture, showRemoveButton, actions } = this.props;
    const baseName = `applicants.${applicantIndex}.employments.${index}`;

    return (
      <Row className="panel-row r">
        <Col sm={3}>
          <Select
            id={`${baseName}.type`}
            name={`${baseName}.type`}
            ref={`${baseName}.type`}
            label="Type"
            value={this.props.type || ''}
            options={getEmploymentTypes()}
            changeCallback={(_, value) => { actions.changeEmploymentType(id, value) }}
            validations={{
                isRequired: true
            }}
            className="form-control"
          />
        </Col>
        <Col sm={4}>
          <div className="form-group">
            <label className="control-label">How long have you been working here?</label>
              <div>
                <Row>
                  <Col sm={3}>
                    <Input
                      id={`${baseName}.years`}
                      name={`${baseName}.years`}
                      ref={`${baseName}.years`}
                      type="text"
                      value={this.props.years}
                      updateOnBlur={false}
                      changeDebounceInterval={0}
                      blurCallback={(name, value) => { Mask.numberFormsyNoZero(name, value, this.refs) }}
                      validationError="Years must be a valid number"
                      validations={{
                        isFormattedNumber: true,
                        isValidYear: true
                      }}
                    />
                  </Col>
                  <Col sm={2} className="mt-sm">{' '}<span className="mute">Years</span></Col>
                  <Col sm={3}>
                    <Input
                      id={`${baseName}.months`}
                      name={`${baseName}.months`}
                      ref={`${baseName}.months`}
                      type="text"
                      value={this.props.months}
                      updateOnBlur={false}
                      changeDebounceInterval={0}
                      blurCallback={(name, value) => Mask.numberFormsyNoZero(name, value, this.refs)}
                      validationError="Months must be a valid number."
                      validations={{
                        isFormattedNumber: true,
                        isValidMonth: true
                      }}
                    />
                  </Col>
                  <Col className="mt-sm" sm={2}><span className="mute">Months</span></Col>
                </Row>
              </div>
          </div>
        </Col>
        <Col sm={2}>
          <div style={{ marginTop: '12px' }}>
            <Checkbox
              id={`${baseName}.isCurrent`}
              name={`${baseName}.isCurrent`}
              ref={`${baseName}.isCurrent`}
              valueLabel={<span>Current</span>}
              value={this.props.isCurrent}
              changeCallback={(_, value) => {
                value
                  ? actions.setEmploymentToCurrent(id)
                  : actions.setEmploymentToNotCurrent(id)
              }
              }
            />
          </div>
        </Col>

        {
          showEndDate &&
          <div className='endDate-wrapper'>
            <Col sm={2} >
              <Input
                name={`${baseName}.endDate`}
                id={`${baseName}.endDate`}
                ref={`${baseName}.endDate`}
                placeholder={`eg: ${Moment().add(6, 'M').format('MM/YYYY')}`}
                updateOnBlur={false}
                changeDebounceInterval={0}
                blurCallback={(name, value) => Mask.dateFormsy(name, value, this.refs)}
                label="End Date"
                value={this.props.endDate || ''}
                validationError={'End date must be valid'}
                validations={
                  endDateCanBeInFuture
                    ? { isValidFutureDate: true }
                    : { isValidPastDate: true }
                }
              />
            </Col>
          </div>
        }

        {
          showRemoveButton &&
          <RemoveEmploymentButton employmentId={id} />
        }
      </Row>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ removeEmployment, changeEmploymentType, setEmploymentToNotCurrent, setEmploymentToCurrent }, dispatch)
  };
}

export default connect(() => ({}), mapDispatchToProps)(EmploymentRow);

function getEmploymentTypes() {
  return [{
    value: '',
    label: 'Select'
  },
  {
    label: 'Permanent Full Time',
    value: 'Permanent Full Time'
  },
  {
    label: 'Permanent Part Time',
    value: 'Permanent Part Time'
  },
  {
    label: 'Contract Full Time',
    value: 'Contract Full Time'
  },
  {
    label: 'Contract Part Time',
    value: 'Contract Part Time'
  },
  {
    label: 'Casual',
    value: 'Casual'
  },
  {
    label: 'Seasonal',
    value: 'Seasonal'
  }];
}
