import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

const ApplicationBarIcon = (props) => {
  let glyph;

  switch (props.type) {
    case 5: glyph = 'city-alt'; break;
    case 2: glyph = 'directions-car'; break;
    case 1: glyph = 'home'; break;
    case 7: glyph = 'money-box'; break;
  }

  return (
    <Icon glyph={glyph} className="mr-sm progress-icon" />
  );
};

ApplicationBarIcon.propTypes = {
  type: PropTypes.number,
};

export default ApplicationBarIcon;
