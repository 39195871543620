import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { browserHistory } from 'react-router';

import applications from './applicationsReducer';
import contacts from './contactsReducer';
import documents from './documentsReducer';
import faqs from './faqsReducer';
import reference from './referenceReducer';
import session from './sessionReducer';
import prompts from './promptsReducer';
import events from './eventsReducer';
import storage from './storageReducer';
import calculators from './calculatorsReducer';
import { prequalReducer } from './prequalReducer';
import { motorDeclarationsReducer } from './motorDeclarationsReducer';

import submission from '../pages/applicationSubmitPage/reducer';
import { lvrReducer } from '../pages/lvrPage/reducer';
import { serviceAbilitySuggestionReducer } from '../pages/serviceAbilityPage/serviceAbilitySuggestion/reducer';
import { comServiceAbilitySuggestionReducer } from '../pages/comServiceAbilityPage/comServiceAbilitySuggestion/reducer';
import { motorPrequalReducer } from '../pages/motorPrequalToolPage/reducer';
import { employmentHistoryReducer } from '../pages/motorPrequalToolPage/employmentHistory/reducer';
import { motorApplicationReducer } from '../pages/applicationPage/reducer';
import { applicationDocumentsReducer } from '../pages/applicationPage/applicationDocuments/reducer';
import { idVerificationReducer } from '../pages/applicationPage/idVerification/reducer';
import { veiCalculatorReducer } from '../pages/veiCalculatorPage/reducer';
import { LfiProtectPrequalReducer } from '../pages/lfiProtectPrequalPage/reducer';

import Storage from '../helpers/storage';

import initialState from './initialState';
import introducersReducer from './introducersReducer';

import { msalInstance } from '../helpers/authConfig';

const appReducer = combineReducers({
  application: applications,
  applications,
  introducers: introducersReducer,
  contacts,
  documents,
  faqs,
  reference,
  session,
  prompts,
  events,
  storage,
  calculators,
  submission,
  prequal: prequalReducer,
  lvr: lvrReducer,
  motorPrequal: motorPrequalReducer,
  employmentHistory: employmentHistoryReducer,
  motorApplication: motorApplicationReducer,
  serviceAbilitySuggestion: serviceAbilitySuggestionReducer,
  comServiceAbilitySuggestion: comServiceAbilitySuggestionReducer,
  routing: routerReducer,
  applicationDocuments: applicationDocumentsReducer,
  idVerification: idVerificationReducer,
  veiCalculator: veiCalculatorReducer,
  motorDeclarations: motorDeclarationsReducer,
  lfiProtectPrequal: LfiProtectPrequalReducer,
});

const rootReducer = (state, action) => {
  if (action.type == 'DO_LOGOUT') {
    Storage.removeItem('token');
    Storage.removeItem('session');
    Storage.removeItem('cache');
    Storage.removeItem('preference');
    Storage.removeItem('LoginRedirectedB2C');
    Storage.removeItem('introducerIdHasChanged');
    Storage.removeItem('capabilities');

    if (Storage.getItem('isUseB2C') == 'true') {
      Storage.removeItem('isUseB2C');
      msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() });
    } else {
      Storage.removeItem('isUseB2C');
      browserHistory.replace('/#/login');
      window.location.reload();
      return appReducer(initialState, action);
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
