import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Select, Input} from 'formsy-react-components';

import Title from '../title';

import {IOption} from '../../../interfaces';

import {IApplicants, IAddress} from '../interfaces';
import {firstOption} from '../functions';

interface IResidencyProps {
  applicants: IApplicants;
  properties: IAddress[];
}
interface IResidencyState {
  address: IAddress | false;
}

export class Residency extends React.Component<IResidencyProps, IResidencyState> {
  UNSAFE_componentWillMount() {
    this.state = {
      address: this.props.properties[0] || false
    };
  }

  getResidenceOptions(properties: IAddress[] = []): IOption[] {
    return properties.map(property => ({
      value: property.autocomplete,
      label: property.autocomplete
    }));
  }

  findResidence(residence: string): IAddress {
    return this.props.properties.filter(property => {
      return property.autocomplete == residence;
    })[0];
  }

  changeResidence(_, value) {
    this.setState({
      address: this.findResidence(value)
    })
  }

  render() {
    const residenceOptions = this.getResidenceOptions(this.props.properties);
    return (
      <div className="panel-section">
        <Title>Residency</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row>
            <Col sm={6} smOffset={6}>
              <label>Prime residence after settlement</label>
            </Col>
          </Row>

          {
            this.props.applicants.individuals.map((applicant, index) =>
              <Row key={`post_resi_applicant_${applicant.id}`}>
                <Col sm={6} className="pt-sm mute">
                  {applicant.firstName} {applicant.lastName}
                </Col>

                <Col sm={6}>
                  <Select
                    name={`residenceAfterSettlement.${applicant.id}.autocomplete`}
                    options={residenceOptions}
                    value={firstOption(residenceOptions)}
                    changeCallback={this.changeResidence}
                    className="form-control" />
                </Col>

                {
                  this.state.address && ["unitNumber", "streetNumber", "streetName", "suburb", "state", "country", "postcode"].map(field => {
                    return (
                      <Input
                        key={`post_resi_applicant_${applicant.id}_${field}`}
                        name={`residenceAfterSettlement.${applicant.id}.${field}`}
                        type={"hidden"}
                        value={this.state.address[field]} />
                    );
                  })
                }
              </Row>
            )
          }
        </div>

      </div>
    );
  }

}

export default Residency;
