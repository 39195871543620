import * as React from 'react';

export default class AssessmentChangeNotice extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="assessment-change-notice text-center">
        <p className="text-lg mute mt-lg">
        Did you know our Residential Serviceability has been updated? Use our calculators now to get an estimate on your customers borrowing power.
        </p>
      </div>
    );
  }
}
