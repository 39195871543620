import * as React from 'react';
import PropTypes from 'prop-types';

import RepaymentsModal from './repaymentsModal';

export default class RepaymentsHeader extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      fineprint: false,
    };

    this.toggleFineprint = this.toggleFineprint.bind(this);
  }

  toggleFineprint() {
    this.setState({
      fineprint: !this.state.fineprint,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="mt-lg pt-sm mb-lg text-center">
        <h1 className="mt-lg">Repayments Calculator</h1>
        <h4 className="mute">Easily estimate loan repayments for your customer</h4>
        <button type="button" id="showServiceabilityModal" className="btn btn-link" onClick={this.toggleFineprint}>
          {'Things you should know '}
          <span className="hidden-xs"> about this calculator</span>
        </button>

        <RepaymentsModal hideModal={this.toggleFineprint} visible={this.state.fineprint} />
      </div>
    );
  }
}

RepaymentsHeader.propTypes = {
};
