import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { browserHistory } from 'react-router';

import API from '../../api';
import Storage from '../../helpers/storage';

import * as applicationsActions from '../../actions/applicationsActions';
import * as contactsActions from '../../actions/contactsActions';
import * as sessionActions from '../../actions/sessionActions';

import LoadingPane from '../../components/loadingPane';
import OnboardingPane from '../../components/onboardingPane';
import AssessmentChangeNoticePane from '../../components/assessmentChangeNoticePane';
import ChatPane from '../../components/chatPane';
import ApplicationsList from './applicationsList';
import ContactsWidget from '../../components/contactsWidget';
import PhoneWidget from '../../components/phoneWidget';
import AssetTypeSelectionPopup from './assetTypeSelectionPopup';
import BestInterestsDutyWidget from '../../components/bestInterestsDutyWidget';

export class ApplicationsPage extends React.Component {
  constructor(props, context) {
    super(props, context);


    this.state = {
      showAssetTypeSelection: false,
    };

    this.getApplications = this.getApplications.bind(this);
    this.filterApplications = this.filterApplications.bind(this);
    this.searchApplications = this.searchApplications.bind(this);
    this.sortApplications = this.sortApplications.bind(this);
    this.onSubmitMotorClick = this.onSubmitMotorClick.bind(this);
    this.onAssetTypeSelectionHide = this.onAssetTypeSelectionHide.bind(this);
  }

  componentDidMount() {
    this.getApplications(true);

    this.checkUpdate();
  }

  onSubmitMotorClick() {
    this.setState({
      showAssetTypeSelection: true,
    });
  }

  checkUpdate() {
    if (window.location.pathname == '/') {
      API.getVersion()
        .then((response) => {
          if (response && (parseInt(response.data) > parseInt(window.iqUIVersion))) {
            /**
             * Reload the page
             */
            window.location.reload();
          }
        });
      setTimeout(this.checkUpdate, 60 * 1000);
    }
  }

  getApplications(init = false) {
    if (Storage.getItem('isUseB2C') == 'true' && Storage.getItem('session') == null) {
      // We have to manage whether we have a session value or not here for B2C. (For reasons)
      browserHistory.replace('#/introducers');
      window.location.reload();
      return;
    }
    if (!this.props.applications.query || init) {
      this.props.actions.getApplications();
      
      if (this.props.session.details.isMotor) {
        // won't be executed on `componentDidMount()` because session is not yet available
        this.props.actions.getPartialMotorApplications();
      }
    }

    setTimeout(this.getApplications, 15 * 60 * 1000);
  }

  filterApplications(filter) {
    this.props.actions.filterApplications(filter);
  }

  sortApplications(order) {
    this.props.actions.sortApplications(order);
  }

  searchApplications(event) {
    const query = event.target.value;
    const filter = event.target.value ? 2 : 1;
    this.props.actions.filterApplications(filter);
    this.props.actions.searchApplications(query);
  }

  shouldShowSubmissionPane() {
    return this.props.session.details.isSubmission || this.props.session.details.isMotor;
  }

  onAssetTypeSelectionHide() {
    this.setState({ showAssetTypeSelection: false });
  }

  render() {
    if (this.props.applications.all === null) {
      return (
        <LoadingPane label="applications" />
      );
    }

    if (this.props.session.details.isMotor && this.props.applications.partialMotor === null) {
      // user has access to motor submissions but action to get applications has not been dispatched yet
      this.props.actions.getPartialMotorApplications();
    }

    return (
      <>
        <AssetTypeSelectionPopup session={this.props.session} shouldShow={this.state.showAssetTypeSelection} onHide={this.onAssetTypeSelectionHide} />
        <Row>
          <Col sm={8}>
            <ApplicationsList
              hasSubmit={() => this.shouldShowSubmissionPane()}
              isMotor={this.props.session.details.isMotor}
              isResSubmission={this.props.session.details.isSubmission}
              applications={this.props.applications}
              filterApplications={this.filterApplications}
              searchApplications={this.searchApplications}
              sortApplications={this.sortApplications}
              onSubmitMotorClick={this.onSubmitMotorClick}
              isUAFUser={this.props.session.details.isUAFUser}
            />
          </Col>
          <Col sm={4}>
            <ContactsWidget contacts={this.props.contacts} title="BDMs" />
            <PhoneWidget />
            <BestInterestsDutyWidget />
          </Col>

          <OnboardingPane />
          <AssessmentChangeNoticePane />
          <ChatPane />
          {/* LinkedIn tracking pixel */}
          <img height="1" width="1" alt="" src="https://dc.ads.linkedin.com/collect/?pid=150012&conversionId=198148&fmt=gif" />
        </Row>
      </>
    );
  }
}

ApplicationsPage.propTypes = {
  applications: PropTypes.object.isRequired,
  contacts: PropTypes.any,
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    applications: state.applications,
    contacts: state.contacts,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...applicationsActions, ...contactsActions, ...sessionActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsPage);
