import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { Form, Checkbox } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';

import Event3rdParty from '../../helpers/event3rdParty';
import { Icon } from '../../utils';

import * as sessionActions from '../../actions/sessionActions';

import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';

export class ProfileSubscription extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      visible: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  hideModal() {
    this.setState({
      visible: false,
    });
  }

  handleSubmit(field, value) {
    // 3rd party
    Event3rdParty.send('update-subscription');

    const data = {};
    data[field] = value;

    this.props.actions.setSubscription(data)
      .then(() => {
        Notify.show(messages.MESS_SUCCESS, 'success', 1000);
      }).catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error', 1000);
      });
  }

  render() {
    return (
      <div className="panel-part">
        <h5 className="panel-bar">
          Subscriptions

          <button id="profileSubscriptionEdit" onClick={this.showModal} className="btn btn-secondary btn-link mt-sm-n mr-sm-n pull-right">
            <Icon glyph="edit" />
          </button>
        </h5>

        <dl className="dl-horizontal">
          <dt>Email updates</dt>
          <dd><Icon glyph={this.props.details.libertyNewsletter && this.props.details.email ? 'check' : 'close'} size="lg" className={this.props.details.libertyNewsletter && this.props.details.email ? 'success' : 'mute-lg'} /></dd>

          <dt>SMS updates</dt>
          <dd><Icon glyph={this.props.details.iqSms && this.props.details.mobile ? 'check' : 'close'} size="lg" className={this.props.details.iqSms && this.props.details.mobile ? 'success' : 'mute-lg'} /></dd>

        </dl>

        <Modal
          show={this.state.visible}
          onHide={this.hideModal}
        >

          <Form
            onValidSubmit={this.handleSubmit}
            layout="vertical"
          >

            <Modal.Body>
              <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
                <span aria-hidden="true">×</span>
              </button>

              <div className="text-center">
                <Icon glyph="cast" className="mt-lg mb-lg mute-lg" size={6} />
              </div>

              <Checkbox
                id="libertyNewsletter"
                name="libertyNewsletter"
                type="checkbox"
                disabled={!this.props.details.email}
                className="form-control form-control-toggle"
                rowClassName="form-group-toggle right"
                style={{ marginTop: '10px' }}
                valueLabel={(
                  <span>
                    Email newsletter
                    <span className="mute b mt-sm-n">
                          {
                          this.props.details.email
                            ? `${this.props.details.libertyNewsletter ? 'Disable' : 'Enable'} newsletter with our latest products, offerings and events sent to ${this.props.details.email}`
                            : 'Please add your email address before you can enable our newsletter'
                        }
                    </span>
                  </span>
                )}
                changeCallback={this.handleSubmit}
                value={!!(this.props.details.libertyNewsletter && this.props.details.email)}
              />

              <Checkbox
                id="iqSms"
                name="iqSms"
                type="checkbox"
                disabled={!this.props.details.mobile}
                className="form-control form-control-toggle"
                rowClassName="form-group-toggle right"
                valueLabel={(
                  <span>
                    SMS updates
                    <span className="mute b mt-sm-n">
                          {
                          this.props.details.mobile
                            ? `${this.props.details.iqSms ? 'Disable' : 'Enable'} SMS on application updates sent to ${this.props.details.mobile}`
                            : 'Please add your mobile number before you can enable our SMS updates'
                        }
                    </span>
                  </span>
                )}
                changeCallback={this.handleSubmit}
                value={!!(this.props.details.iqSms && this.props.details.mobile)}
              />

            </Modal.Body>

          </Form>
        </Modal>
      </div>
    );
  }
}

ProfileSubscription.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  details: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSubscription);
