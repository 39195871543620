import * as React from 'react';

import {Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import DateInput from '../dateInput';
import Title from '../title';

import {toOption, toRequired, firstOption} from '../functions';

import {IOption} from '../../../interfaces';

interface IIdentificationProps {
  index: number
}

interface IIdentificationState {
  type: string
  }

export class Identification extends React.Component<IIdentificationProps, IIdentificationState> {
  constructor(props) {
    super(props);

    this.state = {
      type: 'driversLicence'
    }
  }

  changeId(_, type) {
    this.setState({type})
  }

  get identifications(): IOption[] {
    return ["Drivers Licence", "Passport"].map(o => toOption(o));
  }

  get states(): IOption[] {
    return ["VIC", "NSW", "TAS", "QLD", "SA", "WA", "NT", "ACT"].map(o => toOption(o));
  }

  render() {
    return (
      <div className="panel-section">
        <Title>Identification</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row>
            <Col sm={3}>
              <Select
                name={`individuals[${this.props.index}].identification.type`}
                id={`individuals[${this.props.index}].identification.type`}
                label={toRequired("ID type")}
                options={this.identifications}
                changeCallback={this.changeId.bind(this)}
                className="form-control"
                value={firstOption(this.identifications)} />
            </Col>

            {
              (() => {
                if (this.state.type == 'driversLicence') {
                  return (
                    <Col sm={2}>
                    <Select
                      data-test-name="state"
                      name={`individuals[${this.props.index}].identification.state`}
                      id={`individuals[${this.props.index}].identification.state`}
                      label={toRequired("ID state/territory")}
                      options={this.states}
                      value={firstOption(this.states)}
                      className="form-control"/>
                  </Col>
                  );
                }

              })()
            }

            <Col sm={3}>
              <Input
                name={`individuals[${this.props.index}].identification.number`}
                id={`individuals[${this.props.index}].identification.number`}
                label={toRequired("ID number")}
                validations={{
                  isRequired: true,
                  minLength: 4
                }}
                value=""
                validationError="Valid ID number is required" />
            </Col>

            <Col sm={2}>
              <DateInput
                name={`individuals[${this.props.index}].identification.expiry`}
                label={toRequired("ID expiry date")}
                validations={{
                  isRequired: true
                }}
                validationErrors={{
                  isRequired: "Expiry date is required"
                }}
                precision="month"
                value=""
              />
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default Identification;
