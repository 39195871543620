import { Input, Select } from 'formsy-react-components';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import * as ReactTooltip from 'react-tooltip';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as referenceActions from '../../actions/referenceActions';
import { PRODUCTGROUPS, MOTOR_PLATE_MAX_LENGTH, MOTOR_PLATE_MIN_LENGTH} from '../../constants/types';
import ReferenceApi from '../../api/referenceApi';
import GlassGuideApi, {VehicleSearchStatus} from '../../api/glassGuideApi';

import Mask from '../../helpers/mask';
import { get_States } from '../../constants/functions';
const Moment = require('moment');

type RegoPlateSearchProps = {
  application: any,  
  formsy: Formsy | null, 
  reference: any,
  actions: any,
  session: any,
  getValue: (input: string) => any,
  setMessage: (input: string | null) => any,
}

type RegoPlateSearchState = {
  loading: boolean,
  registrationNumber: string,
  registrationState: string,
}

export class RegoPlateSearch extends React.Component<RegoPlateSearchProps, RegoPlateSearchState> {
  STATE_LIST = ['', ...get_States()] ;
  constructor(props: RegoPlateSearchProps) {
    super(props);

    this.state = {   
      loading: false,
      registrationNumber: '',
      registrationState: '',
    }
    this.getNvicsByRegoPlate = this.getNvicsByRegoPlate.bind(this);  
    this.changeRegistrationNumber = this.changeRegistrationNumber.bind(this);
    this.changeRegistrationState = this.changeRegistrationState.bind(this);
    this.resetInputs = this.resetInputs.bind(this);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  changeRegistrationNumber(e) {
    this.setState({registrationNumber: e.target.value});
    this.resetInputs();
  }
 
  changeRegistrationState(e) {
    this.setState({registrationState: e});
    this.resetInputs();
  }
  resetInputs(){
    this.setFormsyValue('nvicInput', ''); 
    this.setFormsyValue('vinInput', ''); 
    this.props.setMessage(null);
  }
  getAppraisal() {
    if (this.props.reference.nvic && this.props.reference.appraisal) {
      const amount = this.props.application?.motorPurchaseVehicle?.isUsed ? this.props.reference.appraisal.tradeHighAmount : this.props.reference.appraisal.retailAmount;
      return Mask.number(amount);
    }
    return null;
  }
  setNvicAndAppraisal(vehicle) {
    this.setAppraisalOnSearch(vehicle.nvic);
    this.setNvic(vehicle);
  }
  setAppraisalOnSearch(nvic) {
    const isNew = !this.props.getValue('motorPurchaseVehicle.isUsed') || 0;
    const odometer = this.props.getValue('motorPurchaseVehicle.odometer') || 0;
    this.props.actions.getAppraisal(nvic, odometer, isNew, this.props.application?.productGroupId);
  }
  setFormsyValue(field: string, value: any) {
    if (this.props.formsy) {
      this.props.formsy.inputs.filter((input) => input.props.id == field)[0].setValue(value);
    }
  }
  
  setNvic(vehicle) {
    if (vehicle) { 
      this.setFormsyValue('motorPurchaseVehicle.nvic', vehicle.nvic);
      this.setFormsyValue('motorPurchaseVehicle.vehicleColour', vehicle.colour);
      this.setFormsyValue('motorPurchaseVehicle.engineNumber', vehicle.engineNumber);
      this.setFormsyValue('motorPurchaseVehicle.vin', vehicle.vin);
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationState', vehicle.registrationState);
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationNumber', vehicle.registrationPlate);
      const date = new Date(vehicle.registrationExpiryDate); 
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationExpiry',(Moment(date)).format('MM/YYYY'));

      const nvic = this.props.application?.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN ? `CV-${vehicle.nvic}` : vehicle.nvic;
      ReferenceApi.getNvic(nvic)
        .then((response) => response.data[0])
        .then((nvicDetails) => {
          this.setFormsyValue('motorPurchaseVehicle.year', nvicDetails.year);
          this.setFormsyValue('motorPurchaseVehicle.makeId', nvicDetails.makeId);
          this.setFormsyValue('motorPurchaseVehicle.modelId', nvicDetails.modelId);
          this.setFormsyValue('motorPurchaseVehicle.bodyTypeId', nvicDetails.bodyTypeId);
          this.setFormsyValue('motorPurchaseVehicle.transmissionTypeId', nvicDetails.transmissionTypeId);
          this.setFormsyValue('motorPurchaseVehicle.make', nvicDetails.make);
          this.setFormsyValue('motorPurchaseVehicle.model', nvicDetails.model);
        });
    }
  }
  
  getNvicsByRegoPlate(){    
    const registrationNumber = this.state.registrationNumber;  
    const registrationState = this.state.registrationState;  
    
    if(registrationNumber && registrationState){
      this.setState({loading: true});
      GlassGuideApi.searchByRegoPlate(registrationNumber, registrationState)
      .then((response) => {
        if(response.data.status === VehicleSearchStatus.ResultFound){
          this.props.actions.getNvic(response.data);
          this.setNvicAndAppraisal(response.data.result);
          this.props.setMessage('Vehicle updated!');
        } else {
          this.props.actions.clearNvic();
          this.props.actions.clearAppraisal();
          this.props.setMessage(response.data.resultMessage);
          this.setFormsyValue('nvicInput', '');      
          this.setFormsyValue('motorPurchaseVehicle.nvic', '');
        }
        this.setState({loading: false});
      });
    }
  }
  public render() {
    return (
      <div>
       <Col sm={3}>
          <Input
            name="registrationNumberInput"
            id="registrationNumberInput"
            ref="registrationNumberInput"
            type="text"
            label="Registration No."
            placeholder="eg: ABC123."            
            autoComplete="off"
            validationError="Registration no. must be valid"
            validations={{
              isValidRegistration: true,
            }}      
            data-tip
            data-for="plate_tooltip"
            onKeyUp={this.changeRegistrationNumber}
            onFocus={this.changeRegistrationNumber}
          />
          <ReactTooltip id="plate_tooltip" efect="solid" place="bottom">
          Letters and numbers only. Must be between {MOTOR_PLATE_MIN_LENGTH} and {MOTOR_PLATE_MAX_LENGTH} characters in length
          </ReactTooltip>
        </Col>
        <Col sm={3}>
          <Select
              name="registrationStateInput"
              id="registrationStateInput"
              ref="registrationStateInput"
              label="State"
              placeholder="Please select"
              options={this.STATE_LIST.map(x =>({
                  "value" : x,
                  "label" : x
              }))}              
              validations={{
                isValidState: true,
              }}
              className="form-control"                                      
              data-tip
              data-for="state_tooltip"
              changeCallback={(field, value) => { this.changeRegistrationState(value); }}             
          />
          <ReactTooltip id="state_tooltip" efect="solid" place="bottom">
          State is required
          </ReactTooltip>
        </Col>
        <Col sm={2} style={{ marginTop: 30 }}>
          <a id="getNvicsByRegoPlate" onClick={this.getNvicsByRegoPlate} className="btn btn-success btn-sm"> 
            Search &nbsp;
            {
              this.state.loading && (<i className="fas fa-spinner fa-spin fa-pulse fa-1x p-01"></i>)
            }
          </a>
        </Col>
      </div>
    );
  }
}
function mapStateToProps(state) {  
  return {
    reference: state.reference,
    session: state.session,   
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegoPlateSearch);