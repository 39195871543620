import Api from '../api';

export const setFeedbackThumb = (data) => (dispatch) => Api.setFeedbackThumb(data)
  .then(() => dispatch({
    type: 'SET_FEEDBACK_THUMB',
  }))
  .catch((error) => {
    throw (error);
  });

export const setFeedbackMessage = (data) => (dispatch) => Api.setFeedbackMessage(data)
  .then(() => dispatch({
    type: 'SET_FEEDBACK_MESSAGE',
  }))
  .catch((error) => {
    throw (error);
  });
