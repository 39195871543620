import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Money, Percentage } from '../../utils';

const Moment = require('moment');


export class ComServiceAbilityPrint extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };

    this.mapIncomeOtherType = this.mapIncomeOtherType.bind(this);
    this.getLoanAmount = this.getLoanAmount.bind(this);
    this.getPurchaseAmount = this.getPurchaseAmount.bind(this);
    this.getApplicantName = this.getApplicantName.bind(this);
    this.getAddbackAmount = this.getAddbackAmount.bind(this);
  }

  mapIncome(applicant) {
    const incomes = [];
    applicant.payg.map((payg) => {
      incomes.push({
        ...payg,
        type: 'payg',
      });
    });
    applicant.selfEmployed.map((selfEmployed) => {
      incomes.push({
        ...selfEmployed,
        type: 'selfEmployed',
      });
    });
    applicant.otherIncome.map((otherIncome) => {
      incomes.push({
        ...otherIncome,
        type: 'otherIncome',
      });
    });
    return incomes;
  }

  mapIncomeType(income) {
    switch (income) {
      case 'payg': return 'PAYG';
      case 'selfEmployed': return 'Self Employed';
      case 'otherIncome': return 'Other Income';
    }
  }

  mapIncomeOtherType(income) {
    return income != null && !isNaN(income) && typeof income !== 'undefined' ? this.props.reference.otherIncomeTypes.filter((other) => income == other.value)[0].label : '';
  }

  mapIncomeFrequency(frequency) {
    switch (frequency) {
      case 1: return 'annually';
      case 2: return 'monthly';
      case 3: return 'fortnightly';
      case 4: return 'weekly';
    }
  }

  mapRepayment(repayment) {
    switch (repayment) {
      case 0: return 'P&I';
      default: return `${repayment} year${repayment > 1 ? 's' : ''} IO`;
    }
  }

  mapPurpose(purpose) {
    switch (purpose) {
      case 0: return 'Owner Occupied';
      case 1: return 'Investment';
      case 2: return 'Cashout Owner Occupied Security';
      case 3: return 'Cashout Investment Security';
      default: return 'Investment';
    }
  }

  mapStructure(structure) {
    switch (structure) {
      case 0: return 'Variable';
      default: return `${structure} year${structure > 1 ? 's' : ''} fixed`;
    }
  }

  mapLiability(liability) {
    switch (liability) {
      case 'credit': return 'Credit card';
      case 'loan': return 'Personal / Car loan';
      case 'rent': return 'Rent';
      case 'investment': return 'Investment loan';
      case 'other': return 'Other';
      case 'homeloan': return 'Home loan';
    }
  }

  mapLiabilityFrequency(liability) {
    switch (liability) {
      case 'credit': return 'limit';
      case 'loan': return 'monthly repayments';
      case 'rent': return 'monthly amount';
      case 'investment': return 'monthly repayments';
      case 'other': return 'monthly amount';
    }
  }

  getLoanAmount() {
    let amount = 0;

    this.props.data.multiLoanDetails.map((loan) => {
      loan.splitLoanDetails.map((split) => {
        amount += split.loanAmount;
      });
    });

    return amount;
  }

  getPurchaseAmount() {
    let amount = 0;

    this.props.data.multiLoanDetails.map((loan) => {
      amount += loan.purchaseAmount;
    });

    return amount;
  }

  getAddbackAmount(company) {
    let amount = 0;

    if (company) amount += company.depreciation + company.interest + company.amortisation + company.otheraddback;

    return amount;
  }

  getApplicantName(id) {
    let name;

    this.props.data.applicants.map((applicant, index) => {
      name = `applicant ${index + 1}`;

      if (applicant.id == id && applicant.name) {
        name = applicant.name;
      }
    });

    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  /**
   * Render
   */
  render() {
    if (!this.props.data || !this.props.result) {
      return (
        <div className="po text-center" style={{ position: 'fixed', top: '50%' }}>
          <h1>Printing is disabled</h1>
          <p>Please calculate serviceability to enable printing</p>
        </div>
      );
    }

    return (
      <div className="po">
        <img alt="" src={`/${window.iqUIVersion}/assets/img/logo-liberty-financial.png`} width="220" style={{ float: 'right', marginTop: '10px' }} />

        <h1 style={{ fontSize: '28px' }}>
          <strong>
          Serviceability calculation
            <br />
          for Liberty Financial
          </strong>
        </h1>
        <p className="mute" style={{ fontSize: '18px', marginBottom: '40px' }}>
          {'Prepared by '}
          {this.props.session.details.contact}
          {' '}
          {this.props.session.details.companyName ? `of ${this.props.session.details.companyName}` : ''}
          {' on '}
          {Moment().format('D MMM YYYY')}
        </p>

        <div style={{ pageBreakInside: 'avoid' }}>
          <h1>
            <strong>Loan structure</strong>
            {' '}
          </h1>
          <hr />

          <table className="table table-lg">
            <tbody>
              {
                (() => {
                  if (this.props.data.multiLoanDetails[0].splitLoanDetails.length > 1) {
                    return (
                      <tr>
                        <td />

                        {
                        this.props.data.multiLoanDetails.map((loan) => loan.splitLoanDetails.map((split, index) => (
                          <td key={`label-${split.id}`} width="120px" className="text-right">
                            <h5>
                              <strong>
                                {'Split '}
                                {index + 1}
                              </strong>
                            </h5>
                          </td>
                        )))
                      }
                      </tr>
                    );
                  }
                })()
              }

              <tr>
                <td>Loan amount</td>

                {
                  this.props.data.multiLoanDetails.map((loan) => loan.splitLoanDetails.map((split) => (
                    <td key={`amount-${split.id}`} className="text-right"><h5><strong><Money round={0}>{split.loanAmount}</Money></strong></h5></td>
                  )))
                }
              </tr>
              <tr>
                <td>Interest rate</td>
                {
                  this.props.data.multiLoanDetails.map((loan) => loan.splitLoanDetails.map((split) => (
                    <td key={`rate-${split.id}`} className="text-right"><h5><strong><Percentage>{split.interestRate / 100}</Percentage></strong></h5></td>
                  )))
                }
              </tr>
              <tr>
                <td>Loan term</td>
                {
                  this.props.data.multiLoanDetails.map((loan) => loan.splitLoanDetails.map((split) => (
                    <td key={`term-${split.id}`} className="text-right">
                      <h5>
                        <strong>
                          {loan.term}
                          {' years'}
                        </strong>
                      </h5>
                    </td>
                  )))
                }
              </tr>

              <tr>
                <td>Repayment type</td>
                {
                  this.props.data.multiLoanDetails.map((loan) => loan.splitLoanDetails.map((split) => (
                    <td key={`repayment-${split.id}`} className="text-right"><h5><strong>{this.mapRepayment(split.repayment)}</strong></h5></td>
                  )))
                }
              </tr>

            </tbody>
          </table>
        </div>

        <div style={{ pageBreakInside: 'avoid' }}>
          <h1 className="mt-lg"><strong>Business Applicants</strong></h1>
          {
          this.props.data.companies.map((company, index) => (
            <div key={company.id}>
              <hr className={index ? 'sm' : ''} />

              <table className="table table-lg">
              <tbody>
                <tr>
                  <td>
                    <strong>{company.name || `Business ${index + 1}`}</strong>
                  </td>
                  <td>
                  <table className="text-right pull-right" width="100%">
                    <tr>
                    <td className="p-h">
                      <small className="mute">Current year</small>
                    </td>
                    <td width="150" className="p-h">
                      <small>
                      <small className="mute">(deduction)</small>
                      {' '}
                      &nbsp;
                      <strong><Money round={0}>{company.otherdeduction}</Money></strong>
                      </small>
                    </td>
                    <td width="120" className="p-h">
                      <small>
                      <small className="mute">(add back)</small>
                      {' '}
                      &nbsp;
                      <strong><Money round={0}>{this.getAddbackAmount(company)}</Money></strong>
                      </small>
                    </td>
                    <td width="150" className="p-h">
                      <small><small className="mute">(NPBT)</small></small>
                      {' '}
                      &nbsp;
                      <strong><Money round={0}>{company.profit}</Money></strong>
                    </td>
                    </tr>
                  </table>
                  </td>
                </tr>
              </tbody>
              </table>
            </div>
          ))
          }
        </div>

        <div style={{ pageBreakInside: 'avoid' }}>
          <h1 className="mt-lg"><strong>Individual Applicants</strong></h1>

          {
          this.props.data.applicants.map((applicant, index) => (
            <div key={applicant.id}>
              <hr className={index ? 'sm' : ''} />

              <h3 className="mb-sm">
                <strong>{applicant.name || `Applicant ${index + 1}`}</strong>
              </h3>

              <p className="mute">
              (
                {applicant.relationship == 1 ? 'single' : 'de-facto/married'}
                {' '}
                {applicant.relationship == 2 && ` to ${this.getApplicantName(applicant.inRelationshipWithId)}`}
                {', '}
                {applicant.dependants}
                {' dependants, lives in '}
                {applicant.postcode}
                {', '}
                <Money round={false}>{applicant.expenses}</Money>
                {' monthly expenses'}
              )
              </p>

              <table className="table table-lg">
                <tbody>
                  {
                    this.mapIncome(applicant).map((income) => {
                      switch (income.type) {
                        case 'selfEmployed':
                          return (
                            <tr key={`${applicant.id}-${income.id}`}>
                              <td>{this.mapIncomeType(income.type)}</td>
                              <td>
                                <table className="text-right pull-right" width="100%">
                                  <tr>
                                    <td className="p-h">
                                      <small className="mute">Current year</small>
                                    </td>
                                    <td width="150" className="p-h">
                                      <small>
                                        <small className="mute">(depreciation)</small>
                                        {' '}
                                        &nbsp;
                                        <strong><Money round={0}>{income.currentDepreciation}</Money></strong>
                                      </small>
                                    </td>
                                    <td width="120" className="p-h">
                                      <small>
                                        <small className="mute">(interest)</small>
                                        {' '}
                                        &nbsp;
                                        <strong><Money round={0}>{income.currentInterestPaid}</Money></strong>
                                      </small>
                                    </td>
                                    <td width="150" className="p-h">
                                      <small><small className="mute">(NPBT)</small></small>
                                      {' '}
                                      &nbsp;
                                      <strong><Money round={0}>{income.currentAmount}</Money></strong>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          );
                        default:
                          return (
                            <tr key={`${applicant.id}-${income.id}`}>
                              <td>
                                {this.mapIncomeType(income.type)}
                                {' '}
                                {this.mapIncomeOtherType(income.incomeOtherTypeId) ? '- ' : ''}
                                {' '}
                                {this.mapIncomeOtherType(income.incomeOtherTypeId)}
                              </td>
                              <td>
                                <small className="mute">
                                (
                                  {this.mapIncomeFrequency(income.frequency)}
                                  {' gross'}
                                )
                                </small>
                                {' '}
                                &nbsp;
                                <strong><Money round={0}>{income.amount}</Money></strong>
                              </td>
                            </tr>
                          );
                      }
                    })
                  }
                </tbody>
              </table>
            </div>
          ))
        }
        </div>

        <div style={{ pageBreakInside: 'avoid' }}>
          <h1 className="mt-lg"><strong>Liabilities</strong></h1>
          <hr />

          {
          (() => {
            if (this.props.data.liabilities) {
              return (
                <table className="table table-lg">
                  <tbody>
                    {
                      this.props.data.liabilities.map((liability, index) => (
                        <tr key={`liability${index}`}>
                          <td>
                            {this.mapLiability(liability.type)}
                            {' '}
                            <small className="mute">{liability.type == 'investment' ? `($${liability.balance})` : ''}</small>
                          </td>
                          <td className="text-right pr-h">
                            <small className="mute">
                            (
                              {this.mapLiabilityFrequency(liability.type)}
                            )
                            </small>
                            {' '}
                            &nbsp;
                            <strong><Money round={0}>{liability.amount}</Money></strong>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              );
            }

            return (
              <div style={{ fontSize: '16px' }}>No liabilities</div>
            );
          })()
        }
        </div>


        <div style={{ position: 'fixed', bottom: 0, marginRight: '80px' }}>
          <p style={{ fontSize: '10px', color: '#5b77a2' }}>
            The “Indicative result” is an estimate only and based upon the information you have provided. If any of the information changes, the result will change also. The indicative result is only a guide. If an application is submitted, serviceability will be determined in accordance with Liberty’s usual credit assessment criteria.
          </p>
          <p style={{ textAlign: 'justify', textAlignLast: 'justify', fontSize: '10px' }}>
            Level 16, 535 Bourke Street, Melbourne, Victoria 3000 |
            {' '}
            <span className="secondary">Customer Enquiries</span>
            {' '}
            13 11 33 |
            {' '}
            <span className="secondary">Telephone</span>
            {' '}
            03 8635 8888 |
            {' '}
            <span className="secondary">Facsimile</span>
            {' '}
            03 8635 9999 |
            {' '}
            <span className="secondary">liberty.com.au</span>
            <br />
            Liberty Financial Pty Ltd (ABN 55 077 248 983 - Australian Credit Licence 286596) | Secure Funding Pty Ltd (ABN 25 081 982 872 - Australian Credit Licence 388133)
            <br />
            Liberty Network Services Pty Ltd (ABN 65 151 158 628 - Australian Credit Licence 408042) | Liberty Fiduciary Limited (ABN 80 119 884 623 - AFSL 303137)
            <br />
          </p>
        </div>

      </div>

    );
  }
}


ComServiceAbilityPrint.propTypes = {
  data: PropTypes.shape({
    applicants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      relationship: PropTypes.any.isRequired,
      inRelationshipWithId: PropTypes.any,
      payg: PropTypes.any,
    })).isRequired,
    multiLoanDetails: PropTypes.array,
    liabilities: PropTypes.array,
  }).isRequired,
  reference: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

ComServiceAbilityPrint.defaultProps = {
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComServiceAbilityPrint);
