import * as React from 'react';
import PropTypes from 'prop-types';

const ApplicationBarItem = (props) => (
    <div className={`bar-item ${props.right !== undefined ? 'text-right' : ''}`}>
      <small className="b mute text-left">{props.label}</small>
      <h5 className="mt-xs mb-h r">
        <strong className={props.processing ? 'mute' : ''}>
          {props.children}
        </strong>
      </h5>
    </div>
);

ApplicationBarItem.propTypes = {
  processing: PropTypes.bool,
  children: PropTypes.any,
  right: PropTypes.any,
};

export default ApplicationBarItem;
