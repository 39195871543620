import { LfiProtectPrequalActionTypes, LfiProtectPrequalAction } from "./actions"

export enum SubmissionStatus {
  Initial = "Initial",
  Pending = "Pending",
  Succeeded = "Succeeded",
  Failed = "Failed"
  }

export interface LfiProtectPrequalState {
  status:SubmissionStatus,
  errorMessage: string
}

export const initialState: LfiProtectPrequalState = {
  status:SubmissionStatus.Initial,
  errorMessage: ""
}

export const LfiProtectPrequalReducer = (state: LfiProtectPrequalState = initialState, action: LfiProtectPrequalAction): LfiProtectPrequalState => {
    switch (action.type) {            
      case LfiProtectPrequalActionTypes.InitialiseForm:
        return {
          ...state,
          status: SubmissionStatus.Initial,
          errorMessage: ""
        };
      case LfiProtectPrequalActionTypes.SubmitPending:
        return {
          ...state,
          status: SubmissionStatus.Pending,
          errorMessage: ""
        };
      case LfiProtectPrequalActionTypes.SubmitSucceeded:
        return {
          ...state,
          status: SubmissionStatus.Succeeded,
          errorMessage: ""
        };
      case LfiProtectPrequalActionTypes.SubmitFailed:
        return {
          ...state,
          status: SubmissionStatus.Failed,
          errorMessage: action.errorMessage
        };
      default:
        return {
          ...state,
          errorMessage: ""
        }
    }
}