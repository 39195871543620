import * as React from 'react';
import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import Mask from '../../../helpers/mask';

import * as Guid from 'guid';

import Navigation from '../navigation';
import Header from '../header';
import Title from '../title';

import Property from './property';
import Vehicle from './vehicle';
import Other from './other';
import Total from './total';

import {shouldFormBeVisible, toOption, toRequired, hasFormData, setFormData, getCurrentFormData} from '../functions';

import {IBaseProps, IGuid, IApplicants, IIndividual, IFormData, ISpecificAsset, IIncome, IRentalIncome} from '../interfaces';
import {IOption} from '../../../interfaces';
import {assetOptions} from '../options';
import AddButton from '../addButton';

interface IAsset {
  id: IGuid;
  type: IAssetType;
  value: number;
  investment: boolean;
}
interface IOtherAsset {
  id: string
  value: number
}

type IAssetType = "property" | "vehicle";

interface IAssetsProps extends IBaseProps {}

interface IAssetsState {
  specificAssets: IAsset[],
  otherAssets: IOtherAsset[],
  properties: string[],
  total: number,
  initiated: boolean
}

function optionToOtherAsset(option: IOption): IOtherAsset {
  return {
    id: String(option.value),
    value: 0
  }
}

function updateAssetValue(asset, value, id) {
  if (id === asset.id) {
    asset.value = parseInt(Mask.clean(value)) || 0;
  }

  return asset;
}

export class Assets extends React.Component<IAssetsProps, IAssetsState> {
  constructor(props) {
    super(props);
    this.state = {
      specificAssets:[],
      otherAssets: assetOptions.map(optionToOtherAsset),
      properties: [],
      total: 0,
      initiated: false
    };

    this.addAsset = this.addAsset.bind(this);
    this.updateOtherAsset = this.updateOtherAsset.bind(this);
    this.updateSpecificAsset = this.updateSpecificAsset.bind(this);
    this.removeSpecificAsset = this.removeSpecificAsset.bind(this);
    this.calculateValueOfAssets = this.calculateValueOfAssets.bind(this);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.addInitialAssets(this.props.data.applicants, this.props.data.incomes);

    if (hasFormData(this.props.data, 'assets')) {
      this.props.data.assets.specificAssets &&
      this.props.data.assets.specificAssets
        .filter(asset => asset !== null)
        .forEach(asset => {
          if (!this.state.specificAssets.some(specificAsset => specificAsset.id === asset.id)) {
            this.addAsset(asset.type, asset.value, asset.use === "investment");
          }
        });
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'assets')) {
      setFormData(this.props.data, 'assets', this.refs.form);
    }
  }

  isApplicantOwning(individual: IIndividual): boolean {
    return ["owning"].indexOf(individual.currentAddress.occupancy) > -1;
  }

  isInvesting(incomes: IIncome[]): boolean {
    return incomes.some(this.isRentalIncome);
  }

  hasAssetForAddress(propertyAssets: string[], address: string): boolean {
    return propertyAssets.some(asset => asset == address);
  }

  isRentalIncome(income: IIncome): income is IRentalIncome {
    return income.type == 'rental';
  }

  addInitialAssets(applicants: IApplicants, incomes: IIncome[]) {
    let properties = this.state.properties;

    applicants.individuals.forEach(individual => {
      if (!hasFormData(this.props.data, 'assets')) {
        if (this.isApplicantOwning(individual)) {
          if (!this.hasAssetForAddress(properties, individual.currentAddress.autocomplete)) {
            this.addNewAsset('property');
            properties.push(individual.currentAddress.autocomplete);
          }
        }
      }

      if (this.isInvesting(incomes)) {
        incomes
          .filter(this.isRentalIncome)
          .forEach(income => {
            if (!this.hasAssetForAddress(properties, income.address.autocomplete)) {
              this.addAsset('property', 0, true, income.id);
              properties.push(income.address.autocomplete);
            }
        });
      }
    });

    this.setState({properties});
  }

  addNewAsset(type: IAssetType) {
    this.addAsset(type, 0, false);
  }

  addAsset(type: IAssetType, value: number, investment: boolean, id: Guid = Guid.create().value) {
    const specificAssets = this.state.specificAssets;
    specificAssets.push({
      id,
      type,
      value,
      investment
    });
    this.setState({specificAssets, initiated: true});
  }

  removeSpecificAsset(id: IGuid) {
    const specificAssets = this.state.specificAssets.filter(asset => asset.id != id);
    this.setState({specificAssets});
  }

  calculateValueOfAssets(specificAssets, otherAssets) {
    return [
      ...specificAssets,
      ...otherAssets]
      .reduce((sum, asset) => Number(asset.value) + sum, 0);
  }

  updateSpecificAsset(id: IGuid, value: string) {
    const specificAssets = this.state.specificAssets.map(asset => updateAssetValue(asset, value, id));
    this.setState({specificAssets});
  }

  updateOtherAsset(id: string, value: string) {
    const otherAssets = this.state.otherAssets.map(asset => updateAssetValue(asset, value, id));
    this.setState({otherAssets});
  }

  onValidSubmit(data: IFormData) {
    this.props.saveStepData(data, 'assets');
  }

  onInvalidSubmit() {
    this.props.notifyStep();
  }

  get options(): IOption[] {
    return ["Property", "Vehicle"].map(o => toOption(o));
  }

  mapIcon(option: IOption): string {
    switch (option.value) {
      case 'property': return 'home';
      case 'vehicle': return 'car';
    }
    return 'receipt';
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        className={shouldFormBeVisible(this.props.currentStep, 2)}
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName={''}
        labelClassName={''}
        rowClassName={''}
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">
            <Header title="Assets" required />

            <div className="panel-section">
              <Title noborder>Assets</Title>
              <div className="panel-section-body panel-section-body-simple">
                <div className="panel-section-container panel-section-container-well">
                  {
                    this.state.specificAssets
                      .map((asset, index) => {
                      const attr = {
                        index,
                        id: asset.id,
                        key: asset.id,
                        investment: this.props.data.incomes.find(income => income.id === asset.id),
                        updateAsset: this.updateSpecificAsset,
                        removeAsset: this.removeSpecificAsset
                      }

                      switch (asset.type) {
                        case 'vehicle': {
                          return <Vehicle {...attr} />
                        }

                        case 'property': {
                          return <Property {...attr} data={this.props.data} />
                        }
                      }
                    })
                  }

                  <div className="panel-section-btns">
                    {
                      this.options.map(option => {
                        return (
                          <AddButton
                            key={`add${option.label}AssetButtonKey`}
                            id={`add${option.label}AssetButton`}
                            glyph={this.mapIcon(option)}
                            label={option.label}
                            onClick={this.addNewAsset.bind(this, option.value)} />
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>

            <Other updateAsset={this.updateOtherAsset} />

            <Total total={this.calculateValueOfAssets(this.state.specificAssets, this.state.otherAssets)} />
          </div>
        </div>

        <Navigation saveSubmission={() => this.props.saveSubmission(getCurrentFormData(this.refs.form), "assets")} previousStep={this.props.previousStep} />
      </Form>
    );
  }
}

export default Assets;
