import * as React from 'react';
import { Icon } from '../../../utils';
import ApplicationAgreement from '../applicationAgreement';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showAgreement, hideAgreement, getDocument } from '../applicationDocuments/actions';
import {DocumentStatus} from './reducer'
import { Overlay } from '../../../utils';
import { notify as Notify } from 'react-notify-toast';

interface ApplicationESigDocumentProps {
  application: Application,
  broker:Broker,
  status:DocumentStatus,
  showAgreement: boolean,
  getApplication: Function,
  actions: {
    getApplication: Function,
    getDocument: Function,
    showAgreement: Function,
    hideAgreement: Function
  }
}

export interface Application {
  applicationNumber:Number,
  applicationType:Number,
  motorPurchaseVehicle : Object,
  applicants:Applicant[],
  productGroupId: Number,
}

export interface Applicant {
  contact: {
    email: String [],
    mobile: String []
  },
  applicantId: Number
}

export interface Broker {
  email: String
}

const isMotorApplication = (application:Application) => application.applicationType === 2

export const ApplicationESigDocument = (props: ApplicationESigDocumentProps) =>
  <div>
    <button className={'btn btn-left btn-lg btn-block btn-success mt-sm'} onClick={() => props.actions.showAgreement()}>
      <Icon glyph="email" className="btn-icon" />
      Get ESignature Pack
        </button>
    {
      isMotorApplication(props.application)
        ?
        <ApplicationAgreement
          documentId={9}
          visible={props.showAgreement}
          application={props.application}
          getApplication={props.getApplication}
          getDocument={() => {
            props.actions.getDocument(props.application.applicationNumber, props.broker, props.application.applicants, props.application.productGroupId);
          }}
          hideAgreement={props.actions.hideAgreement}
          isDocumentDownloadPending = {props.status === DocumentStatus.Pending ? true:false}
          submitLabel = "Confirm &amp; Generate"
          modalTitle="Confirm Contact &amp; Vehicle Details"
          showContactDetails={true}
        />
        : ''
    }
  </div>

function mapStateToProps({ applicationDocuments, session }) {
  return {
    showAgreement: applicationDocuments.showAgreement,
    status:applicationDocuments.status,
    broker:{
      email:session.details.email,
      mobile:session.details.mobile
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ showAgreement, hideAgreement, getDocument }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationESigDocument);
