import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import ReactTooltip from 'react-tooltip';
import Guid from 'guid';

import Mask from '../../helpers/mask';

import * as referenceActions from '../../actions/referenceActions';
import * as functions from '../../constants/functions';

import Icon from '../../utils/icon';

export class ComServiceAbilityApplicant extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      incomes: [],
    };

    this.changeIncomeType = this.changeIncomeType.bind(this);
    this.changeOtherIncomeType = this.changeOtherIncomeType.bind(this);
    this.addIncome = this.addIncome.bind(this);
    this.removeIncome = this.removeIncome.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);
    this.getApplicantName = this.getApplicantName.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
  }

  inputRefs = {};

  setRef = (id) => (ref) => {
    if (ref) this.inputRefs[id] = ref;
    else delete this.inputRefs[id];
  };

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.incomes) {
      this.props.data.incomes.map((income) => {
        this.addIncome(false, income);
      });
    } else if (!this.props.index) {
      this.addIncome(false);
    }

    ReactTooltip.rebuild();
  }

  getIncomeTypes() {
    return [{
      value: 'payg',
      label: 'PAYG',
    },
    {
      value: 'other',
      label: 'Other income',
    },
    {
      value: 'selfEmployed',
      label: 'Self employed',
    }];
  }

  getRelationships() {
    return [{
      value: 1,
      label: 'Single',
    },
    {
      value: 2,
      label: 'Married/De-facto',
    }];
  }

  getDependants() {
    return functions.getDependants();
  }

  getApplicants() {
    const applicants = [{
      value: '-1',
      disabled: true,
      label: 'Please select',
    },
    {
      value: '',
      label: 'Non-applicant',
    }];

    this.props.applicants.map((applicant, index) => {
      if (applicant.applicantId !== this.props.applicant.applicantId) {
        applicants.push({
          value: applicant.applicantId,
          label: applicant.name || `Applicant ${index + 1}`,
        });
      }
    });

    return applicants;
  }

  getOtherGroups() {
    return this.props.otherIncomeGroups;
  }

  getOtherTypes() {
    return this.props.otherIncomeTypes;
  }

  getFrequencies(index) {
    const frequencies = [];

    const incomeType = this.state.incomes[index].type;
    if (incomeType != 'selfEmployed') {
      frequencies.push({
        value: 4,
        label: 'Weekly',
      });
      frequencies.push({
        value: 3,
        label: 'Fortnightly',
      });
      frequencies.push({
        value: 2,
        label: 'Monthly',
      });
    }

    frequencies.push({
      value: 1,
      label: 'Annually',
    });

    return frequencies;
  }

  changeIncomeType(value, idx) {
    const newState = this.state;
    newState.incomes[idx].type = value;
    this.setState(newState);
  }

  changeOtherIncomeType(value, idx) {
    const newState = this.state;
    newState.incomes[idx].incomeOtherGroupId = this.getOtherTypes().filter((type) => type.typeId == value)[0].groupId;
    this.setState(newState);
  }

  addIncome(focus, income) {
    if (!income) {
      income = {
        incomeId: Guid.create().value,
        type: 'payg',
        incomeOtherGroupId: 1,
      };
    }

    if (!income.incomeId) {
      income.incomeId = Guid.create().value;
    }

    const newState = this.state;
    newState.incomes.push(income);

    this.setState(newState, () => {
      if (focus !== false) {
        document.getElementById(`applicants[${this.props.index}][incomes][${newState.incomes.length - 1}].type`).focus();
      }
    });
  }

  removeIncome(index) {
    const newState = this.state;
    newState.incomes.splice(index, 1);
    this.setState(newState);
  }

  removeApplicant() {
    this.props.removeApplicant(this.props.index);
  }

  handleName(value) {
    this.props.handleName(value, this.props.index);
  }

  handlePartner(value) {
    this.props.handlePartner(value, this.props.index);
  }

  handleRelationship(value) {
    this.props.handleRelationship(value, this.props.index);
  }

  getApplicantName() {
    const name = this.props.applicant.name || `applicant ${this.props.index + 1}`;
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  renderGrossIncome(incomeIndex) {
    return (
      <Col sm={3}>
        <div className="form-prefix">$</div>
        <Input
          id={`applicants[${this.props.index}][incomes][${incomeIndex}].amount`}
          name={`applicants[${this.props.index}][incomes][${incomeIndex}].amount`}
          ref={this.setRef(`applicants[${this.props.index}][incomes][${incomeIndex}].amount`)}
          type="text"
          label={(
            <span>
              {'Amount '}
              <small className="mute">(gross)</small>
            </span>
          )}
          value={0}
          changeDebounceInterval={0}
          updateOnBlur={false}
          blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
          validationError="Amount is required"
          validations={{
            isFormattedNumber: true,
            isRequired: true,
          }}
        />
      </Col>
    );
  }

  renderOtherIncomeType(incomeIndex) {
    return (
      <div>
        <Col sm={3}>
          <Input
            id={`applicants[${this.props.index}][incomes][${incomeIndex}].incomeOtherGroupId`}
            name={`applicants[${this.props.index}][incomes][${incomeIndex}].incomeOtherGroupId`}
            type="hidden"
            value={this.state.incomes[incomeIndex].incomeOtherGroupId}
          />
          <Select
            id={`applicants[${this.props.index}][incomes][${incomeIndex}].incomeOtherTypeId`}
            name={`applicants[${this.props.index}][incomes][${incomeIndex}].incomeOtherTypeId`}
            label="Type"
            options={this.getOtherTypes()}
            value={1}
            changeCallback={(name, value) => {
              this.changeOtherIncomeType(value, incomeIndex);
            }}
            className="form-control"
          />
        </Col>
      </div>
    );
  }

  renderBusinessIncome(incomeIndex) {
    return (
    <Col sm={8}>
      <Row>
        <Col sm={4}>
          <div className="form-prefix">$</div>
          <Input
            id={`applicants[${this.props.index}][incomes][${incomeIndex}].currentAmount`}
            name={`applicants[${this.props.index}][incomes][${incomeIndex}].currentAmount`}
            ref={this.setRef(`applicants[${this.props.index}][incomes][${incomeIndex}].currentAmount`)}
            type="text"
            label={(
              <span>
                {'Profit '}
                <small className="mute">(current year) (NPBT)</small>
              </span>
            )}
            value={0}
            changeDebounceInterval={0}
            updateOnBlur={false}
            blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs, { negative: true })}
            validationError="Profit is required"
            validations={{
              isFormattedNumber: true,
              isRequired: true,
            }}
          />
        </Col>
        <Col sm={4}>
          <div className="form-prefix">$</div>
          <Input
            id={`applicants[${this.props.index}][incomes][${incomeIndex}].currentDepreciation`}
            name={`applicants[${this.props.index}][incomes][${incomeIndex}].currentDepreciation`}
            ref={this.setRef(`applicants[${this.props.index}][incomes][${incomeIndex}].currentDepreciation`)}
            type="text"
            label="Depreciation"
            value={0}
            changeDebounceInterval={0}
            updateOnBlur={false}
            blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
            validationError="Depreciation must be a number"
            validations={{
              isFormattedNumber: true,
            }}
          />
        </Col>

        <Col sm={4}>
          <div className="form-prefix">$</div>
          <Input
            id={`applicants[${this.props.index}][incomes][${incomeIndex}].currentInterestPaid`}
            name={`applicants[${this.props.index}][incomes][${incomeIndex}].currentInterestPaid`}
            ref={this.setRef(`applicants[${this.props.index}][incomes][${incomeIndex}].currentInterestPaid`)}
            type="text"
            label="Interest"
            value={0}
            changeDebounceInterval={0}
            updateOnBlur={false}
            blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
            validationError="Interest paid must be a number"
            validations={{
              isFormattedNumber: true,
            }}
          />
          <i
            style={{ top: '6px', right: '12px' }}
            className="mute-lg a mi mi-help-outline pointer"
            data-position="fixed"
            data-event-on="click"
            data-tip="Can be included, if the facility it relates to has been closed or is being paid out."
          />
        </Col>
      </Row>
    </Col>
    );
  }

  renderIncomeFrequency(incomeIndex) {
    return (
    <Col sm={2}>
      <Select
        id={`applicants[${this.props.index}][incomes][${incomeIndex}].frequency`}
        name={`applicants[${this.props.index}][incomes][${incomeIndex}].frequency`}
        label="Frequency"
        options={this.getFrequencies(incomeIndex)}
        value={this.getFrequencies(incomeIndex).length > 1 ? 2 : 1}
        className="form-control"
      />
    </Col>
    );
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="card card-shallow card-stack incomes">

        <div className="panel-row">
          <h3 className="mt-sm ib">{this.getApplicantName()}</h3>


          <button
            id={`removeApplicant${this.props.index}`}
            type="button"
            className="btn btn-default btn-danger pull-right btn-circle"
            style={{ marginTop: '-4px', marginRight: '-8px' }}
            onClick={this.removeApplicant}
          >
            <Icon glyph="delete" />
          </button>
        </div>

        <Row className="panel-row">
          <Input
            id={`applicants[${this.props.index}].id`}
            name={`applicants[${this.props.index}].id`}
            ref={this.setRef(`applicants[${this.props.index}].id`)}
            value={this.props.applicant.applicantId}
            type="hidden"
          />

          <Col sm={2}>
            <Input
              id={`applicants[${this.props.index}].name`}
              name={`applicants[${this.props.index}].name`}
              ref={this.setRef(`applicants[${this.props.index}].name`)}
              value={this.props.applicant.name}
              label="Name"
              data-hj-masked
              blurCallback={(_, value) => {
                this.handleName(value);
              }}
            />
          </Col>

          <Col sm={4}>
            <Row>
              <Col xs={5}>
                <Select
                  id={`applicants[${this.props.index}].relationship`}
                  name={`applicants[${this.props.index}].relationship`}
                  ref={this.setRef(`applicants[${this.props.index}].relationship`)}
                  label="Relationship"
                  options={this.getRelationships()}
                  value={this.props.applicant.relationship || this.getRelationships()[0]}
                  changeCallback={(name, value) => {
                    this.handleRelationship(value);
                  }}
                  className="form-control"
                />
              </Col>

              <Col xs={7} className={`pl-lg ${this.props.applicant.relationship == 1 ? 'mute' : ''}`}>
                <span className="mute a" style={{ top: '39px', left: '-3px' }}>to</span>
                <Select
                  id={`applicants[${this.props.index}].inRelationshipWithId`}
                  name={`applicants[${this.props.index}].inRelationshipWithId`}
                  ref={this.setRef(`applicants[${this.props.index}].inRelationshipWithId`)}
                  label={<span>&nbsp;</span>}
                  data-hj-masked
                  disabled={this.props.applicant.relationship == 1}
                  options={this.getApplicants()}
                  validationError="Relationship is required"
                  className="form-control"
                  validations={{
                    isSelectedSingle: (values, value) => this.props.applicant.relationship == 1 || value != '-1',
                  }}
                  value={this.props.applicant.inRelationshipWithId || this.getApplicants()[0].value}
                  changeCallback={(_, value) => {
                    this.handlePartner(value);
                  }}
                />
                
              </Col>
            </Row>
          </Col>

          <Col sm={3}>
            <Row>
              <Col sm={7}>
                <Select
                  id={`applicants[${this.props.index}].dependants`}
                  name={`applicants[${this.props.index}].dependants`}
                  ref={this.setRef(`applicants[${this.props.index}].dependants`)}
                  value={this.props.applicant.dependants || '0'}
                  label="Dependants"
                  options={this.getDependants()}
                  validations={{
                    isRequired: true,
                  }}
                  className="form-control"
                />
                <i
                  style={{ top: '6px', right: '12px' }}
                  className="mute-lg a mi mi-help-outline pointer"
                  data-position="fixed"
                  data-event-on="click"
                  data-tip={`Total number of dependants living with ${this.getApplicantName()}`}
                />
              </Col>
              <Col sm={5}>
                <Input
                  id={`applicants[${this.props.index}].postcode`}
                  name={`applicants[${this.props.index}].postcode`}
                  ref={this.setRef(`applicants[${this.props.index}].postcode`)}
                  value={this.props.applicant.postcode}
                  label="Postcode"
                  validations={{
                    isPostcodeOptional: true,
                  }}
                />
                <i
                  style={{ top: '6px', right: '12px' }}
                  className="mute-lg a mi mi-help-outline pointer"
                  data-position="fixed"
                  data-event-on="click"
                  data-tip={`Postcode where ${this.getApplicantName()} will be living after settlement`}
                />
              </Col>
            </Row>
          </Col>


          <Col sm={2}>
            <div className="form-prefix">$</div>
            <Input
              id={`applicants[${this.props.index}].expenses`}
              name={`applicants[${this.props.index}].expenses`}
              ref={this.setRef(`applicants[${this.props.index}].expenses`)}
              value={this.props.applicant.expenses || ''}
              label="Living expenses"
              updateOnBlur={false}
              changeDebounceInterval={0}
              blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
              validations={{
                isFormattedNumberOptional: true,
              }}
            />
            <i
              style={{ top: '6px', right: '12px' }}
              className="mute-lg a mi mi-help-outline pointer"
              data-position="fixed"
              data-event-on="click"
              data-tip="Share of monthly living expenses"
            />
          </Col>

        </Row>

        {
          this.state.incomes.map((income, incomeIndex) => (
            <Row key={`income-${income.incomeId}`} className={!incomeIndex ? 'r panel-row mt-lg' : 'r panel-row'}>
              {!incomeIndex && (
              <h5 className="panel-row-title">
                <strong>
                  {'Income for '}
                  {this.getApplicantName()}
                </strong>
              </h5>
              )}

              <Col sm={3}>
                <Select
                  id={`applicants[${this.props.index}][incomes][${incomeIndex}].type`}
                  name={`applicants[${this.props.index}][incomes][${incomeIndex}].type`}
                  ref={this.setRef(`applicants[${this.props.index}][incomes][${incomeIndex}].type`)}
                  label="Type"
                  value="payg"
                  options={this.getIncomeTypes()}
                  changeCallback={(_, value) => {
                    this.changeIncomeType(value, incomeIndex);
                  }}
                  className="form-control"
                />
              </Col>

              {
                (income.type == 'payg' || income.type == 'other') && this.renderGrossIncome(incomeIndex)
              }

              {
                (income.type == 'other') && this.renderOtherIncomeType(incomeIndex)
              }

              {
                (income.type == 'payg' || income.type == 'other') && this.renderIncomeFrequency(incomeIndex)
              }

              {
                (income.type == 'selfEmployed') && this.renderBusinessIncome(incomeIndex)
              }
              
              <div>
                <button id={`removeIncome${this.props.index}_${incomeIndex}`} type="button" className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle" style={{ marginTop: '31px' }} onClick={() => { this.removeIncome(incomeIndex); }}>
                  <Icon glyph="delete" />
                </button>

                <Col sm={12} className="visible-xs text-right">
                  <button type="button" className="mb-md btn btn-danger" onClick={() => { this.removeIncome(incomeIndex); }}>
                    Remove income
                  </button>
                </Col>
              </div>

            </Row>
          ))
        }

        {
          (() => {
            if (this.state.incomes.length < 24) {
              return (
                <Row className="panel-row text-center">
                  <Col sm={12}>
                    <button type="button" id={`addIncome${this.props.index}`} className="btn btn-default" onClick={() => { this.addIncome(); }}>
                      <Icon glyph="plus" className="primary mute-lg mr-sm" />
                      {' '}
                      <span className="secondary">Add income</span>
                    </button>
                  </Col>
                </Row>
              );
            }
          })()
        }


      </div>
    );
  }
}

ComServiceAbilityApplicant.propTypes = {
  index: PropTypes.number.isRequired,
  applicant: PropTypes.object.isRequired,
  data: PropTypes.object,
  applicants: PropTypes.array.isRequired,
  otherIncomeGroups: PropTypes.array.isRequired,
  otherIncomeTypes: PropTypes.array.isRequired,
  removeApplicant: PropTypes.func.isRequired,
  handleName: PropTypes.func.isRequired,
  handlePartner: PropTypes.func.isRequired,
  handleRelationship: PropTypes.func.isRequired,
};

ComServiceAbilityApplicant.defaultProps = {
  data: {},
};

function mapStateToProps(state) {
  return {
    otherIncomeGroups: state.reference.otherIncomeGroups,
    otherIncomeTypes: state.reference.otherIncomeTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComServiceAbilityApplicant);
