import * as React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Icon } from '../../utils';

const MotorPrequalDeclinedResult = (): JSX.Element => {
    return (
        <Row>
            <Col sm={8} smOffset={2}>
                <div className="card text-left mb-lg mt-md">
                    <div className="text-center mt-lg">
                        <Icon glyph="assignment-o" className="mute-lg" size={6} />
                        <Icon glyph='close' className='text-danger a mt-lg' size={3} style={{ left: 0, right: 0 }} />
                    </div>
                    <div>
                        <Row>
                            <Col sm={12}>
                                <h3 className="text-center mb-md">Application will be declined.</h3>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
}


export default MotorPrequalDeclinedResult;