import { AxiosResponse } from "axios";
import { Dispatch } from "redux";

import event3rdParty from '../../helpers/event3rdParty';

import API from '../../api';

export type LvrAction =
    SetLvrFilterValue
  | ShowFineprint
  | HideFineprint
  | GetLvrData
  | GetLvrDataFailed
  | GetLvrDataSucceeded;

export enum LvrActionType {
  SetLvrFilterValue = "SetLvrFilterValue",

  GetLvrData = "GetLvrData",
  GetLvrDataFailed = "GetLvrDataFailed",
  GetLvrDataSucceeded = "GetLvrDataSucceeded",

  ShowFineprint = "ShowFineprint",
  HideFineprint = "HideFineprint"
}

export type LvrPurpose = "Investor" | "Owner Occupied";
export type LvrVerificationLevel = "Nova" | "Star" | "Private";
export type LvrPropertyType = "House" | "Unit" | "Other";
export type LvrRiskGrade = "AAA" | "AA" | "N/A" | "Custom";
export type LvrSwift = "-" | string;
export type LvrPostcode = "" | string;

export interface LvrFilter {
  verificationLevel: LvrVerificationLevel;
  purpose: LvrPurpose;
  propertyType: LvrPropertyType;
  riskGrade: LvrRiskGrade;
  swift: LvrSwift;
  postcode: LvrPostcode;
}

export interface LvrData extends LvrFilter {
  lVR: number;
}

interface LvrResponse extends AxiosResponse {
  data: LvrData[];
}

export type LvrFilterField = "propertyType" | "purpose" | "riskGrade" | "swift" | "verificationLevel" | "postcode";

interface SetLvrFilterValue {
  field: LvrFilterField;
  value: string;
  type: LvrActionType.SetLvrFilterValue;
}
interface ShowFineprint {
  type: LvrActionType.ShowFineprint;
}
interface HideFineprint {
  type: LvrActionType.HideFineprint;
}
interface GetLvrData {
  type: LvrActionType.GetLvrData;
}
interface GetLvrDataFailed {
  type: LvrActionType.GetLvrDataFailed;
}
interface GetLvrDataSucceeded {
  data: LvrData[];
  type: LvrActionType.GetLvrDataSucceeded;
}

export const showFineprint = () => (dispatch: Dispatch<ShowFineprint>) => {
  dispatch({
    type: LvrActionType.ShowFineprint
  });
}

export const hideFineprint = () => (dispatch: Dispatch<HideFineprint>) => {
  dispatch({
    type: LvrActionType.HideFineprint
  });
}

export const setLvrFilterValue = (field: LvrFilterField, value: string) => (dispatch: Dispatch<SetLvrFilterValue>) => {
  dispatch({
    field,
    value,
    type: LvrActionType.SetLvrFilterValue
  });
}

export const getLvrDataByPostcode = (postcode: string) => (dispatch: Dispatch<LvrAction>) => {
  if (postcode.length === 4) {
    API.getLvrDataByPostcode(postcode)
      .then((response: LvrResponse) => dispatch({
        type: LvrActionType.GetLvrDataSucceeded,
        data: response.data
      }))
      .catch(() => dispatch({
        type: LvrActionType.GetLvrDataFailed
      }));

    dispatch({
      type: LvrActionType.GetLvrData
    });

    event3rdParty.send('lvr');
  }

};
