import * as React from 'react';

import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import Title from '../title';

import {toOption, toRequired, firstOption} from '../functions';

import {IOption} from '../../../interfaces';

interface IDependantsState {
}

interface IDependantsProps {
  changeDependants: (_, value: number) => void;
  dependants: number[];
  index: number;
}

export class Dependants extends React.Component<IDependantsProps, IDependantsState> {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  get dependants(): IOption[] {
    let dependants: IOption[] = [];
    for (let i = 0; i <= 8; i++) {
      dependants.push({
        label: String(i),
        value: i
      });
    }
    return dependants;
  }

  render() {
    return (
      <div className="panel-section">
        <Title sub="Financial dependants">Dependants</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row>
            <Col sm={2}>
              <Select
                name={`individuals[${this.props.index}]._dependants`}
                id={`individuals[${this.props.index}]._dependants`}
                label={toRequired("Dependants")}
                options={this.dependants}
                value={firstOption(this.dependants)}
                className="form-control"
                changeCallback={this.props.changeDependants} />
            </Col>

            <Col sm={8}>
              <div className="form-group">
                { !!this.props.dependants.length && <label className="control-label">Age of dependants</label>}

                <Row>
                  {
                    this.props.dependants.map((_, index) => {
                      return (
                        <div key={`dependant_${index}`} className="form-group" style={{padding: '0 12px'}}>
                          <Col sm={1} className="pl-h pr-sm">
                            <Input
                              name={`individuals[${this.props.index}].dependants[${index}].age`}
                              className="form-control pr-h pl-sm"
                              maxLength={2}
                              value=""
                              layout="elementOnly"
                              data-test-name="dependant"
                            />
                          </Col>
                        </div>
                      )
                    })
                  }
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Dependants;
