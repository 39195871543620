/* eslint-disable no-shadow */
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, hashHistory } from 'react-router';
import { Location } from 'history';
import { RouteHook } from 'react-router/lib/Router';
import MotorSubmissionCommercialWebComponent from './commercial-web-component';
import { resetMotorDeclarations } from '../../actions/motorDeclarationsActions';
import { ISession } from '../../interfaces';
import { MotorDeclarationsState } from '../../reducers/motorDeclarationsReducer';


interface MotorSubmissionCommercialPageState {
  isStable: boolean;
  pendingLocation?: Location;
}

interface MotorSubmissionCommercialPageProps {
  resetMotorDeclarations: () => any;
}

const initialState: MotorSubmissionCommercialPageState = {
  isStable: true,
};

const onCancel = (router) => router.push('/applications');
const onComplete = (router, id: string) => router.push(`/applications/${id}`);
const onIdChanged = (newId: string) => hashHistory.push(`/applications/submit/commercial/${newId}`);
const onCancelNavigation = (setState) => setState((state) => ({ ...state, pendingLocation: undefined }));
const onStableChange = (setState, router, isStable: boolean, pendingLocation, resetMotorDeclarations: () => any) => {
  setState((state) => ({
    ...state,
    isStable,
  }));

  if (isStable && pendingLocation !== undefined) {
    resetMotorDeclarations();

    // if stable and there is a pending location, then go to that location
    router.replace(pendingLocation);
  }
};
// eslint-disable-next-line no-shadow
const routerWillLeave = (state: MotorSubmissionCommercialPageState, setState, resetMotorDeclarations: () => any): RouteHook => (nextLocation?: Location) => {
  if (nextLocation && !state.isStable) {
    // don't allow navigation yet, wait until component is in a stable state
    // eslint-disable-next-line no-shadow
    setState((state: MotorSubmissionCommercialPageState) => ({
      ...state,
      pendingLocation: nextLocation,
    }));
    return false;
  } if (!nextLocation && !state.isStable) {
    // trying to close/navigate away from the site
    // have to return a message (cannot perform async action)
    return 'You have unsaved changes, are you sure you want to exit?';
  }

  resetMotorDeclarations();
  return null; // don't do anything, allow navigation
};

export const MotorSubmissionCommercialPage = (props: RouteComponentProps<{ id?: string; }, {}> & MotorSubmissionCommercialPageProps) => {
  const [state, setState] = React.useState(initialState);

  // register leave hook
  React.useEffect(() => props.router.setRouteLeaveHook(props.route, routerWillLeave(state, setState, props.resetMotorDeclarations)), [props.router, state]);
  return (
    <div className="no-blur" style={{ marginTop: '-8px' }}>
      <MotorSubmissionCommercialWebComponent
        loadApplicationId={props.params.id}
        isLeaving={!!state.pendingLocation}
        onCancel={() => onCancel(props.router)}
        onComplete={(id) => onComplete(props.router, id)}
        onStableChange={(s) => onStableChange(setState, props.router, s, state.pendingLocation, props.resetMotorDeclarations)}
        onIdChanged={(id) => onIdChanged(id)}
        onCancelNavigation={() => onCancelNavigation(setState)}
      />
    </div>
  );
};

const mapStateToProps = ({ session, motorDeclarations }: { session: ISession; motorDeclarations: MotorDeclarationsState }) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetMotorDeclarations: () => dispatch(resetMotorDeclarations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MotorSubmissionCommercialPage));
