import { PersonApplicant } from "../models/commonApiModels";
import { mapApplicant } from "./common";
import { 
  PropertyUse, 
  LoanTerm3Year, 
  LoanTerm5Year, 
  MortgageFreeProduct, 
  MortgageFreeVerificationLevel, 
  MortgageLoanStructure, 
  MortgageRateQuoteQueryInfo, 
  MortgageSharpProduct, 
  MortgageSharpVerificationLevel, 
  MortgageStarProduct, 
  MortgageStarVerificationLevel,
  ResidentialRateQuoteQuery 
} from "../models/residentialApiModels";

const mapPurpose = (purpose: number): PropertyUse => {
  if (purpose === 0) return PropertyUse.OwnerOccupied
  if (purpose === 1) return PropertyUse.Investor
  if (purpose === 2) return PropertyUse.ProgressionalInvestor
  return PropertyUse.Unknown
}

const map3YearLoanTerm = (loanTerm: number): LoanTerm3Year => {
  if (loanTerm === 0) return LoanTerm3Year.Variable;
  if (loanTerm === 1) return LoanTerm3Year.FixedOneYear;
  if (loanTerm === 2) return LoanTerm3Year.FixedTwoYear;
  if (loanTerm === 3) return LoanTerm3Year.FixedThreeYear;
  return LoanTerm3Year.Unknown;
};
const map5YearLoanTerm = (loanTerm: number): LoanTerm5Year => {
  if (loanTerm === 0) return LoanTerm5Year.Variable;
  if (loanTerm === 1) return LoanTerm5Year.FixedOneYear;
  if (loanTerm === 2) return LoanTerm5Year.FixedTwoYear;
  if (loanTerm === 3) return LoanTerm5Year.FixedThreeYear;
  if (loanTerm === 4) return LoanTerm5Year.FixedFourYear;
  if (loanTerm === 5) return LoanTerm5Year.FixedFiveYear;
  return LoanTerm5Year.Unknown;
};

const mapStarVerificationLevel = (verificationLevel: number): MortgageStarVerificationLevel => {
  if (verificationLevel === 0) return MortgageStarVerificationLevel.FullDoc
  if (verificationLevel === 1) return MortgageStarVerificationLevel.LowDoc
  if (verificationLevel === 2) return MortgageStarVerificationLevel.NoDoc
  return MortgageStarVerificationLevel.Unknown;
}

const mapSharpVerificationLevel = (verificationLevel: number): MortgageSharpVerificationLevel => {
  if (verificationLevel === 0) return MortgageSharpVerificationLevel.FullDoc
  return MortgageSharpVerificationLevel.Unknown;
}

const mapFreeVerificationLevel = (verificationLevel: number): MortgageFreeVerificationLevel => {
  if (verificationLevel === 0) return MortgageFreeVerificationLevel.FullDoc
  if (verificationLevel === 1) return MortgageFreeVerificationLevel.LowDoc
  return MortgageFreeVerificationLevel.Unknown;
}

const mapRepaymentType = (repaymentType: number): number => {
  switch (repaymentType) {
    case 0:
      return 1
    case 1:
      return 2
    default:
      throw("Incorrect repayment type")
  }
}

const mapProduct = (rateLoanDetails: any): MortgageStarProduct | MortgageSharpProduct | MortgageFreeProduct => {
  const mortageDetails = {
    LoanAmount: rateLoanDetails.loanAmount,
    IsSwift: rateLoanDetails.isSwift,
    Purpose: mapPurpose(rateLoanDetails.purpose),
    SecurityValue: rateLoanDetails.purchaseAmount
  }

  if (rateLoanDetails.productGroupId === 5000)
    return {
      $type: 'MortgageStarProduct',
      VerificationLevel: mapStarVerificationLevel(rateLoanDetails.verification),
      LoanStructure:  {
        $type: 'Max5YearFixTermLoan',
        LoanTermYear: map5YearLoanTerm(rateLoanDetails.structure),
        ...mortageDetails
      },
      RepaymentType: mapRepaymentType(rateLoanDetails.repayment)
    };
  if (rateLoanDetails.productGroupId === 5500)
    return {
      $type: 'MortgageSharpProduct',
      VerificationLevel: mapSharpVerificationLevel(rateLoanDetails.verification),
      LoanStructure:  {
        $type: 'Max5YearFixTermLoan',
        LoanTermYear: map5YearLoanTerm(rateLoanDetails.structure),
        ...mortageDetails
      },
      RepaymentType: mapRepaymentType(rateLoanDetails.repayment)
    };
    
  return {
    $type: 'MortgageFreeProduct',
    VerificationLevel: mapFreeVerificationLevel(rateLoanDetails.verification),
    LoanStructure:  {
      $type: 'Max3YearFixTermLoan',
      LoanTermYear: map3YearLoanTerm(rateLoanDetails.structure),
      ...mortageDetails
    },
    RepaymentType: mapRepaymentType(rateLoanDetails.repayment)
  };
};

const mapResidentialApplication = (requestId, data): ResidentialRateQuoteQuery =>{
  return ({
    RequestId: requestId,
    QueryInfo: {
      $type: 'MortgageRateQuoteQueryInfo',
      Product: mapProduct(data.rateLoanDetails),
      Applicants: data.applicants?.map(x => mapApplicant(x, false))
    }
  });
}

export default mapResidentialApplication;
