import * as React from 'react';
import { Icon, Loadr } from '../../utils';
import { Document } from '../../actions/documentsActions';

type DocumentItemProps = {
  document: Document,
  group: string,
  getReferenceDocument: (file: Document, group: string) => void
}

const DocumentItem = (props: DocumentItemProps) => (
  <tr
    id={props.document.fileName.split('.')[0]}
    onClick={() => { props.getReferenceDocument(props.document, props.group); }}
    className={`document-item ${props.document.isProcessing ? 'disabled' : ''}`}
  >
    <td style={{ width: '10px' }}>
      <Icon glyph="file-text" color="primary-100" size="lg" className="ml-sm" style={{ marginTop: '12px' }} />
    </td>
    <td>
      <span className={`b ${!props.document.description ? 'mt-sm' : ''}`}>{props.document.name}</span>
      {
        (() => {
          if (props.document.description) {
            return (
              <small className="mute">{props.document.description}</small>
            );
          }
        })()
      }
    </td>
    <td className="r" style={{ overflow: 'hidden', width: '54px' }}>
      <Icon glyph="download" color="primary-100" size="lg" className="a animate-slow ml-md mr-md" style={{ top: props.document.isProcessing ? '160px' : '22px', right: '4px' }} />
      <Loadr sm style={{ right: '12px' }} className={props.document.isProcessing ? 'visible' : 'transparent'} />
    </td>
  </tr>
);

export default DocumentItem;
