import * as React from 'react';
import PropTypes from 'prop-types';

enum AlertType {
  Success = "success",
  Warning = "warning",
  Error = "danger",
  Info = "info"
}

interface AlertProps extends BaseAlertProps {
  type: AlertType;
}

interface BaseAlertProps {
  title?: string;
  children: string | JSX.Element;

  id?: string;
}

const getClassName = (type: AlertType): string => {
  return `alert alert-${type}`;
}

const AlertTitle = ({children}) => children ? <div className="alert-title">{children}</div> : null;
const AlertBody = ({children}) => <div className="alert-body">{children}</div>;

const Alert = (props: AlertProps) => {
  return (
    <div className={getClassName(props.type)} {...props}>
      <AlertTitle>{props.title}</AlertTitle>
      <AlertBody>{props.children}</AlertBody>
    </div>
  );
}

export const SuccessAlert = (props: BaseAlertProps) => <Alert type={AlertType.Success} {...props} />;
export const WarningAlert = (props: BaseAlertProps) => <Alert type={AlertType.Warning} {...props} />;
export const ErrorAlert = (props: BaseAlertProps) => <Alert type={AlertType.Error} {...props} />;
export const InfoAlert = (props: BaseAlertProps) => <Alert type={AlertType.Info} {...props} />;
