import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class ComServiceAbilityModal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm mute">
            <h4 className="mb-lg">About the results</h4>
            <p>The results from this calculator are indicative only and do not take all of the applicant’s individual circumstances into account. The results displayed are based on the information provided and do not represent a quote, prequalification or approval for a loan. All applications are subject to Liberty’s usual credit assessment criteria. </p>

            <h4 className="mt-lg mb-lg">Assumptions we make </h4>
            <p>This calculator also makes a number of assumptions which affect how reliable the results are. The main assumptions are explained below.</p>

            <ul>
              <li>HEMS will be calculated if sufficient information is provided. If no monthly living expense is entered, the HEMS figure will be used in servicing. If you enter the applicants’ monthly living expenses at a figure that is less than HEMS, we will apply the higher HEMS figure by default. If there is insufficient information to calculate HEMS and no living expense is entered, living expenses will be excluded from serviceability.</li>
              <li>If you enter a credit card limit as an ongoing financial commitment, we will assume the applicant’s monthly credit card repayments are 3.8% of their total credit card limit.</li>
              <li>We apply a 2% interest rate buffer to the calculation in order to take into account future interest rate increases.</li>
              <li>When calculating serviceability on an IO basis all repayments are calculated on an IO basis.</li>
              <li>The suggested figure your customer could service is based upon the information provided and may change.</li>
            </ul>

            <h4 className="mt-lg mb-lg">What about fees and charges?</h4>
            <p>This calculator does not take into account all fees, charges or other amounts that may be charged to a loan. Additional fees and charges may increase repayment amounts. Full terms and conditions will be set out in any loan offer (if an offer is made). Further product information including current interest rates, fees and charges can be found in the relevant Introducer Guides available in the ‘Documents’ tab.</p>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

ComServiceAbilityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};
