import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';
import { hashHistory } from 'react-router';

import Moment from 'moment';

import { Guid } from 'guid-typescript';

import Event3rdParty from '../../helpers/event3rdParty';

import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';
import Mask from '../../helpers/mask';

import { Overlay } from '../../utils';
import * as referenceActions from '../../actions/referenceActions';
import * as calculatorsActions from '../../actions/calculatorsActions';
import * as storageActions from '../../actions/storageActions';

import { setPrequalResult } from './actions';

import CalculatorModal from '../../components/calculatorModal';
import MotorPrequalToolHeader from './motorPrequalToolHeader';
import MotorPrequalToolApplicants from './motorPrequalToolApplicants';
import MotorPrequalToolAssets from './motorPrequalToolAssets';
import MotorPrequalToolLoanDetails from './motorPrequalToolLoanDetails';
import MotorPrequalToolResult from './motorPrequalToolResult';
import MotorPrequalCalculateButton from './motorPrequalFooter';
import { mapApplication } from './motorPrequalAPIDataMapping';
import { ProfileType } from '../../actions/prequalActions';


export class MotorPrequalToolPage extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      loanTerm: 0,
      loanAmount: 0,
      modal: false,
      profileName: '',
      processing: false,
      application: null,
      count: 0,
      delay: 1000,
      requestId: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.savePrequal = this.savePrequal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setPrequal = this.setPrequal.bind(this);
    this.convertApplication = this.convertApplication.bind(this);

    this.setProcessing = this.setProcessing.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.id) {
      this.props.actions.getPrequal(this.props.params.id)
        .then((result) => {
          if (result.data) {
            this.setState({
              init: true,
              profileName: result.data.profileName,
              profileId: result.data.profileId,
              lastUpdated: result.data.lastUpdated,
              application: { ...this.state.data, ...JSON.parse(result.data.value) },
            }, this.setPrequal);
          } else {
            this.setState({
              init: true,
            });
          }
        });
    } else {
      this.setState({
        init: true,
      });
    }
  }

  componentDidMount() {
    this.getOtherIncomeTypes();
  }

  stopPolling = () => {
    this.state.count = 0;
    clearInterval(this.interval);
  }
  
  pollRateQuote = () => {
    this.props.actions.trackRateQuote(this.state.requestId)
      .then(({ data }) => {
        if (this.state.count > 120) { // stop polling and timeout after 2 minutes 
          Notify.show('The request timed out.', 'error', 3000);
          return true; 
        }
        let stopPolling = true;
        switch (data?.status) {
          case 'Successful':
            this.props.actions.setPrequalResult({
              ...data.result,
            });
            break;
          case 'InProgress':
          case 'FailedButRetrying':
            this.setState({
              count: this.state.count + 1,
            });
            stopPolling = false;
            break;
          case 'Failed':
            Notify.show('An error occurred.', 'error', 3000);
            break;
          default:
            Notify.show('An unexpected error occured.', 'error', 3000);
            break;
        }
        return stopPolling;
      })
      .then((result) => { 
        if (result == true) {
          this.setProcessing(false);
          this.stopPolling();
        }
      })
      .catch(() => {
        this.setProcessing(false);
        this.stopPolling();
        Notify.show('An error occured. ', 'error', 3000);
      });
  }

  handleDelayChange = (e) => {
    this.setState({ delay: Number(e.target.value) });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  getOtherIncomeTypes() {
    this.props.actions.getOtherIncomeTypes();
  }

  mapData(data) {
    if (data.applicants) {
      data.applicants.map((applicant, index) => {
        data.applicants[index].key = index + 1;

        // Date of Birth
        if (data.applicants[index].dateOfBirth && data.applicants[index].dateOfBirth !== '') {
          data.applicants[index].dateOfBirth = Moment.utc(data.applicants[index].dateOfBirth, 'DD/MM/YYYY');
        } else {
          data.applicants[index].dateOfBirth = null;
        }

        data.applicants[index].gender = data.applicants[index].gender == 'M' || data.applicants[index].gender == 'F' ? data.applicants[index].gender : null;

        data.applicants[index].dependants = parseInt(data.applicants[index].dependants);
        data.applicants[index].dependants = isNaN(data.applicants[index].dependants) ? null : data.applicants[index].dependants;

        // living expenses
        if (applicant.expenses) {
          applicant.expenses.statedExpenses = parseFloat(Mask.clean(applicant.expenses.statedExpenses));
        }

        // occupancy start
        if (applicant.occupancies) {
          applicant.occupancies.map((occupancy) => {
            occupancy.years = parseInt(occupancy.years) || 0;
            occupancy.months = parseInt(occupancy.months) || 0;
          });
        }
        // occupancy end

        // income start
        if (applicant.incomes && applicant.incomes.length > 0) {
          applicant.incomes.map((income) => {
            income.amount = parseFloat(Mask.clean(income.amount));
            income.frequency = parseInt(income.frequency);
            income.incomeOtherGroupId = parseInt(income.incomeOtherGroupId);
            income.incomeOtherTypeId = parseInt(income.incomeOtherTypeId);
          });
        } else {
          applicant.incomes = [];
        }
        // income end

        // employment history start
        if (applicant.employments && applicant.employments.length > 0) {
          applicant.employments.map((employment) => {
            employment.years = parseInt(employment.years) || 0;
            employment.months = parseInt(employment.months) || 0;

            if (employment.endDate && employment.endDate != '') {
              employment.endDate = Moment(employment.endDate, 'MM/YYYY');
            } else {
              employment.endDate = null;
            }
          });
        } else {
          applicant.employments = [];
        }
        // employment history end

        // credit history start
        // creditType, eventtype, status, amount, dateIncurred, dateRectified
        if (applicant.creditHistory) {
          applicant.creditHistory.vedaScore = (!!applicant.creditHistory.vedaScore) ? (parseFloat(Mask.clean(applicant.creditHistory.vedaScore)) || null) : null;
          applicant.creditHistory.numberOfEnquiries = parseFloat(applicant.creditHistory.numberOfEnquiries) >= 0 ? parseFloat(applicant.creditHistory.numberOfEnquiries) : null;

          // credit events
          if (applicant.creditHistory.creditEvents && applicant.creditHistory.creditEvents.length) {
            applicant.creditHistory.creditEvents.map((creditEvent) => {
              if (creditEvent.amount) {
                creditEvent.amount = parseFloat(Mask.clean(creditEvent.amount));
              }

              if (creditEvent.dateIncurred && creditEvent.dateIncurred !== '') {
                creditEvent.dateIncurred = Moment.utc(creditEvent.dateIncurred, 'DD/MM/YYYY');
              } else {
                creditEvent.dateIncurred = null;
              }

              if (creditEvent.dateRectified && creditEvent.dateRectified !== '') {
                creditEvent.dateRectified = Moment.utc(creditEvent.dateRectified, 'DD/MM/YYYY');
              } else {
                creditEvent.dateRectified = null;
              }

              // format dateIncurred, dateRectified
            });
          } else {
            applicant.creditHistory.creditEvents = [];
          }
        } else {
          applicant.creditHistory.vedaScore = null;
          applicant.creditHistory.numberOfEnquiries = null;
          applicant.creditHistory.creditEvents = [];
        }
        // credit history end
      });
    }
    // applicants end

    // loan details start
    data.loanDetails.loanTermInMonths = parseInt(data.loanDetails.loanTermInYears * 12);
    data.loanDetails.loanTermInYears = parseInt(data.loanDetails.loanTermInYears);

    data.loanDetails.loanAmount.purchasePrice = parseFloat(Mask.clean(data.loanDetails.loanAmount.purchasePrice));
    data.loanDetails.loanAmount.deposit = parseFloat(Mask.clean(data.loanDetails.loanAmount.deposit)) || 0;
    data.loanDetails.loanAmount.netTradeIn = parseFloat(Mask.clean(data.loanDetails.loanAmount.netTradeIn)) || 0;
    data.loanDetails.loanAmount.comprehensiveInsurance = parseFloat(Mask.clean(data.loanDetails.loanAmount.comprehensiveInsurance)) || 0;
    data.loanDetails.loanAmount.extendedWarranty = parseFloat(Mask.clean(data.loanDetails.loanAmount.extendedWarranty)) || 0;
    data.loanDetails.loanAmount.applicationFee = parseFloat(Mask.clean(data.loanDetails.loanAmount.applicationFee)) || 0;

    data.loanDetails.loanAmount.vei = data.loanDetails.loanAmount.hasVei ? parseFloat(Mask.clean(data.loanDetails.loanAmount.vei)) || 0 : 0;
    delete data.loanDetails.loanAmount.hasVei;

    data.loanDetails.lpiProductNumber = data.loanDetails.hasLpi ? parseInt(data.loanDetails.lpiProductNumber) || null : null;
    delete data.loanDetails.hasLpi;

    // assets start
    if (data.assets && data.assets.length > 0) {
      data.assets.map((asset) => {
        asset.value = parseFloat(Mask.clean(asset.value));
      });
    } else {
      data.assets = [];
    }
    // assets end

    // financial commitments start
    if (data.liabilities && data.liabilities.length > 0) {
      data.liabilities.map((liability) => {
        liability.repayment = !!(liability.repayment) ? parseFloat(Mask.clean(liability.repayment)) : null;
        liability.balance = !!(liability.balance) ? parseFloat(Mask.clean(liability.balance)) : null;

        if (liability.type == 'creditcard') {
          liability.limit = !!(liability.limit) ? parseFloat(Mask.clean(liability.limit)) : null;
        }
      });
    } else {
      data.liabilities = [];
    }

    return data;
  }


  handleSubmit(data) {   
    const application = this.mapData(data);
    this.setState({ application }, () => {
      Event3rdParty.send('prequalification');

      this.setProcessing(true);
      this.setState({ requestId: Guid.create().toString() }, () => {
        const rateQuoteQuery = mapApplication(this.state.requestId, application);
        this.props.actions.submitRateQuote(rateQuoteQuery)
          .then(() => {
            this.interval = setInterval(this.pollRateQuote, this.state.delay);
          })
          .catch(() => {
            this.setProcessing(false);
            Notify.show('An error occured. ', 'error', 3000);
          });
      });     
    });
  }

  setPrequal() {
    if (this.state.application) {
      const data = Mask.flatten(this.state.application);

      const result = Object.keys(data).filter((k) => k.toLowerCase().includes('dateofbirth'));
      result.forEach((x) => data[x] = Moment(data[x]).format('DD/MM/YYYY'));
   
      this.form.formsyForm.current.reset(data);
    }
  }

  handleInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  savePrequal(route) {
    if (!this.state.profileName) {
      this.showModal(true);
    } else {
      this.setProcessing('Saving prequalification...');

      const data = this.mapData(this.form.formsyForm.current.getModel()) || {};
      const payload = this.props.session.details.isDev ? data : JSON.stringify(data);
      const profileId = this.state.profileId || Guid.create().value;
      
      this.props.actions.savePrequal(this.state.profileName, payload, profileId, ProfileType.MotorRateQuote)
        .then(() => {
          Notify.show(messages.MESS_SUCCESS, 'success');
          this.setState({
            profileId,
            hasChanged: false,
          }, () => {
            if (route) {
              hashHistory.push(route);
            }
          });
        })
        .catch()
        .then(() => {
          this.setProcessing(false);
        });
    }
  }

  showModal(modal) {
    this.state.profileId
      ? this.savePrequal() 
      : this.setState({
        modal,
      });
  }

  setProfile(data) {    
    this.setState({
      profileName: data.name,
    },
    this.savePrequal);
  }

  convertApplication() {
    const data = this.mapData(this.form.formsyForm.current.getModel());
    this.props.actions.addPrequal(data);
    this.props.router.push(`/applications/submit/motor?rateQuoteId=${this.state.profileId || Guid.create().value}`);
  }

  render() {
    return (
      <>
      <CalculatorModal visible={this.state.modal} setModal={this.showModal} setProfile={this.setProfile} />
        <div className="main-panel mt-sm-n">
          {
                  (() => {
                    if (this.state.processing) {
                      return (
                            <Overlay>Calculating...</Overlay>
                      );
                    }
                  })()
              }
          <Form
            onValidSubmit={this.handleSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            layout="vertical"
            ref={(form) => { this.form = form; }}
            id="prequalForm"
          >
            <div className="panel panel-default">
              <div className="panel-body p-h">
                <MotorPrequalToolHeader />
                <MotorPrequalToolApplicants data={this.state.application} />
                <MotorPrequalToolAssets application={this.state.application} formsy={this.form?.formsyForm?.current} />
                <MotorPrequalToolLoanDetails application={this.state.application} formsy={this.form?.formsyForm?.current} />
                <MotorPrequalToolResult />
                <MotorPrequalCalculateButton 
                  id={this.props.params.id}
                  convertApplication={this.convertApplication}
                  showModal={this.showModal} />
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

MotorPrequalToolPage.propTypes = {
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
    calculators: state.calculators,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...referenceActions, ...calculatorsActions, ...storageActions, setPrequalResult,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MotorPrequalToolPage);
