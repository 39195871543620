import * as React from 'react';
import { connect } from 'react-redux';
import { Guid } from 'guid-typescript';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { PRODUCTGROUPS } from '../../constants/types';
import { IProduct, ISession } from '../../interfaces';
import { confirmMotorDeclarations } from '../../actions/motorDeclarationsActions';

interface SupportAsessTypeProductGroup {
  id: Guid;
  productGroupId: number;
  displayName: string;
  description: string;
  icon: string;
  path: string;
}

interface AssetTypeSelectionPopupProps {
  shouldShow: boolean;
  onHide: () => void;
  accreditedProducts: IProduct[];
  confirmMotorDeclarations: () => any;
}

interface AssetTypeSelectionPopupState {
  selectedProductGroup: number;
  accreditedAssetTypeProducts: SupportAsessTypeProductGroup[];
}

const supportedAssetTypeProductGroups: SupportAsessTypeProductGroup[] = [{
  id: Guid.create(),
  productGroupId: PRODUCTGROUPS.MOTOR_CONSUMER,
  displayName: 'Consumer Motor',
  description: 'Financing a car',
  icon: 'fa-car-side',
  path: '/motor',
},
{
  id: Guid.create(),
  productGroupId: PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN,
  displayName: 'Consumer Caravan',
  description: 'Financing a caravan',
  icon: 'fa-caravan',
  path: '/caravan',
},
{
  id: Guid.create(),
  productGroupId: PRODUCTGROUPS.MOTOR_COMMERCIAL,
  displayName: 'Commercial Motor',
  description: 'Financing a commercial car',
  icon: 'fa-truck-pickup',
  path: '/commercial',
},
{
  id: Guid.create(),
  productGroupId: PRODUCTGROUPS.MOTOR_NOVATED_LEASE,
  displayName: 'Novated Lease',
  description: 'Novated Lease',
  icon: 'fa-car-side',
  path: '/novated',
}];

const filterSupportedAssetTypeProductGroups = (accreditedProducts: IProduct[]) => supportedAssetTypeProductGroups.filter((c) => accreditedProducts.find((p) => p.productGroupId == c.productGroupId));

export class AssetTypeSelectionPopup extends React.Component<RouteComponentProps<{}, {}> & AssetTypeSelectionPopupProps, AssetTypeSelectionPopupState> {
  constructor(props) {
    super(props);

    this.state = {
      accreditedAssetTypeProducts: props.accreditedProducts ? filterSupportedAssetTypeProductGroups(props.accreditedProducts) : [],
      selectedProductGroup: PRODUCTGROUPS.MOTOR_CONSUMER
    };

    this.onProductTypeChange = this.onProductTypeChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  static getDerivedStateFromProps(nextProps: AssetTypeSelectionPopupProps, prevState: AssetTypeSelectionPopupState): AssetTypeSelectionPopupState {
    if (!nextProps.accreditedProducts) {
      return prevState;
    }
    
    const accreditedAssetTypeProducts = filterSupportedAssetTypeProductGroups(nextProps.accreditedProducts);

    return {
      ...prevState,
      accreditedAssetTypeProducts,
    };
  }

  onProductTypeChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      selectedProductGroup: parseInt(e.currentTarget.value),
    });
  }

  onConfirm() {
    this.props.confirmMotorDeclarations();

    const link = this.state.accreditedAssetTypeProducts.find((a) => a.productGroupId === this.state.selectedProductGroup)?.path;
    this.props.router.push(`applications/submit${link}`);
  }

  // eslint-disable-next-line no-undef
  render(): JSX.Element {
    return (
      <Modal
        show={this.props.shouldShow}
        onHide={this.props.onHide}
        className="asset-type-modal"
      >
        <Modal.Header>
          <i className="fal fa-caravan fa-3x" />
          <i className="fal fa-car-side fa-3x" />
          <i className="fal fa-shuttle-van fa-4x" />
          <i className="fal fa-truck fa-4x" />
        </Modal.Header>
        <Modal.Body>
          <div className="modal-title">
            <h4>Before we start</h4>
            <h5>Please select an asset type</h5>
          </div>
          <div className="asset-type-options">
            { this.state.accreditedAssetTypeProducts.map((product) => (
              <div className="custom-control control-image" key={product.id.toString()}>
                <input type="radio" className="custom-control-input" name="assetType" id={product.id.toString()} checked={this.state.selectedProductGroup === product.productGroupId} value={product.productGroupId} onChange={this.onProductTypeChange} />
                <label className="custom-control-label asset-type-options-item" htmlFor={product.id.toString()}>
                  <div className="option-image">
                    <i className={`fal ${product.icon} fa-2x`} />
                  </div>
                  <div className="option-info">
                    <p className="option-info-name">{product.displayName}</p>
                    <p className="option-info-description">{product.description}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>
          { this.state.selectedProductGroup
                  && (
                    <>
                      <h5>Just a few bits and pieces to note</h5>
                      <section className="border-bottom mb-7 pb-5">
                        <p>Please confirm the applicant(s) are:</p>
                        <div className="d-flex justify-content-center">
                          <ul className="confirm">
                            <li>
                              <i className="fal fa-info-circle" />
                              {' '}
Permanent resident or citizen
                            </li>
                            <li>
                              <i className="fal fa-info-circle" />
                              {' '}
18 years or older
                            </li>
                          </ul>
                        </div>
                      </section>
                      <section className="border-bottom mb-7 pb-5">
                        <p>We'd love to hear your thoughts! To relay your comments, suggestions or feedback, please use the feedback feature at the bottom of the applications.</p>
                      </section>
                    </>
                  )}
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-link" onClick={this.props.onHide}>Cancel</button>
            <button type="button" className="btn btn-success ml-3" onClick={this.onConfirm}>Confirm</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ session }: { session: ISession }) => ({
  accreditedProducts: session.details.products,
});

const mapDispatchToProps = (dispatch) => ({
  confirmMotorDeclarations: () => dispatch(confirmMotorDeclarations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetTypeSelectionPopup));
