import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import ReactTooltip from 'react-tooltip';

import * as functions from '../../constants/functions';

import Mask from '../../helpers/mask';

export default class ServiceAbilityProduct extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      'rateLoanDetails.purpose': 0,
      'rateLoanDetails.riskGrade': 'AAA',
      'rateLoanDetails.verification': 0,
      'rateLoanDetails.structure': 0,
      'rateLoanDetails.repayment': 0,
      'rateLoanDetails.isSwift': false,
      'rateLoanDetails.purchaseAmount': 0,
      'rateLoanDetails.loanAmount': 0,
      'rateLoanDetails.productGroupId': 5000,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props && this.props.data && this.props.data.rateLoanDetails) {
      const { state } = this;
      state['rateLoanDetails.purpose'] = this.props.data.rateLoanDetails.purpose;
      state['rateLoanDetails.riskGrade'] = this.props.data.rateLoanDetails.riskGrade;
      state['rateLoanDetails.isSwift'] = this.props.data.rateLoanDetails.isSwift;
      state['rateLoanDetails.structure'] = this.props.data.rateLoanDetails.structure;
      state['rateLoanDetails.repayment'] = this.props.data.rateLoanDetails.repayment;
      state['rateLoanDetails.verification'] = this.props.data.rateLoanDetails.verification;
      state['rateLoanDetails.purchaseAmount'] = this.props.data.rateLoanDetails.purchaseAmount;
      state['rateLoanDetails.loanAmount'] = this.props.data.rateLoanDetails.loanAmount;
      state['rateLoanDetails.productGroupId'] = this.props.data.rateLoanDetails.productGroupId;
      this.setState(state);
    }
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  handleChange(field, value) {
    const { state } = this;
    state[field] = value;
    this.setState(state, this.props.handleChange);
  }

  getStructuresForProduct(verification, productGroupId) {
    const structures = [];
    structures.push({
      value: 0,
      label: 'Variable',
    });
    
    // Fixed rates available only for full doc (0) Star (5000)
    if (verification == 0 && productGroupId == 5000) {
      structures.push({
        value: 1,
        label: 'Fixed for 1 year',
      });
      structures.push({
        value: 2,
        label: 'Fixed for 2 years',
      });
      structures.push({
        value: 3,
        label: 'Fixed for 3 years',
      });
    }
    return structures;
  }

  getResiProducts() {
    return functions.getResiProducts(parseInt(this.state['rateLoanDetails.verification']));
  }

  getVerificationLevels() {
    return [{
      value: 0,
      label: 'Full Doc',
    }, {
      value: 1,
      label: 'Low Doc',
    }, {
      value: 2,
      label: 'No Doc',
    }];
  }

  getRepayments() {
    return [{
      value: 0,
      label: 'Principal and interest',
    }, {
      value: 1,
      label: 'Interest only',
    }];
  }

  getStructures() {
    return this.getStructuresForProduct(parseInt(this.state['rateLoanDetails.verification']), parseInt(this.state['rateLoanDetails.productGroupId']));
  }

  getLoanPurposes() {
    return [{
      value: 0,
      label: 'Owner occupied',
    }, {
      value: 1,
      label: 'Investor',
    }, {
      value: 2,
      label: 'Professional investor',
    }];
  }

  getLoanAmountMax(productType, verificationLevel, lvr) {
    let maxLoanAmount = '2,500,000';
    if (productType === 5000 || productType === 5600) {
      if (verificationLevel === 0) { // Full Doc
        if (lvr <= 0.6) {
          maxLoanAmount = '5,000,000';
        }
        if (lvr > 0.6 && lvr <= 0.65) {
          maxLoanAmount = '4,000,000';
        }
        if (lvr > 0.65 && lvr <= 0.75) {
          maxLoanAmount = '3,000,000';
        }
        if (lvr > 0.75 && lvr <= 0.8) {
          maxLoanAmount = '2,500,000';
        }
        if (lvr > 0.8 && lvr <= 0.9) {
          maxLoanAmount = '2,000,000';
        }
        if (lvr > 0.9 && lvr <= 0.95) {
          maxLoanAmount = '1,000,000';
        }
      }
      if (verificationLevel === 1) { // Low Doc
        if (lvr <= 0.65) {
          maxLoanAmount = '3,000,000';
        }
        if (lvr > 0.65 && lvr <= 0.75) {
          maxLoanAmount = '2,500,000';
        }
        if (lvr > 0.75 && lvr <= 0.8) {
          maxLoanAmount = '2,000,000';
        }
        if (lvr > 0.8 && lvr <= 0.85) {
          maxLoanAmount = '1,500,000';
        }
      }
    }
    return maxLoanAmount;
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-header panel-header-fixed mb-md">
          <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Product</h3>
          </div>
        </div>

        <div className="panel-section-body">
          <div className="panel-section-container">
            
            <Row>
              <Col sm={4}>
                <Select
                  id="rateLoanDetails.verification"
                  name="rateLoanDetails.verification"
                  label="Verification"
                  value={this.getVerificationLevels()[0].value}
                  options={this.getVerificationLevels()}
                  changeCallback={this.handleChange}
                  className="form-control"
                />
              </Col>

              <Col sm={4}>
                <Select
                  id="rateLoanDetails.productGroupId"
                  name="rateLoanDetails.productGroupId"
                  label="Product"
                  value={this.getResiProducts()[0].value}
                  options={this.getResiProducts()}
                  changeCallback={this.handleChange}
                  className="form-control"
                />
              </Col>
              <Col sm={4}>
                <Select
                  id="rateLoanDetails.purpose"
                  name="rateLoanDetails.purpose"
                  label="Purpose"
                  value={this.getLoanPurposes()[0].value}
                  options={this.getLoanPurposes()}
                  changeCallback={this.handleChange}
                  className="form-control"
                />
                  <i style={{ right: '12px', top: '6px' }} className="mute a mi mi-help-outline pointer" data-event-on="hover" data-tip="A professional investor loading applies to any application in the name of a company/trust, or any investment application where the individual borrower owns 4 or more properties (inclusive of the application)." />
              </Col>
            </Row>
          
          
            <Row>
              <Col sm={4}>
                <Select
                  id="rateLoanDetails.repayment"
                  name="rateLoanDetails.repayment"
                  label="Repayments"
                  value={this.getRepayments()[0].value}
                  options={this.getRepayments()}
                  changeCallback={this.handleChange}
                  className="form-control"
                />
              </Col>

              <Col sm={4}>
                <Select
                  id="rateLoanDetails.structure"
                  name="rateLoanDetails.structure"
                  label="Structure"
                  value={this.getStructures()[0].value}
                  options={this.getStructures()}
                  changeCallback={this.handleChange}
                  className="form-control"
                />
              </Col>

              <Col sm={4} style={{ marginTop: '12px' }}>

                <Checkbox
                  id="rateLoanDetails.isSwift"
                  name="rateLoanDetails.isSwift"
                  rowClassName="ib mr-sm"
                  valueLabel={<span>Swift</span>}
                  changeCallback={this.handleChange}
                  validationError="Swift is only available on Star owner occupied applications where LVR >80% and LMI is not available."
                  validations={{
                    customValidator: (values, value) => {
                      const loanPurpose = this.state['rateLoanDetails.purpose'];
                      const productType = this.state['rateLoanDetails.productGroupId'];
                      const loanAmount = parseInt(this.state['rateLoanDetails.loanAmount'].toString().replace(/[^0-9.]/g, ''));
                      const securityValue = parseInt(this.state['rateLoanDetails.purchaseAmount'].toString().replace(/[^0-9.]/g, ''));
                      const lvr = loanAmount / securityValue;

                      return value === true ? (productType == 5000 && loanPurpose == 0 && lvr > 0.8) : true;
                    },
                  }}
                />
                <i
                  className="mute a mi mi-help-outline pointer"
                  style={{ top: '6px' }}
                  data-position="fixed"
                  data-event-on="click"
                  data-tip="An option for owner occupied applications above 80% LVR where LMI is not available. Approved applicants only."
                />
              </Col>
            </Row>
              
            <Row>
              <Col sm={4}>
                <div className="form-prefix">$</div>
                <Input
                  id="rateLoanDetails.purchaseAmount"
                  name="rateLoanDetails.purchaseAmount"
                  ref="rateLoanDetails.purchaseAmount"
                  type="text"
                  label="Security Value"
                  value={0}
                  autoFocus
                  changeDebounceInterval={0}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                    this.handleChange('rateLoanDetails.purchaseAmount', value);
                  }}
                  validationError="Security value is required" // default validation error
                  validations={{
                    customValidator: (values, value) => {
                      const loanAmount = parseInt(this.state['rateLoanDetails.loanAmount'].toString().replace(/[^0-9.]/g, ''));
                      const purchaseAmount = parseInt(value.toString().replace(/[^0-9.]/g, ''));
                      if (purchaseAmount === 0) {
                        return false;
                      }
                      if (purchaseAmount < 10000) {
                        return 'Security value must be greater than 10,000';
                      }
                      if (loanAmount >= purchaseAmount) {
                        return 'Security value must be greater than the loan amount';
                      }
                      return true;
                    },
                  }}
                  required
                />
              </Col>

              <Col sm={4}>
                <div className="form-prefix">$</div>
                <Input
                  id="rateLoanDetails.loanAmount"
                  name="rateLoanDetails.loanAmount"
                  ref="rateLoanDetails.loanAmount"
                  type="text"
                  label="Loan Amount"
                  value={0}
                  changeDebounceInterval={0}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                    this.handleChange('rateLoanDetails.loanAmount', value);
                  }}
                  validationError="Loan amount is required" // default validation error
                  validations={{
                    loanAmountWithinRange: (values, value) => {
                      const loanAmount = parseInt(value.toString().replace(/[^0-9.]/g, ''));
                      const purchaseAmount = parseInt(this.state['rateLoanDetails.purchaseAmount'].toString().replace(/[^0-9.]/g, ''));
                      const productType = parseInt(this.state['rateLoanDetails.productGroupId']);
                      const verificationLevel = parseInt(this.state['rateLoanDetails.verification']);
                      const lvr = (loanAmount / purchaseAmount);
                      const maxLoanAmountStr = this.getLoanAmountMax(productType, verificationLevel, lvr);
                      const maxLoanAmount = parseInt(maxLoanAmountStr.replace(/[^0-9.]/g, ''));

                      if (loanAmount === 0) {
                        return false;
                      }
                      // If product type is Sharp (5500)
                      if (productType === 5500 && loanAmount < 100000) {
                        return 'Loan amount must be at least 100,000';
                      }
                      // If full/low doc, product type is Star (5000) or Free (5600)
                      if ((verificationLevel !== 2) && (productType === 5000 || 5600) && loanAmount < 50000) {
                        return 'Loan amount must be at least 50,000';
                      }
                      if (loanAmount < 10000 || loanAmount > maxLoanAmount) {
                        return ('Loan amount must be between 10,000 and ').concat(maxLoanAmountStr);
                      }
                      if (loanAmount >= purchaseAmount) {
                        return 'Loan amount must be smaller than the security value';
                      }
                      return true;
                    },
                    lvrWithinRange: (values, value) => {
                      const verificationLevel = parseInt(this.state['rateLoanDetails.verification']);
                      const structure = parseInt(this.state['rateLoanDetails.structure']);
                      const repayments = parseInt(this.state['rateLoanDetails.repayment']);

                      const loanAmount = parseInt(value.toString().replace(/[^0-9.]/g, ''));
                      const purchaseAmount = parseInt(this.state['rateLoanDetails.purchaseAmount'].toString().replace(/[^0-9.]/g, ''));
                      const lvr = (loanAmount / purchaseAmount);

                      // If verification level is Full Doc (0)
                      if (verificationLevel === 0 && lvr > 0.95) {
                        return 'LVR exceeds maximum full doc LVR of 95%';
                      }
                      // If verification level is Low Doc (1)
                      if (verificationLevel === 1 && lvr > 0.85) {
                        return 'LVR exceeds maximum low doc LVR of 85%';
                      }
                      // If structure is fixed (1,2,3)
                      if (structure !== 0 && lvr > 0.85) {
                        return 'LVR exceeds maximum fixed rate LVR of 85%';
                      }
                      // If repayments are interest only (1)
                      if (repayments === 1 && lvr > 0.9) {
                        return 'LVR exceeds maximum interest only LVR of 90%';
                      }
                      return true;
                    },
                  }}
                  required
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

ServiceAbilityProduct.propTypes = {
};
