import * as React from 'react';
import PropTypes from 'prop-types';

import CalculatorPaneBar from './calculatorPaneBar';

const extractNameId = (props) => (!!props.prequal
  ? { name: props.prequal.profileName, id: props.prequal.profileId }
  : props);

class CalculatorPane extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      scrollClass: 'static',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset;
    let scrollClass = 'static';

    if (scrollTop > 120) {
      scrollClass = 'fixed';
    }

    this.setState({
      scrollClass,
    });
  }

  render() {
    const { name, id } = extractNameId(this.props);

    return (
      <div className={`bar-wrapper bar-${this.state.scrollClass} bar-default np`}>
        <CalculatorPaneBar id={id} saved={this.props.saved} name={name} active={this.props.active} setModal={this.props.setModal} position="fixed" />
        <CalculatorPaneBar id={id} saved={this.props.saved} name={name} active={this.props.active} setModal={this.props.setModal} position="static" />
      </div>
    );
  }
}

export default CalculatorPane;
