import { v4 as uuidv4 } from 'uuid';
import API from '../api';
import * as types from '../constants/types';

export const getIntroducers = (introducerEmail, searchTerm, page, pageSize) => (dispatch) => API.getIntroducers(introducerEmail, searchTerm, page, pageSize)
  .then((response) => dispatch({
    type: types.GET_INTRODUCERS,
    data: response,
  }))
  .catch((error) => {
    throw (error);
  });
