import API from '../api';
import * as types from '../constants/types';

export const getContacts = () => (dispatch) => API.getContacts()
  .then((response) => dispatch({
    type: types.GET_CONTACTS,
    contacts: response.data,
  }))
  .catch((error) => {
    throw (error);
  });
