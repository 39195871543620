/**
 * Import depandancies
 */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

/**
 * BDM list item for BDM widget
 */
export default class ContactsWidgetItem extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      expanded: false,
    };

    this.toggleFaq = this.toggleFaq.bind(this);
  }

  toggleFaq() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  renderFaq(html) {
    return { __html: html };
  }

  /**
     * Render widget
     */
  render() {
    return (
          <div className="faq">

            <p className="faq-link" onClick={this.toggleFaq}>
              <Icon className={`animate-fast ${this.state.expanded ? 'rotate-180' : 'rotate-0'}`} glyph={`${this.state.expanded ? 'minus' : 'plus'}-circle mr-sm mute-lg`} />
              {this.props.faq.question}
            </p>

            {
                    (() => {
                      if (this.state.expanded) {
                        return (
                          <p className="faq-text mb-md" dangerouslySetInnerHTML={this.renderFaq(this.props.faq.answer)} />
                        );
                      }
                    })()
                }
          </div>
    );
  }
}

ContactsWidgetItem.propTypes = {
  faq: PropTypes.any,
};
