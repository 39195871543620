import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { Money } from '../../utils';

import ApplicationActionIcon from './applicationActionIcon';
import ApplicationTypeIcon from './applicationTypeIcon';
import ApplicationApplicants from './applicationApplicants';

const ApplicationsListItem = (props) => (

  <Link className="loan-item" to={`/applications/${props.application.applicationNumber}`}>
    <div className={`${!props.application.applicationState ? 'mute' : ''}`}>
      <ApplicationTypeIcon type={props.application.applicationType} />

      <Money className="loan-amount pull-right">{props.application.amount}</Money>

      <ApplicationApplicants applicants={props.application.applicants} />

      <p className="loan-status">{props.application.applicationStatus.description}</p>
    </div>

    <ApplicationActionIcon status={props.application.applicationStatus} actions={(props.application.applicationState ? props.application.actions : [])} />
  </Link>

);

ApplicationsListItem.propTypes = {
  application: PropTypes.object.isRequired,
};

export default ApplicationsListItem;
