import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

const EmptyPane = (props) => (
    <div className="p-md pt-lg pb-lg text-center">
      <Icon glyph="search" size={4} className="mute-lg mt-md" />

      <h4 className="mt-lg">
        {'Could not find '}
        {props.item}
        {' '}
        for &quot;
        {props.query}
        &quot;
      </h4>
    </div>
);

EmptyPane.propTypes = {
  query: PropTypes.string,
  item: PropTypes.string,
};

export default EmptyPane;
