export const mapMaritalStatus = (relationship) => {
  switch (relationship) {
    case 'Single':
      return 1;
    case 'Married to Non Applicant':
      return 2;
    case 'De Facto to Non Applicant':
      return 3;
    case 'Separated':
      return 4;
    case 'Divorced':
      return 5;
    case 'Widowed':
      return 6;
    default:
      break;
  }
};

export const mapResidencyType = (type) => {
  switch (type) {
    case 'Owner/Buyer':
      return 1;
    case 'With Parents':
      return 2;
    case 'Boarding':
      return 3;
    case 'Renter RE Agent':
      return 4;
    case 'Renter Other':
      return 5;
    case 'Public Housing':
      return 6;
    default:
      break;
  }
};

export const mapResidency = (occupancy) => ({
  ResidencyType: mapResidencyType(occupancy.type),
  DurationInMonths: occupancy.months + (occupancy.years * 12),
});

export const mapEmploymentArrangement = (type) => {
  switch (type) {    
    case 'Permanent Full Time':
      return 2;
    case 'Permanent Part Time':
      return 3;
    case 'Contract Full Time':
      return 4;
    case 'Contract Part Time':
      return 5;
    case 'Casual':
      return 6;
    case 'Seasonal':
      return 7;
    default:
      break;
  }
};

export const mapEmploymentHistory = (employment) => {
  const historyItem = {
    EmploymentArrangement: mapEmploymentArrangement(employment.type),
    DurationInMonths: employment.months + (employment.years * 12),
  };

  if (employment.isCurrent) {
    switch (employment.type) {
      case 'Contract Full Time':
      case 'Contract Part Time':
        historyItem.$type = 'ContractingEmployment';
        historyItem.EndDate = employment.endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        break;
      case 'Sole-Trader':
      case 'Permanent Full Time':
      case 'Permanent Part Time':
      case 'Casual':
      case 'Seasonal':
      case 'Partner':
      case 'Director':
        historyItem.$type = 'PermanentEmployment';
        break;
      default:
        break;
    }
  } else {
    historyItem.$type = 'PreviousEmployment';
    historyItem.EndDate = employment.endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
  return historyItem;
}; 
  
export const mapDebtType = (debtType) => {
  switch (debtType) {
    case 'securedcredit':
      return 1;
    case 'unsecuredcredit':
      return 2;
    case 'tradeAccount':
      return 3;
    case 'ato':
      return 4;
    case 'utilityaccount':
      return 5;
    case 'autoLoan':
      return 6;
    case 'mortgage':
      return 7;
    case 'personalLoans':
      return 8;
    case 'creditCards':
      return 9;
    default:
      break;
  }
};

export const mapEventType = (debtType) => {
  switch (debtType) {
    case 'arrears':
      return 1;
    case 'default':
      return 2;
    case 'judgment':
      return 3;
    case 'warrant':
      return 4;
    case 'writ':
      return 5;
    default:
      break;
  }
};

export const mapBankruptcyEventType = (debtType) => {
  switch (debtType) {
    case 'Part IX':
      return 1;
    case 'Part X':
      return 2;
    case 'Sequestration':
      return 3;
    case 'DebtorsPetition':
      return 4;
    default:
      break;
  }
};

export const mapPaidStatusType = (status) => {
  switch (status) {
    case 'unpaid':
      return 'DebtUnpaidStatus';
    case 'paid':
      return 'DebtPaidStatus';
    default:
      break;
  }
};

export const mapCreditHistory = (creditEvent) => {  
  if (creditEvent.creditType == 'bankruptcy') {
    const bankruptcyEvent = {
      $type: 'Bankruptcy', 
      BankruptcyType: mapBankruptcyEventType(creditEvent.eventType),
    };
    if (!!creditEvent.dateRectified) {
      bankruptcyEvent.BankruptcyStatus = {
        $type: 'BankruptcyRectified',
        DateRectified: creditEvent.dateRectified, 
        DateListed: creditEvent.dateIncurred,
      };
    } else {
      bankruptcyEvent.BankruptcyStatus = {
        $type: 'BankruptcyUnrectified',
        DateListed: creditEvent.dateIncurred,
      };
    }
    return bankruptcyEvent;
  } 
  return {    
    $type: 'AccountDebt',
    DebtType: mapDebtType(creditEvent.creditType),
    EventType: mapEventType(creditEvent.eventType),
    PaidStatus: {
      $type: mapPaidStatusType(creditEvent.status),
      DatePaid: creditEvent.dateRectified,
      DateIncurred: creditEvent.dateIncurred,
    },
    DebtAmount: creditEvent.amount,
  };
};

export const mapSecurityVehicleAsset = (vehicle) => ({
  BodyTypeId: vehicle.bodyTypeId,
  Make: vehicle.make,
  Model: vehicle.model,
  Nvic: vehicle.nvic,
  TransmissionId: vehicle.transmissionTypeId,
  Year: vehicle.year,
  IsUsed: vehicle.isUsed,
});

export const mapApplicant = (applicant) => {
  const personApplicant = {
    NumberOfDependants: applicant.dependants,
    Postcode: applicant.postcode,
    State: applicant.state,
    Country: 1, // Australia
    MaritalStatus: mapMaritalStatus(applicant.relationship),
    DateOfBirth: applicant.dateOfBirth,
    CurrentResidency: mapResidency(applicant.occupancies.find((o) => o.isCurrent)),
    PreviousResidencies: applicant.occupancies.filter((o) => !o.isCurrent).map(mapResidency),
    EmploymentHistory: applicant.employments.map(mapEmploymentHistory),
    CreditEvents: applicant.creditHistory.creditEvents.map(mapCreditHistory),
    CreditEnquiryScore: {
      Provider: 1,
      Score: applicant.creditHistory.vedaScore,
      NumberOfEnquiries: applicant.creditHistory.numberOfEnquiries,
    },
  };

  if (applicant.firstName && applicant.lastName) {
    personApplicant.$type = 'NamedPersonApplicant'; 
    personApplicant.FirstName = applicant.firstName;
    personApplicant.MiddleName = applicant.middleName;
    personApplicant.LastName = applicant.lastName;
  } else {
    personApplicant.$type = 'AnonymousPersonApplicant';
  }

  return personApplicant;
};

export const mapApplication = (requestId, data) => {
  const rateQuoteQuery = {
    RequestId: requestId,
    QueryInfo: {
      MotorLoanStructure: {
        $type: 'MotorLoan',
        LoanTermYear: data.loanDetails.loanTermInYears,
        BrokerageFee: data.loanDetails.loanAmount.applicationFee,
        ComprehensiveInsurancePremium: data.loanDetails.loanAmount.comprehensiveInsurance,
        ExtendedWarranty: data.loanDetails.loanAmount.extendedWarranty,
        CashDeposit: data.loanDetails.loanAmount.deposit,
        NetTradeIn: data.loanDetails.loanAmount.netTradeIn,
        PurchasePrice: data.loanDetails.loanAmount.purchasePrice,
      },
      VehicleAsset: data.motorPurchaseVehicle ? mapSecurityVehicleAsset(data.motorPurchaseVehicle) : null,
    },
  };
  
  if (data.applicants.length == 1) {
    rateQuoteQuery.QueryInfo.$type = 'MotorConsumerSingleApplicantRateQuoteInfoQuery';
    rateQuoteQuery.QueryInfo.ApplicantInfo = mapApplicant(data.applicants[0]);
  } else if (data.applicants.length == 2) {
    rateQuoteQuery.QueryInfo.$type = 'MotorConsumerDualApplicantRateQuoteInfoQuery';
    rateQuoteQuery.QueryInfo.PrimaryApplicantInfo = mapApplicant(data.applicants[0]);
    rateQuoteQuery.QueryInfo.SecondaryApplicantInfo = mapApplicant(data.applicants[1]);
  } else {
    // throw 'Max 2 applicants';
  }
  if (data.hasProperty == undefined) {
    rateQuoteQuery.QueryInfo.HasProperty = false;
  } else {
    rateQuoteQuery.QueryInfo.HasProperty = data.hasProperty;
  }
  if (rateQuoteQuery.QueryInfo.HasProperty && data.hasMortgage !== undefined) {
    rateQuoteQuery.QueryInfo.HasMortgage = data.hasMortgage;
  } else {
    rateQuoteQuery.QueryInfo.HasMortgage = false;
  }
  return rateQuoteQuery;
};
