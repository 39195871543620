export const FV = (rate: number, nPer : number, pmt : number, pv = 0.0) => {
    var num1 = 0;
    
    if (rate == 0.0) {
        num1 = -pv - pmt * nPer;
    }
    else {
        var num2 = 1.0 // By default DueDate.EndOfPeriod : Due == DueDate.EndOfPeriod ? 1.0 : 1.0 + Rate;
        var num3 = Math.pow(1.0 + rate, nPer);
        num1 = -pv * num3 - pmt / rate * num2 * (num3 - 1.0);
    }

    return num1;
}

export default FV;