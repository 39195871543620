import { Dispatch } from "redux"
import API from '../../api';
import { AxiosResponse } from "axios";

interface VehicleResponse extends AxiosResponse {
    data: ApiVehicle[];
}

export type MotorApplicationAction = GetVehicleAction;

export enum MotorApplicationActionTypes {
    GetVehicle = "GetVehicle",
}

export interface Vehicle {
    make: String;
    model: String;
    year: Number;
    category: String;
    nvic: String;
}

interface ApiVehicle {
  make: String;
  model: String;
  year: Number;
  category: String;
  nVIC: String;
}

interface GetVehicleAction {
    type: MotorApplicationActionTypes.GetVehicle,
    vehicle: Vehicle
}

export const getVehicle = (nvic: String) => (dispatch: Dispatch<GetVehicleAction>) => {
   return API.getNVIC(nvic)
        .then((response: VehicleResponse) => {
            if (!response.data[0])
                throw("Could not find the vehicle details for nvic: " + nvic);

            dispatch({
                type: MotorApplicationActionTypes.GetVehicle,
                vehicle: { ...response.data[0], nvic: response.data[0].nVIC }
            });
        })
}
