export enum IApplicationType {
  Residential = 1,
  Motor = 2,
  Commercial = 5,
  BusinessCapital = 7
}

export type IJWT = string;

export type IApplicationNumber = number;

export interface ISession {
  failed: number;
  renewPromise: any;
  timer: number;
  token: IJWT;
  userid?: number;
  username?: string;
  details: IDetails;
}

export interface IDetails {
  address: IAddress;
  bankAccount: IBankAccount;
  bdms: IBdm[];
  products: IProduct[];
  contact: string;
  email: string;
  emailSecondary: string;
  fax: string;
  firstName: string;
  fullName: string;
  initials: string;
  introducerId: number;
  iqSms: boolean
  isMotor: boolean;
  isResidential: boolean;
  isSubmission: boolean;
  lastName: string;
  leadIntroducer: string;
  libertyNewsletter: boolean;
  mobile: string;
  phone: string;
  username: string;
  isPhoneSized: boolean;
  isLfiProtectUser: boolean;
  isMotorCommercial: boolean;
  adjustmentProfileID: number | null;
  type: string;
  isUAFUser: boolean;
}

interface IAddress {
  postcode: string;
  state: string;
  street: string;
  suburb: string;
}

interface IBankAccount {
  accountNumber: string;
  bank: string;
  bsb: string;
}

interface IBdm {
  email: string;
  name: string;
  productGroupId: number
}

export interface IProduct {
  productGroupId: number;
}

export interface IOption {
  label: string,
  value: string | number,
  group?: string,
  description?: string,
  disabled?: boolean
}
