import * as React from 'react';
import PropTypes from 'prop-types';

interface IHeaderProps {
  title: string,
  required?: boolean
  subtitle?: string
}

const Header = (props: IHeaderProps): JSX.Element => {
  return (
    <div className="panel-section">
      <div className="panel-section-header panel-header-fixed p-lg mb-lg-n" style={{border: 0}}>
        <h2>{props.title}</h2>
        <p className="text-lg">
          <span className="mute">
            {props.subtitle}
          </span>
          {props.required && <span><span className="mute">All fields with </span><span className="danger">●</span><span className="mute"> are required.</span></span>}
          &nbsp;
        </p>
      </div>
    </div>
  );
}

export default Header;
