import { VeiCalculatorActionTypes, VeiCalculatorAction} from "./actions"
import { VeiOption } from "../applicationUpdatePage/applicationUpdateVei";
import { getMaxGapValue } from "../applicationUpdatePage/veiMaxGapCalculator";
import { filterEligibleVeiOptions } from "../applicationUpdatePage/helper";
import initialState from "../../reducers/initialState";
import { LoanInformation, VehicleYearOnYearDepreciation } from "../applicationUpdatePage/veiTypes";
import moment = require("moment");
import { Actions } from "history";

export interface VeiCalculatorState {
  loanInfo: VeiLoanInformation,
  validLoanInfo?: LoanInformation
  isValid: boolean
  validVeiOptions?: VeiOption[],
}

export interface VeiLoanInformation  {
  vehicleValuationAmount : string
  vehicleYear : string
  loanTermYears : string
  loanAmount : string
  balloonAmount : string
  interestRate : string
}

const convertToLoanInformation = (loanInfo: VeiLoanInformation): LoanInformation => {
  return {
    loanAmount: Number(loanInfo.loanAmount),
    vehicleValuationAmount:Number(loanInfo.vehicleValuationAmount),
    vehicleAge: moment().year() - Number(loanInfo.vehicleYear),
    loanTermYears: Number(loanInfo.loanTermYears),
    balloonAmount: Number(loanInfo.balloonAmount),
    interestRate: Number(loanInfo.interestRate) / 100
  }
}

type getMaxGapFunction = (v: VehicleYearOnYearDepreciation[], l: LoanInformation) => number;
type filterEligibleVeiOptionsFunction = (v: VeiOption[], m: number) => VeiOption[];

export const veiCalculatorReducerCreator = (getMaxGap: getMaxGapFunction, filterEligibleVeiOptions: filterEligibleVeiOptionsFunction) => {
  return (state: VeiCalculatorState = initialState.veiCalculator, action: VeiCalculatorAction): VeiCalculatorState => {
    switch (action.type) {
      case VeiCalculatorActionTypes.ChangeLoanInfoAction:      
        const newLoanInfo = action.change(state.loanInfo, action.value);      
        const validLoan = convertToLoanInformation(newLoanInfo);

        let veiOptions: VeiOption[] | undefined = undefined;
  
        if (action.isFormValid) {
          const maxGap = getMaxGap(action.vehicleYearOnYearDepreciation, validLoan);
          veiOptions = filterEligibleVeiOptions(action.veiOptions, maxGap);
        }
        
        return {
          ...state,
          isValid: action.isFormValid,
          validLoanInfo: validLoan,
          loanInfo: newLoanInfo,
          validVeiOptions: veiOptions?.length === 0 ? undefined : veiOptions
        }

      default:
        return state
    }
  }
}

export const veiCalculatorReducer = veiCalculatorReducerCreator (getMaxGapValue, filterEligibleVeiOptions);

