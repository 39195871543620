import { ComServiceAbilitySuggestionAction, ComServiceAbilitySuggestionActionType } from './actions';

export interface ComServiceAbilitySuggestionState {
  preferredSurplus: number
};

export const initialState: ComServiceAbilitySuggestionState = {
  preferredSurplus: 200
};

export const comServiceAbilitySuggestionReducer = (state: ComServiceAbilitySuggestionState = initialState, action: ComServiceAbilitySuggestionAction): ComServiceAbilitySuggestionState  => {
  switch (action.type) {
    case ComServiceAbilitySuggestionActionType.SetPreferredSurplus:
      return {
        ...state,
        preferredSurplus: Number(action.value)
      };

    default:
      return state;
  }
};