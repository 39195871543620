import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as feedbackActions from '../../actions/feedbackActions';
import * as sessionActions from '../../actions/sessionActions';

export class FeedbackPane extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      feedback: 0,
      message: '',
    };

    this.setFeedbackMessage = this.setFeedbackMessage.bind(this);
    this.updateFeedbackMessage = this.updateFeedbackMessage.bind(this);
    this.resetFeedback = this.resetFeedback.bind(this);
  }

  setFeedbackMessage(event) {
    if (this.state.message) {
      const data = {
        message: this.state.message,
        username: this.props.session.details.username,
        url: window.location.hash,
      };

      this.props.actions.setFeedbackMessage(data);

      this.setState({
        feedback: 1,
      });

      setTimeout(() => {
        this.setState({
          feedback: 2,
        });

        setTimeout(this.resetFeedback, 2000);
      }, 4000);
    }

    event.preventDefault();
  }

  shouldFeedbackRender() {
    return this.props.session && this.props.session.token;
  }

  updateFeedbackMessage(event) {
    this.setState({
      message: event.target.value,
    });
  }

  resetFeedback() {
    this.setState({
      feedback: 0,
      message: '',
    });
  }

  render() {
    if (!this.shouldFeedbackRender()) {
      return null;
    }

    return (
      <div className={`feedback-wrapper ${this.state.feedback == 2 ? 'out' : 'in'}`}>
        <div className={`feedback-question ${this.state.feedback ? 'out' : 'in'}`}>Tell us how Liberty IQ can make a difference for you</div>

        <div className={`feedback ${this.state.feedback ? 'out' : 'in'}`}>
          <form onSubmit={this.setFeedbackMessage}>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Ideas, suggestions, feedback..." onChange={this.updateFeedbackMessage} value={this.state.message} />
            </div>
          </form>

          <button type="button" className="btn btn-success" onClick={this.setFeedbackMessage}>Send</button>
        </div>

        <div className={`feedback-result ${!this.state.feedback ? 'out' : 'in'}`}>
          Thanks, we really appreciate your feedback!
        </div>
      </div>
    );
  }
}

FeedbackPane.propTypes = {
  session: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...feedbackActions, ...sessionActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPane);
