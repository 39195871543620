import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class RateQuoteModal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm mute">
            <h4 className="mb-lg">About the results</h4>
            <p>This tool will provide an estimate only and is based upon the information you provide. Should this information change, the quoted rate and LVR will change also. If an application is submitted, the final interest rate and LVR will be determined in accordance with Liberty’s usual credit assessment criteria. </p>

            <h4 className="mt-lg mb-lg">What about fees and charges?</h4>
            <p>This calculator does not take into account all fees, charges or other amounts that may be charged to a loan. Additional fees and charges may increase repayment amounts. Full terms and conditions will be set out in any loan offer (if an offer is made). Further product information including current interest rates, fees and charges can be found in the relevant Introducer Guides available in the ‘Documents’ tab.</p>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

RateQuoteModal.propTypes = {
};
