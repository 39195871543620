import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from '../../utils';


import Api from '../../api';

import * as contactsActions from '../../actions/contactsActions';
import * as sessionActions from '../../actions/sessionActions';
import * as promptActions from '../../actions/promptActions';
import * as storageActions from '../../actions/storageActions';

import Event3rdParty from '../../helpers/event3rdParty';

export class PromptPane extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      visible: false,
      result: false,
      init: false,
      prompt: undefined,
      bdm: '',
    };

    this.hidePrompt = this.hidePrompt.bind(this);
    this.showPrompt = this.showPrompt.bind(this);
    this.savePrompt = this.savePrompt.bind(this);

    this.toggleResult = this.toggleResult.bind(this);

    this.primaryAction = this.primaryAction.bind(this);
    this.secondaryAction = this.secondaryAction.bind(this);
  }

  componentDidMount() {
    this.props.actions.getPrompts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.prompts.length && this.state.prompt === undefined) {
      this.showPrompt(nextProps.prompts[0]);
    }

    if (!nextProps.prompts.length && this.state.prompt !== undefined) {
      this.hidePrompt();
    }
  }

  toggleResult() {
    this.setState({
      result: !this.state.result,
    });
  }

  hidePrompt() {
    const { id } = this.state.prompt;
    this.setState({
      visible: false,
      prompt: undefined,
    });
    this.props.actions.setStorage(id);

    setTimeout(() => {
      this.props.actions.removePrompt(id);
    });
  }

  savePrompt(prompt) {
    this.props.actions.savePrompt(prompt.id, this.props.session.userid);
  }

  showPrompt(prompt) {
    this.getBDM();

    this.setState({
      prompt,
    });

    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, prompt.initialTimeout || 1000);

    /**
     * Tracking on show
     */
    Event3rdParty.send(`prompt-${prompt.track}`);
  }

  primaryAction() {
    const { prompt } = this.state;
    if (prompt.primaryCallback) {
      if (prompt.result) {
        this.toggleResult();
        setTimeout(this.hidePrompt, prompt.resultTimeout || 4000);
      } else {
        this.hidePrompt();
      }

      prompt.primaryCallback();
      this.savePrompt(this.state.prompt);

      Event3rdParty.send(`engage-${prompt.track}`);
    }
  }

  secondaryAction() {
    const { prompt } = this.state;
    if (prompt.secondaryCallback) {
      prompt.secondaryCallback();
    }

    // 3rd party
    Event3rdParty.send(`dismiss-${prompt.track}`);

    this.savePrompt(this.state.prompt);
    this.hidePrompt();
  }

  replace(text) {
    return text.replace('{firstName}', this.props.session.details.firstName);
  }

  getBDM() {
    let bdm = 'default';
    if (this.props.prompts[0] && this.props.prompts[0].bdm) {
      Api.getContactsDetails(this.props.contacts.map((contact) => contact.staffId))
        .then((response) => {
          response.data.map((contact, index) => {
            if (!index) {
              bdm = contact.lFStaffID;
            }

            if (contact.lFStaffPosition.toLowerCase().indexOf(this.props.promps[0].bdm) >= 0) {
              bdm = contact.lFStaffID;
            }
          });

          this.setState({
            bdm,
          });
        });
    } else if (this.props.contact && this.props.contacts[0]) {
      this.setState({
        bdm: this.props.contacts[0].staffId,
      });
    } else {
      this.setState({
        bdm: 'default',
      });
    }
  }

  render() {
    const prompt = this.props.prompts.length ? this.props.prompts[0] : false;
    if (!prompt) {
      return false;
    }

    return (
      <div className={`prompt-wrapper ${this.state.visible ? 'in' : ''}`}>
        <div className="prompt" style={{ marginTop: '10px', backgroundImage: `url(/${window.iqUIVersion}/assets/img/bdm/${this.state.bdm}.jpg)` }}>
          <div className={`prompt-message ${!this.state.result ? 'in' : ''}`}>

            <button type="button" className="close" aria-label="Close" onClick={this.secondaryAction}>
              <span aria-hidden="true">×</span>
            </button>

            { prompt.icon && <Icon glyph={prompt.icon} className="mute-lg prompt-icon" size={2} /> }

            <div className="prompt-content">
              { prompt.title && <h3 className="mt-sm">{prompt.title}</h3> }

              <p className="mute mb-md">{this.replace(prompt.text)}</p>

              { prompt.primaryButtonText && <button type="button" className="btn btn-secondary" onClick={this.primaryAction}>{prompt.primaryButtonText}</button> }
              { prompt.secondaryButtonText && <button type="button" className="btn btn-link btn-default" onClick={this.secondaryAction}>{prompt.secondaryButtonText}</button> }
            </div>
          </div>

          <div className={`prompt-result ${this.state.result ? 'in' : ''}`}>
            { prompt.resultIcon && <Icon glyph={prompt.resultIcon} className="success prompt-icon mt-xs" size={2} /> }

            <div className="prompt-content">
              { prompt.resultTitle && <h3 className="mt-sm">{prompt.resultTitle}</h3> }
              <p className="mute mb-md">{prompt.resultText}</p>
            </div>
          </div>
        </div>
        <div className="prompt-overlay" />
      </div>
    );
  }
}

// PromptPane.propTypes = {
//     actions: PropTypes.object
// };

function mapStateToProps(state) {
  return {
    contacts: state.contacts,
    session: state.session,
    prompts: state.prompts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...contactsActions, ...sessionActions, ...promptActions, ...storageActions,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromptPane);
