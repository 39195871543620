import initialState from './initialState';
import * as types from '../constants/types';

export default function faqsReducer(state = initialState.faqs, action) {
  switch (action.type) {
    case types.GET_FAQS:
      return sort(JSON.parse(JSON.stringify(state)));

    default:
      return state;
  }
}

const sort = (faqs) => {
  faqs.sort((a, b) => {
    if (a.question < b.question) return -1;
    if (a.question > b.question) return 1;

    return 0;
  });

  return faqs;
};
