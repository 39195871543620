import { Dispatch } from "redux"
import { notify as Notify } from 'react-notify-toast';
import API from "../../../api";
import {Broker, Applicant, Application} from './applicationEsigDocument'
import { PRODUCTGROUPS } from "../../../constants/types";

export type ApplicationDocumentAction = ShowAgreementAction | HideAgreementAction | DocumentPending | DocumentSucceeded | DocumentFailed;
export type DocumentDownloadAction = DocumentPending | DocumentSucceeded | DocumentFailed;

export enum ApplicationDocumentActionTypes {
    ShowAgreement = "ShowAgreement",
    HideAgreement = "HideAgreement",
    DocumentPending = "DocumentPending",
    DocumentSucceeded = "DocumentSucceeded",
    DocumentFailed = "DocumentFailed"
}

interface ShowAgreementAction {
    type: ApplicationDocumentActionTypes.ShowAgreement,
}

interface HideAgreementAction {
    type: ApplicationDocumentActionTypes.HideAgreement,
}

interface DocumentPending {
    type: ApplicationDocumentActionTypes.DocumentPending,
}

interface DocumentSucceeded {
    type: ApplicationDocumentActionTypes.DocumentSucceeded,
}
interface DocumentFailed {
    type: ApplicationDocumentActionTypes.DocumentFailed,
}

interface AdobeApplicant {
    email: String,
    mobile: String,
    applicantOrder: Number
}

export const showAgreement = () => (dispatch: Dispatch<ShowAgreementAction>) => dispatch({
    type: ApplicationDocumentActionTypes.ShowAgreement,
    showAgreement: true
});

export const hideAgreement = () => (dispatch: Dispatch<HideAgreementAction>) => dispatch({
    type: ApplicationDocumentActionTypes.HideAgreement,
    showAgreement: false
});

export const getDocument = (applicationNumber: Number, broker: Broker, applicants: Applicant[], productGroupId: Number) => (dispatch: Dispatch<DocumentDownloadAction>) => {
    var requestBody = prepareESigRequestBody(applicationNumber, broker, applicants, productGroupId)
    
    dispatch({
        type: ApplicationDocumentActionTypes.DocumentPending,
    })

    API.submitEsignatureRequest(requestBody)
        .then(data => handleESigResponse(data, dispatch))
        .catch((e) => {
            Notify.show('Something went wrong, please contact support team', 'error', 3000)
            dispatch({
                type: ApplicationDocumentActionTypes.DocumentFailed,
            })
        });    
};

const mapDocTypeId = (productGroupId: Number) => {
    switch (productGroupId) {
        case PRODUCTGROUPS.MOTOR_COMMERCIAL:
            return 11
        case PRODUCTGROUPS.MOTOR_NOVATED_LEASE:
            return 12
        case PRODUCTGROUPS.MOTOR_CONSUMER:
        case PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN:
            return 9
        default:
            Notify.show('E-signature is not supported for this product', 'error', 3000)
    }
}

const prepareESigRequestBody = (applicationNumber: Number, broker: Broker, applicants: Applicant[], productGroupId: (Number)) => {
    return {
        "docTypeId": mapDocTypeId(productGroupId),
        "loanId": applicationNumber,
        "broker": broker,
        "applicants": convertToAdobeApplicants(applicants)
    };
}

const handleESigResponse = (data, dispatch: Dispatch<DocumentDownloadAction>) =>{
    switch (data.status) {
        case 200:
            Notify.show('You will receive an email from Adobe shortly...', 'success', 3000)
            dispatch({
                type: ApplicationDocumentActionTypes.DocumentSucceeded,
            })
            break;

        case 500:
        default :
            Notify.show('Unable to create a esignature pack, please contact support team', 'error', 3000)
            dispatch({
                type: ApplicationDocumentActionTypes.DocumentFailed,
            })
            break;
    }
}

const convertToAdobeApplicants = (applicants:Applicant[]):AdobeApplicant[] => applicants
    .filter(a => a.contact?.email?.length > 0)
    .sort(sortByApplicantIdAsc)
    .map((a, i) => {
        return {
            email: a.contact.email[0],
            mobile: a.contact.mobile[0],
            applicantOrder: i + 1
        } as AdobeApplicant
    })

  const sortByApplicantIdAsc = (a:Applicant, b:Applicant) => a.applicantId < b.applicantId ? -1 : 1