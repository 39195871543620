import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';

import Remove from '../remove';
import Title from '../title';
import CurrencyInput from '../currencyInput';

import Mask from '../../../helpers/mask';
import {toRequired, toOptionGroup, selectText, firstOption} from '../functions';

export class OtherIncome extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  get incomes():any[] {
    let incomes:any[] = [];
    let groups = {
      "Pension": [
        "Age pension",
        "Disability support pension",
        "Defense service pension"
      ],
      "Government allowance": [
        "Family tax benefit",
        "Parenting payments",
        "Child care benefit",
        "Carer allowance",
        "Youth allowance",
        "Newstart"
      ],
      "Other": [
        "CSA payments",
        "Board",
        "Workcover payments",
        "TAC payments",
        "Superannuation",
        "Dividends",
        "Interest",
        "Maintenance"
      ]
    }

    Object.keys(groups).forEach(group => {
      groups[group].forEach(income => incomes.push(toOptionGroup(income, group)));
    });

    return incomes;
  }

  get frequencies():any[] {
    return ["Weekly", "Fortnightly", "Monthly", "Yearly"].map(option => ({
      label: option,
      value: option.toLowerCase().replace(" ", "")
    }));
  }

  render(): JSX.Element {
    return (
      <div className={`card mt-lg p-lg`}>
        <Input
          name={`incomes[${this.props.index}].type`}
          type="hidden"
          value="other"
        />

        <Input
          name={`incomes[${this.props.index}].belongsTo`}
          type="hidden"
          value={this.props.income.belongsTo}
        />

        <Title sub="Gross yearly" top>
          Other income
          <Remove onClick={this.props.removeIncome.bind(this, this.props.income.id)} />
        </Title>

        <Row>
          <Col sm={5}>
            <Select
              name={`incomes[${this.props.index}].incomeType`}
              id={`incomes[${this.props.index}].incomeType`}
              label={toRequired("Type of income")}
              options={this.incomes}
              value={firstOption(this.incomes)}
              className="form-control" />
          </Col>

          <Col sm={3} smOffset={4}>
            <CurrencyInput
              name={`incomes[${this.props.index}].incomeAmount`}
              label={toRequired("Amount")}
              align="right"
              validations={{
                isRequired: true,
                isNotZero: true
              }}
              validationErrors={{
                isRequired: "Amount is required",
                isNotZero: "Amount can not be 0"
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default OtherIncome;
