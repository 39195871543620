import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon } from '../../utils';
import { getVehicle, Vehicle } from './actions';
import ApplicationSecurityMotorVehicle from './applicationSecurityMotorVehicle';
import { ApplicationSecurityMotorNoVehicle } from './applicationSecurityMotorNoVehicle';
import { PRODUCTGROUPS } from '../../constants/types';

interface ApplicationSecurityMotorProps {
    vehicle: Vehicle;
    application: Application;
    actions: {
        getVehicle: typeof getVehicle
    }
}

export interface Application {
    motorPurchaseVehicle: MotorPurchaseVehicle;
    applicationState: number;
    applicationNumber: number;
    applicationType: number;
    productGroupId: number;
}

interface MotorPurchaseVehicle {
    vehicleRegistrationNumber: string,
    nvic: string;
    model: string,
    make: string,
    year: number,
}

const shouldRenderNvicVehicle = (vehicle: Vehicle) => !!vehicle.make && vehicle.model && !!vehicle.category && !!vehicle.nvic;
const isNonNvicCaravan = (productGroupId: number, nvic: string) => productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN && !nvic;

export class ApplicationSecurityMotor extends React.Component<ApplicationSecurityMotorProps, {}> {
  componentDidMount() {
    const hasNvicSecurityVehicle = this.props.application.applicationType == 2 && this.props.application.motorPurchaseVehicle
     && !isNonNvicCaravan(this.props.application.productGroupId, this.props.application.motorPurchaseVehicle.nvic);
    if (hasNvicSecurityVehicle) {
      this.props.actions.getVehicle(this.props.application.motorPurchaseVehicle.nvic);
    }
  }

  render() {
    return (
      <div className="card card-border">
        <Icon className="card-icon" glyph="directions-car" />
        {
          this.props.application.motorPurchaseVehicle
           && (shouldRenderNvicVehicle(this.props.vehicle) || isNonNvicCaravan(this.props.application.productGroupId, this.props.application.motorPurchaseVehicle.nvic))
            ? <ApplicationSecurityMotorVehicle application={this.props.application} />
            : <ApplicationSecurityMotorNoVehicle applicationNumber={this.props.application.applicationNumber} applicationState={this.props.application.applicationState} />
        }
      </div>
    );
  }
}

function mapStateToProps({ motorApplication }) {
  return {
    vehicle: motorApplication.vehicle,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getVehicle }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSecurityMotor);
