import * as React from "react";
import { Row, Col } from "react-bootstrap";

import Icon from "../../utils/icon";
import Money from "../../utils/money";
import { VeiOption } from "../applicationUpdatePage/applicationUpdateVei";

export interface VeiCalculatorResultProps {
  veiOptions: VeiOption[];
  isValid: boolean;
}

export const VeiCalculatorResult = (props: VeiCalculatorResultProps) => (
  <div className="panel-section">
    <div className="panel-section-body panel-section-body-tint">
      <div className="panel-section-container text-center">
        <Row>
          <Col sm={10} smOffset={1}>
            <div
              className={`card text-left mb-lg pb-h mt-md text-center ${
                !props.isValid ? "mute-lg" : ""
              }`}
            >
              <div className="mt-lg mb-lg">
                <Icon glyph="money" className="mute-lg" size={6} />
                <Icon
                  glyph="label"
                  className="secondary a mt-lg pl-lg pt-sm"
                  size={3}
                  style={{ left: 0, right: 0 }}
                />
                <h3>
                  {props.veiOptions.length === 0
                    ? "No valid options"
                    : "Options"}
                </h3>
              </div>

              {(() => {
                if (props.veiOptions.length > 0) {
                  return (
                    <div className="ml-md-n mr-md-n text-left">
                      <table className="table table-hover table-lg mb-h">
                        <thead>
                          <tr>
                            <th className="va-sub">Cover</th>
                            <th className="va-sub">Shortfall Benefit</th>
                            <th className="va-sub">Replacement Benefit</th>
                            <th>
                              <div>Premium</div>
                              <small
                                className="mute-sm"
                                style={{ fontSize: "12px" }}
                              >
                                (inc. GST and Stamp Duty)
                              </small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.veiOptions.map(veiOption => (
                            <tr key={`${veiOption.productNumber}`}>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {veiOption.productName.replace("VEI ", "")}
                              </td>
                              <td>
                                <Money>
                                  {veiOption.shortfallBenefitAmount}
                                </Money>
                              </td>
                              <td>
                                <Money>{veiOption.extrasBenefitAmount}</Money>
                              </td>
                              <td>
                                <Money>{veiOption.premiumAmount}</Money>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  );
                }
              })()}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);
