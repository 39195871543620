import * as React from 'react';
import PropTypes from 'prop-types';
import API from '../../api';
import { Icon, Avatar } from '../../utils';


export default class ApplicationProgress extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      groups: [{
        name: 'Submission',
        contact: null,
        code: 1,
      },
      {
        name: 'Assessment',
        contact: null,
        code: 2,
      },
      {
        name: 'Settlement',
        contact: null,
        code: 3,
      }],
    };

    this.getContacts = this.getContacts.bind(this);
  }

  componentDidMount() {
    if (this.props.contacts) {
      this.getContacts(this.props.contacts.map((contact) => contact.userId));
    }
  }

  getContacts(staffIds) {
    if (staffIds.length) {
      API.getContactsDetails(staffIds)
        .then((res) => {
          const { groups } = this.state;
          res.data && res.data.map((contact) => {
            let role = null;

            switch (contact.lFStaffPosition) {
              case 'Support Specialist': {
                role = 'Submission';
                break;
              }
              case 'Credit Analyst':
              case 'Underwriter': {
                role = 'Assessment';
                break;
              }
              case 'Settlements Officer': {
                role = 'Settlement';
                break;
              }
            }

            groups.map((group, index) => {
              if (group.name == role) {
                groups[index].contact = contact;
              }
            });

            this.setState({
              groups,
            });
          });
        });
    }
  }

  render() {
    if ([201, 202, 203, 301, 302, 303].indexOf(this.props.status.code) != -1) {
      return null;
    }

    return (
      <div className={`loan-timeline timeline ${this.props.status.code == 340 && 'done'} ${this.props.status.message && this.props.status.message.introducerAction ? 'action-required' : ''}`}>

        <div className="timeline-bar" />

        {
          this.state.groups.map((group, index) => (
              <div className="timeline-group" key={`timeline-group-${index}`}>
                <h4 className="timeline-title">{group.name}</h4>

                {
                  (() => {
                    if (group.contact) {
                      return (
                      <Avatar contact={group.contact} className="timeline-avatar" size="xs" />
                      );
                    }
                  })()
                }

                {
                  this.props.overview.map((status, overviewIndex) => {
                    if (status.code.toString()[0] == group.code) {
                      const base = this.props.status.code != 340 && status.code == this.props.status.code ? 'active' : status.completed ? 'done' : 'pending';

                      return (
                        <div className={`timeline-item ${base}`} key={`timeline-status-${index}-${overviewIndex}`}>
                          <div className="timeline-indicator">
                            <Icon glyph="more" />
                          </div>

                          {status.description}

                          {
                            (() => {
                              if (base == 'active' && this.props.status.message && this.props.status.message.introducerAction) {
                                return (
                                  <div className="timeline-note">
                                    <a href="javascript:void(0)" onClick={() => { this.props.setTab('actions'); }}>
                                    <Icon glyph="alert-circle" />
                                    {' Actions required'}
                                    </a>
                                  </div>
                                );
                              }
                            })()
                          }
                        </div>
                      );
                    }
                  })
                }
              </div>
          ))
        }
      </div>
    );
  }
}

ApplicationProgress.propTypes = {
  status: PropTypes.object,
  type: PropTypes.number,
  setTab: PropTypes.func,
};
