/**
 * Import frameworks
 */
import * as React from 'react';
import { notify as Notify } from 'react-notify-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form, Input, Checkbox, Select,
} from 'formsy-react-components';
import { Modal, Row, Col } from 'react-bootstrap';
import Event3rdParty from '../../helpers/event3rdParty';
import * as referenceActions from '../../actions/referenceActions';

import API from '../../api';
import Mask from '../../helpers/mask';


/**
 * Import stores
 */
import ApiStore from '../../alt/apiStore';

/**
 * Import others
 */
import { Button, Loadr } from '../../utils';

import * as errors from '../../constants/errors';
import { ApplicationAgreementContactDetails } from './applicationAgreementContactDetails';
const Moment = require('moment');


/**
 * Download Document Component
 */
export class ApplicationAgreement extends React.Component {
  /**
   * Constructor
   */
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      isProcessing: false,
      loanAgreementData: null,
      comprehensiveInsurerCommissionValueDisabled: true,
      extWarrantyInsurerCommissionValueDisabled: true,
      insurers: [],
      emptyInsurers: [{ value: '', label: 'Please select...' }],
    };

    this._doSubmit = this._doSubmit.bind(this);

    this._formatDateInput = this._formatDateInput.bind(this);

    this._startProcessing = this._startProcessing.bind(this);
    this._stopProcessing = this._stopProcessing.bind(this);

    this._setLoanAgreementData = this._setLoanAgreementData.bind(this);

    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);

    this.getInsurers = this.getInsurers.bind(this);
    this.updateInsurer = this.updateInsurer.bind(this);
    this.comprehensiveInsurerCommissionTypeChanged = this.comprehensiveInsurerCommissionTypeChanged.bind(this);
    this.extWarrantyInsurerCommissionTypeChanged = this.extWarrantyInsurerCommissionTypeChanged.bind(this);
  }

  componentDidMount() {
    if (this.props.application) {
      this._setLoanAgreementData(this.props.application);
    }
    this.getInsurers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reference.insurers && nextProps.reference.insurers.length > 0) {
      const { state } = this;
      const list = nextProps.reference.insurers.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)).map((item) => ({ value: item.id, label: item.name }));

      const other = [{ value: -1, label: 'Other' }];

      this.setState({
        insurers: [...state.emptyInsurers, ...other, ...list],
      });
    }
  }

  getInsurers() {
    this.props.actions.getInsurers()
      .catch(() => Notify.show(errors.ERR_DEFAULT, 'error', 3000));
  }

  _doSubmit = (data) => {
    const self = this;
    self._startProcessing();

    // 3rd party
    Event3rdParty.send('update-agreement');

    const apiUrl = ApiStore.applicationAgreementSaveUrl();
    let registrationExpiry = null;

    if (data.vehicleRegistrationExpiry) {
      let dateSplit = [];

      if (data.vehicleRegistrationExpiry.toString().search('/') !== -1) {
        dateSplit = data.vehicleRegistrationExpiry.split('/');
      } else {
        dateSplit.push(data.vehicleRegistrationExpiry.toString().substr(0, 2));
        dateSplit.push(data.vehicleRegistrationExpiry.toString().substr(2, 4));
      }
      registrationExpiry = Moment(`${dateSplit[1]}-${dateSplit[0]}-01`).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    }

    const loanAgreementData = {
      applicationNumber: self.props.application.applicationNumber,
      vehicleRegistration: data.vehicleRegistrationNumber,
      vin: data.vehicleVinNo,
      engineNumber: data.vehicleEngineNo,
      vehicleColour: data.vehicleColour,
      vendorName: data.vehicleVendorName,
      vehicleRegistrationExpiry: registrationExpiry,
      fsgProvided: data.financialServiceGuideProvided,
      pdsProvided: data.productDisclosureStatementProvided,
    };

    if (data.comprehensiveInsurerId && data.comprehensiveInsurerId != 0) {
      loanAgreementData.comprehensiveInsurer = {
        id: parseInt(data.comprehensiveInsurerId),
        term: parseInt(data.comprehensiveInsurerTerm),
        commissionType: data.comprehensiveInsurerCommissionType,
        commissionValue: data.comprehensiveInsurerCommissionValue && data.comprehensiveInsurerCommissionValue > 0 ? parseFloat(data.comprehensiveInsurerCommissionValue) : null,
        name: data.comprehensiveInsurerName || this.state.insurers.filter((insurer) => insurer.value == data.comprehensiveInsurerId)[0].label || '',
      };
    }

    if (data.extWarrantyInsurerId && data.extWarrantyInsurerId != 0) {
      loanAgreementData.extWarrantyInsurer = {
        id: parseInt(data.extWarrantyInsurerId),
        term: parseInt(data.extWarrantyInsurerTerm),
        commissionType: data.extWarrantyInsurerCommissionType,
        commissionValue: data.extWarrantyInsurerCommissionValue && data.extWarrantyInsurerCommissionValue > 0 ? parseFloat(data.extWarrantyInsurerCommissionValue) : null,
        name: data.extWarrantyInsurerName || this.state.insurers.filter((insurer) => insurer.value == data.extWarrantyInsurerId)[0].label || '',
      };
    }

    return API.post(apiUrl, loanAgreementData).then(() => {
      /**
         * Successful
         *
         * Passed via props from DownloadDocument
         */

      self._stopProcessing();
      self.props.getDocument(self.props.application.applicationNumber, this.props.documentId);
    }).catch((error) => {
      /**
         *
         * Failure
         */
      switch (error.status) {
        /**
           * Forbidden
           *
           */
        case 403: {
          Notify.show('You are not allowed to download this document...', 'error', 3000);
          break;
        }

        /**
           * Server error
           */
        case 500:
        default: {
          Notify.show('Something went wrong, please try again...', 'error', 3000);
          break;
        }
      }

      self._stopProcessing();
    });
  }

  updateInsurer(type, value) {
    const insurer = this.state.insurers.filter((_insurer) => _insurer.value == value)[0];

    const loanAgreementData = JSON.parse(JSON.stringify(this.state.loanAgreementData));
    loanAgreementData[type].name = insurer && insurer.label != 'Other' ? insurer.label : '';
    loanAgreementData[type].id = insurer ? insurer.value : 0;

    this.setState({
      loanAgreementData,
    });
  }

  _startProcessing = () => {
    this.setState({
      isProcessing: true,
    });
  }

  _stopProcessing = () => {
    this.setState({
      isProcessing: false,
    });
  }

  _setLoanAgreementData = (applicationData) => {
    const _state = this.state;

    _state.loanAgreementData = {
      vehicleRegistrationNumber: applicationData.motorPurchaseVehicle.vehicleRegistrationNumber || null,
      vehicleRegistrationExpiry: Moment(applicationData.motorPurchaseVehicle.vehicleRegistrationExpiry).isValid() ? Moment(applicationData.motorPurchaseVehicle.vehicleRegistrationExpiry).format('MM/YYYY') : null,
      vehicleVendorName: applicationData.motorPurchaseVehicle.vendorName || null,
      vehicleEngineNo: applicationData.motorPurchaseVehicle.engineNumber || null,
      vehicleVinNo: applicationData.motorPurchaseVehicle.vin || null,
      vehicleColour: applicationData.motorPurchaseVehicle.vehicleColour || null, // MISSING,
      hasInsurance: applicationData.insuranceSummary !== null,
      financialServiceGuideProvided: applicationData.insuranceSummary && applicationData.insuranceSummary.financialServiceGuideProvided,
      productDisclosureStatementProvided: applicationData.insuranceSummary && applicationData.insuranceSummary.productDisclosureStatementProvided,
      comprehensiveInsurer: applicationData.motorPurpose.comprehensiveInsuranceAmount > 0 ? applicationData.motorPurpose.comprehensiveInsurer : null,
      extWarrantyInsurer: applicationData.motorPurpose.extendedWarranty > 0 ? applicationData.motorPurpose.extWarrantyInsurer : null,
    };

    if (applicationData.motorPurpose.comprehensiveInsuranceAmount > 0
      && !_state.loanAgreementData.comprehensiveInsurer) {
      _state.loanAgreementData.comprehensiveInsurer = {
        id: null, name: null, term: null, commissionType: null, commissionValue: null,
      };
    }

    if (applicationData.motorPurpose.extendedWarranty > 0
      && !_state.loanAgreementData.extWarrantyInsurer) {
      _state.loanAgreementData.extWarrantyInsurer = {
        id: null, name: null, term: null, commissionType: null, commissionValue: null,
      };
    }

    if (_state.loanAgreementData.comprehensiveInsurer && _state.loanAgreementData.comprehensiveInsurer.commissionType && _state.loanAgreementData.comprehensiveInsurer.commissionType != '-') { _state.comprehensiveInsurerCommissionValueDisabled = false; }

    if (_state.loanAgreementData.extWarrantyInsurer && _state.loanAgreementData.extWarrantyInsurer.commissionType && _state.loanAgreementData.extWarrantyInsurer.commissionType != '-') { _state.extWarrantyInsurerCommissionValueDisabled = false; }

    this.setState(_state);
  }

  _formatDateInput = (value, name) => {
    const formattedValue = Mask.date(value);
    if (value !== formattedValue) {
      value = formattedValue;

      const _state = this.state;
      _state.loanAgreementData[name] = formattedValue;
      this.setState(_state);
    }
  }

  onInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  onValidSubmit(data) {
    this._doSubmit(data);
  }

  comprehensiveInsurerCommissionTypeChanged(name, value) {
    this.form.formsyForm.current.inputs.filter((input) => input.props.id == 'comprehensiveInsurerCommissionValue')[0].resetValue();
    this.setState({ comprehensiveInsurerCommissionValueDisabled: value == '-' });
  }

  extWarrantyInsurerCommissionTypeChanged(name, value) {
    this.form.formsyForm.current.inputs.filter((input) => input.props.id == 'extWarrantyInsurerCommissionValue')[0].resetValue();
    this.setState({ extWarrantyInsurerCommissionValueDisabled: value == '-' });
  }

  /**
   * Render
   * @return ReactComponent
   */
  render() {
    if (!this.state.loanAgreementData) {
      return null;
    }

    /**
     * Still going strong, we can show the component
     */
    return (
      <Modal
        id="loanAgreementModal"
        bsSize="large"
        show={this.props.visible}
        onHide={this.props.hideAgreement}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>

        {(() => {
          if (this.state.loanAgreementData === false) {
            return (
              <Modal.Body className="modal-body-empty">
                <Loadr />
              </Modal.Body>
            );
          }
          return (
              <Modal.Body className="text-left">
                <Form
                  ref={(form) => { this.form = form; }}
                  id="loanAgreementForm"
                  layout="vertical"
                  onInvalidSubmit={this.onInvalidSubmit}
                  onValidSubmit={this.onValidSubmit}
                >
                  <Input
                    name="vehicleIsUsed"
                    type="hidden"
                    value={this.props.application.motorPurchaseVehicle.isUsed}
                    data-hj-masked
                    validations={{
                    }}
                  />
                  {
                    this.props.showContactDetails ? <ApplicationAgreementContactDetails broker={this.props.broker} applicants={this.props.application.applicants} /> : ''
                  }
                  <Row>
                    <Col sm={4}>
                      <Input
                        name="vehicleRegistrationNumber"
                        label="Registration no."
                        placeholder="eg: ABC123"
                        value={this.state.loanAgreementData.vehicleRegistrationNumber}
                        data-hj-masked
                        validationError="Registration no. must be between 2 and 8 characters"
                        validations={{
                          isValidRegistration: true,
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        name="vehicleRegistrationExpiry"
                        id="vehicleRegistrationExpiry"
                        label="Registration Expiry"
                        placeholder={`eg: ${Moment().add(6, 'M').format('MM/YYYY')}`}
                        value={this.state.loanAgreementData.vehicleRegistrationExpiry}
                        data-hj-masked
                        validationError="Registration exp. must be a valid registration expiry date"
                        validations={{
                          isValidRegistrationExpiry: true,
                        }}
                        changeCallback={this._formatDateInput}
                      />
                    </Col>
                    <Col sm={4}>

                      <Input
                        name="vehicleVendorName"
                        label="Vendor Name"
                        value={this.state.loanAgreementData.vehicleVendorName}
                        data-hj-masked
                        validationError="Vendor name is required"
                        validations={{
                          isRequired: true,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <Input
                        name="vehicleEngineNo"
                        label="Engine No."
                        value={this.state.loanAgreementData.vehicleEngineNo}
                        placeholder="eg: A12BC34567"
                        data-hj-masked
                        validationError="Engine No. should be at least 4 characters"
                        validations={{
                          minLength: 4,
                          isRequired: true,
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        name="vehicleVinNo"
                        label="VIN/Chassis No."
                        value={this.state.loanAgreementData.vehicleVinNo}
                        placeholder="eg: 1ABCD23DEFG456789"
                        data-hj-masked
                        validationError="Vin/Chassis No. should be 17 characters"
                        validations={{
                          isLength: 17,
                          isRequired: true,
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <Input
                        name="vehicleColour"
                        label="Colour"
                        value={this.state.loanAgreementData.vehicleColour}
                        data-hj-masked
                        validationError="Colour is required"
                        validations={{
                          isWords: true,
                          isRequired: true,
                        }}
                      />
                    </Col>

                  </Row>

                  {(() => {
                    if (this.state.loanAgreementData.comprehensiveInsurer || this.state.loanAgreementData.extWarrantyInsurer || this.state.loanAgreementData.hasInsurance) {
                      return (
                        <hr />
                      );
                    }
                  })()}

                  {(() => {
                    if (this.state.loanAgreementData.comprehensiveInsurer) {
                      return (
                        <div>
                          <Row>
                            {
                              (() => {
                                if (this.state.loanAgreementData.comprehensiveInsurer.id == -1) {
                                  return (
                                    <div>
                                      <Col sm={2}>
                                        <Select
                                          name="comprehensiveInsurerId"
                                          id="comprehensiveInsurerId"
                                          label="Comprehensive Insurer"
                                          validationError="The insurer is required"
                                          options={this.state.insurers}
                                          value={this.state.loanAgreementData.comprehensiveInsurer.id || ''}
                                          changeCallback={(field, value) => { this.updateInsurer('comprehensiveInsurer', value); }}
                                          validations={{
                                            isRequired: true,
                                          }}
                                          className="form-control"
                                        />
                                      </Col>

                                      <Col sm={3}>

                                        <Input
                                          name="comprehensiveInsurerName"
                                          id="comprehensiveInsurerName"
                                          validationError="The insurer is required"
                                          validations={{
                                            isRequired: true,
                                          }}
                                          data-hj-masked
                                          label="&nbsp;"
                                          value={this.state.loanAgreementData.comprehensiveInsurer.name || ''}
                                        />
                                      </Col>
                                    </div>
                                  );
                                }
                                return (
                                    <Col sm={5}>
                                      <Select
                                        name="comprehensiveInsurerId"
                                        id="comprehensiveInsurerId"
                                        label="Comprehensive Insurer"
                                        validationError="The insurer is required"
                                        options={this.state.insurers}
                                        value={this.state.loanAgreementData.comprehensiveInsurer.id || ''}
                                        changeCallback={(field, value) => { this.updateInsurer('comprehensiveInsurer', value); }}
                                        validations={{
                                          isRequired: true,
                                        }}
                                        className="form-control"
                                      />
                                    </Col>
                                );
                              })()
                            }


                            <Col sm={2}>

                              <Input
                                name="comprehensiveInsurerTerm"
                                id="comprehensiveInsurerTerm"
                                validationError="Term must be valid"
                                validations={{
                                  isRequired: true,
                                  isNumeric: true,
                                }}
                                data-hj-masked
                                label={(
                                <span>
                                {'Term '}
                                <small className="mute">(months)</small>
                                </span>
                                )}
                                value={this.state.loanAgreementData.comprehensiveInsurer.term || ''}
                              />
                            </Col>
                            <Col sm={3}>
                              <Select
                                name="comprehensiveInsurerCommissionType"
                                id="comprehensiveInsurerCommissionType"
                                label="Commission"
                                options={[
                                  { value: '-', label: 'Unascertainable' },
                                  { value: '%', label: 'Percentage (%)' },
                                  { value: '$', label: 'Fixed Amount ($)' },
                                ]}
                                value={this.state.loanAgreementData.comprehensiveInsurer.commissionType || '-'}
                                changeCallback={this.comprehensiveInsurerCommissionTypeChanged}
                                className="form-control"
                              />

                            </Col>
                            <Col sm={2}>

                              <Input
                                name="comprehensiveInsurerCommissionValue"
                                label="Value"
                                id="comprehensiveInsurerCommissionValue"
                                validationError="Commission amount must be valid"
                                validations="validComprehensiveInsurerCommissionValue"
                                disabled={this.state.comprehensiveInsurerCommissionValueDisabled}
                                data-hj-masked
                                value={this.state.loanAgreementData.comprehensiveInsurer.commissionValue || ''}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (this.state.loanAgreementData.extWarrantyInsurer) {
                      return (
                        <div>
                          <Row>
                            {
                              (() => {
                                if (this.state.loanAgreementData.extWarrantyInsurer.id == -1) {
                                  return (
                                    <div>
                                      <Col sm={2}>
                                        <Select
                                          name="extWarrantyInsurerId"
                                          id="extWarrantyInsurerId"
                                          label="Extended Warranty Insurer"
                                          validationError="The insurer is required"
                                          options={this.state.insurers}
                                          value={this.state.loanAgreementData.extWarrantyInsurer.id || ''}
                                          changeCallback={(field, value) => { this.updateInsurer('extWarrantyInsurer', value); }}
                                          validations={{
                                            isRequired: true,
                                          }}
                                          className="form-control"
                                        />
                                      </Col>

                                      <Col sm={3}>

                                        <Input
                                          name="extWarrantyInsurerName"
                                          id="extWarrantyInsurerName"
                                          validationError="The insurer is required"
                                          validations={{
                                            isRequired: true,
                                          }}
                                          data-hj-masked
                                          label="&nbsp;"
                                          value={this.state.loanAgreementData.extWarrantyInsurer.name || ''}
                                        />
                                      </Col>
                                    </div>
                                  );
                                }
                                return (
                                    <Col sm={5}>
                                      <Select
                                        name="extWarrantyInsurerId"
                                        id="extWarrantyInsurerId"
                                        label="Extended Warranty Insurer"
                                        validationError="The insurer is required"
                                        options={this.state.insurers}
                                        value={this.state.loanAgreementData.extWarrantyInsurer.id || ''}
                                        changeCallback={(field, value) => { this.updateInsurer('extWarrantyInsurer', value); }}
                                        validations={{
                                          isRequired: true,
                                        }}
                                        className="form-control"
                                      />
                                    </Col>
                                );
                              })()
                            }


                            <Col sm={2}>

                              <Input
                                name="extWarrantyInsurerTerm"
                                id="extWarrantyInsurerTerm"
                                validationError="Term must be valid"
                                validations={{
                                  isRequired: true,
                                  isNumeric: true,
                                }}
                                data-hj-masked
                                label="Term"
                                value={this.state.loanAgreementData.extWarrantyInsurer.term || ''}
                              />
                            </Col>
                            <Col sm={3}>
                              <Select
                                name="extWarrantyInsurerCommissionType"
                                id="extWarrantyInsurerCommissionType"
                                label="Commission"
                                options={[
                                  { value: '-', label: 'Unascertainable' },
                                  { value: '%', label: 'Percentage (%)' },
                                  { value: '$', label: 'Fixed Amount ($)' },
                                ]}
                                value={this.state.loanAgreementData.extWarrantyInsurer.commissionType || '-'}
                                changeCallback={this.extWarrantyInsurerCommissionTypeChanged}
                                className="form-control"
                              />

                            </Col>
                            <Col sm={2}>

                              <Input
                                name="extWarrantyInsurerCommissionValue"
                                label="Value"
                                id="extWarrantyInsurerCommissionValue"
                                validationError="Commission amount must be valid"
                                validations="validExtWarrantyInsurerCommissionValue"
                                disabled={this.state.extWarrantyInsurerCommissionValueDisabled}
                                data-hj-masked
                                value={this.state.loanAgreementData.extWarrantyInsurer.commissionValue || ''}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })()}


                  {(() => {
                    if (this.state.loanAgreementData.hasInsurance) {
                      return (

                        <div className="mt-md">
                          <Row>
                            <Col sm={6}>
                              <Checkbox
                                name="financialServiceGuideProvided"
                                id="financialServiceGuideProvided"
                                validations="isRequired"
                                validationError="Providing the Financial Services Guide is required"
                                value={this.state.loanAgreementData.financialServiceGuideProvided || false}
                                valueLabel={<span>Financial Services Guide</span>}
                                help="I understand that the Financial Services Guide is to be provided to the customer prior to giving them any form of financial product advice."
                              />
                            </Col>
                            <Col sm={6}>
                              <Checkbox
                                name="productDisclosureStatementProvided"
                                id="productDisclosureStatementProvided"
                                validations="isRequired"
                                validationError="Providing the Product Disclosure Statement is required"
                                value={this.state.loanAgreementData.productDisclosureStatementProvided || false}
                                valueLabel={<span>Product Disclosure Statement</span>}
                                help="I understand that the Product Disclosure Statement is to be provided to the customer prior to giving any form of financial product advice."
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })()}
                  <Row>
                    <Col className="text-right pt-md">
                      <Button type="submit" bsStyle="primary" id="modalButton" className="modalButton" disabled={this.state.isProcessing || this.props.isDocumentDownloadPending}>
                        {this.props.submitLabel}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
          );
        })()}
      </Modal>
    );
  }
}

// ApplicationAgreement.propTypes = {
//     application: PropTypes.object,
//     visible: PropTypes.bool,
//     hideAgreement: PropTypes.any,
//     actions: PropTypes.object
// };

function mapStateToProps({ reference, session }) {
  return {
    reference,
    broker: {
      email: session.details.email,
      mobile: session.details.mobile,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(referenceActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationAgreement);
