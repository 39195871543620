import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Select, Checkbox } from 'formsy-react-components';
import {bindActionCreators} from 'redux';
import * as referenceActions from '../../actions/referenceActions';
import Icon from '../../utils/icon';
import ReactTooltip from 'react-tooltip';

export interface MotorPrequalPropertyProps {
    namePrefix: String
    hasProperty: Boolean,
    hasMortgage: Boolean,    
}

type MotorPrequalPropertyState = {
    hasMortgageVisible: boolean,
  }

  
export class MotorPrequalProperty extends React.Component<MotorPrequalPropertyProps, MotorPrequalPropertyState> {
    constructor(props) {
        super(props);
        this.state = {
            hasMortgageVisible: false,
          };   
        this.showMortgage = this.showMortgage.bind(this);    
        this.hideMortgage = this.hideMortgage.bind(this);  
    }

    componentDidMount() {        
    }
    

    showMortgage() {
        this.setState({
            hasMortgageVisible: true,
            });       
      }
    hideMortgage() {  
        this.setState({
            hasMortgageVisible: false,
            });         
    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm={3} style={{ marginTop: '4px' }} >
                        <Checkbox
                            id="hasProperty"
                            name="hasProperty"
                            valueLabel={<span>Do the applicants own property?</span>}
                            value={this.props.hasProperty}                               
                            changeCallback={(_, value) => {
                                value
                                  ? this.showMortgage()
                                  : this.hideMortgage()
                              } }            
                        />
                    </Col>                    
                </Row>
                { this.state.hasMortgageVisible
                && (
                <Row>                    
                    <Col sm={3} style={{ marginTop: '4px' }}>                
                        <Checkbox
                            id="hasMortgage"
                            name="hasMortgage"                           
                            valueLabel={<span>Is there a mortgage on this property?</span>}
                            value={this.props.hasMortgage}                    
                        />
                    </Col>
                </Row>
                )}
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
      hasProperty: state.motorPrequal.hasProperty,
      hasMortgage: state.motorPrequal.hasMortgage,      
    };
  }

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...referenceActions}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MotorPrequalProperty);
