import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as queryString from "query-string";
import Guid from "guid";

import Storage from "../helpers/storage";

Axios.defaults.headers.common["X-Liberty-Source"] = "iq-web";

interface IConfig extends AxiosRequestConfig {
  cache: boolean | IConfigCache;
}

interface IConfigCache {
  expires: number;
}

// These environment variables are hardcoded by the Webpack EnvironmentPlugin
const isLocalDev = process.env.NODE_ENV === "development";
const useLocalDevPrequalificationApi = isLocalDev && !!process.env.IQWEB_PREQUALIFICATION_API_URL
const useLocalRateQuoteApi = isLocalDev && !!process.env.IQWEB_RATEQUOTE_API_URL
const useLocalDevApplicationApi = isLocalDev && !!process.env.IQWEB_APPLICATION_API_URL
const useLocalDevMotorApplicationApi = isLocalDev && !!process.env.IQWEB_MOTOR_APPLICATION_API_URL
const useLocalDevMotorOriginationApi = isLocalDev && !!process.env.IQWEB_MOTOR_ORIGINATION_API_URL
const useLocalDevVerificationApi = isLocalDev && !!process.env.IQWEB_VERIFICATION_API_URL
const useLocalDevIntroducersApi = isLocalDev && !!process.env.IQWEB_INTRODUCERS_API_URL

const isProduction = process.env.NODE_ENV === "production";
export class API {
  static rateQuoteBase =
    (window.location && queryString.parse(window.location.search).base) ||
    isProduction ? "https://rate-quote.liberty.com.au/" : "https://rate-quote.sandbox-liberty.com.au/";
  static base =
    (window.location && queryString.parse(window.location.search).base) ||
    isProduction ? "https://api-iq.liberty.com.au/" :
    "https://api-iq.sandbox-liberty.com.au/";

  static glassGuideBase = (window.location && queryString.parse(window.location.search).base) ||
  isProduction ? "https://vehicle-search-api.liberty.com.au/v1/"
  : "https://vehicle-search-api.sandbox-liberty.com.au/v1/";

  static rateQuoteSubmitUrl = API.rateQuoteBase + "v1/submit"

  static rateQuoteTrackingUrl = API.rateQuoteBase + "v1/tracking"

  static prequalificationApiBaseUrl = useLocalDevPrequalificationApi
    ? '/localdev-api-proxy/prequalification/'
    : API.base + "prequalification/"

  static rateQuoteApiBaseUrl = useLocalRateQuoteApi
    ? '/localdev-api-proxy/ratequote/'
    : API.rateQuoteBase
  
  static applicationApiBaseUrl = useLocalDevApplicationApi
    ? '/localdev-api-proxy/application/'
    : API.base + "application/"

  static motorApplicationApiBaseUrl = useLocalDevMotorApplicationApi
    ? '/localdev-api-proxy/motor-application/'
    : API.base + "motor-application/"

  static motorOriginationApiBaseUrl = useLocalDevMotorOriginationApi
    ? '/localdev-api-proxy/motor-origination/'
    : isProduction ? 'https://motor-submission-api.liberty.com.au/'
    : 'https://motor-submission-api.sandbox-liberty.com.au/'

  static verificationApiBaseUrl = useLocalDevVerificationApi
    ? '/localdev-api-proxy/id-verification/'
    :  API.base + "id-verification/"

  static introducersApiBaseUrl = useLocalDevIntroducersApi
    ? '/localdev-api-proxy/introducers/'
    : API.base

  static config(): IConfig {
    return {
      cache: true
    };
  }

  static get(url: string, config: IConfig = this.config()) : Promise<AxiosResponse<any>> {
    if (config.cache !== false) {
      if (Storage && Storage.getItem("cache")) {
        const cache = JSON.parse(Storage.getItem("cache"));

        if (cache[url]) {
          if (cache[url].expires <= new Date().getTime()) {
            Storage.removeItem("cache");
            return this.get(url, config);
          }

          return Promise.resolve(cache[url].response);
        }
      }
    }

    return Axios.get(url, config);
  }

  static post(url, data?: any) {
    if (!data) {
      data = {};
    }

    return Axios.post(url, data);
  }

  /**
   * Versioning
   */
  static getVersion() {
    const config = this.config();
    config.cache = false;

    return this.get(`${isProduction ? "https://iq.liberty.com.au/" : "https://iq.sandbox-liberty.com.au/"}version.txt`, config);
  }

  /**
   * Authentication
   */
  static getToken(data) {
    return this.post(`${this.base}auth/token`, data);
  }

  static getRenewToken() {
    const config = this.config();
    config.cache = false;

    return this.get(`${this.base}auth/renew`, config);
  }

  static getResetToken(data) {
    return this.post(`${this.base}auth/resetpassword/token`, data);
  }

  static getReset(data) {
    return this.post(`${this.base}auth/resetpassword`, data);
  }

  static getDetails() {
    const config = this.config();
    config.cache = false;

    return this.get(`${this.base}users/me`, config);
  }

  static getMigrated(email) {
    const config = this.config();
    config.cache = false;

    return this.get(`${this.base}users/me/migration/status?UserIdentifier=${email}`, config);
  }

  static getStorage(key) {
    if (Storage.getItem('isUseB2C') == "true" && Storage.getItem('session') == null) return;
    const config = this.config();
    config.cache = {
      expires: 168
    };

    return this.get(`${this.base}users/me/state?key=${key}`, config);
  }

  static setStorage(key, value) {
    return this.post(`${this.base}users/me/state`, {
      key,
      value
    });
  }

  static setDetailNumbers(data) {
    return this.post(`${this.base}users/me`, data);
  }

  static setDetailEmails(data) {
    return this.post(`${this.base}users/me`, data);
  }

  static setDetailAddress(data) {
    return this.post(`${this.base}users/me`, data);
  }

  static setDetailPassword(data) {
    return this.post(`${this.base}users/me/change-password`, data);
  }

  static setSubscription(data) {
    return this.post(`${this.base}users/me/subscription`, data);
  }

  static setAccreditation(data) {
    return this.post(`${this.base}users`, data);
  }

  static requestAccreditation() {
    return this.post(`${this.base}users/me/mpi`);
  }

  static requestInsurance(applicationNumber) {
    return this.post(`${this.base}users/me/mpi/${applicationNumber}`);
  }

  /**
   * Applicants
   */
  static setApplicantProperty(data) {
    return this.post(`${this.base}applicant/contact`, data);
  }

  /**
   * Applications
   */
  static getApplications() {
    const config = this.config();
    config.cache = false;
    return this.get(`${this.applicationApiBaseUrl}`, config);
  }

  static getPartialMotorApplications() {
    const config = this.config();
    config.cache = false;

    return this.get(
      `${this.motorOriginationApiBaseUrl}v2/application`,
      config
    );
  }

  static saveApplication(data) {
    return this.post(`${this.motorApplicationApiBaseUrl}conditionalupdate`, data);
  }

  static pollApplication(applicationNumber, attempt) {
    const config = this.config();
    config.cache = false;
    return this.get(
      `${this.motorApplicationApiBaseUrl}conditionalupdate/${applicationNumber}?attempt=${attempt}`,
      config
    );
  }

  /**
   * Documents
   */
  static getReferenceDocuments() {
    return this.get(`${this.base}documents/reference`);
  }

  static getReferenceDocument(document) {
    const config = this.config();
    config.responseType = "arraybuffer";
    config.timeout = 120 * 1000;
    config.cache = false;

    return this.get(
      `${this.base}documents/reference/${document.fileName}`,
      config
    );
  }

  static getApplicationDocument(applicationNumber, documentID, type) {
    const config = this.config();
    config.responseType = "arraybuffer";
    config.timeout = 120 * 1000;
    config.cache = false;

    switch (type) {
      case "publish":
        return this.get(
          `${this.base}documents/publish-docs/${documentID}`,
          config
        );
      default:
        return this.get(
          `${this.base}documents/pdf/${applicationNumber}/${documentID}`,
          config
        );
    }
  }

  static submitEsignatureRequest(data) {
    const config = this.config();
    config.timeout = 120 * 1000;
    return Axios.post(
      `${this.base}documents/esignature/sendDocument`,
      data,
      config
    );
  }

  static sendApplicantIDVerificationLink(applicantId, data) {
    return Axios.post(
      `${this.verificationApiBaseUrl}v2/session/${applicantId}`, data);
  }

  static getApplicantVerificationStatus(applicantId) {
    return Axios.get(
      `${this.verificationApiBaseUrl}v2/session/${applicantId}`);
  }


  /**
   * Originators
   */
  static getIntroducers(introducerEmail, searchTerm, page, pageSize) {
    const config = this.config();
    config.cache = false;
    
    var apiUrl = `${this.introducersApiBaseUrl}auth/originatorsbyidentity?`
    return this.get(`${apiUrl}IdentityEmail=${introducerEmail}&SearchTerm=${searchTerm}&Page=${page}&PageSize=${pageSize}`, config);
  }

  /**
   * Contacts
   */
  static getContacts() {
    return this.get(`${this.base}lookup/introducer-bdms`);
  }

  static getContactsDetails(contacts) {
    const query = new Array();
    contacts.map((contact) => {
      query.push(`StaffId=${contact}`);
    });
    return this.get(`${this.base}lookup/staff/details?${query.join("&")}`);
  }

  /**
   * Calculators
   */
  static calculateServiceability(data) {
    return this.post(`${this.prequalificationApiBaseUrl}serviceability`, data);
  }

  static callMeBack(data) {
    return this.post(`${this.prequalificationApiBaseUrl}callmeback`, data);
  }

  static calculateRate(data) {
    return this.post(`${this.rateQuoteSubmitUrl}`, data);
  }

  static calculateMotorRepayments(data) {
    const config = this.config();
    config.timeout = 120 * 1000;
    return Axios.post(`${this.prequalificationApiBaseUrl}motorprequal`, data, config);
  }

  static submitRateQuote(data) {
    const config = this.config();
    config.timeout = 120 * 1000;
    return Axios.post(`${this.rateQuoteSubmitUrl}`, data, config);
  }

  static trackRateQuote(requestId) {
    return Axios.get(`${this.rateQuoteTrackingUrl}`, { params: {requestId: requestId}});
  }

  static submitLfiProtectLead(data) {
    return this.post(`${this.prequalificationApiBaseUrl}lfiprotectlead/submit`, data);
  }

  static workshopWithBdm(data) {
    return this.post(`${this.prequalificationApiBaseUrl}serviceability/workshop-with-bdm`, data);
  }

  /**
   * FEEDBACK
   */
  static setFeedbackMessage(data) {
    return this.post(`${this.base}track-event/feedback-message_`, data);
  }

  /**
   * NVIC
   */
  static getNVIC(nvic) {
    return this.get(`${this.base}lookup/VehicleDetailByCriteria?NVIC=${nvic}`);
  }

  /**
   * Actions
   */
  static setActionComplete(applicationNumber, actionId) {
    return this.post(
      `${this.base}documents/action/${applicationNumber}/${actionId}`
    );
  }

  static getPrequalBaseUrl(isDev) { return isDev ? `${this.rateQuoteBase}` : `${this.base}users/me/`; }

  /**
   * Calculators
   */
  static searchPrequals(query, isDev) {
    const config = this.config();
    config.cache = false;

    return this.get(`${this.getPrequalBaseUrl(isDev)}prequalprofile/searchprofile?name=${query}`, config);
  }

  static getPrequal(id, isDev) {
    const config = this.config();
    config.cache = false;

    return this.get(`${this.getPrequalBaseUrl(isDev)}prequalprofile/${id}`, config);
  }

  static savePrequal(profileName, value, profileId, profileType, isDev) {
    profileId = profileId || Guid.create().value;
      
    return this.post(`${this.getPrequalBaseUrl(isDev)}prequalprofile`, {
      profileName,
      profileId,
      value,
      profileType
    });
  }

  static getLvrDataByPostcode(postcode) {
    const config = this.config();
    config.cache = {
      expires: 1
    };

    return this.get(
      `${this.prequalificationApiBaseUrl}postcode/v2/${postcode}`,
      config
    );
  }
}

export default API;
