import * as React from 'react';
import PropTypes from 'prop-types';
import Guid from 'guid';

import ServiceAbilityProductResidential from './serviceAbilityProductResidential';
import ServiceAbilityProductMotor from './serviceAbilityProductMotor';

export default class ServiceAbilityProducts extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      loans: [
        {
          loanId: Guid.create().value,
          'loanDetails.purpose': false,
          'loanDetails.riskGrade': 'AAA',
          'loanDetails.isSwift': false,
          'loanDetails.isHelia': false,
        },
      ],
    };

    this.addLoan = this.addLoan.bind(this);
    this.removeLoan = this.removeLoan.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props && this.props.data && this.props.data.loanDetails) {
      const { state } = this;
      state['loanDetails.purpose'] = this.props.data.loanDetails;
      state['loanDetails.riskGrade'] = this.props.data.loanDetails.riskGrade;
      state['loanDetails.isSwift'] = this.props.data.loanDetails.isSwift;
      state['loanDetails.isHelia'] = this.props.data.loanDetails.isHelia;
      this.setState(state);
    }
  }

  addLoan() {
    const { loans } = this.state;
    loans.push({
      loanId: Guid.create().value,
      'loanDetails.purpose': false,
      'loanDetails.riskGrade': 'AAA',
      'loanDetails.isSwift': false,
      'loanDetails.isHelia': false,
    });
    this.setState({
      loans,
    });
  }

  removeLoan(index) {
    const { loans } = this.state;
    loans.splice(index, 1);
    this.setState({
      loans,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section np">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Loan structure</h3>
          </div>
        </div>

        <div className="panel-section-body">
          <div className="panel-section-container m-h p-md bg-primary-50">
            {
              this.state.loans.map((loan, index) => {
                if (this.props.isMotorServiceability) {
                  return (
                    <ServiceAbilityProductMotor
                      key={`loan-${loan.loanId}`}
                      index={index}
                      data={this.props.data}
                      loans={[...this.state.loans]}
                      removeLoan={this.removeLoan}
                      id={this.props.id}
                    />
                  );
                }

                return (
                    <ServiceAbilityProductResidential
                      key={`loan-${loan.loanId}`}
                      index={index}
                      data={this.props.data}
                      loans={[...this.state.loans]}
                      removeLoan={this.removeLoan}
                      id={this.props.id}
                    />
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

ServiceAbilityProducts.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isMotorServiceability: PropTypes.bool,
};

ServiceAbilityProducts.defaultProps = {
  isMotorServiceability: false,
};
