import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { hashHistory, withRouter } from 'react-router';
import { Form } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';
import Guid from 'guid';
import * as Moment from 'moment';
import { 
  setInterval,
  clearInterval,
} from 'timers';
import Event3rdParty from '../../helpers/event3rdParty';
import { Overlay } from '../../utils';

import Mask from '../../helpers/mask';

import * as messages from '../../constants/messages';

import * as referenceActions from '../../actions/referenceActions';
import * as calculatorsActions from '../../actions/calculatorsActions';
import * as storageActions from '../../actions/storageActions';

import RateQuoteHeader from './rateQuoteHeader';
import RateQuoteProduct from './rateQuoteProduct';
import RateQuoteResult from './rateQuoteResult';

import CalculatorPane from '../../components/calculatorPane';
import CalculatorModal from '../../components/calculatorModal';
import CalculatorPrompt from '../../components/calculatorPrompt';
import RateQuoteApplicant from './rateQuoteApplicant';
import { ProfileType } from '../../actions/prequalActions';
import mapResidentialApplication from '../../data/mappings/residentialRateQuote';


export class RateQuotePage extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      init: false,
      result: {
        baseRate: 0,
        totalRate: 0,
        loadings: null,
        lVR: 0,
        reason: null,
      },
      data: {
        'rateLoanDetails.isSwift': false,
        'rateLoanDetails.loanAmount': 0,
        'rateLoanDetails.purchaseAmount': '0',
        'rateLoanDetails.purpose': 0,
        'rateLoanDetails.repayment': 0,
        'rateLoanDetails.structure': 0,
        'rateLoanDetails.verification': 0,
      },
      modal: false,
      prompt: false,
      profileName: null,
      profileId: null,
      lastUpdated: null,
      hasChanged: false,
      invalid: true,
      delay: 1000,
      requestId: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);

    this.setProcessing = this.setProcessing.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setPrompt = this.setPrompt.bind(this);
    this.setPrequal = this.setPrequal.bind(this);
    this.savePrequal = this.savePrequal.bind(this);
    this.dismissPrequal = this.dismissPrequal.bind(this);
    this.setChange = this.setChange.bind(this);
    this.pollRateQuote = this.pollRateQuote.bind(this);
    this.stopPolling = this.stopPolling.bind(this);
    this.mapForm = this.mapForm.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.id) {
      this.props.actions.getPrequal(this.props.params.id)
        .then((result) => {
          if (result.data) {
            this.setState({
              init: true,
              profileName: result.data.profileName,
              profileId: result.data.profileId,
              lastUpdated: result.data.lastUpdated,
              data: { ...this.state.data, ...JSON.parse(result.data.value) },
            }, this.setPrequal);
          } else {
            this.setState({
              init: true,
            });
          }
        });
    } else {
      this.setState({
        init: true,
      });
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, (route) => {
      if (this.state.hasChanged) {
        this.setPrompt(route);
        return false;
      }

      return true;
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setChange(hasChanged) {
    this.setState({
      hasChanged,
    });
  }

  mapForm(data) {
    data.rateLoanDetails.purchaseAmount = parseFloat(data.rateLoanDetails.purchaseAmount.toString().replace(/[^0-9.]/g, ''));
    data.rateLoanDetails.loanAmount = parseFloat(data.rateLoanDetails.loanAmount.toString().replace(/[^0-9.]/g, ''));

    data.rateLoanDetails.purpose = parseInt(data.rateLoanDetails.purpose);
    data.rateLoanDetails.verification = parseInt(data.rateLoanDetails.verification);
    data.rateLoanDetails.structure = parseInt(data.rateLoanDetails.structure);
    data.rateLoanDetails.repayment = parseInt(data.rateLoanDetails.repayment);
    data.rateLoanDetails.productGroupId = parseInt(data.rateLoanDetails.productGroupId);

    data.rateLoanDetails.isSwift = data.rateLoanDetails.isSwift ? 1 : 0;


    if (data.applicants) {
      data.applicants.map((applicant, index) => {
        applicant.key = index + 1;

        // Date of Birth
        if (applicant.dateOfBirth && applicant.dateOfBirth !== '') {
          applicant.dateOfBirth = Moment.utc(applicant.dateOfBirth, 'DD/MM/YYYY');            
        } else {
          applicant.dateOfBirth = null;
        }

        applicant.gender = applicant.gender == 'M' || applicant.gender == 'F' ? applicant.gender : null;

        applicant.dependants = parseInt(applicant.dependants);
        applicant.dependants = isNaN(applicant.dependants) ? null : applicant.dependants;


        // credit history start
        // creditType, eventtype, status, amount, dateIncurred, dateRectified
        if (applicant.creditHistory) {
          applicant.creditHistory.vedaScore = (!!applicant.creditHistory.vedaScore) ? (parseFloat(Mask.clean(applicant.creditHistory.vedaScore)) || null) : null;
          applicant.creditHistory.numberOfEnquiries = !!applicant.creditHistory.numberOfEnquiries ? (parseFloat(Mask.clean(applicant.creditHistory.numberOfEnquiries)) || null) : null;

          // credit events
          if (applicant.creditHistory.creditEvents && applicant.creditHistory.creditEvents.length) {
            applicant.creditHistory.creditEvents.map((creditEvent) => {
              if (creditEvent.amount) {
                creditEvent.amount = parseFloat(Mask.clean(creditEvent.amount));
              }

              if (creditEvent.dateIncurred && creditEvent.dateIncurred !== '') {
                creditEvent.dateIncurred = Moment.utc(creditEvent.dateIncurred, 'DD/MM/YYYY');
              } else {
                creditEvent.dateIncurred = null;
              }

              if (creditEvent.dateRectified && creditEvent.dateRectified !== '') {
                creditEvent.dateRectified = Moment.utc(creditEvent.dateRectified, 'DD/MM/YYYY');
              } else {
                creditEvent.dateRectified = null;
              }

              // format dateIncurred, dateRectified
            });
          } else {
            applicant.creditHistory.creditEvents = [];
          }
        } else {
          applicant.creditHistory = {};
          applicant.creditHistory.vedaScore = null;
          applicant.creditHistory.numberOfEnquiries = null;
          applicant.creditHistory.creditEvents = [];
        }
        // credit history end

        // employment history
        if (applicant.employmentHistory) {
          if (applicant.employmentHistory.employmentItems && applicant.employmentHistory.employmentItems.length) {
            applicant.employmentHistory.employmentItems.map((employmentItem) => {
              employmentItem.years = parseInt(employmentItem.years) || 0;
              employmentItem.months = parseInt(employmentItem.months) || 0;
              if (employmentItem.endDate && employmentItem.endDate !== '') {
                employmentItem.endDate = Moment.utc(employmentItem.endDate, 'MM/YYYY');
              } else {
                employmentItem.endDate = null;
              }
            });
          } else {
            applicant.employmentHistory.employmentItems = [];
          }
        } else {
          applicant.employmentHistory = {};
          applicant.employmentHistory.employmentItems = [];
        }
        // employment hx end
      });
    }
    return mapResidentialApplication(this.state.requestId, data);
  }

  handleSubmit(data) {
    this.setProcessing(true);
    this.setState({ requestId: Guid.create().toString() }, () => {
      const payload = this.mapForm(data);
      this.setState(payload);
      // 3rd party
      Event3rdParty.send('rate-quote');

      this.props.actions.calculateRate(payload)
        .then(() => {
          this.interval = setInterval(this.pollRateQuote, this.state.delay);
        })
        .catch(() => {
          this.setProcessing(false);
          Notify.show('An error occured. ', 'error', 3000);
        });
    });
  }

  handleInvalidSubmit() {
    this.setState({
      invalid: true,
      result: {
        baseRate: 0,
        totalRate: 0,
        loadings: null,
        lVR: 0,
        reason: null,
      },
    });
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  dismissPrequal() {
    this.setState({
      hasChanged: false,
    });
  }

  savePrequal(route) {
    if (!this.state.profileName) {
      this.setModal(true);
    } else {
      this.setProcessing('Saving prequalification...');

      const profileId = this.state.profileId || Guid.create().value;
      const profileData = { ...(this.state.data || {}), ...this.form.formsyForm.current.getModel() };

      if (this.state.result) {
        profileData.rateLoanDetails.interestRate = (this.state.result.totalRate * 100).toFixed(2);
      }

      const payload = profileData;

      // 3rd party
      Event3rdParty.send('save-rate-quote');

      this.props.actions.savePrequal(this.state.profileName, payload, profileId, ProfileType.ResidentialRateQuote)
        .then(() => {
          Notify.show(messages.MESS_SUCCESS, 'success');
          this.setState({
            profileId,
            hasChanged: false,
          }, () => {
            if (route) {
              hashHistory.push(route);
            }
          });
        })
        .catch()
        .then(() => {
          this.setProcessing(false);
        });
    }
  }

  setModal(modal) {
    this.setState({
      modal,
    });
  }

  setPrompt(prompt) {
    this.setState({
      prompt,
    });
  }

  setPrequal() {
    if (this.state.data && this.state.data.rateLoanDetails) {
      const data = Mask.flatten(this.state.data);

      this.form.formsyForm.current.reset(data);

      setTimeout(() => {
        this.setState({
          hasChanged: false,
        });

        if (!this.form.formsyForm.current.inputs.filter((input) => !input.isValid()).length) {
          this.handleSubmit(this.form.formsyForm.current.getModel(), true);
        }
      });
    }
  }

  setProfile(data) {
    this.setState({
      profileName: data.name,
    }, this.savePrequal);
  }

  stopPolling() {
    this.state.count = 0;
    clearInterval(this.interval);
  }

  mapResponse(data) {
    const responseResult = data.result;

    const form = this.form.formsyForm.current.getModel();
    const purchaseAmount = parseFloat(form.rateLoanDetails.purchaseAmount.toString().replace(/[^0-9.]/g, ''));
    const loanAmount = parseFloat(form.rateLoanDetails.loanAmount.toString().replace(/[^0-9.]/g, ''));

    const investor = responseResult.loadings?.investor;
    const professionalInvestor = responseResult.loadings?.professionalInvestor;
    const interestOnly = responseResult.loadings?.interestOnly;
    const swift = responseResult.loadings?.swift;
    
    const result = !responseResult.declineMessage 
      ? {
        baseRate: responseResult.baseRate / 100,
        totalRate: responseResult.totalRate / 100,
        lVR: loanAmount / purchaseAmount,
        riskGrade: responseResult.riskGrade,
        reason: '',
      } 
      : { 
        reason: responseResult.declineMessage, 
      };

    if (investor || professionalInvestor || interestOnly || swift) result.loadings = {};
    if (investor) result.loadings.investor = investor;
    if (professionalInvestor) result.loadings.professionalInvestor = professionalInvestor;
    if (interestOnly) result.loadings.interestOnly = interestOnly;
    if (swift) result.loadings.swift = swift;

    this.setState({
      invalid: !!responseResult.declineMessage,
      result,
    });
  }

  pollRateQuote() {
    this.props.actions.trackRateQuote(this.state.requestId)
      .then(({ data }) => {
        if (this.state.count > 120) { // stop polling and timeout after 2 minutes 
          Notify.show('The request timed out.', 'error', 3000);
          return true;
        }
        let stopPolling = true;
        switch (data?.status) {
          case 'Successful':
            this.mapResponse(data);
            break;
          case 'InProgress':
          case 'FailedButRetrying':
            this.setState({
              count: this.state.count + 1,
            });
            stopPolling = false;
            break;
          case 'Failed':
            Notify.show('An error occurred.', 'error', 3000);
            break;
          default:
            Notify.show('An unexpected error occured.', 'error', 3000);
            break;
        }
        return stopPolling;
      })
      .then((result) => {
        if (result == true) {
          this.setProcessing(false);
          this.stopPolling();
        }
      })
      .catch(() => {
        this.setProcessing(false);
        this.stopPolling();
        Notify.show('An error occured. ', 'error', 3000);
      });
  }
  
  /**
   * Render
   */
  render() {
    if (!this.state.init) {
      return null;
    }

    return (
      <div>
        <CalculatorModal visible={this.state.modal} setModal={this.setModal} setProfile={this.setProfile} />
        <CalculatorPane id={this.state.profileId} name={this.state.profileName} saved={this.state.lastUpdated} setModal={this.setModal} active="rate" />

        <CalculatorPrompt visible={this.state.prompt} setPrompt={this.setPrompt} savePrequal={this.savePrequal} dismissPrequal={this.dismissPrequal} />

        <Row>
          <Col sm={12} className="main-panel">
            {
              (() => {
                if (this.state.processing) {
                  return (
                    <Overlay>{this.state.processing}</Overlay>
                  );
                }
              })()
            }

            <Form
              onValidSubmit={this.handleSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}
              // onChange={this.handleChange}
              layout="vertical"
              ref={(form) => { this.form = form; }}
            >
              <div className="panel panel-default">
                <div className="panel-body p-h">
                  <RateQuoteHeader />
                  <RateQuoteApplicant 
                    apps={this.state.data.applicants} 
                  />
                  <RateQuoteProduct
                    data={this.state.data}
                  />

                  <RateQuoteResult 
                    invalid={this.state.invalid} 
                    result={this.state.result} 
                    data={this.state.data} 
                    calculate={this.calculate} 
                    savePrequal={this.savePrequal} />
                </div>
              </div>

            </Form>

          </Col>
        </Row>
      </div>
    );
  }
}

RateQuotePage.propTypes = {
  actions: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any,
  route: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
    calculators: state.calculators,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions, ...calculatorsActions, ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RateQuotePage));
