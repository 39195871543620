import * as React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  /**
     * Base classname
     * @type {String}
     */
  let className = 'btn';

  /**
     * Create variables
     * @type {Object}
     */
  let id; let onClick; let type; const
    opts = {};

  /**
     * Check wether the button needs to be disabled
     * @param  {Bool}
     */
  if (props.disabled) {
    opts.disabled = 'disabled';
    className += ' disabled';
  }

  /**
     * Check what the classname needs to be
     * @param  {String}
     */
  if (props.bsStyle) {
    className += ` btn-${props.bsStyle}`;
  } else {
    // Fall back to default
    className += ' btn-default';
  }

  /**
     * Check wether the button needs to be block style
     * @param  {Bool}
     */
  if (props.block) {
    className += ' btn-block';
  }

  /**
     * Check wether the button needs any custom classess
     * @param  {String}
     */
  if (props.className) {
    className += ` ${props.className}`;
  }

  /**
     * Check wether the button needs an id
     * @param  {String}
     */
  if (props.id) {
    id = props.id;
  }

  /**
     * Check wether the button needs to be onClick handler
     * @param  {Bool}
     */
  if (props.onClick) {
    onClick = props.onClick;
  }

  /**
     * Check wether the button needs to be of a specific type
     * @param  {Bool}
     */
  if (props.type) {
    type = props.type;
  } else {
    // Fall back to submit
    type = 'submit';
  }

  /**
     * Return the element
     * @return {String}
     */
  return (
      <button {...opts} type={type} className={className} id={id} onClick={onClick}>
        {props.children}
      </button>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  bsStyle: PropTypes.string,
  disabled: PropTypes.any,
  block: PropTypes.any,
  id: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;
