import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, hashHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';
import objectAssign from 'object-assign';
import Event3rdParty from '../../helpers/event3rdParty';
import { Overlay } from '../../utils';

import * as sessionActions from '../../actions/sessionActions';

import * as errors from '../../constants/errors';

import AccreditationProgress from './accreditationProgress';
import AccreditationPersonal from './accreditationPersonal';
import AccreditationCompany from './accreditationCompany';
import AccreditationProduct from './accreditationProduct';
import AccreditationComplete from './accreditationComplete';
import AccreditationNavigation from './accreditationNavigation';
import AccreditationStart from './accreditationStart';

import Storage from '../../helpers/storage';


export class AccreditationPage extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      processing: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleInvalid = this.handleInvalid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.saveFormData = this.saveFormData.bind(this);
    this.loadFormData = this.loadFormData.bind(this);
  }

  componentDidMount() {
    this.loadFormData();
    Event3rdParty.accreditation('false');
  }

  UNSAFE_componentWillReceiveProps() {
    this.loadFormData();
  }

  handleChange(element, value) {
    const form = this.form.formsyForm.current;

    setTimeout(() => {
      switch (element) {
        case 'productResi': {
          if (!value) {
            if (form.getModel().productSMSF) {
              form.inputs.map((input) => {
                if (input.props.id == 'productSMSF') {
                  input.setValue(false);
                }
              });
            }
          }
          break;
        }

        case 'productSMSF': {
          if (value) {
            if (!form.getModel().productResi) {
              form.inputs.map((input) => {
                if (input.props.id == 'productResi') {
                  input.setValue(true);
                }
              });
            }
          }
          break;
        }
      }
    });
  }

  handleClick(step) {
    switch (this.props.params.step) {
      case 'personal': {
        break;
      }

      case 'company': {
        if (step == 'personal') {
          hashHistory.push(`/get-accredited/${step}`);
        }
        break;
      }

      case 'product': {
        if (step == 'personal' || step == 'company') {
          hashHistory.push(`/get-accredited/${step}`);
        }
        break;
      }
    }
  }

  handleSubmit() {
    const accreditation = JSON.parse(Storage.getItem('accreditation'));
    this.setState({ processing: true });

    Event3rdParty.send('accreditation');

    this.props.actions.setAccreditation(accreditation)
      .then(() => {
        Storage.removeItem('accreditation');
        hashHistory.push('/get-accredited/done');
        Event3rdParty.accreditation('true');
      })
      .catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error');
      })
      .then(() => {
        this.setState({ processing: false });
      });
  }

  handleInvalid() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  handleNext() {
    this.saveFormData();

    switch (this.props.params.step) {
      case '': {
        hashHistory.push('/get-accredited/personal');
        break;
      }
      case 'personal': {
        hashHistory.push('/get-accredited/company');
        break;
      }
      case 'company': {
        hashHistory.push('/get-accredited/product');
        break;
      }
      case 'product': {
        this.handleSubmit();
        break;
      }
    }
  }

  handlePrevious() {
    switch (this.props.params.step) {
      case 'personal': {
        hashHistory.push('/get-accredited');
        break;
      }
      case 'company': {
        hashHistory.push('/get-accredited/personal');
        break;
      }
      case 'product': {
        hashHistory.push('/get-accredited/company');
        break;
      }
    }
  }

  loadFormData() {
    const accreditation = JSON.parse(Storage.getItem('accreditation'));
    if (accreditation) {
      const form = this.form.formsyForm.current;
      setTimeout(() => { form.reset(accreditation); });
    }
  }

  saveFormData() {
    const form = this.form.formsyForm.current;
    const accreditation = JSON.parse(Storage.getItem('accreditation')) || {};
    Storage.setItem('accreditation', JSON.stringify(objectAssign({}, { ...accreditation, ...form.getModel() })));
  }

  render() {
    return (
      <Form
        layout="vertical"
        ref={(form) => { this.form = form; }}
        onValidSubmit={this.handleNext}
        onInvalidSubmit={this.handleInvalid}
      >

        <Row>
          <Col sm={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
            <div className="text-center mt-lg mb-lg">
              <Link to="/login">
                <img alt="" src={`/${window.iqUIVersion}/assets/img/logo-liberty-financial.png`} className="mt-lg ml-md-n" width="180" />
              </Link>
            </div>

            <div className="panel panel-default mb-lg">
              <div className="panel-body p-lg">
                <h2 className="mb-md mt-sm text-center">Get accredited</h2>

                <AccreditationProgress step={this.props.params.step} handleClick={this.handleClick} />

                <AccreditationStart visible={!this.props.params.step} handleNext={this.handleNext} />

                <AccreditationPersonal visible={this.props.params.step == 'personal'} handleChange={this.handleChange} />
                <AccreditationCompany visible={this.props.params.step == 'company'} handleChange={this.handleChange} />
                <AccreditationProduct visible={this.props.params.step == 'product'} handleChange={this.handleChange} />

                <AccreditationComplete visible={this.props.params.step == 'done'} />
              </div>

              <AccreditationNavigation visible={(this.props.params.step && this.props.params.step != 'done')} step={this.props.params.step} handleNext={this.handleNext} handlePrevious={this.handlePrevious} />
            </div>
          </Col>
        </Row>

        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Submitting accreditation...</Overlay>
              );
            }
          })()
        }
      </Form>
    );
  }
}

AccreditationPage.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  params: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccreditationPage);
