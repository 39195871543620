import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ReactTooltip from 'react-tooltip';
import {
  Icon, Loadr, Money, Percentage, DateTime,
} from '../../utils';


import ApplicationBarItem from './applicationBarItem';
import ApplicationBarIcon from './applicationBarIcon';
import ApplicationBarApplicant from './applicationBarApplicant';
import { ShouldDisplayCommission } from './commissionChecker';

const Moment = require('moment');

export class ApplicationBar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.props = props;
    this.state = {
      overlay: false,
      scroll: 0,
    };
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  getProgress(application) {
    if (application.applicationState == 0) {
      return 100;
    }

    const total = application.applicationStatusOverview.filter((x) => x.code < 500);
    const completed = total.filter((x) => x.completed);
    return Math.round((completed.length / total.length) * 100);
  }

  getProgressColor(application) {
    if ([101, 102, 103, 201, 202, 203].indexOf(application.applicationStatus.code) > -1) {
      return 'primary';
    }

    return application.applicationStatus.message && application.applicationStatus.message.introducerAction ? 'warning' : 'success';
  }

  getActionsOutstanding() {
    return this.props.application.actions.filter((action) => {
      const pendingIds = [];
      this.getActionsPending().map((pending) => {
        pendingIds.push(pending.id);
      });

      return pendingIds.indexOf(action.id) == -1;
    });
  }

  getActionsPending() {
    return this.props.application.actions.filter((action) => {
      if (action.history && action.history.length) {
        if (action.staffNotes && action.staffNotes.length) {
          if (Moment.utc(action.history.slice().reverse()[0].timestamp).format('x') <= Moment.utc(action.staffNotes.slice().reverse()[0].timestamp).format('x')) {
            return false;
          }
        }

        return true;
      }

      return false;
    });
  }

  render() {
    return (
      <div id={`applicationBar-${this.props.position}`} className={`bar bar-${this.props.position} ${this.props.processing ? 'processing' : ''} ${this.props.processing && this.props.position == 'static' ? 'transparent' : ''}`}>

        <div className="container r">

          <Link to={`/applications${!this.props.update ? `/${this.props.application.applicationNumber}` : ''}`} className="bar-back"><Icon glyph="arrow-left" /></Link>

          <div className={`progress-circle progress-${this.getProgressColor(this.props.application)} progress-${this.getProgress(this.props.application)}`}>
            <ApplicationBarIcon type={this.props.application.applicationType} />

            <div className="progress-circle-fill">
              <div className="mask left" />
              <div className="mask right" />
            </div>
          </div>
          <div className="bar-details">

            <h4 className="mt-h mb-h ellipsis" data-hj-masked>
              {
                this.props.application.applicants.map((applicant, idx) => {
                  const details = JSON.parse(JSON.stringify(applicant));
                  details.applicationNumber = this.props.application.applicationNumber;
                  return (
                    <ApplicationBarApplicant key={`applicant-${idx}`} position={this.props.position} applicant={details} />
                  );
                })
              }
            </h4>

            <p className="mb-h mr-md-n">
              <small className="ib mr-xs mute"> Application </small>
              <strong className="ib">{this.props.application.applicationNumber}</strong>

              <small className="mute mr-xs ml-sm hidden-xs"> Submitted </small>
              <strong>
                <DateTime className="hidden-xs">{this.props.application.applicationDate}</DateTime>
              </strong>
            </p>

          </div>

          {
                  (() => {
                    if (this.props.update && this.props.application.applicationType == 2 && this.props.application.applicationState == 1) {
                      return (
                        <div>
                          <Link className="btn btn-secondary btn-circle bar-btn visible-xs" to={`/applications/${this.props.application.applicationNumber}/update`}>
                              <Icon glyph="edit" />
                          </Link>

                          <Link className="btn btn-secondary bar-btn hidden-xs" to={`/applications/${this.props.application.applicationNumber}/update`}>
                            Update Indicative
                          </Link>
                        </div>
                      );
                    }
                  })()
              }

          {
                (() => {
                  if (this.props.processing) {
                    return (
                      <div className="hidden-xs mt-md-n">
                        <Loadr sm right className="mr-md" />
                      </div>
                    );
                  }
                })()
            }

          {
                (() => {
                  if (this.props.update) {
                    return (
                      <div className="bar-tabs">
                        <div className={`bar-tab ${this.props.tab == 'summary' ? 'active' : ''}`} onClick={() => { this.props.setTab('summary'); }}>Summary</div>

                        {
                          (() => {
                            if (this.props.application.applicationState) {
                              return (
                                <div className={`bar-tab ${this.props.tab == 'actions' ? 'active' : ''}`} onClick={() => { this.props.setTab('actions'); }}>
                                      Outstanding actions
                                  {
                                    (() => {
                                      if (this.getActionsOutstanding().length) {
                                        return (
                                          <span className="badge">{this.getActionsOutstanding().length}</span>
                                        );
                                      }
                                    })()
                                  }
                                </div>
                              );
                            }
                          })()
                        }

                      </div>
                    );
                  }
                  if (this.props.application.applicationType == 2) {
                    if (this.props.application.loanAssessmentResult && this.props.application.applicationStatus.code >= 230) { // pre-approved
                      return (
                        <div className="pb-lg pl-md pr-md ml-md-n mr-md-n scroll-x" id="applicationInfoWrapper" onScroll={this.handleScroll}>

                          <div className={`animate ${this.props.processing ? 'transparent mt-lg' : ''}`}>
                            <div id="applicationInfo" style={{ padding: '4px 0 0 95px' }}>

                              <ApplicationBarItem label="Repayments">
                                <Money>{this.props.application.loanAssessmentResult.repaymentAmount}</Money>
                              </ApplicationBarItem>

                              <ApplicationBarItem label="Rate">
                                <Percentage>{this.props.application.loanAssessmentResult.interestRate}</Percentage>
                              </ApplicationBarItem>

                              <ApplicationBarItem label="Term">
                                {this.props.application.loanAssessmentResult.loanTerm}
                                {' years'}
                              </ApplicationBarItem>

                              <ApplicationBarItem label="Amount">
                                <Money>{this.props.application.loanAssessmentResult.requestLoanAmount}</Money>
                              </ApplicationBarItem>

                              { ShouldDisplayCommission(this.props.application)
                                ? (
                                  <ApplicationBarItem label="Commission">
                                    <Money>{this.props.application.loanAssessmentResult.commission}</Money>
                                  </ApplicationBarItem>
                                )
                                : ''}
                              <ApplicationBarItem label="Product">
                                {this.props.application.productName || 'N/A'}
                              </ApplicationBarItem>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className="pb-lg pl-md pr-md ml-md-n mr-md-n scroll-x" id="applicationInfoWrapper" onScroll={this.handleScroll}>

                        <div id="applicationInfo" style={{ padding: '4px 0 0 95px' }}>
                          {
                            (() => {
                              if (this.props.application.loanAssessmentResult) {
                                return (
                                  <ApplicationBarItem label="Term">
                                    {this.props.application.loanAssessmentResult.loanTerm}
                                    {' years'}
                                  </ApplicationBarItem>
                                );
                              }
                            })()
                          }

                          <ApplicationBarItem label="Amount">
                            <Money>{this.props.application.amount}</Money>
                          </ApplicationBarItem>

                          <ApplicationBarItem label="Product">
                            {this.props.application.productName || 'N/A'}
                          </ApplicationBarItem>
                        </div>
                      </div>
                    );
                  }
                })()
            }
        </div>
      </div>
    );
  }
}

ApplicationBar.propTypes = {
  application: PropTypes.object.isRequired,
  position: PropTypes.any,
  processing: PropTypes.number,
  update: PropTypes.bool,
  toggleSplit: PropTypes.func,
  expanded: PropTypes.bool,
};

export default ApplicationBar;
