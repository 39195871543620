/**
 * UserIDs who have access to the Residential Submission
 */
export const RESI_SUBMISSION = [
  4846,
  54597,
  57431,
  61362,
  63761,
  66875,
  67025,
  67261,
  69014,
  72463,
  73638,
  49535,
  31365,
  40438,
  61573,
  53218,
  45966,
  68983,
  70026,
  57803,
  2313,
  61162,
  70438,
  70958,
  51111,
  50097,
  70782,
  69612,
  53215,
  69718,
  63749,
  68289,
  68309,
  68634,
  66057,
  67538,
  70937,
  67283,
  62453,
  51923,
  62503,
  49191,
  63026,
  62880,
  60215,
  55351,
  49250,
  56636,
  47860,
  57948,
  40823,
  44691,
  56948,
  57127,
  49161,
  48044,
  31183,
  71306,
  69617,
  38500,
  39644,
  47450,
  51224,
  55904,
  40374,
  67553,
  59885,
  70296,
  47332,
  49942,
  57923,
  62456,
  60441,
  70623,
  48361,
  70259,
  55630,
  7954,
  24950,
  26065,
  9260,
  59497,
  59527,
  64292,
  65315,
  56753,
  64796,
  71506,
  63216,
  65178,
  42452,
  58824,
  60334,
  63119,
  58705,
  67774,
  70896,
  69518,
  68457,
  67988,
  69022,
  68730,
  70502,
  67887,
  67903,
  68717,
  68710,
  67680,
  68354,
  69462,
  69103,
  67880,
  69071,
  69094,
  68475,
  67875,
  67942,
  67776,
  62485,
  66833,
  10844,
  46478,
  68777,
  66181,
  45916,
  48384,
  10269,
  44692,
  33015,
  65121,
  68684,
  68809,
  46858,
  50841,
  46891,
  69597,
  69616,
  60975,
  70055,
  52074,
  68611,
  39875,
  19738,
  59589,
  59638,
  59595,
  43434,
  9274,
  52354,
  41927,
  68773,
  50496,
  41924,
  63213,
  66209,
  66878,
  67111,
  26395,
  34624,
  63627,
  68828,
  67463,
  65293,
  39541,
  51489,
  65780,
  17482,
  57434,
  69556,
  67546,
  67529,
  68413,
  64589,
  43273,
  25366,
  25368,
  64039,
  62110,
  46396,
  50398,
  69183,
  69168,
  62186,
  69088,
  65238,
  55662,
  70610,
  63544,
  40494,
  61403,
  57577,
  64562,
  61358,
  65731,
  53370,
  65905,
  52496,
  52497,
  64716,
  58289,
  62347,
  60348,
  60350,
  57849,
  70386,
  66227,
  59998,
  71491,
  5899,
  39180,
  68215,
  53945,
  61584,
  66911,
  58684,
  70991,
  55184,
  60284,
  67874,
  45945,
  12320,
  37180,
  59738,
  61844,
  58470,
  58864,
  57675,
  60052,
  69654,
  67194,
  69630,
  64182,
  69445,
  68123,
  68239,
  37429,
  50426,
  49317,
  39140,
  61665,
  70759,
  68090,
  70079,
  55445,
  68384,
  68373,
  4553,
  12048,
  47202,
  68678,
  59377,
  62411,
  60159,
  20966,
  4225,
  66588,
  64133,
  67421,
  68056,
  70443,
  70442,
  47448,
  49619,
  68542,
  63610,
  69871,
  68943,
  69364,
  58415,
  63246,
  63002,
  66355,
  66382,
  66374,
  63479,
  48572,
  54124,
  67678,
  68485,
  64245,
  52169,
  19814,
  48927,
  52346,
  52166,
  27507,
  66046,
  55652,
  63891,
  37513,
  70641,
  49821,
  51724,
  64964,
  41709,
  70492,
  43038,
  68618,
  54617,
  48456,
  50059,
  53289,
  61592,
  37901,
  38017,
  59490,
  38275,
  39209,
  43919,
  54123,
  10521,
  47350,
  61855,
  60408,
  59910,
  39134,
  68174,
  48965,
  65625,
  39123,
  68170,
  41066,
  25904,
  67267,
  63765,
  64998,
  70786,
  66321,
  69796,
  24715,
  59963,
  14611,
  59324,
  64037,
  43927,
  65193,
  6842,
  47682,
  53950,
  56947,
  64099,
  36047,
  64282,
  51173,
  50320,
  57017,
  57018,
  66204,
  61520,
  57445,
  57396,
  65997,
  68264,
  56813,
  59678,
  68608,
  33323,
  25725,
  68397,
  66691,
  64759,
  66964,
  54922,
  54095,
  60263,
  45781,
  55768,
  60567,
  57951,
  42798,
  42296,
  64415,
  69592,
  39869,
  12252,
  20511,
  66073,
  64777,
  64597,
  65833,
  50086,
  62796,
  14731,
  35675,
  58856,
  63236,
  48231,
  52844,
  60040,
  55146,
  65460,
  63975,
  66781,
  17105,
  67533,
  46479,
  60458,
  8833,
  25557,
  43865,
  69242,
  50817,
  64293,
  64861,
  64575,
  67499,
  64702,
  71461,
  65786,
  39104,
  62439,
  26128,
  53474,
  51600,
  25750,
  67783,
  68028,
  47783,
  5387,
  48171,
  63461,
  26125,
  33508,
  34257,
  14469,
  50674,
  58446,
  59514,
  5443,
  35323,
  70184,
  64924,
  67479,
  69080,
  69424,
  53707,
  59472,
  63875,
  58201,
  52155,
  66325,
  60256,
  4846,
  56811,
  46529,
  5394,
  12643,
  15313,
  11649,
  58767,
  33774,
  41122,
  62306,
  53247,
  66304,
  59618,
  25100,
  53667,
  32668,
  3313,
  69532,
  68213,
  66279,
  60696,
  61356,
  53115,
  70531,
  68378,
  68522,
  68434,
  59215,
  62445,
  68852,
  40301,
  40685,
  69385,
  69858,
  62732,
  57051,
  57053,
  59281,
  59186,
  52213,
  51849,
  55627,
  59485,
  52533,
  54864,
  57821,
  51335,
  52598,
  53424,
  52012,
  51414,
  51687,
  52107,
  52920,
  51408,
  61368,
  58994,
  60425,
  54685,
  54683,
  54503,
  61129,
  59041,
  51490,
  52294,
  60912,
  51331,
  60430,
  61384,
  53130,
  51367,
  52010,
  60712,
  57852,
  59248,
  71185,
  66109,
  60226,
  61622,
  67526,
  56597,
  64787,
  59076,
  60066,
  61043,
  57015,
  56594,
  63793,
  56619,
  70032,
  59529,
  56608,
  56609,
  56607,
  58147,
  56614,
  65068,
  57875,
  70664,
  63356,
  58802,
  65516,
  57388,
  60559,
  58072,
  40331,
  40964,
  69217,
  56455,
  50784,
  69301,
  70688,
  25511,
  67112,
  72521,
  72617,
  18405,
  68590,
  20717,
  56974,
  75132,
  52224,
  82671,
  8739,
  49655,
  83113,
  86541,
  82571,
];
