import * as React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'lf-motor-submission-commercial': any;
    }
  }
}
export interface MotorSubmissionCommercialWebComponentProps {
  loadApplicationId?: string;
  isLeaving: boolean;
  onComplete: (id: string) => void;
  onCancel: () => void;
  onStableChange: (isStable: boolean) => void;
  onIdChanged: (newId: string) => void;
  onCancelNavigation: () => void;
}
export const MotorSubmissionCommercialWebComponent =
    ({loadApplicationId, isLeaving, onComplete, onCancel, onStableChange, onIdChanged, onCancelNavigation}
      : MotorSubmissionCommercialWebComponentProps) => {
        
  const ref = React.useRef<HTMLDivElement>();

  React.useLayoutEffect(()=> {
    const { current } = ref;
    const onCompleteHandler = (event: CustomEvent<string>) => onComplete(event.detail);
    const onCancelHandler = (_: CustomEvent) => onCancel();
    const onStableChangeHandler = (event: CustomEvent<boolean>) => onStableChange(event.detail);
    const onIdChangedHandler = (event: CustomEvent<string>) => onIdChanged(event.detail);
    const onCancelNavigationHandler = () => onCancelNavigation();

    if (current) {
      current.addEventListener('onComplete', onCompleteHandler);
      current.addEventListener('onCancel', onCancelHandler);
      current.addEventListener('onStableChange', onStableChangeHandler);
      current.addEventListener('onIdChanged', onIdChangedHandler);
      current.addEventListener('onCancelNavigation', onCancelNavigationHandler)

      return () => {
        current.removeEventListener('onComplete', onCompleteHandler);
        current.removeEventListener('onCancel', onCancelHandler);
        current.removeEventListener('onStableChange', onStableChangeHandler);
        current.removeEventListener('onIdChanged', onIdChangedHandler);
        current.removeEventListener('onCancelNavigation', onCancelNavigationHandler);
      }
    }
  }, [])

  return (
    <lf-motor-submission-commercial
      ref={ref}
      load-application-id={loadApplicationId}
      is-leaving={isLeaving ? 'true' : null}
    ></lf-motor-submission-commercial>
  )
};

export default MotorSubmissionCommercialWebComponent;
