import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import { Icon } from '../../utils';

const Moment = require('moment');

class CalculatorPaneBar extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
    };
  }

  render() {
    return (
      <div className={`bar bar-${this.props.position}`}>
        <div className="container r">
          <Link className="bar-back" to="/calculators/prequalification">
            <Icon glyph="arrow-left" />
          </Link>

          <div className="bar-details bar-details-calc ib">
            <h4 className="mt-h pt-sm">
              {
                  (() => {
                    if (this.props.name) {
                      return (
                        <strong data-hj-masked>{this.props.name}</strong>
                      );
                    }

                    return (
                      <i className="pointer" onClick={() => { this.props.setModal(true); }}>Anonymous</i>
                    );
                  })()
                }
            </h4>

            <div className="mt-sm-n">
              {
                  (() => {
                    if (this.props.name) {
                      return (
                        <span className="mute-lg">
                          {'Last updated '}
                          {Moment(this.props.saved).fromNow()}
                        </span>
                      );
                    }

                    return (
                      <a href="#/calculators/prequalification">Load saved prequalification</a>
                    );
                  })()
                }
            </div>


          </div>

          <div className="bar-tabs">
            <Link to={`/calculators/serviceability${this.props.id ? `/${this.props.id}` : ''}`} className={`bar-tab ${this.props.active == 'serviceability' ? 'active' : ''}`}>Serviceability</Link>
            <Link to={`/calculators/lvr${this.props.id ? `/${this.props.id}` : ''}`} className={`bar-tab ${this.props.active == 'lvr' ? 'active' : ''}`}>LVR</Link>
            <Link to={`/calculators/rate${this.props.id ? `/${this.props.id}` : ''}`} className={`bar-tab ${this.props.active == 'rate' ? 'active' : ''}`}>Rate</Link>
            <Link to={`/calculators/repayments${this.props.id ? `/${this.props.id}` : ''}`} className={`bar-tab ${this.props.active == 'repayment' ? 'active' : ''}`}>Repayments</Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorPaneBar);
