import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

const ApplicationStatus = (props) => (
    <div>
      <h1 className="text-center">{props.status.description}</h1>

      <div className={`loan-status-progress-container ${props.status.code == 340 && 'done'}`}>
        {(() => {
          if ([201, 202, 203, 301, 302, 303].indexOf(props.status.code) > -1) {
            return (
                      <div className="other loan-status-progress active inactive">
                        <div className="circle">
                          <Icon glyph="close" />
                        </div>
                        {props.status.description}
                      </div>
            );
          }
          return (
                      <div>
                        <div className={`submission loan-status-progress ${props.status.message && props.status.message.introducerAction ? 'action-required' : ''} ${props.status.code >= 105 && props.status.code <= 110 ? 'active' : 'done'}`}>
                          <div className="circle">
                            <Icon glyph="assignment" />
                          </div>
                                Submission
                        </div>

                        <div className={`assessment loan-status-progress ${props.status.message && props.status.message.introducerAction ? 'action-required' : ''} ${props.status.code >= 200 && props.status.code <= 250 ? 'active' : props.status.code < 200 ? 'pending' : 'done'}`}>
                          <div className="circle">
                            <Icon glyph="refresh" />
                          </div>
                                Assessment
                        </div>

                        <div className={`settlement loan-status-progress ${props.status.message && props.status.message.introducerAction ? 'action-required' : ''} ${props.status.code >= 300 && props.status.code < 340 ? 'active' : props.status.code < 300 ? 'pending' : 'done'}`}>
                          <div className="circle">
                            <Icon glyph="money-box" />
                          </div>
                                Settlement
                        </div>

                        <div className="split" />
                      </div>
          );
        })()}

      </div>
    </div>
);

ApplicationStatus.propTypes = {
  application: PropTypes.object,
};

export default ApplicationStatus;
