export class Masker {
  constructor() {
    this.ucfirst = (text) => {
      if (text) {
        return text.toString().charAt(0).toUpperCase() + text.toString().slice(1);
      }
      return '';
    };

    this.clean = (value) => {
      if (value) {
        value = value.toString().replace(/[^\d\.-]+/g, '');

        if (value.replace(/[^\.-]+/g, '') == value) {
          return '';
        }

        return value;
      }

      return '';
    };

    this.date = (value) => {
      if (!value) {
        return '';
      }

      if (value.search('/') > -1) {
        const values = value.split('/');

        if (values[0].length === 1) {
          values[0] = `0${values[0]}`;
        }

        return `${values[0]}/${values[1]}`;
      }
      const numericValue = this.clean(value);

      if (numericValue.length === 0) {
        return '';
      } if (numericValue.length === 1 || numericValue.length === 2) {
        return numericValue;
      }
      return `${numericValue.substr(0, 2)}/${numericValue.substr(2, 4)}`;
    };

    this.fullDate = (value) => {
      if (!value) {
        return '';
      }

      if (value.search('/') > 1) {
        const values = value.split('/');

        if (values[0].length === 1) {
          values[0] = `0${values[0]}`;
        }

        if (values[1].length === 1) {
          values[1] = `0${values[1]}`;
        }

        return `${values[0]}/${values[1]}/${values[2] || ''}`;
      } if (value.search('/') > -1) {
        const values = value.split('/');

        if (values[0].length === 1) {
          values[0] = `0${values[0]}`;
        }

        return `${values[0]}/${values[1]}`;
      }

      const numericValue = this.clean(value);

      if (numericValue.length === 0) {
        return '';
      } if (numericValue.length === 1 || numericValue.length === 2) {
        return numericValue;
      } if (numericValue.length <= 6) {
        return `${numericValue.substr(0, 2)}/${numericValue.substr(2, 4)}`;
      }

      return `${numericValue.substr(0, 2)}/${numericValue.substr(2, 2)}/${numericValue.substr(4, 4)}`;
    };

    this.number = (value, opts) => {
      let prefix = '';

      if (!opts) {
        opts = {};
      }

      opts.decimal = opts.decimal || 0;
      opts.negative = opts.negative || false;
      prefix = (value || '').toString().substr(0, 1);

      value = Mask.clean(value);

      if (!value.length) {
        value = 0;
      }

      value = parseFloat(value);

      if (value < 0 && !opts.negative) {
        value *= -1;
      }

      if (value > 0 && opts.negative && prefix == '-') {
        value *= -1;
      }

      value = value.toFixed(opts.decimal);
      value = value.toString().replace(/./g, (c, i, a) => (i && c !== '.' && ((a.length - i) % 3 === 0) ? `,${c}` : c));

      value = value.replace('-,', '-');

      return value;
    };

    this.flatten = (ob) => {
      const toReturn = {};

      for (const i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) === 'object') {
          const flatObject = this.flatten(ob[i]);
          for (const x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[`${i}.${x}`] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }

      return toReturn;
    };

    this.cleanFormsy = (target, value, refs) => {
      refs[target] && refs[target].setValue(this.clean(value));
    };
    this.dateFormsy = (target, value, refs) => {
      refs[target] && refs[target].setValue(this.date(value));
    };

    this.fullDateFormsy = (target, value, refs) => {
      refs[target] && refs[target].setValue(this.fullDate(value));
    };

    this.numberFormsy = (target, value, refs, opts) => {
      const num = this.number(value, opts);
      refs[target] && refs[target].setValue(num);
    };

    this.numberFormsyNoZero = (target, value, refs, opts) => {
      if (value != '0') {
        let num = this.number(value, opts);
        num = num == '0' ? '' : num;
        refs[target] && refs[target].setValue(num);
      }
    };
  }
}

export const Mask = new Masker();
export default Mask;
