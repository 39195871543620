import { Dispatch } from "redux"
import { notify as Notify } from 'react-notify-toast';
import API from "../../../api";
import { Applicant, Broker, IDVerificationStatus } from './applicationIDVerification'
import {ApplicantIDVerificationStatus} from './reducer'

export type IDVerificationAction = SendingVerificationLink | VerificationLinkSent | SendingVerificationLinkFailed | GetVerificationStatus

export enum IDVerificationActionTypes {
  SendingVerificationLink = "SendingVerificationLink",
  VerificationLinkSent = "VerificationLinkSent",
  SendingVerificationLinkFailed = "SendingVerificationLinkFailed",
  GetApplicantVerificationStatus = "GetVerificationStatus"
}

interface SendingVerificationLink {
  type: IDVerificationActionTypes.SendingVerificationLink
  applicantId: Number
}

interface VerificationLinkSent {
  type: IDVerificationActionTypes.VerificationLinkSent
  applicantId: Number
}

interface SendingVerificationLinkFailed {
  type: IDVerificationActionTypes.SendingVerificationLinkFailed
  applicantId: Number
}

interface GetVerificationStatus {
  type: IDVerificationActionTypes.GetApplicantVerificationStatus
  applicant: ApplicantIDVerificationStatus
}

export const getApplicantIDVerificationStatus = (applicantId:Number) => (dispatch:Dispatch<IDVerificationAction>) => API.getApplicantVerificationStatus(applicantId)
  .then(response => handleIDVerificationResponse(applicantId, response, dispatch))

export const sendApplicantIDVerificationLink = (applicationNumber: Number, applicants: Applicant[], broker: Broker, applicationType: Number) => (dispatch: Dispatch<IDVerificationAction>) => {
  applicants.map(applicant => {
    dispatch({
      type: IDVerificationActionTypes.SendingVerificationLink,
      applicantId: applicant.applicantId
    });

    API.sendApplicantIDVerificationLink(applicant.applicantId, createIDVerificationRequestBody(applicationNumber, applicant, broker, applicationType))
    .then(response => {
      switch(response.status){
        case 201:
          dispatch({
            type: IDVerificationActionTypes.VerificationLinkSent,
            applicantId: applicant.applicantId
          });
          break;
        case 400 :
        default:
          Notify.show('Something went wrong, please contact support team', 'error', 3000);
          dispatch({
            type: IDVerificationActionTypes.SendingVerificationLinkFailed,
            applicantId: applicant.applicantId
          });
      }

    })
      .catch((e) => {
        Notify.show('Something went wrong, please contact support team', 'error', 3000);
        dispatch({
          type: IDVerificationActionTypes.SendingVerificationLinkFailed,
          applicantId: applicant.applicantId
        });
      });
  })
}

const sendSMSToBroker = (sendSMS : Boolean, applicationType: Number ) => (sendSMS && (applicationType != 2)) //exclude motor applications
const createIDVerificationRequestBody = (applicationNumber: Number, applicant:Applicant, broker: Broker, applicationType: Number) => {
  return {
    "clientReference": applicationNumber,
    "smsBrandName": broker.smsBrandName,
    "client": {
      "name": applicant.name,
      "contactMethod": "Phone",
      "phone": applicant.mobileNumber,
      "email": ""
    },
    "broker": {
      "name": broker.name,
      "contactMethod": sendSMSToBroker(broker.sendSMS, applicationType) ? "Phone": "Email",
      "phone": sendSMSToBroker(broker.sendSMS, applicationType) ? broker.mobileNumber : "",
      "email": sendSMSToBroker(broker.sendSMS, applicationType) ? "" : broker.email,
    },
    "applicationType": applicationType == null ? "Motor" : applicationType != null && applicationType == 2 ? "Motor" : "Resi"
  }
}

const handleIDVerificationResponse = (applicantId, response, dispatch: Dispatch<IDVerificationAction>) => {
  switch (response.status) {
    case 200:
      dispatch({
        type: IDVerificationActionTypes.GetApplicantVerificationStatus,
        applicant: {applicantId:applicantId, idVerificationStatus:response.data.status,canSend:response.data.canRequest}
      })
      break;

    case 404:
      dispatch({
        type: IDVerificationActionTypes.GetApplicantVerificationStatus,
        applicant: {applicantId:applicantId, idVerificationStatus:IDVerificationStatus.NotStarted,canSend:true}
      })
    default:
      dispatch({
        type: IDVerificationActionTypes.GetApplicantVerificationStatus,
        applicant: {applicantId:applicantId, idVerificationStatus:IDVerificationStatus.NotStarted,canSend:true}
      })
      break;
  }
}
