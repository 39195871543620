import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

export const AccreditationStart = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div className="text-center mt-lg">
      <h5 className="mute mt-lg">Getting accredited with Liberty is easy. </h5>
      <h5 className="mute">Simply complete your details and we will verify your accreditation as soon as possible.</h5>
      <Link to="/get-accredited/personal" className="btn btn-lg btn-secondary mt-lg">Get accredited</Link>
    </div>
  );
};

AccreditationStart.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default AccreditationStart;
