import GlassGuideApi from '../api/glassGuideApi';
import ReferenceApi from '../api/referenceApi';
import * as types from '../constants/types';

import Mask from '../helpers/mask';

export const getVerificationLevels = (productGroupId) => (dispatch) => ReferenceApi.getVerificationLevels(productGroupId)
  .then((response) => dispatch({
    type: types.GET_VERIFICATION_LEVELS,
    data: response.data,
  }));

export const getLoanPurposes = () => (dispatch) => ReferenceApi.getLoanPurposes()
  .then((response) => dispatch({
    type: types.GET_LOAN_PURPOSES,
    data: response.data,
  }));

export const getVeiTypes = () => (dispatch) => ReferenceApi.getVeiTypes()
  .then((response) => dispatch({
    type: types.GET_VEI_TYPES,
    data: response.data,
  }));

export const getVehicleYearOnYearDepreciation = () => (dispatch) => ReferenceApi.getVehicleYearOnYearDepreciation()
  .then((response) => dispatch({
    type: types.GET_VEHICLE_YOY_DEPRECIATION,
    data: response.data,
  }));

export const getYears = () => (dispatch) => ReferenceApi.getYears()
  .then((response) => dispatch({
    type: types.GET_YEARS,
    data: response.data,
  }));

export const getMakes = (year) => (dispatch) => ReferenceApi.getMakes(year)
  .then((response) => dispatch({
    type: types.GET_MAKES,
    data: response.data,
  }));

export const getModels = (year, make) => (dispatch) => ReferenceApi.getModels(year, make)
  .then((response) => dispatch({
    type: types.GET_MODELS,
    data: response.data,
  }));

export const getBodyTypes = (year, make, model) => (dispatch) => ReferenceApi.getBodyTypes(year, make, model)
  .then((response) => dispatch({
    type: types.GET_BODY_TYPES,
    data: response.data,
  }));

export const getTransmissions = (year, make, model, bodyType) => (dispatch) => ReferenceApi.getTransmissions(year, make, model, bodyType)
  .then((response) => dispatch({
    type: types.GET_TRANSMISSIONS,
    data: response.data,
  }));

export const getCategories = (year, make, model, bodyType, transmission) => (dispatch) => ReferenceApi.getCategories(year, make, model, bodyType, transmission)
  .then((response) => dispatch({
    type: types.GET_CATEGORIES,
    data: response.data,
  }));

export const getNvic = (data) => ({
  type: types.GET_NVIC,
  data,
});
  
export const getNvics = (query, productGroup) => (dispatch) => GlassGuideApi.lookupNvics(query, productGroup)
  .then((data) => dispatch({
    type: types.GET_NVICS,
    data,
  }));

export const setNvic = (data) => ({
  type: types.SET_NVIC,
  data,
});

export const clearNvic = () => ({
  type: types.CLEAR_NVIC,
});

export const clearAppraisal = () => ({
  type: types.CLEAR_APPRAISAL,
});

export const getAppraisal = (nvic, odometer, isNew, productGroupId) => (dispatch) => ReferenceApi.getAppraisal(productGroupId === types.PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN ? `CV-${nvic}` : nvic, Mask.clean(odometer), isNew)
  .then((response) => dispatch({
    type: types.GET_APPRAISAL,
    data: response.data,
  }));

export const getVendorTypes = () => (dispatch) => ReferenceApi.getVendorTypes()
  .then((response) => dispatch({
    type: types.GET_VENDOR_TYPES,
    data: response.data,
  }));

export const getLpiTypes = () => (dispatch) => ReferenceApi.getLpiTypes()
  .then((response) => dispatch({
    type: types.GET_LPI_TYPES,
    data: response.data,
  }));

export const getInsurers = () => (dispatch) => ReferenceApi.getInsurers()
  .then((response) => dispatch({
    type: types.GET_INSURERS,
    data: response.data.map((x) => ({ id: x.key, name: x.value })),
  }));

export const getOtherIncomeTypes = () => (dispatch) => ReferenceApi.getOtherIncomeTypes()
  .then((response) => dispatch({
    type: types.GET_OTHER_INCOME_TYPES,
    data: response.data,
  }));

export const getCommOtherIncomeTypes = () => (dispatch) => ReferenceApi.getCommOtherIncomeTypes()
  .then((response) => dispatch({
    type: types.GET_COMM_OTHER_INCOME_TYPES,
    data: response.data,
  }));

export const getInsurancePaymentMethods = () => (dispatch) => ReferenceApi.getInsurancePaymentMethods()
  .then((response) => dispatch({
    type: types.GET_INSURANCE_PAYMENT_METHODS,
    data: response.data,
  }));

export const getLPIPremium = (data) => (dispatch) => ReferenceApi.getLPIPremium(data)
  .then((response) => dispatch({
    type: types.GET_LPI_PREMIUM,
    data: response.data,
  }));
