import * as React from 'react';
import PropTypes from 'prop-types';
import {Widget, Icon} from '../../utils';

export const PhoneWidget = () => {
  return (
    <Widget title="Get in touch">

      <div className="text-center p-lg">
        <Icon glyph="phone" size={2} className="mute-lg" />
        <a
          href="tel:131133"
          style={{fontSize: "30px", textDecoration: "none"}}
          className="b mt-sm">

          <strong>13 11 33</strong>
        </a>

      </div>
    </Widget>
  );
}

export default PhoneWidget;
