import * as React from "react";
import { Link } from 'react-router';

import {ISession} from "../../interfaces"

interface IPaneMenuProps {
  visible: boolean;
  session: ISession;
  toggleAll: () => void;
}

const isResidential = (session: ISession): boolean => session.details.isResidential;
const isMotor = (session: ISession): boolean => session.details.isMotor;

const shouldShowCalculatorsLink = (session: ISession) => isResidential(session) || isMotor(session);

export const PaneMenu = ({toggleAll, session, visible}: IPaneMenuProps): JSX.Element => {
  const attr = {
    className: "menu-item",
    activeClassName: "active",
    onClick: toggleAll
  }

  return (
    <div>
      <div className={`pane-menu animate menu pull-right ${visible ? "in" : ""}`}>
        <Link to="/applications" {...attr}>Applications</Link>
        { shouldShowCalculatorsLink(session) &&
        <Link to="/calculators" {...attr}>Calculators</Link>
        }
        <Link to="/faqs" {...attr}>FAQs</Link>
        <Link to="/documents" {...attr}>Documents</Link>
        <Link to="/loannet" {...attr}>LoanNET<sup className="mute">&reg;</sup></Link>
      </div>
      <div className="menu-overlay visible-xs" onClick={toggleAll} />
    </div>
  );
}

export default PaneMenu;
