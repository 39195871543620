/**
 * Authentication
 */
export const GET_DETAILS = 'GET_DETAILS';
export const GET_MIGRATED = 'GET_MIGRATED';
export const GET_STORAGE = 'GET_STORAGE';
export const SET_STORAGE = 'SET_STORAGE';
export const SET_DETAIL_NUMBERS = 'SET_DETAIL_NUMBERS';
export const SET_DETAIL_EMAILS = 'SET_DETAIL_EMAILS';
export const SET_DETAIL_ADDRESS = 'SET_DETAIL_ADDRESS';
export const SET_DETAIL_PASSWORD = 'SET_DETAIL_PASSWORD';
export const SET_DETAIL_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_ACCREDITATION = 'SET_ACCREDITATION';
export const REQUEST_ACCREDITATION = 'REQUEST_ACCREDITATION';
export const REQUEST_INSURANCE = 'REQUEST_INSURANCE';

export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGOUT = 'DO_LOGOUT';

export const DO_RESET = 'DO_RESET';
export const DO_RESET_TOKEN = 'DO_RESET_TOKEN';

export const RENEW_TOKEN_SUCCESS = 'RENEW_TOKEN_SUCCESS';
export const RENEW_TOKEN_FAILURE = 'RENEW_TOKEN_FAILURE';
export const RENEW_TOKEN_IN_PROGRESS = 'RENEW_TOKEN_IN_PROGRESS';

export const TIMER_START = 'TIMER_START';
export const TIMER_TICK = 'TIMER_TICK';
export const TIMER_STOP = 'TIMER_STOP';

/**
 * Prompts
 */
export const GET_PROMPTS = 'GET_PROMPTS';
export const ADD_PROMPT = 'ADD_PROMPT';
export const SAVE_PROMPT = 'SAVE_PROMPT';
export const REMOVE_PROMPT = 'REMOVE_PROMPT';
export const CLEAR_PROMPTS = 'CLEAR_PROMPTS';

/**
 * Applications
 */
export const FILTER_APPLICATIONS = 'FILTER_APPLICATIONS';
export const SEARCH_APPLICATIONS = 'SEARCH_APPLICATIONS';
export const SORT_APPLICATIONS = 'SORT_APPLICATIONS';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_PARTIAL_APPLICATIONS = 'GET_PARTIAL_APPLICATIONS';
export const GET_PARTIAL_MOTOR_APPLICATIONS_START = 'GET_PARTIAL_MOTOR_APPLICATIONS_START';
export const GET_PARTIAL_MOTOR_APPLICATIONS_COMPLETE = 'GET_PARTIAL_MOTOR_APPLICATIONS_COMPLETE';
export const GET_PARTIAL_MOTOR_APPLICATIONS_ERROR = 'GET_PARTIAL_MOTOR_APPLICATIONS_ERROR';
export const COMPLETE_UPLOAD = 'COMPLETE_UPLOAD';
export const COMPLETE_ACTION = 'COMPLETE_ACTION';

/**
 * Application
 */
export const GET_APPLICATION = 'GET_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const POLL_APPLICATION = 'POLL_APPLICATION';

/**
 * Applicant
 */
export const SET_APPLICANT_PROPERTY = 'SET_APPLICANT_PROPERTY';

/**
 * Contacts
 */
export const GET_CONTACTS = 'GET_CONTACTS';

/**
 * Introducers
 */
export const GET_INTRODUCERS = 'GET_INTRODUCERS';

/**
 * FAQs
 */
export const GET_FAQS = 'GET_FAQS';

/**
 * References
 */
export const GET_OTHER_INCOME_TYPES = 'GET_OTHER_INCOME_TYPES';
export const GET_COMM_OTHER_INCOME_TYPES = 'GET_COMM_OTHER_INCOME_TYPES';
export const GET_INSURERS = 'GET_INSURERS';
export const GET_VERIFICATION_LEVELS = 'GET_VERIFICATION_LEVELS';
export const GET_LOAN_PURPOSES = 'GET_LOAN_PURPOSES';
export const GET_LPI_TYPES = 'GET_LPI_TYPES';
export const GET_VEI_TYPES = 'GET_VEI_TYPES';
export const GET_VEHICLE_YOY_DEPRECIATION = 'GET_VEHICLE_YOY_DEPRECIATION';
export const GET_VENDOR_TYPES = 'GET_VENDOR_TYPES';
export const GET_NVICS = 'GET_NVICS';
export const GET_NVIC = 'GET_NVIC';
export const GET_NVIC_ERROR = 'GET_NVIC_ERROR';
export const SET_NVIC = 'SET_NVIC';
export const CLEAR_NVIC = 'CLEAR_NVIC';
export const GET_APPRAISAL = 'GET_APPRAISAL';
export const GET_MAKES = 'GET_MAKES';
export const GET_MODELS = 'GET_MODELS';
export const GET_BODY_TYPES = 'GET_BODY_TYPES';
export const GET_TRANSMISSIONS = 'GET_TRANSMISSIONS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_YEARS = 'GET_YEARS';
export const GET_INSURANCE_PAYMENT_METHODS = 'GET_INSURANCE_PAYMENT_METHODS';
export const GET_LPI_PREMIUM = 'GET_LPI_PREMIUM';
export const CLEAR_APPRAISAL = 'CLEAR_APPRAISAL';
/**
 * Calculators
 */
export const CALCULATE_SERVICEABILITY = 'CALCULATE_SERVICEABILITY';
export const CALCULATE_RATE = 'CALCULATE_RATE';
export const CALCULATE_LVR = 'CALCULATE_LVR';
export const GET_PREQUAL = 'GET_PREQUAL';
export const ADD_PREQUAL = 'ADD_PREQUAL';
export const SAVE_PREQUAL = 'SAVE_PREQUAL';
export const SEARCH_PREQUALS = 'SEARCH_PREQUALS';
export const WORKSHOP_WITH_BDM = 'WORKSHOP_WITH_BDM';

/**
 * Motor prequal tool
 */
export const CALCULATE_MOTOR_REPAYMENTS = 'CALCULATE_MOTOR_REPAYMENTS';
export const SUBMIT_RATE_QUOTE = 'SUBMIT_RATE_QUOTE';
export const TRACK_RATE_QUOTE = 'TRACK_RATE_QUOTE';
/**
 * Events
 */
export const GET_EVENT = 'GET_EVENT';
export const SET_EVENT = 'SET_EVENT';

export const CALL_ME_BACK = 'CALL_ME_BACK';

/**
 * Product Group IDs
 */
export const PRODUCTGROUPS = {
  MOTOR_CONSUMER: 5100,
  MOTOR_COMMERCIAL: 11000,
  MOTOR_COSUMER_CARAVAN: 25000,
  MOTOR_NOVATED_LEASE: 14000,
  LFI_VEI: 17200,
  LFI_LPI: 17100,
};

export const MOTOR_LOAN_TERM_MIN = 2;
export const MOTOR_LOAN_TERM_MAX = 7;
export const MOTOR_LOAN_TERM_DEFAULT = 5;
export const MOTOR_PLATE_MAX_LENGTH = 8;
export const MOTOR_PLATE_MIN_LENGTH = 2;
export const MOTOR_VIN_MIN_LENGTH = 17;

export const MINIMUM_MOTOR_NOVATED_RESIDUAL_PERCENTAGE = {
  12: 65.63,
  24: 56.25,
  36: 46.88,
  48: 37.5,
  60: 28.13,
};

export const MAXIMUM_MOTOR_NOVATED_BROKERAGE_PERCENTAGE = 0.08;
/**
 * Capabilities
 */

export const CAPABILITIES = {
  PRINT_AFTER_APPROVAL: 'motorDocumentsPrintAfterApproval',
  ID_Verification: 'idVerification',
  LFI_Pre_Deferral: 'lfiPreDeferral',
  GREEN_VEHICLE: 'greenVehicle',
  UAF: 'universalApplicationForm',
};

export const FUELTYPES = {
  Electric: 'ELECTRIC',
};

/**
 * Originator Types
 */

export const OriginatorTypes = {
  LNS: 's',  
};
