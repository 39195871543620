import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Row, Col } from 'react-bootstrap';

import Icon from '../../utils/icon';
import { InfoAlert, WarningAlert } from '../../utils/alert';
import { LvrData, LvrFilter } from './actions';

import event3rdParty from '../../helpers/event3rdParty';

const AlertLmi = () => (
  <InfoAlert id="lmi">
    Maximum LVR includes any LMI fee.
  </InfoAlert>
);

const AlertSmsf = () => (
  <WarningAlert id="smsf">
    <span>This postcode calculator is not applicable for <strong>SMSF</strong> products. Please email <a href="mailto:commercialscenarios@liberty.com.au">commercialscenarios@liberty.com.au</a> for the maximum postcode LVRs for <strong>SMSF</strong> products.</span>
  </WarningAlert>
);

const AlertRestrictedPostcode = () => (
  <InfoAlert id="rp">
    <span>Restrictions apply within this postcode, please contact Scenarios on <strong> 13 11 33 </strong>.</span>
  </InfoAlert>
);

const hasResult = (data) => !!data && !!data.length;

const isPurpose = (purpose) => (filter) => filter.purpose === purpose;
const isVerification = (verificationLevel) => (filter) => filter.verificationLevel === verificationLevel;

const isInvestor = isPurpose("Investor");
const isOwnerOcc = isPurpose("Owner Occupied");

const isFullDoc = isVerification("Star");
const isLowDoc = isVerification("Nova");

const shouldShowAlertSmsf = ({data, filter}) => hasResult(data) && isInvestor(filter);
const shouldShowAlertLmi = ({data, filter}) => hasResult(data) && (isInvestor(filter) || isLowDoc(filter));

const restrictedPostcodes = ["2570", "2747"];
const shouldShowPostcodeRestrictionAlert = (filter) => restrictedPostcodes.includes(filter.postcode);

const filterResult = (filter) => (row) => !["purpose", "verificationLevel", "propertyType"].some(key => row[key] !== filter[key]);
const sortResultByRiskGrade = (a, b) => getRiskGradeValueFromResult(b) - getRiskGradeValueFromResult(a);

const getRiskGradeValueFromResult = (item: LvrData): number => {
  if (item.riskGrade == "AAA" && item.swift == "-") return 200;
  if (item.riskGrade == "AAA" && item.swift != "-") return 150;
  if (item.riskGrade == "AA") return 100;
  return item.lVR;
}

const getRiskGradeLabel = (riskGrade: string | null): string => {
  switch (riskGrade) {
    case "Custom": return "All other risk grades";
    case null: return "All risk grades";
    default: return riskGrade;
  }
}

const getSwiftLabel = (swift: string): string => swift !== '-' ? ` (with Swift ${swift.replace('Max', 'up to')})` : '';
const getLvrLabel = (lVR: number): JSX.Element => !!lVR ? <span>{lVR}%</span> : <span className="mute">N/A</span>
const getCustomRiskGradeLabel = (riskGrade: string): string => riskGrade === "Custom" ? " (Please refer to your Introducer guide for maximum LVR)" : '';
const getUniqueRowKey = (row: LvrData): string => btoa(JSON.stringify(row));

interface LvrResultProps {
  data: LvrData[];
  filter: LvrFilter;

  prequal: any; // TODO
  savePrequal: any;
}

interface LvrResultRowProps extends LvrData {}

class LvrResultRow extends React.Component<LvrResultRowProps, {}> {
  render() {
    const { swift, riskGrade, lVR } = this.props;
    return (
      <tr key={`lvr-${getRiskGradeLabel(riskGrade)}-${lVR}`} className="table-row">
        <td>
          {getRiskGradeLabel(riskGrade)}
          <small className="mute">{getSwiftLabel(swift)}</small>
          <small className="mute">{getCustomRiskGradeLabel(riskGrade)}</small>
        </td>
        <td>
          {getLvrLabel(lVR)}
        </td>
      </tr>
    );
  }
}

export class LvrResult extends React.Component<LvrResultProps, {}> {
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-body panel-section-body-tint">
          <div className="panel-section-container text-center">
            <Row>
              <Col sm={8} smOffset={2}>
                <div className={`card text-left pb-h mb-lg mt-md ${!hasResult(this.props.data) ? 'mute-lg' : ''}`}>
                  <div className="text-center mt-lg">
                    <Icon glyph="map" className="mute-lg" size={6} />

                    { !hasResult(this.props.data)
                      ? <Icon glyph="gps-dot" className="danger a mt-lg pt-lg" size={3} style={{left: 0, right: '-16px', top: '16px'}} />
                      : <Icon glyph="pin" className="success a mt-lg" size={4} style={{left: 0, right: 0, top: 0}} />
                    }
                  </div>

                  { !hasResult(this.props.data)
                    ? <h3 className="text-center pb-lg">Please enter a valid postcode...</h3>
                    : (
                      <div className="ml-md-n mr-md-n">
                        <h3 className="text-center">Maximum LVR</h3>

                        <table className="table table-hover table-lg mb-h">
                          <thead>
                            <tr>
                              <th>Risk grade</th>
                              <th style={{width: '10px'}}>LVR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.props.data
                                .filter(filterResult(this.props.filter))
                                .sort(sortResultByRiskGrade)
                                .map((row) => <LvrResultRow key={getUniqueRowKey(row)} {...row} />)
                            }
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                 </div>

                { shouldShowAlertLmi(this.props) && <AlertLmi /> }
                { shouldShowPostcodeRestrictionAlert(this.props.filter) && <AlertRestrictedPostcode />}
                { shouldShowAlertSmsf(this.props) && <AlertSmsf /> }

                <button type="button" className="btn btn-default btn-simple btn-lg mr-sm" onClick={() => this.props.savePrequal()}>Save</button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.lvr.filter,
    data: state.lvr.data,
    prequal: state.prequal
  };
}

export default connect(mapStateToProps, {})(LvrResult);

