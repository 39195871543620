import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';
import { Guid } from 'guid-typescript';
import * as Moment from 'moment';

import Mask from '../../helpers/mask';

import Icon from '../../utils/icon';
import { CreditEvent, CreditEventType, Applicant } from './models';

type RateQuoteApplicantCreditHistoryProps = {
  index: number
  applicants: Applicant[]
}

const RateQuoteApplicantCreditHistory = (props: RateQuoteApplicantCreditHistoryProps) => {
  const [creditEvents, setCreditEvents] = React.useState<CreditEvent[]>([]);
  React.useEffect(() => {
    const aa: any[] = [];
    if (props.applicants[props.index] != undefined 
      && props.applicants[props.index].creditHistory != undefined
      && props.applicants[props.index].creditHistory.creditEvents != undefined) {
      props.applicants[props.index].creditHistory.creditEvents.map((app) => {
        aa.push({
          creditEventType: app.creditType,
          eventType: app.eventType,
          dateIncurred: app.dateIncurred,
          dateRectified: app.dateRectified,
          status: app.status,
          toBePaidOff: app.toBePaidOff,
          amount: app.amount,
          missedPayments: app.missedPayments,
          missedPaymentsManual: app.missedPaymentsManual,
        })
      });
    }
    setCreditEvents(aa)
  }, []);

  const dateIncurredRef = React.createRef();
  const dateRectifiedRef = React.createRef();
  const amountRef = React.createRef();
  const statusRef = React.createRef();
  const missedPaymentsRef = React.createRef();
  const toBePaidOffRef = React.createRef();
  const creditTypeRef = React.createRef();
  const eventTypeRef = React.createRef();

  // ----- Event handlers start -----
  const addCreditEvent = () => {
    const defaultValues = getDefaultValues(undefined);
    setCreditEvents([...creditEvents, {
      creditEventType: defaultValues.creditEventType,
      creditEventId: Guid.create().toString(),
      eventType: defaultValues.eventType,
      dateIncurred: defaultValues.dateIncurred,
      dateRectified: defaultValues.dateRectified,
      status: defaultValues.status,
      toBePaidOff: defaultValues.toBePaidOff,
      missedPayments: defaultValues.missedPayments,
      missedPaymentsManual: false,
    }]);
    //document.getElementById(`applicants[${props.index}][creditHistory][creditEvents][${creditEvents.length - 1}].creditType`).focus();
  }

  const removeCreditEvent = (index: number) => {
    const creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList.splice(index, 1);
    setCreditEvents(creditEventList);
    if (creditEvents.length > index) {
      //document.getElementById(`applicants[${props.index}][creditHistory][creditEvents][${creditEvents.length - 1}].creditType`).focus();
    }
  }

  const handleCreditEventTypeChange = (index: number, eventType: CreditEventType) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].creditEventType = eventType;
    // Ensure we set new default values when the credit type is changed. 
    creditEventList[index].eventType = getDefaultEventType(eventType);
    creditEventList[index].status = getDefaultStatus(eventType);
    setCreditEvents(creditEventList);
  }

  const handleEventTypeChange = (index, value) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].eventType = value;
    setCreditEvents(creditEventList);
  }

  const changeToBePaidOffType = (index, value) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].toBePaidOff = value;
    setCreditEvents(creditEventList);
  }

  const changeCreditEventStatus = (index, value) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].status = value;
    setCreditEvents(creditEventList)
  }

  const handleDateIncurredChange = (index, date) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].dateIncurred = date;
    setCreditEvents(creditEventList);
    calculateMissedPayments(index, date);
  }

  const handleDateRectifiedChange = (index, date) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].dateRectified = date;
    setCreditEvents(creditEventList);
    calculateMissedPayments(index, date);
  }

  const handleMissedPaymentsChange = (index, value) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    creditEventList[index].missedPayments = value;
    // Set the override if the user has entered a value; if they clear it, re-enable calculation
    creditEventList[index].missedPaymentsManual = value.length > 0; 
    setCreditEvents(creditEventList)
  }

  // ----- Event handlers end -----

  function padDate(dateString) {
    const parts = dateString.split('/');
    const paddedParts = parts.map(part => part.padStart(2, '0'));
    return paddedParts.join('/');
  }

  function dateComplete(dateString) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      if (parts[2].length === 4 && dateString.length >= 8) {
        return true;
      }
    }
  }

  const calculateMissedPayments = (index, value) => {
    let creditEventList: CreditEvent[] = Object.assign([], creditEvents);
    const incurred = creditEventList[index].dateIncurred;
    const rectified = creditEventList[index].dateRectified;

    const datesPresent = (incurred && rectified);
    const datesComplete = (dateComplete(incurred) && dateComplete(rectified));
    const manuallyOverridden = creditEventList[index].missedPaymentsManual;
    
    if (datesPresent && datesComplete && !manuallyOverridden) {
      let dateIncurred = Moment(padDate(incurred), 'DD/MM/YYYY');
      let dateRectified = Moment(padDate(rectified), 'DD/MM/YYYY');
      
      const months = dateRectified.diff(dateIncurred, 'months').toString();
      creditEventList[index].missedPayments = months;
      setCreditEvents(creditEventList);
    }
  }


  const getCreditEventTypes = () =>
    [{
      label: 'Mortgage',
      value: 'mortgage',
    },
    {
      label: 'Car/Equipment Loan',
      value: 'securedcredit',
    },
    {
      label: 'Credit Card',
      value: 'unsecuredcredit',
    },
    {
      label: 'Trade Account',
      value: 'tradeAccount',
    },
    {
      label: 'ATO Tax Debt',
      value: 'ato',
    },
    {
      label: 'Personal Loan',
      value: 'personalloan',
    },
    {
      label: 'Utility Account',
      value: 'utilityaccount',
    },
    {
      label: 'Council Rates',
      value: 'councilrates',
    },
    {
      label: 'School Fees',
      value: 'schoolfees',
    },
    {
      label: 'Professional Services',
      value: 'professionalservices',
    },
    {
      label: 'Bankruptcy',
      value: 'bankruptcy',
    },
    {
      label: 'Company Administration',
      value: 'administration',
    }];

  const getInfringementTypes = () =>
    [{
      label: 'Arrears',
      value: 'arrears',
    },
    {
      label: 'Default',
      value: 'default',
    },
    {
      label: 'Judgment',
      value: 'judgment',
    },
    {
      label: 'Warrant',
      value: 'warrant',
    },
    {
      label: 'Writ',
      value: 'writ',
    }];


  const getBankruptcyTypes = () =>
    [{
      label: 'Part IX',
      value: 'Part IX',
    }, {
      label: 'Part X',
      value: 'Part X',
    }, {
      label: 'Sequestration/Debtors Petition',
      value: 'Sequestration',
    }];
    // Removed Debtors Petition as a separate option - not returning correct rates - available to map to in RQ API.
    // Currently treating both as sequestration, as rates are the same

  const getStatusTypes = () =>
    [{
      label: 'Unpaid',
      value: 'unpaid',
    },
    {
      label: 'Paid',
      value: 'paid',
    }];

  const getAdministrationEventTypes = () =>
    [{
      label: 'Ceased',
      value: 'ceased',
    },
    {
      label: 'Current',
      value: 'current',
    }];

  const getYesNo = () =>
    [{
      label: 'No',
      value: 'No',
    },
    {
      label: 'Yes',
      value: 'Yes',
    }];

  const isNonFinancial = (creditEventType) => {
    return [
      'ato', 
      'tradeAccount',
      'utilityaccount', 
      'councilrates', 
      'schoolfees', 
      'professionalservices'
    ].includes(creditEventType);
  }

  const getDefaultEventType = (creditEventType) => {
    if (creditEventType === 'bankruptcy') { return getBankruptcyTypes()[0].value; }
    if (creditEventType === 'administration') { return getAdministrationEventTypes()[0].value; }
    return getInfringementTypes()[0].value;
  }

  const getDefaultStatus = (creditEventType) => {
    if (creditEventType === 'administration') { return getAdministrationEventTypes()[0].value; }
    return getStatusTypes()[0].value;
  }

  const getDefaultValues = (creditEvent) => {
    const creditEventType = creditEvent?.creditEventType ?? "mortgage";
    return {
      creditEventType: creditEventType,
      eventType: creditEvent?.eventType ?? getDefaultEventType(creditEventType),
      dateIncurred: creditEvent?.dateIncurred ?? "",
      dateRectified: creditEvent?.dateRectified ?? "",
      status: creditEvent?.status ?? getDefaultStatus(creditEventType),
      toBePaidOff: creditEvent?.toBePaidOff ?? getYesNo()[0].value,
      amount: creditEvent?.amount ?? 0,
      missedPayments: creditEvent?.missedPayments ?? "",
      administrationStatus: creditEvent?.status ?? getAdministrationEventTypes()[0].value,
    }
  }

  const renderEventType = (creditEvent, index) => {
    if (creditEvent.creditEventType === 'bankruptcy') {
      return renderBankruptcyEventTypes(creditEvent, index);
    }
    else if (creditEvent.creditEventType === 'administration') {
      return renderAdministrationStatus(creditEvent, index);
    }
    else return renderInfringementEventTypes(creditEvent, index);
  }
    
  const renderCreditEvent = (creditEvent, index) => {
    return (
        <Select
          id={`applicants[${props.index}][creditHistory][creditEvents][${index}].creditType`}
          name={`applicants[${props.index}][creditHistory][creditEvents][${index}].creditType`}
          ref={creditTypeRef}
          label="Credit Type"
          value={getDefaultValues(creditEvent).creditEventType}
          options={getCreditEventTypes()}
          changeCallback={(name, value) => handleCreditEventTypeChange(index, value) }
          className="form-control"
        />
    )
  }

  const renderBankruptcyEventTypes = (creditEvent, index) => {
    let type = getBankruptcyTypes();
    return (
      <Select
        id={`applicants[${props.index}][creditHistory][creditEvents][${index}].eventType`}
        name={`applicants[${props.index}][creditHistory][creditEvents][${index}].eventType`}
        ref={eventTypeRef}
        label="Event Type"
        value={getDefaultValues(creditEvent).eventType}
        options={type}
        changeCallback={(name, value) => handleEventTypeChange(index, value) }
        className="form-control"
      />
    )
  }

  const renderInfringementEventTypes = (creditEvent, index) => {
    let type = getInfringementTypes();
    return (
      <Select
        id={`applicants[${props.index}][creditHistory][creditEvents][${index}].eventType`}
        name={`applicants[${props.index}][creditHistory][creditEvents][${index}].eventType`}
        ref={eventTypeRef}
        label="Event Type"
        value={getDefaultValues(creditEvent).eventType}
        options={type}
        changeCallback={(name, value) => handleEventTypeChange(index, value) }
        className="form-control"
      />
    )
  }

  const renderAdministrationStatus = (creditEvent, index) => {
    let type = getAdministrationEventTypes();
    return (
      <Select
        id={`applicants[${props.index}][creditHistory][creditEvents][${index}].status`}
        name={`applicants[${props.index}][creditHistory][creditEvents][${index}].status`}
        ref={statusRef}
        label="Status"
        value={getDefaultValues(creditEvent).administrationStatus}
        options={type}
        changeCallback={(name, value) => changeCreditEventStatus(index, value) }
        className="form-control"
      />
    )
  }

  const renderAmount = (creditEvent, index) => {
    return (
      <Col sm={3}>
        <div className="form-prefix">$</div>
        <Input
          id={`applicants[${props.index}][creditHistory][creditEvents][${index}].amount`}
          name={`applicants[${props.index}][creditHistory][creditEvents][${index}].amount`}
          ref={amountRef}
          type="text"
          label="Amount"
          value={getDefaultValues(creditEvent).amount}
          updateOnBlur={false}
          changeDebounceInterval={0}
          blurCallback={(name, value) => Mask.numberFormsy(name, value, amountRef) }
          validationError="Amount is required."
          validations={{
            isFormattedNumber: true,
            isRequired: true,
          }}
        />
      </Col>
    )
  }

  const renderMissedPayments = (creditEvent, index) => {
    return (
      <Col sm={3}>
        <Input
          name={`applicants[${props.index}][creditHistory][creditEvents][${index}].missedPayments`}
          id={`applicants[${props.index}][creditHistory][creditEvents][${index}].missedPayments`}
          ref={missedPaymentsRef}
          changeCallback={ (name, value) => handleMissedPaymentsChange(index, value) }
          updateOnBlur={false}
          label={'Missed payments'}
          value={getDefaultValues(creditEvent).missedPayments}
          validationError={'Missed payments must be a valid number.'}
          validations={{
            positiveIntValidation: function (values, value) {
              return !isNaN(value) && parseInt(value) >= 0; 
            }
          }}
        />
      </Col>
    )
  }

  const renderInfringementToBePaidOff = (creditEvent, index) => {      
    // Display a greyed out TBPO box 'No' if the infringement is non financial.
    // This is because the TBPO field does not exist in the table
    // asysNonFinancialLiabilityRiskGrade, and isn't currently used in LBM risk grading.                  
    const disabled = isNonFinancial(creditEvent.creditEventType);
    return (
      <Col sm={6}>
        <Select
          id={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
          name={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
          ref={statusRef}
          label="To be paid out"
          value={disabled ? 'No' : getDefaultValues(creditEvent).toBePaidOff}
          options={getYesNo()}
          disabled={disabled}
          changeCallback={(name, value) => changeToBePaidOffType(index, value) }
          className="form-control"
        />
      </Col>
    );
  }

  const renderInfringementInputs = (creditEvent, index) => {
    return (
      <Col sm={11}>
        <Row>
          <Col sm={3}>
            <Select
              id={`applicants[${props.index}][creditHistory][creditEvents][${index}].status`}
              name={`applicants[${props.index}][creditHistory][creditEvents][${index}].status`}
              ref={statusRef}
              label="Status"
              value={getDefaultValues(creditEvent).status}
              options={getStatusTypes()}
              changeCallback={(name, value) => changeCreditEventStatus(index, value) }
              className="form-control"
            />
          </Col>
          {
            (() => {
              if (creditEvent.eventType === 'arrears') {
                return renderMissedPayments(creditEvent, index);
              } else {
                return renderAmount(creditEvent, index);
              }
            })()
          }
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <Input
                  name={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateIncurred`}
                  id={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateIncurred`}
                  ref={dateIncurredRef}
                  placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  changeCallback={(name, value) => handleDateIncurredChange(index, value) }
                  blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateIncurredRef)}
                  label={'Date Incurred'}
                  value={getDefaultValues(creditEvent).dateIncurred}
                  validationError={'Date Incurred must be valid'}
                  validations={{
                    isValidPastDate: true,
                  }}
                />
              </Col>
              {
                (() => {
                  if (creditEvent.status === 'paid') {
                    return (
                      <Col sm={6}>
                        <Input
                          name={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
                          id={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
                          ref={dateRectifiedRef}
                          placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                          updateOnBlur={false}
                          changeDebounceInterval={0}
                          changeCallback={(name, value) => handleDateRectifiedChange(index, value) }
                          blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateRectifiedRef)}
                          label="Date Rectified"
                          value={getDefaultValues(creditEvent).dateRectified}
                          validationError="Date Rectified must be valid"
                          validations={{
                            isValidPastDate: true,
                          }}
                        />
                      </Col>
                    );
                  } else {
                    return renderInfringementToBePaidOff(creditEvent, index);
                  }
                })()
              }
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  const renderBankruptcyInputs = (creditEvent, index) => {
    return (
      <Col sm={11}>
        <Row>
          <Col sm={3}>
              <Select
                id={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
                name={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
                ref={statusRef}
                label="To be paid out"
                value={getDefaultValues(creditEvent).toBePaidOff}
                options={getYesNo()}
                changeCallback={(name, value) => changeToBePaidOffType(index, value) }
                className="form-control"
              />
          </Col>
          <Col sm={3}>
            <Input
              name={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateIncurred`}
              id={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateIncurred`}
              ref={dateIncurredRef}
              placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
              updateOnBlur={false}
              changeDebounceInterval={0}
              blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateIncurredRef)}
              label={'List Date'}
              value={getDefaultValues(creditEvent).dateIncurred}
              validationError={'List Date must be valid'}
              validations={{
                isValidPastDate: true,
              }}
            />
          </Col>
          <Col sm={3}>
            <Input
              name={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
              id={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
              ref={dateRectifiedRef}
              placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
              updateOnBlur={false}
              changeDebounceInterval={0}
              changeCallback={(name, value) => handleDateRectifiedChange(index, value) }
              blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateRectifiedRef)}
              label="Discharged Date"
              value={getDefaultValues(creditEvent).dateRectified}
              validationError="Discharged Date must be valid"
              validations={{
                isValidPastDate: true,
              }}
            />
          </Col>
        </Row>
      </Col>
    )
  }

  const renderAdministrationInputs = (creditEvent, index) => {
    if (creditEvent.status === 'current') {
      return (
        <Col sm={11}>
          <Row>
            <Col sm={3}>
              <Select
                id={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
                name={`applicants[${props.index}][creditHistory][creditEvents][${index}].toBePaidOff`}
                ref={statusRef}
                label="To be paid out"
                value={getDefaultValues(creditEvent).toBePaidOff}
                options={getYesNo()}
                changeCallback={(name, value) => changeToBePaidOffType(index, value) }
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      )
    } else {
      return (
        <Col sm={11}>
          <Row>
            <Col sm={3}>
              <Input
                name={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
                id={`applicants[${props.index}][creditHistory][creditEvents][${index}].dateRectified`}
                ref={dateRectifiedRef}
                placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                updateOnBlur={false}
                changeDebounceInterval={0}
                blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateRectifiedRef)}
                label="Finalised Date"
                value={getDefaultValues(creditEvent).dateRectified}
                validationError="Finalised Date must be valid"
                validations={{
                  isValidPastDate: true,
                }}
              />
            </Col>
          </Row>
        </Col>
      )
    }
  }

  return (
    <div className="applicants">
      <div className="panel-header-fixed">
        <span className="visible-xs mt-sm" />
        <i className="mi mi-search-in-file mi-2x ib mr-md text-center np" style={{ width: '25px' }} />
        <div className="ib va-tt mt-sm-n">
          <h4 className="mb-h mt-h">Credit History</h4>
        </div>
      </div>

      <div className="panel-section-body" style={{ overflow: 'visible' }}>
        <div className="card card-shallow">
          <div>
            {
              (() => {
                if (!creditEvents.length) {
                  return (
                    <div className="panel-row row" onClick={addCreditEvent}>
                      <div className="text-center col-sm-12">
                        <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
                        <button type="button" id={`applicants[${props.index}]-addCreditEvent`} name={`applicants[${props.index}]-addCreditEvent`} className="btn btn-link pl-h pr-h np">Add Credit Event</button>
                      </div>
                    </div>
                  );
                }
                return (
                  <div className="creditEvents">
                    {
                      creditEvents.map((creditEvent, index) => (
                        <Row key={`credit-${index}-${creditEvent.creditEventId}`} className="panel-row r">
                          <Col sm={4}>
                            <Row>
                              <Col sm={7}>
                              {
                                (() => {
                                  return renderCreditEvent(creditEvent, index);
                                }
                                )()
                              }
                              </Col>
                              <Col sm={5}>
                                {
                                (() => {
                                  return renderEventType(creditEvent, index);
                                }
                                )()
                              }
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={8}>
                            <Row>
                              {
                                (() => {
                                  if (creditEvent.creditEventType == 'bankruptcy') { return renderBankruptcyInputs(creditEvent, index); }
                                  if (creditEvent.creditEventType == 'administration') { return renderAdministrationInputs(creditEvent, index); }
                                  return renderInfringementInputs(creditEvent, index);
                                }
                                )()
                              }
                              <Col sm={1}>
                                {
                                  (() => {
                                    if (creditEvents.length >= 1) {
                                      return (
                                        <div>
                                          <button type="button" id={`removeCreditEvent_${index}`} className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle" style={{ marginTop: '31px' }} onClick={() => { removeCreditEvent(index); }}>
                                            <Icon glyph="delete" />
                                          </button>
                                          <Col sm={12} className="visible-xs text-right">
                                            <button type="button" className="mb-md btn btn-danger" onClick={() => { removeCreditEvent(index); }}>
                                              Remove Credit Event
                                            </button>
                                          </Col>
                                        </div>
                                      );
                                    }
                                  })()
                                }
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))
                    }
                    {
                      (() => {
                        if (creditEvents.length <= 24) {
                          return (
                            <Row className="panel-row">
                              <Col sm={12} className="text-center">
                                <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
                                <button type="button" id={`applicants[${props.index}]-addCreditEvent`} name={`applicants[${props.index}]-addCreditEvent`} className="btn btn-link pl-h pr-h np" onClick={addCreditEvent}>Add Credit Event</button>
                              </Col>
                            </Row>
                          );
                        }
                      })()
                    }
                  </div>
                );
              })()
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default RateQuoteApplicantCreditHistory;