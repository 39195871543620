import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ApplicationActionIcon from './applicationActionIcon';
import ApplicationTypeIcon from './applicationTypeIcon';

const mergeFirstLastName = (individual) => ({
  ...individual,
  name: `${individual.firstName || 'Unknown'} ${individual.lastName || 'Unknown'}`,
});

const routeMapping = {
  ConsumerMotor: '/motor',
  ConsumerCaravan: '/caravan',
  CommercialMotor: '/commercial',
  NovatedLease: '/novated',
};

const getRoute = (productType) => routeMapping[productType];

const ApplicationPartialMotorListItem = (props) => (
  <Link className="loan-item" to={`/applications/submit${getRoute(props.application.productType ?? 'ConsumerMotor')}/${props.application.applicationId}`}>
    <div>
      <ApplicationTypeIcon type={2} />
        <ul className="loan-client" data-hj-masked="true">
          <li className="loan-client-name" key={`applicant-${0}`}>{props.application.displayName}</li>
        </ul>
      <p className="loan-status">Not Submitted</p>
    </div>

    <ApplicationActionIcon status={100} actions={[]} />
  </Link>
);


ApplicationPartialMotorListItem.propTypes = {
  application: PropTypes.object.isRequired,
};

export default ApplicationPartialMotorListItem;
