import * as React from 'react';
import PropTypes from 'prop-types';
import { Loadr } from '../../utils';

const LoadingPane = (props) => (
    <div className="p-md pt-lg pb-lg mt-md text-center">
      <Loadr lg inline />

      <h4 className="mt-lg">
        {'Loading '}
        {props.label}
        {'...'}
      </h4>
    </div>
);

LoadingPane.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LoadingPane;
