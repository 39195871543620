import * as React from 'react';
import PropTypes from 'prop-types';
import Mask from '../../../helpers/mask';
import { Row, Col } from 'react-bootstrap';
import Title from '../title';

interface TotalProps {
  total: number
}

export default class Total extends React.Component<TotalProps, {}> {
  render() {
    return (
      <div className="panel-section">
        <Title>Total</Title>

        <div className="panel-section-body panel-section-body-simple mt-sm">
          <Row>
            <Col sm={9} className="text-right">
              <span className="mute ib" style={{paddingTop: '12px'}}>Total assets</span>
            </Col>
            <Col sm={3}>
              <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
              <div className="form-group">
                <input
                  name="totalExpenses"
                  value={Mask.number(this.props.total)}
                  className="form-control text-right"
                  disabled
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
};
