import * as types from '../constants/types';

export const getPrompts = () => ({
  type: types.GET_PROMPTS,
});

export const addPrompt = (prompt, introducer) => (dispatch) => {
  dispatch({
    type: types.ADD_PROMPT,
    prompt,
    introducer,
  });
};

export const savePrompt = (prompt, introducer) => (dispatch) => {
  dispatch({
    type: types.SAVE_PROMPT,
    prompt,
    introducer,
  });
};

export const removePrompt = (prompt) => (dispatch) => {
  dispatch({
    type: types.REMOVE_PROMPT,
    prompt,
  });
};

export const clearPrompts = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_PROMPTS,
  });
};
