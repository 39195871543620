import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from 'react-bootstrap';
import Icon from '../../utils/icon';
import * as sessionActions from '../../actions/sessionActions';
import * as storageActions from '../../actions/storageActions';

import AssessmentChangeNotice from './assessmentChangeNotice';

export class AssessmentChangeNoticePane extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      visible: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount() {
    this.props.actions.getStorage('newIntroducerOnboarding')
      .then((onboardingRes) => {
        if (onboardingRes.data && this.props.session.details.isSubmission) {
          this.props.actions.getStorage('assessmentChangeNoticeRead')
            .then((changeNoticeReadRes) => {
              if (!changeNoticeReadRes.data) {
                this.showModal();
                this.props.actions.setStorage('assessmentChangeNoticeRead');
              }
            });
        }
      });
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  hideModal() {
    this.setState({
      visible: false,
    });
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  render() {
    return (
      <Modal
        bsSize="lg"
        id="someID"
        className="feature"
        show={this.state.visible}
        onHide={this.hideModal}
      >
        <Modal.Header>
          <Icon glyph="assignment" className="mt-lg mb-md mute-lg" size={3} />
          <h4 className="mt-md mb-lg">
            {'Hi '}
            {this.props.session.details.firstName}
            {', welcome to Liberty IQ'}
          </h4>
          <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <AssessmentChangeNotice />
        </Modal.Body>
      </Modal>
    );
  }
}


AssessmentChangeNoticePane.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...sessionActions, ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentChangeNoticePane);
