import { PrequalAction, PrequalActionType } from '../actions/prequalActions';

enum PrequalStatus {
  Initial = "Initial",
  Pending = "Pending",
  Succeeded = "Succeeded",
  Failed = "Failed"
}

export interface PrequalState {
  status: PrequalStatus;
  modal: boolean;
  data: any;
};

const initialState: PrequalState = {
  status: PrequalStatus.Initial,
  data: {},
  modal: false
};

export const prequalReducer = (state: PrequalState = initialState, action: PrequalAction): PrequalState => {
  switch (action.type) {
    case PrequalActionType.ShowCreatePrequal: {
      return {
        ...state,
        modal: true
      }
    }

    case PrequalActionType.GetPrequal:
      return {
        ...state,
        status: PrequalStatus.Pending
      };

    case PrequalActionType.GetPrequalFailed:
      return {
        ...state,
        status: PrequalStatus.Failed
      };

    case PrequalActionType.GetPrequalSucceeded:
    return {
        ...state,
        data: action.data,
        status: PrequalStatus.Succeeded
      };

    default:
      return state;
  }
};
