import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'formsy-react-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select } from 'formsy-react-components';
import Mask from '../../helpers/mask';
import * as functions from '../../constants/functions';
import { MOTOR_LOAN_TERM_MIN, MOTOR_LOAN_TERM_MAX, MOTOR_LOAN_TERM_DEFAULT } from '../../constants/types';
import { VeiCalculatorActions } from './actions';
import { VeiCalculatorState, VeiLoanInformation } from './reducer';

export interface VeiCalculatorDetailsProps {
  loanInfo: VeiLoanInformation,
  actions: {
    changeLoanAmount: Function
    changeLoanInterestRate: Function
    changeLoanTerm: Function
    changeVehicleValuationAmount: Function
    changeVehicleAge: Function
    changeBalloonAmount: Function
  }
}

export class VeiCalculatorDetails extends React.Component<VeiCalculatorDetailsProps, {}> {
  constructor(props: VeiCalculatorDetailsProps) {
    super(props);

    // -- patch actions to also pass IsFormValid
    Object.keys(props.actions).forEach(actionName => {
      const original = props.actions[actionName];
      props.actions[actionName] = ((key, value) => {

        // HACK: delay action dispatch because formsy validation state is not yet calculated
        setTimeout(() => {
          const inputs = (this.refs.form as any).formsyForm.current.inputs;
          const isFormValid = inputs.filter(i => !i.isValid()).length === 0;

          original(key, value, isFormValid);
        });
      }).bind(this);
    })
  }

  render() {
    return <Form
      layout="vertical"
      ref="form"
      disabled={false}
      className=""
      elementWrapperClassName=""
      labelClassName=""
      rowClassName=""
      validateBeforeSubmit
      validatePristine>

      <div className="panel-section">
        <div className="panel-section-header panel-header-fixed mb-md">
        </div>

        <div className="panel-section-body">
          <div className="panel-section-container">
            <Row>
              <Col sm={3}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>$</div>
                <Input
                  id="veiCalculatorDetails.loanAmount"
                  name="veiCalculatorDetails.loanAmount"
                  ref="veiCalculatorDetails.loanAmount"
                  type="text"
                  label="Loan Amount"
                  value={this.props.loanInfo.loanAmount}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                  }}
                  changeCallback={this.props.actions.changeLoanAmount}
                  className="form-control form-control-lg"
                  validationError="Loan amount is required"
                  validations={{
                    isNotZero: true,
                    isFormattedNumber: true,
                    isRequired: true
                  }}
                />
              </Col>
              <Col sm={2}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>%</div>
                <Input
                  id="veiCalculatorDetails.interestRate"
                  name="veiCalculatorDetails.interestRate"
                  ref="veiCalculatorDetails.interestRate"
                  className="form-control form-control-lg"
                  type="number"
                  step={0.01}
                  label="Interest Rate"
                  value={this.props.loanInfo.interestRate}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs, { decimal: 2 });
                  }}
                  changeCallback={this.props.actions.changeLoanInterestRate}
                  validationError="Interest rate is required"
                  validations={{
                    isFloat: true,
                    isNotZero: true,
                    isExisty: true,
                    isRequired: true
                  }}
                />
              </Col>
              <Col sm={2}>
                <Select
                  id="veiCalculatorDetails.loanTerm"
                  name="veiCalculatorDetails.loanTerm"
                  ref="veiCalculatorDetails.loanTerm"
                  label="Loan Term"
                  value={this.props.loanInfo.loanTermYears}
                  options={functions.getLoanTerms(MOTOR_LOAN_TERM_MIN, MOTOR_LOAN_TERM_MAX)}
                  changeCallback={this.props.actions.changeLoanTerm}
                  className="form-control form-control-lg"
                />
              </Col>
              <Col sm={3}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>$</div>
                <Input
                  id="veiCalculatorDetails.balloonAmount"
                  name="veiCalculatorDetails.balloonAmount"
                  ref="veiCalculatorDetails.balloonAmount"
                  type="text"
                  label="Residual/Balloon Amount"
                  value={this.props.loanInfo.balloonAmount}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                  }}
                  changeCallback={this.props.actions.changeBalloonAmount}
                  className="form-control form-control-lg"
                  validationError="Residual/Balloon amount is required"
                  validations={{
                    isFormattedNumber: true,
                    isRequired: true
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>$</div>
                <Input
                  id="veiCalculatorDetails.vehicleValue"
                  name="veiCalculatorDetails.vehicleValue"
                  ref="veiCalculatorDetails.vehicleValue"
                  type="text"
                  label="Vehicle Value"
                  value={this.props.loanInfo.vehicleValuationAmount}
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                  }}
                  changeCallback={this.props.actions.changeVehicleValuationAmount}
                  className="form-control form-control-lg"
                  validationError="Vehicle Value is required"
                  validations={{
                    isNotZero: true,
                    isFormattedNumber: true,
                    isRequired: true
                  }}
                />
              </Col>
              <Col sm={2}>
                <Input
                  id="veiCalculatorDetails.vehicleYear"
                  name="veiCalculatorDetails.vehicleYear"
                  ref="veiCalculatorDetails.vehicleYear"
                  type="number"
                  label="Vehicle Year"
                  value={this.props.loanInfo.vehicleYear}
                  changeCallback={this.props.actions.changeVehicleAge}
                  className="form-control form-control-lg"
                  validationError="Vehicle Year is required"
                  validations={{
                    isRequired: true
                  }}
                />
              </Col>

            </Row>
          </div>
        </div>
      </div>
    </Form>
  }
}

function mapStateToProps({ veiCalculator: { loanInfo } }: { veiCalculator: VeiCalculatorState }) {
  return {
    loanInfo: loanInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...VeiCalculatorActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VeiCalculatorDetails);
