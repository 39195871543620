import Storage from '../helpers/storage';

const moment = require('moment');

export default {
  applications: {
    filter: JSON.parse(Storage.getItem('preference') || '{}').filter || 1,
    list: null,
    partial: null,
    partialMotor: null,
    active: null,
    all: null,
    partialAll: null,
    partialMotorAll: null,
    query: '',
    other: 0,
    order: JSON.parse(Storage.getItem('preference') || '{}').order || 'actions_desc',
  },
  lvr: [],
  prequal: {},
  applicants: {},
  contacts: null,
  documents: null,
  prompts: [],
  faqs: [
    /**
     * Document generic
     */
    {
      method: 'faq',
      question: 'Where can I find rates and fees?',
      answer: 'Our <a href="/#/documents">Introducer Guides</a> will provide you with our latest rates, fees and limits.',
      type: [1],
      status: [9],
    },
    /**
     * Residential generic
     */
    {
      method: 'faq',
      question: 'Where do I send the application form and supporting documents?',
      answer: 'Supporting documentation can be uploaded via Liberty IQ once the application has been submitted; alternatively it can be emailed to <a href="mailto:applications@liberty.com.au">applications@liberty.com.au</a>.',
      type: [1],
      status: [1],
    },
    {
      method: 'faq',
      question: 'When can I expect a response from an underwriter? ',
      answer: 'An underwriter will assess the application and contact you once all the supporting documents have been received and searches completed – usually within 24 hours of receipt. ',
      type: [1],
      status: [1],
    },
    {
      method: 'faq',
      question: 'Can I speak to the underwriter directly? ',
      answer: 'Yes, their details are in the application contacts section and we encourage direct engagement through the assessment process to work together towards a favourable outcome.  ',
      type: [1],
      status: [2],
    },
    {
      method: 'faq',
      question: 'What happens if the application gets declined by LMI? ',
      answer: 'Your underwriter will inform you and where possible will propose a tailored solution to keep the deal moving forward. ',
      type: [1],
      status: [2],
    },
    {
      method: 'faq',
      question: 'When can the valuation be ordered? ',
      answer: 'The valuation can be ordered at any time after the valuation fee has been paid. However, we recommend waiting until conditional approval, as the valuation fee up to $330 is waived on our AAA product, where the LVR < 90%.',
      type: [1],
      status: [5],
    },
    {
      method: 'faq',
      question: 'How much does the valuation cost? ',
      answer: 'The standard fee is $330, however the fee may be higher for properties with an estimated value > $1.5m, regional locations or non-standard property types.',
      type: [1],
      status: [5],
    },
    {
      method: 'faq',
      question: 'Where do I send the executed loan documents? ',
      answer: 'Please return the executed documents via Express Post to: The Settlements team, Lvl 16 / 535 Bourke Street, Melbourne VIC 3000.',
      type: [1],
      status: [3],
    },
    {
      method: 'faq',
      question: 'What happens after the documents are returned? ',
      answer: 'Our team will certify the documents within 3 business days and confirm if we can proceed to a settlement date or require further information. ',
      type: [1],
      status: [3],
    },

    /**
     * Post settlement
     */
    {
      method: 'faq',
      question: 'What contact does Liberty make with the customer after settlement?',
      answer: 'We will send out a Welcome letter once the loan has settled. The Welcome letter will confirm first payment date, repayment amount and ongoing frequency.',
      type: [1, 2],
      status: [6],
    },
    {
      method: 'faq',
      question: 'When will the customer have their offset account set up?',
      answer: 'If the customer is eligible for and wanting to set up an offset facility, they will have to call our Customer Service team on 13 11 33 to discuss opening this account.',
      type: [1],
      status: [6],
    },
    {
      method: 'faq',
      question: 'How can the customer access their account?',
      answer: 'An activation email will be sent to the email on file at settlement with directions to be able to set up and access the customers Liberty online account.',
      type: [1, 2],
      status: [6],
    },
    {
      method: 'faq',
      question: 'When will the customer receive their surplus funds after settlement?',
      answer: 'Providing all the banking details are accurate, the customers will receive their funds within 1 to 2 business days after settlement.',
      type: [1],
      status: [6],
    },
    {
      method: 'faq',
      question: 'Where do I direct questions regarding surplus funds?',
      answer: ' If the customers have not received their funds two business days after settlement and would like to check the status, please call 13 11 33.',
      type: [1],
      status: [6],
    },
    {
      method: 'faq',
      question: 'How often will the customer receive statements?',
      answer: 'For all residential customers, Liberty will send two statements each year by communication type via post or email (January and July). Alternatively, statements can be downloaded via Liberty Online at any time.',
      type: [1],
      status: [6],
    },

    /**
     * Motor
     */
    {
      method: 'faq',
      question: 'What documents are required for assessment?',
      answer: 'To progress to assessment, the following minimum documents are required: <br />Proof of income (2 most recent payslips, Centrelink income statement etc.); <br />Privacy consent; <br />And additional documents as requested by a Credit Analyst e.g. Last 3 months of bank statements.',
      type: [2],
      status: [1, 2],
    },
    {
      method: 'faq',
      question: 'How do I make changes to an application?',
      answer: 'Changes can be made at any time by clicking on ‘Update Indicative’ and clicking ‘Save’ after changes are made.',
      type: [2],
      status: [2, 3],
    },
    {
      method: 'faq',
      question: 'What happens once the application has been submitted?',
      answer: 'Once all outstanding documents have been uploaded via the ‘Upload Documents’ function, assessment will commence by a Credit Analyst.',
      type: [2],
      status: [1, 2],
    },
    {
      method: 'faq',
      question: 'Can I communicate directly with a Credit Analyst?',
      answer: 'Yes, their details are in the application contacts section and we encourage direct engagement through the assessment process to work together towards a favourable outcome.',
      type: [2],
      status: [1, 2],
    },
    {
      method: 'faq',
      question: 'How do I proceed with a Pre-Approval?',
      answer: 'Once the applicant has chosen a vehicle, go to ‘Update Indicative’ and input the vehicle details under ‘Vehicle & Vendor’ section.',
      type: [2],
      status: [1, 2],
    },
    {
      method: 'faq',
      question: 'How do I proceed with an Indicative Approval?',
      answer: 'You are now able to proceed to settlement. Please ensure that the ‘Actions Required’ are completed and all documents uploaded.',
      type: [2],
      status: [1, 2, 3],
    },
    {
      method: 'faq',
      question: 'How do I send assessment and settlement documents?',
      answer: 'Use the ‘Upload Documents’ function to send all assessment and settlement documents.',
      type: [2],
      status: [1, 2, 3],
    },
    {
      method: 'faq',
      question: 'How do I know if the Settlements team have received the documents for the loan?',
      answer: 'The status will change to ‘Settlement Pending’.',
      type: [2],
      status: [2, 3],
    },
    {
      method: 'faq',
      question: 'How do I know if I have any outstanding settlement documents?',
      answer: 'Outstanding settlement documents will be listed as ‘Actions Required’ at the top of the screen.',
      type: [2],
      status: [3],
    },
    {
      method: 'faq',
      question: 'How do I know if a loan has settled?',
      answer: 'Once all settlement requirements have been fulfilled, the status will change to ‘Settled’ and you will receive a settlement confirmation email.',
      type: [2],
      status: [3],
    },
    {
      method: 'faq',
      question: 'Do I need to send in the hardcopy of the signed settlement documents?',
      answer: 'No. There is no need to send Liberty the original signed settlement documents as the electronic copies are sufficient.',
      type: [2],
      status: [3],
    },

    /**
     * Commercial
     */
    /**
     * Commercial Documents
     */
    {
      method: 'faq',
      question: 'Where can I find rates and fees?',
      answer: 'Information surrounding rates and fees can be accessed on our <a href="/#/documents">Introducer Guides</a>.',
      type: [5],
      status: [9],
    },
    {
      method: 'faq',
      question: 'Where can I find a Credit Card Authority Form?',
      answer: 'Our credit card authority form can be found  <a href="/#/documents">here</a>.',
      type: [5],
      status: [9],
    },
    /**
     * Commercial Submission
     */
    {
      method: 'hint',
      question: 'Missing information',
      answer: 'One of the most common causes of delay in an application’s progress is missing documents. To avoid delays, please use our <a href="/#/documents">Cover Notes and Checklist</a>.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'How soon after submitting an application When can I expect a response?',
      answer: 'Please contact your BDM or Support Officer to confirm our SLAs, as turnaround times can vary.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'Where do I send the application form and supporting documents?',
      answer: 'Supporting documentation can be uploaded via Liberty IQ once the application has been submitted. Alternatively, please contact your Support Officer if you require further assistance.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'What is the borrowing structure of an SMSF loan?',
      answer: 'Our Liberty SuperCredit applications follow a limited recourse borrowing arrangement (LRBA). The SMSF is the borrower on the loan and associated individuals are guarantors. The property is held in a bare trust, whereby the property trustee performs the actions on behalf of the trust.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'Does Liberty set up the Bare Trust?',
      answer: 'Once an application has been formally approved, on request Liberty can provide customers with a Bare Trust Deed template free of charge. The customer\'s accountant or legal representative will need to assist them in setting up the Bare Trust and arrange stamping of the deed if applicable.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'How do I see whether something has successfully uploaded via IQ?',
      answer: 'Once you have uploaded a document, you will see a time stamp under the document if the upload has been successful.',
      type: [5],
      status: [1],
    },
    {
      method: 'faq',
      question: 'Where can I access servicing calculators?',
      answer: 'As our internal policies/guidelines are constantly evolving, we are unable to provide our Business Partners with a servicing calculator. For assistance surrounding servicing or any queries you may have, please contact <a href="mailto:commercialscenarios@liberty.com.au">commercialscenarios@liberty.com.au</a> who can advise around any servicing queries you may have.',
      type: [5],
      status: [1],
    },
    /**
     * Commercial Assessment
     */
    {
      method: 'faq',
      question: 'Can I speak to the underwriter directly?',
      answer: 'Yes. We encourage direct engagement through the assessment process to work together towards a favourable outcome. We recommend that your first point of contact for any queries is with your Support Officer. If they are unable to assist, they will refer you to the Underwriter directly. ',
      type: [5],
      status: [2],
    },
    {
      method: 'faq',
      question: 'Why are customers required to submit their business bank statements via the Illion Service?',
      answer: 'Liberty uses the Illion service as it provides an efficient means for us to assess business transactions. Illion provides an encrypted data retrieval service that has passed all Australian Government regulatory requirements. Please refer to <a href="https://bankstatements.com.au/submission">Illion\'s Terms and Conditions </a> for more information.',
      type: [5],
      status: [2],
    },
    {
      method: 'faq',
      question: 'Why are customers required to verify their identity via Max ID?',
      answer: 'Liberty is required to demonstrate verification of identity standards, in line with titles office requirements. Max ID is an electronic system of verification which allows customers to complete this part of the process in collaboration with a colleague or friend. Please reach out to your support officer for assistance.',
      type: [5],
      status: [2],
    },
    {
      method: 'faq',
      question: 'When will any conditional items be reviewed for assessment?',
      answer: 'Once all the conditional items are provided, an Underwriter will review the application. Please ensure all conditions provided to us are correct and as requested to avoid delays in assessment. Should you require further clarification, please contact the Support Officer or Underwriter.',
      type: [5],
      status: [2],
    },
    {
      method: 'faq',
      question: 'Who should be listed as the purchaser in the Contract of Sale?',
      answer: 'We cannot offer advice as to who purchases property. It is common in our experience with SMSF/LRBA borrowing for a Property/Bare Trustee company to acts as trustee for a Bare Trust, which holds the property.',
      type: [5],
      status: [2],
    },
    {
      method: 'faq',
      question: 'What happens if the application gets declined?',
      answer: 'Your Underwriter will inform you and seek to find a tailored solution where possible.',
      type: [5],
      status: [2],
    },
    /**
     * Commercial Valuation
     */
    {
      method: 'faq',
      question: 'Does Liberty provide customers with a copy of the completed valuation report?',
      answer: 'The valuation is provided to Liberty Financial for mortgage purposes. We cannot release the full report as it is provided for Liberty’s exclusive use.',
      type: [5],
      status: [5],
    },
    {
      method: 'faq',
      question: 'How much does the valuation cost?',
      answer: 'Confirmation of valuation quotes will be provided to you once the file is conditionally approved. The cost can vary depending on the security property. For AAA residential SuperCredit loans, the valuation is free.',
      type: [5],
      status: [5],
    },
    {
      method: 'faq',
      question: 'When can the valuation be ordered?',
      answer: 'On payment of the valuation fee or provision of a Credit Card Authority Form, the valuation can be ordered.',
      type: [5],
      status: [5],
    },
    {
      method: 'faq',
      question: 'How long will the valuation take?',
      answer: 'Your Support Officer will be able to provide a time estimate based on their knowledge of the valuer\'s workloads. While turnaround times for valuations vary, our valuers do their best to adhere to quoted turnaround times subject to access and receipt of all necessary information and documentation. Some valuers offer an expedited turnaround at a higher cost.',
      type: [5],
      status: [5],
    },
    {
      method: 'faq',
      question: 'Can I request Liberty to order a valuation prior to submitting an application?',
      answer: 'You may request an upfront valuation prior to submitting an application. Requests are considered on a case-by-case basis. Fees will be required up-front for all valuations.',
      type: [5],
      status: [5],
    },
    /**
     * Commercial Settlement
     */
    {
      method: 'hint',
      question: 'Outstanding formal conditions',
      answer: 'Any conditions set out in the Formal Approval Letter not yet satisfied will be required to be provided to the Settlement Officer prior to settlement. The file cannot be progressed without this.',
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'How do I avoid settlement delays?',
      answer: `The most common causes for delays in settlements are:
        <ul>
            <li>Incorrectly completed ID Certificates</li>
            <li>Incorrect witnessing of documents</li>
            <li>The other party not being in a position to settle (e.g., the outgoing lender for a refinance is not ready)</li>
            <li>Insurance for the security is incorrect (e.g., incorrect insurance amount, Secure Funding Pty Ltd not listed as mortgagee, or the policy has not yet commenced) </li>
            <li>Incorrect or missing approval conditions</li>
        </ul>
        `,
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'How do I ensure the Identifiers Certificate is completed correctly?',
      answer: `We suggest you familiarise yourself with the Identifier's Certificate in detail. Common mistakes made regarding the Certificate which can cause delays with settlement include:
        <ul>
            <li>Original certified copies not sent with the certificate </li>
            <li>Missing ID's - we require both from List A, OR one from each list (1, 2 and 3. See <a href="http://ihub.liberty.com.au/sites/AU_COM/Shared/AU%20COM%20Resources/Settlements%20-%20Identifiers%20Certificate%20SAMPLE.pdf">here</a>).</li>
            <li>Identification documents are invalid or out of date</li>
            <li>Incorrect witnessing</li>
        </ul>
        `,
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'How will I know when loan documents have been received?',
      answer: 'Upon receipt of the documents, our Settlements team will send you a confirmation email.',
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'Is Liberty a PEXA subscriber and what does this mean for settlements?',
      answer: 'Yes. Liberty transacts on PEXA allowing us to facilitate electronic settlements without the need to engage with external agents for cheques and physical settlement. Our subscriber name is ‘Liberty Financial.’ This often means we can offer faster turnaround times for settlements and in some cases same day settlement.',
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'What happens after loan documents are returned?',
      answer: 'Our team will certify the loan documents within four business days of them being received and confirm if we can proceed to a settlement date or if we require further information or documents.',
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'Where do I send the executed loan documents?',
      answer: 'Return the executed loan documents via Express Post to: Commercial Settlements, 16/535 Bourke Street. Melbourne VIC 3000.',
      type: [5],
      status: [3],
    },
    {
      method: 'faq',
      question: 'Who do I contact if have questions about my commission?',
      answer: 'Please send your query to <a href="mailto:commisions@liberty.com.au">commisions@liberty.com.au',
      type: [5],
      status: [3],
    },
    /**
     * Post settlement
     */
    {
      method: 'faq',
      question: 'How can the customer access their account?',
      answer: 'An activation email will be sent to the email on file at settlement with directions to be able to set up and access the customers Liberty online account.',
      type: [5],
      status: [6],
    },
    {
      method: 'faq',
      question: 'What contact does Liberty make with the customer following settlement?',
      answer: 'We will send out a Welcome letter once the loan has settled. The Welcome letter will confirm first payment date, repayment amount and ongoing frequency.',
      type: [5],
      status: [6],
    },
    {
      method: 'faq',
      question: 'When will the customer receive their surplus funds after settlement?',
      answer: 'Provided all the banking details are accurate, the customers will receive their funds within one to two days after settlement.',
      type: [5],
      status: [6],
    },
    {
      method: 'faq',
      question: 'Where do I direct questions regarding surplus funds?',
      answer: 'If the customers have not received their funds two business days after settlement and would like to check the status, please call 13 11 33.',
      type: [5],
      status: [6],
    },
    {
      method: 'faq',
      question: 'Who should I call to confirm the instalment amount, due date and direct debit details?',
      answer: 'Please call 13 11 33 and our Service Team will provide the details for you. Please note, Business Partners retain authority on the account for only 30 days post-settlement. After this, a third-party authority form is required to discuss any matters relating to the account.',
      type: [5],
      status: [6],
    },
    /**
     * Hints
     */
    {
      method: 'hint',
      question: 'Missing information',
      answer: 'One of the most common causes of delay in an application’s progress is missing documents, to avoid delays please use our Cover Notes & Checklist which can be found <a href="/#/documents">here</a>.',
      type: [1],
      status: [1],
    },
    {
      method: 'hint',
      question: 'Assessment documents',
      answer: 'Best to check all supporting documents and statements for any arrears or undisclosed debts as these may impact risk grading.',
      type: [1],
      status: [2],
    },
    {
      method: 'hint',
      question: 'Valuation contact details',
      answer: 'Please ensure we have the full contact details for access to the property with this person prepared to take the call. ',
      type: [1],
      status: [5],
    },
    {
      method: 'hint',
      question: 'How do I ensure the Identifier’s Certificate is completed correctly? ',
      answer: `We suggest you familiarise yourself with the Identifier’s Certificate in detail. Common mistakes made in regards to the Certificate which can cause delays with settlement include:
        <ul>
            <li>Original certified copies not sent in with the Certificate;</li>
            <li>Missing ID’s – we require both from List A, OR one from each List (1, 2 & 3);</li>
            <li>Identification documents are invalid or out of date;</li>
            <li>Incorrect witnessing. In QLD in particular, a Justice of the Peace or Commissioner of Declaration must witness the mortgage document. In addition to this, the person witnessing the mortgage must also certify the Identifier’s Certificate and copies of identification.</li>
        </ul>
        `,
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'How will I know the documents have been received?',
      answer: 'Upon receipt of the documents, the Settlements team will check the documents and you will receive a confirmation email from us.',
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'What happens after the documents are returned?',
      answer: 'Our team will certify the documents within 3 business days and confirm if we can proceed to a settlement date or require further information.',
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'How do I avoid settlement delays?',
      answer: `The most common causes for delays in settlements are:
        <ul>
            <li>Incorrectly executed ID Certificates (please see below);</li>
            <li>Incorrect witnessing. Especially in QLD where a Justice of the Peace or Commissioner of Declaration must witness the mortgage, Identifier’s Certificate and copies of identification;</li>
            <li>Liberty not holding the details of the purchaser/customer’s solicitor on file to communicate details for settlement booking;</li>
            <li>The other party not being in a position to settle (e.g., the outgoing lender for a refinance not being ready);</li>
            <li>Insurance for the security is incorrect (e.g., incorrect insurance amount, Secure Funding Pty Ltd not listed as mortgagee, or the policy has not yet commenced).</li>
        </ul>
        `,
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'Who do I contact if I have questions about my commission?',
      answer: 'Please send your query to commissions@liberty.com.au',
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'Is Liberty a PEXA subscriber and what does this mean for settlements?',
      answer: 'Yes! Liberty has been transacting on PEXA since August 2017. This allows Liberty to facilitate our electronic settlements without the need to engage with external agents for cheques and physical settlement. This often means we are able to offer quicker turnaround times for settlements and in some cases same day settlement.',
      type: [1],
      status: [3],
    },
    {
      method: 'hint',
      question: 'Avoiding settlement delays',
      answer: 'You can achieve a speedy settlement by simultaneously uploading all documents listed as ‘Actions Required’.',
      type: [2],
      status: [3],
    },
    {
      method: 'hint',
      question: 'Can living expenses be split if the partner is only receiving Centrelink income?',
      answer: 'No, we do not split expenses when the partner to the applicant only receives Centrelink income. Expenses can only be split if the partner is currently employed for a minimum of 3 months.',
      type: [2],
      status: [1, 2, 3],
    },
  ],
  reference: {
    insurers: [],
    otherIncomeGroups: [],
    otherIncomeTypes: [],
    verificationLevels: [],
    loanPurposes: [],
    veiTypes: [],
    lpiTypes: [],
    vendorTypes: [],
    years: [],
    makes: [],
    models: [],
    bodyTypes: [],
    transmissions: [],
    categories: [],
    nvics: [],
    nvic: null,
    vehicleYearOnYearDepreciation: [],
    appraisal: null,
  },
  session: {
    token: null,
    username: null,
    userid: null,
    details: {},
    capabilities: [],
  },
  calculators: {
    prequals: [],
    prequal: null,
  },
  originators: {
    isSuperUser: false,
    originators: [],
    maxItems: 0,
  },
  veiCalculator: {
    loanInfo: {
      loanAmount: '0',
      loanTermYears: '5',
      interestRate: '0.00',
      vehicleValuationAmount: '0',
      vehicleYear: moment().year().toString(),
      balloonAmount: '0',
    },
    isValid: false,
    validLoanInfo: undefined,
    maxGap: undefined,
    validVeiOptions: undefined,
  },
  events: JSON.parse(Storage.getItem('events') || '{}'),
  storage: {},
  motorDeclarations: JSON.parse(Storage.getItem('motorDeclarations') || '{}'),
};
