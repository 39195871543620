import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'formsy-react-components';
import { withRouter, hashHistory } from 'react-router';
import { notify as Notify } from 'react-notify-toast';


import Guid from 'guid';

import * as messages from '../../constants/messages';

import * as referenceActions from '../../actions/referenceActions';
import * as calculatorsActions from '../../actions/calculatorsActions';
import * as storageActions from '../../actions/storageActions';

import RepaymentsHeader from './repaymentsHeader';
import RepaymentsDetails from './repaymentsDetails';
import RepaymentsResult from './repaymentsResult';

import Event3rdParty from '../../helpers/event3rdParty';
import PMT from '../../helpers/pmt';
import Mask from '../../helpers/mask';

import CalculatorPane from '../../components/calculatorPane';
import CalculatorModal from '../../components/calculatorModal';
import CalculatorPrompt from '../../components/calculatorPrompt';
import { ProfileType } from '../../actions/prequalActions';
const Moment = require('moment');

export class RepaymentsPage extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      result: [0],
      data: {
        'repaymentDetails.interestRate': '0.00',
        'repaymentDetails.loanAmount': '0',
        'repaymentDetails.term': 30,
        'repaymentDetails.interestOnly': 0,
      },
      init: false,
      invalid: true,
      modal: false,
      prompt: false,
      profileName: null,
      profileId: null,
      lastUpdated: null,
      hasChanged: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.trySubmit = this.trySubmit.bind(this);

    this.getRepayment = this.getRepayment.bind(this);

    this.setChange = this.setChange.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setPrequal = this.setPrequal.bind(this);
    this.setPrompt = this.setPrompt.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setProcessing = this.setProcessing.bind(this);

    this.dismissPrequal = this.dismissPrequal.bind(this);

    this.savePrequal = this.savePrequal.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.id) {
      this.props.actions.getPrequal(this.props.params.id)
        .then((result) => {
          if (result.data) {
            this.setState({
              init: true,
              profileName: result.data.profileName,
              profileId: result.data.profileId,
              lastUpdated: result.data.lastUpdated,
              data: { ...this.state.data, ...JSON.parse(result.data.value) },
            }, this.setPrequal);
          }
        });
    } else {
      this.setState({
        init: true,
      });
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, (route) => {
      if (this.state.hasChanged) {
        this.setPrompt(route);
        return false;
      }

      return true;
    });
  }

  trySubmit() {
    const errors = this.form.formsyForm.current.inputs.filter((input) => !input.isValid());
    if (!errors.length) {
      this.handleSubmit(this.form.formsyForm.current.getModel());
    } else {
      this.handleInvalidSubmit();
    }
  }

  handleSubmit(data) {
    Event3rdParty.send('repayments');

    const result = this.getRepayment(data.repaymentDetails);
    this.setState({
      result,
      invalid: result[0] == 0,
    });
  }

  setChange(hasChanged) {
    this.setState({
      hasChanged,
    });
  }

  handleChange(data) {
    const hasChanged = Object.keys(data).some((key) => data[key] != this.state.data[key]);
    if (hasChanged) {
      this.setState({
        hasChanged,
        data: { ...this.state.data, ...data },
      }, this.trySubmit());
    }
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  dismissPrequal() {
    this.setChange(false);
  }

  savePrequal(route) {
    if (!this.state.profileName) {
      this.setModal(true);
    } else {
      this.setProcessing('Saving prequalification...');

      const profileId = this.state.profileId || Guid.create().value;
      const profileData = { ...(this.state.data || {}), ...this.form.formsyForm.current.getModel() };

      // 3rd party
      Event3rdParty.send('save-repayments');

      this.props.actions.savePrequal(this.state.profileName, JSON.stringify(profileData), profileId, ProfileType.ResidentialRepayments)
        .then(() => {
          Notify.show(messages.MESS_SUCCESS, 'success');
          this.setState({
            profileId,
            hasChanged: false,
            lastUpdated: Moment().format(),
          }, () => {
            if (route) {
              hashHistory.push(route);
            }
          });
        })
        .catch()
        .then(() => {
          this.setProcessing(false);
        });
    }
  }

  getRepayment(data) {
    const result = [];

    data.term = parseInt(data.term) * 12;
    data.loanAmount = parseInt(data.loanAmount.toString().replace(/[^0-9.]/g, ''));

    if (parseInt(data.interestOnly)) {
      result.push(data.interestRate * data.loanAmount / 1200);
    }

    result.push(PMT(data.interestRate / 1200, (parseInt(data.term) - parseInt(data.interestOnly * 12)), data.loanAmount));
    return result;
  }

  handleInvalidSubmit() {
    this.setState({
      invalid: true,
      result: [0],
    });
  }

  setModal(modal) {
    this.setState({
      modal,
    });
  }

  setPrompt(prompt) {
    this.setState({
      prompt,
    });
  }

  setPrequal() {
    if (this.state.data && this.state.data.repaymentDetails) {
      const data = Mask.flatten(this.state.data);
      this.form.formsyForm.current.reset(data);

      setTimeout(() => {
        if (!this.form.formsyForm.current.inputs.filter((input) => !input.isValid()).length) {
          this.handleSubmit(this.form.formsyForm.current.getModel(), true);
        }

        this.setChange(false);
      });
    }
  }

  setProfile(data) {
    this.setState({
      profileName: data.name,
    }, this.savePrequal);
  }

  /**
   * Render
   */
  render() {
    return (
      <div>
        <CalculatorModal visible={this.state.modal} setModal={this.setModal} setProfile={this.setProfile} />
        <CalculatorPane id={this.state.profileId} name={this.state.profileName} saved={this.state.lastUpdated} setModal={this.setModal} active="repayment" />

        <CalculatorPrompt visible={this.state.prompt} setPrompt={this.setPrompt} savePrequal={this.savePrequal} dismissPrequal={this.dismissPrequal} />

        <Row>
          <Col sm={12} className="main-panel">

            <Form
              onValidSubmit={this.handleSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}
              onChange={this.handleChange}
              layout="vertical"
              ref={(form) => { this.form = form; }}
            >

              <div className="panel panel-default">
                <div className="panel-body p-h">
                  <RepaymentsHeader />

                  <RepaymentsDetails />

                  <RepaymentsResult
                    result={this.state.result}
                    data={this.state.data}
                    invalid={this.state.invalid}
                    savePrequal={this.savePrequal}
                  />
                </div>
              </div>

            </Form>
          </Col>
        </Row>

      </div>
    );
  }
}

RepaymentsPage.propTypes = {
  actions: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any,
  route: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
    calculators: state.calculators,
    session: state.session,
    events: state.events,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions, ...calculatorsActions, ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RepaymentsPage));
