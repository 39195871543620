import { v4 as uuidv4 } from 'uuid';
import API from '../api';
import * as types from '../constants/types';


export const filterApplications = (filter) => ({
  type: types.FILTER_APPLICATIONS,
  filter,
});

export const sortApplications = (order) => ({
  type: types.SORT_APPLICATIONS,
  order,
});


export const searchApplications = (query) => ({
  type: types.SEARCH_APPLICATIONS,
  query,
});

export const getApplications = () => (dispatch) => API.getApplications()
  .then((response) => dispatch({
    type: types.GET_APPLICATIONS,
    list: response.data,
  }));

export const getPartialMotorApplications = () => (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: types.GET_PARTIAL_MOTOR_APPLICATIONS_START,
    requestId,
  });
  return API.getPartialMotorApplications()
    .then((response) => dispatch({
      type: types.GET_PARTIAL_MOTOR_APPLICATIONS_COMPLETE,
      list: response.data,
      requestId,
    }), (error) => dispatch({
      type: types.GET_PARTIAL_MOTOR_APPLICATIONS_ERROR,
      error,
      requestId,
    }));
};

export const getApplication = (applicationID) => ({
  type: types.GET_APPLICATION,
  applicationID,
});

export const updateApplication = (result) => ({
  type: types.UPDATE_APPLICATION,
  result,
});

export const saveApplication = (data) => (dispatch) => API.saveApplication(data)
  .then(() => dispatch({
    type: types.SAVE_APPLICATION,
  }));

export const pollApplication = (applicationNumber, attempt) => (dispatch) => API.pollApplication(applicationNumber, attempt)
  .then((response) => dispatch({
    type: types.POLL_APPLICATION,
    response,
  }));


export const completeUpload = (action, file) => ({
  type: types.COMPLETE_UPLOAD,
  action,
  file,
});
export const completeAction = (applicationNumber, action) => (dispatch) => API.setActionComplete(applicationNumber, action.id)
  .then(() => dispatch({
    type: types.COMPLETE_ACTION,
    action,
  }));

export const setApplicantProperty = (data) => (dispatch) => {
  dispatch({
    type: types.SET_APPLICANT_PROPERTY,
    data,
  });

  return API.setApplicantProperty(data);
};
