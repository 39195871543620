import initialState from './initialState';
import * as types from '../constants/types';

import Storage from '../helpers/storage';

const Moment = require('moment');

export default function eventsReducer(state = initialState.events, action) {
  switch (action.type) {
    case types.GET_EVENT:
      return state;

    case types.SET_EVENT: {
      const newState = JSON.parse(JSON.stringify(state));
      const userid = JSON.parse(Storage.getItem('session'));

      if (!newState[userid]) {
        newState[userid] = {};
      }

      newState[userid][action.event] = Moment().format();
      Storage.setItem('events', JSON.stringify(newState));

      return newState;
    }

    default:
      return state;
  }
}
