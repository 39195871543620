import * as sessionActions from '../actions/sessionActions';

const jwtMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (typeof action === 'function') {
    const currentState = getState();
    const { session } = currentState;
    
    if (session && session.token && session.timer >= 90) {
      if (!session.renewPromise) { return sessionActions.renewToken(() => next(action))(dispatch); }
      return session.renewPromise.then(() => next(action));
    }
  }

  return next(action);
};

export default jwtMiddleware;
