import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import * as ReactTooltip from 'react-tooltip';
import * as Guid from 'guid';
import { Link } from 'react-router';
import Event3rdParty from '../../helpers/event3rdParty';

import Mask from '../../helpers/mask';

import { Icon } from '../../utils';

import * as functions from '../../constants/functions';

const investmentPurpose = '1';

export default class ServiceAbilityProductResidential extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      lvr: 0,
      splits: [{
        ownerships: [],
        splitId: Guid.create().value,
      }],
      hideHelia: false,
    };

    this.handleLvr = this.handleLvr.bind(this);
    this.addSplit = this.addSplit.bind(this);
  }

  inputRefs = {};

  setRef = (id) => (ref) => {
    if (ref) this.inputRefs[id] = ref;
    else delete this.inputRefs[id];
  };

  recreateOwnershipsForInvestmentSplits() {
    const { splits } = this.state;
    splits.forEach((split, index) => {
      if (split.purpose === investmentPurpose) {
        let newOwnerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
        const hasCustomOwnership = functions.hasCustomOwnership(split.ownerships);
        if (hasCustomOwnership) { newOwnerships = functions.copyCustomOwnerships(split.ownerships, newOwnerships); }
        splits[index].ownerships = newOwnerships;
        splits[index].hasCustomOwnership = hasCustomOwnership;
      }
    });
    this.setState({
      splits,
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.multiLoanDetails && this.props.data.multiLoanDetails[this.props.index].splitLoanDetails) {
      const splits = [];
      this.props.data.multiLoanDetails[this.props.index].splitLoanDetails.forEach((split) => {
        splits.push({
          ...split,
          splitId: split.id,
        });
      });

      this.setState({
        splits,
      }, () => this.recreateOwnershipsForInvestmentSplits());
    }
  }

  componentDidMount() {
    this.handleLvr();
  }

  componentDidUpdate(prevProps) {
    const changed = functions.applicantsDidChange(prevProps.data.applicants)(this.props.data.applicants);
    if (changed === true) this.recreateOwnershipsForInvestmentSplits();
    ReactTooltip.rebuild();
  }

  handleLvr() {
    let lvr = 0;

    const purchaseAmount = parseInt(Mask.clean(this.inputRefs[`multiLoanDetails[${this.props.index}].purchaseAmount`].getValue()));
    let loanAmount = 0;

    this.state.splits.forEach((split, index) => {
      loanAmount += parseInt(Mask.clean(this.inputRefs[`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].loanAmount`].getValue()));
    });

    if (purchaseAmount && loanAmount) {
      lvr = ((loanAmount / purchaseAmount) * 100).toFixed(1);
      if (lvr > 100 || isNaN(lvr)) {
        lvr = 0;
      }
    }

    this.setState({
      lvr,
    });
  }

  addSplit() {
    this.setState({
      splits: [...this.state.splits, { splitId: Guid.create().value }],
    });

    // 3rd party
    Event3rdParty.send('split-serviceability');
  }

  removeSplit(splitId) {
    this.setState({
      splits: this.state.splits.filter((split) => split.splitId !== splitId),
    }, this.handleLvr);
  }

  changePurpose(index, value) {
    const { splits } = this.state;
    splits[index].purpose = value;
    splits[index].hasCustomOwnership = false;
    if (value === investmentPurpose) {
      splits[index].ownerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
    } else {
      splits[index].ownerships = undefined;
    }
    this.setState({
      splits,
    });
  }

  changeOwnership(splitIndex, ownershipIndex, value) {
    const splits = [...this.state.splits];
    splits[splitIndex].ownerships[ownershipIndex].owns = value;
    this.setState({
      splits,
    });
  }

  customiseOwnership(index) {
    const { splits } = this.state;
    splits[index].hasCustomOwnership = true;
    this.setState({
      splits,
    });
  }


  /**
   * Render
   */
  render() {
    return (
      <div className="card card-shallow card-stack expenses">
        <Row className={this.state.splits.length > 1 ? 'r panel-row' : 'r mb-md-n'}>

          <Input
            id={`multiLoanDetails[${this.props.index}].id`}
            name={`multiLoanDetails[${this.props.index}].id`}
            value={this.props.loans[this.props.index].loanId?.toString()}
            type="hidden"
          />

          <Col sm={4}>
            <Row>
              <Col sm={4}>

                <div className="form-prefix">%</div>
                <div className="form-group">
                  <label className="control-label">LVR</label>
                  <input className="form-control" disabled value={this.state.lvr} />
                </div>

              </Col>

              <Col sm={8}>
                <div className="form-prefix">$</div>
                <Input
                  id={`multiLoanDetails[${this.props.index}].purchaseAmount`}
                  name={`multiLoanDetails[${this.props.index}].purchaseAmount`}
                  ref={this.setRef(`multiLoanDetails[${this.props.index}].purchaseAmount`)}
                  type="text"
                  label={(
                    <span>
                      {'Security amount '}
                      <small className={`mute ${this.state.splits.length == 1 ? 'hidden' : ''}`}>(total)</small>
                    </span>
                  )}
                  value={this.props.data && this.props.data.multiLoanDetails ? Mask.number(this.props.data.multiLoanDetails[this.props.index].purchaseAmount)?.toString() : '0'}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    this.handleLvr();
                    Mask.numberFormsy(name, value, this.inputRefs);
                  }}
                  changeDebounceInterval={0}
                  changeCallback={this.handleLvr}
                  validationError="Security value is required"
                  validations={{
                    isFormattedNumber: true,
                    isRequired: true,
                  }}
                />
              </Col>
            </Row>
          </Col>


          <Col sm={2}>
            <Select
              id={`multiLoanDetails[${this.props.index}].riskGrade`}
              name={`multiLoanDetails[${this.props.index}].riskGrade`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].riskGrade`)}
              label="Risk Grade"
              value={functions.getRiskGrades()[0].value}
              options={functions.getRiskGrades()}
              className="form-control"
            />
          </Col>

          <Col sm={2}>
            <Select
              id={`multiLoanDetails[${this.props.index}].term`}
              name={`multiLoanDetails[${this.props.index}].term`}
              ref={this.setRef(`multiLoanDetails[${this.props.index}].term`)}
              label="Loan Term"
              value="30"
              options={functions.getLoanTerms()}
              className="form-control"
            />
          </Col>

          <Col sm={4}>
            <Row>
              <Col sm={4} style={{ marginTop: '12px' }}>
                <Checkbox
                  id={`multiLoanDetails[${this.props.index}].isSwift`}
                  name={`multiLoanDetails[${this.props.index}].isSwift`}
                  ref={this.setRef(`multiLoanDetails[${this.props.index}].isSwift`)}
                  rowClassName="ib mr-sm"
                  disabled={this.props.loans[this.props.index]['loanDetails.purpose'] || this.props.loans[this.props.index]['loanDetails.riskGrade'] != 'AAA'}
                  valueLabel={<span>Swift</span>}
                />
                <i className="mute a mi mi-help-outline pointer" data-event="click" data-tip="An option to the Star product for applications above 80% LVR where LMI approval is not available." />
              </Col>
              <Col
                sm={4}
                style={{ marginTop: '12px' }}
                hidden={this.state.hideHelia}
              >
                <Checkbox
                  id={`multiLoanDetails[${this.props.index}].isHelia`}
                  name={`multiLoanDetails[${this.props.index}].isHelia`}
                  ref={this.setRef(`multiLoanDetails[${this.props.index}].isHelia`)}
                  rowClassName="ib mr-sm"
                  valueLabel={<span>Helia</span>}
                />
                <i className="mute a mi mi-help-outline pointer" data-event="click" data-tip="A 3% servicing buffer is applied to the loan. For other mortgage commitments, repayments will be calculated based on the higher of the floor rate (currently 6%) over 30 years P&I and the actual repayment amount (subject to Helia's credit criteria)." />
              </Col>
            </Row>
          </Col>
          {
            (() => {
              if (this.props.loans.length > 1) {
                return (
                  <div>
                    <button type="button" className="btn btn-default btn-danger a mr-sm hidden-xs btn-circle" style={{ marginTop: '31px', right: 0 }} onClick={() => { this.props.removeLoan(this.props.index); }}>
                      <Icon glyph="delete" />
                    </button>

                    <Col sm={12} className="visible-xs text-right">
                      <button type="button" className="mb-md btn btn-danger" onClick={() => { this.props.removeLoan(this.props.index); }}>
                        Remove split
                      </button>
                    </Col>
                  </div>
                );
              }
            })()
          }
        </Row>

        {
          this.state.splits.map((split, index) => (
            <Row key={`split-${split.splitId}`} className="panel-row r mt-lg">
              <Input
                id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].id`}
                name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].id`}
                value={split.splitId?.toString()}
                type="hidden"
              />

              {this.state.splits.length > 1 && (
              <h3 className="panel-row-title">
                {'Split '}
                {index + 1}
              </h3>
              )}

              <Col sm={4}>
                <Row>

                  <Col sm={4}>
                    <div className="form-prefix">%</div>
                    <Input
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].interestRate`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].interestRate`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].interestRate`)}
                      type="text"
                      label={<span>Rate</span>}
                      value={split.interestRate?.toString() || '0'}
                      validationError="Interest rate is required"
                      validations={{
                        isFloat: true,
                        isExisty: true,
                        isRequired: true,
                        isNotZero: true,
                        customValidator: (values, value) => value <= 30,
                      }}
                    />
                    <small className="mute mt-md-n pt-xs b mb-md">
                      <Link to={`/calculators/rate${this.props.id ? `/${this.props.id}` : ''}`}>Look up rate</Link>
                    </small>
                  </Col>

                  <Col sm={8}>
                    <div className="form-prefix">$</div>
                    <Input
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].loanAmount`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].loanAmount`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].loanAmount`)}
                      type="text"
                      label={`${this.state.splits.length == 1 ? 'Loan' : 'Split'} amount`}
                      value={split.loanAmount?.toString() || '0'}
                      updateOnBlur={false}
                      blurCallback={(name, value) => {
                        this.handleLvr();
                        Mask.numberFormsy(name, value, this.inputRefs);
                      }}
                      changeDebounceInterval={0}
                      changeCallback={this.handleLvr}
                      validationError="Loan amount is required and must be smaller than the security value"
                      validations={{
                        customValidator: (values, value) => {
                          let loanAmount = 0;
                          this.state.splits.forEach((_, i) => {
                            loanAmount += parseInt(Mask.clean(values[`multiLoanDetails[${this.props.index}][splitLoanDetails][${i}].loanAmount`]) || 0);
                          });
                          return value && value != '0' && loanAmount <= parseInt(Mask.clean(values[`multiLoanDetails[${this.props.index}].purchaseAmount`]));
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col sm={5}>
                <Row>
                  <Col sm={6}>
                    <Select
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].repayment`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].repayment`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].repayment`)}
                      label="Repayments"
                      value={split.repayment?.toString() || '0'}
                      options={functions.getRepayments()}
                      className="form-control"
                    />
                  </Col>


                  <Col sm={6}>
                    <Select
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].purpose`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].purpose`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].purpose`)}
                      label="Purpose"
                      value={split.purpose?.toString() || '0'}
                      options={functions.getLoanPurposes()}
                      changeCallback={(_, value) => { this.changePurpose(index, value); }}
                      className="form-control"
                    />
                    <small className="mute mt-md-n pt-xs b mb-md">
                      <button
                        className="btn-link secondary pl-h"
                        type="button"
                        hidden={split.purpose != investmentPurpose || this.props.data.applicants.length == 1 || split.hasCustomOwnership}
                        onClick={() => { this.customiseOwnership(index); }}
                      >
                            Customise ownership
                      </button>
                    </small>
                  </Col>
                </Row>
              </Col>


              <Col sm={2}>
                <Select
                  id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].structure`}
                  name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].structure`}
                  ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].structure`)}
                  label="Structure"
                  value="0"
                  options={functions.getStructures()}
                  className="form-control"
                />
              </Col>

              {
                  (() => {
                    if (this.state.splits.length > 1) {
                      return (
                        <div>
                          <button id={`removeSplit${index}`} type="button" className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle" style={{ marginTop: '31px' }} onClick={() => { this.removeSplit(split.splitId); }}>
                            <Icon glyph="delete" />
                          </button>

                          <Col sm={12} className="visible-xs text-right">
                            <button type="button" className="mb-md btn btn-danger" onClick={() => { this.removeSplit(split.splitId); }}>
                              Remove split
                            </button>
                          </Col>
                        </div>
                      );
                    }
                  })()
                }
              {
                (() => {
                  if (split.purpose === '1' && split.ownerships) {
                    return (
                      <Col sm={12} className={split.hasCustomOwnership ? '' : 'hidden'}>
                        <Row>
                          <h5 className="panel-row-subtitle"> Ownership </h5>
                          {
                          split.ownerships.map((owner, ownershipIndex) => (
                            <Col sm={2} key={`multiLoanDetails-${this.props.index}-splitLoanDetails-${index}-ownerships-${ownershipIndex}`}>
                              <Input
                                id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].applicantId`}
                                name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].applicantId`}
                                ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].applicantId`)}
                                value={owner.applicantId?.toString()}
                                type="hidden"
                              />
                              <div className="form-prefix">%</div>
                              <Input
                                id={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].owns`}
                                name={`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].owns`}
                                ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${ownershipIndex}].owns`)}
                                label={<span className="light">{ owner.applicantName }</span>}
                                value={owner.owns?.toString() || ''}
                                updateOnChange={false}
                                updateOnBlur={false}
                                blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                                changeCallback={(_, value) => { this.changeOwnership(index, ownershipIndex, value); }}
                                validationError="Ownership percentage is required. Total ownership must be 100"
                                validations={{
                                  isFormattedNumber: true,
                                  isRequired: true,
                                  customValidator: (values) => {
                                    let totalOwnership = 0;
                                    split.ownerships && split.ownerships.forEach((_, i) => {
                                      totalOwnership += parseInt(Mask.clean(values[`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}][ownerships][${i}].owns`]) || 0);
                                    });
                                    return !split.ownerships || totalOwnership == 100;
                                  },
                                }}
                              />
                            </Col>
                          ))
                        }
                        </Row>
                      </Col>
                    );
                  }
                })()
              }
            </Row>
          ))
        }

        {
          (() => {
            if (this.state.splits.length < 6) {
              return (
                <Row className="panel-row text-center">
                  <Col sm={12}>
                    <button type="button" id="addSplit" className="btn btn-default" onClick={() => { this.addSplit(); }}>
                      <Icon glyph="arrow-split" className="primary mute-lg mr-sm" />
                      {' '}
                      <span className="secondary">Add split</span>
                    </button>
                  </Col>
                </Row>
              );
            }
          })()
        }
      </div>
    );
  }
}

ServiceAbilityProductResidential.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  loans: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  removeLoan: PropTypes.func.isRequired,
};
