import * as React from 'react';
import PropTypes from 'prop-types';
import Guid from 'guid';
import Mask from '../helpers/mask';

export const toOption = (option, convert = true) => ({
  label: option,
  value: !convert ? option : option.replace(/[&,\/]/g, ' ').replace('  ', ' ').toLowerCase().split(' ')
    .map((word, index) => (index ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(''),
});

export const toOptionGroup = (option, group) => ({ ...toOption(option), group });

export const toRequired = (label) => (
  <span>
    <span className="danger">●</span>
    {' '}
    {label}
  </span>
);

export const pluralise = (value, singular, plural) => (value === 1
  ? singular
  : plural);

export const selectText = (refs, event) => {
  if (event.target.value == 0) {
    event.target.select();
  }
};

export const mapLoanDetails = (data) => {
  data.multiLoanDetails = [{
    purchaseAmount: data.loanDetails.purchaseAmount,
    riskGrade: data.loanDetails.riskGrade,
    term: data.loanDetails.term,
    isSwift: data.loanDetails.isSwift,
    splitLoanDetails: [{
      loanAmount: data.loanDetails.loanAmount,
      repayment: data.loanDetails.repayment,
      purpose: data.loanDetails.purpose,
      interestRate: data.loanDetails.interestRate,
      structure: 0,
    }],
  }];

  delete data.loanDetails;

  return data;
};

export const mapMultiLoanDetails = (data) => {
  var loanDetails = [];

  data.multiLoanDetails.forEach((loan) => {
    var splitDetails = [];
    if (loan.splitLoanDetails) {
      loan.splitLoanDetails.forEach((split) => {
        splitDetails.push({
          id: split.id || Guid.create().value,
          interestRate: split.interestRate,
          loanAmount: split.loanAmount,
          repayment: split.repayment,
          purpose: split.purpose,
          structure: split.structure,
        });
      });
    }

    loanDetails.push({
      purchaseAmount: loan.purchaseAmount,
      riskGrade: loan.riskGrade ?? 'AAA',
      term: loan.term,
      isSwift: loan.isSwift ?? false,
      splitLoanDetails: splitDetails,
    });
  });

  data.multiLoanDetails = loanDetails;

  return data;
};

export const mapApplicants = (data) => {
  const applicants = [];

  data.applicants.map((applicant) => {
    applicants.push({
      id: applicant.id || Guid.create().value,
      name: '',
      relationship: data.applicantRelation || 1,
      inRelationshipWithId: null,
      dependants: '0',
      postcode: '',
      expenses: Math.ceil(Mask.clean(data.expenses.statedExpenses) / data.applicants.length),
      incomes: applicant.incomes || [],
    });
  });

  if (data.applicantRelation == 1) {
    applicants[0].dependants = data.dependants.toString();
  }

  if (data.applicantRelation == 2) {
    applicants.map((applicant, index) => {
      applicants[index].inRelationshipWithId = applicant.id;
      applicants[index].dependants = data.dependants;
    });
  }

  data.applicants = applicants;

  delete data.applicantRelation;
  delete data.dependants;

  return data;
};

export const mapBusiness = (data) => {
  const companies = [];

  data.companies.map((company) => {
    companies.push({
      id: company.id || Guid.create().value,
      name: company.name,
      profit: company.profit,
      amortisation: company.amortisation,
      interest: company.interest,
      depreciation: company.depreciation,
      otheraddback: company.otheraddback,
      otherdeduction: company.otherdeduction,
    });
  });

  data.companies = companies;

  return data;
};

export const mapSelfEmployed = (data) => {
  data.applicants.map((applicant, index) => {
    const incomes = [];
    applicant.incomes.map((income) => {
      if (income.type != 'selfEmployed') {
        incomes.push(income);
      } else {
        incomes.push({
          type: 'selfEmployed',
          currentAmount: income.amount,
        });
      }
    });
    data.applicants[index].incomes = incomes;
  });

  return data;
};

export const mapCompanyIncome = (data) => {
  data.applicants.map((applicant, index) => {
    const incomes = [];
    applicant.incomes.map((income) => {
      if (income.type != 'company') {
        incomes.push(income);
      } else {
        incomes.push({
          type: 'company',
          currentAmount: income.amount,
        });
      }
    });
    data.applicants[index].incomes = incomes;
  });

  return data;
};

export const getLoanTerms = (min, max) => {
  min = min || 10;
  max = max || 30;

  const terms = [];
  for (let value = max; value >= min; value--) {
    terms.push({
      value,
      label: `${value} years`,
    });
  }

  return terms;
};

export const getLoanPurposes = () => [{
  value: 0,
  label: 'Owner Occupied',
}, {
  value: 1,
  label: 'Investment',
}, {
  value: 2,
  label: 'Cashout Owner Occupied Security',
}, {
  value: 3,
  label: 'Cashout Investment Security',
}];

export const getRiskGrades = () => [{
  value: 'AAA',
  label: 'Prime',
}, {
  value: 'Other',
  label: 'Custom',
}];

export const getResiProducts = (verification = 0) => {
  const products = [];

  if (verification == 0) {
    products.push({
      value: 5000,
      label: 'Star',
    });
    products.push({
      value: 5500,
      label: 'Sharp',
    });
    products.push({
      value: 5600,
      label: 'Free',
    });
  } else if (verification == 1) {
    products.push({
      value: 5000,
      label: 'Nova',
    });
    products.push({
      value: 5600,
      label: 'Free',
    });
  } else {
    products.push({
      value: 400,
      label: 'Private',
    });
  }


  return products;
};

export const getStructures = () => [{
  value: 0,
  label: 'Variable',
}, {
  value: 1,
  label: 'Fixed for 1 year',
}, {
  value: 2,
  label: 'Fixed for 2 years',
}, {
  value: 3,
  label: 'Fixed for 3 years',
}];

export const getRepayments = () => [{
  value: 0,
  label: 'Principal and interest',
},
{
  value: 1,
  label: 'Interest only 1 year',
},
{
  value: 2,
  label: 'Interest only 2 years',
},
{
  value: 3,
  label: 'Interest only 3 years',
},
{
  value: 4,
  label: 'Interest only 4 years',
},
{
  value: 5,
  label: 'Interest only 5 years',
}];

export const getRelationships = () => [{
  value: 1,
  label: 'Single',
},
{
  value: 2,
  label: 'Married/De-Facto',
}];

export const getDependants = () => {
  const dependants = [];
  [0, 1, 2, 3, 4, 5, 6, 7, 8].map((number) => {
    dependants.push({
      value: number,
      label: number,
    });
  });
  return dependants;
};

export const getFPA_ISPValues = () => ({
  FPA: 660.00,
  ISP: 850.00,
  LenderFee: 395.00,
});

export const get_States = () => [
  'VIC',
  'NSW',
  'ACT',
  'QLD',
  'SA',
  'WA',
  'NT',
  'TAS',
];

export const applicantsDidChange = (previous) => (current) => {
  let changed = false;
  if (previous && previous.length !== current.length) changed = true;

  current && current.forEach((curr) => {
    if (previous && !previous.find((prev) => prev.id === curr.id && prev.name === curr.name)) changed = true;
  });
  return changed;
};

export const getDefaultOwnershipsForApplicants = (applicants) => {
  const ownerships = applicants.map((applicant, index) => ({ applicantId: applicant.id, applicantName: (applicant.name || `Applicant ${index + 1}`), owns: Math.floor(100 / applicants.length) }));

  if (applicants && applicants.length > 0) {
    const remainder = 100 % applicants.length;
    ownerships[0].owns += remainder;
  }
  return ownerships;
};

export const copyCustomOwnerships = (source, target) => {
  target.forEach((newOwnership) => {
    const previous = source && source.find((ownership) => ownership.applicantId == newOwnership.applicantId);
    if (previous) {
      newOwnership.owns = previous.owns;
    } else {
      newOwnership.owns = 0;
    }
  });
  return target;
};

export const hasCustomOwnership = (ownerships) => {
  if (!ownerships) return false;

  const _ownerships = [...ownerships];

  const equalOwnershipPortion = Math.floor(100 / _ownerships.length);
  const remainder = 100 % _ownerships.length;
  if (_ownerships[0].owns != (equalOwnershipPortion + remainder)) return true;

  _ownerships.splice(0, 1);
  const customOwnership = _ownerships.find((ownership) => ownership.owns != equalOwnershipPortion);
  if (customOwnership) return true;
  return false;
};
