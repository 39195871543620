import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import * as ReactTooltip from 'react-tooltip';
import * as Guid from 'guid';
import { Link } from 'react-router';
import Event3rdParty from '../../helpers/event3rdParty';

import Mask from '../../helpers/mask';

import { Icon } from '../../utils';

import * as functions from '../../constants/functions';

const investmentPurpose = '1';

export default class ComServiceAbilityProduct extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      lvr: 0,
      splits: [{
        ownerships: [],
        splitId: Guid.create().value,
      }],
    };

    this.handleLvr = this.handleLvr.bind(this);
    this.addSplit = this.addSplit.bind(this);
  }

  inputRefs = {};

  setRef = (id) => (ref) => {
    if (ref) this.inputRefs[id] = ref;
    else delete this.inputRefs[id];
  };

  recreateOwnershipsForInvestmentSplits() {
    const { splits } = this.state;
    splits.forEach((split, index) => {
      if (split.purpose === investmentPurpose) {
        let newOwnerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
        const hasCustomOwnership = functions.hasCustomOwnership(split.ownerships);
        if (hasCustomOwnership) { newOwnerships = functions.copyCustomOwnerships(split.ownerships, newOwnerships); }
        splits[index].ownerships = newOwnerships;
        splits[index].hasCustomOwnership = hasCustomOwnership;
      }
    });
    this.setState({
      splits,
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.multiLoanDetails && this.props.data.multiLoanDetails[this.props.index].splitLoanDetails) {
      const splits = [];
      this.props.data.multiLoanDetails[this.props.index].splitLoanDetails.forEach((split) => {
        splits.push({
          ...split,
          splitId: split.id,
        });
      });

      this.setState({
        splits,
      }, () => this.recreateOwnershipsForInvestmentSplits());
    }
  }

  componentDidMount() {
    this.handleLvr();
  }

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild();
  }

  handleLvr() {
    let lvr = 0;

    const purchaseAmount = parseInt(Mask.clean(this.inputRefs[`multiLoanDetails[${this.props.index}].purchaseAmount`].getValue()));
    let loanAmount = 0;

    this.state.splits.forEach((split, index) => {
      loanAmount += parseInt(Mask.clean(this.inputRefs[`multiLoanDetails[${this.props.index}][splitLoanDetails][${index}].loanAmount`].getValue()));
    });

    if (purchaseAmount && loanAmount) {
      lvr = ((loanAmount / purchaseAmount) * 100).toFixed(1);
      if (lvr > 100 || isNaN(lvr)) {
        lvr = 0;
      }
    }

    this.setState({
      lvr,
    });
  }

  addSplit() {
    this.setState({
      splits: [...this.state.splits, { splitId: Guid.create().value }],
    });

    // 3rd party
    Event3rdParty.send('split-serviceability');
  }

  removeSplit(splitId) {
    this.setState({
      splits: this.state.splits.filter((split) => split.splitId !== splitId),
    }, this.handleLvr);
  }

  changePurpose(index, value) {
    const { splits } = this.state;
    splits[index].purpose = value;
    splits[index].hasCustomOwnership = false;
    if (value === investmentPurpose) {
      splits[index].ownerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
    } else {
      splits[index].ownerships = undefined;
    }
    this.setState({
      splits,
    });
  }

  changeOwnership(splitIndex, ownershipIndex, value) {
    const splits = [...this.state.splits];
    splits[splitIndex].ownerships[ownershipIndex].owns = value;
    this.setState({
      splits,
    });
  }

  customiseOwnership(index) {
    const { splits } = this.state;
    splits[index].hasCustomOwnership = true;
    this.setState({
      splits,
    });
  }


  /**
   * Render
   */
  render() {
    return (
      <div className="card card-shallow card-stack expenses">
        <Row className={this.state.splits.length > 1 ? 'r panel-row' : 'r mb-md-n'}>

          <Input
            id={`multiLoanDetails[${this.props.index}].id`}
            name={`multiLoanDetails[${this.props.index}].id`}
            value={this.props.loans[this.props.index].loanId?.toString()}
            type="hidden"
          />

          <Col>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={3}>

                    <div className="form-prefix">%</div>
                    <div className="form-group">
                      <label className="control-label">LVR</label>
                      <input className="form-control" disabled value={this.state.lvr} />
                    </div>

                  </Col>
                  <Col sm={5}>
                    <div className="form-prefix">$</div>
                    <Input
                      id={`multiLoanDetails[${this.props.index}].purchaseAmount`}
                      name={`multiLoanDetails[${this.props.index}].purchaseAmount`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}].purchaseAmount`)}
                      type="text"
                      label={(
                        <span>
                          {'Security amount '}
                          <small className={`mute ${this.state.splits.length == 1 ? 'hidden' : ''}`}>(total)</small>
                        </span>
                      )}
                      value={this.props.data && this.props.data.multiLoanDetails ? Mask.number(this.props.data.multiLoanDetails[this.props.index].purchaseAmount)?.toString() : '0'}
                      updateOnBlur={false}
                      blurCallback={(name, value) => {
                        this.handleLvr();
                        Mask.numberFormsy(name, value, this.inputRefs);
                      }}
                      changeDebounceInterval={0}
                      changeCallback={this.handleLvr}
                      validationError="Security value is required"
                      validations={{
                        isFormattedNumber: true,
                        isRequired: true,
                      }}
                    />
                  </Col>

                  <Col sm={4}>
                    <div className="form-prefix">$</div>
                    <Input
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].loanAmount`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].loanAmount`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][0].loanAmount`)}
                      type="text"
                      label={`${this.state.splits.length == 1 ? 'Loan' : 'Split'} amount`}
                      value='0'
                      updateOnBlur={false}
                      blurCallback={(name, value) => {
                        this.handleLvr();
                        Mask.numberFormsy(name, value, this.inputRefs);
                      }}
                      changeDebounceInterval={0}
                      changeCallback={this.handleLvr}
                      validationError="Loan amount is required and must be smaller than the security value"
                      validations={{
                        customValidator: (values, value) => {
                          let loanAmount = 0;
                          this.state.splits.forEach((_, i) => {
                            loanAmount += parseInt(Mask.clean(values[`multiLoanDetails[${this.props.index}][splitLoanDetails][${i}].loanAmount`]) || 0);
                          });
                          return value && value != '0' && loanAmount <= parseInt(Mask.clean(values[`multiLoanDetails[${this.props.index}].purchaseAmount`]));
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <Select
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].repayment`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].repayment`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][0].repayment`)}
                      label="Repayments"
                      value='0'
                      options={functions.getRepayments()}
                      className="form-control"
                    />
                  </Col>
                  <Col sm={3}>
                    <Select
                      id={`multiLoanDetails[${this.props.index}].term`}
                      name={`multiLoanDetails[${this.props.index}].term`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}].term`)}
                      label="Loan Term"
                      value="30"
                      options={functions.getLoanTerms(1, 30)}
                      className="form-control"
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="form-prefix">%</div>
                    <Input
                      id={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].interestRate`}
                      name={`multiLoanDetails[${this.props.index}][splitLoanDetails][0].interestRate`}
                      ref={this.setRef(`multiLoanDetails[${this.props.index}][splitLoanDetails][0].interestRate`)}
                      type="text"
                      label={<span>Rate</span>}
                      value='0'
                      validationError="Interest rate is required"
                      validations={{
                        isFloat: true,
                        isExisty: true,
                        isRequired: true,
                        isNotZero: true,
                        customValidator: (values, value) => value <= 30,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

ComServiceAbilityProduct.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  loans: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  removeLoan: PropTypes.func.isRequired,
};
