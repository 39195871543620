import API from '../api';
import * as types from '../constants/types';

const Moment = require('moment');

export const getStorage = (key) => (dispatch) => API.getStorage(key)
  .then((response) => dispatch({
    type: types.GET_STORAGE,
    data: response.data,
    key,
  }));

export const setStorage = (key, data = Moment().format()) => (dispatch) => API.setStorage(key, data)
  .then(() => dispatch({
    type: types.SET_STORAGE,
    data,
    key,
  }));
