import * as React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';

const PaneLogo = (props) => (
  <Link to="/applications" className="pane-link" onClick={props.toggleAll}>
    <img alt="" src={`/${window.iqUIVersion}/assets/img/logo-liberty-financial-reverse.png`} className="pane-logo" />
    <span className="pane-logo-addon">IQ</span>
  </Link>
);

PaneLogo.propTypes = {
  toggleAll: PropTypes.func,
};

export default PaneLogo;
