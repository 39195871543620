import * as React from 'react'
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Money from '../../utils/money';
import { Icon, Percentage } from '../../utils';
import MotorPrequalSlider from './motorPrequalSlider';

interface SuccessResultProps {
    riskGrade: string;
    interestRate: number;
    calculatedInterestRate: number;
    repayment: number;
    loanTermMonths: number;
    loanAmount: number;
    minCommission: number;
    maxCommission: number;
    commission: number;
    referMessage:string;
    equalisationFee: number;
    showDiscountSlider: boolean;
}

export const MotorPrequalToolSuccessResult = (props: SuccessResultProps) => (
    <Row>
        <Col sm={8} smOffset={2}>
            <div className="card text-left mb-lg mt-md">
                <div className="text-center mt-lg">
                    <Icon glyph="assignment-o" className="mute-lg" size={6} />
                    <Icon glyph='check' className='text-success a mt-lg' size={3} style={{ left: 0, right: 0 }} />
                </div>
                <div className="ml-md-n mr-md-n">
                    <table className="table table-lg table-hover mt-md mb-h" style={{ width: '80%', margin: "0 auto" }}>
                        <tbody>
                            <tr>
                                <td>Risk Grade</td>
                                <td className="text-right"><strong>{props.riskGrade}</strong></td>
                            </tr>
                            <tr>
                                <td>Interest Rate</td>
                                <td className="text-right"><strong><Percentage>{props.calculatedInterestRate / 100}</Percentage></strong></td>
                            </tr>
                            <tr>
                                <td>Repayment <small className="mute">(monthly)</small></td>
                                <td className="text-right"><strong><Money>{props.repayment}</Money></strong></td>
                            </tr>
                            <tr>
                                <td>Commission</td>
                                <td className="text-right"><strong><Money>{props.commission}</Money></strong></td>
                            </tr>
                            <tr>
                                <td>Loan Amount</td>
                                <td className="text-right">
                                    <strong><Money>{props.loanAmount}</Money></strong>
                                    <div>
                                        <small className="mute">(Including equalisation fee of ${props.equalisationFee})</small>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    { props.showDiscountSlider && <MotorPrequalSlider /> }
                </div>
            </div>
        </Col>
    </Row>
)

function mapStateToProps({ motorPrequal }) {
    return {
        ...motorPrequal
    };
}

export default connect(mapStateToProps, {})(MotorPrequalToolSuccessResult);
