import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../utils';
import {ISubmittedFailedValidation} from '../reducer';

interface IValidationProps {
    resetModal: () => void;
    submission: ISubmittedFailedValidation;
}

export const Validation = (props: IValidationProps) => {
    return (
        <div className="text-center mt-lg mb-lg">
            <Icon glyph="info-outline" className="text-warning" size={4} />

            <h4 className="pt-lg mt-lg">Whoops, not quite right</h4>
            <p className="text-lg mt-sm mb-lg mute">Looks like some fields weren't filled out properly...</p>

            {
                props.submission.errors.map(error => {
                    return (
                        <p className="mute text-danger" key={error.key}>{error.message}</p>
                    );
                })
            }

            <button className="btn btn-simple btn-default mt-lg" onClick={props.resetModal}>
                <span className="secondary">Close</span>
            </button>
        </div>
    );
}

export default Validation;
