import * as React from 'react';
import PropTypes from 'prop-types';

import {Icon} from '../../utils';

interface IProgressProps {
  steps: IStep[],
  currentStep: number,
  changeStep: (id: number) => void
}

interface IStep {
  id: number,
  name: string,
  visible: boolean
}

const _renderStep = (step: IStep, props: IProgressProps): JSX.Element => {
  let classNames:string[] = ['step'];

  if (props.currentStep == step.id) {
    classNames.push('active');
  }

  if (props.currentStep < step.id) {
    classNames.push('disabled');
  }

  return (
    <div key={step.name.toLowerCase()} className={classNames.join(' ')} onClick={() => props.changeStep(step.id)}>
      <span className="step-circle">
        {props.currentStep > step.id ? <Icon glyph="check" /> : step.id + 1}
      </span>
      <span className="step-label">{step.name}</span>
    </div>
  );
}

const Progress = (props: IProgressProps): JSX.Element => {
  return (
    <div className="bar-wrapper bar-static bar-default bar-shallow">
      <div className="bar bar-tint bar-static">
        <div className="container pt-h text-center">
          <div className="bar-steps">
            {
              props.steps.filter(step => step.visible).map(step => _renderStep(step, props))
            }
          </div>
        </div>
      </div>
    </div>
  );

}

export default Progress;
