import * as React from 'react';
import PropTypes from 'prop-types';

import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import AddressInput from '../addressInput';
import DateInput from '../dateInput';
import Title from '../title';

import Mask from '../../../helpers/mask';
import {toOption, toRequired, firstOption} from '../functions';

import {IOption} from '../../../interfaces';

import {titleOptions, dependantOptions, relationshipOptions, genderOptions} from '../options';

interface IPersonalState {
}

interface IPersonalProps {
  index: number,
  changeIndividualName: (index: number, name: string) => void
}

export class Personal extends React.Component<IPersonalProps, IPersonalState> {
  UNSAFE_componentWillMount() {
    this.changeName = this.changeName.bind(this);
  }

  changeName(): void {
    let firstName = (this.refs[`individuals[${this.props.index}].firstName`] as any);
    let middleName = (this.refs[`individuals[${this.props.index}].middleName`] as any);
    let lastName = (this.refs[`individuals[${this.props.index}].lastName`] as any);

    let name: string = `${Mask.ucfirst(firstName.getValue()) || ""} ${Mask.ucfirst(middleName.getValue()) || ""} ${Mask.ucfirst(lastName.getValue()) || ""}`;
    this.props.changeIndividualName(this.props.index, name || `Applicant ${this.props.index}`);
  }

  render() {
    return (
      <div className="panel-section">
        <Title>Personal information</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row>
            <Col sm={2}>
              <Select
                name={`individuals[${this.props.index}].title`}
                id={`individuals[${this.props.index}].title`}
                label={toRequired("Title")}
                options={titleOptions}
                value={firstOption(titleOptions)}
                className="form-control" />
            </Col>

            <Col sm={3}>
              <Input
                id={`individuals[${this.props.index}].firstName`}
                name={`individuals[${this.props.index}].firstName`}
                ref={`individuals[${this.props.index}].firstName`}
                label={toRequired("First name")}
                validations={{
                  isRequired: true
                }}
                blurCallback={this.changeName}
                value=""
                validationError="We'll need a first name" />
            </Col>

            <Col sm={3}>
              <Input
                name={`individuals[${this.props.index}].middleName`}
                ref={`individuals[${this.props.index}].middleName`}
                id={`individuals[${this.props.index}].middleName`}
                label="Middle name"
                value=""
                blurCallback={this.changeName} />
            </Col>

            <Col sm={4}>
              <Input
                name={`individuals[${this.props.index}].lastName`}
                ref={`individuals[${this.props.index}].lastName`}
                id={`individuals[${this.props.index}].lastName`}
                label={toRequired("Last name")}
                value=""
                validations={{
                  isRequired: true
                }}
                blurCallback={this.changeName}
                validationError="We'll need a last name" />
            </Col>
          </Row>

          <Row>
            <Col sm={2}>
              <DateInput
                name={`individuals[${this.props.index}].dateOfBirth`}
                label={toRequired("Date of birth")}
                precision="day"
                validations={{
                  isValidDate: true,
                  isOver18: (_, value: string) => {
                    return Moment().diff(Moment(value, "DD/MM/YYYY"), "years") >= 18;
                  },
                  isRequired: true
                }}
                validationErrors={{
                  isRequired: "Date of birth is required",
                  isValidDate: "Must be a valid date",
                  isOver18: "Must be over 18"
                }}
              />
            </Col>

            <Col sm={2}>
              <Select
                name={`individuals[${this.props.index}].gender`}
                id={`individuals[${this.props.index}].gender`}
                label={toRequired("Gender")}
                options={genderOptions}
                value={firstOption(genderOptions)}
                className="form-control" />
            </Col>

            <Col sm={2}>
              <Select
                name={`individuals[${this.props.index}].relationship`}
                id={`individuals[${this.props.index}].relationship`}
                label={toRequired("Relationship")}
                options={relationshipOptions}
                value={firstOption(relationshipOptions)}
                className="form-control" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Personal;
