import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { notify as Notify } from 'react-notify-toast';
import Axios from 'axios';
import { Document, documentsActions } from '../../actions/documentsActions';
import { DocumentsState } from '../../reducers/documentsReducer';
import Event3rdParty from '../../helpers/event3rdParty';
import SearchPane from '../../components/searchPane';
import EmptyPane from '../../components/emptyPane';
import LoadingPane from '../../components/loadingPane';
import DocumentGroup from './documentGroup';
import DocumentTab, { AssetClassesFilter } from './documentTab';
import API from '../../api';

type DocumentsPageState = {
  query: string,
  filter: AssetClassesFilter,
}

type DocumentPageProps = {
  actions: {
    getReferenceDocuments: () => Promise<void>,
    startReferenceDocument:(file: Document, group: string) => Promise<void>,
    stopReferenceDocument: (file: Document, group: string) => Promise<void>,
  },
  documents: DocumentsState,
  session: { token: string }
}

export class DocumentsPage extends React.Component<DocumentPageProps, DocumentsPageState> {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      filter: AssetClassesFilter.Residential,
    };

    this.countDocuments = this.countDocuments.bind(this);
    this.getReferenceDocuments = this.getReferenceDocuments.bind(this);
    this.getReferenceDocument = this.getReferenceDocument.bind(this);
    this.countDocuments = this.countDocuments.bind(this);
    this.countAnyDocuments = this.countAnyDocuments.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.filterPredicate = this.filterPredicate.bind(this);
    this.reduceDocuments = this.reduceDocuments.bind(this);
    this.getSelectedAssetClass = this.getSelectedAssetClass.bind(this);
  }

  componentDidMount() {
    this.getReferenceDocuments();
  }

  onSearch(event) {
    this.setState({
      query: event.target.value,
    });
  }

  getReferenceDocuments() {
    let selectedAssetClass = this.getSelectedAssetClass();
    this.props.actions.getReferenceDocuments()
      .then(() => {
        this.setState({
          filter: this.countDocuments(selectedAssetClass) ? selectedAssetClass : AssetClassesFilter.Motor,
        });
      });
  }

  getSelectedAssetClass() {
    let routeParam = this.props["route"].mainComponentProps.filter
    switch (routeParam) {
      case 'mortgage':
        return AssetClassesFilter.Residential;
      case 'motor':
        return AssetClassesFilter.Motor;
      case 'commercial':
        return AssetClassesFilter.Commercial;
      case 'insurance':
        return AssetClassesFilter.Insurance;
      case 'business':
        return AssetClassesFilter.Business;
      default:
        return AssetClassesFilter.Residential;
    }

  }

  getReferenceDocument(file: Document, group: string) {
    this.props.actions.startReferenceDocument(file, group);
    setTimeout(() => {
      this.props.actions.stopReferenceDocument(file, group);
    }, 1000);

    Notify.show('Your download will start shortly...', 'success', 3000);

    const url = `${API.base}documents/reference/${file.fileName}`;

    Axios.get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfBlobUrl = URL.createObjectURL(pdfBlob);

        const downloadLink = document.createElement('a');
        downloadLink.href = pdfBlobUrl;
        downloadLink.download = file.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(pdfBlobUrl);
      })
      .catch((error) => console.error('Error:', error));

    // 3rd party
    Event3rdParty.send('download-reference-document');
  }

  showDocument = (opts: { filter: AssetClassesFilter, query: string}) => (document: Document) : boolean => {
    const assetClasses = document.assetClasses.map((a) => a.toLowerCase());

    if (!assetClasses.includes(opts.filter)) {
      return false;
    }

    if (opts.query) {
      const searchRegExp = new RegExp(opts.query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
      return document.name.search(searchRegExp) != -1 || document.description.search(searchRegExp) != -1;
    }

    return true;
  }

  filterPredicate = (document: Document) => this.showDocument({ filter: this.state.filter, query: this.state.query })(document)

  countDocuments(filter: AssetClassesFilter) {
    return this.props.documents?.all.filter((document) => this.showDocument({ filter, query: '' })(document)).length;
  }

  countAnyDocuments() {
    const residential = this.countDocuments(AssetClassesFilter.Residential) ? 1 : 0;
    const motor = this.countDocuments(AssetClassesFilter.Motor) ? 1 : 0;
    const commercial = this.countDocuments(AssetClassesFilter.Commercial) ? 1 : 0;
    const insurance = this.countDocuments(AssetClassesFilter.Insurance) ? 1 : 0;
    const business = this.countDocuments(AssetClassesFilter.Business) ? 1 : 0;

    return (residential + motor + commercial + insurance + business > 1);
  }

  reduceDocuments(filter) {
    this.setState({
      filter,
    });
  }

  render() {
    if (this.props.documents === null) {
      return (
        <LoadingPane label="documents" />
      );
    }

    return (
      <div>
        <div className="mt-md mb-md">
          {
            (() => {
              if (this.countAnyDocuments()) {
                return (
                  <Row>
                    <Col sm={8} smOffset={2}>
                      <div className="tab-container mt-sm mb-lg">
                        <DocumentTab
                          icon="home"
                          title="Residential"
                          filter={this.state.filter}
                          query={AssetClassesFilter.Residential}
                          filterFunction={this.reduceDocuments}
                          visible={this.countDocuments}
                        />
                        <DocumentTab
                          icon="directions-car"
                          title="Motor"
                          filter={this.state.filter}
                          query={AssetClassesFilter.Motor}
                          filterFunction={this.reduceDocuments}
                          visible={this.countDocuments}
                        />
                        <DocumentTab
                          icon="city-alt"
                          title="Commercial"
                          filter={this.state.filter}
                          query={AssetClassesFilter.Commercial}
                          filterFunction={this.reduceDocuments}
                          visible={this.countDocuments}
                        />
                        <DocumentTab
                          icon="store"
                          title="Business"
                          filter={this.state.filter}
                          query={AssetClassesFilter.Business}
                          filterFunction={this.reduceDocuments}
                          visible={this.countDocuments}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              }
            })()
          }

          <Row>
            <Col sm={8} smOffset={2} className="mb-sm">
              <SearchPane query={this.state.query} placeholder="Search documents" onSearch={this.onSearch} />
            </Col>
          </Row>
        </div>

        {
          (() => {
            if (this.state.query) {
              return (
                <div>
                  {
                    (() => {
                      if (!this.props.documents.all.filter(this.filterPredicate).length) {
                        return (
                          <div className="panel panel-default">
                            <div className="panel-body">
                              <EmptyPane query={this.state.query} item="documents" />
                            </div>
                          </div>
                        );
                      }
                      return (
                        <DocumentGroup filterPredicate={this.filterPredicate} documents={this.props.documents.all} title="" group="" getReferenceDocument={this.getReferenceDocument} />
                      );
                    })()
                  }
                </div>
              );
            }
            return (
              <div>
                <DocumentGroup
                  group="guides"
                  title="Guides &amp; reference material"
                  filterPredicate={this.filterPredicate}
                  documents={this.props.documents.guides}
                  getReferenceDocument={this.getReferenceDocument}
                />
                <DocumentGroup
                  group="insuranceDocuments"
                  title="Insurance Documents"
                  filterPredicate={this.filterPredicate}
                  documents={this.props.documents.insuranceDocuments}
                  getReferenceDocument={this.getReferenceDocument}
                />
                <DocumentGroup
                  group="forms"
                  title="Forms"
                  filterPredicate={this.filterPredicate}
                  documents={this.props.documents.forms}
                  getReferenceDocument={this.getReferenceDocument}
                />
                <DocumentGroup
                  group="targetMarketDeterminationDocuments"
                  title="Target Market Determination"
                  filterPredicate={this.filterPredicate}
                  documents={this.props.documents.targetMarketDeterminationDocuments}
                  getReferenceDocument={this.getReferenceDocument}
                />
              </div>
            );
          })()
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(documentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
