
import initialState from './initialState';

import * as types from '../constants/types';

import Storage from '../helpers/storage';
const objectAssign = require('object-assign');


export default function introducersReducer(state = initialState.originators, action) {
  switch (action.type) {
    case types.GET_INTRODUCERS: {
      const data = action.data.data || [];

      return objectAssign({}, state, { originators: data.originators }, { IsSuperUser: data.isSuperUser }, { MaxItems: data.totalOriginatorsCount });
    }

    default:
      return state;
  }
}
