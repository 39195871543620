import * as React from 'react';
import PropTypes from 'prop-types';
import EmploymentRow from './employmentRow';
import { Row, Col } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addEmployment, loadEmployment } from './actions';

import { EmploymentRowState } from './reducer'

import Icon from '../../../utils/icon';

interface EmploymentHistoryProps {
  employment: any,
  applicantIndex: number
  employmentRows: EmploymentRowState[],
  showRemoveButton: boolean,
  actions: {
    loadEmployment: typeof loadEmployment,
    addEmployment: typeof addEmployment
  }
  message: string,
}

const defaultProps = {
  message: "We'll need at least three years of employment history",
}

export const EmploymentHistory = (props: EmploymentHistoryProps) => {
  props = { ...defaultProps, ...props }
  React.useEffect(() => {
    props.actions.loadEmployment(props.employment)
  }, [props.employment]);

  return (
    <div className="applicants">
      <div className="panel-header-fixed">
        <span className="visible-xs mt-sm" />
        <i className="mi mi-case mi-2x ib mr-md text-center np" style={{ width: '25px' }} />
        <div className="ib va-tt mt-sm-n">
          <h4 className="mb-h mt-h" style={{ whiteSpace: 'nowrap' }}>Employment History</h4>
          <p className="mute">{props.message}</p>
        </div>
      </div>
      <div className="card card-shallow" style={{ overflow: 'visible' }}>

        {props.employmentRows.map(
          (employment, index) =>
            <EmploymentRow
              id={employment.id}
              key={employment.id.toString()}
              showEndDate={employment.showEndDate}
              years={employment.years}
              months={employment.months}
              type={employment.type}
              endDate={employment.endDate}
              isCurrent={employment.isCurrent}
              endDateCanBeInFuture={employment.endDateCanBeInFuture}
              index={index}
              applicantIndex={props.applicantIndex}
              showRemoveButton={props.showRemoveButton}
            />
        )
        }

        <Row className="panel-row">
          <Col sm={12} className="text-center">
            <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
            <button
              type="button"
              id={`applicants[${props.applicantIndex}]-addEmployment`}
              name={`applicants[${props.applicantIndex}]-addEmployment`}
              className="btn btn-link pl-h pr-h np"
              onClick={props.actions.addEmployment}>
              Add Employment
            </button>
          </Col>
        </Row>
      </div>
    </div>)
}

function mapStateToProps({ employmentHistory }) {
  return {
    ...employmentHistory
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addEmployment, loadEmployment }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentHistory);
