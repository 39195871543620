import * as React from 'react';
import PropTypes from 'prop-types';

import Icon from './icon';
import Loadr from './loadr';

const Overlay = (props) => (
    <div className="overlay">
      {
        (() => {
          if (props.icon) {
            return (
              <Icon glyph={props.icon} color="secondary" className="overlay-icon" />
            );
          }
        })()
      }
      <Loadr className="overlay-loadr" lg />
      <h3 className="overlay-text">{props.children}</h3>
    </div>
);

Overlay.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
};

export default Overlay;
