import { EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const nonProd = process.env.NODE_ENV !== 'production';
// const isMain = process.env.CI_PIPELINE_IS_MAIN;

const authorityDomain = nonProd
  ? 'libfinintroducerssbx.b2clogin.com'
  : 'authentication.iq.liberty.com.au';

const authority = nonProd
  ? 'https://libfinintroducerssbx.b2clogin.com/libfinintroducerssbx.onmicrosoft.com/B2C_1A_IQ_SIGNIN'
  : 'https://authentication.iq.liberty.com.au/7a302886-44dd-45cf-9440-3cd0753fd920/B2C_1A_IQ_SIGNIN';

// To test this on prod with a specific build, edit the redirect URI to include the build number - i.e: 'https://iq.liberty.com.au/471/'
// You'll also need to include the same URI into the azure AD B2C authentication for 'IQ Website'.
const redirectUris = {
  production: 'https://iq.liberty.com.au/',
  sandbox: 'https://iq.sandbox-liberty.com.au/', 
  development: 'http://localhost:3000/#/login/',
};
const redirectUri = redirectUris[process.env.NODE_ENV] || redirectUris.production; // Default to production if environment is unknown

const clientId = nonProd
  ? 'd96f51fe-c1e6-4d61-9a90-e4921eff8845' // IQ Website App ID
  : 'af2924c2-eacc-4cc8-a7ed-2473b91aff81';

export const requestScopes = {
  scopes: nonProd
    ? ['https://libfinintroducerssbx.onmicrosoft.com/liberty-api-gateway/u65hkh/user_impersonation']
    : ['https://libfinintroducers.onmicrosoft.com/liberty-api-gateway/59sqph/user_impersonation'],
};

export const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_IQ_SIGNIN',
  },
  authorities: {
    signIn: {
      authority,
    },
  },
  authorityDomain,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: redirectUri, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiTodoList: {
    endpoint: 'http://localhost:5000/api/todolist',
    scopes: {
      read: [
        'https://libfinintroducerssbx.onmicrosoft.com/tasks-api/tasks.read',
      ],
      write: [
        'https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.ReadWrite',
      ],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    ...protectedResources.apiTodoList.scopes.read,
  ],
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS
      || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      || event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

// Register Callbacks for Redirect flow
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (response && response.account) {
      msalInstance.setActiveAccount(response.account);
    }
  });
