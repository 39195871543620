let fakeStorage = {};
fakeStorage.getItem = (item) => window.data[item];
fakeStorage.setItem = (item, data) => { window.data[item] = data; };
fakeStorage.removeItem = (item) => { window.data[item] = undefined; };
fakeStorage.clear = () => { window.data = {}; };

if (window.localStorage) {
  fakeStorage = window.localStorage;
}

const Storage = fakeStorage;

export default Storage;
