import * as React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form} from 'formsy-react-components';

import {IFormData} from '../interfaces';

interface IConfirmProps {
    onValidSubmit: (data: IFormData) => void;
    hideModal: () => void;
    data: IFormData;
    notes: string;
    changeNote: (note: string) => void;
}

export const Confirm = (props: IConfirmProps) => {
    return (
        <Form
            onValidSubmit={props.onValidSubmit}
            layout="vertical"
            disabled={false}
            elementWrapperClassName=""
            labelClassName=""
            rowClassName=""
            validateBeforeSubmit
            validatePristine>

            <div className="pl-sm pr-sm mt-sm">
                <p className="text-lg mt-md mb-sm">Making changes</p>
                <p className="mute mb-md ib">You’re about to submit the application. When you do, you won't be able to make any more changes.</p>

                <p className="text-lg mt-md mb-sm">Notes</p>
            </div>

            <div className="ml-lg-n mr-lg-n mt-md">
                <div className="form-group">
                    <textarea
                        name="notes"
                        autoFocus
                        className="form-control form-control-notes"
                        defaultValue={props.notes}
                        onBlur={(event) => props.changeNote((event.target as any).value)}
                        placeholder="Type your notes here..." />
                </div>
            </div>

            <div className="pl-sm pr-sm mt-sm">
                <div className="text-right mt-lg">
                    <button onClick={props.hideModal} type="button" className="btn btn-lg btn-default btn-simple mr-sm">Cancel</button>
                    <button type="submit" className="btn btn-lg btn-success">Submit application</button>
                </div>
            </div>
        </Form>
    );
}

export default Confirm;
