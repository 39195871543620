import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

export default class FaqItem extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      expanded: false,
    };

    this.toggleFaq = this.toggleFaq.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      expanded: false,
    });
  }

  toggleFaq() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  renderFaq(html) {
    return { __html: html };
  }

  render() {
    return (

      <tr onClick={this.toggleFaq} className="faq-item" id={this.props.id}>
        <td width="10">
          <Icon style={{ marginTop: '12px' }} className={`animate mute-lg ml-sm ${!this.state.expanded ? 'rotate-0' : 'rotate-180'}`} glyph={`${!this.state.expanded ? 'plus' : 'minus'}-circle`} size="lg" />
        </td>
        <td className="pt-md">
          <p>{this.props.faq.question}</p>

          {
            (() => {
              if (this.state.expanded) {
                return (
                  <p className="mute" dangerouslySetInnerHTML={this.renderFaq(this.props.faq.answer)} />
                );
              }
            })()
          }
        </td>
      </tr>
    );
  }
}

FaqItem.propTypes = {
  faq: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};
