import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import Moment from 'moment';
import Mask from '../../helpers/mask';

import Icon from '../../utils/icon';

import EmploymentHistory from './employmentHistory/employmentHistory';
import MotorPrequalToolCreditHistory from './motorPrequalToolCreditHistory';
import { convertToSelectOptions } from '../applicationUpdatePage/helper';
import { get_States } from '../../constants/functions';

export default class MotorPrequalToolPersonal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      occupancies: [],
      relationship: this.getRelationshipstatus()[0].value,
    };

    this.handleRelationshipStatusChange = this.handleRelationshipStatusChange.bind(this);
    this.addOccupancy = this.addOccupancy.bind(this);
    this.removeOccupancy = this.removeOccupancy.bind(this);
    this.getOccupancies = this.getOccupancies.bind(this);

    this.getApplicantName = this.getApplicantName.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);
    this.initialiseOccupanciesFromProps = this.initialiseOccupanciesFromProps.bind(this);
    this.mapProps = this.mapProps.bind(this);
  }

  componentDidMount() {
    this.addOccupancy(false, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    this.initialiseOccupanciesFromProps(prevProps, prevState);
  }

  mapProps = (props) => props.map((x) => ({
    type: x.type || '',
    years: +(x.years),
    months: +(x.months),
    isCurrent: x?.isCurrent ?? true,
  }));

  initialiseOccupanciesFromProps(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data?.applicants) {
      if (this.props.data.applicants[this.props.index]?.occupancies !== this.state.occupancies) {
        this.setState({
          occupancies: this.mapProps(this.props.data.applicants[this.props.index]?.occupancies || []),
        });
      }
    }
  }

  addOccupancy(focus, index) {
    const newState = this.state;
    newState.occupancies.push({
      type: '',
      years: '',
      months: '',
      isCurrent: index == 0,
    });

    this.setState({ newState }, () => {
      if (focus !== false) {
        const element = document.getElementById(`applicants.${this.props.index}.occupancies.${newState.occupancies.length - 1}.type`);
        element.focus();
      }
    });
  }

  removeOccupancy(index) {
    const { occupancies } = this.state;
    occupancies.splice(index, 1);
    this.setState({
      occupancies,
    }, () => {
      if (this.state.occupancies.length > index) {
        document.getElementById(`applicants.${this.props.index}.occupancies.${index}.type`).focus();
      }
    });
  }

  getLoanTerms() {
    const terms = [];
    for (let value = 30; value >= 10; value--) {
      terms.push({
        value,
        label: `${value} years`,
      });
    }

    return terms;
  }

  getOccupancies() {
    return [{
      value: '',
      label: 'Select',
    },
    {
      value: 'Owner/Buyer',
      label: 'Owner/Buyer',
    },
    {
      value: 'With Parents',
      label: 'Living With Parents',
    },
    {
      value: 'Boarding',
      label: 'Boarding',
    },
    {
      value: 'Renter RE Agent',
      label: 'Renter RE Agent',
    },
    {
      value: 'Renter Other',
      label: 'Renter Other',
    },
    {
      value: 'Public Housing',
      label: 'Public Housing',
    },
    ];
  }

  getRelationshipstatus() {
    return [{
      value: '',
      label: 'Select',
    },
    {
      value: 'Married to Non Applicant',
      label: 'Married',
    },
    {
      value: 'Single',
      label: 'Single',
    },
    {
      value: 'De Facto to Non Applicant',
      label: 'De-Facto',
    },
    {
      value: 'Separated',
      label: 'Separated',
    },
    {
      value: 'Divorced',
      label: 'Divorced',
    },
    {
      value: 'Widowed',
      label: 'Widowed',
    },
    ];
  }

  getBirthYears() {
    const years = [{ value: '', label: 'Select' }];
    for (let value = (new Date().getFullYear() - 18); value >= (new Date().getFullYear() - 75); value--) {
      years.push({
        value,
        label: `${value}`,
      });
    }

    return years;
  }

  getGenders() {
    return [{
      value: '',
      label: 'Select',
    },
    {
      value: 'M',
      label: 'Male',
    }, {
      value: 'F',
      label: 'Female',
    }];
  }

  getDependants() {
    const dependants = [];
    dependants.push({
      value: '',
      label: 'Select',
    });

    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => {
      dependants.push({
        value: number,
        label: number,
      });
    });
    return dependants;
  }

  getApplicants() {
    const applicants = [{
      value: '',
      label: 'Non Applicant',
    }];

    this.props.applicants.map((applicant) => {
      if (applicant.key !== this.props.applicant.key) {
        applicants.push({
          value: applicant.key,
          label: `Applicant ${applicant.key}`,
        });
      }
    });

    return applicants;
  }

  handleRelationshipStatusChange(name, value) {
    this.setState({
      relationship: value,
    });

    this.props.applicants[this.props.index].relationship = value;
    this.handleRelationship(value);
  }

  removeApplicant() {
    this.props.removeApplicant(this.props.index);
  }

  handleName(value) {
    this.props.handleName(value, this.props.index);
  }

  handlePartner(value) {
    this.props.handlePartner(value, this.props.index);
  }

  handleRelationship(value) {
    this.props.handleRelationship(value, this.props.index);
  }

  getApplicantName(index) {
    let name = `APPLICANT ${index + 1}`;
    if (index == 0) {
      name = `${name} (Primary)`;
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  /**
     * Render
     */
  render() {
    return (
      <div className="card card-shallow card-stack">
        <div className="panel-row bg-primary-50" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
          <i className="mi mi-account mi-2x ib mr-md text-center np" style={{ width: '33px' }} />

          <span className="visible-xs mt-sm" />
          <div className="ib va-tt mt-sm-n" style={{ paddingTop: '4px' }}>
            <h3 className="mb-h mt-h">{this.getApplicantName(this.props.index)}</h3>
          </div>
          {
            (() => {
              if (this.props.applicants.length > 1) {
                return (
                  <button
                    id={`removeApplicant${this.props.index}`}
                    type="button"
                    className="btn btn-default btn-danger pull-right btn-circle"
                    style={{ marginTop: '-4px', marginRight: '2px' }}
                    onClick={this.removeApplicant}
                  >
                    <Icon glyph="delete" />
                  </button>
                );
              }
            })()
          }
        </div>

        <div className="panel-section-container">
          <Row>
            <Col sm={3}>
              <Input
                id={`applicants.${this.props.index}.firstName`}
                name={`applicants.${this.props.index}.firstName`}
                ref={`applicants.${this.props.index}.firstName`}
                label="First name"
                blurCallback={this.changeName}
                value=""
                validationError="First Name is required"
                validations={{
                  isRequired: true,
                }}
              />
            </Col>

            <Col sm={3}>
              <Input
                name={`applicants.${this.props.index}.middleName`}
                ref={`applicants.${this.props.index}.middleName`}
                id={`applicants.${this.props.index}.middleName`}
                label="Middle name"
                value=""
                blurCallback={this.changeName}
              />
            </Col>

            <Col sm={4}>
              <Input
                name={`applicants.${this.props.index}.lastName`}
                ref={`applicants.${this.props.index}.lastName`}
                id={`applicants.${this.props.index}.lastName`}
                label="Last name"
                value=""
                blurCallback={this.changeName}
                validationError="Last Name is required"
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3} md={2}>
              <Input
                id={`applicants.${this.props.index}.dateOfBirth`}
                name={`applicants.${this.props.index}.dateOfBirth`}
                ref={`applicants.${this.props.index}.dateOfBirth`}
                type="text"
                placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                updateOnBlur={false}
                changeDebounceInterval={0}
                blurCallback={(name, value) => Mask.fullDateFormsy(name, value, this.refs)}
                label="Date of Birth"
                value=""
                validationErrors={{
                  isValidPastDate: 'Date of Birth must be valid',
                  ageMinValidator: 'Must be over 18 years',
                  ageMaxValidator: 'Date of Birth must be valid',
                }}
                validations={{
                  isValidPastDate: true,
                  isRequired: true,
                  ageMinValidator: true,
                  ageMaxValidator: true,
                }}
              />
            </Col>
            <Col sm={3} md={2}>
              <Select
                id={`applicants.${this.props.index}.gender`}
                name={`applicants.${this.props.index}.gender`}
                label="Gender"
                value={this.getGenders()[0].value}
                options={this.getGenders()}
                validationError="Please select the gender"
                validations={{
                  isRequired: true,
                }}
                className="form-control"
              />
            </Col>
            {(() => (
              <Col sm={3} md={2}>
                <Select
                  id={`applicants.${this.props.index}.relationship`}
                  name={`applicants.${this.props.index}.relationship`}
                  label="Relationship Status"
                  value={this.props.applicant.relationship || this.getRelationshipstatus()[0].value}
                  options={this.getRelationshipstatus()}
                  changeCallback={this.handleRelationshipStatusChange}
                  validationError="Please select the relationship status"
                  validations={{
                    isRequired: true,
                  }}
                  className="form-control"
                />
              </Col>
            ))()}

            {(() => {
              if (this.props.applicants[this.props.index].relationship == '1' || this.props.applicants[this.props.index].relationship == '3') {
                let labelName = '';
                if (this.props.applicants[this.props.index].relationship == '1') {
                  labelName = 'Spouse';
                } else {
                  labelName = 'Partner';
                }
                return (
                  <Col sm={3} md={2}>
                    <span className="mute a" style={{ top: '39px', left: '-7px' }}>to</span>
                    <Select
                      id={`applicants.${this.props.index}.inRelationshipWithId`}
                      name={`applicants.${this.props.index}.inRelationshipWithId`}
                      ref={`applicants.${this.props.index}.inRelationshipWithId`}
                      label={labelName}
                      data-hj-masked
                      options={this.getApplicants()}
                      value={this.props.applicant.inRelationshipWithId || this.getApplicants()[0].value}
                      changeCallback={(name, value) => {
                        this.handlePartner(value);
                      }}
                      className="form-control"
                    />
                  </Col>
                );
              }
            })()}

            <Col sm={2}>
              <Select
                id={`applicants.${this.props.index}.dependants`}
                name={`applicants.${this.props.index}.dependants`}
                label="Dependants"
                value={this.getDependants()[0].value}
                options={this.getDependants()}
                validationError="Please select the number of dependants"
                validations={{
                  isPositive: true,
                }}
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Input
                id={`applicants.${this.props.index}.postcode`}
                name={`applicants.${this.props.index}.postcode`}
                ref={`applicants.${this.props.index}.postcode`}
                label="Postcode"
                value=""
                type="text"
                maxLength={4}
                updateOnBlur={false}
                changeDebounceInterval={0}
                blurCallback={(name, value) => Mask.cleanFormsy(name, value, this.refs)}
                validations={{
                  isRequired: true,
                }}
              />
              <i
                style={{ top: '6px', right: '12px' }}
                className="mute-lg a mi mi-help-outline pointer"
                data-position="fixed"
                data-event-on="click"
                data-tip="Postcode where the applicant currently lives"
              />
            </Col>
            <Col sm={2}>
              <Select
                id={`applicants.${this.props.index}.state`}
                name={`applicants.${this.props.index}.state`}
                label="State"
                value=""
                validationError="Please select the state"
                validations={{
                  isRequired: true,
                }}
                options={convertToSelectOptions(get_States(), '', (s) => s, (s) => s)}
                className="form-control"
              />
            </Col>
            {(() => {
              if (this.props.applicants[this.props.index].inRelationshipWithId == '' && (this.props.applicants[this.props.index].relationship == '1' || this.props.applicants[this.props.index].relationship == '3')) {
                return (
                  <Col sm={3}>
                    <div style={{ marginTop: '12px' }}>
                      <Checkbox
                        id={`applicants.${this.props.index}.expenses.sharingExpenses`}
                        name={`applicants.${this.props.index}.expenses.sharingExpenses`}
                        ref={`applicants.${this.props.index}.expenses.sharingExpenses`}
                        valueLabel={<span>Sharing the household expenses</span>}
                        value={false}
                      />
                    </div>
                  </Col>
                );
              }
            })()}
          </Row>
        </div>
        <div className="applicants">
          <div className="panel-header-fixed">
            <span className="visible-xs mt-sm" />
            <i className="mi mi-home mi-2x ib mr-md text-center np" style={{ width: '25px' }} />
            <div className="ib va-tt mt-sm-n">
              <h4 className="mb-h mt-h" style={{ whiteSpace: 'nowrap' }} data-tip="Occupancy should be at least two years.">
                Occupancy
              </h4>
              <p className="mute">We&apos;ll need at least two years of residency history</p>
            </div>
          </div>
          {
            (() => (
              <div className="card card-shallow">
                {
                  this.state.occupancies.map((occupancy, index) => (
                    <Row key={`occupancy-${index}`} className="panel-row r">
                      <Col sm={3}>
                        <Select
                          id={`applicants.${this.props.index}.occupancies.${index}.type`}
                          name={`applicants.${this.props.index}.occupancies.${index}.type`}
                          ref={`applicants.${this.props.index}.occupancies.${index}.type`}
                          label="Occupancy"
                          value={occupancy.type}
                          options={this.getOccupancies()}
                          changeCallback={this.handleOccupancyChange}
                          validationError="Please select the occupancy status"
                          validations={{
                            isRequired: true,
                          }}
                          className="form-control"
                        />
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label className="control-label" htmlFor={`applicants.${this.props.index}.occupancies.${index}.years`}>How long have you been living here?</label>
                          <div>
                            <Row>
                              <Col sm={3}>
                                <Input
                                  id={`applicants.${this.props.index}.occupancies.${index}.years`}
                                  name={`applicants.${this.props.index}.occupancies.${index}.years`}
                                  ref={`applicants.${this.props.index}.occupancies.${index}.years`}
                                  type="number"
                                  value={occupancy.years}
                                  updateOnBlur={false}
                                  changeDebounceInterval={0}
                                  blurCallback={(name, value) => Mask.numberFormsyNoZero(name, value, this.refs)}
                                  validationError="Years must be a valid number"
                                  validations={{
                                    isFormattedNumber: true,
                                    isValidYear: true,
                                  }}
                                />
                              </Col>
                              <Col sm={2} className="mt-sm">
                                {' '}
                                <span className="mute">Years</span>
                              </Col>
                              <Col sm={3}>
                                <Input
                                  id={`applicants.${this.props.index}.occupancies.${index}.months`}
                                  name={`applicants.${this.props.index}.occupancies.${index}.months`}
                                  ref={`applicants.${this.props.index}.occupancies.${index}.months`}
                                  type="number"
                                  value={occupancy.months}
                                  updateOnBlur={false}
                                  changeDebounceInterval={0}
                                  blurCallback={(name, value) => Mask.numberFormsyNoZero(name, value, this.refs)}
                                  validationError="Months must be a valid number."
                                  validations={{
                                    isFormattedNumber: true,
                                    isValidMonth: true,
                                  }}
                                />
                              </Col>
                              <Col className="mt-sm" sm={2}><span className="mute">Months</span></Col>
                            </Row>
                          </div>
                        </div>
                      </Col>

                      {(() => (
                        <Col sm={2}>
                          <div style={{ marginTop: '12px' }}>
                            <Checkbox
                              id={`applicants.${this.props.index}.occupancies.${index}.isCurrent`}
                              name={`applicants.${this.props.index}.occupancies.${index}.isCurrent`}
                              ref={`applicants.${this.props.index}.occupancies.${index}.isCurrent`}
                              valueLabel={<span>Current</span>}
                              value={occupancy.isCurrent}
                              disabled={true}
                            />
                          </div>
                        </Col>
                      ))()}

                      {
                        (() => {
                          if (index > 0) {
                            return (
                              <div>
                                <button type="button" id={`removeOccupancy_${index}`} className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle" style={{ marginTop: '31px' }} onClick={() => { this.removeOccupancy(index); }}>
                                  <Icon glyph="delete" />
                                </button>
                                <Col sm={12} className="visible-xs text-right">
                                  <button type="button" className="mb-md btn btn-danger" onClick={() => { this.removeOccupancy(index); }}>
                                    Remove Occupancy
                                  </button>
                                </Col>
                              </div>
                            );
                          }
                        })()
                      }
                    </Row>
                  ))
                }
                {(() => (
                  <Row className="panel-row">
                    <Col sm={12} className="text-center">
                      <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
                      <button type="button" id={`applicants.${this.props.index}.-addOccupancy`} name={`applicants.${this.props.index}.-addOccupancy`} className="btn btn-link pl-h pr-h np" onClick={this.addOccupancy}>Add Occupancy</button>
                    </Col>
                  </Row>
                ))()}
              </div>
            ))()
          }
        </div>
        <div marginTop="15px">&nbsp;</div>
        <div>
          <EmploymentHistory
            applicantIndex={this.props.index}
            employment={this.props.data?.applicants?.[this.props.index]?.employments} />
        </div>
        <div marginTop="15px">&nbsp;</div>
        <div>
          <MotorPrequalToolCreditHistory
            index={this.props.index}
            data={this.props.data}
            applicant={this.props.applicant ? this.props.applicant : null}
            applicants={this.props.applicants}
          />
        </div>
      </div>
    );
  }
}

MotorPrequalToolPersonal.propTypes = {
  index: PropTypes.number,
  applicant: PropTypes.object,
  applicants: PropTypes.array,
  handleName: PropTypes.func,
  handleRelationship: PropTypes.func,
  handlePartner: PropTypes.func,
  removeApplicant: PropTypes.func,
};
