import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Icon from '../../utils/icon';
import Money from '../../utils/money';

export default class RepaymentsResult extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-body panel-section-body-tint">
          <div className="panel-section-container text-center">
            {
              (() => {
                if (this.props.result) {
                  return (
                    <Row>
                      <Col sm={8} smOffset={2}>
                        <div className={`card text-left mb-lg pb-h mt-md text-center ${this.props.invalid ? 'mute-lg' : ''}`}>

                          <div className="mt-lg mb-lg">
                            <Icon glyph="money" className="mute-lg" size={6} />
                            <Icon glyph="label" className="secondary a mt-lg pl-lg pt-sm" size={3} style={{ left: 0, right: 0 }} />

                            <h3>
                              Repayments
                            </h3>

                          </div>

                          <div className="ml-md-n mr-md-n text-left">
                            <table className="table table-hover table-lg mb-h">
                              <thead>
                                {
                                  (() => {
                                    if (this.props.result.length > 1) {
                                      return (
                                        <tr>
                                          <td />
                                          <td className="mute-lg text-right" width={120}><small>IO period</small></td>
                                          <td className="mute-lg text-right" width={120}><small>P&amp;I period</small></td>
                                        </tr>
                                      );
                                    }
                                  })()
                                }
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="mute">Weekly</td>
                                  {
                                    this.props.result.map((result, index) => <td key={`w-${index ? 'io' : 'pi'}`} className="text-right"><strong><Money round={0}>{result / 4}</Money></strong></td>)
                                  }
                                </tr>
                                <tr>
                                  <td className="mute">Fortnightly</td>
                                  {
                                    this.props.result.map((result, index) => <td key={`f-${index ? 'io' : 'pi'}`} className="text-right"><strong><Money round={0}>{result / 2}</Money></strong></td>)
                                  }
                                </tr>
                                <tr>
                                  <td className="mute">Monthly</td>
                                  {
                                    this.props.result.map((result, index) => <td key={`m-${index ? 'io' : 'pi'}`} className="text-right"><strong><Money round={0}>{result / 1}</Money></strong></td>)
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  );
                }
              })()
            }

            <button type="button" className="btn btn-default btn-simple btn-lg mr-sm" onClick={() => { this.props.savePrequal(); }}>Save</button>

          </div>
        </div>

      </div>
    );
  }
}

RepaymentsResult.propTypes = {
  result: [PropTypes.array, PropTypes.bool],
  invalid: PropTypes.bool,
  savePrequal: PropTypes.func.isRequired,
};

RepaymentsResult.defaultProps = {
  result: false,
  invalid: false,
};
