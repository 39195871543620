import { Dispatch } from "redux";
import { Guid } from "guid-typescript";
import { EmploymentRowState } from "./reducer";

export type EmploymentHistoryAction =
  AddEmploymentAction
  | LoadEmploymentAction
  | RemoveEmploymentAction
  | ChangeEmploymentTypeAction
  | SetEmploymentToNotCurrentAction
  | SetEmploymentToCurrentAction;;

export enum EmploymentHistoryActionTypes {
  LoadEmployment = "LoadEmployment",
  AddEmployment = "AddEmployment",
  RemoveEmployment = "RemoveEmployment",
  ChangeEmploymentType = "ChangeEmploymentType",
  SetEmploymentToNotCurrent = "SetEmploymentToNotCurrent",
  SetEmploymentToCurrent = "SetEmploymentToCurrent",
}

interface LoadEmploymentAction { type: EmploymentHistoryActionTypes.LoadEmployment, employment: EmploymentRowState[] }
interface AddEmploymentAction { type: EmploymentHistoryActionTypes.AddEmployment }
interface RemoveEmploymentAction { type: EmploymentHistoryActionTypes.RemoveEmployment, id: Guid  }
interface ChangeEmploymentTypeAction { type: EmploymentHistoryActionTypes.ChangeEmploymentType, id: Guid, employmentType: EmploymentType }
interface SetEmploymentToNotCurrentAction { type: EmploymentHistoryActionTypes.SetEmploymentToNotCurrent, id: Guid }
interface SetEmploymentToCurrentAction { type: EmploymentHistoryActionTypes.SetEmploymentToCurrent, id: Guid }

export type EmploymentType =
  "Permanent Full Time"
  | "Permanent Part Time"
  | "Contract Full Time"
  | "Contract Part Time"
  | "Casual"
  | "Seasonal"
  | "Sole-Trader"
  | "Partner"
  | "Director";

export const loadEmployment = (employment: EmploymentRowState[]) => (dispatch: Dispatch<LoadEmploymentAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.LoadEmployment,
    employment
  });
}

export const addEmployment = () => (dispatch: Dispatch<AddEmploymentAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.AddEmployment,
  });
}

export const removeEmployment = (id: Guid) => (dispatch: Dispatch<RemoveEmploymentAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.RemoveEmployment,
    id
  });
}

export const changeEmploymentType = (id: Guid, employmentType: EmploymentType) => (dispatch: Dispatch<ChangeEmploymentTypeAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.ChangeEmploymentType,
    id,
    employmentType
  });
}

export const setEmploymentToNotCurrent = (id: Guid) => (dispatch: Dispatch<SetEmploymentToNotCurrentAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.SetEmploymentToNotCurrent,
    id
  });
}

export const setEmploymentToCurrent = (id: Guid) => (dispatch: Dispatch<SetEmploymentToCurrentAction>) => {
  dispatch({
    type: EmploymentHistoryActionTypes.SetEmploymentToCurrent,
    id
  });
}
