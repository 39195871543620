import * as React from 'react';
import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Checkbox, Input} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import Navigation from '../navigation';
import Header from '../header';
import Title from '../title';

import Question from './question';

import {toOption, toRequired, shouldFormBeVisible, hasFormData, setFormData} from '../functions';
import {IOption} from '../../../interfaces';
import {IBaseProps} from '../interfaces';

import {debug} from '../debug';

interface IDeclarationProps extends IBaseProps {}
interface IDeclarationState {}

export class Declaration extends React.Component<IDeclarationProps, IDeclarationState> {
  constructor(props) {
    super(props);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  UNSAFE_componentWillMount(){
    if (hasFormData(this.props.data, 'declaration')) {
      //
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'declaration')) {
      setFormData(this.props.data, 'declaration', this.refs.form);
    }
  }

  onValidSubmit(data) {
    this.props.saveStepData(data, 'declaration');
  }

  onInvalidSubmit() {
    this.props.notifyStep();
  }

  get options(): IOption[] {
    return ["Yes", "No"].map(o => toOption(o));
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        className={shouldFormBeVisible(this.props.currentStep, 6)}
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <Header title="Declaration" required />
          <div className="panel-body p-h">
            <div className="panel-section">
              <Title>Responsible lending</Title>
              <div className="panel-section-body panel-section-body-simple">
                <Checkbox
                  name={`factSheet`}
                  ref={`factSheet`}
                  id={`factSheet`}
                  validations={{
                    isRequired: true
                  }}
                  validationError="Required"
                  valueLabel={toRequired(<strong>I confirm I have provided all applicants with a Liberty Key Facts sheet.</strong>)}
                />
                <Checkbox
                  name={`liabilities`}
                  id={`liabilities`}
                  ref={`liabilities`}
                  validations={{
                    isRequired: true
                  }}
                  validationError="Required"
                  valueLabel={toRequired(<strong>I confirm I have asked all applicants about liabilities.</strong>)}
                />
              </div>

              <Title>Credit declaration</Title>

              <div className="panel-section-body">
                <div className="panel-section-container">
                  <p className="mute mt-md"><i>The undersigned hereby applies for the loan described herein to be secured by a mortgage on the property described herein and represents that all statements made in this application are true and correct and understands that the credit provider is relying on the information that I/we supply them and that it is my/our responsibility to be accurate in giving that information.  We agree that this application can only proceed with a valuation of the subject property by a qualified valuer selected by the credit provider, at the expense of the undersigned and without implied obligation on the part of the credit provider.  The undersigned further agrees to pay all necessary expense, including legal costs, incurred in obtaining this loan.  It is agreed that by accepting this application the credit provider is not obligated to grant a loan or any other financial accommodation.  We note that the mortgage will require general insurance coverage against hazards equal, at least, to the value of the property improvements.  Such insurer shall be acceptable to the credit provider, who has sole discretion for rejection without grounds.  I/We declare that I/we, undersigned, am/are over the age of 18 at the time of the execution of this application and acknowledge that this application is being made to credit provider and it’s successors and assigns.  I/We consent to the credit provider paying the commissions set out above.  I/We have read and agree to the use and disclosure of personal information about me/us as set out in the Important Privacy Notice & Consent.</i></p>
                </div>
              </div>

              <div className="panel-section-body panel-section-body-simple mt-lg-n pb-h">
                <Question name="debts" label="Have you or a company with which you are associated had any past or current debts that were or are in default of the payment arrangement?" options={this.options} />

                <Question name="bankruptcy" label="Have you been bankrupt or insolvent, petitioned or aware of anyone petitioning you for bankruptcy, advised to seek bankruptcy, or associated with a company that was or threatened to be placed into administration?" options={this.options} />

                <Question name="ability" label="Are you aware of anything that may adversely affect your application for credit or ability to meet your obligations under this loan (e.g., increase in expenses, change in employment, changes to family circumstances, etc.)?" options={this.options} />
              </div>
            </div>
          </div>
        </div>

        <Navigation previousStep={this.props.previousStep} nextLabel="Submit application" />
      </Form>
    );
  }
}

export default Declaration;
