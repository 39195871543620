import * as React from 'react';
import PropTypes from 'prop-types';


import { Icon } from '../../utils';

export default class ChatPane extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      loaded: false,
      interval: null,
    };

    this.enableChat = this.enableChat.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      interval: setInterval(this.enableChat, 1000),
    });
  }

  enableChat() {
    if (window.insideFrontInterface && window.insideFrontInterface.openChatPane) {
      clearInterval(this.state.interval);
      this.setState({
        loaded: true,
        interval: null,
      });
    }
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return (
      <div className="inside" onClick={window.insideFrontInterface.openChatPane}>
        <Icon glyph="comment-more" />
        {' '}
        <span className="hidden-xs">Chat now!</span>
      </div>
    );
  }
}
