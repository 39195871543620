/**
 * Import depandancies
 */
import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon, Avatar } from '../../utils';
import Event3rdParty from '../../helpers/event3rdParty';

/**
 * BDM list item for BDM widget
 */
export default class ContactsWidgetItem extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      showEmail: false,
      showPhone: false,
    };

    this.showEmail = this.showEmail.bind(this);
    this.showPhone = this.showPhone.bind(this);
  }

  showEmail() {
    this.setState({
      showEmail: true,
    });

    Event3rdParty.send('reveal-email');
  }

  showPhone() {
    this.setState({
      showPhone: true,
    });

    Event3rdParty.send('reveal-phone');
  }

  /**
     * Render widget
     */
  render() {
    return (
          <div className="bdm-item">
            <Avatar className="bdm-avatar" contact={this.props.contact} />

            <div className="bdm-info ib">
              <h4 className="bdm-name mb-h mt-xs">
                {this.props.contact.lFStaffFirstName}
                {' '}
                {this.props.contact.lFStaffSurname}
              </h4>
              <span className="bdm-title ib mute mb-sm ellipsis">{this.props.contact.lFStaffPosition}</span>


              {(() => {
                /**
                             * Check if the phone numbers are passed on
                             */
                if (this.props.contact.lFStaffPhones && this.props.contact.lFStaffPhones[0]) {
                  return (
                  <div className="bdm-phone-wrap">
                    <Icon glyph="phone" color="secondary" />
                        &nbsp;
                    <span className={`bdm-phone mr-md-n animate-fast ${(!this.state.showPhone) ? 'transparent ml-lg-n a' : 'a'}`}>
                      {
                        this.props.contact.lFStaffPhones.map((phone, idx) => (<a key={`phone=${this.props.contact.lFStaffID}-${idx}`} href={`tel:${phone.replace(/\D/g, '')}`} className={`primary ib ${idx ? 'hidden-sm' : ''}`}>{phone}</a>))
                      }
                    </span>
                    <a onClick={this.showPhone} className={`bdm-phone link primary animate-fast a ${this.state.showPhone ? 'transparent ml-100' : ''}`}>
                    {'Call '}
                    {this.props.contact.lFStaffFirstName}
                    </a>
                  </div>
                  );
                }
              })() }

              {(() => {
                /**
                             * Check if the email address is passed on
                             */
                if (typeof this.props.contact.lFStaffEmail !== 'undefined' && this.props.contact.lFStaffEmail !== false) {
                  return (
                  <div className="bdm-email-wrap">
                    <Icon glyph="mail-send" color="secondary" />
                        &nbsp;
                    <a href={`mailto:${this.props.contact.lFStaffEmail}?subject=${this.props.subject || ''}`} className={`bdm-email primary animate-fast a ${(!this.state.showEmail) ? 'transparent ml-lg-n' : ''}`}>{this.props.contact.lFStaffEmail}</a>
                    <a onClick={this.showEmail} className={`bdm-email link primary animate-fast a ${this.state.showEmail ? 'transparent ml-100' : ''}`}>
                    {'Email '}
                    {this.props.contact.lFStaffFirstName}
                    </a>
                  </div>
                  );
                }
              })() }
            </div>
          </div>
    );
  }
}

ContactsWidgetItem.propTypes = {
  contact: PropTypes.any,
  subject: PropTypes.any,
};
