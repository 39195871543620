import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showFineprint, hideFineprint } from './actions';
import { Visibility } from './reducer';

import LvrModal from './lvrModal';

interface LvrHeaderProps {
  fineprint: Visibility;
  actions: {
    showFineprint: typeof showFineprint;
    hideFineprint: typeof hideFineprint;
  }
}

export const LvrHeader = (props: LvrHeaderProps) => (
  <div className="mt-lg pt-sm mb-lg text-center">
    <h1 className="mt-lg">Residential LVR Lookup</h1>
    <h4 className="mute">Get an estimate on the maximum LVR for a postcode</h4>

    <button type="button" id="showLvrModal" className="btn btn-link" onClick={props.actions.showFineprint}>Things you should know<span className="hidden-xs"> about this tool</span></button>

    <LvrModal hideModal={props.actions.hideFineprint} visible={props.fineprint} />
  </div>
);

function mapStateToProps(state) {
  return {
    fineprint: state.lvr.fineprint
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ showFineprint, hideFineprint }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LvrHeader);
