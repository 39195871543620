import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Row, Col,
} from 'react-bootstrap';
import { Input } from 'formsy-react-components';

import Formsy from 'formsy-react';
import * as referenceActions from '../../actions/referenceActions';
import Mask from '../../helpers/mask';
import ApplicationUpdateNvicSearch from './applicationUpdateNvicSearch';
import ApplicationUpdateVehicleManual from './applicationUpdateVehicleManual';
import { PRODUCTGROUPS } from '../../constants/types';

export type ApplicationUpdateVehiclePickerProps = {
  isRequired: boolean,
  actions: any,
  reference: any,
  application: any,
  formsy: Formsy | null,
  setMessage: (input: string | null) => any,
}

type ApplicationUpdateVehiclePickerState = {
  manual: boolean
}

const isConsumerCaravan = (productGroupId: number) => productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN;

export class ApplicationUpdateVehiclePicker extends React.Component<ApplicationUpdateVehiclePickerProps, ApplicationUpdateVehiclePickerState> {
  constructor(props) {
    super(props);

    this.state = {
      manual: !this.props.application?.motorPurchaseVehicle?.nvic && isConsumerCaravan(this.props.application?.productGroupId || 0),
    };

    this.toggleNvic = this.toggleNvic.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  componentWillUnmount() {
    this.setState({
      manual: false,
    });

    this.props.actions.clearNvic();
  }

  getValue(field) {
    const matches = this.props.formsy && this.props.formsy.inputs.filter((input) => input.props.id == field);
    if (matches && matches.length) {
      return matches[0].getValue();
    }
    return undefined;
  }

  getAppraisal() {
    if (this.props.reference.nvic && this.props.reference.appraisal) {
      const amount = this.props.application?.motorPurchaseVehicle?.isUsed ? this.props.reference.appraisal.tradeHighAmount : this.props.reference.appraisal.retailAmount;
      return Mask.number(amount);
    }

    return null;
  }

  toggleNvic() {
    this.setState({
      manual: !this.state.manual,
    });
  }

  render() {
    const showManual = this.state.manual && isConsumerCaravan(this.props.application?.productGroupId);
    return (
      <Row>
        <Col sm={9}>
          {showManual
            ? (
              <>
                <ApplicationUpdateVehicleManual />
                {isConsumerCaravan(this.props.application?.productGroupId) && (
                  <span className="help-block">
                    <button type="button" className="btn btn-link mt-md-n mb-h pl-h pr-h b" onClick={this.toggleNvic}>
                      <small>Switch back to vehicle search</small>
                    </button>
                  </span>
                )}
              </>
            )
            : (
              <>
                <ApplicationUpdateNvicSearch
                  isRequired={this.props.isRequired}
                  application={this.props.application}
                  formsy={this.props.formsy}
                  getValue={this.getValue}
                  setMessage={this.props.setMessage}
                />
                {isConsumerCaravan(this.props.application?.productGroupId) && (
                  <span className="help-block">
                    <button type="button" className="btn btn-link mt-md-n mb-h pl-h pr-h b" onClick={this.toggleNvic}>
                      <small>Select vehicle manually</small>
                    </button>
                  </span>
                )}
              </>
            )}
        </Col>

        {!showManual && this.props.reference.nvic && (
          <Col sm={2}>
            <div className="form-prefix">$</div>
            <div className="form-group">
              <label className="control-label">
                {'Value '}
                <small className="mute">(estimated)</small>
              </label>
              <div className="form-control" disabled>{this.getAppraisal()}</div>
            </div>
            <Input
              type="hidden"
              value={this.props.application?.motorPurchaseVehicle?.isUsed ? 
                this.props.reference?.appraisal?.tradeHighAmount : 
                this.props.reference?.appraisal?.retailAmount}
              id="motorPurchaseVehicle.valuation"
              name="motorPurchaseVehicle.valuation"
            />
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateVehiclePicker);
