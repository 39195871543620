import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingStepSupport extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="onboarding-step text-center support">
        <h2 className="onboarding-title">Supporting information</h2>
        <h4 className="onboarding-subtitle mute">Liberty IQ provides you all the documents and tools to help you prepare an application to us. From calculators to introducer guides, we’ve got you covered.</h4>
      </div>
    );
  }
}
