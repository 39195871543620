import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import Storage from '../../helpers/storage';
import Icon from '../../utils/icon';

class IntroducerItem extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
    };
  }

  handleClick = (e) => {
    Storage.setItem('introducerIdHasChanged', true);
    Storage.setItem('session', this.props.originatorId);
    Storage.setItem('capabilities', this.props.capabilities);
  }

  render() {
    return (
      <Link padding='0px 0px 15px 0px' className="loan-item loan-single" to="/applications" onClick={this.handleClick}>
        <div>
          <Icon glyph="account" rounded background='primary' color='white' />
          <div style={{ display: 'inline-block', verticalAlign: 'middle', padding: '0px 0px 0px 12px' }}>
            <p className="loan-client-name" margin='0px'>{this.props.fullname}</p>
            <p className="loan-status" margin='0px'>{this.props.username}</p>
          </div>
        </div>
      </Link>
    );
  }
}

IntroducerItem.propTypes = {
  originatorId: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  fullname: PropTypes.string.isRequired,
  capabilites: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroducerItem);
