import { LvrAction, LvrActionType, LvrData, LvrFilter } from './actions';

enum LvrStatus {
  Initial = "Initial",
  Pending = "Pending",
  Succeeded = "Succeeded",
  Failed = "Failed"
}

export enum Visibility {
  Hidden,
  Visible
} 

export interface LvrState {
  status: LvrStatus
  filter: LvrFilter;
  data: LvrData[];
  fineprint: Visibility.Visible | Visibility.Hidden;
};

export const initialState: LvrState = {
  status: LvrStatus.Initial,
  filter: {
    propertyType: "House",
    purpose: "Owner Occupied",
    riskGrade: "AAA",
    swift: "-",
    verificationLevel: "Star",
    postcode: ""
  },
  data: [],
  fineprint: Visibility.Hidden
};

const exhaustiveReducerCheck = (action: never): void => {};

export const lvrReducer = (state: LvrState = initialState, action: LvrAction): LvrState  => {
  switch (action.type) {
    case LvrActionType.ShowFineprint:
      return {
        ...state,
        fineprint: Visibility.Visible
      };

    case LvrActionType.HideFineprint:
      return {
        ...state,
        fineprint: Visibility.Hidden
      };
    
    case LvrActionType.SetLvrFilterValue:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.field]: action.value
        },
      };
    
    case LvrActionType.GetLvrData:
      return {
        ...state,
        status: LvrStatus.Pending
      };

    case LvrActionType.GetLvrDataFailed:
      return {
        ...state,
        status: LvrStatus.Failed
      };

    case LvrActionType.GetLvrDataSucceeded:
      return {
        ...state,
        data: action.data,
        status: LvrStatus.Succeeded
      };

    default:
      exhaustiveReducerCheck(action);
      return state;
  }
};