import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select, Checkbox} from 'formsy-react-components';

import Mask from '../../../helpers/mask';

import Remove from '../remove';
import Title from '../title';
import SelectableInput from '../selectableInput';
import CurrencyInput from '../currencyInput';
import {toOption, toRequired} from '../functions';

import {IOption} from '../../../interfaces';
import {IGuid, ILiabilityItem, IAssets} from '../interfaces';

import {lenderOptions} from '../options';

interface ILoanProps {
  liability: ILiabilityItem;
  index: number;
  removeLiability: (id: IGuid) => void;
  changeLiabilityRepayment: (id: IGuid, value: string | number) => void;
  data: any;
}
interface ILoanState {
  type: string;
  refinance: boolean;
  repayment: string;
}

function getBalanceValidations(liability: ILiabilityItem): any {
  const validations: any = {
    isRequired: true
  };

  if (liability.type != 'creditCard') {
    validations.isNotZero = true;
  }

  return validations;
}

export class Loan extends React.Component<ILoanProps, ILoanState> {
  UNSAFE_componentWillMount() {
    this.state = {
      type: "creditCard",
      refinance: true,
      repayment: ""
    };

    this.canRefinance = this.canRefinance.bind(this);
    this.changeRepayment = this.changeRepayment.bind(this);
    this.calculateRepayment = this.calculateRepayment.bind(this);
  }

  changeType(_, type: string) {
    this.setState({
      type
    });
  }

  get purposes(): IOption[] {
    let purposes = ["Personal"];

    if (this.props.liability.type == 'homeLoan') {
      purposes.push("Investment");
    }

    return purposes.map(o => toOption(o));
  }

  isProperty(asset) {
    return asset.type == 'property';
  }

  isToBeSold(asset) {
    return asset.use == "toBeSold";
  }

  isValidSecurity(asset) {
    return this.isProperty(asset);
  }

  isSecurity(asset) {
    return asset.security;
  }

  isCreditCard(type) {
    return type == 'creditCard'
  }

  shouldRefinance() {}

  canRefinance() {
    if (!this.props.data.assets.specificAssets)
      return false;

    let refinance = true;

    this.props.data.assets.specificAssets
      .filter(asset => this.isProperty(asset) && this.isToBeSold(asset))
      .forEach(asset => {
        let field = this.refs[`liabilities[${this.props.index}].securities.${asset.id}`] as any;
        if (field) {
          if (field.getValue()) {
            refinance = false;
          }
        }
      });

    return refinance;

   //
  }

  changeRepayment(_:string, value:string) {
    this.props.changeLiabilityRepayment(this.props.liability.id, value);
  }

  calculateCreditCardRepayment(repayment) {
    return Math.floor(Number(Mask.clean(repayment)) * .03)
  }

  calculateRepayment(_:string, value:string) {
    if (this.isCreditCard(this.props.liability.type)) {
      let repayment = this.calculateCreditCardRepayment(value);
      this.props.changeLiabilityRepayment(this.props.liability.id, repayment);

      this.setState({
        repayment: Mask.number(repayment)
      });
    }
  }

  render(): JSX.Element {
    return (
      <div className={`card mt-lg p-lg`}>
        <Input
          name={`liabilities[${this.props.index}].type`}
          type="hidden"
          value={this.props.liability.type}
        />
        <Input
          name={`liabilities[${this.props.index}].id`}
          type="hidden"
          value={this.props.liability.id}
        />

        <Title top>
          {this.props.liability.name}
          <Remove onClick={this.props.removeLiability.bind(this, this.props.liability.id)} />
        </Title>

        <Row>
          <Col sm={6}>
            <SelectableInput
              name={`liabilities[${this.props.index}].lender`}
              label={toRequired("Lender")}
              validations={{
                isRequired: true
              }}
              options={lenderOptions}
              validationError="Lender is required" />
          </Col>

          <Col sm={3} style={{marginTop: '12px'}}>
            {
              (() => {
                if (this.canRefinance()) {
                  return (
                    <Checkbox
                      name={`liabilities[${this.props.index}].refinance`}
                      id={`liabilities[${this.props.index}].refinance`}
                      validations={{
                        isRequiredSecurity: (values, value) => {
                          let required = false;
                          this.props.data.assets.specificAssets.filter(asset => this.isValidSecurity(asset)).forEach(property => {
                            if (values[`liabilities[${this.props.index}].securities.${property.id}`] && property.security) {
                              required = true;
                            }
                          });

                          if (required) {
                            return value;
                          }

                          return true;
                        }
                      }}
                      validationError="Liability has to be refinanced if the security is part of the application"
                      rowClassName="ib mr-sm"
                      valueLabel={<span>Refinance to Liberty</span>}
                    />
                  );
                }

                return (
                  <Input
                    type="hidden"
                    name={`liabilities[${this.props.index}].refinance`}
                    value={false} />
                );
              })()
            }


          </Col>
        </Row>

        <Row>

          <Col sm={3}>
            <CurrencyInput
              name={`liabilities[${this.props.index}].balance`}
              label={toRequired("Balance")}
              validations={getBalanceValidations(this.props.liability)}
              validationErrors={{
                isRequired: "Balance is required",
                isNotZero: "Balance can not be 0"
              }}
             />
          </Col>

          <Col sm={3}>
            <CurrencyInput
              name={`liabilities[${this.props.index}].limit`}
              label={toRequired("Limit")}
              validations={{
                isRequired: true,
                isNotZero: true
              }}
              blurCallback={this.calculateRepayment}
              validationErrors={{
                isRequired: "Limit is required",
                isNotZero: "Limit can not be 0"
              }}
              data-test-name="limit"
            />
          </Col>

          <Col sm={3} smOffset={3}>
              {this.isCreditCard(this.props.liability.type)
                ? <div>
                    <div className="form-prefix" style={{marginTop: '33px'}}>$</div>
                    <Input
                      name={`liabilities[${this.props.index}].repayment`}
                      id={`liabilities[${this.props.index}].repayment`}
                      label={toRequired(<span>Repayments <small className="mute">(monthly)</small></span>)}
                      data-test-name="repayment"
                      value={Mask.number(this.state.repayment)}
                      className="form-control text-right"
                      disabled />
                  </div>
                : <CurrencyInput
                    name={`liabilities[${this.props.index}].repayment`}
                    label={toRequired(<span>Repayments <small className="mute">(monthly)</small></span>)}
                    align="right"
                    validations={{
                      isRequired: true,
                      isNotZero: true
                    }}
                    validationErrors={{
                      isRequired: "Repayments are required",
                      isNotZero: "Repayments can not be 0"
                    }}
                    blurCallback={this.changeRepayment}
                    data-test-name="repayment"
                  />
                }
          </Col>
        </Row>

        {
          (() => {
            if (this.props.liability.type == 'homeLoan') {
              return (
                <Row>
                  <Col sm={12}>
                    <hr />

                    <label className="mb-sm">Security</label>

                    {
                      this.props.data.assets.specificAssets && this.props.data.assets.specificAssets.filter(asset => this.isValidSecurity(asset)).map(asset => {
                        return (
                          <div key={`specificAsset_${asset.id}`}>
                            <Checkbox
                              name={`liabilities[${this.props.index}].securities.${asset.id}`}
                              ref={`liabilities[${this.props.index}].securities.${asset.id}`}
                              id={`liabilities[${this.props.index}].securities.${asset.id}`}
                              valueLabel={<span>{asset.address.autocomplete}</span>}
                              validations={{
                                isAtLeastOneSelected: (values, value) => {
                                  return !!this.props.data.assets.specificAssets.filter(asset => this.isValidSecurity(asset)).reduce((count, property) => {
                                    return count += values[`liabilities[${this.props.index}].securities.${property.id}`] ? 1 : 0;
                                  }, 0);
                                }
                              }}
                              validationErrors={{
                                isAtLeastOneSelected: "At least one security needs to be selected"
                              }}
                            />
                          </div>
                        );
                      })
                    }
                  </Col>
                </Row>
              );
            }
          })()
        }
      </div>
    );
  }
}

export default Loan;
