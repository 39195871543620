export enum MotorDeclarationsActionType {
  ConfirmedMotorDeclarations = "ConfirmedMotorDeclarations",
  ResetMotorDeclarations = "ResetMotorDeclarations"
}

export type MotorDeclarationsAction =
  ConfirmMotorDeclarations
  | ResetMotorDeclarations

interface ConfirmMotorDeclarations {
  type: MotorDeclarationsActionType.ConfirmedMotorDeclarations
};

interface ResetMotorDeclarations {
  type: MotorDeclarationsActionType.ResetMotorDeclarations
}

export const confirmMotorDeclarations = (): ConfirmMotorDeclarations => ({
  type: MotorDeclarationsActionType.ConfirmedMotorDeclarations
});

export const resetMotorDeclarations = (): ResetMotorDeclarations => ({
  type: MotorDeclarationsActionType.ResetMotorDeclarations
})
