import * as React from 'react';
import PropTypes from 'prop-types';
import { Empty } from '../../utils';

const NotFoundPage = () => (
    <Empty glyph="block" title="Oops, can’t find that page...">The page you were trying to access could not be found.</Empty>
);

NotFoundPage.propTypes = {
};

export default NotFoundPage;
