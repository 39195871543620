import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PrequalHeader from './prequalHeader';
import PrequalLoad from './prequalLoad';
import PrequalSelect from './prequalSelect';

import * as storageActions from '../../actions/storageActions';

export class PrequalPage extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  componentDidMount() {
    this.props.actions.setStorage('newPrequalFeature');
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="main-panel mt-sm-n">
        <div className="panel panel-default">
          <div className="panel-body pl-lg pr-lg">
            <PrequalHeader />
            { (this.props.session.details.isResidential || this.props.session.details.isMotor) && <PrequalLoad /> }
            <PrequalSelect session={this.props.session} />
          </div>
        </div>
      </div>
    );
  }
}

PrequalPage.propTypes = {
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrequalPage);
