import { Dispatch } from 'react';
import API from '../api';

export enum DocumentsActionType {
  GET_DOCUMENTS = "GET_DOCUMENTS",
  START_DOCUMENT = "START_DOCUMENT",
  STOP_DOCUMENT = "STOP_DOCUMENT"
}

export type Document = {
  name: string,
  fileName: string,
  description: string,
  assetClasses: string[],
  isProcessing: boolean,
}

export type DocumentsResponse = {
  forms: Document[],
  guides: Document[],
  insuranceDocuments: Document[],
  targetMarketDeterminationDocuments: Document[],
}

interface GetReferenceDocumentsAction {
  type: DocumentsActionType.GET_DOCUMENTS,
  documents: DocumentsResponse
}

interface StopReferenceDocumentsAction {
  type: DocumentsActionType.STOP_DOCUMENT,
  file: Document,
  group: string
}

interface StartReferenceDocumentsAction {
  type: DocumentsActionType.START_DOCUMENT,
  file: Document,
  group: string
}

export type DocumentsAction =
  GetReferenceDocumentsAction
  | StopReferenceDocumentsAction
  | StartReferenceDocumentsAction

const getReferenceDocuments = () => (dispatch: Dispatch<DocumentsAction>) => API.getReferenceDocuments()
  .then((response) => dispatch({
    type: DocumentsActionType.GET_DOCUMENTS,
    documents: {...response.data},
  }))
  .catch((error) => {
    throw (error);
  });

const startReferenceDocument = (file, group) => (dispatch: Dispatch<DocumentsAction>) => {
  dispatch({
    type: DocumentsActionType.START_DOCUMENT,
    file,
    group,
  });
};

const stopReferenceDocument = (file, group) => (dispatch: Dispatch<DocumentsAction>) => {
  dispatch({
    type: DocumentsActionType.STOP_DOCUMENT,
    file,
    group,
  });
};

export const documentsActions = {
  getReferenceDocuments,
  startReferenceDocument,
  stopReferenceDocument,
};
