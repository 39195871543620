import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { Form, Input } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';

import { Icon, Overlay } from '../../utils';
import Event3rdParty from '../../helpers/event3rdParty';

import * as sessionActions from '../../actions/sessionActions';

import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';

export class ProfileEmail extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      visible: false,
      processing: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.stopProcessing = this.stopProcessing.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  hideModal() {
    this.setState({
      visible: false,
    });
  }

  startProcessing() {
    this.setState({
      processing: true,
    });
  }

  stopProcessing() {
    this.setState({
      processing: false,
    });
  }

  handleSubmit(data) {
    this.hideModal();
    this.startProcessing();

    // 3rd party
    Event3rdParty.send('update-email');

    this.props.actions.setDetailEmails(data)
      .then(() => {
        Notify.show(messages.MESS_SUCCESS, 'success', 3000);
      }).catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error', 3000);
      })
      .then(this.stopProcessing);
  }

  handleInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  render() {
    return (
      <div className="panel-part">
        <h5 className="panel-bar">
          Email addresses

          {
            // We've removed this section for B2C because we don't allow the user to edit B2C credentials
            () => {
              if (Storage.getItem('isUseB2C') !== 'true') {
                return (
                  <button id="profileEmailEdit" onClick={this.showModal} className="btn btn-secondary btn-link mt-sm-n mr-sm-n pull-right">
                    <Icon glyph="edit" />
                  </button>
                );
              }
            }
          }
        </h5>

        <dl className="dl-horizontal">
          <dt>Primary</dt>
          <dd data-hj-masked>{this.props.getValue(this.props.details.email)}</dd>

          <dt>Secondary</dt>
          <dd data-hj-masked>{this.props.getValue(this.props.details.emailSecondary)}</dd>
        </dl>

        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Saving changes...</Overlay>
              );
            }
          })()
        }

        <Modal
          show={this.state.visible}
          onHide={this.hideModal}
        >

          <Form
            onValidSubmit={this.handleSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            layout="vertical"
          >

            <Modal.Body>
              <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
                <span aria-hidden="true">×</span>
              </button>

              <div className="text-center">
                <Icon glyph="email-open" className="mt-lg mb-lg mute-lg" size={6} />
              </div>

              <Input
                id="email"
                name="email"
                type="email"
                label="Primary email address"
                data-hj-masked
                value={this.props.details.email || ''}
                validationError="Email address is required and must be valid"
                validations={{
                  isEmail: true,
                  isRequired: true,
                }}
              />

              <Input
                id="emailSecondary"
                name="emailSecondary"
                type="email"
                label="Secondary email address"
                data-hj-masked
                value={this.props.details.emailSecondary || ''}
                validationError="Email address must be valid"
                validations={{
                  isEmail: true,
                }}
              />

            </Modal.Body>

            <Modal.Footer>
              <button type="button" onClick={this.hideModal} className="btn btn-link btn-default">Cancel</button>
              <button type="submit" className="btn btn-success">Save</button>
            </Modal.Footer>

          </Form>
        </Modal>
      </div>
    );
  }
}

ProfileEmail.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  details: PropTypes.any,
  getValue: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEmail);
