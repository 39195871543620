import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Input, Select } from 'formsy-react-components';

const AccreditationCompany = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div>
      <div className="panel-bar ml-lg-n mr-lg-n pl-lg">
        <h5 className="panel-title">Company</h5>
      </div>

      <Row>
        <Col sm={7}>
          <Input
            name="companyName"
            label="Company name"
            value=""
            tabIndex="1"
            autoFocus
            validationError="Company name is required"
            validations={{
              isRequired: true,
            }}
          />
        </Col>

        <Col sm={5}>
          <Input
            name="companyACLorCRN"
            label="ACL / CRN"
            value=""
            tabIndex="2"
            validationError="ACL or CRN is required and must be valid"
            validations={{
              isRequired: true,
              isNumeric: true,
              minLength: 4,
              maxLength: 12,
            }}
          />
        </Col>
      </Row>

      <div className="panel-bar ml-lg-n mr-lg-n mt-md pl-lg">
        <h5 className="panel-title">Address</h5>
      </div>

      <Row>
        <Col sm={12}>
          <Input
            name="companyAddress"
            label="Address"
            value=""
            tabIndex="3"
            validationError="Address is required and must be valid"
            validations={{
              isRequired: true,
              isAddress: true,
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Input
            name="companyCity"
            label="City / Suburb"
            value=""
            tabIndex="4"
            validationError="City or Suburb is required and must be valid"
            validations={{
              isRequired: true,
              isSpecialWords: true,
              minLength: 2,
            }}
          />
        </Col>
        <Col sm={3}>
          <Input
            name="companyPostcode"
            label="Postcode"
            value=""
            tabIndex="5"
            validationError="Postcode is required and must be valid"
            validations={{
              isRequired: true,
              isNumeric: true,
              isLength: 4,
            }}
          />
        </Col>
        <Col sm={3}>
          <Select
            name="companyState"
            label="State"
            tabIndex="6"
            className="form-control"
            options={[
              { value: 'ACT', label: 'ACT' },
              { value: 'NSW', label: 'New South Wales' },
              { value: 'NT', label: 'Nothern Territory' },
              { value: 'QLD', label: 'Queensland' },
              { value: 'SA', label: 'South Australia' },
              { value: 'TAS', label: 'Tasmania' },
              { value: 'VIC', label: 'Victoria' },
              { value: 'WA', label: 'Western Australia' },
            ]}
          />
        </Col>
      </Row>

      <div className="panel-bar ml-lg-n mr-lg-n mt-md pl-lg">
        <h5 className="panel-title">Aggregator</h5>
      </div>

      <Row>
        <Col sm={12}>
          <Input
            name="aggregatorName"
            id="aggregatorName"
            tabIndex="8"
            validationError="Aggregator must be valid"
            validations={{
              isSpecialWords: true,
            }}
            label={(
            <span>
            {'Aggregator '}
            <small className="mute-md">(optional)</small>
            </span>
            )}
            value=""
          />
        </Col>
      </Row>
    </div>
  );
};

AccreditationCompany.propTypes = {
  handleChange: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AccreditationCompany;
