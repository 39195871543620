import * as React from 'react';
import PropTypes from 'prop-types';

import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import AddressInput from '../addressInput';
import MaskedInput from '../maskedInput';
import Title from '../title';

import Personal from './personal';
import Identification from './identification';
import Contact from './contact';
import Living from './living';
import Dependants from './dependants';

import Mask from '../../../helpers/mask';
import {toOption, toRequired} from '../functions';

import {IOption} from '../../../interfaces';

interface IIndividualState {
}

interface IIndividualProps {
  index: number;
  individuals: any[]; //IIndividual
  individual: any; //IIndividual
  setAskPreviousAddress: (setAskPreviousAddress: boolean) => void;
  changeIndividualName: (index: number, name: string) => void;
  changeDependants: (_, value: number, index: number) => void;
}

export class Individual extends React.Component<IIndividualProps, IIndividualState> {
  get occupancies(): IOption[] {
    return ["Owning", "Renting", "Boarding", "Free accomodation", "With parents", "Other"].map(o => toOption(o));
  }

  render() {
    return (
      <div>
        <Input
          type="hidden"
          name={`individuals[${this.props.index}].id`}
          value={this.props.individual.id} />

        <Personal index={this.props.index} changeIndividualName={this.props.changeIndividualName} />
        <Dependants index={this.props.index} dependants={this.props.individual.dependants} changeDependants={(_, value) => this.props.changeDependants(_, value, this.props.index)} />
        <Identification index={this.props.index} />
        <Contact index={this.props.index} />
        <Living index={this.props.index} askPreviousAddress={this.props.individual.askPreviousAddress} setAskPreviousAddress={this.props.setAskPreviousAddress} />
      </div>
    );
  }
}

export default Individual;
