import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { Input } from 'formsy-react-components';

import MarketingCampaign from '../../helpers/marketingCampaign';


const AccreditationPersonal = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div>
      <div className="panel-bar ml-lg-n mr-lg-n pl-lg">
        <h5 className="panel-title">Name</h5>
      </div>

      <Input
        name="libCampaign"
        type="hidden"
        value={MarketingCampaign.value}
      />

      <Row>
        <Col sm={12}>
          <Input
            name="fullName"
            label="Full name"
            value=""
            validationError="Full name is required"
            validations={{
              isFullName: true,
              isRequired: true,
            }}
          />
        </Col>
      </Row>

      <div className="panel-bar ml-lg-n mr-lg-n mt-md pl-lg">
        <h5 className="panel-title">Contact numbers</h5>
      </div>

      <Row>
        <Col sm={4}>
          <Input
            name="mobile"
            label="Mobile"
            value=""
            validationError="Mobile is required and must be valid"
            validations={{
              isPhone: true,
              isRequired: true,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            name="phone"
            id="phone"
            label={(
              <span>
                {'Phone '}
                <small className="mute-md">(optional)</small>
              </span>
            )}
            value=""
            validationError="Phone and must be valid"
            validations={{
              isPhone: true,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            name="fax"
            id="fax"
            label={(
              <span>
                {'Fax '}
                <small className="mute-md">(optional)</small>
              </span>
            )}
            value=""
            validationError="Fax must be valid"
            validations={{
              isPhone: true,
            }}
          />
        </Col>
      </Row>

      <div className="panel-bar ml-lg-n mr-lg-n mt-md pl-lg">
        <h5 className="panel-title">Email addresses</h5>
      </div>

      <Row>
        <Col sm={6}>
          <Input
            name="email"
            label="Email address"
            value=""
            validationError="Email address must be valid and is required"
            validations={{
              isEmail: true,
              isRequired: true,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            name="emailSecondary"
            label={(
              <span>
                {'Additional email address '}
                <small className="mute-md">(optional)</small>
              </span>
            )}
            id="emailSecondary"
            value=""
            validationError="Email address must be valid"
            validations={{
              isEmail: true,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

AccreditationPersonal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AccreditationPersonal;
