
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { getPrequalResult } from './actions'
import { addPrequal } from '../../actions/calculatorsActions';
import { connect } from 'react-redux';
import { AssessmentResult } from './reducer';

interface MotorPrequalFooterProps {
    id: string,
    convertApplication: () => void;
    showModal: (show: boolean) => void;
    actions: {
        getPrequalResult: typeof getPrequalResult
    },
    assessmentResult: AssessmentResult
}

const buttonStyle: React.CSSProperties = {
    width: '125px',
    textAlign: 'center',
    margin: '0 1rem'
}

export const MotorPrequalFooter = (props: MotorPrequalFooterProps) => (
    <Row>
        <Col sm={12}>
            <div className="text-center" style={{ margin: '25px 0' }}>
                <button style={buttonStyle} onClick={() => props.actions.getPrequalResult()} type="submit" id="btnCalculate" name="btnCalculate" className="btn btn-success btn-lg">Calculate</button>
                <button style={buttonStyle} type="button" className="btn btn-primary btn-lg" onClick={() => props.showModal(true)}>Save</button>
                <button className="btn btn-secondary btn-lg" style={buttonStyle} onClick={props.convertApplication}>Convert</button>
            </div>
        </Col>
    </Row>
)

function mapStateToProps({ motorPrequal }) {
    return {
        assessmentResult: motorPrequal.assessmentResult
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ getPrequalResult, addPrequal }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MotorPrequalFooter);
