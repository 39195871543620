import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';


import { notify as Notify } from 'react-notify-toast';
import Guid from 'guid';

import Event3rdParty from '../../helpers/event3rdParty';

import * as messages from '../../constants/messages';

import { Icon, Overlay } from '../../utils';

import * as calculatorsActions from '../../actions/calculatorsActions';

import CalculatorModal from '../../components/calculatorModal';
import { ProfileType } from '../../actions/prequalActions';
const Moment = require('moment');

export class PrequalLoad extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      visible: false,
      modal: false,
      processing: false,
      query: '',
      prequal: {
        profileName: '',
      },
    };

    this.searchPrequals = this.searchPrequals.bind(this);

    this.showPrequal = this.showPrequal.bind(this);
    this.hidePrequal = this.hidePrequal.bind(this);
    this.loadPrequal = this.loadPrequal.bind(this);
    this.savePrequal = this.savePrequal.bind(this);
    this.copyPrequal = this.copyPrequal.bind(this);

    this.setProcessing = this.setProcessing.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setProfile = this.setProfile.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.actions.searchPrequals(this.state.query);
  }

  setModal(modal) {
    this.setState({
      modal,
    });
  }

  setProcessing(processing) {
    this.setState({
      processing,
    });
  }

  setProfile(data) {
    const { prequal } = this.state;
    prequal.profileName = data.name;

    this.setState({
      prequal,
    }, this.savePrequal);
  }

  searchPrequals(event) {
    this.setState({
      query: event.target.value,
    }, () => {
      this.props.actions.searchPrequals(this.state.query);
    });
  }

  loadPrequal(prequal) {
    let routes = '';
    switch (prequal.profileType) {
      case ProfileType.ResidentialRateQuote:
        routes = 'rate';
        break;
      case ProfileType.ResidentialServiceability:
        routes = 'serviceability';
        break;
      case ProfileType.ResidentialLvr:
        routes = 'lvr';
        break;
      case ProfileType.ResidentialRepayments:
        routes = 'repayments';
        break;
      case ProfileType.MotorServiceability:
        routes = 'motor-serviceability';
        break;
      case ProfileType.MotorRateQuote:
        routes = 'motor-prequalification';
        break;
      case ProfileType.CommercialServiceability:
        routes = 'commercial-serviceability';
        break;
      default:
        break;
    }

    hashHistory.push(`calculators/${routes}/${prequal.profileId}`);
  }

  copyPrequal(event, prequal) {
    prequal = { ...prequal };

    prequal.id = null;
    prequal.lastUpdated = null;
    prequal.profileId = Guid.create().value;
    prequal.profileName = `${prequal.profileName} (copy)`;

    this.setState({
      prequal,
    }, () => {
      this.setModal(true);
    });

    event.stopPropagation();
    return false;
  }

  savePrequal() {
    this.setProcessing('Saving prequalification...');

    // 3rd party
    Event3rdParty.send('copy-prequal');

    this.props.actions.savePrequal(this.state.prequal.profileName, this.state.prequal.value, this.state.prequal.profileId, this.state.prequal.profileType)
      .then(() => {
        Notify.show(messages.MESS_SUCCESS, 'success');
        this.props.actions.searchPrequals(this.state.query);
      })
      .catch()
      .then(() => {
        this.setProcessing(false);
      });
  }

  showPrequal() {
    this.setState({
      visible: true,
    });
  }

  hidePrequal() {
    setTimeout(() => {
      this.setState({
        visible: false,
      });
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="text-center mt-lg pb-lg">
        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>{this.state.processing}</Overlay>
              );
            }
          })()
        }

        <CalculatorModal
          suggestedName={this.state.prequal.profileName}
          visible={this.state.modal}
          setModal={this.setModal}
          setProfile={this.setProfile}
        />

        <Row>
          <Col sm={8} smOffset={2}>
            <div className="form-group text-left">
              <Icon glyph="search" size={2} className="a ml-md" style={{ marginTop: '15px', opacity: '.2' }} />

              <input
                name="test"
                className="form-control form-control-hg"
                onKeyUp={this.searchPrequals}
                onFocus={this.showPrequal}
                placeholder="Search saved prequalifications..."
                style={{ paddingLeft: '48px' }}
              />

              <div className={`form-result ${!this.state.visible ? 'hidden' : ''}`} style={{ marginTop: '-11px' }}>
                {
                  this.props.calculators.prequals.map((prequal) => (
                    <div className="form-result-item" onClick={() => { this.loadPrequal(prequal); }} key={`prequal-${prequal.profileId}`}>
                      <strong>{prequal.profileName}</strong>
                      <small className="b mute">
                        {'Last updated '}
                        {Moment(prequal.lastUpdated).fromNow()}
                      </small>

                      <button type="button" className="btn btn-link" onClick={(event) => { this.copyPrequal(event, prequal); }}>
                        <span>Copy</span>
                        {' '}
                        <Icon size="lg" glyph="copy" />
                      </button>
                    </div>
                  ))
                }
                <div className="form-result-item nohover empty">No results</div>
              </div>
              <div className={`form-result-overlay ${!this.state.visible ? 'hidden' : ''}`} onClick={this.hidePrequal} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

PrequalLoad.propTypes = {
  actions: PropTypes.object.isRequired,
  calculators: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    calculators: state.calculators,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...calculatorsActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrequalLoad);
