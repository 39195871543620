import Mask from '../../../helpers/mask';

import {ILoanSplit, IProperty, IGuid, IAddress, ISpecificAsset, ILiabilityType, IIndividual, IFormData, IPersonalUse} from '../interfaces';

function getSecurities(properties: IProperty[]): IProperty[] {
    return properties.filter(asset => asset.security);
  }
  
  function getProperties(specificAssets: ISpecificAsset[] = [], purchases: IProperty[] = []): IProperty[] {
    let properties: IProperty[] = [];
    specificAssets.forEach(asset => {
      if (asset.type == "property") {
        properties.push(asset);
      }
    });
  
    return [
      ...purchases,
      ...properties, 
    ];
  }
  
  function isOwning(individual): boolean {
    return ["owning"].indexOf(individual.currentAddress.occupancy) > -1;
  }
  
  function calculateSecurityAmount(properties: IProperty[]): number {
    return getSecurities(properties).reduce((value, security) => {
      return value += Number(Mask.clean(security.value));
    }, 0);
  }
  
  function calculateLoanAmount(purchases: IProperty[] = [], uses: IPersonalUse, liabilities): number {
    return newPurchasesAmount(purchases) + refinancingAmount(liabilities) + personalUseAmount(uses);
  }
  
  function calculateLvrPercentage(loanAmount: number, securityAmount: number): string {
    return ((loanAmount / securityAmount) * 100).toFixed(2);
  }
  
  function newPurchasesAmount(purchases: IProperty[]) {
    return purchases.reduce((value, purchase) => {
      return value += (Number(Mask.clean(purchase.value)) - contributedAmount(purchase));
    }, 0)
  }
  
  function contributedAmount(purchase) {
    return ["Deposit", "Gifts", "FHOG", "Finance", "Other"].reduce((value, field) => {
      return value += Number(Mask.clean(purchase[field.toLowerCase()]));
    }, 0);
  }

  function personalUseAmount(uses: IPersonalUse): number { 
    return ["buyConsumerGoods", "cashOut", "other", "payBills", "travel"].reduce((value, use) => {
      return value += uses[use].amount ? Number(Mask.clean(uses[use].amount)) : 0
    }, 0);
  }
  
  function refinancingAmount(liabilities: any[] = []): number { 
    return liabilities.reduce((value, liability) => totalRefinanceAmount(value, liability), 0);
  }

  function totalRefinanceAmount(value, liability) {
    return value += liability.refinance ? Number(Mask.clean(liability.balance)) : 0;
  }
  
  function getResidencies(properties: IProperty[]): IAddress[] {
    return properties
      .filter(property => property.use == "residence")
      .map(property => property.address);
  }
  
  function getOccupancies(individuals: IIndividual[]): IAddress[] {
    return individuals
      .filter(individual => !isOwning(individual))
      .map(occupancy => occupancy.currentAddress);
  }
  
  function getResidenceOptions(properties, individuals): IAddress[] {    
    let residencies = getResidencies(properties);
    let occupancies = getOccupancies(individuals);
    return [...residencies, ...occupancies];
  }

  export { getProperties, calculateLoanAmount, calculateSecurityAmount, calculateLvrPercentage, getResidenceOptions };