import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'formsy-react-components';
import Formsy from 'formsy-react';
import { Row, Col } from 'react-bootstrap';
import * as referenceActions from '../../actions/referenceActions';
import ReferenceApi from '../../api/referenceApi';
import { FUELTYPES, PRODUCTGROUPS } from '../../constants/types';

type ApplicationUpdateNvicSearchProps = {
  isRequired: boolean,
  application: any,
  session: any,
  actions: any,
  reference: any,
  formsy: Formsy | null,
  getValue: (input: string) => any,
  setMessage: (input: string | null) => any,
}

type ApplicationUpdateNvicSearchState = {
  query: string,
  fuelType: string,
  description: string | null,
}

export class ApplicationUpdateNvicSearch extends React.Component<ApplicationUpdateNvicSearchProps, ApplicationUpdateNvicSearchState> {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      fuelType: '',
      description: null,
    };

    this.getNvics = this.getNvics.bind(this);
    this.setAppraisalOnSearch = this.setAppraisalOnSearch.bind(this);
    this.setNvicAndAppraisal = this.setNvicAndAppraisal.bind(this);
    this.setNvic = this.setNvic.bind(this);
    this.setInitialAppraisal = this.setInitialAppraisal.bind(this);
  }

  componentDidMount() {
    if (this.props.application?.motorPurchaseVehicle?.nvic) {
      this.setInitialAppraisal();
      this.props.actions.getNvics(
        this.props.application?.motorPurchaseVehicle?.nvic, 
        this.props.application.productGroupId);
    }
  }

  componentDidUpdate(prevProps: ApplicationUpdateNvicSearchProps) {
    if (prevProps.reference.nvics !== this.props.reference.nvics && !this.state.query) {
      if(this.props.reference.nvic){
        this.setState({       
          description: `${this.props.reference.nvic.year} ${this.props.reference.nvic.make} ${this.props.reference.nvic.model} ${this.props.reference.nvic.body}`,
        });
        this.props.setMessage('Vehicle updated!');
      };

      const allnvics: any = [];
      this.props.reference?.nvics?.forEach((groupedItems: any) => {
        groupedItems.items.forEach((item: any) => {
          allnvics.push(item);
        });
      });
      const nvicDetails = allnvics?.find((n) => n.nvic === this.props.application?.motorPurchaseVehicle.nvic);
      nvicDetails && this.setNvicAndAppraisal(nvicDetails);
    }
  }
  setInitialAppraisal() {
    if (this.props.application?.motorPurchaseVehicle.nvic) {
      this.props.actions.getAppraisal(
        this.props.application?.motorPurchaseVehicle.nvic,
        this.props.application?.motorPurchaseVehicle.odometer, !this.props.application?.motorPurchaseVehicle.isUsed,
        this.props.application.productGroupId,
      );
    }
  }
  getNvics(event) {
    const query = event.target.value;

    if (query.length > 2) {
      this.props.actions.getNvics(event.target.value, this.props.application?.productGroupId);
      this.setState({
        query,
        fuelType: '',
        description: null,
      });
       
    }
    this.setFormsyValue('registrationNumberInput', ''); 
    this.setFormsyValue('registrationStateInput', ''); 
    this.setFormsyValue('vinInput', ''); 
    
  }

  setNvicAndAppraisal(nvicDetails) {
    this.setAppraisalOnSearch(nvicDetails.nvic);
    this.setNvic(nvicDetails);
  }

  setAppraisalOnSearch(nvic) {
    const isNew = !this.props.getValue('motorPurchaseVehicle.isUsed') || 0;
    const odometer = this.props.getValue('motorPurchaseVehicle.odometer') || 0;

    this.props.actions.getAppraisal(nvic, odometer, isNew, this.props.application?.productGroupId);
  }

  setFormsyValue(field, value) {
    if (this.props.formsy) {
      const item = this.props.formsy.inputs.filter((input) => input.props.id == field)[0];
      if(item){
        item.setValue(value);
      }
    }
  }

  setNvic(details) {
    if (details) {
      this.setFormsyValue('nvicInput', details.nvic);
      this.setFormsyValue('motorPurchaseVehicle.nvic', details.nvic);
      this.props.actions.setNvic(details);

      this.setFormsyValue('motorPurchaseVehicle.isMotorbike', false);

      let nvic = this.props.application?.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN ? `CV-${details.nvic}` : details.nvic;
      if(details.isMotorbike)
      {
        nvic = `MB-${details.nvic}`;
        this.setFormsyValue('motorPurchaseVehicle.isMotorbike', true)
      }
      ReferenceApi.getNvic(nvic)
        .then((response) => response.data[0])
        .then((nvicDetails) => {
          this.setFormsyValue('motorPurchaseVehicle.year', nvicDetails.year);
          this.setFormsyValue('motorPurchaseVehicle.makeId', nvicDetails.makeId);
          this.setFormsyValue('motorPurchaseVehicle.modelId', nvicDetails.modelId);
          this.setFormsyValue('motorPurchaseVehicle.bodyTypeId', nvicDetails.bodyTypeId);
          this.setFormsyValue('motorPurchaseVehicle.transmissionTypeId', nvicDetails.transmissionTypeId);
          this.setFormsyValue('motorPurchaseVehicle.make', nvicDetails.make);
          this.setFormsyValue('motorPurchaseVehicle.model', nvicDetails.model);
        });

      this.setState({
        query: '',
        fuelType: details.fuelType,
        description: `${details.year} ${details.make} ${details.model} ${details.body}`,
      });
      
    }
  }
 
  render() {
    return (
      <>
        <Row>
          <Col sm={this.state.description ? 4 : 10}>
          {this.props.isRequired ?
              <Input
                id="nvicInput"
                name="nvicInput"
                type="text"
                label="What type of vehicle is it?"
                placeholder="search by year, make, model"
                value=""
                autoComplete="off"
                onKeyUp={this.getNvics}
                onFocus={this.getNvics}
                validationError="Vehicle is required"
                validations={{
                  isRequired: false,
                  nvicHasBeenPicked: (values, _) => values["motorPurchaseVehicle.nvic"] == values["nvicInput"]
                }}
              /> :
              <Input
                id="nvicInput"
                name="nvicInput"
                type="text"
                label="What type of vehicle is it?"
                placeholder="search by year, make, model"
                value=""
                autoComplete="off"
                onKeyUp={this.getNvics}
                onFocus={this.getNvics}
              />}
                <Input 
                    type="hidden"
                    id="motorPurchaseVehicle.nvic" 
                    name="motorPurchaseVehicle.nvic"/>
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.year"
                    name="motorPurchaseVehicle.year"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.makeId"
                    name="motorPurchaseVehicle.makeId"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.modelId"
                    name="motorPurchaseVehicle.modelId"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.bodyTypeId"
                    name="motorPurchaseVehicle.bodyTypeId"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.transmissionTypeId"
                    name="motorPurchaseVehicle.transmissionTypeId"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.make"
                    name="motorPurchaseVehicle.make"
                  />
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.model"
                    name="motorPurchaseVehicle.model"
                  />           
                  <Input
                    type="hidden"
                    id="motorPurchaseVehicle.isMotorbike"
                    name="motorPurchaseVehicle.isMotorbike"                    
                  />
          </Col>
          {
            (this.state.description && this.props.reference.nvic) && (
              <Col sm={8}>
                <div className="form-group">
                  <label className="control-label">Description</label>
                  <div className="form-control" disabled>{this.state.description}</div>
                </div>
              </Col>
            )
          }
        </Row>
        <Row>
          {
            Object.keys(FUELTYPES).some(x => FUELTYPES[x] === this.state.fuelType) &&
            this.props.session.details.isGreenUser &&
            <Col sm={8}>
              <div className='form-group'>
                <div className="badge badge-green-offerring">
                  The vehicle selected is eligible for a 'green' rate discount
                  <img src={`/${window['iqUIVersion'] || '.'}/assets/img/tree.svg`} />
                </div>
              </div>
            </Col>
          }
        </Row>
        {
          (() => {
            if (this.state.query && this.props.reference.nvics) {
              return (
                <div className="form-result form-scrollable" style={{ padding: '0px' }}>
                  {
                    this.props.reference.nvics.map((groupedItem) => (
                      <div className="form-result-item-vehicle" key={groupedItem.groupHeader}>
                        <div className="form-result-item-vehicle-title">{`${groupedItem.groupHeader}`}</div>
                        {
                          groupedItem.items.map((nvic) => (
                            <div className="form-result-item-vehicle-subtitle" key={nvic.nvic} onClick={() => { this.setNvicAndAppraisal(nvic); }}>
                              <div className="form-result-item-vehicle-subtitledetails">
                                <strong>
                                  {`${nvic.variant}`}
                                </strong>
                                {`${nvic.category}`}
                              </div>
                              <div className="form-result-item-vehicle-nvic">{`${nvic.nvic}`}</div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              );
            }
          })()
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateNvicSearch);
