import * as React from 'react';
import PropTypes from 'prop-types';
import * as Moment from 'moment';
import { Link } from 'react-router';
import API from '../../api';
import {Avatar} from '../../utils';
import {SuccessAlert, WarningAlert} from '../../utils/alert';

import {IApplicationType, IApplicationNumber, IDetails} from '../../interfaces';

interface IApplicationMessageProps {
	message: IApplicationMessage;
	me: IDetails;
	id: IApplicationNumber;
	type: IApplicationType;
	setTab: (tab: string) => void;
}

interface IApplicationMessageState {
	style: any;
	staffId: number | null;
	contact: any | null;
}

interface IApplicationMessage {
	staffId: number;
	userId: number;
	textFormat: ITextData[];
	updatedDate: Date;
	introducerAction: boolean;
	introducerActionTypes: IApplicationActionType[];
}

enum IApplicationActionType {
	UploadDocuments = "UPLOAD_DOCS",
	PostDocuments = "SEND_DOCS",
	UpdateIndicative = "UPDATE_INDICATIVE"
}

interface ITextData {
  data: {
    introducer?: string
    dueDate: Date;
  };
  text: string;
  pastText: string;
}

export default class ApplicationMessage extends React.Component<IApplicationMessageProps, IApplicationMessageState> {
  constructor(props) {
    super(props, {});
    this.state = {
      style: {},
      staffId: null,
      contact: null
    };

    this.getContact = this.getContact.bind(this);
  }

  componentDidMount() {
    if(this.props.message && this.state.staffId !== this.props.message.userId)
      this.getContact(this.props.message.userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.message && this.state.staffId !== nextProps.message.userId)
      this.getContact(nextProps.message.userId);
  }

  getContact(staffId: number) {
    if (staffId) {
      API.getContactsDetails([staffId])
        .then(resp => {
          if(this.props.message.userId === staffId)
            this.setState({contact: resp.data[0], staffId});
        });


    }
  }

  getUnit(applicationType: IApplicationType): Moment.unitOfTime.Diff {
    switch (applicationType) {
        case IApplicationType.Motor:
            return "minutes"
        case IApplicationType.Residential:
        case IApplicationType.BusinessCapital:
        case IApplicationType.Commercial: {
          return "days"
        }
    }
  }

  getCalendarSpec(applicationType: IApplicationType): Moment.CalendarSpec {
    switch (applicationType) {
			case IApplicationType.Motor: {
					return {
							sameDay: 'h:mma [today]',
							nextDay: 'h:mma [tomorrow]',
							nextWeek: 'dddd',
							lastDay: 'dddd',
							lastWeek: 'dddd',
							sameElse: 'DD MMM YYYY'
					};
			}

			case IApplicationType.Residential:
      case IApplicationType.BusinessCapital:
			case IApplicationType.Commercial: {
				return {
					sameDay: '[by] dddd',
					nextDay: '[by] dddd',
					nextWeek: '[by] dddd',
					lastDay: '[by] dddd',
					lastWeek: '[by] dddd',
					sameElse: '[by] DD MMM YYYY'
				};
			}
    }
  }

  _formatText(textData: ITextData, applicationType: IApplicationType) {
    let dateConfig = this.getCalendarSpec(applicationType);
    let unit = this.getUnit(applicationType);
    let pattern = /{(\w+)?}/g;

    let text;
    if (textData.data && textData.data.dueDate && textData.pastText && (Moment().diff(Moment(textData.data.dueDate), unit) > 0)) {
      text = textData.pastText;
    } else {
      text = textData.text;
    }

    const formatted = text.replace(pattern, (match, group) => {
      return group === 'introducer' ? this.props.me.firstName : Moment(textData.data[group]).calendar(undefined, dateConfig)
    });

    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  render() {
    if (!this.props.message || this.props.message.textFormat.length == 0)
      return null;

    const Alert = this.props.message.introducerAction ? WarningAlert : SuccessAlert;

    return (
      <div className="bubble-container clearfix">

      <Avatar contact={this.state.contact} />

      <div className="last-updated-date">
        <span className="mute">{Moment(this.props.message.updatedDate).fromNow()}</span>
      </div>

      <Alert>
        <div>
            {
              this.props.message.textFormat.map((textData, index) => (
                <p key={`bubble-message-${index}`}>
                  { this._formatText(textData, this.props.type) }{ ['.','!','?'].indexOf(textData.text.slice(-1)) < 0 ? '.' : '' }
                </p>)
              )
            }

            <div className="text-right mt-md pt-md">
              {
                (() => {
                  if (this.props.message.introducerAction && this.props.message.introducerActionTypes && this.props.message.introducerActionTypes.length) {
                    return this.props.message.introducerActionTypes.map((action, index) => {
                      switch (action) {
                        case IApplicationActionType.UploadDocuments:
                        case IApplicationActionType.PostDocuments:
                          return (
                            <button key={`action-btn-${index}`} id="view-outstanding-actions" onClick={() => this.props.setTab('actions')}className="btn btn-warning">View outstanding actions</button>
                          );

                        case IApplicationActionType.UpdateIndicative:
                          return (
                            <Link key={`action-btn-${index}`} to={`/applications/${this.props.id}/update/finance`} id="update-indicative" className="btn btn-warning">Update indicative</Link>
                          );
                      }
                    });
                  }
                })()
             }
            </div>
        </div>
      </Alert>
      <div className="split" />
      </div>
    );
  }
}
