import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import SearchPane from '../../components/searchPane';
import EmptyPane from '../../components/emptyPane';

import { Icon } from '../../utils';

import * as faqsActions from '../../actions/faqsActions';

import FaqGroup from './faqGroup';

export class FaqsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      show: false,
      filter: 1,
    };

    this.onSearch = this.onSearch.bind(this);

    this.reduceFaqs = this.reduceFaqs.bind(this);
    this.filterFaqs = this.filterFaqs.bind(this);
  }

  componentDidMount() {
    this.getFaqs();
  }

  getFaqs() {
    this.props.actions.getFaqs();
  }

  onSearch(event) {
    this.setState({
      query: event.target.value,
    });
  }

  reduceFaqs(filter) {
    this.setState({
      filter,
    });
  }

  filterFaqs(faq, status) {
    let filter = true;

    if (this.state.query) {
      const regExp = new RegExp(this.state.query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
      filter = (faq.question.search(regExp) !== -1 || faq.answer.search(regExp) !== -1);
    }

    if (status != 0 && faq.status.indexOf(status) == -1) {
      filter = false;
    }

    if (this.state.filter && faq.type.indexOf(this.state.filter) == -1) {
      filter = false;
    }

    return filter;
  }

  render() {
    return (
      <div>
        <div className="mt-md mb-md">
          <Row>
            <Col sm={8} smOffset={2}>
              <div className="tab-container mt-sm mb-lg">
                <a className={`tab residential ${this.state.filter == 1 ? 'active' : ''}`} onClick={(() => { this.reduceFaqs(1); })}>
                  <Icon glyph="home" size={2} className="mute-lg" />
                  <p className="hidden-xs">Residential</p>
                </a>
                <a className={`tab motor ${this.state.filter == 2 ? 'active' : ''}`} onClick={(() => { this.reduceFaqs(2); })}>
                  <Icon glyph="directions-car" size={2} className="mute-lg" />
                  <p className="hidden-xs">Motor</p>
                </a>
                <a className={`tab commercial ${this.state.filter == 5 ? 'active' : ''}`} onClick={(() => { this.reduceFaqs(5); })}>
                  <Icon glyph="city-alt" size={2} className="mute-lg" />
                  <p className="hidden-xs">Commercial</p>
                </a>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={8} smOffset={2} className="mb-sm">
              <SearchPane query={this.state.query} placeholder="Search FAQs" onSearch={this.onSearch} />
            </Col>
          </Row>
        </div>

        {
          (() => {
            if (this.state.query) {
              return (
                <div>
                  {
                      (() => {
                        if (!this.props.faqs.filter((item) => this.filterFaqs(item, 0)).length) {
                          return (
                            <div className="panel panel-default">
                              <div className="panel-body">
                                <EmptyPane query={this.state.query} item="FAQs" />
                              </div>
                            </div>
                          );
                        }
                        return (
                            <FaqGroup faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 0))} />
                        );
                      })()
                    }
                </div>
              );
            }
            return (
                <div>
                  <FaqGroup title="Documents" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 9))} />
                  <FaqGroup title="Submission" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 1))} />
                  <FaqGroup title="Assessment" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 2))} />
                  <FaqGroup title="Valuation" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 5))} />
                  <FaqGroup title="Settlement" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 3))} />
                  <FaqGroup title="Post Settlement" faqs={this.props.faqs.filter((item) => this.filterFaqs(item, 6))} />
                </div>
            );
          })()
        }
      </div>
    );
  }
}

FaqsPage.propTypes = {
  faqs: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    faqs: state.faqs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(faqsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqsPage);
