import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export class TermsModal extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm mute">
            <h4 className="mb-lg">Terms of Use</h4>
            <p>You have accessed the Liberty IQ portal. Liberty IQ is owned by Liberty Financial Pty Ltd (ABN 55 077 248 983) (Liberty) and is operated by Liberty and other companies in the Liberty Group. Your use of Liberty IQ is governed by these Terms of Use and the Universal Introducer Agreement (UIA) you have entered into with Liberty. In the event of any inconsistency between these Terms of Use and the UIA, the UIA will prevail.</p>

            <ul>
              <li>We reserve the right to update and amend Liberty IQ (including all information, graphics, materials and these Terms of Use) at any time without notice.</li>
              <li>You must not disclose information relating to a customer to any other person unless that person is a joint applicant for the same product or you are otherwise required by law to make this disclosure.</li>
              <li>You must not allow your customers to have access to Liberty IQ or show them the information presented on Liberty IQ.</li>
              <li>You agree that you will not send or disclose your username or password to any other person and will not let any other person access your account.</li>
              <li>You must immediately notify Liberty by calling 13 11 33 if you know or suspect that your username and password have been compromised, become known to a third party, or if you know or suspect any unauthorised use of your account.</li>
              <li>You consent to Liberty sending you email, SMS and/or social media notifications about the status of applications you have submitted to Liberty.</li>
              <li>Applications for credit are subject to Liberty’s lending criteria policy applicable at the time of the application.</li>
              <li>While every effort is made to ensure the information on Liberty IQ is accurate, Liberty does not make any representation or provide any warranty as to the accuracy, adequacy or completeness of the information.</li>
              <li>Neither Liberty nor any other company in the Liberty Group nor any of their directors, employees and associates guarantees the security of this website and is not liable to you (whether in contract, tort, negligence or for breach of statute or otherwise) for any losses (including lost commissions), damages, liabilities, claims and expenses (including legal costs) you may suffer or incur in relation to your use of Liberty IQ including the availability or non-availability of Liberty IQ.</li>
              <li>You agree to indemnify Liberty Financial and keep it indemnified against all actions, claims, costs, demands, damages or liability arising in any manner from a breach by you of these Terms of Use.</li>
              <li>Copyright in the material on Liberty IQ is owned by Liberty. All rights are reserved. You may reproduce this information in hard copy as long as any such use is limited to purposes directly related to your role. The information may not otherwise be reproduced and must not be distributed or transmitted to any other person or incorporated in any way into another document or other material.</li>
              <li>Unauthorised use of Liberty and its registered trademarks is prohibited.</li>
              <li>Mortgage Protection Insurance, Loan Protection Insurance and Vehicle Equity Insurance  are issued by LFI Group Pty Ltd ABN 31 138 903 581 AFSL 413613. Customers must be provided with the relevant Product Disclosure Statement before purchasing any of these products. Liberty is a business partner of LFI. Liberty does not itself underwrite insurance products or services.</li>
            </ul>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

TermsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};


export default TermsModal;
