import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input} from 'formsy-react-components';
import Mask from '../../../helpers/mask';

import Title from '../title';

import {liabilityOptions} from '../options';
import {ILoanLiability} from '../interfaces';

interface IRefinanceProps {
    liability: ILoanLiability;
    index: number;
}
interface IRefinanceState {}

export class Refinance extends React.Component<IRefinanceProps, IRefinanceState> {
    UNSAFE_componentWillMount() {
        this.state = {};
    }

    render() {
        return (
            <div className={`card p-lg mt-lg`}>
                <Title top>
                    {liabilityOptions.filter(option => option.value == this.props.liability.type)[0].label}
                </Title>

                <Input
                    name={`refinances[${this.props.index}].liability`}
                    type="hidden"
                    value={this.props.liability.id}
                />

                <Row>
                    <Col sm={6}>
                        <Input
                            name={`refinances[${this.props.index}].lender`}
                            label="Lender"
                            value={this.props.liability.lender}
                            disabled />
                    </Col>

                    <Col sm={3} smOffset={3}>
                        <div className="form-prefix">$</div>
                        <Input
                            name={`refinances[${this.props.index}].amount`}
                            label="Amount"
                            value={this.props.liability.balance}
                            className="form-control text-right"
                            disabled />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Refinance;
