import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Mask from '../../helpers/mask';

import { Icon } from '../../utils';
import Percentage from '../../utils/percentage';

export default class ServiceAbilityResult extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      fineprint: false,
    };

    this.getRate = this.getRate.bind(this);
  }

  getRate() {
    return !this.props.result.reason;
  }

  getRatePercentage(percentage) {
    if (this.getRate()) {
      return (percentage / this.props.result.totalRate) * 100;
    }
    return 100;
  }

  getColor(index) {
    switch (index) {
      case 0: return 'primary';
      case 1: return 'success';
      case 2: return 'warning';
      case 3: return 'danger';
    }
  }

  parseRiskGrade(riskGrade) {
    if (this.getRate() && riskGrade) {
      return riskGrade.replace('_Minus', '-').replace('_Plus', '+'); 
    }
    return '';
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-body panel-section-body-tint">
          <div className="panel-section-container text-center">
            {
              (() => {
                if (this.props.result) {
                  return (
                    <Row>
                      <Col sm={8} smOffset={2}>
                        <div className={`card text-left pb-h mb-lg mt-md ${this.props.invalid ? 'mute-lg' : ''}`}>

                          <div className="text-center mt-lg">
                            {
                              (() => {
                                if (this.getRate() && this.props.result.baseRate) {
                                  return (
                                    <h5 className="text-lg mute-md">
                                      Please contact your Business Development Manager or Liberty Financial on 13 11 33 to discuss.
                                    </h5>
                                  );
                                }
                              })()
                            }
                            <Icon glyph="assignment-o" className="mute-lg" size={6} />

                            {
                              (() => {
                                if (!this.props.result.baseRate && !this.props.result.reason) {
                                  return (
                                    <Icon glyph="help-outline" className="primary mute-lg a mt-lg" size={3} style={{ left: 0, right: 0 }} />
                                  );
                                }

                                return (
                                  <Icon glyph={this.getRate() ? 'check' : 'close'} className={`text-${this.getRate() ? 'success' : 'danger'} a mt-lg`} size={3} style={{ left: 0, right: 0 }} />
                                );
                              })()
                            }

                            <h3>
                              {this.getRate() ? 'Interest rate' : 'No interest rate available'}
                            </h3>

                            { this.getRate() && !!this.props.result.totalRate && <h2 className="mute mt-h mb-sm" style={{ fontSize: '36px' }}><Percentage>{this.props.result.totalRate}</Percentage></h2>}
                            { this.getRate() && (
                            <h5 className={`text-lg mute-md ${!this.props.result.baseRate ? 'transparent' : ''}`}>
                              <Percentage>{(this.props.result.lVR)}</Percentage>
                              {' LVR'}
                            </h5>
                            )}
                            { this.getRate() && (
                              <h5 className={`text-lg mute-md ${!this.props.result.riskGrade ? 'transparent' : ''}`}>
                              {(this.parseRiskGrade(this.props.result.riskGrade))}
                              {' Risk Grade'}
                              </h5>
                            )}
                            { !this.getRate() && (
                            <h5 className="text-lg mute-md">
                              Please contact your Business Development Manager or Liberty Financial on 13 11 33 to discuss this scenario.
                            </h5>
                            ) }
                          </div>

                          <Row className={!this.props.result.baseRate ? 'hidden' : ''}>
                            <Col sm={10} smOffset={1}>
                              <div className="progress mb-md mt-md">
                                <div data-tip="Base rate" data-event-on="hover" className={`progress-bar progress-bar-${this.getRate() ? 'secondary' : 'danger'}`} style={{ width: `${this.getRatePercentage(this.props.result.baseRate)}%` }} />

                                {
                                  this.props.result.loadings && Object.keys(this.props.result.loadings).map((key, index) => (
                                      <div key={key} data-tip={Mask.ucfirst(key)} data-event-on="hover" className={`progress-bar progress-bar-${this.getColor(index)}`} style={{ width: `${this.getRatePercentage(this.props.result.loadings[key])}%` }} />
                                  ))
                                }
                              </div>
                            </Col>
                          </Row>

                          {
                            (() => {
                              if (this.getRate()) {
                                return (
                                  <div className="ml-md-n mr-md-n">
                                    <table className={`table table-lg table-hover mt-md mb-h ${!this.props.result.baseRate ? 'hidden' : ''}`}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <span className="dot dot-secondary mr-sm" />
                                            Base Rate
                                          </td>
                                          <td className="text-right">
                                            <strong><Percentage round={2}>{this.props.result.baseRate}</Percentage></strong>
                                          </td>
                                        </tr>

                                        {
                                          this.props.result.loadings && Object.keys(this.props.result.loadings).map((key, index) => (
                                            <tr key={key}>
                                              <td>
                                                <span className={`dot dot-${this.getColor(index)} mr-sm`} />
                                                {Mask.ucfirst(key.replace(/([A-Z])/g, ' $1'))}
                                              </td>
                                              <td className="text-right">
                                                <strong><Percentage round={2}>{this.props.result.loadings[key]}</Percentage></strong>
                                              </td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                );
                              }

                              return <br />;
                            })()
                          }

                        </div>
                      </Col>
                    </Row>
                  );
                }
              })()
            }

            <div className="text-right np">
              {/* Disabling save functionality for now. Works when isDev and sent to RQ API; payload is the wrong format when isDev = false and sent to IQ Auth. */}
              {/* <button type="button" className="btn btn-default btn-simple btn-lg mr-sm" onClick={() => { this.props.savePrequal(); }}>Save</button> */}
              <button type="submit" className="btn btn-success btn-lg hidden-xs pull-right">Calculate</button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
