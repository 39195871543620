import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, hashHistory } from 'react-router';
import { notify as Notify } from 'react-notify-toast';
import { Form, Input } from 'formsy-react-components';
import { Col } from 'react-bootstrap';

import Event3rdParty from '../../helpers/event3rdParty';
import * as sessionActions from '../../actions/sessionActions';

import InfoPane from '../../components/infoPane';

/**
 * Import others
 */
import { Overlay } from '../../utils';

export class ResetPage extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      step: 1,
      processing: false,
      creds: {
        resetUsername: this.props.params.username || '',
      },
    };

    this.doReset = this.doReset.bind(this);
    this.doResetToken = this.doResetToken.bind(this);

    this.startProcessing = this.startProcessing.bind(this);
    this.stopProcessing = this.stopProcessing.bind(this);
  }

  doResetToken(event) {
    this.startProcessing();
    const promise = this.props.actions.doResetToken({
      username: event.resetUsername,
    });

    promise
      .then((response) => {
        this.stopProcessing();
        Notify.show(`Your reset token has been sent to your mobile ending with ${response.data.mobile}!`, 'success', 3000);

        // Hotjar trigger
        Event3rdParty.send('reset-password');

        this.setState({
          step: 2,
        });
      })
      .catch(() => {
        this.stopProcessing();
        Notify.show('Your password cannot be reset. Please contact us on 13 11 33...', 'error', 3000);
      });
  }

  doReset(event) {
    this.startProcessing();
    const promise = this.props.actions.doReset({
      username: event.resetUsername,
      password: event.resetPassword,
      token: event.resetCode,
    });

    promise
      .then(() => {
        this.stopProcessing();
        Notify.show('Your password has been reset!', 'success', 3000);

        hashHistory.push('/login');
      })
      .catch((error) => {
        this.stopProcessing();

        switch (error.response.status) {
          case 403:
            Notify.show('Your username or reset token is not valid. Please try again..', 'error', 3000);
            break;

          default:
            Notify.show('Your password cannot be reset. Please contact us on 13 11 33...', 'error', 3000);
            break;
        }

        if (error.response.status === 403) { Notify.show('Your password cannot be reset. Please contact us on 13 11 33...', 'error', 3000); }
      });
  }

  startProcessing() {
    this.setState({
      processing: true,
    });
  }

  stopProcessing() {
    this.setState({
      processing: false,
    });
  }

  render() {
    return (
      <div>
        <div className="text-center mt-lg mb-lg">
          <Link to="/login">
            <img alt="" src={`/${window.iqUIVersion}/assets/img/logo-liberty-financial.png`} className="mt-lg ml-md-n" width="180" />
          </Link>
        </div>
        <div className="panel panel-fixed panel-default mb-lg">

          <div className="panel-body p-lg">
            <h2 className="mb-md mt-sm text-center">Reset password</h2>

            {
              (() => {
                if (this.state.step === 1) {
                  return (
                    <Form
                      id="loginForm"
                      layout="vertical"
                      onValidSubmit={this.doResetToken}
                      onChange={this.handleChange}
                    >
                      <Col sm={12}>
                        <Input
                          id="resetUsername"
                          name="resetUsername"
                          type="text"
                          label="Username"
                          placeholder="your LoanNet ID or email"
                          validationError="Username is required"
                          autoFocus={true}
                          validations={{
                            isRequired: true,
                            maxLength: 255,
                          }}
                        />

                        <button
                          className="btn btn-primary btn-lg btn-block btn-center loginButton"
                          id="resetButton"
                        >
                        Request reset token
                        </button>
                        {
                            (() => {
                              if (this.state.processing) {
                                return (
                                  <Overlay>Requesting token...</Overlay>
                                );
                              }
                            })()
                          }
                      </Col>
                    </Form>
                  );
                }
                return (
                  <Form
                    id="loginForm"
                    layout="vertical"
                    onValidSubmit={this.doReset}
                  >
                    <Col sm={12}>
                      <Input
                        id="resetUsername"
                        name="resetUsername"
                        type="text"
                        disabled
                        label="Username"
                        placeholder="Email address or LoanNET ID"
                        validationError="Username is required"
                        autoFocus
                        validations={{
                          isRequired: true,
                          maxLength: 255,
                        }}
                      />

                      <Input
                        id="resetCode"
                        name="resetCode"
                        type="password"
                        label="Reset token"
                        validationError="Reset token is required"
                        autoFocus
                        validations={{
                          isRequired: true,
                          maxLength: 255,
                        }}
                      />

                      <Input
                        id="resetPassword"
                        name="resetPassword"
                        type="password"
                        label="New password"
                        validationError="Password is required (4 digits)"
                        validations={{
                          isRequired: true,
                          maxLength: 255,
                        }}
                      />

                      <button
                        className="btn btn-primary btn-center btn-lg btn-block loginButton"
                        id="resetButton"
                      >
                            Reset password
                      </button>

                      {
                          (() => {
                            if (this.state.processing) {
                              return (
                                <Overlay>Resetting password...</Overlay>
                              );
                            }
                          })()
                      }
                    </Col>
                  </Form>
                );
              })()
            }
          </div>
          <div className="panel-footer pt-lg pb-lg pl-h pr-h">
            <InfoPane action="resetting" />
          </div>
        </div>
      </div>
    );
  }
}

ResetPage.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPage);
