import * as React from 'react';
import PropTypes from 'prop-types';
import {RadioGroup, Textarea} from 'formsy-react-components';

import {toRequired} from '../functions';

import {IOption} from '../../../interfaces';

interface IQuestionProps {
  label: string,
  name: string,
  options: IOption[]
}

interface IQuestionState {
  answer: IAnswer;
}

type IAnswer = "yes" | "no";

export class Question extends React.Component<IQuestionProps, IQuestionState> {
  constructor(props) {
    super(props);

    this.state = {
      answer: "no"
    };
  }

  shouldAskExplanation(answer: IAnswer): boolean {
    return this.state.answer == "yes";
  }

  changeAnswer(_, answer: IAnswer) {
    this.setState({answer});
  }

  render() {
    return (
      <fieldset className="form-wrap mt-md pb-md">
        <RadioGroup
          name={this.props.name + ".question"}
          id={this.props.name + ".question"}
          ref={this.props.name + ".question"}
          label={toRequired(this.props.label)}
          rowClassName="form-group-inline"
          options={this.props.options.map(o => ({
            value: o.value,
            label: <span>{o.label}</span>
          }))}
          changeCallback={this.changeAnswer.bind(this)}
          required
        />

        <div className={`bg-primary-50 ml-lg-n mr-lg-n pl-lg mb-md-n pr-lg pt-md pb-sm ${this.shouldAskExplanation(this.state.answer) ? '' : 'hidden'}`}>
          <Textarea
            name={this.props.name + ".explanation"}
            id={this.props.name + ".explanation"}
            ref={this.props.name + ".explanation"}
            label={toRequired("Explanation")}
            validations={{
              isRequiredWhenYes: (_, value) => {
                if (this.shouldAskExplanation(this.state.answer)) {
                  return value != "";
                }
                return true;
              }
            }}
            validationErrors={{
              isRequired: "Explanation is required"
            }}
            value=""
          />
        </div>

      </fieldset>
    );
  }

}

export default Question;
