import { Dispatch } from "redux";
import API from '../../api';
import { notify as Notify } from 'react-notify-toast';
import { SubmissionStatus } from "./reducer";

export type LfiProtectPrequalAction = SubmitLfiProtectLeadAction | SubmitSucceededAction | SubmitFailedAction | SubmitPendingAction | InitialiseFormAction ;

export enum LfiProtectPrequalActionTypes {
  SubmitSucceeded = "SubmitSucceeded",
  SubmitFailed = "SubmitFailed",
  SubmitLfiProtectLead = "SubmitLfiProtectLead",
  SubmitPending = "SubmitPending",
  InitialiseForm = "InitialiseForm"
}

interface SubmitLfiProtectLeadAction {
  type: LfiProtectPrequalActionTypes.SubmitLfiProtectLead,
}

interface SubmitSucceededAction {
  type: LfiProtectPrequalActionTypes.SubmitSucceeded,
}

interface SubmitFailedAction {
  type: LfiProtectPrequalActionTypes.SubmitFailed,
  errorMessage: string
}

interface SubmitPendingAction {
  type: LfiProtectPrequalActionTypes.SubmitPending,
  status: string
}

interface InitialiseFormAction {
  type: LfiProtectPrequalActionTypes.InitialiseForm,
  status: string
}

export const initialiseForm = () => (dispatch: Dispatch<InitialiseFormAction>) => dispatch({
  type: LfiProtectPrequalActionTypes.InitialiseForm,
  status: SubmissionStatus.Initial
});

export const submitLfiProtectLead = (data) => (dispatch: Dispatch<LfiProtectPrequalAction>) => {
  dispatch({
    type: LfiProtectPrequalActionTypes.SubmitPending,
    status: SubmissionStatus.Pending
  })

  API.submitLfiProtectLead(data)
    .then(data => handleSubmitResponse(data, dispatch))
    .catch(() => {	
      Notify.show('Something went wrong, please contact support team', 'error', 3000)

      dispatch({					
        type: LfiProtectPrequalActionTypes.SubmitFailed,
        errorMessage: "Something went wrong, please contact support team"
      })
    });       
}

const handleSubmitResponse = (data, dispatch: Dispatch<LfiProtectPrequalAction>) => {
  switch (data.status) {
    case 200:
      Notify.show('Your LFI Protect lead has been submitted', 'success', 3000)

      dispatch({
          type: LfiProtectPrequalActionTypes.SubmitSucceeded,												
      })
      break;

    case 500:
    default :
      Notify.show('Something went wrong, please contact support team', 'error', 3000)

      dispatch({
          type: LfiProtectPrequalActionTypes.SubmitFailed,
          errorMessage: "Something went wrong, please contact support team"
      })
      break;
  }
}