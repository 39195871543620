import initialState from './initialState';
import * as types from '../constants/types';

export default function promptsReducer(state = initialState.prompts, action) {
  switch (action.type) {
    case types.GET_PROMPTS:
      return state;

    case types.ADD_PROMPT: {
      const newState = JSON.parse(JSON.stringify(state));
      if (action.prompt.condition !== false) {
        newState.push(action.prompt);
      }

      return newState;
    }

    case types.SAVE_PROMPT: {
      return state;
    }

    case types.REMOVE_PROMPT: {
      const newState = JSON.parse(JSON.stringify(state));
      const filteredState = newState.filter((prompt) => action.prompt != prompt.id);
      return filteredState;
    }

    case types.CLEAR_PROMPTS: {
      return initialState.prompts;
    }

    default:
      return state;
  }
}
