import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { Form, Input, Select } from 'formsy-react-components';
import { notify as Notify } from 'react-notify-toast';

import { Icon, Overlay } from '../../utils';

import * as sessionActions from '../../actions/sessionActions';
import Event3rdParty from '../../helpers/event3rdParty';
import * as errors from '../../constants/errors';
import * as messages from '../../constants/messages';

export class ProfileAddress extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
      visible: false,
      processing: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.startProcessing = this.startProcessing.bind(this);
    this.stopProcessing = this.stopProcessing.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }

  getAddress(details) {
    if (details.address) {
      return `${details.address.street}, ${details.address.suburb}, ${details.address.state} ${details.address.postcode}`;
    }

    return '';
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  hideModal() {
    this.setState({
      visible: false,
    });
  }

  startProcessing() {
    this.setState({
      processing: true,
    });
  }

  stopProcessing() {
    this.setState({
      processing: false,
    });
  }

  handleSubmit(data) {
    this.hideModal();
    this.startProcessing();

    // 3rd party
    Event3rdParty.send('update-address');

    this.props.actions.setDetailAddress(data)
      .then(() => {
        Notify.show(messages.MESS_SUCCESS, 'success', 3000);
      })
      .catch(() => {
        Notify.show(errors.ERR_DEFAULT, 'error', 3000);
      })
      .then(this.stopProcessing);
  }

  handleInvalidSubmit() {
    Notify.show(errors.ERR_VALIDATION, 'error', 3000);
  }

  render() {
    return (
      <div className="panel-part">
        <h5 className="panel-bar">
          Address

          <button id="profileAddressEdit" onClick={this.showModal} className="btn btn-secondary btn-link mt-sm-n mr-sm-n pull-right">
            <Icon glyph="edit" />
          </button>
        </h5>

        <dl className="dl-horizontal">
          <dt>Address</dt>
          <dd data-hj-masked>{this.props.getValue(this.getAddress(this.props.details))}</dd>
        </dl>

        {
          (() => {
            if (this.state.processing) {
              return (
                <Overlay>Saving changes...</Overlay>
              );
            }
          })()
        }

        <Modal
          show={this.state.visible}
          onHide={this.hideModal}
        >

          <Form
            onValidSubmit={this.handleSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            layout="vertical"
          >

            <Modal.Body>
              <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
                <span aria-hidden="true">×</span>
              </button>

              <div className="text-center r">
                <Icon glyph="map" className="mt-lg mb-lg mute-lg" size={6} />
                <Icon glyph="pin" className="a" size={4} style={{ marginTop: '12px', marginLeft: '-36px' }} />
              </div>

              <Input
                id="street"
                name="street"
                type="text"
                label="Address"
                data-hj-masked
                value={this.props.details.address && this.props.details.address.street || ''}
                validationError="Address is required and must be valid"
                validations={{
                  isAddress: true,
                  isRequired: true,
                }}
              />

              <Row>
                <Col sm={5}>
                  <Input
                    id="suburb"
                    name="suburb"
                    type="text"
                    label="City / Suburb"
                    data-hj-masked
                    value={this.props.details.address && this.props.details.address.suburb || ''}
                    validationError="Suburb is required and must be valid"
                    validations={{
                      isRequired: true,
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <Select
                    id="state"
                    name="state"
                    type="select"
                    label="State"
                    value={this.props.details.address && this.props.details.address.state || 'VIC'}
                    options={[
                      { value: 'ACT', label: 'ACT' },
                      { value: 'NSW', label: 'NSW' },
                      { value: 'NT', label: 'NT' },
                      { value: 'QLD', label: 'QLD' },
                      { value: 'SA', label: 'SA' },
                      { value: 'TAS', label: 'TAS' },
                      { value: 'VIC', label: 'VIC' },
                      { value: 'WA', label: 'WA' },
                    ]}
                    className="form-control"
                  />

                </Col>
                <Col sm={3}>
                  <Input
                    id="postcode"
                    name="postcode"
                    type="number"
                    label="Postcode"
                    data-hj-masked
                    value={this.props.details.address && this.props.details.address.postcode || ''}
                    validationError="Postcode is required and must be valid"
                    validations={{
                      isNumeric: true,
                      isLength: 4,
                      isRequired: true,
                    }}
                  />
                </Col>
              </Row>

            </Modal.Body>

            <Modal.Footer>
              <button type="button" onClick={this.hideModal} className="btn btn-link btn-default">Cancel</button>
              <button type="submit" className="btn btn-success">Save</button>
            </Modal.Footer>

          </Form>
        </Modal>
      </div>
    );
  }
}

ProfileAddress.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
  details: PropTypes.any,
  getValue: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddress);
