import Formsy from 'formsy-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Input } from 'formsy-react-components';

import Vehicle from '../applicationSubmitPage/assets/vehicle';
import ApplicationUpdateVehiclePicker from '../applicationUpdatePage/applicationUpdateVehiclePicker';

type LoanPurpose = 'new' | 'used' | 'preapproval';

type MotorPrequalPurposeTogglesProps = {
  vehicle?: Vehicle;
  isUsed: boolean;
  formsy?: Formsy;
  application: any;
  updateLoanPurpose: (loanPurpose: LoanPurpose) => void
}

const motorPrequalPurposeToggles = (props: MotorPrequalPurposeTogglesProps) => {
  const [purpose, setPurpose] = React.useState<LoanPurpose>('preapproval');
  React.useEffect(() => { 
    props.application?.motorPurchaseVehicle && mapVehicleAsset(props.application?.motorPurchaseVehicle);
  }, [props.application])

  const mapVehicleAsset = (vehicle: any) => {
    if (vehicle.isUsed && purpose != 'used') setPurpose('used');
    if (!vehicle.isUsed && purpose != 'new') setPurpose('new');
  }

  const getButtonClasses = (buttonPurpose: LoanPurpose) =>
    `btn btn-default btn-toggle ${buttonPurpose === purpose ? 'selected' : ''}`;

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Row>
        <Col sm={12}>
          <p>What is the purpose of the loan?</p>
        </Col>
      </Row>
      <Row className="btn-toggles">
        <button className={getButtonClasses('preapproval')} type="button" onClick={() => setPurpose('preapproval')}>
          <span className="icon fal fa-clipboard-check fa-2x"></span>
          <div>
            <p className="option-info-name">Preapproval</p>
            <p className="mute">In-Principle</p>
          </div>
        </button>
        <button className={getButtonClasses('new')} type="button" onClick={() => setPurpose('new')}>
          <span className="icon fal fa-2x fa-car"></span>
          <div>
            <p className="option-info-name">New Vehicle</p>
            <p className="mute">Brand New</p>
          </div>
        </button>
        <button className={getButtonClasses('used')} type="button" onClick={() => setPurpose('used')}>
          <span className="icon fal fa-2x fa-car"></span>
          <div>
            <p className="option-info-name">Used Vehicle</p>
            <p className="mute">Second Hand</p>
          </div>
        </button>
      </Row>
      {purpose != 'preapproval' &&
        <div style={{ marginTop: 15 }}>
          <ApplicationUpdateVehiclePicker 
            isRequired={true}
            formsy={props.formsy} 
            setMessage={()=>{}}
            application={props.application} />
        </div>
      }
      {
        purpose === 'used' &&
        <Input
          type="hidden"
          id="motorPurchaseVehicle.isUsed"
          name="motorPurchaseVehicle.isUsed"
          value={true}
        />
      }
    </div>
  );
};

export default motorPrequalPurposeToggles;