import { PersonApplicant } from "./commonApiModels";

export enum MaxFixed3YearTerm {
  Unknown = -1,
  Variable,
  FixedOneYear,
  FixedTwoYear,
  FixedThreeYear
}

export enum MaxFixed5YearTerm {
  Unknown = -1,
  Variable,
  FixedOneYear,
  FixedTwoYear,
  FixedThreeYear,
  FixedFourYear,
  FixedFiveYear
}

export enum PropertyUse {
  Unknown = 0,
  OwnerOccupied,
  Investor,
  ProgressionalInvestor
}

export enum RepaymentType
{
  Unknown = 0,
  PrincipalAndInterest,
  InterestOnly
}

export enum MortgageStarVerificationLevel
{
  Unknown = 0,
  NoDoc,
  LowDoc,
  FullDoc
}

export enum MortgageSharpVerificationLevel
{
  Unknown = 0,
  FullDoc
}

export enum MortgageFreeVerificationLevel
{
  Unknown = 0,
  LowDoc,
  FullDoc
}

export enum LoanTerm3Year
{
  Unknown = 0,
  Variable = -1,
  FixedOneYear = 1,
  FixedTwoYear = 2,
  FixedThreeYear = 3,
}
export enum LoanTerm5Year
{
  Unknown = 0,
  Variable = -1,
  FixedOneYear = 1,
  FixedTwoYear = 2,
  FixedThreeYear = 3,
  FixedFourYear = 4,
  FixedFiveYear = 5,
}

export interface Max3YearFixTermLoan {
  $type: 'Max3YearFixTermLoan';
  LoanTermYear: LoanTerm3Year
}

export interface Max5YearFixTermLoan {
  $type: 'Max5YearFixTermLoan';
  LoanTermYear: LoanTerm5Year;
}

export interface MortgageLoanStructure {
  LoanAmount: number;
  IsSwift: boolean;
  Purpose: PropertyUse;
  SecurityValue: number;
}

export interface MortgageStarProduct { 
  $type: 'MortgageStarProduct';
  VerificationLevel: MortgageStarVerificationLevel;
  LoanStructure: Max5YearFixTermLoan & MortgageLoanStructure;
  RepaymentType: RepaymentType;
}
export interface MortgageSharpProduct { 
  $type: 'MortgageSharpProduct';
  VerificationLevel: MortgageSharpVerificationLevel;
  LoanStructure: Max5YearFixTermLoan & MortgageLoanStructure;
  RepaymentType: RepaymentType;
}
export interface MortgageFreeProduct { 
  $type: 'MortgageFreeProduct';
  VerificationLevel: MortgageFreeVerificationLevel;
  LoanStructure: Max3YearFixTermLoan & MortgageLoanStructure;
  RepaymentType: RepaymentType;
}

export interface MortgageRateQuoteQueryInfo {
  $type: 'MortgageRateQuoteQueryInfo';
  Product: MortgageStarProduct | MortgageSharpProduct | MortgageFreeProduct;
  Applicants: PersonApplicant[];
};

export interface ResidentialRateQuoteQuery {
  RequestId: string;
  QueryInfo: MortgageRateQuoteQueryInfo
}