import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { msalInstance } from '../../helpers/authConfig';

import API from '../../api';

import * as introducersActions from '../../actions/introducersActions';
import * as contactsActions from '../../actions/contactsActions';
import * as sessionActions from '../../actions/sessionActions';

import LoadingPane from '../../components/loadingPane';
import IntroducerScroller from '../../components/introducerScroller';

export class IntroducersPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showAssetTypeSelection: false,
    };

    this.getIntroducers = this.getIntroducers.bind(this);
    this.searchIntroducers = this.searchIntroducers.bind(this);
    // this.onAssetTypeSelectionHide = this.onAssetTypeSelectionHide.bind(this);
  }

  componentDidMount() {
    this.getIntroducers(true);

    this.checkUpdate();
  }

  checkUpdate() {
    if (window.location.pathname == '/') {
      API.getVersion()
        .then((response) => {
          if (response && (parseInt(response.data) > parseInt(window.iqUIVersion))) {
            /**
             * Reload the page
             */
            window.location.reload();
          }
        });
      setTimeout(this.checkUpdate, 60 * 1000);
    }
  }

  getIntroducers(init = false) {
    if (!this.props.introducers.query || init) {
      // this.props.actions.getIntroducers();
    }

    setTimeout(this.getIntroducers, 15 * 60 * 1000);
  }

  searchIntroducers(event) {
    const query = event.target.value;
    this.props.actions.searchIntroducers(query);
  }

  render() {
    if (this.props.introducers.all === null) {
      return (
        <LoadingPane label="introducers" />
      );
    }


    const activeUser = msalInstance.getActiveAccount();
    let email = activeUser.idTokenClaims.email;

    if (!email && activeUser.idTokenClaims.emails) {
      email = activeUser.idTokenClaims.emails[0];
    }

    return (
      <>
        <Row>
          <IntroducerScroller name={email} />
        </Row>
      </>
    );
  }
}

IntroducersPage.propTypes = {
  introducers: PropTypes.object.isRequired,
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    introducers: state.introducers,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...introducersActions, ...contactsActions, ...sessionActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroducersPage);
