import API from '../api';
import * as types from '../constants/types';
import Storage from '../helpers/storage';

let timer = null;

export const getDetails = () => (dispatch) => API.getDetails()
  .then((response) => dispatch({
    type: types.GET_DETAILS,
    data: response.data,
  }));

export const getMigrated = (data) => (dispatch) => API.getMigrated(data)
  .then((response) => dispatch({
    type: types.GET_MIGRATED,
    data: response.data,
  }));

export const setDetailNumbers = (data) => (dispatch) => API.setDetailNumbers(data)
  .then(() => dispatch({
    type: types.SET_DETAIL_NUMBERS,
    data,
  }));

export const setDetailEmails = (data) => (dispatch) => API.setDetailEmails(data)
  .then(() => dispatch({
    type: types.SET_DETAIL_EMAILS,
    data,
  }));

export const setDetailAddress = (data) => (dispatch) => API.setDetailAddress(data)
  .then(() => dispatch({
    type: types.SET_DETAIL_ADDRESS,
    data,
  }));

export const setDetailPassword = (data) => (dispatch) => API.setDetailPassword(data)
  .then(() => dispatch({
    type: types.SET_DETAIL_PASSWORD,
    data,
  }));

export const setSubscription = (data) => (dispatch) => API.setSubscription(data)
  .then(() => dispatch({
    type: types.SET_DETAIL_SUBSCRIPTION,
    data,
  }));

export const setAccreditation = (data) => (dispatch) => API.setAccreditation(data)
  .then(() => dispatch({
    type: types.SET_ACCREDITATION,
    data,
  }));

export const requestAccreditation = (data) => (dispatch) => API.requestAccreditation(data)
  .then(() => dispatch({
    type: types.REQUEST_ACCREDITATION,
    data,
  }));

export const requestInsurance = (data) => (dispatch) => API.requestInsurance(data)
  .then(() => dispatch({
    type: types.REQUEST_INSURANCE,
    data,
  }));

export const doLogin = (data) => (dispatch) => API.getToken(data)
  .then((response) => {
    response.data.username = data.username;
    dispatch({
      type: types.DO_LOGIN,
      data: response.data,
    });
    dispatch(start());
  });

export const doResetToken = (data) => (dispatch) => API.getResetToken(data)
  .then((response) => dispatch({
    type: types.DO_RESET_TOKEN,
    data: response.data,
  }));

export const doReset = (data) => (dispatch) => API.getReset(data)
  .then((response) => dispatch({
    type: types.DO_RESET,
    data: response.data,
  }));

export const doLogout = () => (dispatch) => {
  dispatch({
    type: types.DO_LOGOUT,
  });
  dispatch(stop());
};

export const renewToken = (next) => (dispatch) => {
  if (Storage.getItem('isUseB2C') == 'true') {
    // If we're using B2C, we refresh the token elsewhere, ignore this and continue.
    return Promise.resolve();
  }
  const renewPromise = API.getRenewToken()
    .then((response) => {
      dispatch({
        type: types.RENEW_TOKEN_SUCCESS,
        data: response.data,
      });
      dispatch(start());
    })
    .catch(() => {
      dispatch({
        type: types.RENEW_TOKEN_FAILURE,
      });
    });

  dispatch({
    type: types.RENEW_TOKEN_IN_PROGRESS,
    renewPromise,
  });

  if (next) { return renewPromise.then(next); }

  return renewPromise;
};

const start = () => (dispatch) => {
  clearInterval(timer);
  timer = setInterval(() => dispatch(tick()), 10000);
  dispatch({ type: types.TIMER_START });
};

const tick = () => ({ type: types.TIMER_TICK });

const stop = () => {
  clearInterval(timer);
  return { type: types.TIMER_STOP };
};
