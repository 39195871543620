import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ApplicationMessage from './applicationMessage';
import ApplicationSecurityMotor from './applicationSecurityMotor';
import ApplicationSecurityResi from './applicationSecurityResi';
import ApplicationLoan from './applicationLoan';
import ApplicationInsurance from './applicationInsurance';
import ApplicationProgress from './applicationProgress';
import ApplicationDownloadDocument from './applicationDocuments';
import ApplicationIDVerificationToggle from './idVerification';
import ApplicationStatus from './applicationStatus';
import ContactsWidget from '../../components/contactsWidget';
import FaqsWidget from '../../components/faqsWidget';

export default class ApplicationSummary extends React.Component {
  constructor(props) {
    super(props, {});
    this.state = {
    };
  }

  render() {
    const { applicationType } = this.props.application;

    return (
        <Row className="f mb-lg">
          <Col sm={8} className="main-panel">
            <div className="panel panel-default">
              <div className="panel-body p-h">
                <div className="loan-item loan-single">
                  <ApplicationStatus status={this.props.application.applicationStatus} reason={this.props.application.statusReason} />
                  <ApplicationMessage setTab={this.props.setTab} id={this.props.application.applicationNumber} type={applicationType} message={this.props.application.applicationStatus.message} me={this.props.details} />
                  <Row>
                    <Col sm={4}>
                      {
                        (() => {
                          switch (applicationType) {
                            case 2:
                              return <ApplicationSecurityMotor application={this.props.application} />;
                            case 1:
                            case 5:
                              return <ApplicationSecurityResi application={this.props.application} />;
                            default:
                              return null;
                          }
                        })()
                      }

                    </Col>

                    <br className="visible-xs" />

                    <Col sm={applicationType == 7 ? 12 : 4}>
                      <ApplicationLoan application={this.props.application} />
                    </Col>

                    <br className="visible-xs" />

                    <Col sm={4}>
                      {
                        (() => {
                          switch (applicationType) {
                            case 1:
                            case 5:
                              return <ApplicationInsurance application={this.props.application} reference={this.props.reference} />;
                            default:
                              return null;
                          }
                        })()
                      }
                    </Col>
                  </Row>

                  <ApplicationProgress setTab={this.props.setTab} contacts={this.props.application.contacts} type={applicationType} status={this.props.application.applicationStatus} overview={this.props.application.applicationStatusOverview} />
                </div>
              </div>
            </div>
          </Col>

          <Col sm={4} className="right-panel">
            <ApplicationDownloadDocument application={this.props.application} getApplication={this.props.getApplication} markPublishDocumentRead={() => { }} />          
            <ApplicationIDVerificationToggle application={this.props.application} />
            <ContactsWidget title="Contacts" contacts={this.props.application.contacts} application={this.props.application} />
            <FaqsWidget application={this.props.application} />
          </Col>
        </Row>
    );
  }
}
