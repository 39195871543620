import initialState from './initialState';
import * as types from '../constants/types';

export default function contactsReducer(state = initialState.contacts, action) {
  switch (action.type) {
    case types.GET_CONTACTS: {
      return action.contacts;
    }

    default:
      return state;
  }
}
