import * as React from 'react';
import PropTypes from 'prop-types';

const InfoPane = (props) => (
    <div id="info-pane" className="text-center">
      <span className="mute">
      {'Trouble '}
      {props.action}
      ?
      </span>
        {' '}
        <a href="mailto:introducer@liberty.com.au">Email us</a>
        {' '}
        <span className="mute">or call</span>
        {' '}
      <a href="tel: +61131133">13 11 33</a>
    </div>
);

export default InfoPane;
