
import initialState from './initialState';
import * as types from '../constants/types';
const objectAssign = require('object-assign');

export default function storageReducer(state = initialState.storage, action) {
  switch (action.type) {
    case types.GET_STORAGE: {
      const newState = objectAssign({}, state, {});
      newState[action.key] = action.data;
      return newState;
    }

    case types.SET_STORAGE: {
      const newState = objectAssign({}, state, {});
      return newState;
    }

    default:
      return state;
  }
}
