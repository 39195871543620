import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const PaneSlide = (props) => (
  <div>
    <div className={`pane-slide animate ${props.visible ? 'in' : ''}`}>
      <div className="avatar avatar-lg">
        <div className="avatar-image" style={props.styles} />
        <span className="avatar-initials">{props.initials}</span>
      </div>
      <h3 className="pane-title mb-xs">{props.name}</h3>
      <p className="mute">{props.company}</p>

      <div className="pane-slide-menu">
        <Link onClick={props.toggleAll} to="/profile" className="menu-item pointer" activeClassName="active">My profile</Link>
        <Link onClick={props.handleLogout} to="/login" className="menu-item pointer" activeClassName="active">Log out</Link>
      </div>
    </div>
    <div className="pane-overlay" onClick={props.toggleOptions} />
  </div>
);

PaneSlide.propTypes = {
  visible: PropTypes.any,
  styles: PropTypes.any,
  initials: PropTypes.string,
  company: PropTypes.string,
  toggleOptions: PropTypes.func,
  toggleAll: PropTypes.func,
  handleLogout: PropTypes.func,
  name: PropTypes.string,
};

export default PaneSlide;
