import * as React from 'react';
import { connect } from 'react-redux';

type ReduxState = any;

export interface OverrideComponent {
  condition: (state: ReduxState) => boolean;
  component: () => JSX.Element;
}

interface ConditionalRouteProps {
  reduxState: ReduxState;
  route: {
    mainComponent: JSX.Element | any;
    overrideComponents: OverrideComponent[];
    mainComponentProps?: Object;
  };
}

interface ConditionalProps {
  reduxState: ReduxState;

  mainComponent: JSX.Element | any;
  overrideComponents: OverrideComponent[];
  mainComponentProps?: Object;
}

/**
 * Shim to make Conditional re-usable for routing since
 * we can't utilise the `Route.render` feature of React Router 4
 * and need to copy Conditional props off the Route props because
 * we can't set them directly on the Conditional component from the Route
 */
class ConditionalRoute extends React.Component<ConditionalRouteProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    let newProps = {
      ...this.props,
      route:  this.props.route,
      mainComponent: this.props.route.mainComponent,
      overrideComponents: this.props.route.overrideComponents,
      mainComponentProps: this.props.route.mainComponentProps,
    }

    return <Conditional {...newProps} />
  }
}


class Conditional extends React.Component<ConditionalProps, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { overrideComponents, mainComponent, reduxState, mainComponentProps } = this.props;

    let Component = overrideComponents.reduce((currentComponent: JSX.Element | undefined, overrideComponent: OverrideComponent) => {
      if (currentComponent === undefined && overrideComponent.condition(reduxState)) {
        return overrideComponent.component;
      }

      return currentComponent;
    }, undefined) || mainComponent;

    return <Component {...this.props} {...mainComponentProps} />;
  }
}

const ConnectedConditional = connect((state) => ({ reduxState: state }), {})(Conditional)
const ConnectedConditionalRoute = connect((state) => ({ reduxState: state }), {})(ConditionalRoute)

export {
  ConnectedConditional as Conditional,
  Conditional as UnconnectedConditional,
  ConnectedConditionalRoute as ConditionalRoute,
  ConditionalRoute as UnconnectedConditionalRoute
}
