import * as React from 'react';
import { Link } from 'react-router';
import { Icon, Empty } from '../../utils';

const refreshAfterSeconds = (seconds: number) => setTimeout(_ => window.location.reload(true), seconds * 1000)

class MaintenancePage extends React.Component<{}, {}> {
  componentDidMount() {
    refreshAfterSeconds(60);
  }

  render() {
    return (
      <div className="text-center">
        <div className="mt-lg mb-;g">
          <Link to="/login">
            <img alt="" src={"/" + (window as any).iqUIVersion + "/assets/img/logo-liberty-financial.png"} className="mt-lg ml-md-n" width="180" />
          </Link>
        </div>

        <div style={{marginTop: '120px'}}>
          <Icon glyph={"wrench"} size={4} className="mt-lg mb-lg mute-lg" />
          <h2>Scheduled maintenance...</h2>
          <p className="text-lg mute">We are currently performing scheduled maintenance and should be back online shortly. We apologise for any inconvenience.</p>
        </div>
      </div>
    );
  }
}

export default MaintenancePage;
