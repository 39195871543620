import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

const SearchPane = (props) => (
    <div className="form-group r mb-h">
      <input value={props.query} name="search" style={{ paddingLeft: '40px' }} className="animate-fast form-control form-control-search" placeholder={props.placeholder} onChange={props.onSearch} />
      <Icon glyph="search" size="lg ml-md" className="a animate" style={{ top: '12px' }} />

      <Icon onClick={() => { props.onSearch({ target: { value: '' } }); }} glyph="close" size="lg ml-md" className={`a animate-slow pull-right ${props.query ? 'rotate-180' : 'transparent'}`} style={{ top: '12px', right: '16px' }} />
    </div>
);

SearchPane.propTypes = {
  query: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
};

export default SearchPane;
