
import queryString from 'query-string';
import API from '../api';

class ApiStore {
  static baseUrl() {
    return (window.location && queryString.parse(window.location.search).base) || API.base;
  }

  /**
       * Base Urls
       */
  static lookupUrl() {
    return `${this.baseUrl()}lookup/`;
  }

  static authUrl() {
    return `${this.baseUrl()}'auth/`;
  }

  static documentUrl() {
    return `${this.baseUrl()}documents/`;
  }

  static publishDocUrl() {
    return `${this.documentUrl()}publish-docs/`;
  }

  static userCommandUrl() {
    return `${this.baseUrl()}commands/users/`;
  }

  static applicationCommandUrl() {
    return `${this.baseUrl()}commands/applications/`;
  }

  /**
     * Token Url
     *
     * TODO Needs updating
     */
  static tokenUrl() {
    return `${this.authUrl()}token`;
  }

  /**
     * Introducer Url
     */
  static introducerUrl() {
    return `${this.baseUrl()}users/me`;
  }

  /**
     * Resend Password
     *
     * TODO Needs updating
     */
  static resendPasswordUrl() {
    return `${this.authUrl()}resetpassword/token`;
  }

  /**
     * Resend Password
     *
     * TODO Needs updating
     */
  static resetPasswordUrl() {
    return `${this.authUrl()}resetpassword`;
  }

  /**
     * Upload Document

     * @param ApplicationNumber number
     */
  static uploadDocumentUrl(ApplicationNumber) {
    return `${this.documentUrl()}upload/${ApplicationNumber}`;
  }

  /**
     * Download Document
     */
  static downloadDocumentUrl(ApplicationNumber, DocumentId) {
    return `${this.documentUrl()}pdf/${ApplicationNumber}/${DocumentId}`;
  }

  /**
     * Download Reference Document
     */
  static downloadReferenceDocumentUrl(Filename) {
    return `${this.documentUrl()}reference/${Filename}`;
  }

  /**
     * Reference Document List
     */
  static referenceDocumentsUrl() {
    return `${this.documentUrl()}reference`;
  }

  /**
     * Download publish doc
     */
  static downloadPublishDocUrl(DocumentId) {
    return this.publishDocUrl() + DocumentId;
  }

  /**
     * Application Url
     *
     * @params int
     */
  static applicationUrl(ApplicationId) {
    return `${this.baseUrl()}application/${ApplicationId}`;
  }

  /**
     * Application Save Url
     */
  static applicationSaveUrl() {
    return `${this.baseUrl()}motor-application/conditional-update`;
  }

  /**
     * Application List Url
     *
     * @params int
     */
  static applicationListUrl() {
    return `${this.baseUrl()}application`;
  }

  /**
     * BDM list
     *
     * @params int
     */
  static bdmListUrl() {
    return `${this.lookupUrl()}introducer-bdms`;
  }

  /**
     * Application Save Agreement Url
     *
     * @params int
     */
  static applicationAgreementSaveUrl() {
    return `${this.baseUrl()}motor-application/finalise`;
  }

  /**
     * Avatar Url
     *
     * @param Avatar string
     */
  static avatarUrl(StaffId) {
    return `${this.lookupUrl()}staff/images/${StaffId}.jpg`;
  }

  /**
     * Staff Details
     */
  static staffDetailsUrl(Staff) {
    const staffList = new Array();
    for (let i = 0; i < Staff.length; i++) {
      staffList.push(`StaffId=${Staff[i]}`);
    }

    return `${this.lookupUrl()}staff/details?${staffList.join('&')}`;
  }

  /**
     * Loan Vendor Types
     */
  static vendorTypesUrl() {
    return `${this.lookupUrl()}VendorTypes`;
  }

  /**
     * VEI Types
     */
  static veiTypesUrl() {
    return `${this.lookupUrl()}VeiCoverOptions`;
  }

  /**
     * LPI Types
     */
  static lpiTypesUrl() {
    return `${this.lookupUrl()}LpiCoverOptions`;
  }

  /**
     * Loan Purposes
     */
  static loanPurposesUrl() {
    return `${this.lookupUrl()}LoanPurposes`;
  }

  /**
     * Loan Verification Levels
     *
     * @param ProductGroupId number
     */
  static verificationLevelsUrl(ProductGroupId) {
    return `${this.lookupUrl()}VerificationLevelByCriteria?ProductGroupId=${ProductGroupId}`;
  }

  /**
     * Vehicle Years of Manufacture
     */
  static yearsOfManufactureUrl() {
    return `${this.lookupUrl()}VehicleYearOfManufacture`;
  }

  /**
     * Makes
     *
     * @param Year number
     * @param IsNew boolean
     */
  static makesUrl(Year, IsNew) {
    return `${this.lookupUrl()}VehicleMakeByCriteria?Year=${Year 
    }&IsNew=${IsNew}`;
  }

  /**
     * Vehicle Models
     *
     * @param Year number
     * @param IsNew boolean
     * @param Make number
     */
  static modelsUrl(Year, IsNew, Make) {
    return `${this.lookupUrl()}VehicleModelByCriteria?Year=${Year 
    }&IsNew=${IsNew 
    }&Make=${Make}`;
  }

  /**
     * Vehicle Body Types
     *
     * @param Year number
     * @param IsNew boolean
     * @param Make number
     * @param Model number
     */
  static bodyTypesUrl(Year, IsNew, Make, Model) {
    return `${this.lookupUrl()}VehicleBodyByCriteria?Year=${Year 
    }&IsNew=${IsNew 
    }&Make=${Make 
    }&Model=${Model}`;
  }

  /**
     * Vehicle Transmission Types
     *
     * @param Year number
     * @param IsNew boolean
     * @param Make number
     * @param Model number
     * @param Body number
     */
  static transmissionTypesUrl(Year, IsNew, Make, Model, Body) {
    return `${this.lookupUrl()}VehicleTransmissionByCriteria?Year=${Year 
    }&IsNew=${IsNew 
    }&Make=${Make 
    }&Model=${Model 
    }&Body=${Body}`;
  }

  /**
     * Vehicle NVIC
     *
     * @param Year number
     * @param IsNew boolean
     * @param Make number
     * @param Model number
     * @param Body number
     * @param Transmission number
     */
  static nvicsUrl(Year, IsNew, Make, Model, Body, Transmission) {
    return `${this.lookupUrl()}VehicleCategoryByCriteria?Year=${Year 
    }&IsNew=${IsNew 
    }&Make=${Make 
    }&Model=${Model 
    }&Body=${Body 
    }&Transmission=${Transmission}`;
  }


  /**
     * Vehicle Details
     */
  static detailsUrl(NVIC) {
    return `${this.lookupUrl()}VehicleDetailByCriteria?NVIC=${NVIC}`;
  }

  /**
     * Vehicle Appraisal
     *
     * @param Nvic Function
     * @param IsNew boolean
     * @param Odometer number
     */
  static appraisalUrl(NVIC, IsNew, Odometer) {
    return `${this.lookupUrl()}VehicleAppraisalByCriteria?NVIC=${NVIC 
    }&IsNew=${IsNew 
    }&Odometer=${Odometer}`;
  }

  /**
     * Insurers
     */
  static insurersUrl() {
    return `${this.lookupUrl()}Insurers`;
  }
}

export default ApiStore;
