import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../utils';

const ApplicationActionIcon = (props) => {
  let hasActions = false;

  // set actions if there are actions
  if (props.actions.length) {
    hasActions = true;
  }

  // set actions if there are introducer actions
  if (props.status.message && props.status.message.introducerAction) {
    hasActions = true;
  }

  // unset actions if there are no introducer actions
  if (props.status.message && !props.status.message.introducerAction) {
    hasActions = false;
  }

  if (hasActions) {
    return (
      <div className="loan-action">
        <span className="loan-action-required">
          <em>Actions required</em>
          <Icon glyph="notifications" rounded color="white" background="warning" />
        </span>
      </div>
    );
  }

  return (
    <div className="loan-action">
      <Icon glyph="chevron-right" rounded background="primary-100" color="white" />
    </div>
  );
};

ApplicationActionIcon.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default ApplicationActionIcon;
