import * as React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';

import Remove from '../remove';
import Title from '../title';
import CurrencyInput from '../currencyInput';
import AddressInput from '../addressInput';

import Mask from '../../../helpers/mask';
import {toRequired, toOptionGroup, selectText, firstOption} from '../functions';

export class Rental extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render(): JSX.Element {
    return (
      <div className={`card mt-lg p-lg`}>
        <Input
          name={`incomes[${this.props.index}].id`}
          type="hidden"
          value={this.props.income.id}
        />


        <Input
          name={`incomes[${this.props.index}].type`}
          type="hidden"
          value="rental"
        />

        <Input
          name={`incomes[${this.props.index}].belongsTo`}
          type="hidden"
          value={this.props.income.belongsTo}
        />

        <Title sub="Gross weekly" top>
          Rental income
          <Remove onClick={this.props.removeIncome.bind(this, this.props.income.id)} />
        </Title>

        <Row>
          <Col sm={6}>
            <AddressInput
              name={`incomes[${this.props.index}].address`}
              label={toRequired("Address")}
              validations={{
                isRequired: true
              }} />
          </Col>

          <Col sm={3}>
            <CurrencyInput
              name={`incomes[${this.props.index}].deductionAmount`}
              label={toRequired(<span>Deductions <small className="mute">(gross yearly)</small></span>)}
              align="right"
              validations={{
                isRequired: true
              }}
              validationErrors={{
                isRequired: "Amount is required"
              }}
            />
          </Col>
          <Col sm={3}>
            <CurrencyInput
              name={`incomes[${this.props.index}].incomeAmount`}
              label={toRequired(<span>Amount <small className="mute">(gross weekly)</small></span>)}
              align="right"
              validations={{
                isRequired: true,
                isNotZero: true
              }}
              validationErrors={{
                isRequired: "Amount is required",
                isNotZero: "Amount can not be 0"
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Rental;
