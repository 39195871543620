import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hashHistory } from 'react-router';

import { Modal } from 'react-bootstrap';
import { Form } from 'formsy-react-components';

class CalculatorPrompt extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.state = {
    };

    this.hideModal = this.hideModal.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
    this.saveModal = this.saveModal.bind(this);
  }

  hideModal() {
    this.props.setPrompt(false);
  }

  dismissModal() {
    this.props.dismissPrequal();
    hashHistory.push(this.props.visible.pathname);
  }

  saveModal() {
    this.props.savePrequal(this.props.visible.pathname);
    this.hideModal();
  }

  render() {
    return (
      <Modal
        show={this.props.visible}
        bsSize="sm"
        onHide={this.hideModal}
      >

        <Form
          onValidSubmit={this.handleSubmit}
          layout="vertical"
          disabled={false}
          elementWrapperClassName=""
          labelClassName=""
          rowClassName=""
          validateBeforeSubmit
          validatePristine
        >

          <Modal.Body>
            <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
              <span aria-hidden="true">×</span>
            </button>

            <h4 className="mb-lg">You haven&#39;t saved your changes</h4>
            <p className="mute">Your changes will be lost if you don&#39;t save them.</p>

          </Modal.Body>

          <Modal.Footer>
            <button type="submit" className="btn btn-default" onClick={this.dismissModal}>Don&#39;t save</button>
            <button type="submit" className="btn btn-success" onClick={this.saveModal}>Save</button>
          </Modal.Footer>

        </Form>

      </Modal>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorPrompt);
