import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../utils';

export class ApplicationUpdateHeader extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div className="panel-section-header" onClick={() => { this.props.toggleSection(this.props.section); }}>
        <Icon glyph={this.props.glyph} className="mr-md text-center" size={3} style={{ width: '44px' }} />

        <span className="visible-xs mt-sm" />

        <div className="ib">
          <h3 className="mb-xs mt-h">{this.props.title}</h3>
          <span className="mute">{this.props.description}</span>
        </div>

        <Icon glyph="chevron-up" size={3} className="animate panel-toggle" />
      </div>
    );
  }
}

ApplicationUpdateHeader.propTypes = {
  glyph: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  toggleSection: PropTypes.func.isRequired,
};

export default ApplicationUpdateHeader;
