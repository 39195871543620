import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import ReactTooltip from 'react-tooltip';
import Icon from '../../utils/icon';

import Mask from '../../helpers/mask';

import MotorPrequalPurposeToggles from './motorPrequalPurposeToggles';

export default class MotorPrequalToolLoanDetails extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      lenderFee: 495.00,
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }


  calculateLoanAmount() {
    const loanAmount = this.getFloatValue('loanDetails.loanAmount.purchasePrice')
      - this.getFloatValue('loanDetails.loanAmount.deposit')
      - this.getFloatValue('loanDetails.loanAmount.netTradeIn')
      + this.getFloatValue('loanDetails.loanAmount.comprehensiveInsurance')
      + this.getFloatValue('loanDetails.loanAmount.extendedWarranty')
      + this.getFloatValue('loanDetails.loanAmount.applicationFee');

    return loanAmount > 0 ? loanAmount + this.state.lenderFee : loanAmount;
  }

  getFloatValue(formInput) {
    return (parseFloat(this.refs[formInput].getValue().toString().replace(/[^0-9.]/g, '')) || 0);
  }

  getLoanTerms() {
    const terms = [];
    for (let value = 2; value <= 5; value++) {
      terms.push({
        value,
        label: `${value} years`,
      });
    }

    return terms;
  }

  getRiskGrades() {
    return [{
      value: 'AAA',
      label: 'AAA',
    }, {
      value: 'Other',
      label: 'Other',
    }];
  }

  getVehicleMakeYears() {
    const years = [{ value: '', label: 'Select' }];
    for (let value = (new Date().getFullYear()); value >= (new Date().getFullYear() - 20); value--) {
      years.push({
        value,
        label: `${value}`,
      });
    }

    return years;
  }

  /**
     * Render
     */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />
          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Loan Details</h3>
          </div>
        </div>
        <div className="panel-section-body">
          <div className="panel-section-container">
            <MotorPrequalPurposeToggles application={this.props.application} formsy={this.props.formsy} />
            <Row>
              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.purchasePrice"
                  id="loanDetails.loanAmount.purchasePrice"
                  ref="loanDetails.loanAmount.purchasePrice"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={<span>Purchase Price</span>}
                  value={0}
                  validationError="Purchase price must be valid and positive"
                  validations={{
                    isFormattedNumber: true,
                    isRequired: true,
                  }}
                />
              </Col>

              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.deposit"
                  id="loanDetails.loanAmount.deposit"
                  ref="loanDetails.loanAmount.deposit"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={<span>- Deposit</span>}
                  value={0}
                />
              </Col>
              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.netTradeIn"
                  id="loanDetails.loanAmount.netTradeIn"
                  ref="loanDetails.loanAmount.netTradeIn"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={<span>- Net Trade-In</span>}
                  value={0}
                />
              </Col>
              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.applicationFee"
                  id="loanDetails.loanAmount.applicationFee"
                  ref="loanDetails.loanAmount.applicationFee"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={(
                    <span>
                      {'+ Brokerage Fee '}
                      <small className="mute">(up to $1450)</small>
                    </span>
                  )}
                  value={0}
                  validationError="Brokerage fee must not be more than $1,000"
                  validations={{
                    isLesserAmount: (values, value) => parseFloat(Mask.clean(value)) <= 1450 || value == '',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.comprehensiveInsurance"
                  id="loanDetails.loanAmount.comprehensiveInsurance"
                  ref="loanDetails.loanAmount.comprehensiveInsurance"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={<span>+ Comprehensive Insurance</span>}
                  value={0}
                />
              </Col>
              <Col sm={3}>
                <div className="form-prefix">$</div>
                <Input
                  name="loanDetails.loanAmount.extendedWarranty"
                  id="loanDetails.loanAmount.extendedWarranty"
                  ref="loanDetails.loanAmount.extendedWarranty"
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label={<span>+ Extended Warranty</span>}
                  value={0}
                />
              </Col>
            </Row>
          </div>
          <div className="panel-section-container">
            <Row>
              <Col sm={2}>
                <Select
                  id="loanDetails.loanTermInYears"
                  name="loanDetails.loanTermInYears"
                  label={(
                    <span data-tip="We can consider up to a 7 year loan term for some profiles">
                      Loan Term
                      <Icon glyph='info' className="ml-sm" color='danger' />
                    </span>
                  )}
                  data-hj-masked
                  value={this.getLoanTerms()[3].value}
                  options={this.getLoanTerms()}
                  className="form-control"
                />
              </Col>
              <Col sm={1} />
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
