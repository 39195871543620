import * as React from 'react';
import PropTypes from 'prop-types';
import Icon from './icon';

export default class Widget extends React.Component {
  constructor(props) {
    super();

    this.props = props;
    this.titleStyleClass = this.props.titleStyleClass && this.props.titleStyleClass !== ''
      ? this.props.titleStyleClass : 'widget-title';
    this.state = {
      expanded: true,
    };

    this.toggleWidget = this.toggleWidget.bind(this);
  }

  toggleWidget() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    return (
      <div className={`widget ${!this.state.expanded ? 'collapsed' : ''}`}>
        {
          (() => {
            if (this.props.title) {
              return (
                <h2 className={this.titleStyleClass} onClick={this.toggleWidget}>
                  <span className="ellipsis">{this.props.title}</span>
                  <Icon glyph="chevron-up" className="widget-toggle" />
                </h2>
              );
            }
          })()
        }

        <div className="widget-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

Widget.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  titleStyleClass: PropTypes.string,
};
