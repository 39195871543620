import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';

import Mask from '../../helpers/mask';

import * as functions from '../../constants/functions';

export default class RepaymentsDetails extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section">
        <div className="panel-section-header panel-header-fixed mb-md">
          <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Product</h3>
          </div>
        </div>

        <div className="panel-section-body">
          <div className="panel-section-container">
            <Row>
              <Col sm={2}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>%</div>
                <Input
                  id="repaymentDetails.interestRate"
                  name="repaymentDetails.interestRate"
                  ref="repaymentDetails.interestRate"
                  className="form-control form-control-lg"
                  type="number"
                  autoFocus
                  step={0.01}
                  label="Interest Rate"
                  value=""
                  changeDebounceInterval={0}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs, { decimal: 2 });
                  }}
                  validationError="Interest rate is required"
                  validations={{
                    isFloat: true,
                    isNotZero: true,
                    isExisty: true,
                    isRequired: true,
                  }}
                />
              </Col>

              <Col sm={3}>
                <div className="form-prefix" style={{ lineHeight: '46px' }}>$</div>
                <Input
                  id="repaymentDetails.loanAmount"
                  name="repaymentDetails.loanAmount"
                  ref="repaymentDetails.loanAmount"
                  type="text"
                  label="Loan Amount"
                  value="0"
                  changeDebounceInterval={0}
                  updateOnBlur={false}
                  blurCallback={(name, value) => {
                    Mask.numberFormsy(name, value, this.refs);
                  }}
                  className="form-control form-control-lg"
                  validationError="Loan amount is required"
                  validations={{
                    isFormattedNumber: true,
                    isRequired: true,
                  }}
                />
              </Col>

              <Col sm={2}>
                <Select
                  id="repaymentDetails.term"
                  name="repaymentDetails.term"
                  label="Loan Term"
                  value={30}
                  options={functions.getLoanTerms()}
                  className="form-control form-control-lg"
                />
              </Col>

              <Col sm={5}>
                <Select
                  id="repaymentDetails.interestOnly"
                  name="repaymentDetails.interestOnly"
                  label="Repayments"
                  value={0}
                  options={functions.getRepayments()}
                  className="form-control form-control-lg"
                />
              </Col>

            </Row>
          </div>
        </div>
      </div>
    );
  }
}
