import * as React from 'react';
import PropTypes from 'prop-types';
import Notifications from 'react-notify-toast';

const ReactTooltip = require('react-tooltip');

import HeaderPane from './components/headerPane'
import PromptPane from './components/promptPane';
import FeedbackPane from './components/feedbackPane';

const App = (props) => {
  return (
    <div>
      <HeaderPane />
      <PromptPane />

      <div className='container'>
        {props.children}
      </div>

      <Notifications />

      <FeedbackPane />

      <ReactTooltip />
    </div>
  );
};

export default App;
