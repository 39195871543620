import * as React from 'react';
import PropTypes from 'prop-types';

import ServiceAbilityExpense from './serviceAbilityExpense';

export default class ServiceAbilityExpenses extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <div>
        <div className="panel-section np">
          <div className="panel-section-header panel-header-fixed">
            <i className="mi mi-money-box mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

            <div className="ib va-tt mt-sm-n">
              <h3 className="mb-h mt-h">Ongoing commitments</h3>
            </div>
          </div>

          <div className="panel-section-body">
            <div className="panel-section-container m-h p-md bg-primary-50">
              <ServiceAbilityExpense
                data={this.props.data}
                isMotorServiceability={this.props.isMotorServiceability}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ServiceAbilityExpenses.propTypes = {
  isMotorServiceability: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

ServiceAbilityExpenses.defaultProps = {
  isMotorServiceability: false,
};
