import * as React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Guid } from 'guid-typescript';
import Icon from '../../../utils/icon';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeEmployment } from './actions';

interface RemoveEmploymentButtonProps {
  employmentId: Guid
  actions: {
    removeEmployment: typeof removeEmployment
  }
}

export const RemoveEmploymentButton = (props: RemoveEmploymentButtonProps) => (
  <div>
    <button
      type="button"
      className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle"
      style={{ marginTop: '31px' }}
      onClick={() => { props.actions.removeEmployment(props.employmentId) }}>
      <Icon glyph="delete" />
    </button>
    <Col sm={12} className="visible-xs text-right">
      <button type="button" className="mb-md btn btn-danger">
        Remove Employment
              </button>
    </Col>
  </div>
)

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ removeEmployment }, dispatch)
  };
}

export default connect(() => ({}), mapDispatchToProps)(RemoveEmploymentButton);
