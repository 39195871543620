import * as React from 'react';
import PropTypes from 'prop-types';
import {Input, Select, Checkbox} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import AddressInput from '../addressInput';
import Remove from '../remove';
import Title from '../title';
import Ownership from '../ownership';
import Estate from '../estate';

import Mask from '../../../helpers/mask';

import {toRequired, selectText} from '../functions';
import {IApplicants} from '../interfaces';

import {valuationOptions, propertyTypeOptions, propertyTitleOptions, propertyZoneOptions, propertyUseOptions, landSizeOptions} from '../options';
import { CurrencyInput } from '../currencyInput';

interface IPurchaseProps {
  index: number;
  id: string;
  data: any;
  removePurpose: any;
  updatePurchaseAmount: any;
  form: any;
}

interface IPurchaseState {
}

export class Purchase extends React.Component<IPurchaseProps, IPurchaseState> {
  get fields(): string[] {
    return ["Deposit", "Gifts", "FHOG", "Finance", "Other"];
  }

  updateTotalAmount() {
    if (this.props.form.current) {
      let data = this.props.form.current.formsyForm.current.getModel();

      let paidAmount = this.fields.reduce((value, field) => {
        return value + Number(Mask.clean(data.purchases[this.props.index][field.toLowerCase()]));
      }, 0);
      let purchasedAmount = Number(Mask.clean(data.purchases[this.props.index]["value"]));
      let remainingAmount = purchasedAmount - paidAmount;

      this.props.updatePurchaseAmount(this.props.id, remainingAmount);
    }
  }

  render(): JSX.Element {
    return (
      <div className={`card p-lg mt-lg`}>
        <Title top>
          Purchase property
          <Remove onClick={this.props.removePurpose.bind(this, this.props.id)} />
        </Title>

        <Estate name={`purchases[${this.props.index}]`} id={this.props.id} updateValue={this.updateTotalAmount.bind(this)} context="purchase" />

        <Title>Contribution</Title>

        <Row>
          <Col sm={3} smOffset={9}>
            <label>{toRequired("Amount")}</label>
          </Col>
        </Row>

        {
          this.fields.map(field => {
            return (
              <Row key={`purchase_${this.props.index}_contribution_${field}`}>
                <Col sm={9} className="pt-md mute">
                  {field}
                </Col>
                <Col sm={3}>
                  <CurrencyInput
                    name={`purchases[${this.props.index}].${field.toLowerCase()}`}
                    validations={{
                      isRequired: true
                    }}
                    validationErrors={{
                      isRequired: `${field} is required`
                    }}
                    align="right"
                    blurCallback={this.updateTotalAmount.bind(this)}
                  />
                </Col>
              </Row>
            );
          })
        }

        <Ownership applicants={this.props.data.applicants} name={`purchases[${this.props.index}]`} />
      </div>
    );
  }

}

export default Purchase;
