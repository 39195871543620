import { ServiceAbilitySuggestionAction, ServiceAbilitySuggestionActionType } from './actions';

export interface ServiceAbilitySuggestionState {
  preferredSurplus: number
};

export const initialState: ServiceAbilitySuggestionState = {
  preferredSurplus: 200
};

export const serviceAbilitySuggestionReducer = (state: ServiceAbilitySuggestionState = initialState, action: ServiceAbilitySuggestionAction): ServiceAbilitySuggestionState  => {
  switch (action.type) {
    case ServiceAbilitySuggestionActionType.SetPreferredSurplus:
      return {
        ...state,
        preferredSurplus: Number(action.value)
      };

    default:
      return state;
  }
};