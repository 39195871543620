import {ISubmitAction} from './actions';
import {IDataLabel} from './interfaces';
import {AxiosResponse} from 'axios';

type IData = any;

export interface ISubmissionState {
  load: ILoadApplication;
  save: ISaveApplication;
  submit: ISubmitApplication;

  data: IData; // TODO
  notes: {
    visible: boolean;
    text: string;
  };
}

export type ILoadApplication =
  IInitial | 
  ILoaded |
  ILoadedSuccessful |
  ILoadedFailed |
  IExplosion;

export type ISaveApplication =
  IInitial | 
  ISaved | 
  ISavedSuccessful | 
  ISavedFailed |
  IExplosion;

export type ISubmitApplication = 
  IInitial | 
  ISubmitted | 
  ISubmittedSuccessful | 
  ISubmittedFailedValidation | 
  ISubmittedFailedApi | 
  IExplosion

interface IInitial {
  status: "initial";
}

interface ILoaded {
  status: "loading"
}
interface ILoadedSuccessful {
  status: "loaded";
}
interface ILoadedFailed extends IFailed {
  status: "error";
}

interface ISaved {
  status: "saving";
}
interface ISavedSuccessful {
  status: "saved";
}
interface ISavedFailed extends IFailed {
  status: "error";
}


interface ISubmitted {
  status: "submitting";
}
export interface ISubmittedSuccessful {
  status: "submitted"
  applicationNumber: string; // TODO change to number
}
export interface ISubmittedFailedValidation extends IFailed {
  status: "validationError";
}
export interface ISubmittedFailedApi extends IFailed {
  status: "apiError";
}
interface IFailed {
  status: string;
  errors: IError[];
}
interface IError {
  key: string;
  message: string;
}
export interface IExplosion {
  status: "explosion";
}



const explosionState: IExplosion = { status: "explosion" };
const submittedState: ISubmitted = { status: "submitting" };
const initialLoadState: IInitial = { status: "initial" };
const initialSaveState: IInitial = { status: "initial" };
const initialSubmitState: IInitial = { status: "initial" };

export const initialState: ISubmissionState = {
  load: initialLoadState,
  save: initialSaveState,
  submit: initialSubmitState,
  data: {},
  notes: {
    visible: false,
    text: ""
  }
}

function responseUnexpected(response: AxiosResponse): boolean {
  return response.data === undefined;
}

function parseLoadRequest(): ILoaded {
  return {
    status: "loading"
  };
}
function parseLoadSuccessResponse(response: AxiosResponse): ILoadedSuccessful | IExplosion {
  if (typeof response.data.applicationId !== "string") return explosionState;
  
  let successfulResponse: ILoadedSuccessful = { 
    status: "loaded"
  };

  return successfulResponse;
}

// TODO seperate this
function parseLoadSuccessData(response: AxiosResponse): IData {
  return response.data;
}

function parseLoadSuccessNotes(response: AxiosResponse): IData {
  return  {
    visible: false,
    text: response.data.notes
  };
}

function parseSaveSuccessResponse(response: AxiosResponse): ISavedSuccessful {  
  let successfulResponse: ISavedSuccessful = { 
    status: "saved"
  };

  return successfulResponse;
} 
function parseSaveData(currentData: IData, newData: IData, label: IDataLabel) {
  const mergedData = {...currentData, [label]: newData};
  return mergedData;
}

function parseSubmitRequest(): ISubmitted {
  return {
    status: "submitting"
  };
}
function parseSubmitSuccessResponse(response: AxiosResponse): ISubmittedSuccessful | IExplosion {
  if (typeof response.data.applicationNumber !== "string") return explosionState;
    
  let successfulResponse: ISubmittedSuccessful = {
    status: "submitted",
    applicationNumber: String(response.data.applicationNumber)
  };

  return successfulResponse;
}
function parseSubmitFailedValidationResponse(response: AxiosResponse): ISubmittedFailedValidation | IExplosion {
  if (!Array.isArray(response.data.errors)) return explosionState;

  return {
    status: "validationError",
    errors: response.data.errors
  };
}
function parseSubmitFailedApiResponse(response: AxiosResponse): ISubmittedFailedApi | IExplosion {
  if (!Array.isArray(response.data.errors)) return explosionState;
  
  return {
    status: "apiError",
    errors: response.data.errors
  };
}

export default function submissionReducer(state: ISubmissionState = initialState, action: ISubmitAction): ISubmissionState {  
  switch (action.type) {
    case "NOTES_UPDATE": {
      const notes = {
        ...state.notes,
        text: action.text
      };
      return {...state, notes};
    }

    case "NOTES_TOGGLE": {
      const notes = {
        ...state.notes,
        visible: !state.notes.visible
      };
      return {...state, notes};
    }
    
    case "LOAD_GET": {
      return {...state, load: parseLoadRequest()}
    }

    case "LOAD_INIT": {
      return {...state, load: { status: "loaded" }}
    }

    case "LOAD_RESET": {
      return {...initialState};
    }

    case "LOAD_RESPONSE": {
      if (responseUnexpected(action.response)) {
        return {...state, load: explosionState}
      }

      switch (action.response.status) {
        case 200: 
        case 400: 
        case 404: // REMOVE THIS
        case 500: {
          return {...state, load: parseLoadSuccessResponse(action.response), data: parseLoadSuccessData(action.response), notes: parseLoadSuccessNotes(action.response)};
        }
      }
    }

    case "SAVE_POST": {
      return {...state, save: { status: "saving" }};
    }
    case "SAVE_RESPONSE": {
      if (responseUnexpected(action.response)) {
        return {...state, save: explosionState};
      }

      switch (action.response.status) {
        case 200: // 200 should not happen, how should I handle this
        case 201: {
          return {...state, save: parseSaveSuccessResponse(action.response), data: action.data};
        }

        case 400:
        case 500: {
          return {...state, save: explosionState};
        }
      }
    }
    case "SUBMIT_POST": {
      return {...state, submit: parseSubmitRequest()};
    }
    case "SUBMIT_RESPONSE": {      
      if (responseUnexpected(action.response)) {
        return {...state, submit: explosionState}
      }

      switch (action.response.status) {
        case 201: {
          return {...state, submit: parseSubmitSuccessResponse(action.response)};
        }
        case 200: {
          return {...state, submit: parseSubmitFailedValidationResponse(action.response)};
        }
        case 400: 
        case 500: {
          return {...state, submit: parseSubmitFailedApiResponse(action.response)};
        }
      }
    }

    default: {
      return state;
    }
  }
}