import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select, Checkbox} from 'formsy-react-components';

import * as Moment from 'moment';
import Mask from '../../helpers/mask';
import {toOption, toRequired, selectText} from './functions';
import AddressInput from './addressInput';
import Title from './title';
import CurrencyInput from './currencyInput';
import DateInput from './dateInput';

import {valuationOptions, propertyTypeOptions, propertyTitleOptions, propertyZoneOptions, currentUseOptions, propertyUseOptions, landSizeOptions} from './options';

import {IOption} from '../../interfaces';

import {IGuid, IRentalIncome} from './interfaces';

type uses = "residence" | "investment" | "toBeSold"

interface IEstateProps {
  name: string;
  id: IGuid;
  investment?: IRentalIncome;
  context: "asset" | "purchase";
  updateValue: (id:IGuid, value:string) => void;
}

interface IEstateState {
  use: uses;
}

function isInvestment(investment?: IRentalIncome): boolean {
  return !!investment;
}

export class Estate extends React.Component<IEstateProps, IEstateState> {
  constructor(props) {
    super(props);

    this.state = {
      use: "residence"
    }

    this.changeUse = this.changeUse.bind(this);
  }

  componentDidMount() {
    if (isInvestment(this.props.investment)) {
      this.changeUse(null, "investment");
      (this.refs[`${this.props.name}.use`] as any).setValue('investment');
    }
  }

  changeUse(_, use: uses) {
    this.setState({use});
  }

  willBeSold(): boolean {
    return this.state.use == 'toBeSold';
  }

  validateInvestment = (_, value?: string) => {
    if (this.state.use === 'residence') {
      return true;
    }

    return value !== undefined && value.trim().length;
  }

  willBePurchased(): boolean {
    return this.props.context == "purchase";
  }

  get useOptions(): IOption[] {
    return this.props.context == "purchase" ? propertyUseOptions : currentUseOptions;
  }

  render(): JSX.Element {
    return (
      <div>
        <Input
          name={`${this.props.name}.type`}
          type="hidden"
          value="property"
        />

        <Input
          name={`${this.props.name}.id`}
          type="hidden"
          value={this.props.id}
        />

        <Row>
          <Col sm={6}>
            <AddressInput
              name={`${this.props.name}.address`}
              address={this.props.investment ? this.props.investment.address : undefined}
              label={toRequired("Address")}
              validations={{
                isRequired: true
              }}
            />
          </Col>

          <Col sm={3}>
            <Select
              name={`${this.props.name}.valuation`}
              id={`${this.props.name}.valuation`}
              label={toRequired("Valuation")}
              options={valuationOptions}
              value={valuationOptions[0].value}
              className="form-control" />
          </Col>

          <Col sm={3}>
            <CurrencyInput
              name={`${this.props.name}.value`}
              value="0"
              label={toRequired("Value")}
              validations={{
                isRequired: true,
                isNotZero: true,
              }}
              validationErrors={{
                isRequired: "Value is required",
                isNotZero: "Value can not be 0"
              }}
              align="right"
              blurCallback={(_, value) => this.props.updateValue(this.props.id, value)}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={2}>
            <Select
              name={`${this.props.name}.property`}
              id={`${this.props.name}.property`}
              label={toRequired("Property type")}
              options={propertyTypeOptions}
              value={propertyTypeOptions[0].value}
              className="form-control" />
          </Col>

          <Col sm={2}>
            <Select
              name={`${this.props.name}.title`}
              id={`${this.props.name}.title`}
              label={toRequired("Title")}
              options={propertyTitleOptions}
              value={propertyTitleOptions[0].value}
              className="form-control"/>
          </Col>

          <Col sm={3}>
            <Select
              name={`${this.props.name}.zoning`}
              id={`${this.props.name}.zoning`}
              label={toRequired("Zoning")}
              options={propertyZoneOptions}
              value={propertyZoneOptions[0].value}
              className="form-control" />
          </Col>

          <Col sm={3}>
            <Select
              name={`${this.props.name}.size`}
              id={`${this.props.name}.size`}
              label={toRequired("Land size")}
              options={landSizeOptions}
              value={landSizeOptions[0].value}
              className="form-control" />
          </Col>
        </Row>

        <Row>

          <Col sm={3}>
            <Select
              name={`${this.props.name}.use`}
              ref={`${this.props.name}.use`}
              id={`${this.props.name}.use`}
              label={toRequired("Use after settlement")}
              changeCallback={this.changeUse}
              options={this.useOptions}
              value={this.useOptions[0].value}
              className="form-control" />
          </Col>


         {
            (() => {
              if (!this.willBeSold()) {
                return (
                  <Col sm={3} style={{marginTop: '12px'}}>
                    <Checkbox
                      id={`${this.props.name}.security`}
                      name={`${this.props.name}.security`}
                      ref={`${this.props.name}.security`}
                      rowClassName="ib mr-sm"
                      valueLabel={<span>Security for this application</span>}
                    />
                  </Col>
                );
              }
            })()
          }
        </Row>

        <div className={this.state.use == 'investment' ? "" : "hidden"}>
          <Title>Investment</Title>
          <Row>
            <Col sm={3}>
              <CurrencyInput
                name={`${this.props.name}.rent`}
                value={this.props.investment && this.props.investment.incomeAmount}
                label={toRequired(<span>Amount <small className="mute">(gross weekly)</small></span>)}
                validations={{
                  isRequiredWhenInvestment: this.validateInvestment
                }}
                validationErrors={{
                  isRequiredWhenInvestment: "Rent is required",
                }}
              />
            </Col>

            <Col sm={3}>
              <CurrencyInput
                name={`${this.props.name}.deductions`}
                value={this.props.investment && this.props.investment.deductionAmount}
                label={toRequired(<span>Deductions <small className="mute">(gross yearly)</small></span>)}
                validations={{
                  isRequiredWhenInvestment: this.validateInvestment
                }}
                validationErrors={{
                  isRequiredWhenInvestment: "Deductions are required",
                }}
              />
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

export default Estate;
