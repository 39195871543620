import * as React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import * as Guid from 'guid';

import Mask from '../../../helpers/mask';

import Navigation from '../navigation';
import Header from '../header';
import Add from '../add';

import {debug} from '../debug';
import {shouldFormBeVisible, getCurrentFormData, hasFormData, setFormData} from '../functions';

import Individual from './individual';

import {IBaseProps, IFormData, IGuid} from '../interfaces';

interface IIndividualItem {
  id: string;
  fullName: string;
  askPreviousAddress: boolean;
  dependants: number[];
}

interface IApplicantState {
  individuals: IIndividualItem[];
}

interface IApplicantProps extends IBaseProps {
}

export class Applicants extends React.Component<IApplicantProps, IApplicantState> {
  constructor(props) {
    super(props);

    this.state = {
      individuals: []
    };

    this.addIndividual = this.addIndividual.bind(this);
    this.changeIndividualName = this.changeIndividualName.bind(this);
    this.changeDependants = this.changeDependants.bind(this);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (hasFormData(this.props.data, 'applicants')) {
      this.props.data.applicants.individuals.forEach((individual, index) => {
        this.addIndividual(true);
        this.changeDependants(undefined, (individual.dependants || []).length, index);
      });
    } else {
      this.addIndividual();
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'applicants')) {
      setFormData(this.props.data, 'applicants', this.refs.form)
    }
  }

  addIndividual(askPreviousAddress = false, dependants = []) {
    let individuals = this.state.individuals;
    individuals.push({
      id: Guid.create().value,
      fullName: '',
      askPreviousAddress,
      dependants
    });

    this.setState({
      individuals
    });
  }

  setAskPreviousAddress(id: IGuid, askPreviousAddress: boolean) {
    let individuals = this.state.individuals.map(individual => {
      if (individual.id === id) {
        individual.askPreviousAddress = askPreviousAddress;
      }
      return individual;
    });
    this.setState({individuals})
  }

  changeIndividualName(index: number, name: string) {
    let individuals = this.state.individuals;
    individuals[index].fullName = name;
    this.setState({
      individuals
    });
  }

  changeDependants(_, num: number, index: number): void {
    let dependants: number[] = [];
    for (let i = 0; i < num; i++) {
      dependants.push(i);
    }

    let individuals = this.state.individuals;
    individuals[index].dependants = dependants;
    this.setState({
      individuals
    });
  }

  formatStepData(data: IFormData): IFormData {
    data.individuals.forEach(individual => {
      individual = this.ensureDependants(individual);
      individual = this.formatAddresses(individual);
      return individual;
    });

    return data;
  }

  ensureDependants(individual) {
    individual._dependants = 0
      individual.dependants = [];
    return individual;
  }

  formatAddresses(individual) {
    if (!individual.previousAddress) {
      individual.previousAddress = null;
    }

    if (!individual.postAddress.autocomplete) {
      individual.postAddress = null;
    }

    return individual;
  }

  onValidSubmit(data: IFormData) {
    data = this.formatStepData(data);
    this.props.saveStepData(data, 'applicants');
  }

  onInvalidSubmit() {
    this.props.notifyStep();
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        className={shouldFormBeVisible(this.props.currentStep, 0)}
        autoComplete="no"
        role="presentation"
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">
            <Header title="Applicants" required />
            {
              this.state.individuals.map((individual, index) => <Individual key={individual.id} index={index} individual={individual} individuals={this.state.individuals} changeDependants={this.changeDependants} setAskPreviousAddress={this.setAskPreviousAddress.bind(this, individual.id)} changeIndividualName={this.changeIndividualName} />)
            }
          </div>

        </div>

        <Navigation saveSubmission={() => this.props.saveSubmission(getCurrentFormData(this.refs.form), "applicants")} />
      </Form>
    );
  }
}

export default Applicants;
