import * as React from 'react';
import PropTypes from 'prop-types';
import * as Guid from 'guid';

import ComServiceAbilityCompany from './comServiceAbilityCompany';
import { Icon } from '../../utils';

export default class ComServiceAbilityCompanies extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      companies: [],
    };

    this.addCompany = this.addCompany.bind(this);
    this.removeCompany = this.removeCompany.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.companies) {
      const companies = [];
      this.props.data.companies.forEach((company) => {
        company.companyId = company.id || Guid.create().value;
        companies.push(company);
      });

      this.setState({
        companies,
      });
    }
  }

  addCompany() {
    const { companies } = this.state;
    companies.push({
      companyId: Guid.create().value,
      name: '',
      relationship: 1,
      partner: '',
    });
    this.setState({
      companies,
    }, () => this.props.OnCompaniesChanged());
  }

  removeCompany(index) {
    const companies = Object.assign([], this.state.companies);

    if (companies[index].partner) {
      companies.forEach((company, instance) => {
        if (company.companyId == companies[index].partner) {
          companies[instance].partner = '';
        }
      });
    }

    companies.splice(index, 1);
    this.setState({
      companies,
    }, () => this.props.OnCompaniesChanged());
  }

  handleName(name, index) {
    const { companies } = this.state;
    companies[index].name = name;
    this.setState({
      companies,
    }, () => this.props.OnCompaniesChanged());
  }

  handleRelationship(relationship, index) {
    const { companies } = this.state;

    if (relationship == 1) {
      if (companies[index].inRelationshipWithId) {
        companies.forEach((company, instance) => {
          if (company.companyId == companies[index].inRelationshipWithId) {
            companies[instance].inRelationshipWithId = '';
          }
        });
      }
    }

    companies[index].relationship = relationship;
    companies[index].inRelationshipWithId = '';

    this.setState({
      companies,
    });
  }

  handlePartner(inRelationshipWithId, index) {
    const { companies } = this.state;

    companies.forEach((company, instance) => {
      if (inRelationshipWithId == '') {
        if (company.companyId == companies[index].inRelationshipWithId) {
          companies[instance].inRelationshipWithId = '';
        }
      }

      if (company.companyId == inRelationshipWithId) {
        companies[instance].relationship = 2;
        companies[instance].inRelationshipWithId = companies[index].companyId;
      }
    });

    companies[index].inRelationshipWithId = inRelationshipWithId;

    this.setState({
      companies,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section np">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-city mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">
            Business Applicants
            {' '}
            <small className="mute">(if applicable)</small>
            </h3>
          </div>
        </div>
        <div className="panel-section-body companies">
          <div className="panel-section-container m-h p-md bg-primary-50">
            {
              this.state.companies.map((company, index) => (
                  <ComServiceAbilityCompany
                    key={`company-${company.companyId}`}
                    index={index}
                    data={this.props.data && this.props.data.companies ? this.props.data.companies[index] : null}
                    company={{ ...company }}
                    companies={this.state.companies}
                    handleName={this.handleName}
                    handleRelationship={this.handleRelationship}
                    handlePartner={this.handlePartner}
                    removeCompany={this.removeCompany}
                  />
              ))
            }

            <button style={{ width: '100%', fontSize: '15px' }} type="button" className="card card-shallow card-mute card-hover mt-md" id="addcompany" onClick={this.addCompany}>
              <strong className="secondary">
              <Icon glyph="plus" className="primary mute-lg mr-sm" />
              {' '}
              Add business applicant
              </strong>
            </button>

          </div>
        </div>
      </div>
    );
  }
}

ComServiceAbilityCompanies.propTypes = {
  data: PropTypes.object.isRequired,
  OnCompaniesChanged: PropTypes.func.isRequired,
};

ComServiceAbilityCompanies.defaultProps = {
};
