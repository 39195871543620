import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MotorPrequalToolSuccessResult from './motorPrequalToolSuccessResult'
import MotorPrequalDeclinedResult from './motorPrequalDeclinedResult'
import { AssessmentResult } from './reducer';

interface ResultProps {
  assessmentResult: AssessmentResult,
  isMortageAggregator: boolean
}

export const MotorPrequalToolResult = (props: ResultProps) =>
  <div id="prequal-results" className="panel-section">
    <div className="panel-section-body panel-section-body-tint">
      <div className="panel-section-container text-center">
        {(() => {
          switch (props.assessmentResult) {
            case AssessmentResult.Accepted: 
              return <MotorPrequalToolSuccessResult showDiscountSlider={!props.isMortageAggregator}/>
            case AssessmentResult.Declined:
              return <MotorPrequalDeclinedResult />
            default:
              return;
          }
        })()
        }
      </div>
    </div>
  </div>

function mapStateToProps({ motorPrequal, session }) {
  let isMortageAggregator = session.details?.adjustmentProfileID == 9
  return {
    assessmentResult: motorPrequal.assessmentResult,
    isMortageAggregator: isMortageAggregator,
  };
}

export default connect(mapStateToProps, {})(MotorPrequalToolResult);
