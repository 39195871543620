import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingDots extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const dots = [];
    for (let idx = 0; idx < this.props.dots; idx++) dots.push(idx + 1);

    return (
      <div className="onboarding-dots">
        {
          dots.map((dot) => <div key={`dot-${dot}`} className={`dot dot-${this.props.step >= dot ? 'secondary' : ''}`} />)
        }
      </div>
    );
  }
}
