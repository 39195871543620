import * as React from 'react';
import PropTypes from 'prop-types';

import ApplicationBar from './applicationBar';

export default class ApplicationInfo extends React.Component {
  /**
     * Constructor
     */
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      scrollClass: 'static',
      expanded: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollTop = window.pageYOffset;
    let scrollClass = 'static';

    if (scrollTop > 120) {
      scrollClass = 'fixed';
    }

    this.setState({
      scrollClass,
    });
  }

  /**
     * Render
     * @return ReactComponent
     */
  render() {
    return (
          <div className={`bar-wrapper bar-${this.state.scrollClass} bar-${this.props.processing ? 'fixed' : 'default'}`}>
            <ApplicationBar setTab={this.props.setTab} tab={this.props.tab} application={this.props.application} update={this.props.update} expanded={this.state.expanded} processing={this.props.processing} position="fixed" />
            <ApplicationBar setTab={this.props.setTab} tab={this.props.tab} application={this.props.application} update={this.props.update} expanded={this.state.expanded} processing={this.props.processing} position="static" />
          </div>
    );
  }
}

ApplicationInfo.propTypes = {
  application: PropTypes.object.isRequired,
  update: PropTypes.bool,
  processing: PropTypes.any,
};
