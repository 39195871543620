import * as React from 'react';
import PropTypes from 'prop-types';
import MotorPrequalModal from './motorPrequalModal';

export default class MotorPrequalToolHeader extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      fineprint: false,
    };

    this.toggleFineprint = this.toggleFineprint.bind(this);
  }

  toggleFineprint() {
    this.setState({
      fineprint: !this.state.fineprint,
    });
  }

  /**
     * Render
     */
  render() {
    return (
          <div className="mt-lg pt-sm mb-lg text-center">
            <h1 className="mt-lg">Pre-Qualification Tool</h1>
            <h4 className="mute">Get an estimate on the repayments and interest rate for your customer</h4>
            <button type="button" id="showMotorPrequalodal" className="btn btn-link" onClick={this.toggleFineprint}>
            {'Things you should know '}
            <span className="hidden-xs">about this tool</span>
            </button>

            <MotorPrequalModal hideModal={this.toggleFineprint} visible={this.state.fineprint} />
          </div>
    );
  }
}
