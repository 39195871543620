import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';
import Mask from '../../../helpers/mask';

import Title from '../title';

import {toOption, toRequired, selectText} from '../functions';
import {IOption} from '../../../interfaces';
import { CurrencyInput } from '../currencyInput';

interface IPersonalProps {
  updateTotalPersonalAmount: (totalAmount: number) => void;
  form: any;
}
interface IPersonalState {}

export class Personal extends React.Component<IPersonalProps, IPersonalState> {
  UNSAFE_componentWillMount() {
    this.state = {};

    this.updateTotalAmount = this.updateTotalAmount.bind(this);
}

  updateTotalAmount() {
    if (this.props.form.current) {
      let data = this.props.form.current.formsyForm.current.getModel();

      let totalAmount = this.uses.reduce((value, use) => {
        return value + Number(Mask.clean(data.uses[use.value].amount))
      }, 0);

      this.props.updateTotalPersonalAmount(totalAmount);
    }
  }

  get uses(): IOption[] {
    return [
      "Buy consumer goods",
      "Travel",
      "Pay bills",
      "Cash out",
      "Other"
    ].map(o => toOption(o));
  }

  render() {
    return (
      <div>
        <Title>Personal use</Title>
        <div className="panel-section-body panel-section-body-simple">

          <Row>
            <Col sm={6} smOffset={3}>
              <label>Description</label>
            </Col>
            <Col sm={3}>
              <label>Amount</label>
            </Col>
          </Row>

          {
            this.uses.map(use =>
              <Row key={use.value}>
                <Col sm={3} className="mute">
                  {use.label}
                </Col>
                <Col sm={6}>
                  <Input
                    name={`uses.${use.value}.desc`}
                    value=""
                    maxLength={50}
                    />
                </Col>
                <Col sm={3}>
                  <CurrencyInput
                    name={`uses.${use.value}.amount`}
                    validations={{
                      isRequired: true
                    }}
                    validationErrors={{
                      isRequired: "Amount is required"
                    }}
                    blurCallback={this.updateTotalAmount}
                    align="right"
                  />
                </Col>
              </Row>
            )
          }

        </div>
      </div>
    );
  }
}

export default Personal;
