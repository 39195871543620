import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../utils';

interface AddButtonProps {
  glyph: string;
  label: string;
  id?: string;
  onClick: () => void
}

const AddButton = ({glyph, label, onClick, id}: AddButtonProps): JSX.Element => {
  let attr: {[name: string]: string} = {
    className: "btn btn-default pt-md pb-md pl-lg pr-lg ml-xs mr-xs",
    type: "button"
  };

  if (id) {
    attr.id = id
  }

  return (
    <button {...attr} onClick={onClick}>
      <Icon glyph={glyph} size={"lg"} className="b text-center primary mute mb-sm" />
      <Icon glyph="plus-circle" size={1} className="a success btn-add" />
      <span className="mute">Add</span> <span className="secondary">{label.toLowerCase()}</span>
    </button>
  );
}

export default AddButton;
