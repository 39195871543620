import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import { Input } from 'formsy-react-components';
import Formsy from 'formsy-react';

import * as referenceActions from '../../actions/referenceActions';
import { changeLoanInfo } from '../veiCalculatorPage/actions';

import ApplicationUpdateHeader from './applicationUpdateHeader';
import ApplicationUpdateVei, { ApplicationUpdateVeiProps, VeiOption } from './applicationUpdateVei';
import ApplicationUpdateLpi from './applicationUpdateLpi';

import Mask from '../../helpers/mask';

import { ISession } from '../../interfaces';
import { PRODUCTGROUPS } from '../../constants/types';
import { VeiLoanInformation } from '../veiCalculatorPage/reducer';

type ApplicationUpdateInsuranceProps = {
  actions: any,
  veiOptions: VeiOption[],
  reference: any,
  session: ISession,
  application: any,
  params: any,
  formsy: Formsy | null,
}

type ApplicationUpdateInsuranceState = {
  visible: boolean,
  lpiSelected: boolean,
  veiSelected: boolean,
}

export class ApplicationUpdateInsurance extends React.Component<ApplicationUpdateInsuranceProps, ApplicationUpdateInsuranceState> {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.params.tab == 'insurance',
      lpiSelected: this.props.application.motorInsuranceInterest.interestedInLPI,
      veiSelected: this.props.application.motorInsuranceInterest.interestedInVEI,
    };

    this.toggleSection = this.toggleSection.bind(this);
    this.toggleInsurance = this.toggleInsurance.bind(this);
    this.filterInsurance = this.filterInsurance.bind(this);
    this.getVeiInformation = this.getVeiInformation.bind(this);
  }

  componentDidMount() {
    this.props.actions.getVeiTypes();
    this.props.actions.getLpiTypes();
    this.props.actions.getInsurancePaymentMethods();

    this.getVeiInformation();
  }


  filterInsurance(applicant) {
    return applicant.type.toLowerCase() === 'person'
      && ((applicant.status !== undefined && applicant.status.toLowerCase() === 'borrower') || applicant.status === undefined);
  }

  toggleInsurance(field, value) {
    switch (field) {
      case 'motorInsuranceInterest.interestedInLPI':
        this.setState({
          lpiSelected: value,
        });
        break;
      case 'motorInsuranceInterest.interestedInVEI':
        this.setState({
          veiSelected: value,
        });
        break;
    }
  }

  toggleSection() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  getVeiInformation() {
    let change = (l: VeiLoanInformation, v: VeiLoanInformation) => v;
    let payload = {
      vehicleValuationAmount: this.props.application?.loanAssessmentResult?.securityValue,
      vehicleYear: this.props.application?.motorPurchaseVehicle?.year,
      loanTermYears: this.props.application?.loanTerm,
      loanAmount: this.props.application?.amount,
      balloonAmount: this.props.application?.motorPurpose?.balloonResidual,
      interestRate: +(this.props.application?.loanAssessmentResult?.interestRate) * 100
    }
    this.props.actions.changeLoanInfo(change, 'populate', payload, false)
  }

  render() {
    return (
      <div className={`panel-section ${this.state.visible ? '' : 'collapsed'}`}>
        <ApplicationUpdateHeader
          title="Insurance &amp; Protection"
          description="Protect your customer"
          glyph="shield-security"
          section="insurance"
          toggleSection={this.toggleSection}
        />


        <div className="panel-section-body">
          <div className="panel-section-container">
            <h5 className="mt-md">Insurance</h5>
            <Row>
              <Col sm={3}>
                <div className="form-prefix">$</div>

                <Input
                  name="motorPurpose.comprehensiveInsuranceAmount"
                  id="motorPurpose.comprehensiveInsuranceAmount"
                  ref="motorPurpose.comprehensiveInsuranceAmount"
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label="Comprehensive Insurance"
                  value=""
                  validationError="Comprehensive insurance must be positive"
                  validations={{
                    isFormattedNumber: true,
                  }}
                />
              </Col>

              <Col sm={3}>
                <div className="form-prefix">$</div>

                <Input
                  name="motorPurpose.extendedWarranty"
                  id="motorPurpose.extendedWarranty"
                  ref="motorPurpose.extendedWarranty"
                  updateOnChange={false}
                  updateOnBlur={false}
                  blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  label="Extended Warranty"
                  value=""
                  validationError="Extended warranty must be positive"
                  validations={{
                    isFormattedNumber: true,
                  }}
                />
              </Col>
              {
                this.props.application.productGroupId === PRODUCTGROUPS.MOTOR_NOVATED_LEASE && (
                  <Col sm={3}>
                    <div className="form-prefix">$</div>
                    <Input
                      name="motorPurpose.services"
                      id="motorPurpose.services"
                      ref="motorPurpose.services"
                      updateOnChange={false}
                      updateOnBlur={false}
                      blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                      onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                      label="Services"
                      value="0.0"
                      validationError="Services is required"
                      validations={{
                        isFormattedNumber: true,
                        
                      }}
                    />
                  </Col>
                )
              }              
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
    session: state.session,
    veiOptions: state.veiCalculator.validVeiOptions || state.reference.veiTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions, changeLoanInfo }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateInsurance);
