import * as React from 'react';
import PropTypes from 'prop-types';

//import RepaymentsModal from './repaymentsModal';

export const VeiCalculatorHeader = (props: any) =>
  <div className="mt-lg pt-sm mb-lg text-center">
    <h1 className="mt-lg">Vehicle Equity Insurance Eligibility Calculator</h1>
    <h4 className="mute">Easily estimate GAP insurance premiums for your customers</h4>
    {/* <button type="button" id="showServiceabilityModal" className="btn btn-link" onClick={this.toggleFineprint}>Things you should know<span className="hidden-xs"> about this calculator</span></button> */}

  </div>
