import * as React from 'react';
import PropTypes from 'prop-types';
import { LvrFilter } from './actions';

import * as ReactTooltip from 'react-tooltip';

interface LvrToggleBaseProps {
  label: string;
  name: string;
  changeCallback: (field: string, value: string) => void;
}

export interface ToggleOption {
  label: string;
  value: string;
}

export interface LvrToggleProps extends LvrToggleBaseProps {
  options: ToggleOption[];
  filter: LvrFilter;
  tooltip?: string;
}

interface LvrTogglePropsTooltip extends LvrToggleProps {
  tooltip: string;
}

interface LvrToggleOptionProps extends LvrToggleBaseProps {
  value: string;
  isActive: boolean;
}

const getUniqueToggleKey = (option: ToggleOption) => btoa(JSON.stringify(option));

const isActiveInFilter = (name: string, filter: LvrFilter, value: string) => filter[name] === value;

const getClassName = (isActive: boolean) => isActive ? 'secondary' : 'default';
const shouldRenderTooltip = (props: LvrToggleProps): props is LvrTogglePropsTooltip => !!props.tooltip;

const LvrToggleTooltip = ({children}: {children: string}) : JSX.Element => (
  <i
    style={{top: '3px', right: '-18px'}}
    className="btn-tooltip mute-lg a mi mi-help-outline pointer"
    data-multiline={true}
    data-position="fixed"
    data-event-on="click"
    data-tip={children}
  />
);

const LvrToggleOption = ({ label, name, value, changeCallback, isActive }: LvrToggleOptionProps) => (
  <button
    type="button"
    className={`btn btn-${getClassName(isActive)}`}
    onClick={() => { changeCallback(name, value) }}>
    {label}
  </button>
);

export class LvrToggle extends React.Component<LvrToggleProps, {}> {
  constructor(props) {
    super(props)
  };

  public componentDidMount() {
    ReactTooltip.rebuild();
  }

  public render() {
    return (
      <div className="ib mb-md">
        <label className="r control-label">
          {this.props.label}

          {shouldRenderTooltip(this.props) && <LvrToggleTooltip>{this.props.tooltip}</LvrToggleTooltip>}
        </label>
        <div className="btn-toggle-bar">
          {
            this.props.options.map(option => <LvrToggleOption key={getUniqueToggleKey(option)} {...this.props} {...option} isActive={isActiveInFilter(this.props.name, this.props.filter, option.value)} />)
          }
        </div>
      </div>
    );
  }
}

export default LvrToggle;
