import * as React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'formsy-react-components';
import Autocomplete from '../../helpers/autocomplete';
import {IAddress, IFormsyValidationRules, IFormsyValidationErrors} from './interfaces';

interface IAddressInputProps {
  name: string;
  address?: IAddress;
  label: string | JSX.Element;
  validations?: IFormsyValidationRules;
  disabled?: boolean;
}

interface IAddressInputState {
  address?: IAddress;
  autocomplete?: typeof Autocomplete;
}

export class AddressInput extends React.Component<IAddressInputProps, IAddressInputState> {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.setAddress = this.setAddress.bind(this);
    this.clearAddress = this.clearAddress.bind(this);
  }

  componentDidMount() {
    // init
    let autocomplete;
    let field = document.getElementById(this.getField('autocomplete'));
    let address: IAddress = this.props.address || this.emptyAddress;

    autocomplete = new Autocomplete(
      field,
      {
        types: ['address'],
        componentRestrictions: {
          country: 'au'
        }
      }
    );

    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      this.setAddress(place);
    });

    field && field.addEventListener('onInject', () => {
      setTimeout(this.injectAddress);
    });

    field && field.addEventListener('keydown', event => {
      if (event.keyCode == 13) {
        event.preventDefault();
      }
    });

    this.setState({
      autocomplete,
      address
    }, () => {
      let input = (this.refs[this.getField('autocomplete')] as any);
      input.setValue(address.autocomplete);
    });
  }

  clearAddress(): void {
    this.setState({
      address: undefined
    })
  }

  setAddress(result): void {
    let input = (this.refs[this.getField('autocomplete')] as any);
    input.setValue(result.formatted_address);

    let address: IAddress = this.emptyAddress;

    this.fields.map(field => {
      let value = result.address_components.filter(component => {
        return component.types && component.types.some(type => {
          return type == this.mapField(field);
        });
      });

      address[field] = value.length ? value[0].long_name : '';
    });

    this.setState({address})
  }

  get emptyAddress(): IAddress {
    return {
      autocomplete: "",
      unitNumber: "",
      streetNumber: "",
      streetName: "",
      suburb: "",
      state: "",
      country: "",
      postcode: "",
    }
  }

  injectAddress = () => {
    let address: IAddress = this.emptyAddress;
    this.fields.forEach(field => {
      address[field] = (this.refs[this.getField(field)] as any).getValue();
    });

    this.setState({address});
  }

  getField(field) {
    return `${this.props.name}.${field}`;
  }

  mapField(field) {
    let iq = this.fields;
    let google = ['subpremise', 'street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code'];

    return google[iq.indexOf(field)];
  }

  get fields() {
    return ['unitNumber', 'streetNumber', 'streetName', 'suburb', 'state', 'country', 'postcode'];
  }

  get validations() {
    let validations: IFormsyValidationRules = {...this.props.validations};

    validations.isSelectedAddress = (_, value?: string) => {
      if (!value) {
        return true;
      }

      return !!this.state.address;
    }

    return validations;
  }

  get validationErrors() {
    let validationErrors: IFormsyValidationErrors = {
      isSelectedAddress: "Address needs to be selected"
    };

    if (this.validations.isRequired) {
      validationErrors.isRequired = "Address is required"
    }

    return validationErrors;
  }

  render(): JSX.Element {
    return (
      <div>
        <Input
          name={this.getField('autocomplete')}
          id={this.getField('autocomplete')}
          ref={this.getField('autocomplete')}
          disabled={!!this.props.disabled}
          label={this.props.label}
          autoComplete="no"
          changeCallback={this.clearAddress}
          validations={this.validations}
          placeholder=""
          value=""
          validationErrors={this.validationErrors}
          changeDebounceInterval={0}
        />

        <div className="hidden bg-primary-50 ml-md-n mr-md-n p-md">
          {
            this.fields.map(component => {
              return (
                <Input
                  key={this.getField(component)}
                  name={this.getField(component)}
                  ref={this.getField(component)}
                  id={this.getField(component)}
                  value={this.state.address && this.state.address[component] || ""} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default AddressInput;
