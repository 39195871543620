import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Widget } from '../../utils';

import * as faqsActions from '../../actions/faqsActions';

import FaqsWidgetItem from './faqsWidgetItem';

export class FaqsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.filterFaqs = this.filterFaqs.bind(this);
  }

  filterFaqs(application, faqs) {
    const status = parseInt(application.applicationStatus.code.toString().substr(0, 1));
    const type = parseInt(application.applicationType.toString().substr(0, 1));

    return faqs.filter((faq) => {
      let include = true;

      if (faq.status.length && faq.status.indexOf(status) == -1) {
        include = false;
      }

      if (faq.type.length && faq.type.indexOf(type) == -1) {
        include = false;
      }

      return include;
    });
  }


  render() {
    const faqs = this.filterFaqs(this.props.application, this.props.faqs);

    if (!faqs.length || !this.props.application.applicationState) {
      return null;
    }

    return (
      <Widget title="Helpful hints">
        {
          faqs.map((faq, idx) => (() => (
                <FaqsWidgetItem key={`faq-${idx}`} faq={faq} />
          )
          )())
        }
      </Widget>
    );
  }
}

FaqsWidget.propTypes = {
  faqs: PropTypes.any,
  application: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    faqs: state.faqs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(faqsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqsWidget);
