import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';


import Icon from '../../utils/icon';

export class SparkModal extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm text-center">
            <Icon glyph="tablet-mac" className="mt-lg mb-md mute-hg" size={3} />
            <p className="text-lg mt-md mb-lg">Please refer to Loan Tracker in Spark to keep track of your deals.</p>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

SparkModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};


export default SparkModal;
