import {IApplication, IPartialApplication, IDataLabel, IData, IFormData, IIndividual} from './interfaces';
import Axios, { AxiosResponse } from 'axios'
import {Dispatch} from 'redux'

import {IGuid} from './interfaces';

import * as Guid from 'guid';
import API from '../../api';

export type ISubmitAction = 
  INotesUpdate | 
  INotesToggle |   

  ILoadGet | 
  ILoadInit | 
  ILoadReset | 
  ILoadResponse |
  
  ISavePost | 
  ISaveMerge | 
  ISaveResponse |

  ISubmitPost | 
  ISubmitReset | 
  ISubmitResponse;

interface INotesUpdate {
  type: "NOTES_UPDATE";
  text: string;
}

interface INotesToggle {
  type: "NOTES_TOGGLE";
}

interface ILoadGet {
  type: "LOAD_GET";
}
interface ILoadInit {
  type: "LOAD_INIT";
}
interface ILoadReset {
  type: "LOAD_RESET";
}
interface ILoadResponse {
  type: "LOAD_RESPONSE";
  response: AxiosResponse;
}

interface ISavePost {
  type: "SAVE_POST";
}
interface ISaveMerge {
  type: "SAVE_MERGE";
  data: IFormData;
  label: IDataLabel;
}
interface ISaveResponse {
  type: "SAVE_RESPONSE";
  data: IFormData;
  label: IDataLabel;
  response: AxiosResponse;
}

interface ISubmitPost {
  type: "SUBMIT_POST";
}
interface ISubmitReset {
  type: "SUBMIT_RESET";
}
interface ISubmitResponse {
  type: "SUBMIT_RESPONSE";
  response: AxiosResponse;
}

// non-pure
export const getVersion = () => {
  return `1.0.${(window as any).iqUIVersion}`.replace("..", ".0");
}

export const getConfig = () => {
  return {
    headers: {
      'X-Liberty-RequestId': Guid.create().value,
    },
    timeout: 60 * 1000 
  }
}

export const getUrl = (endpoint: string = "nancy"): string => {
  return `${API.base}submission/${endpoint}`;
}

export const getApplicationName = (individuals: IIndividual[]): string => {
  return individuals.map(individual => `${individual.firstName} ${individual.lastName}`).join(", ")
}

export const getApplicationId = (application: IApplication): IGuid => {
  return application.applicationId || Guid.create().value;
}

export const initApplication = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "LOAD_INIT"
    });
  }
}

export const resetApplication = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "LOAD_RESET"
    });
  }
}

export const loadApplications = () => {
}

export const loadApplication = (id: IGuid) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "LOAD_GET"
    });

    const url = getUrl(`partial-application/${id}`)

    return Axios.get(url)
      .then((response) => dispatch({
        type: "LOAD_RESPONSE",
        response
      }))
      .catch((error) => {
        return dispatch({
          type: "LOAD_RESPONSE",
          response: error.response
        })
      });
  };
}

export const saveApplication = (application: IPartialApplication, callback = () => {}) => {  
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "SAVE_POST"
    });

    const config = getConfig();
    const data = {
      ...application,
      applicationId: getApplicationId(application),
      applicationName: getApplicationName(application.applicants.individuals),
      version: getVersion()
    }

    const url = getUrl(`partial-application/${data.applicationId}`);

    return Axios.post(url, data, config)
      .then((response) => dispatch({
        type: "SAVE_RESPONSE",
        data,
        response
      }))
      .catch((error) => dispatch({
        type: "SAVE_RESPONSE",
        data,
        response: error.response
      }))
      .then(callback);
  };
}

export const updateApplicationNotes = (text: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "NOTES_UPDATE",
      text
    });
  }
}

export const toggleApplicationNotes = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "NOTES_TOGGLE"
    });
  }
}

export const submitApplication = (data: IApplication) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "SUBMIT_POST"
    });
    
    const config = getConfig();
    const url = getUrl();

    return Axios.post(url, data, config)
      .then((response) => dispatch({
        type: "SUBMIT_RESPONSE",
        response
      }))
      .catch((error) => dispatch({
        type: "SUBMIT_RESPONSE",
        response: error.response
      }));
  };
};

export const resetSubmit = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: "SUBMIT_RESET"
    });
  }
}