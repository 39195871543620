import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select, Checkbox} from 'formsy-react-components';

import Mask from '../../../helpers/mask';

import Remove from '../remove';
import Title from '../title';

import CurrencyInput from '../currencyInput';

import {toRequired} from '../functions';
import {otherLiabilityOptions} from '../options';

import {IGuid} from '../interfaces';

interface IOtherProps {
  liability: ILiabilityItem;
  index: number;
  removeLiability: (id: IGuid) => void;
  changeLiabilityRepayment: (id: IGuid, value: string | number) => void;
}
interface IOtherState {}
interface ILiabilityItem {
  id: IGuid,
  type: string,
  name: string,
  repayments: number,
  limit: number
}

export class Other extends React.Component<IOtherProps, IOtherState> {
  render(): JSX.Element {
    return (
      <div className={`card p-lg mt-md`}>
        <Title top>
          {this.props.liability.name}
          <Remove onClick={this.props.removeLiability.bind(this, this.props.liability.id)} />
        </Title>

        <Row>
          {
            (() => {
              if (this.props.liability.type == 'rent') {
                return (
                  <Input
                    type="hidden"
                    name={`liabilities[${this.props.index}].type`}
                    value="rent" />
                );
              }

              return (
                <Col sm={3}>
                  <Select
                    name={`liabilities[${this.props.index}].type`}
                    id={`liabilities[${this.props.index}].type`}
                    label={toRequired("Type")}
                    options={otherLiabilityOptions}
                    value={otherLiabilityOptions[0].value}
                    className="form-control"/>
                </Col>
              );
            })()
          }

          <Input
            name={`liabilities[${this.props.index}].id`}
            type="hidden"
            value={this.props.liability.id}
          />

          {
            (() => {
              if (this.props.liability.type == 'rent') {
                return (
                  <Col sm={3} style={{marginTop: '12px'}}>
                    <Checkbox
                      id={`liabilities[${this.props.index}].cease`}
                      name={`liabilities[${this.props.index}].cease`}
                      ref={`liabilities[${this.props.index}].cease`}
                      rowClassName="ib mr-sm"
                      valueLabel={<span>Ceases after settlement</span>}
                    />
                  </Col>
                );
              }
            })()
          }

          <Col sm={3} smOffset={6}>
            <CurrencyInput
              name={`liabilities[${this.props.index}].payment`}
              label={toRequired(<span>Payments <small className="mute">(monthly)</small></span>)}
              align="right"
              validations={{
                isRequired: true,
                isNotZero: true
              }}
              validationErrors={{
                isRequired: "Payments are required",
                isNotZero: "Repayment can not be 0"
              }}
              blurCallback={(_, value) => this.props.changeLiabilityRepayment.call(this, this.props.liability.id, value)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Other;
