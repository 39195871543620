import * as React from 'react';
import PropTypes from 'prop-types';
import Guid from 'guid';

import MotorPrequalToolPersonal from './motorPrequalToolPersonal';
import { Icon } from '../../utils';

export default class MotorPrequalToolApplicants extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      applicants: [{
        applicantId: Guid.create().value,
        key: 1,
        name: '',
        relationship: '',
        partner: '',
      }],
    };

    this.addApplicant = this.addApplicant.bind(this);
    this.removeApplicant = this.removeApplicant.bind(this);

    this.handleName = this.handleName.bind(this);
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handlePartner = this.handlePartner.bind(this);
  }

  addApplicant() {
    const { applicants } = this.state;
    applicants.push({
      applicantId: Guid.create().value,
      key: this.state.applicants.length + 1,
      name: '',
      relationship: '',
      partner: '',
    });
    this.setState({
      applicants,
    });
  }

  removeApplicant(index) {
    const applicants = Object.assign([], this.state.applicants);

    if (applicants[index].partner) {
      applicants.map((applicant, instance) => {
        if (applicant.applicantId == applicants[index].partner) {
          applicants[instance].partner = '';
        }
      });
    }

    applicants.splice(index, 1);
    this.setState({
      applicants,
    });
  }

  handleName(name, index) {
    const { applicants } = this.state;
    applicants[index].name = name;
    this.setState({
      applicants,
    });
  }

  handleRelationship(relationship, index) {
    const { applicants } = this.state;

    if (applicants[index].inRelationshipWithId) {
      applicants.map((applicant, instance) => {
        if (applicant.key == applicants[index].inRelationshipWithId) {
          applicants[instance].inRelationshipWithId = '';
        }
      });
    }

    applicants[index].relationship = relationship;
    applicants[index].inRelationshipWithId = '';

    this.setState({
      applicants,
    });
  }

  handlePartner(inRelationshipWithId, index) {
    const { applicants } = this.state;

    applicants.map((applicant, instance) => {
      if (inRelationshipWithId == '') {
        if (applicant.inRelationshipWithId == applicants[index].key) {
          applicants[instance].inRelationshipWithId = '';
        }
      }

      if (applicant.key == inRelationshipWithId) {
        applicants[instance].relationship = applicants[index].relationship;
        applicants[instance].inRelationshipWithId = applicants[index].key;
      }
    });

    applicants[index].inRelationshipWithId = inRelationshipWithId;

    this.setState({
      applicants,
    });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section np">
        <div className="panel-section-header panel-header-fixed">
          <i className="mi mi-accounts mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

          <div className="ib va-tt mt-sm-n">
            <h3 className="mb-h mt-h">Applicants</h3>
          </div>
        </div>
        <div className="panel-section-body applicants">
          <div className="panel-section-container m-h p-md bg-primary-50">
            {
              this.state.applicants.map((applicant, index) => (
                  <MotorPrequalToolPersonal
                    key={`applicant-${applicant.applicantId}`}
                    data={this.props.data}
                    index={index}
                    applicant={{ ...applicant }}
                    applicants={this.state.applicants}
                    handleName={this.handleName}
                    handleRelationship={this.handleRelationship}
                    handlePartner={this.handlePartner}
                    removeApplicant={this.removeApplicant}
                  />
              ))
            }

            {
              (() => {
                if (this.state.applicants.length < 2) {
                  return (
                    <button
                      style={{ width: '100%', fontSize: '15px' }} 
                      type="button" 
                      className="card card-shallow card-mute card-hover mt-md"
                      id="addApplicant" 
                      onClick={this.addApplicant}>
                        <strong className="secondary">
                        <Icon glyph="plus" className="primary mute-lg mr-sm" />
                        {' ADD APPLICANT'}
                        </strong>
                    </button>
                  );
                }
              })()
            }
          </div>
        </div>
      </div>
    );
  }
}

MotorPrequalToolApplicants.propTypes = {
};
