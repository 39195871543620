import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../utils';

const AccreditationProgress = (props) => {
  let progress = 0;

  switch (props.step) {
    case 'personal': progress = 1; break;
    case 'company': progress = 2; break;
    case 'product': progress = 3; break;
    case 'done': progress = 4; break;
  }

  const width = `${!progress ? 0 : progress <= 3 ? (20 + progress * 15) : 100}%`;

  return (
    <div className="milestone-wrapper">
      <div className="milestone-bar">
        <div className="milestone-bar-progress" style={{ width }} />
      </div>

      <div className="milestones">
        <div onClick={() => { props.handleClick('personal'); }} className={`milestone ${progress > 1 ? `done ${progress < 4 ? 'milestone-link' : ''}` : progress == 1 ? 'active' : 'pending'}`}>
          <div className="milestone-circle">
            <Icon glyph="account" />
          </div>
          Personal
        </div>

        <div onClick={() => { props.handleClick('company'); }} className={`milestone ${progress > 2 ? `done ${progress < 4 ? 'milestone-link' : ''}` : progress == 2 ? 'active' : 'pending'}`}>
          <div className="milestone-circle">
            <Icon glyph="city" />
          </div>
          Company
        </div>

        <div onClick={() => { props.handleClick('product'); }} className={`milestone ${progress == 4 ? `done ${progress < 4 ? 'milestone-link' : ''}` : progress == 3 ? 'active' : 'pending'}`}>
          <div className="milestone-circle">
            <Icon glyph="assignment-o" />
          </div>
          Product
        </div>
      </div>
    </div>
  );
};

AccreditationProgress.propTypes = {
  step: PropTypes.any,
  handleClick: PropTypes.func.isRequired,
};

export default AccreditationProgress;
