import * as React from 'react';
import { connect } from 'react-redux';

import { Icon, Money, Percentage } from '../../utils';
import { Link } from 'react-router';
import { ShouldDisplayCommission } from './commissionChecker';

interface Application {
  loans: Loan[];
  amount: number;
  applicationType: number;
  productName: String;
  loanTerm: number;
  loanAssessmentResult: LoanAssessmentResult
  applicationNumber: number;
  applicationState: number;
};

interface LoanAssessmentResult {
  commission?: number;
}

interface Loan {
  amount: number;
  interestRate: number;
  repayment: number;
}

interface ApplicationLoanProps {
  application: Application
}

export const ApplicationLoan = ({application}: ApplicationLoanProps) =>
  <div className="card card-border">
    <Icon className="card-icon" glyph="assignment-o" />
    {
      (() => {
        if (application.loans.length) {
          return (
            application.loans.map((loan, index) => {
              return (
                <div key={`loan-${index}`} className={`loan loan-${application.loans.length == 1 ? 'single' : 'multiple'}`}>
                  <h2><Money round={application.applicationType == 2 ? 2 : 0}>{loan.amount}</Money></h2>
                  <span className="mute">{application.productName}, {application.loanTerm} years</span>

                  <p className="loan-details">
                    <span>
                      <small className="mute">Rate <br /></small>
                      <Percentage>{loan.interestRate}</Percentage>
                    </span>

                    <span>
                      <small className="mute">Repayments <br /></small>
                      <Money>{loan.repayment}</Money>
                    </span>

                    { ShouldDisplayCommission(application)
                      ? <span>
                          <small className="mute">Commission <br /></small>
                          <Money>{application.loanAssessmentResult.commission}</Money>
                        </span>
                      : ''
                    }
                  </p>

                  {
                    (() => {
                      if (application.applicationType == 2 && application.applicationState) {
                        return (

                            <Link className="btn btn-secondary btn-circle card-btn" to={`/applications/${application.applicationNumber}/update/finance`}>
                              <Icon glyph="edit" />
                            </Link>

                        );
                      }
                    })()
                  }
                </div>
              )
            })
          );
        } else {
          return (
            <div className={`loan loan-single`}>
              <h2><Money round={application.applicationType == 2}>{application.amount}</Money></h2>
              <span className="mute">{application.productName}, {application.loanTerm} years</span>

              {
                (() => {
                  if (application.applicationType == 2 && application.applicationState) {
                    return (
                      <div>
                        <Link className="btn btn-secondary btn-circle card-btn" to={`/applications/${application.applicationNumber}/update/finance`}>
                          <Icon glyph="edit" />
                        </Link>
                      </div>
                    );
                  }
                })()
              }
            </div>
          );
        }
      })()
    }
  </div>

function mapStateToProps(state) {
  return {
    application: state.application.active
  };
}

export default connect(mapStateToProps, {})(ApplicationLoan);
