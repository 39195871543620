import * as React from 'react';
import DocumentItem from './documentItem';
import { Document } from '../../actions/documentsActions';

type DocumentGroupProps = {
  documents: Document[],
  title: string,
  group: string,
  filterPredicate: (document: Document) => boolean,
  getReferenceDocument: (file: Document, group: string) => void
}

const DocumentGroup = (props: DocumentGroupProps) => {
  if (!props.documents || !props.documents.filter(props.filterPredicate).length) {
    return null;
  }

  return (
    <div className="panel panel-default">
      {
        (() => {
          if (props.title) {
            return (
              <div className="panel-header">
                <h4>{props.title}</h4>
              </div>
            );
          }
        })()
      }

      <div className="panel-body p-h">
        <table className="table table-hover table-click m-h document-group">
          <tbody>
            {
              props.documents.filter(props.filterPredicate).map((document: Document, idx) => (
                <DocumentItem key={`document-guide-${idx}`} document={document} group={props.group} getReferenceDocument={props.getReferenceDocument} />
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DocumentGroup;
