import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const ApplicationUpdateButton = (props) => (
  <div className="btn-bar mb-lg">
    <Link to={`/applications/${props.applicationID}`} className="btn btn-lg btn-default btn-link">
      Back
    </Link>

    <button
      className="btn btn-lg btn-save btn-success"
      type="submit"
    >

      Save
    </button>
  </div>
);

ApplicationUpdateButton.propTypes = {
  applicationID: PropTypes.any,
};

export default ApplicationUpdateButton;
