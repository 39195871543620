import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import * as sessionActions from '../../actions/sessionActions';

import ProfileName from './profileName';
import ProfileCredentials from './profileCredentials';
import ProfileBank from './profileBank';
import ProfileEmail from './profileEmail';
import ProfilePhone from './profilePhone';
import ProfileAddress from './profileAddress';
import ProfileCompany from './profileCompany';
import ProfileSubscription from './profileSubscription';

export class ProfilePage extends React.Component {
  constructor(props) {
    super();

    this.props = props;
  }

  componentDidMount() {
    this.getDetails();
  }

  getDetails() {
    if (this.props.session.token) {
      this.props.actions.getDetails();
    }
  }

  getValue(value) {
    return value || <small className="mute">N/A</small>;
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={8} smOffset={2}>

            <div className="panel panel-default mb-lg mt-lg">
              <div className="panel-header">
                <h4>Personal details</h4>
              </div>
              <div className="panel-body">
                <ProfileName details={this.props.session.details} />
                <ProfileCredentials details={this.props.session.details} />
                <ProfileSubscription details={this.props.session.details} />
              </div>
            </div>

            <div className="panel panel-default mb-lg mt-lg">
              <div className="panel-header">
                <h4>Contact details</h4>
              </div>
              <div className="panel-body">
                <ProfileEmail details={this.props.session.details} getValue={this.getValue} />
                <ProfilePhone details={this.props.session.details} getValue={this.getValue} />
                <ProfileAddress details={this.props.session.details} getValue={this.getValue} />
                <ProfileCompany details={this.props.session.details} getValue={this.getValue} />
              </div>
            </div>

            <div className="panel panel-default mt-lg">
              <div className="panel-header">
                <h4>Payment details</h4>
              </div>
              <div className="panel-body">
                <ProfileBank details={this.props.session.details} getValue={this.getValue} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
