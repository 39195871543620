import * as React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'formsy-react-components';
import {IOption} from '../../interfaces';

interface ISelectableInputState {
  options: IOption[],
  query: string,
  visible: boolean,
  selected: number
}
interface ISelectableInputProps {
  name: string,
  label: string | JSX.Element,
  placeholder?: string,
  options: IOption[],
  validations?: any
  validationError?: string,
  blurCallback?: (name, value) => void
}

export class SelectableInput extends React.Component<ISelectableInputProps, ISelectableInputState> {
  constructor(props) {
    super(props);

    this.state = {
        options: [],
        query: "",
        selected: 0,
        visible: false
    };

    this.filterOption = this.filterOption.bind(this);
    this.checkKey = this.checkKey.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.showOptions = this.showOptions.bind(this);
  }

  filterOption(event: any) {
    let key = event.keyCode;
    if (this.keyCodes.indexOf(key) > -1) {
      event.preventDefault();
      return;
    }

    this.setState({
      selected: 0,
      query: event.target.value
    }, this.showOptions);
  }

  checkKey(event: any) {
    let key = event.keyCode;
    if (this.keyCodes.indexOf(key) > -1) {
      event.preventDefault();

      if (key == 13) {
        this.setOption(this.state.options[this.state.selected])
      }

      if (key == 27 || key == 13) { // esc || return
        this.hideOptions();
      }

      if (key == 38) { // up
        if (this.state.selected > 0) {
          this.setState({
            selected: this.state.selected - 1
          })
        }
      }

      if (key == 40) { // down
        if (this.state.selected < this.state.options.length) {
          this.setState({
            selected: this.state.selected + 1
          })
        }
      }

      return;
    }
  }

  setOption(option: IOption) {
    let valueInput = (this.refs[this.props.name] as any);
    let labelInput = (this.refs[this.props.name + "Label"] as any);

    valueInput.setValue(option.value);
    labelInput.setValue(option.label);

    this.setState({
      query: option.label
    }, this.hideOptions);
  }

  hideOptions() {
    this.setState({
      visible: false
    }, this.resetOptions);
  }

  resetOptions() {
    setTimeout(() => {
      this.setState({
       options: []
     })
    }, 400);
  }

  showOptions() {
    let options = this.props.options.filter(option => option.label.toLowerCase().indexOf(this.state.query.toLowerCase()) > -1);
    this.setState({
      options,
      visible: true
    });
  }

  highlightOption(label: string): JSX.Element {
    label = label.replace(new RegExp(this.state.query, 'gi'), "___$&___");

    let split = label.split('___');

    return (
      <span className="mute-fixed">
        {
          split.map((element, index) => {
            if (index % 2 == 0) {
              return <span key={label + index}>{element}</span>
            }

            return <b key={label + index} className="primary">{element}</b>
          })
        }
      </span>
    )
  }

  shouldSetOption(value: string) {
    if (!this.validations.isStrict && value) {
      this.setOption({
        label: value,
        value: value
      });
    }
  }

  get keyCodes(): number[] {
    return [38, 40, 27, 13];
  }

  get validations(): any {
      if (this.props.validations) {
        const validations = Object.assign({}, this.props.validations)

        if (this.props.validations.isStrict) {
            validations.isStrict = (_, value) => {
                return this.props.options.some(option => option.label == value);
            }
        }

        return validations;
      }

      return {};
  }

  get options(): IOption[] {
    if (!this.state.options.length) {
      if (!this.validations.isStrict) {
        return [{
          value: this.state.query,
          label: this.state.query
        }];
      }
    }

    return  [...this.state.options].filter((_, index) => index < 10);
  }

  render(): JSX.Element {
    return (
      <div className="form-group">
        <Input
          type="hidden"
          name={this.props.name}
          id={this.props.name}
          ref={this.props.name} />

        <Input
          data-test-name="selectable"
          name={this.props.name + "Label"}
          id={this.props.name + "Label"}
          ref={this.props.name + "Label"}
          label={this.props.label}
          onKeyUp={this.filterOption}
          onKeyDown={this.checkKey}
          onFocus={this.showOptions}
          blurCallback={(name, value) => {
            if (this.props.blurCallback) {
              this.props.blurCallback(name, value);
            }
            setTimeout(() => {
              this.shouldSetOption.call(this, value);
              this.hideOptions();
            });
          }}
          validations={this.validations}
          placeholder={this.props.placeholder || "Type to select"}
          autoComplete="no"
          validationError={this.props.validationError} />

          <div className={`form-result ${!this.state.visible ? 'transparent' : ''}`}>
            {
              this.options.map((option, index) => {
                return (
                  <div className={`form-result-item ${this.state.selected == index ? 'active' : ''}`} key={`${option.value}-${index}`} onClick={this.setOption.bind(this, option)}>
                    {option.group && <small className="b mute-lg">{option.group}</small>}
                    {this.highlightOption(option.label)}
                  </div>
                );
              })
            }
          </div>

      </div>
    );
  }
}

export default SelectableInput;
