import { 
  PersonApplicant, 
  CreditHistoric, 
  EmploymentHistoric, 
  ContractingEmployment, 
  PermanentEmployment, 
  PreviousEmployment 
} from "../models/commonApiModels";
import { DualApplicantQueryInfo, SingleApplciantQueryInfo } from "../models/motorApiModels";

const mapMaritalStatus = (relationship) => {
  switch (relationship) {
    case 'Single':
      return 1;
    case 'Married to Non Applicant':
      return 2;
    case 'De Facto to Non Applicant':
      return 3;
    case 'Separated':
      return 4;
    case 'Divorced':
      return 5;
    case 'Widowed':
      return 6;
    default:
      break;
  }
};

const mapResidencyType = (type) => {
  switch (type) {
    case 'Owner/Buyer':
      return 1;
    case 'With Parents':
      return 2;
    case 'Boarding':
      return 3;
    case 'Renter RE Agent':
      return 4;
    case 'Renter Other':
      return 5;
    case 'Public Housing':
      return 6;
    default:
      break;
  }
};

const mapResidency = (occupancy) => ({
  ResidencyType: mapResidencyType(occupancy.type),
  OccupancyLengthYears: occupancy.years,
  OccupancyLengthMonths: occupancy.months,
});

const mapEmploymentArrangement = (type) => {
  switch (type) {
    case 'Sole-Trader' || 'Self Employed':
      return 1;
    case 'Permanent Full Time':
      return 2;
    case 'Permanent Part Time':
      return 3;
    case 'Contract Full Time':
      return 4;
    case 'Contract Part Time':
      return 5;
    case 'Casual':
      return 6;
    case 'Seasonal':
      return 7;
    case 'Partner':
      return 8;
    case 'Director':
      return 9;
    default:
      break;
  }
};

const mapEmploymentHistory = (employment) => {
  let historyItem: Partial<EmploymentHistoric> = {
    EmploymentArrangement: mapEmploymentArrangement(employment.type),
    DurationInMonths: employment.months + (employment.years * 12),
  };

  if (employment.isCurrent) {
    switch (employment.type) {
      case 'Contract Full Time':
      case 'Contract Part Time':
        historyItem = historyItem as ContractingEmployment;
        historyItem.$type = 'ContractingEmployment';
        historyItem.EndDate = employment.endDate;
        break;
      case 'Sole-Trader':
      case 'Permanent Full Time':
      case 'Permanent Part Time':
      case 'Casual':
      case 'Seasonal':
      case 'Partner':
      case 'Director':
        historyItem = historyItem as PermanentEmployment;
        historyItem.$type = 'PermanentEmployment';
        break;
      default:
        break;
    }
  } else {
    historyItem = historyItem as PreviousEmployment;
    historyItem.$type = 'PreviousEmployment';
    historyItem.EndDate = employment.endDate;
  }
  return historyItem;
};

const mapDebtType = (debtType) => {
  switch (debtType) {
    case 'securedcredit':
      return 1;
    case 'unsecuredcredit':
      return 2;
    case 'tradeAccount':
      return 3;
    case 'ato':
      return 4;
    case 'utilityaccount':
      return 5;
    case 'carloan':
      return 6; // not currently used
    case 'mortgage':
      return 7;
    case 'personalloan':
      return 8; // not currently used
    case 'creditcard':
      return 9; // not currently used
    case 'professionalservices':
      return 10;
    case 'schoolfees':
      return 11;
    case 'councilrates':
      return 12;
    default:
      break;
  }
};

const mapEventType = (debtType) => {
  switch (debtType) {
    case 'arrears':
      return 1;
    case 'default':
      return 2;
    case 'judgment':
      return 3;
    case 'warrant':
      return 4;
    case 'writ':
      return 5;
    default:
      break;
  }
};

const mapPaidStatusType = (status) => {
  switch (status) {
    case 'unpaid':
      return 'DebtUnpaidStatus';
    case 'paid':
      return 'DebtPaidStatus';
    default:
      break;
  }
};

export const mapBankruptcyEventType = (debtType) => {
  switch (debtType) {
    case 'Part IX':
      return 1;
    case 'Part X':
      return 2;
    case 'Sequestration':
      return 3;
    case 'DebtorsPetition':
        return 4;      
    default:
      break;
  }
};

export const mapAdministrationEventType = (debtType) => {
  switch (debtType) {
    case 'ceased':
      return 'Ceased';
    case 'current':
      return 'Current';
    default:
      break;
  }
}

export const mapCreditHistory = (creditEvent) => {
  if (creditEvent.creditType == 'bankruptcy') {

    if (!!creditEvent.dateRectified) {
      return {
        $type: 'Bankruptcy', 
        BankruptcyType: mapBankruptcyEventType(creditEvent.eventType),
        IsToBePaidOff: creditEvent.toBePaidOff,
        BankruptcyStatus: {
          $type: 'BankruptcyRectified',
          DateRectified: creditEvent.dateRectified, 
          DateListed: creditEvent.dateIncurred,
        }
      };

    } else {
      return {
        $type: 'Bankruptcy', 
        BankruptcyType: mapBankruptcyEventType(creditEvent.eventType),
        IsToBePaidOff: creditEvent.toBePaidOff,
        BankruptcyStatus: {
          $type: 'BankruptcyUnrectified',
          DateListed: creditEvent.dateIncurred,
        }
      };
    }
  }

  if (creditEvent.creditType == 'administration')
  {
    return {
      $type: 'Administration', 
      Status: mapAdministrationEventType(creditEvent.status),
      IsToBePaidOff: creditEvent.toBePaidOff,
      FinalisedDate: creditEvent.dateRectified,
    };
  }
  
  return {
    $type: 'AccountDebt',
    DebtType: mapDebtType(creditEvent.creditType),
    EventType: mapEventType(creditEvent.eventType),
    IsToBePaidOff: creditEvent.toBePaidOff,
    MissedPayments: creditEvent.missedPayments,
    PaidStatus: {
      $type: mapPaidStatusType(creditEvent.status),
      DatePaid: creditEvent.dateRectified,
      DateIncurred: creditEvent.dateIncurred,
    },
    DebtAmount: creditEvent.amount,
  } as CreditHistoric;
};

export const mapApplicant = (applicant, motor = true): PersonApplicant => {
  const employments = motor ? applicant.employments : applicant.employmentHistory.employmentItems;
  const personApplicant: Partial<PersonApplicant> = {
    DateOfBirth: applicant.dateOfBirth,
    NumberOfDependants: applicant.dependants,
    Postcode: applicant.postcode,
    State: applicant.state,
    Country: 1, // Australia
    MaritalStatus: mapMaritalStatus(applicant.relationship),
    PreviousResidencies: [],
    CurrentResidency: {
      ResidencyType: 1,
      DurationInMonths: 36
    },
    EmploymentHistory: employments ? employments.map(mapEmploymentHistory) : [],
    CreditEvents: applicant.creditHistory?.creditEvents ? applicant.creditHistory.creditEvents.map(mapCreditHistory) : [],
  };

  if (motor) {    
    personApplicant.DateOfBirth = applicant.dateOfBirth;
    personApplicant.CurrentResidency = applicant.occupancies ? mapResidency(applicant.occupancies.find((o) => o.isCurrent)) : [];
    personApplicant.PreviousResidencies = applicant.occupancies ? applicant.occupancies.filter((o) => !o.isCurrent).map(mapResidency) : [];    
    personApplicant.CreditEnquiryScore = {
      Provider: 1,
      Score: applicant.creditHistory.vedaScore,
    };
  }

  if (applicant.firstName && applicant.lastName) {
    personApplicant.$type = 'NamedPersonApplicant';
    personApplicant.FirstName = applicant.firstName;
    personApplicant.MiddleName = applicant.middleName;
    personApplicant.LastName = applicant.lastName;
  } else {
    personApplicant.$type = 'AnonymousPersonApplicant';
  }

  return personApplicant as PersonApplicant;
};

export const mapSingleApplicant = (data: any): SingleApplciantQueryInfo => ({
  $type: 'MotorConsumerSingleApplicantRateQuoteInfoQuery',
  ApplicantInfo: mapApplicant(data.applicants[0])
});

export const mapDualApplicant = (data: any): DualApplicantQueryInfo => ({
  $type: 'MotorConsumerDualApplicantRateQuoteInfoQuery',
  PrimaryApplicantInfo: mapApplicant(data.applicants[0]),
  SecondaryApplicantInfo: mapApplicant(data.applicants[1])
});


