import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Overlay } from '../../utils';

import * as referenceActions from '../../actions/referenceActions';
import { submitLfiProtectLead, initialiseForm } from './actions';
import { SubmissionStatus } from './reducer';
import { VeiOption } from '../applicationUpdatePage/applicationUpdateVei';
import { LfiProtectPrequalHeader } from './lfiProtectPrequalHeader';
import { LfiProtectPrequalDetails } from './lfiProtectPrequalDetails';
import { LfiProtectPrequalMessage } from './lfiProtectPrequalMessage';

export interface LfiProtectPrequalProps {
  veiOptions: VeiOption[], 
  lpiOptions: VeiOption[], 
  status: SubmissionStatus,
  errorMessage: string,
  actions: {
    getVeiTypes: Function,
    getLpiTypes: Function,
    submitLfiProtectLead: Function,
    initialiseForm: Function
  }
}

export class LfiProtectPrequalPage extends React.Component<LfiProtectPrequalProps, {}> {    
  constructor(props: LfiProtectPrequalProps) {
    super(props, {});	
    this.state = {
      status: SubmissionStatus.Initial,
      errorMessage: ""
    }
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getVeiTypes();
    this.props.actions.getLpiTypes();	
    this.props.actions.initialiseForm();
  }

  render() {
      return (
          <div>
          {
              (() => {
                if (this.props.status == SubmissionStatus.Pending) {
                  return (
                        <Overlay>Submitting...</Overlay>
                  );
                }
              })()
          }

          <Row>
            <Col sm={12} className="main-panel">
              <div className='panel panel-default'>
                <div className='panel-body p-h'>
                  <LfiProtectPrequalHeader />

                  {
                    (() => {
                      if (this.props.status != SubmissionStatus.Succeeded) {
                        return (
                          <LfiProtectPrequalDetails 
                            veiOptions={this.props.veiOptions} 
                            lpiOptions={this.props.lpiOptions}  
                            submitLfiProtectLead={this.props.actions.submitLfiProtectLead}										
                          />
                        );
                      }	
                      else{
                        return (
                          <LfiProtectPrequalMessage status={this.props.status} initialiseForm={this.props.actions.initialiseForm}  />
                        );
                      }
                    })()																				
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>				
      )
    }
}

function mapStateToProps({reference, lfiProtectPrequal}): { veiOptions: VeiOption[], lpiOptions: VeiOption[], status: SubmissionStatus, errorMessage: string } {
  return {
    veiOptions: reference.veiTypes,
    lpiOptions: reference.lpiTypes,
    status: lfiProtectPrequal.status,
    errorMessage: lfiProtectPrequal.errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...referenceActions,
      submitLfiProtectLead,
      initialiseForm
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LfiProtectPrequalPage);