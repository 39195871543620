import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class ServiceAbilityModal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm mute">
            <h4 className="mb-lg">About the results</h4>
            <p>The results from this calculator are indicative only and do not take all of the applicant’s individual circumstances into account. The results displayed are based on the information provided and do not represent a quote, prequalification or approval for a loan. All applications are subject to Liberty’s usual credit assessment criteria. </p>

            <h4 className="mt-lg mb-lg">Assumptions we make </h4>
            <p>This calculator also makes a number of assumptions which affect how reliable the results are. The main assumptions are explained below.</p>

            {(() => {
              if (this.props.isMotorServiceability) {
                return (
                  <ul>
                    <li>If you enter the applicants’ monthly living expenses at a figure that is less than HEMS, we will apply the higher HEMS figure by default.</li>
                    <li>If you enter a credit card limit as an ongoing financial commitment, we will assume the applicant’s monthly credit card repayments are 3% of their total credit card limit.</li>
                    <li>If you enter a rental income amount, only 80% of the amount will be considered in the serviceability calculation.</li>
                    <li>The suggested figure your customer could service is based upon the information provided and may change.</li>
                  </ul>
                );
              }

              return (
                <ul>
                  <li>If you enter the applicants’ monthly living expenses at a figure that is less than HEMS, we will apply the higher HEMS figure by default.</li>
                  <li>If you enter a credit card limit as an ongoing financial commitment, we will assume the applicant’s monthly credit card repayments are 3.8% of their total credit card limit.</li>
                  <li>If you enter an outstanding balance for an investment loan as an ongoing financial commitment, we will calculate the interest charges assuming an interest rate of 7% on the balance. These interest charges will decrease the applicant’s annual taxable income. If you indicate that an investment loan is sought by the applicant by ticking the box, we will also calculate the interest charges assuming an interest rate of 7% on the loan amount. These interest charges will decrease the applicant’s annual taxable income.</li>
                  <li>If the loan purpose is investment, we will add a buffer to the financial commitments.</li>
                  <li>We apply a 1% interest rate buffer to the calculation in order to take into account future interest rate increases. For AAA products, with a LVR ≥ 80%, we will apply an interest rate of 7.5% unless the interest rate entered (plus the 1% buffer) is more than 7.5%. We may apply a higher interest rate buffer when assessing actual applications depending on the product type.</li>
                  <li>When calculating serviceability on an IO basis all repayments are calculated on a P&amp;I basis.</li>
                  <li>The suggested figure your customer could service is based upon the information provided and may change.</li>
                </ul>
              );
            })()}

            <h4 className="mt-lg mb-lg">What about fees and charges?</h4>
            <p>This calculator does not take into account all fees, charges or other amounts that may be charged to a loan. Additional fees and charges may increase repayment amounts. Full terms and conditions will be set out in any loan offer (if an offer is made). Further product information including current interest rates, fees and charges can be found in the relevant Introducer Guides available in the ‘Documents’ tab.</p>
          </div>

        </Modal.Body>
      </Modal>
    );
  }
}

ServiceAbilityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isMotorServiceability: PropTypes.bool.isRequired,
};
