import * as React from 'react';
import PropTypes from 'prop-types';

const AccreditationNavigation = (props) => {
  if (!props.visible) {
    return null;
  }

  return (
    <div className="panel-footer pl-h pr-lg">
      <button
        className="btn btn-default btn-link btn-lg pull-left"
        onClick={props.handlePrevious}
        type="button"
        tabIndex="21"
      >
        Previous
      </button>

      <button
        className="btn btn-success btn-lg pull-right"
        tabIndex="20"
      >
        {props.step == 'product' ? 'Submit' : 'Next'}
      </button>

      <div className="clearfix" />
    </div>
  );
};

AccreditationNavigation.propTypes = {
  visible: PropTypes.bool.isRequired,
  step: PropTypes.any,
  handlePrevious: PropTypes.func.isRequired,
};

export default AccreditationNavigation;
