import * as React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../utils';

interface IRemoveProps {
  onClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>> | undefined
}

const Remove = (props: IRemoveProps): JSX.Element =>  {
  return (
    <button type="button" className="btn btn-default btn-danger pull-right btn-circle" style={{marginTop: '-6px'}} onClick={props.onClick}>
      <Icon glyph="delete" />
    </button>
  );
}

export default Remove;
