import PMT from '../../helpers/pmt';
import FV from '../../helpers/fv';
import { VehicleValuePerYear, VehicleYearOnYearDepreciation, LoanScheduleYear, LoanInformation } from './veiTypes';

const getVehicleValueYearOnYear = (vehicleValuationAmount : number, vehicleAge : number, loanTerm : number, vehicleYearOnYearDepreciation : Array<VehicleYearOnYearDepreciation>) : Array<VehicleValuePerYear> => {
    const endTerm = vehicleAge + loanTerm;

    const item = {
        year : 0,
        vehicleAge : vehicleAge,
        vehicleValue: vehicleValuationAmount
    }

    let items = vehicleYearOnYearDepreciation
    .filter(element => element.vehicleAge > vehicleAge && element.vehicleAge <= endTerm)
    .sort((yearA, yearB) => yearA.vehicleAge > yearB.vehicleAge ? 1 : -1)
    .map((yearOnYearDepr, i) => {
        vehicleValuationAmount = vehicleValuationAmount * yearOnYearDepr.yearOnYearChange;

        return {
            year : i +1,
            vehicleAge : yearOnYearDepr.vehicleAge,
            vehicleValue: vehicleValuationAmount
        };
    });

   items.unshift(item);

   return items;
}

const getLoanScheduleBalanceEachYear = (loanAmount : number, balloonAmount : number, interestRate : number, loanTermYears : number) : Array<LoanScheduleYear> => {
    var mmp = PMT(interestRate / 12, loanTermYears * 12, loanAmount, balloonAmount * -1);

    return [...Array(loanTermYears+1).keys()]
        .map(year =>
        {
            return {
                year: year,
                loanValue: Math.round(FV(interestRate / 12, year * 12, mmp, -loanAmount))
            }
        });
}

export const getMaxGapValue = (vehicleYearOnYearDepreciation : Array<VehicleYearOnYearDepreciation>, loanInformation : LoanInformation) =>
{
    const { vehicleValuationAmount, vehicleAge, loanTermYears, loanAmount, balloonAmount, interestRate } = loanInformation;

    var listVehicleValue = getVehicleValueYearOnYear(vehicleValuationAmount, vehicleAge, loanTermYears, vehicleYearOnYearDepreciation);
    var listScheduleBalance = getLoanScheduleBalanceEachYear(loanAmount, balloonAmount, interestRate, loanTermYears);
    var maxGapValue = listVehicleValue
    .map((vv, index) => listScheduleBalance[index].loanValue - vv.vehicleValue)
    .sort((a : number, b : number) => a > b ? -1 : 1)[0];

    return Math.round(maxGapValue * 100) / 100;
}
