import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';
import Guid from 'guid';

import * as referenceActions from '../../actions/referenceActions';

import Mask from '../../helpers/mask';

import Icon from '../../utils/icon';

import * as functions from '../../constants/functions';

export class ComServiceAbilityExpense extends React.Component {
    inputRefs = {};

    constructor(props) {
      super(props, {});
      this.props = props;
      this.state = {
        expenses: [],
      };

      this.addExpense = this.addExpense.bind(this);
      this.removeExpense = this.removeExpense.bind(this);

      this.changeExpenseType = this.changeExpenseType.bind(this);
    }

  setRef = (id) => (ref) => {
    if (ref) this.inputRefs[id] = ref;
    else delete this.inputRefs[id];
  };

  UNSAFE_componentWillMount() {
    if (this.props.data && this.props.data.liabilities) {
      const expenses = [];
      this.props.data.liabilities.forEach((liability) => {
        expenses.push({
          expenseId: Guid.create().value,
          expenseType: liability.type,
          ownerships: liability.ownerships,
        });
      });

      this.setState({
        expenses,
      }, () => this.recreateOwnershipsForInvestmentLiabilities());
    }
  }

  recreateOwnershipsForInvestmentLiabilities() {
    const { expenses } = this.state;
    expenses.forEach((expense, index) => {
      if (expense.expenseType == 'investment') {
        let newOwnerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
        const hasCustomOwnership = functions.hasCustomOwnership(expense.ownerships);
        if (hasCustomOwnership) { newOwnerships = functions.copyCustomOwnerships(expense.ownerships, newOwnerships); }
        expenses[index].ownerships = newOwnerships;
        expenses[index].hasCustomOwnership = hasCustomOwnership;
      }
    });
    this.setState({
      expenses,
    });
  }

  addExpense() {
    this.setState({
      expenses: [...this.state.expenses, { expenseId: Guid.create().value, expenseType: 'credit' }],
    });
  }

  componentDidUpdate(prevProps) {
    const prevPropsContainsData = !(prevProps.data?.applicants === undefined || prevProps.data?.applicants.length === 0);
    const curPropsContainsData = !(this.props.data?.applicants === undefined || this.props.data?.applicants.length === 0);
    let changed = false;
    if (prevPropsContainsData === false && curPropsContainsData === false) return;
    if (prevPropsContainsData === true && curPropsContainsData === false) return;
    if (prevPropsContainsData === false && curPropsContainsData === true) changed = true;
    if (prevPropsContainsData === true && curPropsContainsData === true) changed = functions.applicantsDidChange(prevProps.data.applicants)(this.props.data.applicants);
    if (changed === true) this.recreateOwnershipsForInvestmentLiabilities();
  }

  removeExpense(index) {
    const { expenses } = this.state;
    expenses.splice(index, 1);
    this.setState({
      expenses,
    });
  }

  changeExpenseType(index, value) {
    const { expenses } = this.state;
    expenses[index].expenseType = value;
    expenses[index].hasCustomOwnership = false;
    if (value === 'investment') {
      expenses[index].ownerships = functions.getDefaultOwnershipsForApplicants(this.props.data.applicants);
    } else {
      expenses[index].ownerships = undefined;
    }
    this.setState({
      expenses,
    });
  }

  changeOwnership(expenseIndex, ownershipIndex, value) {
    const { expenses } = this.state;
    expenses[expenseIndex].ownerships[ownershipIndex].owns = value;
    this.setState({
      expenses,
    });
  }

  customiseOwnership(index) {
    const { expenses } = this.state;
    expenses[index].hasCustomOwnership = true;
    this.setState({
      expenses,
    });
  }

  getExpenseTypes() {
    const expenseTypes = [{
      value: 'credit',
      label: 'Credit card',
    },
    {
      value: 'loan',
      label: 'Personal / Car loan',
    },
    {
      value: 'rent',
      label: 'Rent',
    },
    {
      value: 'other',
      label: 'Other',
    },
    {
      value: 'homeloan',
      label: 'Home loan',
    }];

    return expenseTypes;
  }

  /**
   * Render
   */
  render() {
    return (
      <div>
        {
          (() => {
            if (!this.state.expenses.length) {
              return (
                <button style={{ width: '100%', fontSize: '15px' }} type="button" className="card card-shallow card-mute card-hover" id="addExpense" onClick={() => { this.addExpense(); }}>
                  <strong className="secondary text-lg">Add financial commitment</strong>
                </button>
              );
            }
            return (
              <div className="card card-shallow liabilities">
                {
                  this.state.expenses.map((expense, index) => (
                    <Row key={`income-${expense.expenseId}`} className="panel-row">
                      <Col sm={12}>
                        <Row>
                          <Col sm={4}>
                            <Select
                              id={`liabilities[${index}].type`}
                              name={`liabilities[${index}].type`}
                              ref={this.setRef(`liabilities[${index}].type`)}
                              label="Type"
                              value="credit"
                              options={this.getExpenseTypes()}
                              changeCallback={(_, value) => { this.changeExpenseType(index, value); }}
                              className="form-control"
                            />
                            <small className="mute mt-md-n pt-xs b mb-md">
                              <button
                                type="button"
                                className="btn-link secondary pl-h"
                                hidden={expense.expenseType != 'investment' || expense.hasCustomOwnership}
                                onClick={() => { this.customiseOwnership(index); }}
                              >
                                Customise ownership
                              </button>
                            </small>
                          </Col>

                          {
                              (() => {
                                if (expense.expenseType == 'credit') {
                                  return (
                                    <Col sm={3}>
                                      <div className="form-prefix">$</div>
                                      <Input
                                        id={`liabilities[${index}].amount`}
                                        name={`liabilities[${index}].amount`}
                                        ref={this.setRef(`liabilities[${index}].amount`)}
                                        type="text"
                                        label={<span>Credit limit</span>}
                                        changeDebounceInterval={0}
                                        updateOnBlur={false}
                                        blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                                        value="0"
                                        validationError="Credit limit is required"
                                        validations={{
                                          isFormattedNumber: true,
                                          isRequired: true,
                                        }}
                                      />
                                    </Col>
                                  );
                                }
                              })()
                            }

                          {
                              (() => {
                                if (expense.expenseType == 'investment' || expense.expenseType == 'rent' || expense.expenseType == 'loan' || expense.expenseType == 'other' || expense.expenseType == 'homeloan') {
                                  return (
                                    <Col sm={3}>
                                      <div className="form-prefix">$</div>
                                      <Input
                                        id={`liabilities[${index}].amount`}
                                        name={`liabilities[${index}].amount`}
                                        ref={this.setRef(`liabilities[${index}].amount`)}
                                        type="text"
                                        label={(
                                          <span>
                                            {expense.expenseType == 'rent' || expense.expenseType == 'other' ? 'Amount' : 'Repayments'}
                                            {' '}
                                            <small className="mute">(monthly)</small>
                                          </span>
                                          )}
                                        value="0"
                                        changeDebounceInterval={0}
                                        updateOnBlur={false}
                                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.inputRefs); }}
                                        validationError={`${expense.expenseType == 'rent' || expense.expenseType == 'other' ? 'Amount is' : 'Repayments are'} required`}
                                        validations={{
                                          isFormattedNumber: true,
                                          isRequired: true,
                                        }}
                                      />
                                    </Col>
                                  );
                                }
                              })()
                          }

                          {
                              (() => {
                                if (expense.expenseType == 'investment' || expense.expenseType == 'homeloan') {
                                  return (
                                    <Col sm={3}>
                                      <div className="form-prefix">$</div>
                                      <Input
                                        id={`liabilities[${index}].balance`}
                                        name={`liabilities[${index}].balance`}
                                        ref={this.setRef(`liabilities[${index}].balance`)}
                                        type="text"
                                        label={<span>Outstanding Balance</span>}
                                        value="0"
                                        changeDebounceInterval={0}
                                        updateOnBlur={false}
                                        blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                                        validationError="Outstanding balance required"
                                        validations={{
                                          isFormattedNumber: true,
                                          isRequired: true,
                                        }}
                                      />
                                    </Col>
                                  );
                                }
                              })()
                          }

                          {
                              (() => {
                                if (this.state.expenses.length >= 1) {
                                  return (
                                    <div>
                                      <button id={`removeExpense_${index}`} type="button" className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle btn-inverse" style={{ marginTop: '31px' }} onClick={() => { this.removeExpense(index); }}>
                                        <Icon glyph="delete" />
                                      </button>

                                      <Col sm={12} className="visible-xs text-right">
                                        <button type="button" className="mb-md btn btn-danger" onClick={() => { this.removeExpense(index); }}>
                                          Remove commitment
                                        </button>
                                      </Col>
                                    </div>
                                  );
                                }
                              })()
                          }
                        </Row>
                        {
                            (() => {
                              if (expense.expenseType == 'investment' && expense.ownerships) {
                                return (
                                  <Row className={expense.hasCustomOwnership ? '' : 'hidden'}>
                                    <h5 className="panel-row-subtitle"> Ownership </h5>
                                    {
                                    expense.ownerships.map((owner, ownershipIndex) => (
                                      <Col key={`liabilities[${expense.expenseId}][ownerships][${owner.applicantId}]`} sm={2}>
                                        <Input
                                          id={`liabilities[${index}][ownerships][${ownershipIndex}].applicantId`}
                                          name={`liabilities[${index}][ownerships][${ownershipIndex}].applicantId`}
                                          ref={this.setRef(`liabilities[${index}][ownerships][${ownershipIndex}].applicantId`)}
                                          value={owner.applicantId?.toString()}
                                          type="hidden"
                                        />
                                        <div className="form-prefix">%</div>
                                        <Input
                                          id={`liabilities[${index}][ownerships][${ownershipIndex}].owns`}
                                          name={`liabilities[${index}][ownerships][${ownershipIndex}].owns`}
                                          ref={this.setRef(`liabilities[${index}][ownerships][${ownershipIndex}].owns`)}
                                          label={<span className="light">{ owner.applicantName }</span>}
                                          value={owner.owns?.toString() || ''}
                                          updateOnChange={false}
                                          updateOnBlur={false}
                                          blurCallback={(name, value) => Mask.numberFormsy(name, value, this.inputRefs)}
                                          changeCallback={(_, value) => { this.changeOwnership(index, ownershipIndex, value); }}
                                          validationError="Ownership percentage is required. Total ownership must be <= 100"
                                          validations={{
                                            isFormattedNumber: true,
                                            isRequired: true,
                                            customValidator: (values) => {
                                              let totalOwnership = 0;
                                              expense.ownerships && expense.ownerships.forEach((_, i) => {
                                                totalOwnership += parseInt(Mask.clean(values[`liabilities[${index}][ownerships][${i}].owns`]) || 0);
                                              });
                                              return !expense.ownerships || totalOwnership <= 100;
                                            },
                                          }}
                                        />
                                      </Col>
                                    ))
                                  }
                                  </Row>
                                );
                              }
                            })()
                          }
                      </Col>
                    </Row>
                  ))
                }

                {
                      (() => {
                        if (this.state.expenses.length <= 24) {
                          return (
                            <Row className="panel-row text-center">
                              <Col sm={12}>
                                <button type="button" id="addExpense" className="btn btn-default" onClick={() => { this.addExpense(); }}>
                                  <Icon glyph="plus" className="primary mute-lg mr-sm" />
                                  {' '}
                                  <span className="secondary">
                                    {'Add '}
                                    <span className="hidden-xs"> financial</span>
                                    {' '}
                                    commitment
                                  </span>
                                </button>
                              </Col>
                            </Row>
                          );
                        }
                      })()
                    }
              </div>
            );
          })()
        }

      </div>
    );
  }
}

ComServiceAbilityExpense.propTypes = {
  data: PropTypes.object.isRequired,
};

ComServiceAbilityExpense.defaultProps = {
};

function mapStateToProps(state) {
  return {
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComServiceAbilityExpense);
