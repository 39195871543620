import * as React from 'react';
import PropTypes from 'prop-types';

interface INavigationProps {
  nextLabel?: string;
  previousStep?: React.EventHandler<React.MouseEvent<HTMLButtonElement>> | undefined
  saveSubmission?: () => void;
}

const Navigation = (props: INavigationProps): JSX.Element =>  {
  return (
    <div className="mt-lg mb-lg">
      <button type="submit" className="btn btn-success btn-lg pull-right">{props.nextLabel || "Next"}</button>
      {props.previousStep && <button data-test-name="previous" type="button" className="btn btn-default btn-simple btn-lg pull-right mr-sm" onClick={props.previousStep}>Previous</button>}
      {/* <button data-test-name="save" type="button" className="btn btn-default btn-simple btn-lg pull-right mr-sm" onClick={props.saveSubmission}>Save</button> */}
    </div>
  );
}

export default Navigation;
