import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { notify as Notify } from 'react-notify-toast';
import Event3rdParty from '../../helpers/event3rdParty';

import API from '../../api';

import { Icon, DateTime } from '../../utils';
import { InfoAlert } from '../../utils/alert';


import * as applicationsActions from '../../actions/applicationsActions';

import ApplicationAction from './applicationAction';

export class ApplicationActions extends React.Component {
  constructor(props) {
    super(props, {});
    this.state = {
      active: -1,
      progress: 100,
      progressPrevious: 100,
      processing: false,
      visible: false,
      device: 'Desktop',
      complete: false,
      contacts: {},
    };

    this.setActive = this.setActive.bind(this);
    this.dropzoneComplete = this.dropzoneComplete.bind(this);
    this.fileComplete = this.fileComplete.bind(this);
    this.actionComplete = this.actionComplete.bind(this);

    this.getActionsOutstanding = this.getActionsOutstanding.bind(this);
    this.getActionsPending = this.getActionsPending.bind(this);
    this.getActionsCompleted = this.getActionsCompleted.bind(this);
    this.getActionsTotal = this.getActionsTotal.bind(this);
  }

  componentDidMount() {
    let contacts = [];
    [...this.getActionsOutstanding(), ...this.getActionsPending()].map((action) => {
      if (action.staffNotes) {
        action.staffNotes.map((note) => {
          if (note.createdById && !contacts[note.createdById]) {
            contacts[note.createdById] = note.createdById;
          }
        });
      }
    });

    if (contacts.length) {
      API.getContactsDetails(contacts)
        .then((response) => {
          contacts = {};

          response.data.map((contact) => {
            contacts[contact.lFStaffID] = contact;
          });

          this.setState({
            contacts,
          });
        });
    }
  }

  refresh() {
    if (this.getActionsOutstanding().length == 0) {
      this.props.actions.getApplications()
        .then(() => {
          this.props.actions.getApplication(this.props.application.applicationNumber);
        });
    }
  }

  setActive(active) {
    this.setState({
      active,
    });
  }

  fileComplete(action, file) {
    setTimeout(() => {
      this.setState({
        complete: action.id,
      });
    }, 800);

    setTimeout(() => {
      this.setState({
        active: -1,
        complete: false,
      });

      this.props.actions.completeUpload(action, file);
      this.refresh();
    }, 1000);

    Notify.show('Your documents have been uploaded!', 'success', 3000);
  }

  actionComplete(action) {
    setTimeout(() => {
      this.setState({
        complete: this.state.active,
      });
    }, 800);

    setTimeout(() => {
      this.setState({
        active: -1,
        complete: false,
      });

      this.props.actions.completeAction(this.props.application.applicationNumber, action)
        .then(() => {
          setTimeout(() => {
            this.refresh();
          }, 1000);
        });
    }, 1000);
  }

  dropzoneComplete(dz) {
    Event3rdParty.send('upload-documents');

    setTimeout(() => {
      dz.removeAllFiles(true);
    }, 1000);
  }

  getActionsOutstandingPending() {
    return this.props.application.actions;
  }

  getActionsOutstanding() {
    return this.props.application.actions.filter((action) => action.status == 1);
  }

  getActionsPending() {
    return this.props.application.actions.filter((action) => action.status == 2);
  }

  getActionsCompleted() {
    return this.props.application.verifiedActions || [];
  }

  getActionsTotal() {
    return [...this.props.application.actions, ...(this.getActionsCompleted())];
  }

  getActionIcon(action) {
    switch (action.type) {
      case 3:
        return 'tag-more';
      case 2:
        return 'email-open';
      case 1:
      case 4:
      case 5:
      default: {
        if (action.status <= 2) {
          return 'upload';
        }

        return 'file';
      }
    }
  }

  getActionType(action) {
    switch (action.type) {
      case 3:
        return 'action';
      case 2:
        return 'post';
      case 6:
        return 'emailOrPhone';
      case 1:
      case 4:
      case 5:
      default:
        return 'file';
    }
  }

  getActionNote(action) {
    if (!action.staffNotes || !action.staffNotes.length) {
      return false;
    }
    return action.staffNotes[action.staffNotes.length - 1];
  }

  render() {
    return (
      <Row>
        <Col sm={12} className="main-panel">
          <div className="panel panel-default">
            <div className="panel-body">

              <Row className="hidden-xs mt-sm">
                <Col sm={4}>
                  <div className="card card-border card-slim">
                    <div className={`progress-circle progress-warning progress-${100 - Math.round((this.getActionsOutstanding().length / this.getActionsTotal().length) * 100)}`}>
                      <h2 className="progress-value">{this.getActionsOutstanding().length}</h2>
                      <div className="progress-circle-fill">
                        <div className="mask left" />
                        <div className="mask right" />
                      </div>
                    </div>

                    <h5 className="progress-label">
                    {'action'}
                    {this.getActionsOutstanding().length != 1 ? 's' : ''}
                    {' '}
                    <br />
                    outstanding
                    </h5>
                  </div>
                </Col>

                <Col sm={4}>
                  <div className="card card-border card-slim">
                    <div className={`progress-circle progress-secondary progress-${Math.round((this.getActionsPending().length / this.getActionsTotal().length) * 100)}`}>
                      <h2 className="progress-value">{this.getActionsPending().length}</h2>
                      <div className="progress-circle-fill">
                        <div className="mask left" />
                        <div className="mask right" />
                      </div>
                    </div>

                    <h5 className="progress-label">
                    {'action'}
                    {this.getActionsPending().length != 1 ? 's' : ''}
                    {' '}
                    <br />
                    {'awaiting '}
                    <span className="hidden-sm">verification</span>
                    </h5>
                  </div>
                </Col>

                <Col sm={4}>
                  <div className="card card-border card-slim">
                    <div className={`progress-circle progress-success progress-${Math.round((this.getActionsCompleted().length / this.getActionsTotal().length) * 100)}`}>
                      <h2 className="progress-value">{this.getActionsCompleted().length}</h2>
                      <div className="progress-circle-fill">
                        <div className="mask left" />
                        <div className="mask right" />
                      </div>
                    </div>

                    <h5 className="progress-label">
                      {'action'}
                      {this.getActionsCompleted().length != 1 ? 's' : ''}
                      {' '}
                      <br />
                      completed
                    </h5>
                  </div>
                </Col>
              </Row>

              <div className="mt-lg mb-lg ml-lg-n mr-lg-n">
                <hr />
              </div>

              <InfoAlert title="Tip">
                Files emailed to us won’t be reflected below until one of our team members verifies your file(s).
                {' '}
                <br />
                We recommend uploading files through Liberty IQ for a smoother experience and a quicker turnaround!
              </InfoAlert>

              <div className="mt-lg mb-lg ml-lg-n mr-lg-n">
                <hr />
              </div>

              {/* <h5>Actions outstanding</h5> */}
              <div className="actions">

                {
                (() => {
                  if (this.getActionsOutstandingPending().length) {
                    return (
                      <div>
                        {
                          this.getActionsOutstandingPending().map((action, index) => (
                              <ApplicationAction
                                action={action}
                                index={index}
                                application={this.props.application}

                                getActionIcon={this.getActionIcon}
                                getActionNote={this.getActionNote}
                                getActionType={this.getActionType}

                                setActive={this.setActive}
                                actionComplete={this.actionComplete}
                                fileComplete={this.fileComplete}
                                dropzoneComplete={this.dropzoneComplete}

                                state={this.state}
                              />
                          ))
                        }

                      </div>


                    );
                  }
                })()
              }

                <div className="mt-sm" />

                <ApplicationAction
                  action={{
                    description: 'Upload other documents',
                    history: [],
                    id: '00000000-0000-0000-0000-000000000000',
                    staffNotes: [],
                    status: 1,
                    type: 1,
                  }}
                  index={0}
                  application={this.props.application}

                  getActionIcon={this.getActionIcon}
                  getActionNote={this.getActionNote}
                  getActionType={this.getActionType}

                  setActive={this.setActive}
                  actionComplete={this.actionComplete}
                  fileComplete={this.fileComplete}
                  dropzoneComplete={this.dropzoneComplete}

                  state={this.state}
                />

              </div>

              {
                (() => {
                  if (this.getActionsCompleted().length) {
                    return (
                      <div>
                        {/* <h5 className="mt-lg">Actions completed</h5> */}

                        <div className="actions mt-md">
                          {
                            this.getActionsCompleted().map((action) => (
                                <div className="action completed mute" key={`completed-${action.id}`}>
                                  <Icon glyph={this.getActionIcon(action)} className="action-icon" />
                                  <Icon glyph="check" className="action-alert" />

                                  <div className="action-label">{action.name || action.description}</div>
                                  <div className="action-completed">
                                    {'Verified on '}
                                    <DateTime>{action.verifiedDate}</DateTime>
                                  </div>
                                </div>
                            ))
                          }
                        </div>
                      </div>
                    );
                  }
                })()
              }


            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...applicationsActions }, dispatch),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ApplicationActions);
