import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input} from 'formsy-react-components';
import Mask from '../../../helpers/mask';

import Title from '../title';
import CurrencyInput from '../currencyInput';

import {toOption, toRequired, selectText} from '../functions';
import {ILiabilityItem} from '../interfaces';
import {expenseOptions} from '../options';

interface IExpensesState {
  totalLivingExpenses: number;
}
interface IExpensesProps {
  liabilities: ILiabilityItem[];
  form: any;
}

export class Expenses extends React.Component<IExpensesProps, IExpensesState> {
  componentDidMount() {
    this.state = {
      totalLivingExpenses: 0
    };

    this.changeExpenses = this.changeExpenses.bind(this);
  }

  changeExpenses() {
    if (this.props.form) {
      let data = this.props.form.formsyForm.current.getModel();
      let totalLivingExpenses = expenseOptions.reduce((value, expense) => {
        return value + Number(Mask.clean(data.expense[expense.value].amount))
      }, 0);
      this.setState({totalLivingExpenses});
    }
  }

  get totalLivingExpenses(): number {
    return this.state ? this.state.totalLivingExpenses : 0;
  }

  get totalLiabilities():number {
    return this.props.liabilities.reduce((value, liability) => Number(liability.repayments) + value, 0);
  }

  get totalExpenses(): number {
    return this.totalLivingExpenses + this.totalLiabilities;
  }

  render(): JSX.Element {
    return (
      <div className="panel-section">
        <Title sub="Monthly breakdown of day to day living expenses">Living expenses</Title>

        <div className="panel-section-body panel-section-body-simple">

          <Row style={{marginBottom: '4px'}}>
            <Col sm={6} smOffset={3}>
              <label>Description</label>
            </Col>
            <Col sm={3}>
              <label>{toRequired(<span>Amount <small className="mute">(monthly)</small></span>)}</label>
            </Col>
          </Row>

          {
            expenseOptions.map(expense => {
              return (
                <Row key={`expense_${expense.label}`}>
                  <Col sm={3}>
                    <span className="mute ib" style={{paddingTop: '12px'}}>{expense.label}</span>
                  </Col>
                  <Col sm={6}>
                    <Input
                      name={`expense.${expense.value}.description`}
                      value="" />
                  </Col>

                  <Col sm={3}>
                    <CurrencyInput
                      name={`expense.${expense.value}.amount`}
                      align="right"
                      validations={{
                        isRequired: true
                      }}
                      validationErrors={{
                        isRequired: `${expense.label} is required`
                      }}
                      blurCallback={this.changeExpenses}
                    />
                  </Col>
                </Row>
              );
            })
          }

        </div>

        <Title>Total</Title>

        <div className="panel-section-body panel-section-body-simple">

          <Row>
            <Col sm={9} className="text-right">
              <span className="mute ib" style={{paddingTop: '12px'}}>Total living expenses</span>
            </Col>
            <Col sm={3}>
              <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
              <Input
                name="totalLiving"
                value={Mask.number(this.totalLivingExpenses)}
                className="form-control text-right"
                disabled />
            </Col>
          </Row>

          <Row>
            <Col sm={9} className="text-right">
              <span className="mute ib" style={{paddingTop: '12px'}}>Total financial commitments</span>
            </Col>
            <Col sm={3}>
              <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
              <Input
                name="totalLiabilities"
                value={Mask.number(this.totalLiabilities)}
                className="form-control text-right"
                disabled />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col sm={9} className="text-right">
              <span className="mute ib" style={{paddingTop: '12px'}}>Total expenses</span>
            </Col>
            <Col sm={3}>
              <div className="form-prefix" style={{marginTop: '2px'}}>$</div>
              <Input
                name="totalExpenses"
                value={Mask.number(this.totalExpenses)}
                className="form-control text-right"
                disabled />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Expenses;
