import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { Visibility } from './reducer';

interface LvrModalProps {
  visible: Visibility;
  hideModal: () => void;
}

export const LvrModal = ({hideModal, visible}: LvrModalProps) => (
  <Modal
    show={ visible === Visibility.Visible}
    onHide={hideModal}>

    <Modal.Body>
      <button type="button" className="close" aria-label="Close" onClick={hideModal}>
        <span aria-hidden="true">×</span>
      </button>

      <div>
        <h4 className="mb-lg">About the tool</h4>
        <p className="mute">This tool will provide you with an estimate of the maximum LVRs we will consider for different postcodes depending on the risk grade, the loan purpose and the verification level sought.</p>
        <p className="mute">This tool generates estimated maximum LVRs for certain postcodes based on a number of factors relevant to each postcode including population, geographical location, historical property prices, property turnover rates and historical loan performance.</p>
        <p className="mute">This tool is intended as a guide only and does not necessarily reflect the final maximum LVR applicable to an application which will depend on other factors including the property size, type, zoning and condition. All applications are subject to Liberty’s usual credit assessment criteria.</p>
      </div>
    </Modal.Body>

    <Modal.Footer>
      <button id="hideLvrModal" onClick={hideModal} type="button" className="btn btn-default pull-right">Close</button>
    </Modal.Footer>
  </Modal>
);

export default LvrModal;
