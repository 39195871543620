import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select, Checkbox } from 'formsy-react-components';
import Mask from '../../helpers/mask';
import * as Moment from 'moment';
import Icon from '../../utils/icon';

import { Applicant, KeyValuePair } from './models'
import { convertToSelectOptions } from '../applicationUpdatePage/helper';
import { get_States } from '../../constants/functions';
import RateQuoteApplicantCreditHistory from './rateQuoteApplicantCreditHistory';
import EmploymentHistory from '../motorPrequalToolPage/employmentHistory/employmentHistory';
import RateQuoteApplicantEmploymentHistory from './rateQuoteApplicantEmploymentHistory';

type RateQuoteApplicationPersonProps = {
  applicant: Applicant,
  applicants: Applicant[],
  index: number,
  handleName: (name: string, index: number) => void,
  handleRelationship: (relationship: string, index: number) => void,
  handlePartner: (index: number, inRelationshipWithId?: number) => void,
  removeApplicant: (index: number) => void
}

const RateQuoteApplicantPerson = (props: RateQuoteApplicationPersonProps) => {
  const [relationship, setRelationship] = React.useState<string>('');

  const dateOfBirthRef = React.useRef();
  const postcodeRef = React.useRef();

  const getLoanTerms = () => {
    const terms: KeyValuePair[] = [];
    for (let value = 30; value >= 10; value--) {
      terms.push({
        value,
        label: `${value} years`,
      });
    }

    return terms;
  }

  const getRelationshipStatuses = () => {
    return [{
      value: '',
      label: 'Select',
    },
    {
      value: 'Married to Non Applicant',
      label: 'Married',
    },
    {
      value: 'Single',
      label: 'Single',
    },
    {
      value: 'De Facto to Non Applicant',
      label: 'De-Facto',
    },
    {
      value: 'Separated',
      label: 'Separated',
    },
    {
      value: 'Divorced',
      label: 'Divorced',
    },
    {
      value: 'Widowed',
      label: 'Widowed',
    },
    ];
  }

  const getRelationshipStatus = (relationship) => {
    const statuses = getRelationshipStatuses();
    return statuses.find((status) => status.value === relationship)?.value || statuses[0].value;
  }

  const getDependants = () => {
    const dependants: KeyValuePair[] = [];
    dependants.push({
      value: '',
      label: 'Select',
    });

    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => {
      dependants.push({
        value: number,
        label: `${number}`,
      });
    });
    return dependants;
  }

  const getDependantValue = (value) => {
    const dependants = getDependants();
    const intVal = parseInt(value);
    return dependants.find((dependant) => dependant.value === intVal)?.value || dependants[0].value;
  }

  const getApplicants = () => {
    const applicants: KeyValuePair[] = [{
      value: '',
      label: 'Non Applicant',
    }];

    props.applicants.map((applicant) => {
      if (applicant.key !== props.applicant.key) {
        applicants.push({
          value: applicant.key,
          label: `Applicant ${applicant.key}`,
        });
      }
    });

    return applicants;
  }

  const handleRelationshipStatusChange = (name, value) => {
    setRelationship(value);

    props.applicants[props.index].relationship = value;
    props.handleRelationship(value, props.index);
  }

  const removeApplicant = () => props.removeApplicant(props.index);
  const handleName = (value) => props.handleName(value, props.index);
  const handlePartner = (value) => props.handlePartner(value, props.index);
  const handleRelationship = (value) => props.handleRelationship(value, props.index);

  const getApplicantName = (index) => {
    let name = `APPLICANT ${index + 1}`;
    if (index == 0) {
      name = `${name} (Primary)`;
    }
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  const changeName = () => { }

  return (
    <div className="card card-shallow card-stack">
      <div className="panel-row bg-primary-50" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
        <i className="mi mi-account mi-2x ib mr-md text-center np" style={{ width: '33px' }} />

        <span className="visible-xs mt-sm" />
        <div className="ib va-tt mt-sm-n" style={{ paddingTop: '4px' }}>
          <h3 className="mb-h mt-h">{getApplicantName(props.index)}</h3>
        </div>
        <button
          id={`removeApplicant${props.index}`}
          type="button"
          className="btn btn-default btn-danger pull-right btn-circle"
          style={{ marginTop: '-4px', marginRight: '2px' }}
          onClick={removeApplicant}
        >
          <Icon glyph="delete" />
        </button>
      </div>

      <div className="panel-section-container">
        <Row>
          <Col sm={4}>
            <Input
              id={`applicants.${props.index}.firstName`}
              name={`applicants.${props.index}.firstName`}
              //ref={`applicants.${props.index}.firstName`}
              label="First name (optional)"
              blurCallback={changeName}
              value={props.applicant.firstName}
            />
          </Col>

          <Col sm={4}>
            <Input
              name={`applicants.${props.index}.middleName`}
              //ref={`applicants.${props.index}.middleName`}
              id={`applicants.${props.index}.middleName`}
              label="Middle name (optional)"
              value={props.applicant.middleName}
              blurCallback={changeName} />
          </Col>

          <Col sm={4}>
            <Input
              name={`applicants.${props.index}.lastName`}
              //ref={`applicants.${props.index}.lastName`}
              id={`applicants.${props.index}.lastName`}
              label="Last name (optional)"
              value={props.applicant.lastName}
              blurCallback={changeName}
            />
          </Col>
        </Row>
        <Row>
          {(() => (
            <Col sm={4}>
              <Select
                id={`applicants.${props.index}.relationship`}
                name={`applicants.${props.index}.relationship`}
                label="Relationship Status"
                value={getRelationshipStatus(props.applicant.relationship)}
                options={getRelationshipStatuses()}
                changeCallback={handleRelationshipStatusChange}
                validationError="Please select the relationship status"
                validations={{
                  isRequired: true,
                }}
                className="form-control"
              />
            </Col>
          ))()}

          <Col sm={4}>
            <Select
              id={`applicants.${props.index}.dependants`}
              name={`applicants.${props.index}.dependants`}
              label="Dependants"
              value={getDependantValue(props.applicant.dependants)}
              options={getDependants()}
              validationError="Please select the number of dependants"
              validations={{
                isRequired: true,
              }}
              className="form-control"
            />
          </Col>
          <Col sm={4}>
            <Input
              id={`applicants.${props.index}.dateOfBirth`}
              name={`applicants.${props.index}.dateOfBirth`}
              ref={dateOfBirthRef}
              type="text"
              placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
              updateOnBlur={false}
              changeDebounceInterval={0}
              blurCallback={(name, value) => Mask.fullDateFormsy(name, value, dateOfBirthRef)}
              label="Date of Birth"
              value={props.applicant.dateOfBirth}
              validationError="Date of Birth must be valid"
              validations={{
                isValidPastDate: true,
                isRequired: true,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Input
              id={`applicants.${props.index}.postcode`}
              name={`applicants.${props.index}.postcode`}
              ref={postcodeRef}
              label="Postcode"
              value={props.applicant.postcode}
              type="text"
              maxLength={4}
              updateOnBlur={false}
              changeDebounceInterval={0}
              blurCallback={(name, value) => Mask.cleanFormsy(name, value, postcodeRef)}
              validationError="Postcode is required"
              validations={{
                isRequired: true,
              }}
            />
            <i
              style={{ top: '6px', right: '12px' }}
              className="mute-lg a mi mi-help-outline pointer"
              data-position="fixed"
              data-event-on="click"
              data-tip="Postcode where the applicant currently lives"
            />
          </Col>


          <Col sm={6}>
            <Select
              id={`applicants.${props.index}.state`}
              name={`applicants.${props.index}.state`}
              label="State"
              value={props.applicant.state}
              validationError="Please select the state"
              validations={{
                isRequired: true,
              }}
              options={convertToSelectOptions(get_States(), '', (s) => s, (s) => s)}
              className="form-control"
            />
          </Col>
        </Row>
      </div>
      {/* Hiding employment history until risk grades returned are accurate. */}
      {/* <div className="panel-section-container">
        <RateQuoteApplicantEmploymentHistory
          index={props.index}
          applicants={props.applicants} 
        />
      </div> */}
      <div className="panel-section-container">
        <RateQuoteApplicantCreditHistory
          index={props.index}
          //data={props.applicants ? props.applicants[props.index] : null}
          applicants={props.applicants}
        />
      </div>
    </div>
  );
}

export default RateQuoteApplicantPerson;
