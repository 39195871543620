import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingStepHelp extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="onboarding-step text-center">
        <h2 className="onboarding-title">Help us help you</h2>
        <h4 className="onboarding-subtitle mute">Our aim is to make Liberty IQ as great as possible. To do this we’d love your feedback, so at the bottom of every page we have included the ability for you to leave your ideas and suggestions.</h4>
      </div>
    );
  }
}
