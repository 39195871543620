import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class MotorPrequalModal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <div className="pl-sm pr-sm mt-sm mute">
            <h4>Disclaimer</h4>
            <p>This tool will provide an estimate for consumer applications only. Not all information is mandatory, however, providing more information will enhance the accuracy of the result. If an application is submitted, the final interest rate and outcome will be determined by factors including: the customer profile, serviceability, credit history and security details.</p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
