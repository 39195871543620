import * as React from 'react';
import PropTypes from 'prop-types';
import FaqItem from './faqItem';

const FaqGroup = (props) => {
  if (!props.faqs || !props.faqs.length) {
    return null;
  }

  return (
    <div className="panel panel-default">

      {
        (() => {
          if (props.title) {
            return (
              <div className="panel-header">
                <h4>{props.title}</h4>
              </div>
            );
          }
        })()
      }

      <div className="panel-body p-h">

        <table className="table table-hover table-click m-h faq-group">
          <tbody>
            {
              props.faqs.map((faq, idx) => (
                  <FaqItem key={`faq-${idx}`} faq={faq} id={`${props.title ? props.title.toLowerCase() : ''}-${idx}`} />
              ))
            }
          </tbody>
        </table>

      </div>

    </div>
  );
};


FaqGroup.propTypes = {
  faqs: PropTypes.any,
  title: PropTypes.string,
};

export default FaqGroup;
