import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import ReactTooltip from 'react-tooltip';

import { Icon, Money, Percentage } from '../../utils';

import ComServiceAbilitySubmitNow from './comServiceAbilitySubmitNow';
import ComServiceAbilitySuggestion from './comServiceAbilitySuggestion';
import { shouldShowSubmitNow } from './comServiceAbilitySubmitNowController';

import { surplusOptionsResidential } from './index';

export default class ComServiceAbilityResult extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      fineprint: false,
      breakdown: false,
    };

    this.getService = this.getService.bind(this);

    this.getRepaymentPercentage = this.getRepaymentPercentage.bind(this);
    this.getExpensePercentage = this.getExpensePercentage.bind(this);
    this.getCommitmentPercentage = this.getCommitmentPercentage.bind(this);
    this.getSuggestion = this.getSuggestion.bind(this);
    this.getLoanAmount = this.getLoanAmount.bind(this);
    this.getPurchaseAmount = this.getPurchaseAmount.bind(this);
    this.getLoanSplits = this.getLoanSplits.bind(this);
    this.toggleBreakdown = this.toggleBreakdown.bind(this);
    this.navigateToCommercialDocuments = this.navigateToCommercialDocuments.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getService() {
    return this.props.result.surplusWithLoading >= 0;
  }

  getSuggestion() {
    return this.props.result.maxBorrowingCapacityAtSurplus[0].capacity > 0;
  }

  getRepaymentPercentage() {
    const percentage = Math.round((this.props.result.totalRepaymentWithLoading / this.props.result.netIncome) * 100);
    return percentage;
  }

  getExpensePercentage() {
    const percentage = Math.round((this.props.result.livingExpenses / this.props.result.netIncome) * 100);

    if (!this.getService()) {
      if (100 - percentage - this.getRepaymentPercentage() <= 0) {
        return 100 - this.getRepaymentPercentage();
      }
    }
    return percentage;
  }

  getCommitmentPercentage() {
    const percentage = Math.round((this.props.result.financialCommitments / this.props.result.netIncome) * 100);

    if (!this.getService()) {
      if (100 - this.getExpensePercentage() - this.getRepaymentPercentage() <= 0) {
        return 0;
      }

      if (100 - percentage - this.getExpensePercentage() - this.getRepaymentPercentage() <= 0) {
        return 100 - this.getExpensePercentage() - this.getRepaymentPercentage();
      }
    }
    return percentage;
  }

  getLoanAmount() {
    let amount = 0;

    this.props.data.multiLoanDetails.map((loan) => {
      loan.splitLoanDetails.map((split) => {
        amount += split.loanAmount;
      });
    });

    return amount;
  }

  getPurchaseAmount() {
    let amount = 0;

    this.props.data.multiLoanDetails.map((loan) => {
      amount += loan.purchaseAmount;
    });

    return amount;
  }

  getLoanSplits() {
    const splits = [];

    this.props.result.loanRepayments.map((loan) => {
      loan.repaymentSplitResults.map((split) => {
        splits.push(split);
      });
    });

    return splits;
  }

  toggleBreakdown() {
    this.setState({
      breakdown: !this.state.breakdown,
    });
  }

  navigateToCommercialDocuments(router) {
    router.push({ pathname: '/documents/cd' });
  }

  /**
   * Render
   */
  render() {
    return (
      <div className="panel-section result">

        {
          (() => {
            if (this.props.result) {
              return (
                <div className="panel-section-header panel-header-fixed po">
                  <i className="mi mi-assignment-o mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

                  <div className="ib va-tt mt-sm-n">
                    <h3 className="mb-h mt-h">Indicative result</h3>
                  </div>
                </div>
              );
            }
          })()
        }

        <div className="panel-section-body panel-section-body-tint">

          <div className="panel-section-container text-center">
            {
              (() => {
                if (this.props.result) {
                  return (
                    <Row className="mb-lg">
                      <div className="panel-divider mt-sm mb-lg" />
                      <Col sm={8} smOffset={2}>
                        <div className="card text-left pb-h mb-lg mt-md">

                          <div className="text-center mt-lg">
                            <Icon glyph="assignment-o" className="mute-hg" size={6} />
                            <Icon glyph={this.getService() ? 'check' : 'close'} className={`text-${this.getService() ? 'success' : 'danger'} a mt-lg`} size={3} style={{ left: 0, right: 0 }} />

                            <h3>
                              {this.getService() ? 'Serviceability is looking good' : 'Serviceability is not quite there yet'}
                              {' for '}
                              <strong>
                              <Money round={false}>{this.getLoanAmount()}</Money>
                              {' '}
                              </strong>
                              {' '}
                              <span className="text-md mute po">
                              <br />
                              (
                              <Percentage>{this.getLoanAmount() / this.getPurchaseAmount()}</Percentage>
                              {' '}
                              LVR)
                              </span>
                            </h3>

                            <div className="clearfix" />

                            {
                              this.getSuggestion()
                                && (
                                <ComServiceAbilitySuggestion
                                  numberOfApplicants={this.props.data ? this.props.data.applicants.length : 0}
                                  result={this.props.result}
                                  options={surplusOptionsResidential}
                                />
                                )
                            }

                            <div className="clearfix" />
                          </div>

                          <div className="progress m-md mt-lg" data-tip="Surplus" data-event-on="hover">
                            <div data-tip="Loan repayments" data-event-on="hover" className={`progress-bar progress-bar-${this.getService() ? 'success' : 'danger'}`} style={{ width: `${this.getRepaymentPercentage()}%` }} />
                            <div data-tip="Living expenses" data-event-on="hover" className={`progress-bar progress-bar-${this.getService() ? 'success' : 'danger'}`} style={{ opacity: 0.33, width: `${this.getExpensePercentage()}%` }} />
                            <div data-tip="Financial commitments" data-event-on="hover" className={`progress-bar progress-bar-${this.getService() ? 'success' : 'danger'}`} style={{ opacity: 0.66, width: `${this.getCommitmentPercentage()}%` }} />
                          </div>

                          <div className="clearfix" />

                          <div className="ml-md-n mr-md-n">
                            <table className="table table-lg table-hover mt-md mb-h" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="dot mr-sm" />
                                    Income
                                    {' '}
                                    <small className="mute">(after tax)</small>
                                    {' '}
                                    &nbsp;
                                  </td>
                                  <td className="text-right">
                                    <strong className="mr-md">
                                      <Money round={0}>{this.props.result.netIncome}</Money>
                                      <span className="transparent">)</span>
                                    </strong>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <span className={`dot dot-${this.getService() ? 'success' : 'danger'} mr-sm`} style={{ opacity: 1 }} />
                                    Loan repayments
                                    {' '}
                                    {this.getLoanSplits().length > 1 && (
                                    <button type="button" className="btn btn-link p-h text-md np" style={{ marginTop: '-1px' }} onClick={this.toggleBreakdown}>
                                    {this.state.breakdown ? 'hide' : 'show'}
                                    {' '}
                                    breakdown
                                    </button>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    <strong className="mr-md">
                                    (
                                    <Money round={0}>{this.props.result.totalRepaymentWithLoading}</Money>
                                    )
                                    </strong>
                                    <small
                                      data-event-on="hover"
                                      data-tip={`Your customer's repayments at the requested rate would be about $${Math.round(this.props.result.totalRepaymentAtRequestedRate).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ($1) => `${$1},`)}`}
                                    >
                                      <Icon glyph="help-outline" className="a mute-lg" style={{ right: '18px' }} />
                                    </small>
                                  </td>
                                </tr>

                                {
                                  this.props.result.loanRepayments.map((loan, loanIndex) => (
                                      <tr key={loanIndex} className={!this.state.breakdown ? 'hidden' : ''}>
                                        <td colSpan={2} className="p-h">
                                          <table className="table mute m-h">
                                            <tbody>
                                              {
                                              loan.repaymentSplitResults.map((split, splitIndex) => (
                                                  <tr key={splitIndex}>
                                                    <td className="pt-sm pb-sm">
                                                    <span className="ml-md">
                                                    {'Split '}
                                                    {splitIndex + 1}
                                                    </span>
                                                    </td>
                                                    <td className="pt-sm pb-sm text-right">
                                                      <small className="mute po">
                                                        (
                                                        <Money round={0}>{split.repaymentAtRequestedRate}</Money>
                                                        )
                                                      </small>
                                                      &nbsp;
                                                      <strong className="mr-md">
                                                      (
                                                      <Money round={0}>{split.repaymentWithLoading}</Money>
                                                      )
                                                      </strong>
                                                      <small
                                                        className="np"
                                                        data-event-on="hover"
                                                        data-tip={`Your customer's repayments at the requested rate would be about $${Math.round(split.repaymentAtRequestedRate).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ($1) => `${$1},`)}`}
                                                      >
                                                        <Icon glyph="help-outline" className="a mute-lg" style={{ right: '18px' }} />
                                                      </small>
                                                    </td>
                                                  </tr>
                                              ))
                                            }
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                  ))
                                }

                                <tr>
                                  <td>
                                    <span className={`dot dot-${this.getService() ? 'success' : 'danger'} mr-sm`} style={{ opacity: 0.33 }} />
                                    Living expenses
                                    {' '}
                                    {this.props.data.expenses && this.props.data.expenses.statedExpenses < this.props.result.livingExpenses && <small className="mute">(estimated)</small>}
                                  </td>
                                  <td className="text-right">
                                    <strong className="mr-md">
                                      (
                                      {this.props.result.livingExpenses !== 0 ? (
                                        <Money round={0}>{this.props.result.livingExpenses}</Money>
                                      ) : (
                                        'N/A'
                                      )}
                                      )
                                    </strong>
                                    {this.props.result.livingExpenses === 0 && (
                                      <small
                                        data-event-on="hover"
                                        data-tip="Living expenses excluded from serviceability"
                                      >
                                        <Icon glyph="help-outline" className="a mute-lg" style={{ right: '18px' }} />
                                      </small>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span className={`dot dot-${this.getService() ? 'success' : 'danger'} mr-sm`} style={{ opacity: 0.66 }} />
                                    Financial commitments
                                  </td>
                                  <td className="text-right">
                                    <strong className="mr-md">
                                      (
                                      <Money round={0}>{this.props.result.financialCommitments}</Money>
                                      )
                                    </strong>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <span className="dot mr-sm transparent" />
                                    {this.getService() ? 'Surplus' : 'Shortfall'}
                                  </td>
                                  <td className="text-right">
                                    <strong className="mr-md">
                                      {this.getService() ? (
                                    <span>
                                    <Money round={0}>{this.props.result.surplusWithLoading}</Money>
                                    <span className="transparent">)</span>
                                    </span>
                                      ) : (
                                    <span>
                                    (
                                    <Money round={0}>{this.props.result.surplusWithLoading * -1}</Money>
                                    )
                                    </span>
                                      )}
                                    </strong>
                                  </td>
                                </tr>

                                <tr> 
                                  <td> 
                                    <span className="dot mr-sm transparent" /> 
                                      DSCR 
                                  </td> 
                                  <td className="text-right"> 
                                    <strong className="mr-md"> 
                                    {this.props.result.debtServicingCoverageRatio}
                                    x 
                                    </strong> 
                                  </td> 
                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </Col>
                    </Row>
                  );
                }
              })()
            }

            <div className="text-right np">

              <button type="submit" className="btn btn-success btn-lg hidden-xs pull-right">Calculate</button>
              <button type="submit" className="btn btn-success btn-block btn-lg mt-md-n visible-xs text-center mb-sm">Calculate</button>

              <button type="button" className="btn btn-default btn-simple btn-lg mr-sm" onClick={() => { this.props.savePrequal(); }}>Save</button>
              {this.props.result && <button type="button" className="btn btn-primary btn-simple btn-lg mr-sm" onClick={() => { this.props.workshopWithBdm(this.props.data, this.props.result); }}>Workshop with BDM</button>}

              {this.props.result && <button type="button" className="btn btn-default btn-simple pull-left btn-lg mr-sm" onClick={window.print}>Print</button>}
              {this.props.result && <button type="button" className="btn btn-default btn-simple pull-left btn-lg" onClick={() => { this.navigateToCommercialDocuments(this.props.router); }}>Commercial Documents</button>}
            </div>

          </div>
        </div>

      </div>
    );
  }
}

ComServiceAbilityResult.propTypes = {
  result: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  data: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  savePrequal: PropTypes.func.isRequired,
  workshopWithBdm: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

ComServiceAbilityResult.defaultProps = {
  result: false,
};
