import * as React from 'react';
import PropTypes from 'prop-types';
import { getPrefixText } from "./serviceAbilitySuggestion";
import { InfoAlert } from "../../utils/alert";
import { Money } from "../../utils";

interface MotorServiceabilitySuggestionProps {
  numberOfApplicants: number;
  maxBorrowingCapacity: number;
  preferredSurplus: number;
  hasBalloonAmount: boolean;
}

export const MotorServiceabilitySuggestion = (props: MotorServiceabilitySuggestionProps) =>
  <div className="m-md">
    <InfoAlert>
      <div>
        Your {props.numberOfApplicants > 1 ? "customers" : "customer"} can
        service {getPrefixText(props.preferredSurplus)}
        <strong className="primary">
          {" "}
          <Money round={false}>{props.maxBorrowingCapacity}</Money>{" "}
        </strong>
        {props.hasBalloonAmount ? " with $0 balloon " : ""}
        leaving a
        <span>
          {" "}
          <Money round={false}>{props.preferredSurplus}</Money>{" "}
        </span>
        surplus.
      </div>
    </InfoAlert>
  </div>
