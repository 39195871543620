import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Icon } from '../../utils';

export const ApplicationSecurityMotorNoVehicle = (props: { applicationState: Number, applicationNumber: Number }) => (
  <div className="security security-single">
    <h4>No vehicle selected</h4>
    <p className="mute">
            No vehicle selected.
    </p>
    <br />
    <br className="visible-xs" />
    {
          props.applicationState
            ? (
              <div>
                <Link to={`/applications/${props.applicationNumber}/update/vehicle`} style={{ position: 'absolute', bottom: '20px' }}>Add vehicle</Link>

                <Link className="btn btn-secondary btn-circle card-btn" to={`/applications/${props.applicationNumber}/update/vehicle`}>
                  <Icon glyph="edit" />
                </Link>
              </div>
            )
            : ''
        }
  </div>
);
