import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from 'react-bootstrap';
import * as sessionActions from '../../actions/sessionActions';
import * as storageActions from '../../actions/storageActions';


import OnboardingDots from './onboardingDots';
import OnboardingStepWelcome from './onboardingStepWelcome';
import OnboardingStepTrack from './onboardingStepTrack';
import OnboardingStepSupport from './onboardingStepSupport';
import OnboardingStepHelp from './onboardingStepHelp';
import OnboardingStepConfirm from './onboardingStepConfirm';

export class OnboardingPane extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      visible: false,
      step: 1,
      steps: 5,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount() {
    this.props.actions.getStorage('newIntroducerOnboarding')
      .then((result) => {
        if (!result.data) {
          this.showModal();
          this.props.actions.setStorage('newIntroducerOnboarding');
          this.props.actions.setStorage('newPrequalOnboarding');
        }
      });
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  hideModal() {
    this.setState({
      visible: false,
    });
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  render() {
    return (
      <Modal
        bsSize="lg"
        id="someID"
        className="onboarding"
        show={this.state.visible}
        onHide={this.hideModal}
      >

        <Modal.Body>
          { this.state.step < this.state.steps && (
            <button type="button" className="btn btn-default btn-lg onboarding-skip" onClick={this.hideModal}>
            {'Skip '}
            <span className="hidden-xs">guide</span>
            </button>
          ) }

          <OnboardingDots step={this.state.step} dots={this.state.steps} />

          { this.state.step == 1 && <OnboardingStepWelcome name={this.props.session.details.firstName} /> }
          { this.state.step == 2 && <OnboardingStepTrack /> }
          { this.state.step == 3 && <OnboardingStepSupport /> }
          { this.state.step == 4 && <OnboardingStepHelp /> }
          { this.state.step == 5 && <OnboardingStepConfirm /> }

          <div className="onboarding-actions">
            { this.state.step < this.state.steps && <button type="button" className="btn btn-default btn-secondary btn-lg pull-right" onClick={this.nextStep}>Next</button> }
            { this.state.step == this.state.steps && <button type="button" className="btn btn-default btn-secondary btn-lg pull-right" onClick={this.hideModal}>Close</button> }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}


OnboardingPane.propTypes = {
  session: PropTypes.any,
  actions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...sessionActions, ...storageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPane);
