import initialState from './initialState';
import { Document, DocumentsAction, DocumentsResponse, DocumentsActionType } from '../actions/documentsActions';

export type DocumentsState = {
  all: Document[],
  forms: Document[],
  guides: Document[],
  insuranceDocuments: Document[],
  targetMarketDeterminationDocuments: Document[],
} | null

export default function documentsReducer(state: DocumentsState = initialState.documents, action: DocumentsAction) {
  switch (action.type) {
    case DocumentsActionType.GET_DOCUMENTS:
      return sortDocuments(action.documents);

    case DocumentsActionType.START_DOCUMENT: {
      return startProcessingDocuments(action, state);
    }

    case DocumentsActionType.STOP_DOCUMENT: {
      return stopProcessingDocuments(action, state);
    }

    default:
      return state;
  }
}

function startProcessingDocuments(action: DocumentsAction, state) {
  return setDocumentIsProcessingByFileName(true, action, state);
}

function stopProcessingDocuments(action: DocumentsAction, state) {
  return setDocumentIsProcessingByFileName(false, action, state);
}

function setDocumentIsProcessingByFileName(isProcessing, action, state): Document {
  function _processDocumentMapper(_isProcessing, fileName) {
    return function setDocumentIsProcessing(document: Document): Document {
      if (document.fileName === fileName) {
        return {
          ...document,
          isProcessing: _isProcessing,
        };
      }

      return {
        ...document,
        isProcessing: false,
      };
    };
  }

  const setDocumentProcessing = _processDocumentMapper(isProcessing, action.file.fileName);
  return {
    ...state,
    all: state.all.map(setDocumentProcessing),
    [action.group]: state[action.group] ? state[action.group].map(setDocumentProcessing) : [],
  };
}

function sortDocuments(documents: DocumentsResponse): DocumentsState {
  let all = [];

  for (const group of Object.keys(documents)) {
    all = all.concat(...documents[group]);
  }

  for (const group of Object.keys(documents)) {
    if (documents[group] && documents[group].length) {
      documents[group] = documents[group].filter((document) => (document.description.search('New Zealand') == -1) && document.name != 'Australian Insurance Introducer Guide').sort((a, b) => {
        if (a.description < b.description) return -1;
        if (a.description > b.description) return 1;

        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });
    }
  }

  return { ...documents, all };
}
