import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'react-bootstrap';

const ContactPane = (props) => (
    <Modal
      id="ContactPane"
      show={props.showModal}
      onHide={props.onHide}
    >

      <Modal.Header closeButton>
        <Modal.Title>Get in touch</Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <Row>
          <Col sm={6}>
            <h5>Scenarios</h5>
            <p>
              <i className="mi mi-phone secondary" />
              {' '}
              13 11 33
              <br />
              <i className="mi mi-mail-send secondary" />
              {' '}
              <a href="mailto:scenarios@liberty.com.au" className="primary">scenarios@liberty.com.au</a>
            </p>
          </Col>

          <Col sm={6}>
            <h5>Support</h5>
            <p>
              <i className="mi mi-phone secondary" />
              {' '}
              13 11 33
              <br />
              <i className="mi mi-mail-send secondary" />
              {' '}
              <a href="mailto:application_support@liberty.com.au" className="primary">application_support@liberty.com.au</a>
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
);

ContactPane.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ContactPane;
