import * as React from 'react';
import Axios from 'axios';
import * as Moment from 'moment';
import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';
import * as Guid from 'guid';

import Navigation from '../navigation';
import Header from '../header';
import Add from '../add';
import Title from '../title';

import Payg from './payg';
import Rental from './rental';
import OtherIncome from './otherIncome';

import {debug} from '../debug';
import {shouldFormBeVisible, getCurrentFormData, hasFormData, setFormData} from '../functions';

import {IBaseProps, IGuid, IIndividual, IFormData} from '../interfaces';
import {IOption} from '../../../interfaces';

import Mask from '../../../helpers/mask';
import AddButton from '../addButton';

interface IIncome {
  id: string;
  type: string;
  belongsTo: string;
  status: string;
}

interface IIncomesState {
  incomes: IIncome[]
}
interface IIncomesProps extends IBaseProps {}

export class Incomes extends React.Component<IIncomesProps, IIncomesState> {
  constructor(props) {
    super(props);

    this.state = {
      incomes: []
    };

    this.addIncome = this.addIncome.bind(this);
    this.removeIncome = this.removeIncome.bind(this);

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (hasFormData(this.props.data, 'incomes')) {
      this.props.data.incomes.forEach(income => {
        this.addIncome(income.belongsTo, income.type, income.status);
      });
    } else {
      this.props.data.applicants.individuals.forEach(individual => {
        this.addIncome(individual.id, 'payg');
      });
    }
  }

  componentDidMount() {
    if (hasFormData(this.props.data, 'incomes')) {
      setFormData(this.props.data, 'incomes', this.refs.form);
    }
  }

  addIncome(belongsTo: IGuid, type: string, status: string = "permanentFullTime") {
    const incomes = this.state.incomes;
    incomes.push({
      id: Guid.create().value,
      belongsTo,
      type,
      status
    });
    this.setState({incomes});
  }

  removeIncome(id: IGuid) {
    const incomes = this.state.incomes.filter(income => income.id != id);
    this.setState({incomes});
  }

  onValidSubmit(data: IFormData) {
    if (!this.hasEnteredIncome.call(this)) {
      return this.props.notifyStep("No incomes entered...");
    }

    this.props.saveStepData(data.incomes, 'incomes');
  }

  hasEnteredIncome() {
    return this.state.incomes.length;
  }

  onInvalidSubmit() {
    this.props.notifyStep();
  }

  get options(): IOption[] {
    return ["PAYG", "Rental", "Other"].map(option => ({
      label: option,
      value: option.toLowerCase().split(' ').map((word, index) => index ? word.charAt(0).toUpperCase() + word.slice(1) : word).join('')
    }));
  }

  mapIcon(option: IOption): string {
    switch(option.value) {
      case 'payg': return 'store';
      case 'rental': return 'home';
      case 'other':
      default: return 'receipt';
    }
  }

  render(): JSX.Element {
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.onInvalidSubmit}
        className={shouldFormBeVisible(this.props.currentStep, 1)}
        ref="form"
        layout="vertical"
        disabled={false}
        elementWrapperClassName=""
        labelClassName=""
        rowClassName=""
        validateBeforeSubmit
        validatePristine>

        <div className="panel panel-default">
          <div className="panel-body p-h">
            <Header title="Income" required />

            {
              this.props.data.applicants.individuals.map(applicant => {
                return (
                  <div className="panel-section" key={`applicant-${applicant.id}`}>
                    <Title sub="We'll need at least 6 months of PAYG income" noborder>Income for {applicant.firstName} {applicant.lastName}</Title>

                    <div className="panel-section-body panel-section-body-simple">
                      <div className="panel-section-container panel-section-container-well">
                        {
                          this.state.incomes.filter(income => income.belongsTo == applicant.id).map((income, index) => {
                            let attr = {
                              removeIncome: this.removeIncome,
                              key: income.type + income.id,
                              income,
                              index
                            }

                            switch (income.type) {
                              case 'payg': {
                                return <Payg {...attr} />
                              }

                              case 'rental': {
                                return <Rental {...attr} />
                              }

                              case 'other': {
                                return <OtherIncome {...attr} />
                              }
                            }
                          })
                        }

                        <div className="panel-section-btns">
                          {
                            this.options.map(option => {
                              return (
                                <AddButton
                                  key={`add-${option.value}`}
                                  glyph={this.mapIcon(option)}
                                  label={`${option.label} income`}
                                  onClick={this.addIncome.bind(this, applicant.id, option.value)} />
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>

        <Navigation saveSubmission={() => this.props.saveSubmission(getCurrentFormData(this.refs.form), "incomes")} previousStep={this.props.previousStep} />
      </Form>
    );
  }
}

export default Incomes;
