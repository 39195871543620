import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '.';

const Empty = (props) => (
    <div className="main panel mt-sm-n">
      <div className="panel panel-default">
        <div className="panel-body pl-lg pr-lg">
          <div className="text-center mt-lg pt-lg pb-lg mb-lg">
            <Icon glyph={props.glyph} size={6} className="mt-lg mb-lg mute-lg" />
            <h3>{props.title}</h3>
            {props.children}
          </div>
        </div>
      </div>
    </div>
);

export const EmptyNotAllowed = () => <Empty glyph="block" title="Oops, no access to this page...">You do not have access to this page.</Empty>;
export const EmptyNoMobile = () => <Empty glyph="smartphone" title="Mobile version not yet supported..." />;

Empty.propTypes = {
  glyph: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default Empty;
