import * as React from 'react';
import PropTypes from 'prop-types';

export default class OnboardingStepWelcome extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    return (
      <div className="onboarding-step text-center welcome">
        <h2 className="onboarding-title">
        {this.props.name}
        {', welcome to Liberty IQ'}
        </h2>
        <h4 className="onboarding-subtitle mute">We’re here to help you track and progress your applications and provide you with all the necessary information and documentation you need from us.</h4>
      </div>
    );
  }
}
