import Guid from 'guid';
import { Dispatch } from 'redux';

import API from '../api';

export interface PrequalUrlParams {
  id?: Guid;
}

export type PrequalData = any;

export const hasPrequalId = (params: PrequalUrlParams) => !!params.id;
export const hasPrequalName = (prequal: PrequalData) => !!prequal.profileName;

export const shouldRedirect = (route?: string) => !!route;
export const shouldCreatePrequal = (prequal: PrequalData) => hasPrequalName(prequal);

export enum PrequalActionType {
  InitPrequal = "InitPrequal",

  GetPrequal = "GetPrequal",
  GetPrequalFailed = "GetPrequalFailed",
  GetPrequalSucceeded = "GetPrequalSucceeded",

  SavePrequal = "SavePrequal",
  SavePrequalFailed = "SavePrequalFailed",
  SavePrequalSucceeded = "SavePrequalSucceeded",

  ShowCreatePrequal = "ShowCreatePrequal"
}

export enum ProfileType {
  ResidentialServiceability = 'residentialServiceability',
  ResidentialRateQuote = 'residentialRateQuote',
  ResidentialLvr = 'residentialLvr',
  ResidentialRepayments = 'residentialRepayments',
  MotorServiceability = 'motorServiceability',
  MotorRateQuote = 'motorRateQuote',
  CommercialServiceability = 'commercialServiceability',

}

export type PrequalAction =
  InitPrequalAction
  | CreatePrequalAction
  | GetPrequalAction
  | SavePrequalAction;

export type GetPrequalAction =
  GetPrequal
  | GetPrequalFailed
  | GetPrequalSucceeded;

export type InitPrequalAction =
  InitPrequal;

interface InitPrequal {
  type: PrequalActionType.InitPrequal;
}

interface GetPrequal {
  type: PrequalActionType.GetPrequal;
}
interface GetPrequalFailed {
  type: PrequalActionType.GetPrequalFailed;
}
interface GetPrequalSucceeded {
  data: PrequalData;
  type: PrequalActionType.GetPrequalSucceeded;
}

export type CreatePrequalAction =
  ShowCreatePrequal;

export type SavePrequalAction =
  SavePrequal
  | SavePrequalFailed
  | SavePrequalSucceeded;

interface ShowCreatePrequal {
  type: PrequalActionType.ShowCreatePrequal;
}

interface SavePrequal {
  type: PrequalActionType.SavePrequal;
}
interface SavePrequalFailed {
  type: PrequalActionType.SavePrequalFailed;
}
interface SavePrequalSucceeded {
  data: PrequalData;
  type: PrequalActionType.SavePrequalSucceeded;
}

export const getPrequal = (id: Guid) => (dispatch: Dispatch<GetPrequalAction>, getState) => API.getPrequal(id, getState().session.details.isDev);
export const savePrequal = (name: string, data: PrequalData, id: Guid, profileType: ProfileType) => (dispatch: Dispatch<SavePrequalAction>, getState) => API.savePrequal(name, JSON.stringify(data), id, profileType, getState().session.details.isDev);
