import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Empty, Icon } from '../../utils';


import LoadingPane from '../../components/loadingPane';

import * as applicationsActions from '../../actions/applicationsActions';
import * as sessionActions from '../../actions/sessionActions';
import * as promptActions from '../../actions/promptActions';
import * as storageActions from '../../actions/storageActions';
import * as referenceActions from '../../actions/referenceActions';

import ApplicationSummary from './applicationSummary';
import ApplicationInfo from './applicationInfo';
import ApplicationActions from './applicationActions';

export class ApplicationPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      init: false,
      tab: 'summary',
      attempt: 1,
    };

    this.getApplication = this.getApplication.bind(this);
    this.setApplication = this.setApplication.bind(this);
    this.setTab = this.setTab.bind(this);
  }

  componentDidMount() {
    this.getApplication();
    this.props.actions.getVeiTypes();
    this.props.actions.getLpiTypes();
    this.props.params.tab && this.setTab(this.props.params.tab);
  }

  componentWillUnmount() {
    this.props.actions.clearPrompts();
    this.setState({
      init: false,
    });
  }

  getApplication(force) {
    this.setState({
      init: false,
    });

    if (!this.props.application.list || !!force) {
      this.props.actions.getApplications()
        .then(this.setApplication);
    } else {
      this.setApplication();
    }
  }

  setApplication() {
    this.props.actions.getApplication(this.props.params.applicationID);

    setTimeout(() => {
      if (!this.props.application.active && this.state.attempt <= 3) {
        setTimeout(() => {
          this.getApplication(true);
        }, 500);

        this.setState({
          attempt: this.state.attempt + 1,
        });
      } else {
        setTimeout(() => {
          this.setPrompts();
          this.setState({
            init: true,
          });
        });
      }
    });
  }

  setPrompts() {
    setTimeout(() => {
      const application = this.props.application.active;

      if (application) {
        const mpiAccredited = this.props.details.products && this.props.details.products.reduce((product) => product.productGroupId == 17000);
        const hasInsurance = application && application.insuranceSummary !== null;
        const rightState = application && application.applicationStatus && application.applicationStatus.code === 210;
        const rightAssetClass = application && application.applicationType == 1;
        const customerNames = application && application.applicants.map((applicant) => applicant.type == 'Person' && applicant.name.split(' ')[0]);
        const commission = `$${Math.round(0.0011854 * application.amount * customerNames.length)}`;

        this.props.actions.getStorage(`lfi-accreditation-${application.applicationNumber}`)
          .then((result) => {
            if (!result.data) {
              this.props.actions.addPrompt({
                id: `lfi-accreditation-${application.applicationNumber}`,
                condition: (!mpiAccredited && !hasInsurance && rightState && rightAssetClass),
                title: 'Mortgage Protection Insurance (MPI)',
                text: `Hi {firstName}, adding MPI cover for ${customerNames.join(' and ')} could earn you up to ${commission} extra commission per year!`,
                icon: 'shield-security',
                initialTimeout: 1000,
                track: 'get accredited LFI',
                primaryButtonText: 'Tell me more',
                primaryCallback: () => { this.props.actions.requestAccreditation(); },
                secondaryButtonText: 'Dismiss',
                result: true,
                resultTitle: 'Great',
                resultText: "We'll be in touch soon to walk you through the options to get accredited and start providing your customers with MPI.",
                resultIcon: 'check',
                resultTimeout: 4000,
              });

              this.props.actions.addPrompt({
                id: `lfi-accreditation-${application.applicationNumber}`,
                condition: (mpiAccredited && !hasInsurance && rightState && rightAssetClass),
                title: 'Mortgage Protection Insurance (MPI)',
                text: `Hi {firstName}, we see that MPI cover isn't included. Let us know if you need help positioning MPI for ${customerNames.join(' and ')}.`,
                icon: 'shield-security',
                initialTimeout: 1000,
                bdm: 'lfi',
                track: 'get LFI',
                primaryButtonText: 'Yes, please',
                primaryCallback: () => { this.props.actions.requestInsurance(application.applicationNumber); },
                secondaryButtonText: 'Dismiss',
                result: true,
                resultTitle: 'Great',
                resultText: "We'll be in touch soon to walk you through our MPI options.",
                resultIcon: 'check',
                resultTimeout: 4000,
              });
            }
          });
      }
    });
  }

  setTab(tab) {
    this.setState({
      tab,
    });
  }

  render() {
    const application = this.props.application.active;

    if (application === null || !this.state.init) {
      return (
        <LoadingPane label="application" />
      );
    }

    if (application === undefined) {
      return (
        <Empty glyph="assignment-o" title="Oops, we can&apos;t find that application...">
          <Icon glyph="search" size={4} style={{ position: 'absolute', marginTop: '-124px', marginLeft: '-2px' }} />

          <p className="text-lg mute mt-lg">
            Are you sure you&apos;ve got the right application number?
            <br />
            <a className="pointer" onClick={this.getApplication}>Try again</a>
            {' '}
            in a few seconds if you have just submitted this application.
          </p>
          <p className="text-lg mute mt-sm">
            If you&apos;d like some help, you can always call us on
            {' '}
            <a href="tel:131133">13 11 33</a>
            !
          </p>

          <a type="button" href="#/applications" className="btn btn-default btn-lg mt-lg"><span className="secondary">Go back</span></a>

        </Empty>
      );
    }

    return (
      <div>
        <ApplicationInfo setTab={this.setTab} tab={this.state.tab} application={application} update processing={0} />

        {
          (() => {
            switch (this.state.tab) {
              case 'actions': {
                return (
                  <ApplicationActions application={application} />
                );
              }

              case 'summary':
              default: {
                return (
                  <ApplicationSummary setTab={this.setTab} getApplication={this.getApplication} application={application} details={this.props.details} reference={this.props.reference} />
                );
              }
            }
          })()
        }

      </div>
    );
  }
}

ApplicationPage.propTypes = {
  application: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    application: state.application,
    details: state.session.details,
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...applicationsActions, ...sessionActions, ...promptActions, ...storageActions, ...referenceActions,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPage);
