import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Input, Select } from 'formsy-react-components';
import Guid from 'guid';
import Moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Mask from '../../helpers/mask';

import Icon from '../../utils/icon';


export default class MotorPrequalToolCreditHistory extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
      creditEvents: [],
    };

    this.addCreditEvent = this.addCreditEvent.bind(this);
    this.removeCreditEvent = this.removeCreditEvent.bind(this);

    this.changeCreditEventType = this.changeCreditEventType.bind(this);
    this.handleDateIncurredChange = this.handleDateIncurredChange.bind(this);
    this.initialiseCreditEventsFromProps = this.initialiseCreditEventsFromProps.bind(this);
    this.mapPropsToState = this.mapPropsToState.bind(this);
    this.mapDate = this.mapDate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    ReactTooltip.rebuild();
    this.initialiseCreditEventsFromProps(prevProps, prevState);
  }

  mapPropsToState = (props) => props.map((x) => ({
    creditEventType: x.creditType,
    eventType: x.eventType,
    status: x.status,
    amount: x.amount,
    dateIncurred: x.dateIncurred,
    dateRectified: x.dateRectified,
  }));

  initialiseCreditEventsFromProps(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data?.applicants) {
      if (this.props.data.applicants[this.props.index]?.creditHistory?.creditEvents !== this.state.creditEvents) {
        this.setState({
          creditEvents: this.mapPropsToState(this.props.data.applicants[this.props.index].creditHistory.creditEvents),
        });
      }
    }
  }

  addCreditEvent() {
    const { creditEvents } = this.state;
    creditEvents.push({
      creditEventType: 'autoLoan',
      creditEventId: Guid.create().value,
      dateIncurred: '',
      dateRectified: '',
    });
    this.setState({
      creditEvents,
    }, () => {
      document.getElementById(`applicants.${this.props.index}.creditHistory.creditEvents.${this.state.creditEvents.length - 1}.creditType`).focus();
    });
  }

  removeCreditEvent(index) {
    const { creditEvents } = this.state;
    creditEvents.splice(index, 1);
    this.setState({
      creditEvents,
    }, () => {
      if (this.state.creditEvents.length > index) {
        document.getElementById(`applicants.${this.props.index}.creditHistory.creditEvents.${this.state.creditEvents.length - 1}.creditType`).focus();
      }
    });
  }

  changeCreditEventType(index, value) {
    const { creditEvents } = this.state;
    creditEvents[index].creditEventType = value;
    this.setState({
      creditEvents,
    });
  }

  changeCreditEventStatus(index, value) {
    const { creditEvents } = this.state;
    creditEvents[index].status = value;
    this.setState({
      creditEvents,
    });
  }

  handleDateIncurredChange(index, date) {
    const { creditEvents } = this.state;
    creditEvents[index].dateIncurred = date;
    this.setState({
      creditEvents,
    });
  }

  handleDateRectifiedChange(index, date) {
    const { creditEvents } = this.state;
    creditEvents[index].dateRectified = date;
    this.setState({
      creditEvents,
    });
  }


  getCreditEventTypes() {
    return [{
      label: 'Auto loan',
      value: 'autoLoan',
    },
    {
      label: 'Mortgage',
      value: 'mortgage',
    },
    {
      label: 'Personal Loans',
      value: 'personalLoans',
    },
    {
      label: 'Credit Cards',
      value: 'creditCards',
    },
    {
      label: 'Trade Account',
      value: 'tradeAccount',
    },
    {
      label: 'ATO',
      value: 'ato',
    },
    {
      label: 'Utility Account',
      value: 'utilityaccount',
    },
    {
      label: 'Bankruptcy',
      value: 'bankruptcy',
    }];
  }

  getCreditEventWorstPastTypes() {
    return [{
      label: 'Arrears',
      value: 'arrears',
    },
    {
      label: 'Default',
      value: 'default',
    },
    {
      label: 'Judgment',
      value: 'judgment',
    },
    {
      label: 'Warrant',
      value: 'warrant',
    },
    {
      label: 'Writ',
      value: 'writ',
    }];
  }


  getBankruptcyTypes() {
    return [{
      label: 'Part IX',
      value: 'Part IX',
    }, {
      label: 'Part X',
      value: 'Part X',
    }, {
      label: 'Sequestration',
      value: 'Sequestration',
    }, {
      label: 'DebtorsPetition',
      value: 'DebtorsPetition',
    }];
  }

  getStatusTypes() {
    return [{
      label: 'Unpaid',
      value: 'unpaid',
    },
    {
      label: 'Paid',
      value: 'paid',
    }];
  }

  mapDate(date) {
    if (!date) return '';

    return Moment(date).format('DD/MM/YYYY');
  }

  /**
     * Render
     */
  render() {
    return (
      <div className="applicants">
        <div className="panel-header-fixed">
          <span className="visible-xs mt-sm" />
          <i className="mi mi-search-in-file mi-2x ib mr-md text-center np" style={{ width: '25px' }} />
          <div className="ib va-tt mt-sm-n">
            <h4 className="mb-h mt-h">Credit History</h4>
          </div>
        </div>

        <div className="panel-section-body" style={{ overflow: 'visible' }}>
          <div className="panel-section-container">
            <Row>
              <Col sm={2}>
                <Input
                  id={`applicants.${this.props.index}.creditHistory.vedaScore`}
                  name={`applicants.${this.props.index}.creditHistory.vedaScore`}
                  ref={`applicants.${this.props.index}.creditHistory.vedaScore`}
                  type="text"
                  label={(
                    <span style={{ paddingLeft: '15px' }} data-tip="Current Credit Score.">
                      <Icon glyph="help-outline" className="mute a mi-help-outline pointer" style={{ left: '10px', top: '5px' }} />
                      {'Credit Score '}
                    </span>
                  )}
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsyNoZero(name, value, this.refs, { decimal: 0, negative: true })}
                  onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                  validationError="Veda Score must be valid"
                  validations={{
                    vedaScoreValidation: true,
                    isRequired: true,
                  }}
                />
              </Col>
              <Col sm={2}>
                <Input
                  id={`applicants.${this.props.index}.creditHistory.numberOfEnquiries`}
                  name={`applicants.${this.props.index}.creditHistory.numberOfEnquiries`}
                  ref={`applicants.${this.props.index}.creditHistory.numberOfEnquiries`}
                  type="number"
                  label={(
                    <span style={{ paddingLeft: '15px' }} data-tip="Total number of Credit Enquiries in last 6 months.">
                      <Icon glyph="help-outline" className="mute a mi-help-outline pointer" style={{ left: '10px', top: '5px' }} />
                      Number of Enquiries (last 6 months)
                    </span>
                  )}
                  updateOnBlur={false}
                  changeDebounceInterval={0}
                  blurCallback={(name, value) => Mask.numberFormsy(name, value, this.refs)}
                  validationError="Number of enquiries must be valid"
                  validations={{
                    numberOfEnquiriesValidation: true,
                    isPositive: true,
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className="card card-shallow">
            <div>
              {
                (() => {
                  if (!this.state.creditEvents.length) {
                    return (
                      <div className="card card-shallow card-hover card-mute text-center np" onClick={this.addCreditEvent}>
                        <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
                        <button type="button" id={`applicants.${this.props.index}-addCreditEvent`} name={`applicants.${this.props.index}-addCreditEvent`} className="btn btn-link pl-h pr-h pt-h np">Add Credit Event</button>
                      </div>
                    );
                  }
                  return (
                    <div className="creditEvents">
                      {
                        this.state.creditEvents.map((creditEvent, index) => (
                          <Row key={`credit-${index}-${creditEvent.creditEventId}`} className="panel-row r">
                            <Col sm={4}>
                              <Row>
                                <Col sm={7}>
                                  <Select
                                    id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.creditType`}
                                    name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.creditType`}
                                    ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.creditType`}
                                    label="Credit Type"
                                    value={creditEvent.creditEventType}
                                    options={this.getCreditEventTypes()}
                                    changeCallback={(name, value) => { this.changeCreditEventType(index, value); }}
                                    className="form-control"
                                  />
                                </Col>
                                {
                                  (() => {
                                    if (creditEvent.creditEventType == 'bankruptcy') {
                                      return (
                                        <Col sm={5}>
                                          <Select
                                            id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                            name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                            ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                            label="Event Type"
                                            value={creditEvent.eventType || this.getBankruptcyTypes()[0].value}
                                            options={this.getBankruptcyTypes()}
                                            className="form-control"
                                          />
                                        </Col>

                                      );
                                    }

                                    return (
                                      <Col sm={5}>
                                        <Select
                                          id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                          name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                          ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.eventType`}
                                          label="Event Type"
                                          value={creditEvent.eventType || this.getCreditEventWorstPastTypes()[0].value}
                                          options={this.getCreditEventWorstPastTypes()}
                                          className="form-control"
                                        />
                                      </Col>

                                    );
                                  })()
                                }
                              </Row>
                            </Col>
                            <Col sm={8}>
                              <Row>
                                <Col sm={11}>
                                  <Row>
                                    {
                                      (() => {
                                        if (creditEvent.creditEventType != 'bankruptcy') {
                                          return (
                                            <Col sm={5}>
                                              <Row>
                                                <Col sm={6}>
                                                  <Select
                                                    id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.status`}
                                                    name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.status`}
                                                    ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.status`}
                                                    label="Status"
                                                    value={creditEvent.status || this.getStatusTypes()[0].value}
                                                    options={this.getStatusTypes()}
                                                    changeCallback={(name, value) => { this.changeCreditEventStatus(index, value); }}
                                                    className="form-control"
                                                  />
                                                </Col>
                                                <Col sm={6}>
                                                  <div className="form-prefix">$</div>
                                                  <Input
                                                    id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.amount`}
                                                    name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.amount`}
                                                    ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.amount`}
                                                    type="text"
                                                    label="Amount"
                                                    value={creditEvent.amount || 0}
                                                    updateOnBlur={false}
                                                    changeDebounceInterval={0}
                                                    blurCallback={(name, value) => { Mask.numberFormsyNoZero(name, value, this.refs); }}
                                                    validationError="Amount is required."
                                                    validations={{
                                                      isFormattedNumber: true,
                                                      isRequired: true,
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                          );
                                        }
                                      })()
                                    }
                                    <Col sm={7}>
                                      <Row>
                                        <Col sm={6}>
                                          <Input
                                            name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateIncurred`}
                                            id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateIncurred`}
                                            ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateIncurred`}
                                            placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                                            updateOnBlur={false}
                                            changeDebounceInterval={0}
                                            blurCallback={(name, value) => Mask.fullDateFormsy(name, value, this.refs)}
                                            label={creditEvent.creditEventType === 'bankruptcy' ? 'List Date' : 'Date Incurred'}
                                            value={this.mapDate(creditEvent.dateIncurred)}
                                            validationError={creditEvent.creditEventType == 'bankruptcy' ? 'List Date must be valid' : 'Date Incurred must be valid'}
                                            validations={{
                                              isValidPastDate: true,
                                            }}
                                          />
                                        </Col>
                                        {
                                          (() => {
                                            if (creditEvent.creditEventType === 'bankruptcy') {
                                              return (
                                                <Col sm={6}>
                                                  <Input
                                                    name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                                                    updateOnBlur={false}
                                                    changeDebounceInterval={0}
                                                    blurCallback={(name, value) => Mask.fullDateFormsy(name, value, this.refs)}
                                                    label="Discharged Date"
                                                    value={this.mapDate(creditEvent.dateRectified)}
                                                    validationError="Discharged Date must be valid"
                                                    validations={{
                                                      isValidPastDate: true,
                                                      isRequired: true,
                                                    }}
                                                  />
                                                </Col>
                                              );
                                            } if (creditEvent.status === 'paid') {
                                              return (
                                                <Col sm={6}>
                                                  <Input
                                                    name={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    id={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    ref={`applicants.${this.props.index}.creditHistory.creditEvents.${index}.dateRectified`}
                                                    placeholder={`eg: ${Moment().format('DD/MM/YYYY')}`}
                                                    updateOnBlur={false}
                                                    changeDebounceInterval={0}
                                                    blurCallback={(name, value) => Mask.fullDateFormsy(name, value, this.refs)}
                                                    label="Date Rectified"
                                                    value={this.mapDate(creditEvent.dateRectified)}
                                                    validationError="Date Rectified must be valid"
                                                    validations={{
                                                      isValidPastDate: true,
                                                    }}
                                                  />
                                                </Col>
                                              );
                                            }
                                          })()
                                        }
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm={1}>
                                  {
                                    (() => {
                                      if (this.state.creditEvents.length >= 1) {
                                        return (
                                          <div>
                                            <button type="button" id={`removeCreditEvent_${index}`} className="btn btn-default btn-danger pull-right mr-sm hidden-xs btn-circle" style={{ marginTop: '31px' }} onClick={() => { this.removeCreditEvent(index); }}>
                                              <Icon glyph="delete" />
                                            </button>
                                            <Col sm={12} className="visible-xs text-right">
                                              <button type="button" className="mb-md btn btn-danger" onClick={() => { this.removeCreditEvent(index); }}>
                                                Remove Credit Event
                                              </button>
                                            </Col>
                                          </div>
                                        );
                                      }
                                    })()
                                  }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))
                      }
                      {
                        (() => {
                          if (this.state.creditEvents.length <= 24) {
                            return (
                              <Row className="panel-row">
                                <Col sm={12} className="text-center">
                                  <Icon glyph="plus" size={2} className="mute-lg mr-sm va-m" />
                                  <button type="button" id={`applicants.${this.props.index}-addCreditEvent`} name={`applicants.${this.props.index}-addCreditEvent`} className="btn btn-link pl-h pr-h np" onClick={this.addCreditEvent}>Add Credit Event</button>
                                </Col>
                              </Row>
                            );
                          }
                        })()
                      }
                    </div>
                  );
                })()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MotorPrequalToolCreditHistory.propTypes = {
};
