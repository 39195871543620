import * as React from 'react';
import * as referenceActions from '../../actions/referenceActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ApplicationUpdateHeader from './applicationUpdateHeader';
import Formsy from 'formsy-react';
import { Col, Row } from 'react-bootstrap';
import { Input } from 'formsy-react-components';

type ApplicationUpdateApplicantProps = {
  application: any,
  params: any,
  formsy: Formsy | null,
}

type ApplicationUpdateApplicantState = {
  visible: boolean
}

export class ApplicationUpdateApplicants extends React.Component<ApplicationUpdateApplicantProps, ApplicationUpdateApplicantState> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleSection = this.toggleSection.bind(this);
    this.getField = this.getField.bind(this);
  }

  componentDidMount() {
    if (this.props.params.tab == 'applicants') {
      this.setState({
        visible: true,
      });
    }
  }

  toggleSection() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  getField = (field, index) => `personApplicants.${index}.${field}`;

  render() {
    return (
      <div className={`panel-section ${this.state.visible ? '' : 'collapsed'}`}>
        <ApplicationUpdateHeader
          title="Applicant Details"
          description="Update the contact details"
          glyph="account-box-o"
          section="applicants"
          toggleSection={this.toggleSection}
        />

        <div className="panel-section-body">
          <div className="panel-section-container">
            {
              this.props.application.personApplicants.map((applicant, index) => (
                <div key={index}>
                  <h5 className="mt-md">{applicant.name}</h5>
                  
                  <Input
                    name={this.getField("applicantId", index)}
                    value={applicant.applicantId}
                    type="hidden"
                  />

                  <Row>
                    <Col sm={6}>
                      <div className="form-prefix">
                        <span className="far fa-mobile-alt"></span>
                      </div>

                      <Input
                        name={this.getField("mobileNumber", index)}
                        id={this.getField("mobileNumber", index)}
                        placeholder="04XX XXX XXX"
                        label="Mobile Number"
                        value={applicant.mobileNumber}
                        validationErrors={{
                          isMobile: "Mobile number is not valid",
                          isRequired: "Mobile number is required",
                          validateSameMobile: "The mobile number can't be the same for the two applicants"
                        }}
                        validations={{
                          isMobile: true,
                          isRequired: true,
                          validateSameMobile: validateSameMobile
                        }}
                      />
                    </Col>
                    <Col sm={6}>
                      <div className="form-prefix">
                        <span className="far fa-envelope"></span>
                      </div>

                      <Input
                        name={this.getField("email", index)}
                        id={this.getField("email", index)}
                        placeholder="me@example.com"
                        label="Email Address"
                        value={applicant.email}
                        validationErrors={{
                          isRequired: "Email address is required",
                          validateSameEmail: "The email address can't be the same for the two applicants"
                        }}
                        validations={{
                          isRequired: true,
                          validateSameEmail: validateSameEmail
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reference: state.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

const validateDuplicateValues = (fieldName, values, value) => {
  const items: string[] = [];
  for (var field in values)
    if (field.startsWith('personApplicants.') && field.endsWith(fieldName))
      items.push(values[field].trim().toUpperCase());

  const val = value.trim().toUpperCase();
  return items.filter(x => x == val).length <= 1;
}

export const validateSameMobile = (values, value) => validateDuplicateValues('mobileNumber', values, value);
export const validateSameEmail = (values, value) => validateDuplicateValues('email', values, value);

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateApplicants);