import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import { Link } from 'react-router';
import Icon from '../../utils/icon';

interface IIntroProps {
  shouldShow: boolean;
}
interface IIntroState {
  visible: boolean;
}

export class Intro extends React.Component<IIntroProps, IIntroState> {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.shouldShow
    }

    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({
      visible: false
    })
  }

  renderRequirement(requirement: string, icon: string, color: string): JSX.Element {
    return (
      <div className="mb-xs" key={btoa(requirement)}>
        <Icon glyph={icon} className={color} /> <span className="pl-sm mute">{requirement}</span>
      </div>
    )
  }

  get requirements(): string[] {
      return [
        "A permanent resident or citizen",
        "18 years or older"
      ];
  }

  get availables(): string[] {
      return [
          "Single applicant",
          "PAYG or other income",
          "Property purchase or refinance",
          "Owner occupied or investment",
          "Prime product"
      ];
  }

  get unavailables(): string[] {
      return [
        "Multiple applicants",
        "Companies or trusts",
        "Self employed income"
      ]
  }

  render(): JSX.Element {
    return (
      <Modal
        show={this.state.visible}
        onHide={this.hideModal}
        className="feature">

        <Modal.Header>
            <Icon glyph="assignment" className="mt-lg mb-md mute-lg" size={3} />
            <h4 className="mt-md mb-lg">Submit application</h4>

            <button type="button" className="close" aria-label="Close" onClick={this.hideModal}>
                <span aria-hidden="true">×</span>
            </button>
        </Modal.Header>

        <Modal.Body>

          <div className="pl-sm pr-sm mt-sm">
            <p className="text-lg mt-md mb-sm">Before we continue, please confirm the applicant is;</p>
            {
                this.requirements.map(requirement => this.renderRequirement(requirement, "info-outline", "secondary"))
            }

            <p className="text-lg mt-md pt-lg mb-sm">We're making submitting applications to us as fast and fuss-free as possible, but we're not quite there yet!</p>
            <p className="mute">At this stage, you can submit the following prime applications via Liberty IQ.</p>
            {
                this.availables.map(requirement => this.renderRequirement(requirement, "check", "success"))
            }

            <p className="mute mt-lg">You’ll have to use LoanNET to submit applications for the following.</p>
            {
                this.unavailables.map(requirement => this.renderRequirement(requirement, "close", "danger"))
            }
          </div>

          <div className="text-right mt-md">
            <Link className="btn btn-default btn-simple btn-lg mr-sm" to="/applications">Cancel</Link>
            <button onClick={this.hideModal} className="btn btn-lg btn-success" autoFocus>Confirm</button>
        </div>

        </Modal.Body>
      </Modal>
    );
  }
}

export default Intro;
