import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select, Checkbox} from 'formsy-react-components';

import Remove from '../remove';
import Title from '../title';
import Ownership from '../ownership';
import Estate from '../estate';

import Mask from '../../../helpers/mask';
import {toOption, toRequired, selectText} from '../functions';

import {IOption} from '../../../interfaces';
import {IGuid, IRentalIncome} from '../interfaces';

interface IPropertyProps {
  data: any;
  index: number;
  updateAsset: (id:IGuid, value:string) => void;
  investment?: IRentalIncome;
  removeAsset: (id: IGuid) => void;
  id: IGuid;
}

function isInvestment({investment}: IPropertyProps): boolean {
  return !!investment;
}

function shouldBeRemovable(props: IPropertyProps): boolean {
  return !isInvestment(props) && !!props.removeAsset;
}

export const Property = (props: IPropertyProps): JSX.Element => {
  return (
    <div className={`card mt-lg p-lg`}>
      <Title top>
        Property
        <Remove onClick={props.removeAsset.bind(this, props.id)} />
      </Title>

      <Estate name={`specificAssets[${props.index}]`} id={props.id} updateValue={props.updateAsset} context="asset" investment={props.investment} />

      <Ownership applicants={props.data.applicants} name={`specificAssets[${props.index}]`} />
    </div>
  );
}

export default Property;
