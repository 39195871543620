import * as React from 'react';

import {Form, Input, Select} from 'formsy-react-components';
import {Row, Col} from 'react-bootstrap';

import PhoneInput from '../phoneInput';
import Title from '../title';

import Personal from './personal';
import Identification from './identification';

import Mask from '../../../helpers/mask';
import {toRequired, toOption} from '../functions';

import {IOption} from '../../../interfaces';

interface IContactState {
  contactDetails: any
}

interface IContactProps {
  index: number
}

export class Contact extends React.Component<IContactProps, IContactState> {
  constructor(props) {
    super(props);

    this.state = {
      contactDetails: {
        mobile: '',
        email: '',
        homePhone: '',
        workPhone: ''
      }
    }

    this.validateContactDetails = this.validateContactDetails.bind(this);
    this.updateContactDetails = this.updateContactDetails.bind(this);
  }

  validateContactDetails(_, value = "") {
    return this.contactDetails.length < 2 ? value.length : true
  }


  updateContactDetails(event) {
    let contactDetails = this.state.contactDetails;
    contactDetails[event.target.name.split('.')[1]] = event.target.value;

    this.setState(contactDetails);
  }


  get contactDetails() {
    let fields = [
      "mobile",
      "email",
      "homePhone",
      "workPhone"
    ];

    let count: string[] = [];
    let individual: number = this.props.index;

    fields.forEach(field => {
      if (this.state.contactDetails[field]) {
        count.push(field);
      }
    });

    return count;
  }

  render() {
    return (
      <div className="panel-section">
        <Title sub="We'll need at least 2 ways to get in touch">Contact</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row>
            <Col sm={4}>
              <Input
                name={`individuals[${this.props.index}].email`}
                ref={`individuals[${this.props.index}].email`}
                id={`individuals[${this.props.index}].email`}
                label={this.contactDetails.length >= 2 && !~this.contactDetails.indexOf('email') ? "Email" : toRequired("Email")}
                validations={{
                  isEmail: true,
                  isContactDetailsEntered: this.validateContactDetails
                }}
                onKeyUp={this.updateContactDetails}
                validationErrors={{
                  isEmail: "Email must be valid",
                  isContactDetailsEntered: "Must enter 2 contact details"
                }}
                value=""
              />
            </Col>

            <Col sm={2}>
              <PhoneInput
                name={`individuals[${this.props.index}].mobile`}
                label={this.contactDetails.length >= 2 && !~this.contactDetails.indexOf('mobile') ? "Mobile" : toRequired("Mobile")}
                type="mobile"
                validations={{
                  isContactDetailsEntered: this.validateContactDetails,
                }}
                onKeyUp={this.updateContactDetails}
                validationErrors={{
                  isContactDetailsEntered: "Must enter 2 contact details"
                }}
              />
            </Col>

            <Col sm={2}>
              <PhoneInput
                name={`individuals[${this.props.index}].homePhone`}
                label={this.contactDetails.length >= 2 && !~this.contactDetails.indexOf('homePhone') ? "Home phone" : toRequired("Home phone")}
                type="landLine"
                validations={{
                  isPhone: true,
                  isContactDetailsEntered: this.validateContactDetails,
                  isUnlikelyPhone: true
                }}
                onKeyUp={this.updateContactDetails}
                validationErrors={{
                  isContactDetailsEntered: "Must enter 2 contact details"
                }} />
            </Col>

            <Col sm={2}>
              <PhoneInput
                name={`individuals[${this.props.index}].workPhone`}
                label={this.contactDetails.length >= 2 && !~this.contactDetails.indexOf('workPhone') ? "Work phone" : toRequired("Work phone")}
                type="landLine"
                validations={{
                  isContactDetailsEntered: this.validateContactDetails,
                }}
                onKeyUp={this.updateContactDetails}
                validationErrors={{
                  isContactDetailsEntered: "Must enter 2 contact details"
                }} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Contact;
