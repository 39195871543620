import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input, Select} from 'formsy-react-components';
import * as Moment from 'moment';

import Remove from '../remove';
import Title from '../title';
import MaskedInput from '../maskedInput';
import CurrencyInput from '../currencyInput';
import SelectableInput from '../selectableInput';


import Mask from '../../../helpers/mask';
import {toOption, toRequired} from '../functions';

import {vehicleMakeOptions} from '../options';

import {IOption} from '../../../interfaces';
import {IGuid} from '../interfaces';

interface IVehicleProps {
  index: number;
  updateAsset: (id:IGuid, value:string) => void;
  removeAsset: (id: IGuid) => void;
  id: IGuid;
}
interface IVehicleState {}

export class Vehicle extends React.Component<IVehicleProps, IVehicleState> {

  render(): JSX.Element {
    return (
      <div className={`card mt-lg p-lg`}>
        <Input
          name={`specificAssets[${this.props.index}].type`}
          type="hidden"
          value="vehicle"
        />

        <Input
          name={`specificAssets[${this.props.index}].id`}
          type="hidden"
          value={this.props.id}
        />

        <Title top>
          Vehicle
          <Remove onClick={() => this.props.removeAsset(this.props.id)} />
        </Title>

        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <SelectableInput
                  data-test-name="make"
                  name={`specificAssets[${this.props.index}].make`}
                  label={toRequired("Make")}
                  options={vehicleMakeOptions}
                  validations={{
                    isRequired: true
                  }}
                  validationError="Make is required" />
              </Col>

              <Col sm={6}>
               <Input
                  name={`specificAssets[${this.props.index}].model`}
                  id={`specificAssets[${this.props.index}].model`}
                  label={toRequired("Model")}
                  validations={{
                    isRequired: true
                  }}
                  value=""
                  validationError="Model is required" />
              </Col>
            </Row>

          </Col>

          <Col sm={3}>
            <Row>
              <Col sm={5}>
                <MaskedInput
                  name={`specificAssets[${this.props.index}].year`}
                  label={toRequired("Year")}
                  placeholder="yyyy"
                  validations={{
                    isRequired: true,
                    idsValidYear: (_, value) => {
                      return value > 1800 && value <= Number(Moment().format('YYYY')) + 1
                    }
                  }}
                  validationErrors={{
                    isRequired: "Year is required",
                    isValidYear: `Year must be before ${Number(Moment().format('YYYY')) + 1}`
                  }} />
              </Col>

              <Col sm={7}>
                <Input
                  name={`specificAssets[${this.props.index}].registration`}
                  id={`specificAssets[${this.props.index}].registration`}
                  label="Registration"
                  value=""
                />
              </Col>
            </Row>
          </Col>

          <Col sm={3}>
            <CurrencyInput
              name={`specificAssets[${this.props.index}].value`}
              value="0"
              label={toRequired("Value")}
              align="right"
              validations={{
                isRequired: true,
                isNotZero: true,
              }}
              validationErrors={{
                isRequired: "Value is required",
                isNotZero: "Value can not be 0"
              }}
              blurCallback={(_, value) => {
                this.props.updateAsset(this.props.id, value)
              }} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Vehicle;
