import { MotorApplicationActionTypes, MotorApplicationAction, Vehicle } from "./actions"

export interface MotorApplicationState {
    vehicle: Vehicle
}

export const initialState:MotorApplicationState = {
    vehicle :{
        make: "",
        model:"",
        year: 0,
        category:"",
        nvic:""
    }
}

export const motorApplicationReducer = (state: MotorApplicationState = initialState, action: MotorApplicationAction): MotorApplicationState => {
    switch (action.type) {
        case MotorApplicationActionTypes.GetVehicle:
            return {
                vehicle: action.vehicle
            }
        default:
            return state
    }
}