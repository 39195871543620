import * as React from 'react';
import { Icon } from '../../../utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox, Form } from 'formsy-react-components';

export enum IDVerificationStatus {
  NotStarted = "NotStarted",
  Initiated = "Initiated",
  InProgress = "InProgress",
  CompletedPass = "CompletedPass",
  CompletedReferToManagement = "CompletedReferToManagement",
  CompletedReferToSettlement = "CompletedReferToSettlement",
  CompletedActionRequired = "CompletedActionRequired",
  Expired = "Expired",
  Cancelled = "Cancelled",
  FailedToSend = "FailedToSend"
}

export interface Applicant {
  applicantId: Number
  name:string
  mobileNumber : String
  idVerificationStatus: IDVerificationStatus
  canSend: Boolean
}

export interface ApplicationIDVerificationProps {
  applicationNumber : Number
  applicants: Applicant[]
  broker : Broker
  actions :{
    sendApplicantIDVerificationLink : Function
    getApplicantIDVerificationStatus : Function
  }
  applicationType: Number
  applicationStatusCode : Number
  isIDVerification : Boolean
}

export interface Broker {
  name:String
  email: String
  smsBrandName : String
  mobileNumber : String
  sendSMS : Boolean
}

const canInitiateIDVerificationProcess = (a: Applicant): Boolean => a.canSend === true
  && (a.idVerificationStatus === IDVerificationStatus.NotStarted || a.idVerificationStatus === IDVerificationStatus.FailedToSend || a.idVerificationStatus === IDVerificationStatus.Expired || a.idVerificationStatus === IDVerificationStatus.Cancelled)

  const canAnyApplicantInitiateIDVerificationProcess = (applicants: Applicant[]): Boolean => applicants.filter(canInitiateIDVerificationProcess).length > 0

const mapStatusToIcon = (status: IDVerificationStatus) => {
  switch (status) {
    case IDVerificationStatus.NotStarted:
    case IDVerificationStatus.Expired:
    case IDVerificationStatus.Cancelled:
    case IDVerificationStatus.FailedToSend:
      return (<div></div>);
    case IDVerificationStatus.Initiated:
    case IDVerificationStatus.InProgress:
      return (<div className="circle success bc-success"><i className="mi mi-more-horiz "></i></div>);
    case IDVerificationStatus.CompletedPass:
      return (<div className="circle success bc-success"><i className="mi mi-check "></i></div>);
    case IDVerificationStatus.CompletedReferToManagement:
    case IDVerificationStatus.CompletedReferToSettlement:
    case IDVerificationStatus.CompletedActionRequired:
      return (<div className="circle warning bc-warning"><i className="mi mi-notifications "></i></div>);
  }
}

const mapStatusToDisplayMessage = (status: IDVerificationStatus) => {
  switch (status) {
    case IDVerificationStatus.NotStarted:
    case IDVerificationStatus.FailedToSend:
      return ("Not verified");
    case IDVerificationStatus.Initiated:
    case IDVerificationStatus.InProgress:
      return ("In progress");
    case IDVerificationStatus.CompletedPass:
      return ("Verification completed and passed");
    case IDVerificationStatus.CompletedReferToManagement:
      return ("Verification completed - refer to Management");
    case IDVerificationStatus.CompletedReferToSettlement:
      return ("Verification completed - refer to Settlements");
    case IDVerificationStatus.CompletedActionRequired:
      return ("Verification completed - provide current Medicare or Passport");
    case IDVerificationStatus.Expired:
      return ("Expired - resend link");
    case IDVerificationStatus.Cancelled:
      return ("Cancelled - resend link");
  }
}

export const ApplicationIDVerification = (props: ApplicationIDVerificationProps) =>
  <div>
    {
      <div className="widget idverification voi-widget">
        <Form layout="vertical" onValidSubmit={(data)=>{props.actions.sendApplicantIDVerificationLink(props.applicationNumber, props.applicants.filter((a, i) => data.applicants[i] === true), props.broker, props.applicationType);}}>
          <div className="voi-widget-content pt-h">
            {
              props.applicants.map(
                (applicant, index) => {
                  if (canInitiateIDVerificationProcess(applicant)) {
                    return(
                      <Checkbox
                        id={`applicants[${index}]`}
                        name={`applicants[${index}]`}
                        rowClassName="mb-h"
                        value={true}
                        valueLabel={<span>{applicant.name}<small className="mute mt-sm-n pt-sm b ml-sm">{mapStatusToDisplayMessage(applicant.idVerificationStatus)}</small></span>}
                      />
                    );
                  }
                  else {
                    return(
                      <div className="form-group mb-h">
                        <div className="custom-control custom-checkbox">
                          <label className="custom-control-label pl-h width-100">
                            {(() => {
                              return(mapStatusToIcon(applicant.idVerificationStatus));
                            })()}
                            <span>{applicant.name}<small className="mute mt-sm-n pt-sm b ml-sm">{mapStatusToDisplayMessage(applicant.idVerificationStatus)}</small></span>
                          </label>
                        </div>
                      </div>
                    );
                  }
                })
            }
          </div>
          {(() => {
            if (canAnyApplicantInitiateIDVerificationProcess(props.applicants)) {
              return (<button type="submit" className={'btn btn-left btn-lg btn-block btn-success F'}><Icon glyph="smartphone" className="btn-icon" />Send Verification Link</button>);
            }
          })()}
        </Form>
      </div>
    }
  </div>

function mapStateToProps({}) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationIDVerification);
