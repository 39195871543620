import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class RepaymentsModal extends React.Component {
  constructor(props) {
    super(props, {});
    this.props = props;
    this.state = {
    };
  }

  /**
   * Render
   */
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.hideModal}
      >

        <Modal.Body>
          <button type="button" className="close" aria-label="Close" onClick={this.props.hideModal}>
            <span aria-hidden="true">×</span>
          </button>

          <h4 className="mb-lg">About the results</h4>
          <p className="mute">The results from this calculator should be used as a guide only. They do not represent either quotes or pre-qualifications for a loan and are not an offer of credit. The repayment amounts shown are based on annualised calculations. Weekly and fortnightly loan repayments will vary slightly where repayments are calculated by dividing the minimum monthly repayment amount by 4 (for weekly) or 2 (for fortnightly). All applications for credit are subject to Liberty’s usual credit assessment criteria. </p>

          <h5 className="mb-md mt-lg">What about fees and charges?</h5>
          <p className="mute">This calculator does not take into account all fees, charges or other amounts that may be charged to a loan. Additional fees and charges may increase repayment amounts. Full terms and conditions will be set out in any loan offer (if an offer is made). Further product information including current interest rates, fees and charges can be found in the relevant Introducer Guides available in the ‘Documents’ tab.</p>

        </Modal.Body>

        <Modal.Footer>
          <button onClick={this.props.hideModal} type="button" className="btn btn-default pull-right">Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RepaymentsModal.propTypes = {
};
