import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Icon } from '../../utils';

const ApplicationInsurance = (props) => (
  <div className="card card-border">
    <Icon className="card-icon" glyph="shield-security" />
    {
      (() => {
        switch (props.application.applicationType) {
          case 1:
          case 5: {
            if (props.application.insuranceSummary && props.application.insuranceSummary.mortgageProtectionInsurances.length) {
              return (
                <div className="insurance">
                  <h4>Insured with MPI</h4>

                  <p className="insurance-details mute">
                    {'Your customer'}
                    {props.application.insuranceSummary.mortgageProtectionInsurances.length > 1 ? 's are' : ' is'}
                    {' protected with MPI.'}
                  </p>
                </div>
              );
            }

            return (
              <div className="insurance">
                <h4>Not insured</h4>

                <p className="insurance-details mute">Protect your customer from illness, injury or job loss.</p>
              </div>
            );
          }
        }
      })()
  }
  </div>
);

ApplicationInsurance.propTypes = {
  application: PropTypes.any,  
  reference: PropTypes.any,  
};

export default ApplicationInsurance;
