import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from '../../utils';


import * as sessionActions from '../../actions/sessionActions';
import * as contactsActions from '../../actions/contactsActions';
import * as promptActions from '../../actions/promptActions';
import * as storageActions from '../../actions/storageActions';

import PaneLogo from './paneLogo';
import PaneDetails from './paneDetails';
import PaneSlide from './paneSlide';
import PaneMenu from './paneMenu';
import Storage from '../../helpers/storage';

const Moment = require('moment');

export class HeaderPane extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      calculators: false,
      menu: false,
      options: false,
      update: false,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);

    this.toggleAll = this.toggleAll.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    this.getStorage();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { session } = nextProps;
    if (session && session.failed > 3) { this.props.actions.doLogout(); }

    if (session && session.timer >= 110 && !session.renewPromise) { this.props.actions.renewToken(); }
  }

  shouldComponentUpdate(nextProps) {
    // This is for non-B2C login types. Remove once we finish migrating.
    if (Storage.getItem('isUseB2C') !== 'true' && nextProps.session.token && nextProps.session.userid !== this.props.session.userid) {
      this.props.actions.getDetails();
      this.props.actions.getContacts();
    }

    // Is for B2C only :)
    if (Storage.getItem('isUseB2C') === 'true' && Storage.getItem('introducerIdHasChanged') === 'true' && (Storage.getItem('session') !== null && Storage.getItem('session') !== undefined)) {
      this.props.session.capabilities = Storage.getItem('capabilities');
      this.getDetails();
      this.getStorage();
      Storage.removeItem('introducerIdHasChanged');
    }

    return true;
  }

  getDetails() {
    if (Storage.getItem('isUseB2C') === 'true' && Storage.getItem('introducerIdHasChanged') !== 'true' && (Storage.getItem('session') === null || Storage.getItem('session') === undefined)) return;

    if (this.props.session.token) {
      this.props.actions.renewToken()
        .then(this.props.actions.getContacts())
        .then(this.props.actions.getDetails())
        .then(() => {
          this.props.actions.getStorage('resubscribe')
            .then((result) => {
              if (!result.data) {
                this.props.actions.addPrompt({
                  id: 'resubscribe',
                  condition: !this.props.session.details.libertyNewsletter,
                  title: "You're missing out on our updates!",
                  text: 'Hi {firstName}, it looks like you are not receiving our emails with our latest products, offerings and events.',
                  icon: 'email-open',
                  initialTimeout: 1000,
                  track: 'resubscribe newsletter',
                  primaryButtonText: 'Subscribe now',
                  primaryCallback: this.props.actions.setSubscription.bind(this, { libertyNewsletter: true }),
                  secondaryButtonText: 'Dismiss',
                  result: true,
                  resultTitle: 'All set!',
                  resultText: 'You will now receive our emails again!',
                  resultIcon: 'check',
                  resultTimeout: 4000,
                });
              }
            });
        });
    }
  }

  getStorage() {
    if (Storage.getItem('isUseB2C') === 'true' && Storage.getItem('introducerIdHasChanged') !== 'true' && (Storage.getItem('session') === null || Storage.getItem('session') === undefined)) return;

    if (this.props.session.token) {
      this.props.actions.renewToken()
        .then(() => {
          this.props.actions.getStorage('sync')
            .then((result) => {
              // Temporary code to sync local storage with remote storage
              if (!result.data) {
                const sync = Moment().format();
                const events = (JSON.parse(Storage.getItem('events')) || {})[this.props.session.userid] || null;
                const prompts = (JSON.parse(Storage.getItem('prompts')) || {})[this.props.session.userid] || null;

                const data = { sync, ...events, ...prompts };

                Object.keys(data).map((key) => {
                  this.props.actions.setStorage(key, data[key]);
                });
              }
            });
        });
    }
  }

  getInitials(details) {
    if (details && details.contact) {
      return details.contact.split(' ').map((name) => name.substr(0, 1)).join('');
    }
    return null;
  }

  getStyles() {
    const styles = {
      marginTop: '-1px',
    };

    if (this.props.session.userid) {
      styles.backgroundImage = `url(/${(window).iqUIVersion}/assets/avatars/${this.props.session.userid}.jpg)`;
    }

    return styles;
  }

  getName(details) {
    if (details && details.contact) {
      return details.contact.split(' ')[0];
    }
    return null;
  }

  getFullName(details) {
    if (details && details.contact) {
      return details.contact;
    }
    return null;
  }

  handleLogout() {
    this.props.actions.doLogout();
  }

  toggleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  toggleOptions() {
    this.setState({
      options: !this.state.options,
    });
  }

  toggleAll() {
    this.setState({
      calculators: false,
      menu: false,
      options: false,
    });
  }

  isShowMenu() {
    // dont show menu if you are in motor serviceability calculator that is supposed to be standalone
    return window.location.hash.indexOf('#/calculators/motor-serviceability-standalone') == -1;
  }

  isShowButtons() {
    // do not show menu buttons if we're on the introducers page.
    return window.location.hash.indexOf('#/introducers') == -1;
  }

  render() {
    if (!this.props.session || !this.props.session.token || !this.props.session.details) {
      return null;
    }

    if (!this.isShowButtons()) {
      return (
        <div className="pane">
          <PaneLogo
            toggleAll={this.toggleAll}
          />
        </div>
      );
    }

    // Default menu banner
    if (this.isShowMenu()) {
      return (
        <div className="pane">
          <PaneLogo
            toggleAll={this.toggleAll}
          />

          <PaneDetails
            toggleOptions={this.toggleOptions}
            initials={this.getInitials(this.props.session.details)}
            styles={this.getStyles()}
            name={this.getName(this.props.session.details)}
          />

          <PaneSlide
            toggleOptions={this.toggleOptions}
            toggleAll={this.toggleAll}
            handleLogout={this.handleLogout}
            visible={this.state.options}
            initials={this.getInitials(this.props.session.details)}
            styles={this.getStyles()}
            name={this.getFullName(this.props.session.details)}
            company={this.props.session.details.companyName}
          />

          <PaneMenu
            toggleAll={this.toggleAll}
            visible={this.state.menu}
            session={this.props.session}
          />

          <button onClick={this.toggleMenu} id="details-menu-toggle" className="btn pane-toggle">
            <Icon glyph="menu" />
          </button>
        </div>
      );
    }

    return (
      <div>&nbsp;</div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
    events: state.events,
    storage: state.storage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      ...sessionActions, ...contactsActions, ...promptActions, ...storageActions,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPane);
