import * as React from 'react';
import PropTypes from 'prop-types';

export const Money = (props) => {
  if (props.children == undefined) {
    return null;
  }

  let amount = props.children;

  if (props.round !== false) {
    amount = parseFloat(amount).toFixed(props.round);
  }
  amount = amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ($1) => `${$1},`);
  amount = `$${amount}`;

  return (
    <span className={props.className}>{amount}</span>
  );
};

Money.propTypes = {
  children: PropTypes.number,
  className: PropTypes.string,
  round: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
};

Money.defaultProps = {
  round: 2,
  className: '',
};

export default Money;
