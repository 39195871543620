
export class Event3rdPartyPlanner {
  constructor() {
    this.identify = (id) => {
      window.heap && window.heap.identify(id);
      window.hj && window.hj('tagRecording', [id]);
      window.fbq && window.fbq('trackCustom', 'IQ', {
        login: 'true',
        referrer: document.referrer,
      });
    };

    this.send = (name, data = {}) => {
      window.heap && window.heap.track(name, data);
      window.hj && window.hj('trigger', name);
      window.hj && window.hj('tagRecording', [name]);
      window.ga && window.ga('send', 'event', 'IQ', name);
    };

    this.pageview = (page) => {
      window.ga && window.ga('set', 'page', page);
    };

    this.accreditation = (accreditation = 'false') => {
      window.fbq && window.fbq('trackCustom', 'IQ', {
        accreditation,
        referrer: document.referrer,
      });
    };
  }
}

export const Event3rdParty = new Event3rdPartyPlanner();
export default Event3rdParty;
