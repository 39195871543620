/**
 * Import dependancies
 */
import * as Axios from 'axios';
import NProgress from 'nprogress';

import { notify as Notify } from 'react-notify-toast';
import { browserHistory } from 'react-router';
import Storage from './storage';

import Event3rdParty from './event3rdParty';

import { msalInstance } from './authConfig';

/**
 * Export module
 */
export class Interceptor {
  static isAbsoluteURLRegex() {
    return /^(?:\w+:)\/\//;
  }

  /**
     * Intercept outgoing request
     * @param  AxiosXHRConfig
     * @return AxiosXHRConfig
     */
  static requestLocationInterceptor(config) {
    /**
         * Start the visual progressbar
         */
    NProgress.start();

    /**
         * Return the config
         */
    return config;
  }

  static isTokenExpired(accessToken) {
    if (!accessToken) {
      // Token is not present or undefined
      return true;
    }
    try {
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const decodedToken = JSON.parse(atob(base64));

      if (decodedToken && decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000);

        // Check if the token expiration time is close to the current time
        const expirationThreshold = 300; // 5 minutes
        const isCloseToExpiration = decodedToken.exp - currentTime < expirationThreshold;
        return isCloseToExpiration;
      }

      return true;
    } catch (e) {
      return true;
    }
  }

  /**
     * Intercept nonce request
     * @param  AxiosXHRConfig
     * @return AxiosXHRConfig
     */
  static async requestNonceGeneratorInterceptor(config) {
    const isB2C = Storage.getItem('isUseB2C');
    // If we're using B2C, try to refresh the token. This is just accessing the cache so it's very quick.
    if (isB2C === 'true' && Interceptor.isTokenExpired(Storage.getItem('token'))) {
      const allAccounts = msalInstance.getAllAccounts();
      const accounts = !allAccounts ? [] : allAccounts;
      const account = accounts.length > 0
        ? accounts[0]
        : null;
      if (account !== null) {
        try {
          const scopes = process.env.NODE_ENV !== 'production'
            ? ['https://libfinintroducerssbx.onmicrosoft.com/liberty-api-gateway/u65hkh/user_impersonation']
            : ['https://libfinintroducers.onmicrosoft.com/liberty-api-gateway/59sqph/user_impersonation'];
          const request = { scopes, account };
          const accessTokenResponse = await msalInstance.acquireTokenSilent(request);
          Storage.setItem('token', accessTokenResponse.accessToken);
        } catch (e) {
          // Force logout if we can't refresh the token
          Interceptor.store.dispatch({
            type: 'DO_LOGOUT',
          });
          return Promise.reject(config);
        }
      }
    }

    /**
     * Get a token from the session storage and dont parse it if it's a b2c access token
     */
    let token;
    try {
      token = JSON.parse(Storage.getItem('token'));
    } catch (e) {
      token = Storage.getItem('token');
    }

    /**
         * Only intercept if we have a particular user
         */
    if (!token) {
      return config;
    }

    if (config.url.includes('localhost')) {
      token = Storage.getItem('local-token');
    }
    /**
         * Set headers
         */
    if (isB2C === 'true') {
      // B2C - Add the extra B2C header items if we're using B2C. This if statement can be removed post-migration.
      config.headers.IsB2C = isB2C;
      config.headers.OriginatorId = Storage.getItem('session'); // session is the originator userId
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers.Expires = '-1';
    config.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';

    config.timeout = config.timeout || 20 * 1000;

    /**
         * Return config
         */
    return config;
  }

  /**
     * Intercept nonce request
     * @param  AxiosXHRConfig
     * @return AxiosXHRConfig
     */
  static responseUnauthorizedTokenInterceptor(xhr) {
    /**
         * Stop the visual progress bar
         */
    NProgress.done();

    /**
         * Handle the reponse status
         * @param  {Number}
         */
    if (xhr.response && (xhr.response.status >= 400 || xhr.response.status === 0)) {
      Event3rdParty.send('server-error', xhr.response);

      if (xhr.response.data.errors) {
        xhr.response.data.errors.map((error) => {
          const message = error.message || error.errorMessage;
          Notify.show(`${message}...`, 'error', 3000);
        });
      }

      if (xhr.response.status === 401) {
        // This should only trigger if for some reason we get to the applications page WITHOUT having selected an introducer
        if (Storage.getItem('isUseB2C') == 'true' && Storage.getItem('session') == null) {
          browserHistory.replace('/#/introducers');
          window.location.reload();
          return xhr;
        }

        if (!xhr.response.data.errors || !xhr.response.data.errors.filter((x) => x.message.search('username or password'))) {
          Interceptor.store.dispatch({
            type: 'DO_LOGOUT',
            xhr,
          });
        }
      }

      return Promise.reject(xhr);
    }

    return xhr;
  }

  /**
     * Register response caching
     *
     * Will try to cache the response based on the URL if the caching
     * is not explicitly set to false.
     *
     * Optionally you can add an expires option to the caching object
     * to change the default expiry of the cached request (in hours).
     *
     * Example of the object:
     *
     *   config = {
     *     cache: true, // force cache
     *     expires: 6 // 6 hours
     *   }
     *
     */
  static responseCacheInterceptor(response) {
    if (response.config) {
      /**
             * Check that the conditions are right for caching
             */

      if (response.config.cache !== false && response.config.method === 'get' && response.status === 200) {
        let cache = Storage.getItem('cache');

        let expires = 24;

        /**
                 * Check if we need use a preferred expiry
                 * @param  {[type]} response.config.cache&&response.config.caches.expires [description]
                 * @return {[type]}                                                       [description]
                 */
        if (response.config.cache && response.config.cache.expires) {
          expires = response.config.cache.expires;
        }

        /**
                 * Try and get the existing cache
                 * @param  {[type]} !cache [description]
                 * @return {[type]}        [description]
                 */
        if (!cache) {
          cache = {};
        } else {
          cache = JSON.parse(cache);
        }

        /**
                 * Store the response if there is any
                 */
        if (response && response.data) {
          cache[response.config.url] = {
            response,
            expires: new Date().getTime() + (expires * 60 * 60 * 1000),
          };
        }

        Storage.setItem('cache', JSON.stringify(cache));
      }
    }

    return response;
  }

  /**
     * Register
     * @return {}
     */
  static register(store) {
    /**
         * Axios requires access to the session store for the
         * credentials so we subscribe the interceptor here...
         */
    Axios.interceptors.request.use(this.requestLocationInterceptor);
    Axios.interceptors.request.use(this.requestNonceGeneratorInterceptor);

    Interceptor.store = store;

    Axios.interceptors.response.use((xhr) => {
      NProgress.done();
      return xhr;
      // return Promise.resolve(xhr);
    }, this.responseUnauthorizedTokenInterceptor);
    Axios.interceptors.response.use(this.responseCacheInterceptor);
  }
}

export default Interceptor;
