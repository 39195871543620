import * as React from 'react';
import PropTypes from 'prop-types';

const Dots = (props) => {
  const classes = new Array();
  let style = {};

  if (props.className) {
    classes.push(props.className);
  }

  if (props.style) {
    style = props.style;
  }

  return (
    <span className={`dots ${classes.join(' ')}`} style={style}>
      <i />
      <i />
      <i />
    </span>
  );
};

Dots.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Dots;
