import * as React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';
import {Input} from 'formsy-react-components';
import Mask from '../../../helpers/mask';

import Title from '../title';
import CurrencyInput from '../currencyInput';

import {toOption, toRequired, selectText} from '../functions';

import {IOption} from '../../../interfaces';

import {assetOptions} from '../options';

interface IOtherProps {
  updateAsset: (name: string, value: string) => void;
}
interface IOtherState {}

export class Other extends React.Component<IOtherProps, IOtherState> {
  render(): JSX.Element {
    return (
      <div className="panel-section">
        <Title>Other assets</Title>

        <div className="panel-section-body panel-section-body-simple">
          <Row style={{marginBottom: '4px'}}>
            <Col sm={6} smOffset={3}>
              <label>Description</label>
            </Col>
            <Col sm={3}>
              <label>{toRequired("Value")}</label>
            </Col>
          </Row>

          {
            assetOptions.map(assets => {
              return (
                <Row key={assets.value}>
                  <Col sm={3}>
                    <span className="mute ib" style={{paddingTop: '12px'}}>{assets.label}</span>
                  </Col>
                  <Col sm={6}>
                    <Input
                      name={`list.${assets.value}.description`}
                      value="" />
                  </Col>

                  <Col sm={3}>
                    <CurrencyInput
                      name={`list.${assets.value}.value`}
                      validations={{
                        isRequired: true
                      }}
                      validationErrors={{
                        isRequired: `${assets.label} value is required`
                      }}
                      blurCallback={(_, value) => {
                        this.props.updateAsset(String(assets.value), value)
                      }}
                      align="right"
                    />
                  </Col>
                </Row>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default Other;
