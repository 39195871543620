import { Input } from 'formsy-react-components';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import * as ReactTooltip from 'react-tooltip';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as referenceActions from '../../actions/referenceActions';
import { PRODUCTGROUPS, MOTOR_VIN_MIN_LENGTH } from '../../constants/types';
import ReferenceApi from '../../api/referenceApi';
import Mask from '../../helpers/mask';
import GlassGuideApi, {VehicleSearchStatus} from '../../api/glassGuideApi';
const Moment = require('moment');

type VinSearchProps ={  
  application: any,   
  actions: any,
  reference: any,
  session: any,  
  formsy: Formsy | null,
  getValue: (input: string) => any,
  setMessage: (input: string | null) => any,
}

type VinSearchState ={
  loading: boolean,
  vin: string,
}

export class VinSearch extends React.Component<VinSearchProps, VinSearchState> { 
  constructor(props: VinSearchProps) {
    super(props);

    this.state = {  
      loading: false,
      vin: '',
    }
    this.getNvicsByVin = this.getNvicsByVin.bind(this);
    this.setNvicAndAppraisal = this.setNvicAndAppraisal.bind(this);
    this.changeVin = this.changeVin.bind(this);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  getNvicsByVin() {
    const vin = this.state.vin;   
    if (vin.length === MOTOR_VIN_MIN_LENGTH) {
      this.changeLoading(true);
     
      GlassGuideApi.searchByVin(vin)
      .then((response) => {
        if(response.data.status === VehicleSearchStatus.ResultFound){
          this.props.actions.getNvic(response.data);
          this.setNvicAndAppraisal(response.data.result);
          this.props.setMessage('Vehicle updated!');
        } else {
          this.props.actions.clearNvic();
          this.props.actions.clearAppraisal();
          this.setFormsyValue('nvicInput', '');      
          this.setFormsyValue('motorPurchaseVehicle.nvic', '');
          this.props.setMessage(response.data.resultMessage);
        }
        this.changeLoading(false);
      });
    }
  }
  setNvicAndAppraisal(vehicle) {
    this.setAppraisalOnSearch(vehicle.nvic);
    this.setNvic(vehicle);
  }
  setAppraisalOnSearch(nvic) {
    const isNew = !this.props.getValue('motorPurchaseVehicle.isUsed') || 0;
    const odometer = this.props.getValue('motorPurchaseVehicle.odometer') || 0;
    this.props.actions.getAppraisal(nvic, odometer, isNew, this.props.application?.productGroupId);
  }
  getAppraisal() {
    if (this.props.reference.nvic && this.props.reference.appraisal) {
      const amount = this.props.application?.motorPurchaseVehicle?.isUsed ? this.props.reference.appraisal.tradeHighAmount : this.props.reference.appraisal.retailAmount;
      return Mask.number(amount);
    }
    return null;
  }
  setNvic(vehicle) {
    if (vehicle) {
      this.setFormsyValue('motorPurchaseVehicle.vin', vehicle.vin);
      this.setFormsyValue('motorPurchaseVehicle.nvic', vehicle.nvic);
      this.setFormsyValue('motorPurchaseVehicle.vehicleColour', vehicle.colour);
      this.setFormsyValue('motorPurchaseVehicle.engineNumber', vehicle.engineNumber);
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationState', vehicle.registrationState);
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationNumber', vehicle.registrationPlate);
      const date = new Date(vehicle.registrationExpiryDate); 
      this.setFormsyValue('motorPurchaseVehicle.vehicleRegistrationExpiry',(Moment(date)).format('MM/YYYY'));

      const nvic = this.props.application?.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN ? `CV-${vehicle.nvic}` : vehicle.nvic;
      ReferenceApi.getNvic(nvic)
        .then((response) => response.data[0])
        .then((nvicDetails) => {
          this.setFormsyValue('motorPurchaseVehicle.year', nvicDetails.year);
          this.setFormsyValue('motorPurchaseVehicle.makeId', nvicDetails.makeId);
          this.setFormsyValue('motorPurchaseVehicle.modelId', nvicDetails.modelId);
          this.setFormsyValue('motorPurchaseVehicle.bodyTypeId', nvicDetails.bodyTypeId);
          this.setFormsyValue('motorPurchaseVehicle.transmissionTypeId', nvicDetails.transmissionTypeId);
          this.setFormsyValue('motorPurchaseVehicle.make', nvicDetails.make);
          this.setFormsyValue('motorPurchaseVehicle.model', nvicDetails.model);
        });
    }
  }  
  setFormsyValue(field: string, value: any) {
    if (this.props.formsy) {
      this.props.formsy.inputs.filter((input) => input.props.id == field)[0].setValue(value);
    }
  }
  
  changeLoading(loading: boolean){
    this.setState({loading});
  }
  changeVin(e) {
    this.setState({vin: e.target.value});
    this.setFormsyValue('nvicInput', ''); 
    this.setFormsyValue('registrationNumberInput', ''); 
    this.setFormsyValue('registrationStateInput', ''); 
    this.props.setMessage(null);
  }

  public render() {
    return (
      <div>
        <Col sm={6} md={3}>
          <Input
            name="vinInput"
            id="vinInput"
            type="text"
            label="Vehicle Identification Number (VIN)"
            placeholder="eg: 1ABCD23DEFG456789."
            autoComplete="off"
            validationError="Must be 17 characters in length."
            validations={{                 
              isValidVin: true
            }}
            data-tip
            data-for="vin_tooltip"
            onKeyUp={this.changeVin}
            onFocus={this.changeVin}
          />
          <ReactTooltip id="vin_tooltip" efect="solid" place="bottom">
          Must be {MOTOR_VIN_MIN_LENGTH} characters in length. Cannot include I, O or Q. Must contain at least 1 alpha and 1 numeric character
          </ReactTooltip>
        </Col>
        <Col sm={2} style={{ marginTop: 30 }}>
          <a id="getNvicsByVin" onClick={this.getNvicsByVin} className="btn btn-success btn-sm"> 
            Search &nbsp;
            {
              this.state.loading && (<i className="fas fa-spinner fa-spin fa-pulse fa-1x p-01"></i>)
            }
          </a>
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {  
  return {
    reference: state.reference,
    session: state.session,   
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VinSearch);