import * as React from 'react';
import PropTypes from 'prop-types';
import { Loadr, Widget } from '../../utils';

import API from '../../api';

import ContactsWidgetItem from './contactsWidgetItem';

export default class ContactsWidget extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      contacts: null,
    };

    this.getContactsDetails = this.getContactsDetails.bind(this);
    this.setContactsDetails = this.setContactsDetails.bind(this);
  }

  componentDidMount() {
    this.getContactsDetails(this.props.contacts);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const stateChange = JSON.stringify(this.state) !== JSON.stringify(nextState);
    const propsChange = JSON.stringify(this.props) !== JSON.stringify(nextProps);

    if (stateChange || propsChange) {
      this.getContactsDetails(nextProps.contacts);
      return true;
    }

    return false;
  }

  getContactsDetails(contacts) {
    if (contacts) {
      if (!contacts.length) {
        this.setState({
          contacts,
        });
      } else {
        const mappedContacts = new Array();
        contacts.map((contact) => {
          const id = contact.staffId || contact.userId;
          mappedContacts.push(id);
        });

        API.getContactsDetails(mappedContacts)
          .then((response) => {
            this.setContactsDetails(response.data);
          });
      }
    }
  }

  setContactsDetails(contacts) {
    const mappedContacts = this.mapContactsDetails(contacts);
    this.setState({
      contacts: mappedContacts,
    });

    this.forceUpdate();
  }

  mapContactsDetails(contacts) {
    const mappedContactsDetails = new Array();

    contacts.map((contact) => {
      contact.lFStaffPhones = [contact.lFStaffMobile, contact.lFStaffPhoneNumber].filter((phone) => phone);
      mappedContactsDetails.push(contact);
    });

    return mappedContactsDetails;
  }

  getSubject(application) {
    let subject = `Application ${application.applicationNumber}`;

    const personApplicants = application.applicants.filter((applicant) => applicant.type == 'Person');

    if (personApplicants.length > 0) {
      subject += ` - ${personApplicants[0].name}`;
    }

    return subject;
  }

  render() {
    return (
      <Widget title={this.props.title || 'BDMs'}>
        {
          (() => {
            if (this.state.contacts === null) {
              return (
                <Loadr />
              );
            }

            if (!this.state.contacts.length) {
              return (
                <div className="bdm-item bdm-none p-md">
                  <p className="text-center">
                    {'No '}
                    {this.props.title.toLowerCase()}
                    {' assigned...'}
                  </p>
                </div>
              );
            }

            const subject = this.props.application ? this.getSubject(this.props.application) : 'Liberty IQ';

            return (
              <div className="bdm">
                {
                  this.state.contacts.map((contact, idx) => (
                      <ContactsWidgetItem key={`contact-${idx}`} contact={contact} subject={subject} />
                  ))
                }
              </div>
            );
          })()
        }
      </Widget>
    );
  }
}

ContactsWidget.propTypes = {
  contacts: PropTypes.any,
  title: PropTypes.string,
};
