import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { InfoAlert } from "../../../utils/alert";
import { Money } from "../../../utils/money";

import { pluralise } from "../../../constants/functions";

import { setPreferredSurplus } from "./actions";

const floorToNearest = (value: number, round: number): number =>
  Math.floor(value / round) * round;

export const getPrefixText = (preferredSurplus: number) =>
  preferredSurplus === 0 && ` up to `;

export const getPreferredMaxBorrowingCapacity = (
  result: ComResidentialServiceAbilityResult,
  preferredSurplus: number
) => {
  var maxBorrowingCapacityAtPreferredSurplus = result.maxBorrowingCapacityAtSurplus.find(
    x => x.surplus === preferredSurplus
  );

  return floorToNearest(
    (maxBorrowingCapacityAtPreferredSurplus &&
      maxBorrowingCapacityAtPreferredSurplus.capacity) ||
      result.maxBorrowingCapacity,
    100
  );
};

const shouldShowSelect = options => options.length > 1;

export const SurplusSelect = ({ changeCallback, options }): JSX.Element => (
  <div className="form-group ib mb-h">
    <select
      onChange={changeCallback}
      defaultValue={options[0]}
      className="form-control form-control-inline ml-sm mr-sm"
    >
      {options.map(surplusOption => (
        <option value={surplusOption} key={surplusOption}>
          ${surplusOption}
        </option>
      ))}
    </select>
  </div>
);

export const SurplusValue = ({ value }): JSX.Element => (
  <span>
    {" "}
    <Money round={false}>{value}</Money>{" "}
  </span>
);

interface LoanRepaymentSplit {
  interestRateRequested: number;
  interestRateLoading: number;
  repaymentAtRequestedRate: number;
  repaymentWithLoading: number;
  loanTerm: number;
}

interface LoanRepayment {
  totalLoanRepaymentAtRequestedRate: number;
  totalLoanRepaymentWithLoading: number;
  repaymentSplitResults: Array<LoanRepaymentSplit>;
}

export interface ComResidentialServiceAbilityResult {
  netIncome: number;
  livingExpenses: number;
  financialCommitments: number;
  totalLivingCosts: number;
  totalRepaymentAtRequestedRate: number;
  totalRepaymentWithLoading: number;
  loanRepayments: Array<LoanRepayment>;
  surplusAtRequestRate: number;
  surplusWithLoading: number;
  maxBorrowingCapacity: number;
  maxBorrowingCapacityAtSurplus: Array<MaxBorrowingCapacityAtSurplus>;
  debtServicingCoverageRatio: number;
}

interface MaxBorrowingCapacityAtSurplus {
  capacity: number;
  surplus: number;
}

interface ComServiceAbilitySuggestionProps {
  result: ComResidentialServiceAbilityResult;
  numberOfApplicants: number;
  preferredSurplus: number;
  options: Array<number>;
  actions: {
    setPreferredSurplus: typeof setPreferredSurplus;
  };
}

export class ComServiceAbilitySuggestion extends React.Component<
ComServiceAbilitySuggestionProps,
  {}
> {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.props.actions.setPreferredSurplus(this.props.options[0].toString());
  }

  handlePreferredSurplusChange = (event: React.FormEvent<HTMLSelectElement>) =>
    this.props.actions.setPreferredSurplus(event.currentTarget.value);

  render() {
    return (
      <div className="m-md">
        <InfoAlert>
          <div>
            Your{" "}
            {pluralise(this.props.numberOfApplicants, "customer", "customers")}{" "}
            can service {getPrefixText(this.props.preferredSurplus)}
            <strong className="primary">
              {" "}
              <Money round={false}>
                {getPreferredMaxBorrowingCapacity(
                  this.props.result,
                  this.props.preferredSurplus
                )}
              </Money>{" "}
            </strong>
            leaving a
            {shouldShowSelect(this.props.options) ? (
              <SurplusSelect
                options={this.props.options}
                changeCallback={this.handlePreferredSurplusChange}
              />
            ) : (
              <SurplusValue value={this.props.options[0]} />
            )}
            surplus.
          </div>
        </InfoAlert>
      </div>
    );
  }
}

function mapStateToProps({ comServiceAbilitySuggestion: { preferredSurplus } }) {
  return {
    preferredSurplus
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setPreferredSurplus }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComServiceAbilitySuggestion);
