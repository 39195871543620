import * as React from 'react';
import PropTypes from 'prop-types';
import MaskedInput, {IMaskedInputBaseProps} from './maskedInput';

interface ICurrencyInputProps extends IMaskedInputBaseProps {
  align?: IAlignment;
}
type IAlignment = "left" | "right" | undefined;

export class CurrencyInput extends React.Component<ICurrencyInputProps, any> {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getClassName(align: IAlignment): string {
    let classNames = ["form-control"];

    if (align == "right") {
      classNames.push('text-right')
    };

    return classNames.join(" ");
  }

  render(): JSX.Element {
    return (
      <MaskedInput
        name={this.props.name}
        label={this.props.label}
        validations={this.props.validations}
        blurCallback={this.props.blurCallback}
        changeCallback={this.props.changeCallback}
        disabled={this.props.disabled}
        placeholder="xxx,xxx,xxx,xxx"
        maxLength={12}
        hidePlaceholder={true}
        direction="rtl"
        prefix="$"
        value={this.props.value || "0"}
        className={this.getClassName(this.props.align)}
        validationErrors={this.props.validationErrors}
        updateOnChange={this.props.updateOnChange || false}
        updateOnBlur={this.props.updateOnBlur || false}
      />
    );
  }
}

export default CurrencyInput;
