import * as React from 'react';
import PropTypes from 'prop-types';

const Moment = require('moment');

const DateTime = (props) => {
  let className = 'formatted-date';

  /**
   * Append className
   */
  if (props.className) {
    className += ` ${props.className}`;
  }


  /**
   * Return the element
   * @return {String}
   */
  return (
    <span className={className}>{Moment(props.children).format(props.format || 'D MMM YYYY, h:mm a')}</span>
  );
};

DateTime.propTypes = {
  children: PropTypes.string.isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
};

export default DateTime;
