import { ApplicationDocumentActionTypes, ApplicationDocumentAction } from "./actions"

export enum DocumentStatus {
    Initial = "Initial",
    Pending = "Pending",
    Succeeded = "Succeeded",
    Failed = "Failed"
  }

export interface ApplicationDocumentsState {
    showAgreement: boolean
    status:DocumentStatus
}

export const initialState: ApplicationDocumentsState = {
    showAgreement: false,
    status:DocumentStatus.Initial
}

export const applicationDocumentsReducer = (state: ApplicationDocumentsState = initialState, action: ApplicationDocumentAction): ApplicationDocumentsState => {
    switch (action.type) {
        case ApplicationDocumentActionTypes.ShowAgreement:
            return {
                ...state,
                showAgreement: true                
            };
        case ApplicationDocumentActionTypes.HideAgreement:
            return {
                ...state,
                showAgreement: false                
            }
        case ApplicationDocumentActionTypes.DocumentPending:  
            return {
                ...state,
                status: DocumentStatus.Pending                
            };
        case ApplicationDocumentActionTypes.DocumentSucceeded:  
            return {
                ...state,
                status: DocumentStatus.Succeeded,
                showAgreement:false             
            };
        case ApplicationDocumentActionTypes.DocumentFailed:  
            return {
                ...state,
                status: DocumentStatus.Failed,
                showAgreement:false              
            };
        default:
            return state
    }
}