import { IDVerificationActionTypes } from "./actions";
import { IDVerificationStatus } from "./applicationIDVerification";

export interface ApplicantIDVerificationStatus {
  applicantId: Number
  idVerificationStatus: IDVerificationStatus
  canSend: Boolean
}

export interface IDVerificationState {
  applicants : ApplicantIDVerificationStatus[]
}

export const initialState: IDVerificationState = {
  applicants : []
}

export const idVerificationReducer = (state: IDVerificationState = initialState, action: any): IDVerificationState => {
  switch(action.type){
    case IDVerificationActionTypes.GetApplicantVerificationStatus:{
      return {...state, applicants : [...state.applicants, action.applicant]}
    }

    case IDVerificationActionTypes.SendingVerificationLink: {
      return {applicants: state.applicants.map(a => (a.applicantId === action.applicantId) ? {applicantId: a.applicantId, idVerificationStatus:IDVerificationStatus.Initiated, canSend:false} : a)};
    }

    case IDVerificationActionTypes.VerificationLinkSent: {
      return {applicants: state.applicants.map(a => (a.applicantId === action.applicantId) ? {applicantId: a.applicantId, idVerificationStatus:IDVerificationStatus.InProgress, canSend:false} : a)};
    }

    case IDVerificationActionTypes.SendingVerificationLinkFailed: {
      return {applicants: state.applicants.map(a => (a.applicantId === action.applicantId) ? {applicantId: a.applicantId, idVerificationStatus:IDVerificationStatus.FailedToSend, canSend:true} : a)};
    }

    default:
      return state;
  }
}
