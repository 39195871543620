import * as React from 'react';
import { Row, Col } from 'react-bootstrap';


import { Input } from 'formsy-react-components';

export const ApplicationUpdateVehicleManual = () => (
  <Row>
    <Col sm={2}>
      <Input
        name="motorPurchaseVehicle.year"
        id="motorPurchaseVehicle.year"
        label="Year"
      />
    </Col>
    <Col sm={4}>
      <Input
        name="motorPurchaseVehicle.make"
        id="motorPurchaseVehicle.make"
        label="Make"
      />
    </Col>
    <Col sm={4}>
      <Input
        name="motorPurchaseVehicle.model"
        id="motorPurchaseVehicle.model"
        label="Model"
      />
    </Col>
  </Row>
);

export default ApplicationUpdateVehicleManual;
