import * as React from 'react';
import { Guid } from 'guid-typescript';
import { Icon } from '../../utils';
import { Applicant } from './models'
import RateQuoteApplicantPerson from './rateQuoteApplicantPersonal';

export interface RateQuoteApplicantProps {
  apps: Applicant[],
}

const RateQuoteApplicant = (props: RateQuoteApplicantProps) => {

  const addApplicant = () => {
    let key = applicants.length + 1

    let apps = applicants;
    apps = [...apps, {
      applicantId: Guid.create().toString(),
      key,
      name: '',
      relationship: '',
      partner: '',
      employmentHistory: [],
      firstName: '',
      middleName: '',
      lastName: '',
      dependants: '',
      dateOfBirth: '',
      postcode: '',
      state: '',
      creditHistory: []
    }];
    setApplicants(apps);
  }
  let [applicants, setApplicants] = React.useState<Applicant[]>([]);
  React.useEffect(() => {
      if (props.apps === undefined || props.apps.length === 0) {
        addApplicant();
      } else {
        setApplicants(props.apps);
      }
    }, []);

  const removeApplicant = (index: number) => {
    const applicantList: Applicant[] = Object.assign([], applicants);

    if (applicantList[index].partner) {
      applicantList.map((applicant, instance) => {
        if (applicant.applicantId == applicantList[index].partner) {
          applicantList[instance].partner = '';
        }
      });
    }

    applicantList.splice(index, 1);
    setApplicants(applicantList);
  }

  const handleName = (name: string, index: number) => {
    const applicantList: Applicant[] = Object.assign([], applicants);
    applicantList[index].name = name;
    setApplicants(applicantList);
  }

  const handleRelationship = (relationship: string, index: number) => {
    const applicantList: Applicant[] = Object.assign([], applicants);

    if (applicantList[index].inRelationshipWithId) {
      applicantList.map((applicant, instance) => {
        if (applicant.key == applicantList[index].inRelationshipWithId) {
          applicantList[instance].inRelationshipWithId = undefined;
        }
      });
    }

    applicantList[index].relationship = relationship;
    applicantList[index].inRelationshipWithId = undefined;
    setApplicants(applicantList);
  }

  const handlePartner = (index: number, inRelationshipWithId?: number) => {
    const applicantList: Applicant[] = Object.assign([], applicants);

    applicantList.map((applicant, instance) => {
      if (inRelationshipWithId == undefined) {
        if (applicant.inRelationshipWithId == applicantList[index].key) {
          applicantList[instance].inRelationshipWithId = undefined;
        }
      }

      if (applicant.key == inRelationshipWithId) {
        applicantList[instance].relationship = applicantList[index].relationship;
        applicantList[instance].inRelationshipWithId = applicantList[index].key;
      }
    });

    applicantList[index].inRelationshipWithId = inRelationshipWithId;
    setApplicants(applicantList);
  }
  return <>
    <div className="panel-section np">
      <div className="panel-section-header panel-header-fixed">
        <i className="mi mi-accounts mi-3x ib mr-md text-center np" style={{ width: '44px' }} />

        <div className="ib va-tt mt-sm-n">
          <h3 className="mb-h mt-h">Applicants</h3>
        </div>
      </div>
      <div className="panel-section-body applicants">
        <div className="panel-section-container m-h p-md bg-primary-50">
          {
            applicants.map((applicant, index) => (
              <RateQuoteApplicantPerson
                key={`applicant-${applicant.applicantId}`}
                index={index}
                //data={this.props.data && this.props.data.applicants ? this.props.data.applicants[index] : null}
                applicant={{ ...applicant }}
                applicants={applicants}
                handleName={handleName}
                handleRelationship={handleRelationship}
                handlePartner={handlePartner}
                removeApplicant={removeApplicant}
              />
            ))
          }

          {
            (() => {
              if (applicants.length < 6) {
                return (
                  <button style={{ width: '100%', fontSize: '15px' }} type="button" className="card card-shallow card-mute card-hover mt-md" id="addApplicant" onClick={addApplicant}>
                    <strong className="secondary">
                      <Icon glyph="plus" className="primary mute-lg mr-sm" />
                      {' ADD APPLICANT'}
                    </strong>
                  </button>
                );
              }
            })()
          }
        </div>
      </div>
    </div>
  </>;
}

export default RateQuoteApplicant;