import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import { Input, Select } from 'formsy-react-components';

import * as referenceActions from '../../actions/referenceActions';

import ApplicationUpdateHeader from './applicationUpdateHeader';

import Mask from '../../helpers/mask';
import ReferenceApi from '../../api/referenceApi';
import { MAXIMUM_CONSUMER_RATE_DISCOUNT, MAXIMUM_COMMERCIAL_RATE_DISCOUNT, MAXIMUM_NOVATED_LEASE_RATE_DISCOUNT } from '../../constants/flexCommission';
import { PRODUCTGROUPS } from '../../constants/types';
import ApplicationUpdateProductNovatedLease from './applicationUpdateProductNovatedLease';

export class ApplicationUpdateProduct extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.props = props;
         
    const isConsumer = this.props.application.productGroupId === PRODUCTGROUPS.MOTOR_CONSUMER;
    const maximumRateDiscount = this.getDefaultRateDiscount(this.props.application.productGroupId);
    const isNovatedLease = this.props.application.productGroupId === PRODUCTGROUPS.MOTOR_NOVATED_LEASE;
    this.state = {
      visible: false,
      maximumRateDiscount,
      isConsumer,
      isNovatedLease,
    };

    this.toggleSection = this.toggleSection.bind(this);
    this.getVerificationLevels = this.getVerificationLevels.bind(this);
    this.getLoanPurposes = this.getLoanPurposes.bind(this);
  }

  componentDidMount() {
    this.getMaxRateDiscount(this.props.application.productGroupId);
  }

  UNSAFE_componentWillMount() {
    this.getVerificationLevels();
    this.getLoanPurposes();

    if (this.props.params.tab == 'finance') {
      this.setState({
        visible: true,
      });
    }
  }

  getDefaultRateDiscount(productGroupId) {    
    switch (productGroupId) {
      case PRODUCTGROUPS.MOTOR_CONSUMER:
        return MAXIMUM_CONSUMER_RATE_DISCOUNT;
      case PRODUCTGROUPS.MOTOR_NOVATED_LEASE:
        return MAXIMUM_NOVATED_LEASE_RATE_DISCOUNT;
      case PRODUCTGROUPS.MOTOR_COMMERCIAL:
        return MAXIMUM_COMMERCIAL_RATE_DISCOUNT;
      default:
        return MAXIMUM_CONSUMER_RATE_DISCOUNT;
    }  
  }

  getMaxRateDiscount(productGroupId) {        
    ReferenceApi.getMaxRateDiscount(productGroupId)
      .then((response) => {
        this.setState({ maximumRateDiscount: response.data * 100 });
      });
  }

  toggleSection() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  getVerificationLevels() {
    this.props.actions.getVerificationLevels(5100);
  }

  getLoanPurposes() {
    this.props.actions.getLoanPurposes();
  }

  render() {
    return (
      <div className={`panel-section ${this.state.visible ? '' : 'collapsed'}`}>
        <ApplicationUpdateHeader
          title="Purchase &amp; Product"
          description="Choose the right loan product and details"
          glyph="assignment-o"
          subGlyph="money"
          section="purchase"
          toggleSection={this.toggleSection}
        />

        <div className="panel-section-body">
          <div className="panel-section-container">
            <Row>

              {
                !this.state.isNovatedLease ? (
                  <>
                    <Col sm={3}>
                      <Input
                        name="motorProduct.verificationLevelId"
                        type="hidden"
                      />
      
                      <Select
                        name="motorProduct.verificationLevelId"
                        label="Verification Level"
                        placeholder="Please select"
                        disabled
                        options={this.props.reference.verificationLevels}
                        className="form-control"
                      />
                    </Col>
      
                    <Col sm={3}>
                      <Input
                        name="motorProduct.productGroupId"
                        type="hidden"
                      />
      
                      <Select
                        name="motorProduct.productGroupId"
                        label="Loan Purpose"
                        placeholder="Please select"
                        disabled
                        options={this.props.reference.loanPurposes}
                        className="form-control"
                      />
                    </Col> 
                    <Col sm={3}>
                      <div className="form-prefix">$</div>
                      <Input
                        name="motorPurpose.applicationFee"
                        id="motorPurpose.applicationFee"
                        ref="motorPurpose.applicationFee"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label={(
                          <span>
                            Brokerage
                            <span> </span>  
                            <small className="mute">
                              up to $1,450
                            </small>
                          </span>
                          )}
                        validationError="It must be smaller than $1,450"
                        validations={{
                          isFormattedNumber: true,
                          isLesserAmount: (values, value) => parseFloat(Mask.clean(value)) <= 1450,
                        }}
                      />
                    </Col>
                  </>
                ) : null
              }
              

              { (!((this.props.isMortgageAggregator && this.state.isConsumer) || this.state.isNovatedLease)) 
                  && (
                    <Col sm={3}>
                      <div className="form-prefix">%</div>
                      <Input
                        name="motorProduct.trailCommissionReduction"
                        id="motorProduct.trailCommissionReduction"
                        ref="motorProduct.trailCommissionReduction"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}                            
                        label={(
                          <span>
                          {'Rate Discount '}
                            <small className="mute">
                              {'up to '}
                              {this.state.maximumRateDiscount}
                              %
                            </small>
                          </span>
                          )}
                        value=""
                        validationError={`Rate discount must not be greater than ${this.state.maximumRateDiscount}%`}
                        validations={{
                          isFormattedNumber: true,
                          isLesserAmount: (values, value) => parseFloat(value) <= this.state.maximumRateDiscount,
                        }}
                      />
                      <i className="mute mi mi-help-outline a pointer" style={{ right: '12px', top: '6px' }} data-event="click" data-tip="The discount will reduce applicable commission and the customer delivery rate from that published in our Introducer Guide. An applicable saving to your customer will be calculated upon submission." />
                    </Col>
                  )}
            </Row>
            {
              this.state.isNovatedLease ? (
                <ApplicationUpdateProductNovatedLease application={this.props.application} formsy={this.props.formsy} />
              ) : (
                <div>
                  <Row>
                    <Col sm={3}>
                      <Select
                        name="motorProduct.loanTermMonths"
                        id="motorProduct.loanTermMonths"
                        ref="motorProduct.loanTermMonths"
                        label="Loan Term"
                        placeholder="Please select"
                        options={[2, 3, 4, 5, 6, 7].map((year) => ({
                          label: `${year} years`,
                          value: year * 12,
                        }))}
                        className="form-control"
                      />
                    </Col>
                    <Col sm={3}>
                      <div className="form-prefix">$</div>

                      <Input
                        name="motorPurpose.purchasePrice"
                        id="motorPurpose.purchasePrice"
                        ref="motorPurpose.purchasePrice"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label={(
                        <span>
                        {'Purchase Price '}
                        <small className="mute">(Incl. GST)</small>
                        </span>
                        )}
                        value=""
                        validationError="Purchase price must be positive"
                        validations={{
                          isFormattedNumber: true,
                          isRequired: true,
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="form-prefix">$</div>

                      <Input
                        name="motorPurpose.deposit"
                        id="motorPurpose.deposit"
                        ref="motorPurpose.deposit"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label="Deposit"
                        value=""
                        validationError="Deposit must be positive"
                        validations={{
                          isFormattedNumber: true,
                        }}
                      />
                    </Col>

                    <Col sm={3}>
                      <div className="form-prefix">$</div>

                      <Input
                        name="motorPurpose.netTradeIn"
                        id="motorPurpose.netTradeIn"
                        ref="motorPurpose.netTradeIn"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => Mask.numberFormsy(name, value, this.refs, { decimal: 2, negative: true })}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label="Net Trade-In"
                        value=""
                        validationError="Net trade-in must be valid"
                        validations={{
                          isFormattedNumber: true,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <div className="form-prefix">$</div>
                      <Input
                        name="motorPurpose.balloonResidual"
                        id="motorPurpose.balloonResidual"
                        ref="motorPurpose.balloonResidual"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label="Balloon Residual"
                        value=""
                        validationError="Balloon residual must be positive and smaller than the loan amount"
                        validations={{
                          isFormattedNumber: true,
                          isLesserAmount: (values, value) => parseFloat(Mask.clean(value)) <= parseFloat(Mask.clean(values['motorPurpose.purchasePrice'])),
                        }}
                      />
                    </Col>
                    <Col sm={3} className="hidden">
                      <div className="form-prefix">$</div>

                      <Input
                        name="motorPurpose.otherCost"
                        id="motorPurpose.otherCost"
                        ref="motorPurpose.otherCost"
                        updateOnChange={false}
                        updateOnBlur={false}
                        blurCallback={(name, value) => { Mask.numberFormsy(name, value, this.refs, { decimal: 2 }); }}
                        onFocus={(event) => Mask.cleanFormsy(event.target.name, event.target.value, this.refs)}
                        label="Other Costs"
                        value=""
                        validationError="Other cost must be positive"
                        validations={{
                          isFormattedNumber: true,
                        }}
                      />
                    </Col>
                    
                  </Row>                 
                </div>
              )
            }
            
          </div>
        </div>
      </div>
    );
  }
}

ApplicationUpdateProduct.propTypes = {
  actions: PropTypes.object,
  reference: PropTypes.object,
  isMortgageAggregator: PropTypes.bool,    
};


function mapStateToProps(state) {
  const isMortgageAggregator = state.session.details.adjustmentProfileID === 9;  
  return {
    reference: state.reference, 
    isMortgageAggregator,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUpdateProduct);
