import * as React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../utils';

const ApplicationTypeIcon = (props) => {
  let glyph;

  switch (props.type) {
    case 1: glyph = 'home'; break;
    case 2: glyph = 'directions-car'; break;
    case 5: glyph = 'city-alt'; break;
    case 7: glyph = 'money-box'; break;
  }

  return (
    <Icon glyph={glyph} className="loan-icon" rounded background="primary" />
  );
};

ApplicationTypeIcon.propTypes = {
  type: PropTypes.number.isRequired,
};

export default ApplicationTypeIcon;
