import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router';
import { Icon, Dots } from '../../utils';

import ContactPane from '../../components/contactPane';
import EmptyPane from '../../components/emptyPane';

export default class ApplicationListEmpty extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      contact: false,
    };

    this.toggleContact = this.toggleContact.bind(this);
  }

  toggleContact() {
    this.setState({
      contact: !this.state.contact,
    });
  }

  render() {
    if (this.props.applications.query) {
      return (
        <EmptyPane query={this.props.applications.query} item="applications" />
      );
    }


    return (
      <div className="p-md pt-lg pb-lg text-center">
        <Icon glyph="assignment-o" size={4} className="mute-lg mt-md" />
        <Dots style={{ marginLeft: '-33px', marginTop: '49px' }} />

        <h4 className="mt-lg">IQ is waiting to track your next application...</h4>

        {
          (() => {
            if (this.props.applications.all.length) {
              return (
                <h5 className="mute pb-md">
                  {'If you are looking for '}
                  <a href="javascript:void(0)" onClick={() => { this.props.filterApplications(0); }}>your past applications</a>
                  {' you can change your filter.'}
                </h5>
              );
            }
            return (
              <h5 className="mute pb-md">Your applications will show here.</h5>
            );
          })()
        }

        <div className="bg-100 mt-md ml-md-n mr-md-n mb-lg-n mt-lg p-lg">
          <h4>What&#39;s next?</h4>
          <h5 className="mute pb-lg">You have no current applications with us but we’d love to do business with you!</h5>

          <Row>
            <Col sm={4}>
              <Icon glyph="folder-outline" size="lg" rounded className="bg-white secondary" />
              <p className="mt-md mute">
                {'Browse our '}
                <Link to="/documents">documents</Link>
                {' to learn about our products.'}
              </p>
            </Col>
            <Col sm={4}>
              <Icon glyph="assignment-o" size="lg" rounded className="bg-white secondary" />
              <p className="mt-md mute">
                {'Prequalify your customer with our '}
                <Link to="/calculators">calculators</Link>
                .
              </p>
            </Col>
            <Col sm={4}>
              <Icon glyph="face" size="lg" rounded className="bg-white secondary" />
              <p className="mt-md mute">
                <a href="javascript:void(0)" onClick={this.toggleContact}>Get in touch</a>
                {' with our support and product specialists.'}
              </p>
            </Col>
          </Row>

          <ContactPane showModal={this.state.contact} onHide={this.toggleContact} />
        </div>
      </div>
    );
  }
}

ApplicationListEmpty.propTypes = {
  applications: PropTypes.any,
  filterApplications: PropTypes.func,
};
