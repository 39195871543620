import * as React from 'react';
import PropTypes from 'prop-types';

const Percentage = (props) => {
  let percentage;

  /**
   * Set the amount
   */
  if (!props.children) {
    percentage = 'TBA';
  } else {
    /**
       * Round the number
       */
    percentage = props.children * 100 * 100;
    percentage = Math.round(percentage);
    percentage /= 100;

    if (props.round) {
      percentage = percentage.toFixed(typeof props.round === 'number' ? props.round : 2);
    }

    percentage = `${percentage}%`;
  }

  /**
   * Return the element
   * @return {String}
   */
  return (
    <span className="formatted-percentage">{percentage}</span>
  );
};

Percentage.propTypes = {
  children: PropTypes.any,
  round: PropTypes.any,
};

export default Percentage;
